import { createContext } from "react";
import { User } from "../../interfaces/user";

export type UserContextProps = {
  userState: User;
  isLoggedIn: boolean;
  accessToken : string;
  refreshToken : string;
  isAdmin : boolean;
  loginUser: (user: User) => void;
  logoutUser: (user: User,skipRequestLogout? : boolean) => void;
  setFavorites: (favorites: number[]) => void;
  saveAccessToken : (token:string) =>void;
  saveRefreshToken : (token:string) =>void;
  setUser : (user :User) => void;
};

const UserContext = createContext<UserContextProps>({} as UserContextProps);

export default UserContext;
