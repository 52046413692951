import { GetAppraisalCompaniesResponse, GetAppraisalsResponse, ValidateDataRequest, ValidateDataResponse } from "../../interfaces/appraisal"
import { useFetch } from "./useFetch"

export const useAppraisalFetch = () => {

    const {authFetch} = useFetch()

    const getAppraisalCompanies = async (): Promise<GetAppraisalCompaniesResponse> => {

        const response = await authFetch(
            process.env.REACT_APP_AUTH_API_URL + '/api/appraisal/get-companies',
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache'
                },
            }
        )
        
        return response;
    }

    const validateData = async ( data : ValidateDataRequest ): Promise<ValidateDataResponse> => {

        const response = await authFetch(
            process.env.REACT_APP_AUTH_API_URL + '/api/appraisal/validate-data',
            {
                method : 'POST',
                body : JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache'
                },
            }
        )
        
        return response;
    }

    return {
        getAppraisalCompanies,
        validateData
    }
}