import React, { ReactNode, useState, useEffect } from "react";
import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { usePaginateTable } from "../../hooks/paginateTable/usePaginateTable";
import { UnitsTableFooter } from "./UnitsTableFooter";
import { useCookies } from "../../hooks/cookies/useCookies";

interface Column {
  text: ReactNode;
  name: string;
  visible?: boolean;
}

interface UnitsTableProps {
  data: any[];
  columns: Column[];
  onSort?: (name: string) => void;
  className?: string;
  unitExpand?: number | null;
  handleModal?: (id: number) => void;
}

export const UnitsTable: React.FC<UnitsTableProps> = ({
  data,
  columns,
  onSort,
  className = "",
  unitExpand,
  handleModal,
}) => {
  const rowsPerPage = 8;
  const [page, setPage] = useState(1);
  const { slice, range } = usePaginateTable({ data, page, rowsPerPage });
  const [openNew, setOpenNew] = useState(true);
  const { getCookie, setCookie } = useCookies();

  useEffect(() => {
    if (!getCookie("showNewTour")) {
      setCookie("showNewTour", "=1;expires=-1;path=/;SameSite=none;Secure");
      setOpenNew(true);
    } else {
      setOpenNew(false);
    }
  }, []);

  const handleSortClick = (name: string) => {
    if (onSort) {
      onSort(name);
    }
  };

  const handleRowClick = (id: number) => {
    if (handleModal) {
      handleModal(id);
    }
  };

  const handleHeaderClick = (columnName : string) => {
    if (onSort ) {
      if (columnName) {
        handleSortClick(columnName);
      }
    }
  };

  return (
    <div className={`units-table border bg-white rounded px-lg-3 ${className}`}>
      <Row className="units-table-headers d-lg-flex align-items-center d-none">
        {columns.map((col, index) => {
          if (col.visible) {
            return (
              <Col
                key={"units_table_col_" + index}
                className={`units-table-header-col units_table_col_${index} text-center fs-xs fw-500 ${onSort !== undefined ? "sortable" : ""
                  }`}
                role={onSort !== undefined ? "button" : "none"}
                data-name={col.name}
                onClick={(e:any) => handleHeaderClick(col.name)}
                md={col.name === "workPrice" || col.name === "listPrice" ? 2 : 0}
                style={{ minWidth: col.name === "price" ? 110 : (col.name == 'm2' ? 83 : 0)}}
              >
                {col.name === "virtualTour" && unitExpand === null ? (
                  <OverlayTrigger
                    show={openNew}
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props) => (
                      <Tooltip className="alert-new" {...props}>
                        <Button className="alert-new-close" onClick={() => setOpenNew(false)}>
                          <i className="bi bi-x-lg"></i>
                        </Button>
                        <div className="pt-2 pe-3 text-start">
                          <span className="fw-bold">¡NUEVO!</span>
                          <span> Ahora podés ver el tour virtual de la unidad</span>
                        </div>
                      </Tooltip>
                    )}
                  >
                    <span>{col.text}</span>
                  </OverlayTrigger>
                ) : (
                  <span>{col.text}</span>
                )}
              </Col>
            );
          }
          return null;
        })}
      </Row>
      {slice.map((row, index) => (
        <div key={"units-table-row" + index} onClick={() => handleRowClick(row.id)}>
          <hr className="m-0 bg-light d-none d-lg-block" />
          <Row
            className={`units-table-row d-flex align-items-lg-center bg-white border rounded py-1 py-lg-0  ${index === data.length - 1 ? "mb-0" : "mb-2"
              } mb-lg-0`}
          >
            {columns.map((col, index) => {
              if (col.visible) {
                return (
                  <Col
                    key={"units_table_col_" + index}
                    className={`units-table-row-col units_table_col_${index} py-lg-3 py-2 text-lg-center text-black-50 lh-1 ${col.name === "action" ? "col-action" : ""
                      } ${col.name=='bathrooms' || col.name=='garages' ? 'units-col-small':''}`}
                    md={col.name === "workPrice" || col.name === "listPrice" ? 2 : 0}
                  >
                    {row[col.name]}
                  </Col>
                );
              }
              return null;
            })}
          </Row>
        </div>
      ))}

      {(page !== 1 || data.length > rowsPerPage) && (
        <UnitsTableFooter range={range} slice={slice} setPage={setPage} page={page} />
      )}
    </div>
  );
};
