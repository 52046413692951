import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import userFilter from "../../../hooks/filter/useFilter";
import { useSearchParams } from "react-router-dom";
import { uniqueFromArray, excludeFromArray } from "../../../utils/Functions";
import useCountry from "../../../hooks/config/useConfig";

export const FilterRoom = () => {
  const { setFilter } = userFilter();
  const [searchParams] = useSearchParams();
  const [currentRooms, setCurrentRooms] = useState([] as string[]);
  const { translation } = useCountry();

  useEffect(() => {
    const parameter = searchParams.get("rooms");
    setCurrentRooms(!!parameter ? parameter.split(",") : ([] as string[]));
  }, [searchParams]);

  return (
    <div className="mb-1 d-flex justify-content-start flex-wrap numbers-container">
      {["0", "1", "2", "3", "4", "5"].map((number) => (
        <Button
          key={`filter-${number}`}
          variant="outline-light"
          className={(number === "0" ? "btn-mono " : "") + `text-dark bg-white btn-number d-flex align-items-center justify-content-center ${currentRooms.includes(number) ? "active" : ""
            }`}
          onClick={(e) => {
            const current = currentRooms ?? ([] as string[]);
            setFilter({
              rooms: !(e.target as Element).classList.contains("active")
                ? (uniqueFromArray([...current, number]) as unknown as string[])
                : (excludeFromArray(current, number) as unknown as string[]),
            });
          }}
        >
          {number === "5" ? `+${number}` : number === "0" ? translation.abbrStudio : number}
        </Button>
      ))}
    </div>
  );
};
