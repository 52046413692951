import { useEffect } from "react"
import { CoursesList } from "../../fragments/academy/CoursesList"
import { useGoogleAnalytics } from "../../hooks/googleAnalytics/useGoogleAnalytics"
import useUser from "../../hooks/user/useUser"


export const Courses = () => {

    const GA = useGoogleAnalytics(true)
    const {userState} = useUser()

    useEffect(() => {
        GA.Event({ category: "Courses list", action: "courses_list_open", label: `${userState.name}`, value: Number(userState.id) })
    }, [])

    return (
        <>
            <main className="main-container container">
            <CoursesList />
            </main>
        </>
    )
}