import { Col, Row } from "react-bootstrap";
import useCountry from "../../hooks/config/useConfig";
import { CountryFeatures } from "../../interfaces/config";

export const PropertyCommissionRent = ({ commisionRent , projectCountryFeatures }: { commisionRent: string ,
  projectCountryFeatures?  : CountryFeatures | null }) => {
  const { translation , country} = useCountry();

  return (
    <Row className="d-flex">
      <Col lg={12} className="d-flex align-items-center mb-1">
        <h3 className="fs-4 fw-normal m-0">Comisión {translation.rent}</h3>
        <i className="ms-2 bi bi-info-circle small text-light"></i>
      </Col>

      <Col lg={12} className="flex-column small">
        <p className="m-0 h3 text-green">{commisionRent}% {projectCountryFeatures?.plus_iva ? ' + IVA' : ''}</p>
      </Col>
    </Row>
  );
};
