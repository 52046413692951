import useBreakpoint from "use-breakpoint";
import { Button, Carousel, Container } from "react-bootstrap";
import { BREAKPOINTS } from "../../utils/Constants";
import { CustomImage } from "../../components/CustomImage/CustomImage";
import { useTranslation } from '../../hooks/translation/useTranslation';
import useCountry from "../../hooks/config/useConfig";
import { useLinks } from "../../hooks/config/useLinks";

export const BannerTop = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");
  const { country  } = useCountry();
  const { mainProjectsUrl } = useLinks()
  const translation : any = useTranslation(country.toLowerCase())

  const images = [
    "https://cdn1.infocasas.com.uy/web/6230bb4e0903b_infocdn__20220310_carrouselhome1.png",
    "https://cdn1.infocasas.com.uy/web/6230bb86c4559_infocdn__20220310_carrouselhome2.png",
    "https://cdn1.infocasas.com.uy/web/6230bb86e7699_infocdn__20220310_carrouselhome3.png",
    "https://cdn1.infocasas.com.uy/web/6230bb86ed1f0_infocdn__20220310_carrouselhome4.png",
    "https://cdn1.infocasas.com.uy/web/6230bb8718df7_infocdn__20220310_carrouselhome5.png",
    "https://cdn1.infocasas.com.uy/web/6230bb86eec9d_infocdn__20220310_carrouselhome6.png",
  ];
  return (
    <div className="home-banner-top position-relative">
      <Container className="home-banner-top-cta position-absolute text-white">
        <div className={`home-banner-top-text ${breakpoint === "xs" ? "ms-3" : ""}`}>
          <h1 className="display-4 home-banner-title fw-bold lh-sm">
            Empieza a vender obra nueva sin barreras
          </h1>
          <p className={"fs-3 lh-sm home-banner-top-texto " + (breakpoint === "md" || breakpoint === "sm" || breakpoint === "xs" ? "mt-1" : "mt-4")}>
            con un sistema de información claro, atractivo y <strong>sin ningún costo para tu negocio.</strong>
          </p>
          <p className={"fs-3 lh-sm home-banner-top-texto mb-4 " + (breakpoint === "md" || breakpoint === "sm" || breakpoint === "xs" ? "mt-1" : "mt-4")}>
            Plataforma <strong className="fs-2">gratuita exclusiva</strong> para broker e inmobiliarias.
          </p>
          <Button href={mainProjectsUrl} className="home-banner-btn">
            Pruebalo ahora
          </Button>
        </div>
      </Container>
      <Carousel controls={false} touch interval={4000} className="h-100">
        {images.map((item, index) => (
          <Carousel.Item key={"slide_" + index} className="h-100">
            <div className="w-100 position-relative">
              <div className="gradient-overlay w-100 position-absolute"></div>
              <CustomImage
                classes="d-block w-100"
                src={item}
                alt="Empieza a vender obra nueva sin barreras"
                width={500}
                height={500}
              />
            </div>
            <div className="home-banner-top-text-container w-100 h-100 d-flex align-items-center">
              {
                index === 0 || index === 2 ?
                  (
                    <div className="home-banner-message position-absolute end-0 d-flex justify-content-center align-self-end pb-3 pe-4">
                      <img src={window.location.origin + '/images/message-bg.png'}></img>
                      <p
                        className="position-absolute top-0"
                        dangerouslySetInnerHTML={{
                          __html: index === 0 ? `Más de <strong>5000 ${translation.developer?.toLowerCase()}s ya se registraron</strong> en Iris` : 'Incrementa tus ventas <strong>hasta en un 100% más</strong>'
                        }}
                      >
                      </p>
                    </div>
                  ) : ""
              }
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};
