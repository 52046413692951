import { Accordion, Badge, Button, Offcanvas } from "react-bootstrap";
import useBreakpoint from "use-breakpoint";
import userFilter from "../../hooks/filter/useFilter";
import useCountry from "../../hooks/config/useConfig";
import { BREAKPOINTS } from "../../utils/Constants";
import { FilterAmenities } from "../Filters/FilterAmenities/FilterAmenities";
import { FilterBathroom } from "../Filters/FilterBathroom/FilterBathroom";
import { FilterPrice } from "../Filters/FilterPrice/FilterPrice";
import { FilterPropertyType } from "../Filters/FilterPropertyType/FilterPropertyType";
import { FilterRoom } from "../Filters/FilterRoom/FilterRoom";
import { FilterSocialHousing } from "../Filters/FilterSocialHousing/FilterSocialHousing";
import { FilterState } from "../Filters/FilterState/FilterState";
import "./Sidebar.scss";
import { FilterFinancing } from "../Filters/FilterFinancing/FilterFinancing";
import { FilterUnitsRented } from "../Filters/FilterUnitsRented/FilterUnitsRented";
import { ReactNode, useMemo } from "react";
import { FilterSearch } from "../Filters/FilterSearch/FilterSearch";
import { FilterSubsidy } from "../Filters/FilterSubsidy/FilterSubsidy";
import { IconTrash } from "../CustomIcons/IconTrash";
import { FilterHandOverDate } from "../Filters/FilterHandOverDate/FilterHandOverDate";
import { DeveloperType, ProjectStatusType, ProjectTypeType } from "../../interfaces/project";
import { FilterPieBonus } from "../Filters/FilterPieBonus/FilterPieBonus";
import { FilterServiceRoom } from "../Filters/FilterServiceRoom/FilterPieBonus";
import { FilterDeveloper } from "../Filters/FilterDeveloper/FilterDeveloper";

export const Sidebar = ({
  open,
  setOpen,
  operationType,
  projectTypesList,
  projectStatusList,
  developersList
}: {
  open: boolean;
  setOpen: Function;
  operationType: "sale" | "rent";
  projectTypesList : ProjectTypeType[],
  projectStatusList : ProjectStatusType[],
  developersList : DeveloperType[]
}) => {
  const { filterState, removeFilter } = userFilter();
  const { countryFeatures, filtersByCountry, translation } = useCountry();
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");
  const countFilters = Object.entries(filterState).map((filter) => {
    let [k, v] = filter;
    if (typeof v === "boolean") return v
    if (typeof v !== "undefined" && typeof v !== "object" && k !== "minPrice" && k !== "maxPrice")
      v = v.split(",");
    // typeof v === 'object' ? v.length : v
    return (
      (k !== "minPrice" && k !== "maxPrice" && typeof v !== "undefined" ? v.length : v > 0 ? 1 : 0) ?? 0
    );
  });
  const numberFilters = (countFilters.length ? countFilters : [0]).reduce(
    (a, b) => a + b
  );

  let sidebarFilters: { title: string | JSX.Element; component: ReactNode; filterName: string[]; position: string; visible: boolean }[] = useMemo(() => {
    let cols = [
      {
        title: translation.filter_type,
        component: <FilterPropertyType projectTypesList={projectTypesList} />,
        filterName: ["propertyType"],
        position: "inside",
        visible: filtersByCountry.propertyType,
      },
      {
        title: translation.filter_developers,
        component: <FilterDeveloper developersList={developersList} />,
        filterName: ["developers"],
        position: "inside",
        visible: filtersByCountry.developers,
      },
      {
        title: (
          <>
            Precios 
          </>
        ),
        component: <FilterPrice />,
        filterName: ["minPrice", "maxPrice"],
        position: "inside",
        visible: filtersByCountry.price,
      },
      {
        title: "Estado",
        component: <FilterState projectStatusList={projectStatusList}/>,
        filterName: ["state"],
        position: "inside",
        visible: filtersByCountry.estates,
      },
      {
        title: translation.bedrooms,
        component: <FilterRoom />,
        filterName: ["rooms"],
        position: "inside",
        visible: filtersByCountry.bethrooms,
      },
      {
        title: "Baños",
        component: <FilterBathroom />,
        filterName: ["bathrooms"],
        position: "inside",
        visible: filtersByCountry.badrooms,
      },
      {
        title: translation.amenities,
        component: <FilterAmenities />,
        filterName: ["amenities"],
        position: "inside",
        visible: filtersByCountry.amenities,
      },
      {
        title: "Nombre de Proyecto",
        component: <FilterSearch source="filters" />,
        filterName: ["search"],
        position: "inside",
        visible: filtersByCountry.searchProject,
      },
      {
        title: "Financiación",
        component: <FilterFinancing />,
        filterName: ["financing"],
        position: "inside",
        visible: filtersByCountry.financing,
      },
      {
        title: "Fecha de entrega",
        component: <FilterHandOverDate />,
        filterName: ["handOverDate"],
        position: "inside",
        visible: filtersByCountry.handOverDate,
      },
      {
        title: "Sujeto a subsidio",
        component: <FilterSubsidy />,
        filterName: ["subsidy"],
        position: "inside",
        visible: filtersByCountry.subjectToSubsidy,
      },
      {
        title: "Bono Pie",
        component: <FilterPieBonus />,
        filterName: ["pie_bonus"],
        position: "left",
        visible: filtersByCountry.pieBonus,
      },
      {
        title: translation.social_housing,
        component: <FilterSocialHousing />,
        filterName: ["social_housing"],
        position: "left",
        visible: filtersByCountry.promotedHousing,
      },
      {
        title: translation.units_rent,
        component: <FilterUnitsRented />,
        filterName: ["units_rented"],
        position: "left",
        visible: operationType === "sale" && filtersByCountry.unitsRented,
      },
      {
        title: 'Cuarto de servicio',
        component: <FilterServiceRoom />,
        filterName: ["service_room"],
        position: "left",
        visible: filtersByCountry.serviceRoom,
      },
    ];

    return cols;
  }, [operationType, countryFeatures, filtersByCountry,translation,projectStatusList,projectTypesList, developersList])

  const checkHiddenClass = (filterName: string[]) => {
    let hide = true;

    filterName.forEach((name: string) => {
      const size =
        name in filterState && typeof filterState[name] !== "undefined"
          ? typeof filterState[name] === "object"
            ? filterState[name].length
            : filterState[name]
          : 0;
      hide = !size ?? true;
    });
    return hide;
  };

  return (
    <Offcanvas
      show={open}
      onHide={() => setOpen(false)}
      backdrop={false}
      scroll={breakpoint !== "xs"}
      className="sidebar shadow"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="px-3">
          Filtros
          {numberFilters > 0 && (
            <Badge pill className="text-white ms-2" bg="primary">
              {numberFilters}
            </Badge>
          )}
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="pt-0">
        <div className="filters border-top">
          <Accordion alwaysOpen flush>
            {sidebarFilters.map((f, i) => (
              f.visible && (
                <Accordion.Item key={`accordion-${f.title}`} eventKey={`${i}`}>
                  <Accordion.Header
                    className={`shadow-none bg-white ${f.position === "left" ? "hide-icon d-flex align-items-center" : ""}`}
                  >
                    <i className={`bi bi-dot text-primary dot-filter ${checkHiddenClass(f.filterName) ? "hide" : ""}`}></i>
                    <span className="fw-500">{f.title}</span>
                    {f.position === "left" && (
                      <div className="flex-grow-1 d-flex align-items-center justify-content-end">
                        {f.component}
                      </div>
                    )}
                  </Accordion.Header>
                  {f.position === "inside" && (
                    <Accordion.Body className="px-3 pt-0">
                      {f.component}
                    </Accordion.Body>
                  )}
                </Accordion.Item>
              )
            ))}
          </Accordion>
        </div>
        <div className="d-flex px-4 mt-3 mb-5 justify-content-center">
          <Button
            variant="outline-primary"
            className="border-0"
            onClick={() => removeFilter()}
            children={
              <span className="d-flex align-items-center">
                <IconTrash width={16} height={16} className="me-2" /> Limpiar filtros
              </span>
            }
          />
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
