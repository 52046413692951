import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Countdown } from "./Countdown";
import { useCookies } from "../../hooks/cookies/useCookies";

const getWindowSize = () => {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
}

export const PromoModal = ({cookieName , mobileImg , desktopImg , link, dateEvent} : 
    {cookieName : string, mobileImg : string , desktopImg : string , link? : string, dateEvent: string } ) => {
    const [windowSize, setWindowSize] = useState(getWindowSize());

    const [modal, setShowModal] = useState(false);

    const { getCookie, setCookie } = useCookies()

    const now = new Date();
    const time = now.getTime();
    const expireTime = time + 86400 * 1000; //24h
    now.setTime(expireTime);

    const expires = '=ok;expires=' + now.toUTCString() + ';path=/;SameSite=none;Secure';

    useEffect(() => {
        function handleWindowResize() {
          setWindowSize(getWindowSize());
        }
    
        window.addEventListener('resize', handleWindowResize);
    
        if (!getCookie(cookieName)) {
            // setCookie(cookieName, expires);
            setShowModal(true);
        } else {
            setShowModal(false)
        };


        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <Modal
            show={modal}
            size={windowSize.innerWidth > 991 ? "lg" : "sm"}
            onHide={() => setShowModal(false)}
            centered
            contentClassName="border-0 modal-container"
        >
            <Modal.Body className="popup-container" 
            onClick={() => {if(link) window.open(link)}}
            style={{backgroundImage : `url(${(windowSize.innerWidth > 991 ? desktopImg : mobileImg)})` }}
            >
                <Countdown date={dateEvent} />
            </Modal.Body>
        </Modal>
    )
}