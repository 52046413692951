import React, { useEffect } from 'react';
import { Route, Navigate, Outlet, useLocation } from "react-router-dom";
import useUser from "../hooks/user/useUser";
import useAlert from "../hooks/alert/useAlert";

const AdminRoute = () => {
    const { isAdmin , isLoggedIn  } = useUser()
    const { pathname, search } = useLocation();

    if(isLoggedIn){
        if(isAdmin) return  <Outlet/>
        else return  <Navigate to='/forbidden'  state={{ from: pathname, search: search }}/>
    }else return  <Navigate to='/iniciar-sesion' state={{ from: pathname, search: search }}/>

}

export default AdminRoute;