import { useEffect, useState } from "react";
import { Badge, Collapse, Dropdown, Form } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import userFilter from "../../../hooks/filter/useFilter";
import "./FilterNeighbourhood.scss";
import useCountry from "../../../hooks/config/useConfig";
import { FilterCommune } from "../FilterCommune/FilterCommune";
import { Department, Zone } from "../../../interfaces/locations";


export const FilterNeighbourhood = ({estate, activeParent, setActiveParent, setCounter }: { estate : Department, activeParent: boolean, setActiveParent: Function, setCounter: Function }) => {
  const { filterState, setFilter } = userFilter();
  const { country } = useCountry();
  const [searchParams] = useSearchParams();
  const [neighbourhoods, setNeighbourhoods] = useState<Zone[]>([]);
  const [activeObject , setActiveObject] = useState<{[key:string]: boolean}>({});
  const [openCollapse, setOpenCollapse] = useState<any[]>([]);
  
  const [counterNeighbourhoodObject , setCounterNeighbourhoodObject] = useState<{[key:string]: number}>({});


  useEffect(() => {

    const filteredNeighbourhoods = estate.zones

    setNeighbourhoods(filteredNeighbourhoods)
    //setNeighbourhoodsEstate(filteredNeighbourhoods)

    emptyActiveNeighbourhoods()
    
  }, [estate])

  useEffect(() => {
    const parameter = searchParams.get("neighbourhood");
    emptyActiveNeighbourhoods()
    if (!!parameter) {
      const newEstates = neighbourhoods.filter((neighbourhood) =>
        parameter.split(",").includes(neighbourhood.id.toString())
      );
      
      newEstates.map(ne =>{
        setActiveObject(prev=> {
          return {
            ...prev,
            [ne.id] : true
          }
        })
      })
    } else {
      
    };
  }, [searchParams, neighbourhoods]);

  useEffect(()=>{
    setCounter(document.querySelectorAll(`.parent-estate-${estate.id} .neighbourhood-check .form-check-input:checked`).length)
    
  },[activeObject])

  const emptyActiveNeighbourhoods = () =>{
    let objectA : {[key:string]: boolean} = {}
    neighbourhoods.forEach(fn=>{
      objectA[fn.id] = activeParent?true: false
    })
    setActiveObject(objectA)
  }


  const saveActiveParent = (isActive : boolean , neighboorhoudId : string)  => {
    setActiveParent(isActive)
    if(!isActive){
      
      const filterEstates = filterState.location?.filter((location) => location !== estate.id);
        setFilter({ location: filterEstates });
      setActiveObject(prev=> {
      return {
        ...prev,
        [neighboorhoudId] : isActive
      }
    })
  }
  }

  const setCounterNeighbour = (nId : string, count : number) => {
    setCounterNeighbourhoodObject(prev => {
      return {
        ...prev,
        [nId] : count
      }
    })
  }

  const dropdowns = neighbourhoods.map((neighbourhood) => {

    const communesByNeighbourhood = neighbourhood.communes
    const hasCommunes = communesByNeighbourhood.length > 0 ? true : false

    const handleChange = (e: any) => {

      if (
        (typeof filterState.location === "undefined" ||!filterState.location.length) && 
        (typeof filterState.neighbourhood === "undefined" ||!filterState.neighbourhood.length)
      ) { //No existe filtro ni por location ni por neighbourhood
        
        setFilter({ neighbourhood: [neighbourhood.id] })
      } else { 
        
        let filter;
        //Si se está DESACTIVANDO
        if (activeObject[neighbourhood.id]) {
          //Tiene a su padre estate
          if(filterState.location?.includes(estate.id)){
            const filterNeighbourhood = filterState.neighbourhood? filterState.neighbourhood?.filter((id) => id !== neighbourhood.id).map((n) => n) : []
            const filterNeighbourhoodOwn = neighbourhoods.filter((n) => n.id !== neighbourhood.id).map((n) => n.id);
            filter = Array.from(new Set(filterNeighbourhood?.concat(filterNeighbourhoodOwn)))
            setActiveParent(false);

          }else{
            filter = filterState.neighbourhood?.filter((n: number) => n !== neighbourhood.id);
          }
        }
        else {
          filter = [...(filterState.neighbourhood ?? []), neighbourhood.id]
        }
        setFilter({ neighbourhood: filter })
        
      }
      
      let filterEstates : any = filterState.location ? filterState.location : []
      if(activeObject[neighbourhood.id]){
        //Está desactivando. Quitamos padre
        filterEstates = filterState.location?.filter((location) => location !== estate.id);
      }
      
      const communesFilters = filterState.commune?.filter((n) => !communesByNeighbourhood.map((n) => n.id).includes(n));
      setFilter({location: filterEstates , commune : communesFilters});
      
    };

    const handleDrop = (e: any) => {

      if (e.target.classList.contains('form-check-input')) {
        handleChange(e)
      } else {
        const collapseIndex = openCollapse.findIndex((collapse) => collapse === neighbourhood.id);

        if (collapseIndex >= 0) {
          setOpenCollapse([
            ...openCollapse.slice(0, collapseIndex),
            ...openCollapse.slice(collapseIndex + 1, openCollapse.length)
          ])
        } else {
          setOpenCollapse(openCollapse => [...openCollapse, neighbourhood.id])
        }
      }
    }

    return <div key={'neighbourhood-main-'+neighbourhood.id}>
    {
      hasCommunes && 
      <>
          <Dropdown.Item
            key={`neighboorhoud-title-${neighbourhood.id}`}
            onClick={(e) => handleDrop(e)}
            className={`d-flex align-items-center px-2 py-2 rounded-1 `}
          >
            <Form.Check
              key={`neighboorhoud-checking-${neighbourhood.id}`}
              className="me-2 d-flex align-items-center neighbourhood-check neighbourhood-main-check ">
              <Form.Check.Input checked={activeObject[neighbourhood.id] || false} id={`neighbourhood-${neighbourhood.id}`} type="checkbox" onClick={e => e.stopPropagation()} onChange={e => handleDrop(e)} />
              <Form.Check.Label htmlFor={`neighbourhood-${neighbourhood.id}`} >{`${country === "CL" ? 'Comuna' : ''} ${neighbourhood.name} `}</Form.Check.Label>
            </Form.Check>
            {(!activeObject[neighbourhood.id] && counterNeighbourhoodObject[neighbourhood.id] > 0) && (
              <small className="d-flex align-items-center">
                <Badge pill bg="primary" className="badge-check ms-2">
                  {counterNeighbourhoodObject[neighbourhood.id]}
                </Badge>
              </small>
            )}
          </Dropdown.Item>

          <Collapse className={`parent-neighbourhood-${neighbourhood.id}`} key={`collapse-neigh-${neighbourhood.id}`} in={openCollapse.findIndex((collapse) => collapse === neighbourhood.id) >= 0}>
            <div className="commune-collapse">
              <FilterCommune estate={estate} neighbourhood={neighbourhood} activeParent={activeObject[neighbourhood.id]} setActiveParent={saveActiveParent} setCounter={setCounterNeighbour} communesOfNeighbourhood={communesByNeighbourhood} />
            </div>
          </Collapse>
        </>
    }

    {
      !hasCommunes && 
      <div key={'neigh-' + neighbourhood.id} className="neighbourhood-check d-flex py-1 d-flex align-items-center px-2 py-2 rounded-1 text-black" >
        <Form.Check id={`neighbourhood-${neighbourhood.id}`} className="me-2 d-flex align-items-center" checked={activeObject[neighbourhood.id] || false} readOnly onChange={handleChange}
          label={`${country === "CL" ? 'Comuna' : ''} ${neighbourhood.name}`} />
      </div>
    }
    </div>

  });

  return (
    <>
      {dropdowns}
    </>
  );
};


