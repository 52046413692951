import { useEffect, useState } from "react";
import { PropertyType } from "./../../interfaces/property";
import { PropertyGallery } from "./../PropertyGallery/PropertyGallery";
import { CountryFlag } from "./../CountryFlag/CountryFlag";
import { PropertyAgentCommision } from "./../PropertyAgentCommision/PropertyAgentCommision";
import { PropertyBedroom } from "./../PropertyBedroom/PropertyBedroom";
import { PropertyMeters } from "./../PropertyMeters/PropertyMeters";
import { PropertyPrice } from "./../PropertyPrice/PropertyPrice";
import { PropertyCardAmenities } from "../PropertyCardAmenities/PropertyCardAmenities";
import { IconFire } from "../CustomIcons/IconFire";
import { IconStarFill } from "../CustomIcons/IconStarFill";
import { CustomImage } from "../CustomImage/CustomImage";
import { Placeholder } from "react-bootstrap";
import useUser from "../../hooks/user/useUser";
import useCountry from "../../hooks/config/useConfig";
import { ProjectType } from "../../interfaces/project";
import { DownloadBrochure } from "../PropertyDownload/DownloadBrochure";
import { AUTH_VALUES } from "../../utils/Constants";
import { PropertyCommissionRent } from "../PropertyFinancial/PropertyCommissionRent";
import { usePromo } from "../../hooks/promo/usePromo";

const {ADMIN_LEVEL} = AUTH_VALUES

interface PropertyCardType {
  data: ProjectType;
  fixed?: boolean;
  download_brochure? : boolean;
} 

export const PropertyCard = ({ data, fixed = false, download_brochure = false }: PropertyCardType) => {
  const { userState } = useUser();
  const unitHasAC = data.units ? data.units?.filter((unit) => unit.has_ac) : []
  const unitHasBalcony = data.units ? data.units?.filter((unit) => unit.has_balcony) : []
  const isBlocked = Number(data.level) > Number(userState?.level);
  const isUnBlocked = data.level ? Number(data.level) > 1 && Number(data.level) <=  Number(userState?.level) : false;
  const isRent = !!data.units?.filter((d) => d.operation_type === "Alquiler").length;
  const isTransactional = data.level ? Number(data.level) == ADMIN_LEVEL : false;
  const { translation } = useCountry();
  const [operation, setOperation] = useState<any>(null)
  const { countryPromo } = usePromo()

  useEffect(() => {
    const operationType = window.localStorage.getItem("OperationType");

    if (operationType) setOperation(operationType)
  }, [])

  return (
    <div
      className={
        "property-card border rounded w-100 d-flex flex-column bg-white shadow-sm"
      }
    >
      <div className="property-card-cover">
        {(countryPromo?.is_black_friday && data.promos && data.promos == 1) && (
          <div className="overlay-promo">
            <img src="https://cdn1.infocasas.com.uy/web/637c1914f3799_black-friday-iris.png" className="logo-promo" />
          </div>
        )}
        <CountryFlag flag={data.country?.flag} shadow border />

        {((operation === "sale" || !operation) && data.financial.commission.percent > 0) ?
          <PropertyAgentCommision commision={data.financial.commission.percent}
            old_before_promo={data.financial.commission.old_before_promo} promos={data.promos} /> :
          ((operation === "sale" || !operation)&& userState?.level !== ADMIN_LEVEL.toString() )?
            <PropertyAgentCommision commision={data.financial.commission.percent}
              old_before_promo={data.financial.commission.old_before_promo} promos={data.promos} />
            : ""
        }

        {
          (operation === 'rent' && data.financial.commision_rent > 0 ) && 
          <PropertyAgentCommision commision={data.financial.commision_rent} old_before_promo={0} promos={data.promos}  />
        }

        {!data.images.length && (
          <CustomImage src="https://cdn1.infocasas.com.uy/web/62ab96629ac14_default-img-property.png"
            alt={data.title}
            classes="w-100 h-100 static-image" />
        )
        }
        {fixed && !!data.images.length? (
          <CustomImage
            src={data.images[0]}
            width={310}
            height={150}
            alt={data.title}
            classes="w-100 h-100 static-image"
          />
        ) : (
          <PropertyGallery images={data.images} counter isCard />
        )}
      </div>
      <div className="property-card-body d-flex flex-column rounded align-items-start">
        <div className="property-tags">
          {isUnBlocked && (
            <div className="bg-primary text-white d-flex align-items-center py-1 px-2 rounded-1 mb-1">
              <span className="ms-1 text-uppercase fw-bold lh-1 fs-xxs">
                EXCLUSIVO {!isTransactional && ('NIVEL ' + data.level)}
              </span>
            </div>
          )}

          {!!data.is_vip && !isUnBlocked && (
            <div className="bg-primary text-white d-flex align-items-center py-1 px-2 rounded-1 mb-1">
              <span className="ms-1 text-uppercase fw-bold lh-1 fs-xxs">
                EXCLUSIVO
              </span>
            </div>
          )}

          {!!data.promos && data.promos == 1 && (
            <div className=" hover:text-black d-flex align-items-center py-1 px-2 rounded-1 mb-1 bg-warning">
              <IconStarFill width={9} height={10} />
              <span className="ms-1 text-uppercase fw-bold lh-1 fs-xxs ">
                PROMO
              </span>
            </div>
          )}
          
          {data.is_trend && (
            <div className="border-primary bg-primary-light d-flex align-items-center text-primary py-1 px-2 rounded-1 mb-1">
              <IconFire width={9} height={10} />
              <span className="ms-1 text-uppercase fw-bold lh-1 fs-xxs">
                Trending
              </span>
            </div>
          )}
          {data.handover_date_text && (
            <span className="tag-hand-over m-0 mb-1">{data.handover_date_text}</span>
          )}
          {data.financial.benefits && data.financial.benefits.length > 0 && (
            <div className="border border-primary d-flex align-items-center text-primary py-1 px-2 rounded-1 mb-1">
              <IconStarFill width={9} height={10} />
              <span className="ms-1 text-uppercase fw-bold lh-1 fs-xxs">
                Beneficios
              </span>
            </div>
          )}
        </div>
        {isBlocked ? (
          <Placeholder xs={5} className="text-light rounded my-2" />
        ) : (
          <p className="text-secondary property-card-location mb-0">
            {data.type && (data.type?.identifier == '2' ? translation.apartment : 
            (data.type?.identifier == '3' ? translation.lands : data.type.name))}{(data.type && data.zone) && ", "}
            {data.zone ? (data.country?.code === "CL" ? `Comuna ${data.zone.name}` : `${data.zone.name}`) : ''}
          </p>
        )}
        {isBlocked ? (
          <Placeholder xs={6} className="text-light rounded mb-2" />
        ) : (
          <p className="fw-bold text-secondary property-card-title lh-sm mb-1">
            {data.title}
          </p>
        )}
        {!isBlocked && data.type?.identifier !== '3' && (
          <div className="d-flex align-items-center justify-content-start w-100 flex-wrap h-35">
            <div className="text-black-50 lh-sm">
              <PropertyBedroom units={data.units} short />
            </div>
            <div className="text-black-50 ml-2 lh-sm">
              <PropertyMeters units={data.units} />
            </div>
          </div>
        )}
        <div className="flex-grow-1 pt-2 pb-2 d-flex flex-column justify-content-end w-100">
          <PropertyPrice units={data.units} garages={data.garages} currencyPrice={isRent ? data.rent_currency : data.sale_currency} isBlocked={isBlocked} propertyTypeId={data.type?.identifier} />
        </div>
        
        <PropertyCardAmenities
          hasBalcony={!!unitHasBalcony?.length ? true : false}
          hasAC={!!unitHasAC?.length ? true : false}
          hasElevator={data.has_elevator}
          hasGarage={data.has_garage}
          hasLaundry={data.has_laundry}
          hasSecurity={data.has_security}
          hasSwimmingPool={data.has_swimming_pool}
        />
        {
          download_brochure  &&
          <DownloadBrochure property={data}/>
        }
      </div>
    </div>
  );
};
