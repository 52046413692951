import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { OperationType } from "../../interfaces/property";
import { GarageType, UnitType } from "../../interfaces/project";
import { formatMoney } from "../../utils/Functions";
import useBreakpoint from "use-breakpoint";
import userFilter from "../../hooks/filter/useFilter";
import { BREAKPOINTS } from "../../utils/Constants";
import { Placeholder } from "react-bootstrap";
import useCountry from "../../hooks/config/useConfig";

export const PropertyPrice = ({ units = [], garages = [], currencyPrice = undefined, isCol = false, isBlocked = false, propertyTypeId, operation , setMinProjectPrice}
  : { units?: UnitType[], garages? : GarageType[], currencyPrice?: string, isCol?: boolean, isBlocked?: boolean, propertyTypeId: string, operation?: OperationType , setMinProjectPrice? : Function}) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");
  const [searchParams] = useSearchParams();
  const { filterState } = userFilter();
  const [currentMinPrice, setCurrentMinPrice] = useState(0);
  const [currentMaxPrice, setCurrentMaxPrice] = useState(0);
  const [filteredUnitByPrice, setFilteredUnitByPrice] = useState<UnitType|GarageType>({} as UnitType)
  const [bedroomText, setBedroomText] = useState("");
  const [validFloat, setValidFloat] = useState("");
  const { translation } = useCountry();

  useEffect(() => {
    if (filterState.minPrice) setCurrentMinPrice(filterState.minPrice)
    else setCurrentMinPrice(0)

    if (filterState.maxPrice) setCurrentMaxPrice(filterState.maxPrice)
    else setCurrentMaxPrice(0)
  }, [searchParams]);

  useEffect(() => {

    let items : UnitType[] | GarageType[] = []
    items = [...units]
    
    
    if (operation) {
      units = [...units.filter(u => u.operation_type == operation)]
      items = [...units.filter(u => u.operation_type == operation)]
    }

    if((!items || items.length == 0) && garages && garages.length > 0 ){
      items = garages
    } 


    let orderedUnit : any[] = [...items];
    let res = "";

    if (filterState.minPrice) {
      // @ts-ignore

      orderedUnit = items.sort((a, b) => Number(a.price_by_project_currency) - Number(b.price_by_project_currency))
    }

    if (filterState.maxPrice && !filterState.minPrice) {
      // @ts-ignore

      orderedUnit = items.sort((a, b) => Number(b.price_by_project_currency) - Number(a.price_by_project_currency))
    }

    if (filterState.order) {
      if (filterState.order?.includes("maxPrice")) {
        orderedUnit = orderedUnit.sort((a, b) => Number(b.price_by_project_currency) - Number(a.price_by_project_currency));
      }

      if (filterState.order?.includes("minPrice")) {
        orderedUnit = orderedUnit.sort((a, b) => Number(a.price_by_project_currency) - Number(b.price_by_project_currency));
      }
    }

    if (filterState.minPrice || filterState.maxPrice) {
      if (units.length > 0) {
        if (units[0].bedrooms === units[units.length - 1].bedrooms) { // Si es al unica unidad que hace fit con el precio
          res += getBedroomText(units[0].bedrooms);
        } else { // La primera unidad que hace fit con el filtro
          res += units[0].bedrooms > 0 ? `${units[0].bedrooms}` : translation.abbrStudio;
        }
        if (units[0].bedrooms < units[units.length - 1].bedrooms) { // La última unidad que hace fit con el filtro
          res += " a ";
          res += getBedroomText(units[units.length - 1].bedrooms);
        }
      }
    }

    
    if (!filterState.minPrice &&
      !filterState.maxPrice &&
      !filterState.order?.includes("minPrice") &&
      !filterState.order?.includes("maxPrice")
    ) {
      
      orderedUnit = items.sort((a, b) => Number(a.price_by_project_currency) - Number(b.price_by_project_currency))
    }

    let unitToShow : any = orderedUnit[0]
    
    const float = orderedUnit[0] && 'm2' in orderedUnit[0] ? (orderedUnit[0]?.m2 ?? 0).toString().replace(",", ".") : '0'
    
    setBedroomText(res);
    if(unitToShow){
      let minProjectPrice = {currency : 'USD', price : 0}
      if(currencyPrice){
        minProjectPrice.currency = currencyPrice
        minProjectPrice.price = formatMoney(unitToShow.price_by_project_currency)
      }else{
        minProjectPrice.currency = unitToShow.currency
        minProjectPrice.price = parseFloat(unitToShow.price.toString())
      }
      if(setMinProjectPrice) setMinProjectPrice(minProjectPrice)
      
      setFilteredUnitByPrice(unitToShow)
    }
    
    setValidFloat(float)
  }, [units]);

  const getBedroomText = (count: number) => {
    return count > 0
      ? count > 1
        ? `${count} ${translation.short_bedroom}`
        : `1 ${translation.short_bedroom}`
      : translation.abbrStudio;
  };

  if (filteredUnitByPrice == null) return null; // retornar 404, validar cuando no haya rango ejemplo desde 10.000

  return (
    <div className={"property-price " + (breakpoint === "xs" ? "order-1" : "")}>
      <div className="price d-flex flex-column w-100">
        <small className="property-units-price text-black-50 lh-sm fw-normal ">
          {currentMinPrice != 0 || currentMaxPrice != 0
            ? `${bedroomText} ${currentMinPrice != 0
              ? "desde"
              : "hasta"
            }`
            : propertyTypeId === "3" ? "Lotes desde" : (propertyTypeId === '8' ? `${translation.garage}s desde` : "Unidades desde")
          }
        </small>
        <div className={(!isCol ? "d-flex align-items-baseline justify-content-between w-100 flex-wrap" : "")}>
          {isBlocked ? (
            <Placeholder xs={5} className="text-light rounded mt-2" />
          ) : (
            <div className="price text-secondary fw-bold lh-1 mb-1">
              {currencyPrice ?
                <>{currencyPrice} {formatMoney(filteredUnitByPrice.price_by_project_currency)}</> :
                <>{filteredUnitByPrice.currency} {formatMoney(filteredUnitByPrice.price)}</>
              }
            </div>
          )}
          {/* {isBlocked ? (
            <Placeholder xs={4} className="text-light rounded" />
          ) : (
            <div className="price-per-meter text-black-50 lh-1">
              {currencyPrice ?
                <>{currencyPrice} {formatMoney(Math.round(Number(filteredUnitByPrice.priceByProjectCurrency) / Number(validFloat)))}</> :
                <>{filteredUnitByPrice.currencyPrice} {formatMoney(Math.round(Number(filteredUnitByPrice.price) / Number(validFloat)))}</>
              }
              /m²
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};
