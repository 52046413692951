import { Col, Container, Row } from "react-bootstrap";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { formatMoney } from "../../utils/Functions";

export const CountUpStatistics = () => {
  const statistics = [
    {
      preCount: "Más de",
      count: 11100,
      title: "unidades disponibles para ventas",
      format: (c: number) => `${formatMoney(c)}`,
    },
    {
      preCount: "Más de USD",
      count: 10500000,
      title: "en comisiones",
      format: (c: number) => `${formatMoney(c)}`,
    },
    {
      preCount: "Plataforma",
      count: 100,
      title: "actualizada en tiempo real",
      format: (c: number) => `${c}%`,
    },
  ];
  return (
    <Container className="py-5 count-up">
      <Row className="gx-md-3 gx-lg-5 mb-4 gy-4 justify-content-center">
        {statistics.map((item, index) => (
          <Col sm={6} lg={4} key={"counup_" + index}>
            <VisibilitySensor partialVisibility offset={{ bottom: 0 }}>
              {({ isVisible }) => (
                <div className="w-100 d-flex flex-column align-items-center text-center">
                  <p className="text-black-50 lh-md fw-500 text-uppercase mb-0">
                    {item.preCount}
                  </p>
                  <div style={{ height: "1rew" }}>
                    {isVisible ? (
                      <CountUp
                        separator="."
                        end={item.count}
                        formattingFn={item.format}
                        className="display-2 text-secondary fw-bold"
                      />
                    ) : null}
                  </div>
                  <p
                    className="text-black-50 fw-500 text-uppercase mt-1"
                    style={{ maxWidth: 200 }}
                  >
                    {item.title}
                  </p>
                </div>
              )}
            </VisibilitySensor>
          </Col>
        ))}
      </Row>

      <hr className="border-bottom-light" />
    </Container>
  );
};
