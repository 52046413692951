import { Placeholder, Card } from "react-bootstrap"

export const SkeletonFeedCard = () => {
    return (
        <Card className="post-card border-0 rounded bg-white mb-3 shadow-sm p-3">
            <Card.Header className="bg-white border-0 pb-3 pt-0 px-0">
                <div className="user-img-loader"></div>
                <Placeholder as="div" animation="glow"><Placeholder xs={3} /></Placeholder>
                <Placeholder as="div" animation="glow"><Placeholder xs={5} /></Placeholder>
                <Placeholder as="div" animation="glow"><Placeholder xs={1} /></Placeholder>
            </Card.Header>
            <div className="post-ppl-img mb-3"></div>
            <Placeholder as="div" animation="glow">
                <Placeholder xs={12} /> <Placeholder xs={4} />
            </Placeholder>
            <Placeholder as="div" animation="glow"><Placeholder xs={3} /></Placeholder>
        </Card>
    )
}