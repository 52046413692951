import { AppraisalForm } from '../../../fragments/admin/appraisals/AppraisalForm'
import { SaleForm } from '../../../fragments/admin/sales/SaleForm'

const AdminEditAppraisal = () =>{

    return (
        <main className='p-5'>
            <AppraisalForm type='edit' />
        </main>
    )
}
export default AdminEditAppraisal