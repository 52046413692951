import { Col, Row } from "react-bootstrap";
import { ProjectFinancialType } from "../../interfaces/project";
import { IconCheckCircleFill } from "../CustomIcons/IconCheckCircleFill";
import { IconXCircleFill } from "../CustomIcons/IconXCircleFill";
import { CustomImage } from "../CustomImage/CustomImage";
import { CountryFeatures } from "../../interfaces/config";

export const PropertyFinancial = ({ financial, isInPdf = false , propertyCountryFeatures }: 
  { financial: ProjectFinancialType, isInPdf?: boolean , propertyCountryFeatures? : CountryFeatures | null }) => {

  return (
    <Row className="d-flex">
      <h3 className={"fs-4 " + (isInPdf ? " fw-bold text-secondary" : "fw-normal mb-3")}>Financiación</h3>

      {!financial.has_bank_financial && !financial.has_own_financial && (
        <Col xs={12}>
          <p className={"text-light-secondary m-0 " + (isInPdf ? "" : "mb-3")}>
            <i className={"bi me-2 bi-x-circle-fill " + (isInPdf ? "text-secondary" : "text-light-secondary")}></i> No
            acepta financiacion bancaria
          </p>
        </Col>
      )}

      {(!!financial.has_own_financial || !!financial.has_bank_financial) && (
        <Col xs={12}>
          <Row className="d-flex align-items-center justify-content-between py-2">
            <Col lg={!!financial.bank_images?.length ? 6 : 12} xs={12}>
              {
                !propertyCountryFeatures?.hide_own_financing && 
                <p className={"text-dark m-0 " + (isInPdf ? "" : "mb-2")}>
                  {
                    !!financial.has_own_financial ?
                      <IconCheckCircleFill width={16} height={16} className={`me-2 mb-1 ${isInPdf ? "text-secondary" : "text-primary"}`} /> :
                      <IconXCircleFill width={16} height={16} className={`text-light-secondary me-2 mb-1 ${isInPdf ? "text-secondary" : "text-light-secondary"}`} />
                  }

                  Cuenta con financiación propia
                </p>
              }
              
              <p className={"text-dark m-0 " + (isInPdf ? "" : "mb-2")}>
                {
                  !!financial.has_bank_financial ?
                    <IconCheckCircleFill width={16} height={16} className={`me-2 mb-1 ${isInPdf ? "text-secondary" : "text-primary"}`} /> :
                    <IconXCircleFill width={16} height={16} className={`text-light-secondary me-2 mb-1 ${isInPdf ? "text-secondary" : "text-light-secondary"}`} />
                }

                Acepta financiación bancaria
              </p>
              {(!propertyCountryFeatures?.hide_bank_financing_info && !!financial.bank_financial_information?.quota) && 
                <p className="text-light-secondary m-0 small mb-3 ps-4">
                { financial.bank_financial_information?.quota != 0 &&
                  `Hasta ${financial.bank_financial_information?.quota} años post ocupación.`
                }
                { financial.bank_financial_information?.description != "" &&
                  `Valor máx. ${financial.bank_financial_information?.description}`
                }
              </p>
              }
              
            </Col>
            <Col lg={6} className="text-lg-end d-dlex flex-row">
              { financial.bank_images?.map((d, index) => {
                return (
                  d.image!='' && <CustomImage
                    src={d.image}
                    alt={d.name}
                    width={30}
                    height={30}
                    key={"developer_" + index}
                    classes={"ms-3 my-1 developer-image " + (d.name === "BBVA" ? "financial-img-bbva" : ( d.name == 'Itaú' ? "financial-img-itau": "financial-img-santander"))}
                  />
                  
                )
              })}
            </Col>
          </Row>
        </Col >
      )}
    </Row >
  );
};
