import { Link } from "react-router-dom";
import useUser from "../hooks/user/useUser";

const Admin = () =>{
    const {userState} = useUser()
    return (
        <main className="text-center">
            <div className="main-content">
                <h2>Bienvenido, {userState.name}</h2>   
                <div className="admin-sections m-auto pt-5 d-flex justify-content-center align-items-center">
                <div className="admin-section flex-1 m-2">
                    <Link to={'notifications'} >Notificaciones </Link>
                </div>
                <div className="admin-section flex-1 m-2">
                    <Link to={'sales'} >Ventas </Link>
                </div>
                <div className="admin-section flex-1 m-2">
                    <Link to={'appraisals'} >Tasaciones </Link>
                </div>
                <div className="admin-section flex-1 m-2">
                    <Link to={'services/notarial'} >Servicios Notariales </Link>
                </div>
                </div>            
            </div>
            
        </main>
    )
}
export default Admin