import { useState } from "react";
import { cleanHtmlFromString } from "../../utils/Functions";

export const NeighbourhoodDescription = ({
  description,
}: {
  description?: string;
}) => {
  const [collapse, setCollapse] = useState(true);

  return (
    <div className={`neighbourhood-description  ${collapse ? "" : "expand"}`}>
      <p
        className="lh-sm fw-light fs-sm"
        dangerouslySetInnerHTML={{
          __html: cleanHtmlFromString(description ?? "", true),
        }}
      ></p>

      <span
        role={"button"}
        onClick={() => setCollapse(!collapse)}
        className="more bg-xs-light fw-normal d-flex align-items-baseline justify-content-between"
      >
        {collapse && "... "}
        <span className="text-primary fs-sm fw-500">
          {collapse ? "Más" : "Menos"}
        </span>
      </span>
    </div>
  );
};
