import {  useState } from "react"
import { DataAYC, DataVTP, ValidateDataRequest, ValidateDataResponse } from "../../interfaces/appraisal"
import { FormAYC } from "./FormAYC"
import { FormVTP } from "./FormVTP"
import { useAppraisalFetch } from "../../hooks/fetch/useAppraisalFetch"

export const StepTwo = ( 
    { data , setData , setCurrentStep} : 
    { data? : ValidateDataRequest,setData : Function , setCurrentStep : Function}) => {

    const {validateData } = useAppraisalFetch()
    const [errorMessage , setErrorMessage] = useState('')
    const [loading , setLoading] = useState(false)

    const setValidForm = (formData : DataVTP) => {    

        setErrorMessage('')

        if(formData){
            
            //LLamar validación 
            if(data){
                
                const sendData = {
                    type : data.type,
                    data : formData,
                    appraisal_id : data.appraisal_id
                }

                setLoading(true)
                validateData(sendData).then((response : ValidateDataResponse) => {
                    setLoading(false)
                    if(response.success){
                        if(response.created_id){
                            setData((prev :ValidateDataRequest) => ({...prev,appraisal_id : response.created_id}))
                        }
                        setCurrentStep((prev : number) => prev + 1)
                    }else{
                        setErrorMessage(response.message)
                    }
                }).catch(err => {
                    setLoading(false)
                })
            }
        }
    }

    return (
        <div className="appraisal-form">
            <h2>Ingresa los datos</h2>
            {
                data?.type == 'AYC' && 
                <FormAYC  setValidForm={setValidForm} setData={setData} errorMessage={errorMessage} dataVTP={data.data} setCurrentStep={setCurrentStep} loading={loading} />
            }

            {
                data?.type == 'VTP' && 
                <FormVTP setValidForm={setValidForm} setData={setData} errorMessage={errorMessage} dataVTP={data.data} setCurrentStep={setCurrentStep} loading={loading}/>
            }

            
            
        </div>
    )
}