
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Course } from "../../interfaces/academy";
import { Link } from "react-router-dom";
import useConfig from "../../hooks/config/useConfig"

export const CourseFinancialSection = ({ course,  }: {
  course: Course
}) => {
  
  const {countryFeatures} = useConfig()

  return (
    <>
    {
      course.link && 
      <div  className="financial-section">
        {
        course.link_type == 'REDIRECCIÓN' ? 
        (<Link to={course.link} target="_blank" >
        <button className="btn btn-primary"> Consíguelo Aquí </button>
        </Link>)
         :
        <Row>
          <h3 className="fs-4 fw-normal mb-3">Financiación</h3>
          <p>1. Realiza el pago:
            <Link to={course.link} target="_blank">
              <Button className="ms-2" variant="primary">Paga Aquí</Button>
            </Link>
            </p>
            
          <p className="mt-1">2. Envíanos la captura: 
            <Link to={countryFeatures.contact_wame || ''} target="_blank" >
            <button className="btn btn-green border-color-green bg-white  ml-2 mx-2" >
              Aquí <i className="bi bi-whatsapp"></i> 
            </button>
            </Link>
            y te brindaremos el curso.
          </p>

        </Row>
      }

      </div>
      
      
    }
      
    </>
  );
};