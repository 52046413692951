import useBreakpoint from "use-breakpoint";
import { Col, Container, Row } from "react-bootstrap";
import { BREAKPOINTS } from "../../utils/Constants";
import { CustomImage } from "../../components/CustomImage/CustomImage";

export const HowItWork = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");

  const items = [
    {
      image:
        "https://cdn1.infocasas.com.uy/web/62bc662c04666_hiw_oportunidades.png",
      title: "Oportunidades",
      text: "Con una base de datos de <b>más de 11.100 unidades en 5 países distintos</b> (Uruguay, Paraguay, Colombia, Chile y Panamá) te acercamos las mejores oportunidades de venta de obra nueva: <b>en pozo, en obra o a estrenar.</b>",
    },
    {
      image:
        "https://cdn1.infocasas.com.uy/web/62bc662bebed2_hiw_transparencia.png",
      title: "Transparencia",
      text: "Te proveemos de renders, fotos, planos, promociones, detalles del desarrollo y de compra/venta del proyecto y las unidades.<b>Todos los profesionales inmobiliarios tendran acceso a toda la información</b> para vender desde sus propios canales",
    },
    {
      image:
        "https://cdn1.infocasas.com.uy/web/62bc662be57d1_hiw_gestion.png",
      title: "Gestión",
      text: "Agilizamos la <b>gestión de las reuniones para visitar los desarrollos</b> unificando todo en un mismo canal de comunicación y te ofrecemos un <b>sistema de seguimiento de contactos</b> para garantizar que tus clientes siempre serán respetados y reconocidos.",
    },
  ];

  return (
    <Container className={"home-how-it-work py-5 " + (breakpoint === "xs" ? "px-4" : "")}>
      <Row className="mb-3">
        <Col>
          <h3 className="fs-3 fw-bold">Conoce cómo funciona Iris</h3>
        </Col>
      </Row>
      <Row className="gx-md-3 gx-lg-5 gy-5">
        {items.map((item, index) => (
          <Col
            key={"how_" + index}
            md={4}
            className="d-flex flex-column text-center text-lg-start"
          >
            <div className="ratio ratio-1x1 mb-3">
              <CustomImage
                classes="w-100"
                src={item.image}
                alt={item.title}
                width={500}
                height={500}
              />
            </div>
            <h4 className="fs-5 fw-bold text-primary mb-3">{item.title}</h4>
            <small
              className="lh-md"
              dangerouslySetInnerHTML={{ __html: item.text }}
            ></small>
          </Col>
        ))}
      </Row>
    </Container>
  );
};
