import { useEffect, useState } from "react"
import { useAcademyFetch } from "../fetch/useAcademyFetch"
import { Course } from "../../interfaces/academy"
import useConfig from "../config/useConfig"

export const useAcademy = () => {


    const {getCoursesEndpoint } = useAcademyFetch()
    const {config} = useConfig()
    const [ courses, setCourses ] = useState<Course[]>([])


    useEffect(()=> {
        
        if(config?.country?.id){
            
            getCoursesEndpoint(config?.country?.id).then(data => {
                if(data.success){
                    setCourses(data.data)
                }
            }).catch(err => console.log(err))
        }
        

    },[config])

    return {

        courses
    }
}