export const VirtualTour: React.FC<{ url: string | undefined; isModal?: boolean; }> = ({
	url, isModal
}) => {
	return (
		<>
			{!isModal && <div className="cover-iframe w-100 h-100 position-absolute"></div>}
			<iframe
				width="100%"
				height="100%"
				src={url}
				frameBorder="0"
				allowFullScreen
				allow="xr-spatial-tracking"
			></iframe>
		</>
	)
};