import { Col, Container, Row } from "react-bootstrap";
import { FeedBenefitsCard } from "../../components/FeedBenefitsCard/FeedBenefitsCard";
import { BREAKPOINTS } from "../../utils/Constants";
import useBreakpoint from "use-breakpoint";
import useUser from "../../hooks/user/useUser";
import useConfig from "../../hooks/config/useConfig"

export const Benefits = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");
  const { isLoggedIn } = useUser();
  const {countryFeatures,translation} = useConfig()

  const benefits = [
    {
      title: "PRÉSTAMOS HIPOTECARIOS",
      badge: "https://cdn3.infocasas.com.uy/web/62cd6d69be203_mortgagebadge.png",
      image: breakpoint === "xs" ? "https://cdn1.infocasas.com.uy/web/63efc74f66400_credit-mobile.png" : "https://cdn1.infocasas.com.uy/web/63efc74f698fd_credit-desktop.png",
      text: "¿Tenés un <strong>cliente potencial</strong>? Derivá sus datos y <strong>recibí una comisión</strong> al cierre del negocio",
      type: "mortgage",
      visible : countryFeatures.mortgageLoan
    },
    {
      title: translation.rent_warranties,
      badge: "https://cdn3.infocasas.com.uy/web/6361609bec59e_seguros.png",
      image: breakpoint === "xs" ? "https://cdn1.infocasas.com.uy/web/63efc74f5fc86_warranty-mobile.png" : "https://cdn1.infocasas.com.uy/web/63efc74f5ab14_warranty-desktop.png",
      text: `Fácil, rápido y seguro. <strong>${translation.warranty_earning}</strong> por cada ${translation.rent_warranty} que derives`,
      type: "warranty",
      visible : countryFeatures.rentWarranty
    },
    {
      title: "MIS ESTADOS DE "+translation.warranty+"S",
      badge: "https://cdn3.infocasas.com.uy/web/6361609bec59e_seguros.png",
      image: breakpoint === "xs" ? "https://cdn1.infocasas.com.uy/web/64b95492b0536_img-iris-familia-garantias-a.jpg" : "https://cdn1.infocasas.com.uy/web/64b95492b0536_img-iris-familia-garantias-a.jpg",
      text: "Consultá <strong>ahora mismo</strong> el listado de clientes con <strong>"+translation.rent_warranties+"</strong> en proceso",
      type: "warranty_status",
      visible : countryFeatures.rentWarrantyPanel
    },
    {
      title: "SERVICIOS NOTARIALES",
      image: "https://cdn1.infocasas.com.uy/web/66a12bfca974b_estudio360x2.png",
      text: "¿Tenés un <strong>cliente potencial</strong>? Derivá sus datos y <strong>recibí una comisión</strong> al cierre del negocio",
      type: "notarial",
      visible : countryFeatures.notarial_service
    },
  ]

  return (
    <Container className={`${!isLoggedIn ? "pb-5" : "" } count-up`}>
      <h3 className="fs-3 fw-bold mb-5">Servicios para agentes</h3>

      <Row className="gx-md-3 gx-lg-4 mb-4 gy-4 justify-content-center">
        {benefits.filter(b=> !!b.visible).map((benefit, index) => (
          <Col sm={12} md={12} lg={6} key={"counup_" + index}>
            <FeedBenefitsCard benefit={benefit} source="home" />
          </Col>
        ))}
      </Row>
    </Container>
  );
};
