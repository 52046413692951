import { useEffect, useState } from "react"
import { ProjectType, UnitType } from "../../interfaces/project"
import { formatMoney } from "../../utils/Functions"
import useConfig from '../../hooks/config/useConfig'
import { FeaturesTop } from "./components/FeaturesTop"
import { Payment } from "./components/Payment"
import { Features } from "./components/Features"
import { UnitPlan } from "./components/UnitPlan"
import { SmallFeatures } from "./components/SmallFeatures"
import { PaginationPDF } from "./components/PaginationPDF"
import { PROPERTIES_VALUES } from "../../utils/Constants"
import { EstimatorData } from "./Estimator"

const {LAND_TYPE , APARTMENT_TYPE} = PROPERTIES_VALUES

export const EstimationGenerated = (
    {
        project,
        allData,
        unit,
        pie,
        price,
        unit_plan_encoded,
        inmo_logo_encoded,
        pageHeight,
        currency,
        exchange_rate
    }: {
        project: ProjectType,
        allData : EstimatorData,
        unit: UnitType,
        pie: number,
        price: number,
        unit_plan_encoded: string,
        inmo_logo_encoded: string,
        pageHeight: number,
        currency : string,
        exchange_rate? : number

    }
) => {

    const [data, setData] = useState({
        pie_amount: 0,
        financing_amount: 0,
        financing_percentage: 0
    })
    const { translation } = useConfig()
    const typeName = project.type?.identifier == APARTMENT_TYPE ? translation.apartment : project.type.name
    const isLand = project.type?.identifier === LAND_TYPE;
    const showOutdoorM2 = !isLand && ((project.countryFeatures?.hide_m2_outdoor && unit.m2_outdoor && unit.m2_outdoor > 0) || !project?.countryFeatures?.hide_m2_outdoor)
    const [total, setTotal ] = useState(0)


    useEffect(() => {

        const percentageAmount = 100 - pie
        const garagePrice = parseFloat(allData.garage_price) || 0
        const warehousePrice = parseFloat(allData.warehouse_price) || 0
        const totalFinancing : number = price + garagePrice + warehousePrice
        const financingAmount = (percentageAmount * totalFinancing) / 100
        const pieAmount = (pie * totalFinancing) / 100
        setTotal(totalFinancing)

        setData(prev => (
            {
                ...prev,
                pie_amount: pieAmount,
                financing_amount: financingAmount,
                financing_percentage: percentageAmount
            }
        ))
    }, [pie,price,allData.garage_price , allData.warehouse_price])

    //A4 pixel dimensions: 793.706 x 1,122.52 pixels
    
    return (
        <div id="cotizacion-generada p-4" style={{ fontSize: 14 }}
            className="estimation-generated" >

            <div className="page-1 position-relative" style={{ height: pageHeight, padding: 50 }}>
                

                <FeaturesTop project={project} unit={unit} inmo_logo_encoded={inmo_logo_encoded} />

                <div style={{ marginTop: 30 }}>
                    <Features project={project} typeName={typeName} unit={unit} />
                </div>

                <div style={{ marginTop: 30 }}>
                    <p className="fw-bold mt-2">II. PRECIOS</p>
                    <div className="px-4">
                        <p>{typeName} {unit.number} : {currency} {formatMoney(price)}</p>
                        {
                            allData.garage && <p>{translation.garage} {allData.garage?.name} : {currency} {formatMoney(allData.garage_price)}</p>
                        }
                        {
                            allData.warehouse && <p>{translation.warehouse} {allData.warehouse?.name} : {currency} {formatMoney(allData.warehouse_price)}</p>
                        }
                        
                    </div>

                </div>

                <div style={{ marginTop: 30 }} >
                    <Payment 
                    project={project} 
                    pie={pie} 
                    financing_amount={data.financing_amount} 
                    financing_percentage={data.financing_percentage} 
                    pie_amount={data.pie_amount} 
                    currency={currency} 
                    exchange_rate={exchange_rate}
                    total={total}/>
                </div>

                <PaginationPDF page={1} total={2} />
                
            </div>


            <div className="page2 position-relative" style={{ height: pageHeight, padding: 50 }}>
                <FeaturesTop project={project} unit={unit} inmo_logo_encoded={inmo_logo_encoded} />
                
                <UnitPlan unit_plan_encoded={unit_plan_encoded} pageHeight={pageHeight} />

                <div className="mt-4">
                    <SmallFeatures project={project} unit={unit} showOutdoorM2={showOutdoorM2} isLand={isLand} />
                </div>
                <PaginationPDF page={2} total={2} />
            </div>

        </div>
    )
}