export interface WarrantyInsurance {
    form_id: number,
    label: string
}

const ID_FORM_MAPFRE = 455;
const ID_FORM_PORTO = 21;
const ID_FORM_SURA = 1149;
const ID_FORM_AFFI = 1186;
const ID_FORM_UNIFIANZA = 1187;
const ID_FORM_AFIARRIENDOS = 1188;
const ID_FORM_FIANZAS = 1197;
const ID_FORM_FIANZA_PROFESIONAL = 1198;
const ID_FORM_SURA_CO = 1199;
const ID_FORM_ALUCERTO_CL = 1558;
const ID_FORM_ALUCERTO_PY = 1557;
const ID_FORM_ALUCERTO_UY = 1556;

export const countryInsurances: { [key: string]: WarrantyInsurance[] } = {
    'UY': [
        {
            form_id: ID_FORM_MAPFRE,
            label: 'Mapfre'
        },
        {
            form_id: ID_FORM_PORTO,
            label: 'Porto Seguro'
        },
        {
            form_id: ID_FORM_SURA,
            label: 'Sura Seguros'
        },
        {
            form_id: ID_FORM_ALUCERTO_UY,
            label: 'Alucerto'
        }
    ],
    'CO' : [
        // {
        //     form_id: ID_FORM_AFFI,
        //     label: 'Affi'
        // },
        // {
        //     form_id: ID_FORM_UNIFIANZA,
        //     label: 'Unifianza'
        // },
        {
            form_id: ID_FORM_AFIARRIENDOS,
            label: 'AFIANCOL'
        },
        // {
        //     form_id: ID_FORM_FIANZAS,
        //     label: 'Fianzas de Bogotá S.A.S'
        // },
        // {
        //     form_id: ID_FORM_FIANZA_PROFESIONAL,
        //     label: 'Fianza Profesional S.A'
        // },
        {
            form_id: ID_FORM_SURA_CO,
            label: 'Sura'
        },
    ],
    'PY' : [
        {
            form_id: ID_FORM_ALUCERTO_PY,
            label: 'Alucerto'
        }
    ],
    'CL' : [
        {
            form_id: ID_FORM_ALUCERTO_CL,
            label: 'Alucerto'
        }
    ]
}