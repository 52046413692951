import { ReactNode } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { formatMoney } from "../../utils/Functions";
import { PropertyFavorite } from "../PropertyFavorite/PropertyFavorite";
import { PropertyFormButton } from "../PropertyFormButton/PropertyFormButton";
import useBreakpoint from "use-breakpoint";
import { BREAKPOINTS } from "../../utils/Constants";
import { ProjectType } from "../../interfaces/project";

export const PropertyModal = ({
  property,
  show,
  onClose,
  children,
  className = "",
  minProjectPrice
}: {
  show: boolean;
  onClose: () => void;
  children: ReactNode;
  property: ProjectType;
  className?: string;
  minProjectPrice? : { currency : string , price : number}
}) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");

  return (
    <Modal
      fullscreen
      show={show}
      onHide={onClose}
      className={`property-modal text-white ${className}`}
      backdropClassName="property-modal-backdrop"
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        className="border-bottom-0"
      >
        <Modal.Title className="lh-1">
          <h2 className="fs-4 my-0 lh-1">{property.title}</h2>
          {
            minProjectPrice && 
            <span className="fs-xs lh-1 text-uppercase fw-light">
            Desde {minProjectPrice?.currency} {" "}
            {formatMoney(minProjectPrice.price)}{" "}
            {property.zone && `· ${property.zone.name}`}
          </span>
          }
          
        </Modal.Title>
        <Row className="flex-grow-1 justify-content-end d-flex me-0 me-lg-2">
          <>
            <Col xs="auto">
              <PropertyFavorite id={property.id} />
            </Col> 
              <Col xs="auto" className="d-none d-lg-block">
              <PropertyFormButton property={property} type="OFFER" />
            </Col>
          </>
          
        </Row>
      </Modal.Header>
      <Modal.Body className={breakpoint === 'xs' ? "px-0" : ""}>{children}</Modal.Body>
      <Modal.Footer className="bg-white d-lg-none px-1 py-2">
        <Row className="gx-2 w-100">
          <Col xs="6">
            <PropertyFormButton property={property} type="OFFER" />
          </Col>
          <Col xs="6">
            <PropertyFormButton property={property} type="SCHEDULE"/>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};
