import { useState } from "react"
import { Alert, Col, Form, Row } from "react-bootstrap"
import { DataVTP, ValidateDataRequest } from "../../interfaces/appraisal"
import useUser from "../../hooks/user/useUser"
import SpecialSelect, { OptionSelectType } from "../ui/SpecialSelect"
import Lottie from "lottie-react";
import GrayLoader from "../../lotties/gray-loader.json";

export const FormVTP = (
    { dataVTP, setValidForm, setData, errorMessage, setCurrentStep , loading}:
        { dataVTP?: any, setValidForm: Function, setData: Function, errorMessage: string, setCurrentStep: Function,loading : boolean }
) => {

    const { userState } = useUser()
    const [validated, setValidated] = useState(false);

    const defaultData: DataVTP = {
        corredor: {
            rut: '',
            nombre: userState.name,
            email : userState.email
        },
        cliente: {
            rut: '',
            nombre: '',
            telefono: '',
            email: ''
        },
        propiedad: {
            tipo: '1',
            comuna: '',
            calle: '',
            complemento: '',
            numero: '',
            nbanos: null,
            ndormitorios: null,
            nrodepto: '',
            cntbodegas: 0,
            cntestacionamientos: 0,
            mtconstruidos: 0,
            mttotal: 0
        },
        tipoinforme: 1
    }

    const initialData = dataVTP || defaultData


    const [formData, setFormData] = useState<DataVTP>(initialData)
    const [invalidCommune, setInvalidCommune] = useState(false)

    const comunnes = [
        'Santiago', 'Conchalí', 'Huechuraba', 'Independencia', 'Quilicura', 'Recoleta', 'Renca', 'Las Condes', 'Lo Barnechea',
        'Providencia', 'Vitacura', 'La Reina', 'Macul', 'Ñuñoa', 'Peñalolen', 'La Florida', 'La Granja', 'El Bosque', 'La Cisterna',
        'La Pintana', 'San Ramón', 'Lo Espejo', 'Pedro Aguirre Cerda', 'San Joaquin', 'San Miguel', 'Cerrillos', 'Estacion Central',
        'Maipu', 'Cerro Navia', 'Lo Prado', 'Pudahuel', 'Quinta Normal'
    ]

    const comunnesOptions: OptionSelectType[] = comunnes.map(c => ({ label: c, value: c }))

    const setValueCorredor = (e: any) => {
        let newValue = e.target.value
        setFormData((prev) => ({ ...prev, 'corredor': { ...prev.corredor, [e.target.name]: newValue } }))
    }

    const setValueCliente = (e: any) => {
        let newValue = e.target.value
        setFormData((prev) => ({ ...prev, 'cliente': { ...prev.cliente, [e.target.name]: newValue } }))
    }
    const setValuePropiedad = (e: any) => {
        let newValue = e.target.value
        setFormData((prev) => ({ ...prev, 'propiedad': { ...prev.propiedad, [e.target.name]: newValue } }))
    }
    const setValueCommune = (e: any) => {
        let newValue = e.value
        if(newValue?.length) setInvalidCommune(false)
        setFormData((prev) => ({ ...prev, 'propiedad': { ...prev.propiedad, comuna: newValue } }))
    }

    const handleSubmit = (event: any) => {

        event.preventDefault()

        window.scrollTo({ top: 0, behavior: 'smooth' });

        let form = event.currentTarget;
        setValidated(true)
        let validForm = form.checkValidity()

        if (!formData.propiedad.comuna) {
            setInvalidCommune(true)
            return;
        }

        if (validForm) {
            //Llamar validación externa

            setData((prev: ValidateDataRequest) => ({ ...prev, data: formData }))
            setValidForm(formData)

        }
    }

    const prev = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setCurrentStep((prev: number) => prev - 1)
    }


    return (
        <div className="p-3 text-start vtp-form">

            <Form onSubmit={handleSubmit} noValidate validated={validated}>
                <Row >
                    <h3>Corredor</h3>
                    <Col sm="12" lg='6'>
                        <Form.Group controlId="corredor_nombre">
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                value={formData.corredor.nombre}
                                name="nombre"
                                onChange={setValueCorredor}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Campo requerido
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm="12" lg='6'>
                        <Form.Group controlId="corredor_rut">
                            <Form.Label>RUT</Form.Label>
                            <Form.Control
                                name="rut"
                                value={formData.corredor.rut}
                                onChange={setValueCorredor}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Campo requerido
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>


                    <h3>Cliente</h3>

                    <Col sm="12" lg='6'>
                        <Form.Group controlId="cliente_nombre">
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                value={formData.cliente.nombre}
                                name="nombre"
                                onChange={setValueCliente}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Campo requerido
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm="12" lg='6'>
                        <Form.Group controlId="cliente_rut">
                            <Form.Label>RUT</Form.Label>
                            <Form.Control
                                value={formData.cliente.rut}
                                name="rut"
                                onChange={setValueCliente}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Campo requerido
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm="12" lg='6'>
                        <Form.Group controlId="cliente_telefono">
                            <Form.Label>Teléfono</Form.Label>
                            <Form.Control
                                value={formData.cliente.telefono}
                                name="telefono"
                                onChange={setValueCliente}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Campo requerido
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm="12" lg='6'>
                        <Form.Group controlId="cliente_email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                value={formData.cliente.email}
                                name="email"
                                type="email"
                                onChange={setValueCliente}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Campo requerido
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <h3>Propiedad</h3>


                    <Col sm="12" lg='6'>
                        <Form.Group controlId="comuna">
                            <Form.Label>Comuna</Form.Label>
                            <SpecialSelect
                                options={comunnesOptions}
                                onChange={setValueCommune}
                                selected={{ label: formData.propiedad.comuna, value: formData.propiedad.comuna }}
                                setInvalid={invalidCommune}
                            />
                            {
                                invalidCommune &&
                                <div className="invalid-feedback d-block">Elija una comuna</div>

                            }
                            <Form.Control.Feedback type="invalid">
                                Campo requerido
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm="12" lg='6'>
                        <Form.Group controlId="calle">
                            <Form.Label>Calle</Form.Label>
                            <Form.Control
                                value={formData.propiedad.calle}
                                name="calle"
                                onChange={setValuePropiedad}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Campo requerido
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm="12" lg='3'>
                        <Form.Group controlId="numero">
                            <Form.Label>Número</Form.Label>
                            <Form.Control
                                value={formData.propiedad.numero}
                                name="numero"
                                onChange={setValuePropiedad}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Campo requerido
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm="12" lg='3'>
                        <Form.Group controlId="tipo">
                            <Form.Label>Tipo</Form.Label>
                            <Form.Select
                                value={formData.propiedad.tipo}
                                name="tipo"
                                onChange={setValuePropiedad}
                                required
                            >
                                <option value="1">Casa</option>
                                <option value="2">Departamento</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Campo requerido
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm="12" lg='6'>
                        <Form.Group controlId="complemento">
                            <Form.Label>Complemento</Form.Label>
                            <Form.Control
                                value={formData.propiedad.complemento}
                                name="complemento"
                                onChange={setValuePropiedad}
                            />
                            <Form.Control.Feedback type="invalid">
                                Campo requerido
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm="12" lg='3'>
                        <Form.Group controlId="nrodepto">
                            <Form.Label>Nro Departamento</Form.Label>
                            <Form.Control
                                value={formData.propiedad.nrodepto}
                                name="nrodepto"
                                onChange={setValuePropiedad}
                                disabled={formData.propiedad.tipo == '1'}
                                required={formData.propiedad.tipo == '2'}
                            />
                            <Form.Control.Feedback type="invalid">
                                Campo requerido
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm="12" lg='3'>
                        <Form.Group controlId="cntbodegas">
                            <Form.Label># Bodegas</Form.Label>
                            <Form.Control
                                type="number"
                                value={formData.propiedad.cntbodegas}
                                name="cntbodegas"
                                onChange={setValuePropiedad}
                                disabled={formData.propiedad.tipo == '1'}
                                required={formData.propiedad.tipo == '2'}
                            />
                            <Form.Control.Feedback type="invalid">
                                Campo requerido
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm="12" lg='3'>
                        <Form.Group controlId="cntestacionamientos">
                            <Form.Label># Estacionamientos</Form.Label>
                            <Form.Control
                                type="number"
                                value={formData.propiedad.cntestacionamientos}
                                name="cntestacionamientos"
                                onChange={setValuePropiedad}
                                disabled={formData.propiedad.tipo == '1'}
                                required={formData.propiedad.tipo == '2'}
                            />
                            <Form.Control.Feedback type="invalid">
                                Campo requerido
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm="12" lg='3'>
                        <Form.Group controlId="ndormitorios">
                            <Form.Label>Número dormitorios</Form.Label>
                            <Form.Control
                                type="number"
                                value={formData.propiedad.ndormitorios || ''}
                                name="ndormitorios"
                                onChange={setValuePropiedad}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Campo requerido
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm="12" lg='3'>
                        <Form.Group controlId="nbanos">
                            <Form.Label>Número baños</Form.Label>
                            <Form.Control
                                type="number"
                                value={formData.propiedad.nbanos || ''}
                                name="nbanos"
                                onChange={setValuePropiedad}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Campo requerido
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm="12" lg='3'>
                        <Form.Group controlId="mtconstruidos">
                            <Form.Label>Metros construidos</Form.Label>
                            <Form.Control
                                type="number"
                                value={formData.propiedad.mtconstruidos || ''}
                                name="mtconstruidos"
                                onChange={setValuePropiedad}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Campo requerido
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm="12" lg='3'>
                        <Form.Group controlId="mttotal">
                            <Form.Label>Metros totales</Form.Label>
                            <Form.Control
                                type="number"
                                value={formData.propiedad.mttotal || ''}
                                name="mttotal"
                                onChange={setValuePropiedad}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Campo requerido
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                </Row>

                <Row>
                    {
                        errorMessage && errorMessage != '' &&
                        <Alert key="danger" variant="danger">
                            {errorMessage}
                        </Alert>
                    }

                    <Col sm="12" lg='12'>
                        <div className="d-flex justify-content-center align-items-center mt-5">
                            <button className="btn btn-outline-secondary me-2" onClick={prev} >ATRÁS</button>
                            <button className="btn btn-primary position-relative px-5" type="submit" disabled={loading}>
                                SIGUIENTE
                                {loading && <Lottie className="position-absolute loader-btn top-1" animationData={GrayLoader} style={{ height: 28, right: 5, top: 4 }} loop />}
                            </button>
                        </div>
                    </Col>

                </Row>
            </Form>

        </div>
    )
}