
import "./appraisal.scss"
import useConfig from '../../hooks/config/useConfig'

import { IconArrowRight } from "../../components/CustomIcons/IconArrowRight"
import { AppraisalSteps } from "../../components/Appraisal/Steps"
import { useEffect, useState } from "react"
import { StepsContent } from "../../components/Appraisal/StepsContent"
import { ValidateDataRequest } from "../../interfaces/appraisal"

export const AppraisalProcesss = () => {

    const {translation} = useConfig()
    const steps = [1,2,3]
    const [currentStep , setCurrentStep] = useState(1)
    const [ completedStep , setCompletedStep] = useState(1)
    
    const [ data , setData ] = useState<ValidateDataRequest>()

    useEffect(()=> {
        if(currentStep > completedStep ){
            setCompletedStep(currentStep)
        }
    },[currentStep])

    return (
        <main className="appraisals main container p-3 p-sm-5 mh-100 text-center ">

            <h2>SOLICITAR TASACIÓN</h2>
            
            <AppraisalSteps 
                steps={steps} 
                currentStep={currentStep} 
                setCurrentStep={setCurrentStep}
                completedStep={completedStep}/>

            <StepsContent 
                data={data}
                currentStep={currentStep} 
                setData={setData}
                setCurrentStep={setCurrentStep} 
                setCompletedStep={setCompletedStep}
                />

        </main>
    )
}