import { Col, Row } from "react-bootstrap";
import { Link, Navigate } from "react-router-dom";
import { ChangePasswordForm } from "../fragments/login/ChangePasswordForm";
import useUser from "../hooks/user/useUser";
import useBreakpoint from "use-breakpoint";
import { BREAKPOINTS } from "../utils/Constants";
import "./../styles/login.scss";
import { useGoogleAnalytics } from "../hooks/googleAnalytics/useGoogleAnalytics";
import { CustomImage } from "../components/CustomImage/CustomImage";

const ChangePassword = () => {
  const { isLoggedIn, userState } = useUser();
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");
  const GA = useGoogleAnalytics(true);
  
  if (isLoggedIn) {
  
    return <Navigate to={"/feed"} />
  }

  return (
    <Row className="login w-100 p-0 m-0 g-0 d-flex flew-nowrap align-items-stretch">
      <Col
        lg="12"
        md="12"
        className={"login-left p-3 p-md-4 p-lg-5 d-flex align-items-center justify-content-center position-relative  register-left " + (breakpoint === "xs" ? "px-4" : "") }
      >

        <div className="change-password-container w-100">
          <CustomImage
            src="https://cdn1.infocasas.com.uy/web/61eee8ead10a6_infocdn__brand_logo_400x200.png"
            alt="Iris"
            width={20}
            height={20}
            classes="mb-5"
            styles={{ height: "50px" }}
          />
          <ChangePasswordForm />
        </div>
      </Col>
      
    </Row>
  );
};

export default ChangePassword;
