import { Button } from "react-bootstrap";
import { useGoogleAnalytics } from "../../hooks/googleAnalytics/useGoogleAnalytics";
import useUser from "../../hooks/user/useUser";


export const PropertyShare = ({ blobImgShare }: 
  { blobImgShare:Blob }) => {

    const GA = useGoogleAnalytics()
    const {userState} = useUser()

  const shareCanvasAsImage = async () => {
    
    GA.Event({ category: "Project shared as image", action: "project_shared_as_image", label: `${userState?.name}`, value: Number(userState?.id) })
    // @ts-ignore
    const image = new File([blobImgShare], 'image.png', { type: blobImgShare.type })
            
    if (navigator.canShare && navigator.canShare({ files: [image] })) {
 
      navigator.share({
          files: [image],         // Array of files to share
          //title: property.title , // Share dialog title        
      }).then()
      .catch((error : any)=>{
          const { message = '' } = error || {};
          //Normalmente para iOS
          if (message.indexOf('user denied permission') !== -1) {
            alert("Por favor, intentalo de nuevo")            
          }else{
            console.log('Error : ' + error.toString())
          }
      }) 

    } else {
      alert('El dispositivo no soporta compartir archivos')
      console.log('This device does not support sharing files.')
    } 
    
  } 

  return (
    <>
    <Button
      id="btnShare"
      className="property-share fw-normal d-flex align-items-center 
      text-secondary lh-1 p-0 position-relative"
      variant="outline-ligh"
      onClick={shareCanvasAsImage}
    >
      {/* <img className="share-ig" src={window.location.origin + '/images/share-ig.png'}></img> */}
      <i className={`bi text-primary fs-5 bi-instagram`}></i>
      {/* <i className="bi fs-1 bi-instagram position-absolute"></i> */}
      <span className="d-none d-lg-inline ms-2">Compartir</span>
    </Button>
    
    
    </>
    
  );
};
