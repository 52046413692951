import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import userFilter from "../../../hooks/filter/useFilter";
import { useSearchParams } from "react-router-dom";
import { uniqueFromArray, excludeFromArrayNumber } from "../../../utils/Functions";
import { StateType } from "../../../interfaces/property";
import { ProjectStatusType } from "../../../interfaces/project"
import { getStates } from "../../../data/api";
import useCountry from "../../../hooks/config/useConfig";

export const FilterState = ({projectStatusList } :{projectStatusList : ProjectStatusType[]}) => {
  const { setFilter } = userFilter();
  const [searchParams] = useSearchParams();
  const [currentState, setCurrentState] = useState([] as number[]);
  const { countryFilter } = useCountry();
  const states = getStates(countryFilter)
  const [listStatus , setListStatus] = useState<StateType[]>([])

  useEffect(() => {
    const parameter = searchParams.get("state");
    setCurrentState(
      !!parameter
        ? projectStatusList.filter((t) => parameter.split(",").includes(t.id.toString())).map(v=>v.id)
        : ([] as number[])
    );
  }, [searchParams]);

  useEffect(() => {
    
    let formattedList : any[] = []
    projectStatusList.map(s=>{
      let found = states.find(o=>o.id == s.identifier)
      if(found){
        formattedList.push({
          id : s.id,
          show : found.show,
          name : found.name
        })
      }
    })
    setListStatus(formattedList)
  },[projectStatusList])


  return (
    <Form>
      {listStatus.filter((state) => state.show).map((state) => (
        <div key={`filter-${state.name}`} className="mb-1">
          <label className="d-inline-flex w-100">
            <Form.Check
              className="me-2"
              type="checkbox"
              value={state.id}
              checked={currentState.includes(parseInt(state.id)) ?? false}
              onChange={(e) => {
                const current = currentState?.map((c) => c) ?? [];
                setFilter({
                  state: e.target.checked
                    ? (uniqueFromArray([
                      ...current,
                      parseInt(state.id),
                    ]) as unknown as number[])
                    : (excludeFromArrayNumber(
                      current,
                      parseInt(state.id)
                    ) as unknown as number[]),
                });
              }}
            />
            {state.name}
          </label>
        </div>
      ))}
    </Form>
  );
};
