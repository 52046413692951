import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import userFilter from "../../../hooks/filter/useFilter";
import "./FilterCommune.scss";
import { Commune, Department, Zone } from "../../../interfaces/locations";


export const FilterCommune = ({ estate,neighbourhood, activeParent, setActiveParent, setCounter , communesOfNeighbourhood }: 
  { estate : Department, neighbourhood : Zone, activeParent: boolean, setActiveParent: Function, setCounter: Function, communesOfNeighbourhood : Commune[] }) => {
  const { filterState, setFilter } = userFilter();
  const [searchParams] = useSearchParams();
  const [currentCommune, setCurrentCommune] = useState<Commune[]>([]);
  const neighboorhoudsOfEstate = estate.zones

  useEffect(() => {
    const parameter = searchParams.get("commune");
    if (!!parameter) {
      const newCommunes = communesOfNeighbourhood.filter((commune) =>
        parameter.split(",").includes(commune.id.toString())
      );
      setCurrentCommune(newCommunes);
    } else setCurrentCommune([]);
  }, [searchParams, communesOfNeighbourhood]);

  useEffect(()=>{
    updateCounterNeighbourhood()
  },[activeParent])

  const updateCounterNeighbourhood = () => {
    setCounter(neighbourhood.id, document.querySelectorAll(`.parent-neighbourhood-${neighbourhood.id} .commune-check .form-check-input:checked`).length)
  }

  const dropdowns = communesOfNeighbourhood.map((commune) => {
    const active = activeParent || currentCommune.findIndex((n) => n.id === commune.id) >= 0;
    
    const handleChange = () => {

      updateCounterNeighbourhood()

      let filterNeighbourhood = filterState.neighbourhood?.filter((neighboorhoud) => neighboorhoud !== neighbourhood.id);

      if (
        (typeof filterState.location === "undefined" ||!filterState.location.length) && 
        (typeof filterState.neighbourhood === "undefined" ||!filterState.neighbourhood.length)&&
        (typeof filterState.commune === "undefined" ||!filterState.commune.length)
      ) { 
        //No existe filtro ni por location ni por neighbourhood
        setFilter({ commune: [commune.id] })
        
      } else { //Ya existe neighbourhood
        let filter;
        

        if (active) {
          //Va a DESACTIVAR
          if(filterState.neighbourhood?.includes(neighbourhood.id) || filterState.location?.includes(estate.id) ){

            const filterCommune = communesOfNeighbourhood.filter((n) => n.id !== commune.id ).map((n) => n.id);
            filter = [...(filterState.commune ?? []), ...filterCommune]
            setActiveParent(false);
            if(filterState.location?.includes(estate.id)){
              //Está elegido el estate, al desactivarlo, asi que agregaremos los neighbourhoods del estate
              let allowedNeighbourhoods = neighboorhoudsOfEstate.filter(n=> n.id != neighbourhood.id).map(n=>n.id)
              filterNeighbourhood = filterNeighbourhood?.concat(allowedNeighbourhoods)
            }
          }else{
            filter = filterState.commune?.filter((n: number) => n !== commune.id);
          }
        }
        else {
          filter = [...(filterState.commune ?? []), commune.id]
        }

        setFilter({ commune: filter })
        if(active) setActiveParent(false)

      }
      setFilter({ neighbourhood: filterNeighbourhood });
    };

    return (
      <small key={'commune-'+commune.id} className="commune-check d-flex py-1 text-gray" >
        <Form.Check id={`commune-${commune.id}`} className="me-2 d-flex align-items-center w-100" checked={active} readOnly onChange={handleChange}
            label={`${commune.name}`}  />
      </small>
    );
  });

  return (
    <>
      {dropdowns}
    </>
  );
};


