import { useState, useEffect } from 'react';
import { translations } from '../../utils/Translations';

export const useTranslation = (countryCode: string) => {
    const [dialect, setDialect] = useState('uy');
    const [translation, setTranslation] = useState({});

    useEffect(() => {
        if (Object.keys(translations).includes(countryCode)) {
            setDialect(countryCode);
        } else {
            setDialect('uy');
        }
    }, [countryCode]);

    useEffect(() => {
        //@ts-ignore
        setTranslation(translations[dialect]);
    }, [dialect]);

    return translation;
};