import { Modal } from "react-bootstrap"
import { ReactNode } from "react";
import useConfig from "../../hooks/config/useConfig"

export const FeedBenefitsModal = ({ show, setShow, type, children }: { show: boolean, setShow: (x: boolean) => void, type: string, children: ReactNode; }) => {
    const handleClose = () => setShow(false);
    const {translation} = useConfig()

    return (
        <Modal
            show={show}
            onHide={() => handleClose()}
            centered
            contentClassName="border-0 warranty-modal"
            size="lg"
        >
            <Modal.Header closeButton className={"px-5 " + (type === "warranty" ? "bg-green" : "bg-blue")}>
                <Modal.Title>
                    {
                        type === "warranty"
                            ? (<span className="text-white h5">{translation.rent_warranties}</span>)
                            : (<span className="text-white h5">Préstamos Hipotecarios</span>)
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="container p-4">
                {children}
            </Modal.Body>
        </Modal>
    )
}