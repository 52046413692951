
import useConfig from "../../hooks/config/useConfig"


export const StepThree = ( 
    {  setCurrentStep} : 
    { setCurrentStep : Function}) => {

    const {translation, countryFeatures} = useConfig()
    
    const prev = () => {
        setCurrentStep((prev : number) => prev - 1)
    }

    return (
        <div className="appraisal-payment m-auto">
            <h2>Realiza el pago</h2>
            <ul className="appraisal-payment">
                <li>
                    <p className="mt-3 text-start">Paga mediante WebPay: 
                    <a href={`https://www.webpay.cl/form-pay/35155`} target="_blank"> 
                    <button className="btn btn-outline-primary mx-2">Paga Aquí</button>
                    </a> </p>
                </li>
                <li>
                    <p className="mt-3 text-start">Y envíanos el comprobante: 
                        <a href={`${countryFeatures.contact_wame}`} target="_blank"> 
                        <button className="btn text-green border-color-green bg-white fw-bold ml-2 mx-2" >
                            Aquí <i className="bi bi-whatsapp"></i> 
                        </button>
                        </a>
                    </p>
                </li>

                <li>
                    <p className="mt-3 text-start">El informe de la tasación se enviará a la brevedad a tu correo electrónico.</p>
                    
                </li>
                <li>
                    <p className="mt-4 text-start appraisal-contact">¿Alguna duda?  &nbsp;
                        <a href={`${countryFeatures.contact_wame}`} target="_blank"> 
                        Contáctanos aquí &nbsp;<i className="bi bi-whatsapp"></i> 
                        </a>
                    </p>
                </li>

                
            </ul>
            
            {/* <button className="btn btn-outline-secondary  mt-5" onClick={prev} >ATRÁS</button>  */}
            
        </div>
    )
}