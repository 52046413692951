import { Button, Card } from "react-bootstrap"
import { RankingProjectsType } from '../../interfaces/ranking'
import { formatMoney } from "../../utils/Functions";
import { CustomImage } from "../CustomImage/CustomImage";
import { useImage } from "../../hooks/image/useImage";

export const FeedPropertyCard = ({ property }: { property: RankingProjectsType }) => {
    const optimizeSrc = useImage({ src: (property.project?.images[0] ?? "") })

    return (
        <>
            <Card className="feed-property bg-white text-secondary d-none d-lg-block shadow-sm border-0">
                <div className="country-flag rounded-1 shadow-sm position-absolute">
                    <CustomImage
                        alt={property.project?.title}
                        src={property.project?.country?.flag ?? ""}
                        width={30}
                        height={30}
                    />
                </div>
                <Card.Img variant="top" src={optimizeSrc} />
                <Card.Body className="">
                    <Card.Title className="text-green commission h6">COMISIÓN INMOB.: {property.project?.financial.commission.percent}%</Card.Title>
                    <Card.Text className="mb-2 fs-sm">
                        <span className="unitsFrom">{property.project?.title} ·</span> desde
                        {`${property.project?.sale_currency} ${formatMoney(property.project?.units?.sort((a, b) => a.price - b.price)[0].price)}`}
                    </Card.Text>
                    <Button
                        variant="outline-secondary"
                        className="fw-500 py-2 px-4 text-nowrap fs-sm"
                        onClick={() => window.open(`/proyecto/${property.project?.identifier}`, "_blank")}
                    >
                        Conoce más
                    </Button>
                </Card.Body>
            </Card>
        </>
    )
}