import { useState } from "react";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import { GalleryArrow } from "../../components/GalleryArrow/GalleryArrow";
import { PropertyModal } from "../../components/PropertyModal/PropertyModal";
import { PropertyType } from "../../interfaces/property";
import { FloorModalContent } from "./FloorModalContent";
import { ProjectType , FloorType } from "../../interfaces/project";

export const Floors = ({
  floors,
  property
}: {
  floors: FloorType[];
  property: ProjectType;
}) => {
  const [expandFloor, setExpandFloor] = useState<number | null>(null);

  return (
    <>
      <Row className="floors gx-2 gy-2">
        {floors.map((f, index) => (
          <Col key={"floor_" + index} xs={"auto"}>
            <div
              className="bg-white border rounded-pill pe-2 ps-3 py-1 d-flex align-items-center"
              role={"button"}
              onClick={() => setExpandFloor(index)}
            >
              <span className="fs-sm fw-500 me-1">{f.name}</span>
              <i className="bi bi-chevron-right text-primary"></i>
            </div>
          </Col>
        ))}
      </Row>
      <PropertyModal
        show={expandFloor !== null}
        onClose={() => setExpandFloor(null)}
        property={property}
        className="property-modal-floors"
      >
        <Container className="h-100">
          {expandFloor !== null && (
            <Carousel
              className="carousel-100 h-100"
              activeIndex={expandFloor}
              onSelect={setExpandFloor}
              interval={null}
              indicators={false}
              nextIcon={
                <GalleryArrow
                  direction="right"
                  customClass="border shadow property-gallery-arrow"
                />
              }
              prevIcon={
                <GalleryArrow
                  direction="left"
                  customClass="border shadow property-gallery-arrow"
                />
              }
              wrap={false}
              controls={floors.length > 1}
            >
              {floors.map((f, index) => (
                <Carousel.Item key={"floor-modal-item_" + index}>
                  <FloorModalContent floor={f} />
                </Carousel.Item>
              ))}
            </Carousel>
          )}
        </Container>
      </PropertyModal>
    </>
  );
};
