export const IconSchool = ({ width = 20 }) => {
  return (
    <svg width={width} height={width} viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.8125 10.875L14.5 3.625L27.1875 10.875L14.5 18.125L1.8125 10.875Z" stroke="currentColor" strokeWidth="1.48148" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.34375 13.5938V20.8438L14.5 25.375L22.6562 20.8438V13.5938" stroke="currentColor" strokeWidth="1.48148" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M27.1875 20.8438V10.875" stroke="currentColor" strokeWidth="1.48148" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.5 18.125V25.375" stroke="currentColor" strokeWidth="1.48148" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
