
export const UnitPlan = ({unit_plan_encoded , pageHeight } : {unit_plan_encoded : string , pageHeight : number}) => {

    return (
        <>
            {
                unit_plan_encoded && unit_plan_encoded != '' &&
                <div className="mt-5">

                    <img className="plan-estimation mt-2 border-1 "
                        style={{ marginTop: 10, maxHeight: pageHeight / 2, width: 'auto', maxWidth: '100%', margin: 'auto', objectFit:'cover' }}
                        src={unit_plan_encoded} alt=""
                    />
                </div>

            }
            {
                (!unit_plan_encoded || unit_plan_encoded == '') &&
                <p className="mt-4">No incluye plano.</p>
            }
        </>
    )
}