import { useEffect, useState } from "react";
import userFilter from "../../../hooks/filter/useFilter";
import { useSearchParams } from "react-router-dom";
import { uniqueFromArray, excludeFromArray } from "../../../utils/Functions";
import { IconAC } from "../../CustomIcons/IconAC";
import { IconBalcony } from "../../CustomIcons/IconBalcony";
import { IconElevator } from "../../CustomIcons/IconElevator";
import { IconGarage } from "../../CustomIcons/IconGarage";
import { IconLaundry } from "../../CustomIcons/IconLaundry";
import { IconSecurity } from "../../CustomIcons/IconSecurity";
import { IconSecurityCamera } from "../../CustomIcons/IconSecurityCamera";
import { IconSwimmingPool } from "../../CustomIcons/IconSwimmingPool";
import { IconGarden } from "../../CustomIcons/IconGarden";
import { IconBarbacue } from "../../CustomIcons/IconBarbacue";
import { IconPlayroom } from "../../CustomIcons/IconPlayroom";
import { IconSeaView } from "../../CustomIcons/IconSeaView";
import { IconSUM } from "../../CustomIcons/IconSUM";
import { IconForniture } from "../../CustomIcons/IconForniture";
import useCountry from "../../../hooks/config/useConfig";
import { IconParkCustom } from "../../CustomIcons/IconParkCustom";
import { IconGymCustom } from "../../CustomIcons/IconGymCustom";
import { IconEventsRoomCustom } from "../../CustomIcons/IconEventsRoomCustom";
import { IconPetCustom } from "../../CustomIcons/IconPetCustom";

export const FilterAmenities = () => {
    const { setFilter } = userFilter();
    const [searchParams] = useSearchParams();
    const [currentAmenities, setCurrentAmenities] = useState([] as string[]);
    const { translation } = useCountry();

    useEffect(() => {
        const parameter = searchParams.get("amenities");
        setCurrentAmenities(!!parameter ? parameter.split(',') : [] as string[]);
    }, [searchParams]);

    const amenities = [
        {
            title: "Ascensor",
            icon: <IconElevator />,
            value: "hasElevator",
        },
        {
            title: translation.garage,
            icon: <IconGarage />,
            value: "hasGarage",
        },
        {
            title: "Seguridad",
            icon: <IconSecurity width={19} />,
            value: "hasSecurity",
        },
        {
            title: translation.air_conditioner,
            icon: <IconAC width={19} />,
            value: "hasAC",
        },
        {
            title: "Balcón",
            icon: <IconBalcony width={20} />,
            value: "hasBalcony",
        },
        {
            title: "Piscina",
            icon: <IconSwimmingPool width={20} />,
            value: "hasSwimmingPool",
        },
        {
            title: "Lavanderia",
            icon: <IconLaundry />,
            value: "hasLaundry",
        },
        {
            title: "Vista al Mar",
            icon: <IconSeaView />,
            value: "hasSeaView",
        },
        {
            title: "Jardin",
            icon: <IconGarden width={21} />,
            value: "hasGarden",
        },
        {
            title: "SUM",
            icon: <IconSUM width={20} />,
            value: "hasSUM",
        },
        {
            title: "Playroom",
            icon: <IconPlayroom width={21} />,
            value: "hasPlayroom",
        },
        {
            title: translation.griller,
            icon: <IconBarbacue width={23} />,
            value: "hasBarbecue",
        },
        {
            title: translation.furnished,
            icon: <IconForniture width={18} />,
            value: "hasFornitures",
        },
        {
            title: "Camaras de Seguridad",
            icon: <IconSecurityCamera width={20} />,
            value: "hasSecurtyCameras",
        },
        {
            title: "Parque de niños",
            icon: <IconParkCustom width={20} />,
            value: "hasKidsPark",
        },
        {
            title: "Gimnasio",
            icon: <IconGymCustom width={22} />,
            value: "hasGym",
        },
        {
            title: translation.social_room,
            icon: <IconEventsRoomCustom width={20} />,
            value: "hasEventsRoom",
        },
        {
            title: translation.visit_garage,
            icon: <IconGarage width={20} />,
            value: "hasVisitGarage",
        },
        {
            title: "Pet friendly",
            icon: <IconPetCustom width={20} />,
            value: "isPetFriendly",
        },
    ];

    return (
        <div className="mb-3 d-flex justify-content-start flex-wrap">
            {amenities.map((amenitie) => (
                <div
                    key={`filter-${amenitie.title}`}
                    className={`rounded-pill border px-3 py-1 m-1 fw-500 fs-sm d-flex align-items-center amenitie-pill ${currentAmenities.includes(amenitie.value) ? "active" : ""}`}
                    style={{ height: 30 }}
                    role="button"
                    onClick={(e) => {
                        const current = currentAmenities ?? [] as string[];
                        setFilter({
                            amenities: (!(e.target as Element).classList.contains('active') ? uniqueFromArray([...current, amenitie.value]) as unknown as string[] : excludeFromArray(current, amenitie.value) as unknown as string[])
                        })
                    }}
                >
                    {amenitie.icon}
                    <span className={`ms-2 ${currentAmenities.includes(amenitie.value) ? "active" : ""}`}>
                        {amenitie.title}
                    </span>
                </div>
            ))
            }
        </div >
    )
}
