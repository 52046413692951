
export const PaginationPDF = ({page , total} : {page : number , total : number}) => {

    return (
        <div className="position-absolute p-2 pb-3 px-4" 
            style={{ border : '2px solid #ff7328',bottom : 0, right : 20 , left : 20, 
            borderLeft : '0px' , borderRight : '0px' , borderBottom : '0px',
            textAlign : 'right'}} >
            <p>Página {page} de {total}</p>
        </div>
    )
}