export const IconFire = ({ width = 16, height = 20 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8565 10.3208C13.4173 6.58084 8.29317 6.37917 9.53067 0.943335C9.62234 0.540001 9.1915 0.228335 8.84317 0.439168C5.51567 2.40084 3.12317 6.33334 5.13067 11.485C5.29567 11.9067 4.80067 12.3008 4.44317 12.0258C2.784 10.77 2.60984 8.96417 2.7565 7.67167C2.8115 7.195 2.18817 6.96584 1.92234 7.36C1.299 8.31334 0.666504 9.85334 0.666504 12.1725C1.01484 17.3058 5.35067 18.8825 6.909 19.0842C9.1365 19.3683 11.5473 18.9558 13.2798 17.37C15.1865 15.6008 15.8832 12.7775 14.8565 10.3208V10.3208ZM6.34984 14.9317C7.66984 14.6108 8.34817 13.6575 8.5315 12.8142C8.834 11.5033 7.6515 10.22 8.449 8.14834C8.7515 9.8625 11.4465 10.935 11.4465 12.805C11.5198 15.1242 9.00817 17.1133 6.34984 14.9317V14.9317Z"
        fill="currentColor"
      />
    </svg>
  );
};
