
import { getCourseResponse, getCoursesResponse } from "../../interfaces/academy"
import { generalDataResult } from "../../interfaces/apiTypes"
import {useFetch} from "./useFetch"


export const useAcademyFetch = () => {

    const {authFetch } = useFetch()

    const getCoursesEndpoint = async  (country_id : number) => {
        let result: getCoursesResponse = {
            success: false,
            data: [],
            message: ''
        }


        const url = `${process.env.REACT_APP_AUTH_API_URL}/api/academy/get-courses`
    
        try {

            await authFetch(url,
                {
                    cache: 'no-cache',
                    method : 'POST',
                    body : JSON.stringify({country_id}),
                    headers: {
                        'Content-Type': 'application/json',
                        'Cache-Control': 'no-cache'
                    },
                }
            )
            .then((response) => {
                    if (response.error) {
                        if (response.message && response.message !== '') {
                            result.message = response.message
                        }
                    } else result = response

            })

            return result
        } catch (error) {
            console.log(error)
            return result
        }
    }

    const getCourseByIdentifierEndpoint = async  (id : number) => {
        let result: getCourseResponse = {
            success: false,
            message: ''
        }


        const url = `${process.env.REACT_APP_AUTH_API_URL}/api/academy/get-course`
    
        try {

            await authFetch(url,
                {
                    cache: 'no-cache',
                    method : 'POST',
                    body : JSON.stringify({identifier : id}),
                    headers: {
                        'Content-Type': 'application/json',
                        'Cache-Control': 'no-cache'
                    },
                }
            )
            .then((response) => {
                    if (response.error) {
                        if (response.message && response.message !== '') {
                            result.message = response.message
                        }
                    } else result = response

            })

            return result
        } catch (error) {
            console.log(error)
            return result
        }
    }

    return {

        getCoursesEndpoint,
        getCourseByIdentifierEndpoint
    }

}