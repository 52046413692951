import { Col, Row } from "react-bootstrap";
import { ProjectFinancialType } from "../../interfaces/project";
import useCountry from "../../hooks/config/useConfig";
import { CountryFeatures } from "../../interfaces/config";
import { usePromo } from "../../hooks/promo/usePromo";


export const PropertyCommission = ({ financial , propertyCountryFeatures, promos}: 
  { financial: ProjectFinancialType,propertyCountryFeatures:CountryFeatures|null|undefined,promos : any}) => {
  const { translation } = useCountry();
  const showMaxCommision = propertyCountryFeatures?.max_commission && financial.commission.max_percent?true:false ;
  const showFileCommission = propertyCountryFeatures?.file_commission && financial.commission.file?true:false ;
  const promoCommission = promos && promos != null && promos == 1 && financial.commission.old_before_promo ? true : false
  const {countryPromo} = usePromo()
  const enableBlackFriday = !!countryPromo?.is_black_friday 


  return (
    <Row className="d-flex mb-2">
      <Col lg={12} className="d-flex align-items-center mb-1 position-relative">
        <h3 className="fs-4 fw-normal m-0">{translation.commision}</h3>
        <i className="ms-2 bi bi-info-circle small text-light"></i>
        {enableBlackFriday && (promoCommission) &&
              <img src="https://cdn1.infocasas.com.uy/web/6381001fb3831_etiqueta.png" alt="Logo promo" className="logo-promo logo-black-friday" />
        }
      </Col>
      <Col lg={12} className="flex-column small">
        {
          promoCommission && 
          <p className="h5 m-0 text-green">Antes: <span className="strikethrough">{financial.commission.old_before_promo} % + IVA</span></p>
        }
        <p className={`h3 m-0 text-green ` + (promoCommission ? ' text-primary ': '')}>
          {promoCommission && 'Ahora: '}
          {showMaxCommision && 'Estándar: ' }
          <span className={(promoCommission?'fw-bold':'')}>
            {financial.commission.full_value}
          </span>
           
          {showFileCommission &&  !showMaxCommision &&
            <a className="mx-2 fs-6" href={financial.commission.file} target="_blank">Ver más</a>
          }  
        </p>

        {
          showMaxCommision && <p className="h3 m-0 text-green">Máxima: {financial.commission.max_percent}% + IVA 
          {showFileCommission &&  
            <a className="mx-2 fs-6" href={financial.commission.file} target="_blank">Ver más</a>
          }  
          </p>
        }
        {
          financial.commission.description && Array.isArray(financial.commission.description) &&
          financial.commission.description.map((description, i) =>
            description.trim() !== '' && <p className="text-light-secondary m-0 text-green" key={i}>{description.trim()}</p>
          )
        }
      </Col>
    </Row>
  );
};
