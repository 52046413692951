import { ReactElement, useMemo, useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { PropertyType } from "../../interfaces/property";
import { MarkerGroup } from "../../interfaces/markerGroup";
import { PropertyMap } from "../../components/PropertyMap/PropertyMap";
import { UnitsSelect } from "../../components/UnitsSelect/UnitsSelect";
import { IconHealth } from "../../components/CustomIcons/IconHealth";
import { IconRestaurant } from "../../components/CustomIcons/IconRestaurant";
import { IconSchool } from "../../components/CustomIcons/IconSchool";
import { IconTransport } from "../../components/CustomIcons/IconTransport";
import { getMapMarkers } from "../../data/api";
import { ProjectType } from "../../interfaces/project";

export const PropertyLocation = ({ property }: { property: ProjectType }) => {

  const [points, setPoints] = useState("school");
  const [icon, setIcon] = useState(<IconSchool />);
  const [mapMarkers, setMapMarkers] = useState([] as MarkerGroup[]);
  const options: { value: string; text: string; disabled?: boolean }[] =
    useMemo(() => {
      const disabled = false;
      const res: { value: string; text: string; disabled?: boolean, icon?: ReactElement }[] = [
        { value: "school", text: "Escuelas", disabled: disabled, icon: <div className="me-2"><IconSchool /></div> },
        { value: "transport", text: "Transporte", disabled: disabled, icon: <div className="me-2"><IconTransport /></div> },
        { value: "health", text: "Salud", disabled: disabled, icon: <div className="me-2"><IconHealth /></div> },
        { value: "restaurant", text: "Restaurantes", disabled: disabled, icon: <div className="me-2"><IconRestaurant /></div> },
      ];
      return res;
    }, []);

  useEffect(() => {
    setMapMarkers(getMapMarkers())

    switch (points) {
      case "school":
        setIcon(<div className="fs-2 marker-group bg-white border border-3 rounded-circle border-primary text-primary"><IconSchool /></div>)
        break;
      case "transport":
        setIcon(<div className="fs-2 marker-group bg-white border border-3 rounded-circle" style={{ borderColor: "#0079E8 !important", color: "#0079E8 !important" }}><IconTransport /></div>)
        break;
      case "health":
        setIcon(<div className="fs-2 marker-group bg-white border border-3 rounded-circle" style={{ borderColor: "#00B3AC !important", color: "#00B3AC !important" }}><IconHealth /></div>)
        break;
      case "restaurant":
        setIcon(<div className="fs-2 marker-group bg-white border border-3 rounded-circle" style={{ borderColor: "#F79D0C !important", color: "#F79D0C !important" }}><IconRestaurant /></div>)
        break;

      default:
        setIcon(<div className="fs-2 marker-group bg-white border border-3 rounded-circle border-primary text-primary"><IconSchool /></div>)
        break;
    }
  }, [points])


  return (
    <Row>
      {
        property.latitude && property.longitude && 
        <Col lg={12} style={{ height: '400px' }} className="mb-3">
          <PropertyMap
            latitude={parseFloat(property.latitude)}
            longitude={parseFloat(property.longitude)}
            expandable
            property={property}
            group={mapMarkers}
            icon={icon}
          />
          
        </Col>
      }
      <Col lg="12" xs={"auto"} className="mb-4">
        {/* Aún no tienen funcionalidad */}
        {/* <UnitsSelect
          options={options}
          onChange={setPoints}
          value={points}
        /> */}
      </Col>
    </Row>
  );
};
