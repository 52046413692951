import { ReactNode, useEffect, useMemo, useState } from "react";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import { PropertyType } from "../../interfaces/property";
import { UnitsSelect } from "../../components/UnitsSelect/UnitsSelect";
import { UnitsTable } from "../../components/UnitsTable/UnitsTable";
import { IconBed } from "../../components/CustomIcons/IconBed";
import { IconShower } from "../../components/CustomIcons/IconShower";
import { formatMoney } from "../../utils/Functions";
import { PropertyModal } from "../../components/PropertyModal/PropertyModal";
import { GalleryArrow } from "../../components/GalleryArrow/GalleryArrow";
import { UnitModalContent } from "./UnitModalContent";
import { VirtualTourIcon } from "../../components/CustomIcons/VirtualTour";
import useCountry from "../../hooks/config/useConfig";
import { IconCrop } from "../../components/CustomIcons/IconCrop";
import { IconX } from "../../components/CustomIcons/IconX";
import { IconCheckCircle } from "../../components/CustomIcons/IconCheckCircle";
import { ProjectType ,UnitType } from "../../interfaces/project";
import { IconGarage } from "../../components/CustomIcons/IconGarage";
import { IconSocialHousing } from "../../components/CustomIcons/IconSocialHousing";

export const Units = ({
  units = [],
  property,
  operationType
}: {
  units?: UnitType[];
  property: ProjectType;
  operationType: "Venta" | "Alquiler"
}) => {
  const [unitsFilter, setUnitsFilter] = useState({ value: "all", tipology: "all" });
  const initialSorter = property.promos ? "is_promo" : "price"
  const [unitSorter, setUnitSorter] = useState(initialSorter);
  const [unitExpand, setUnitExpand] = useState<number | null>(null);
  const isLand = property.type.identifier === "3";
  const { country, translation,countryFeatures } = useCountry();

  // units options
  const options: { value: string; text: string; tipology: string; disabled?: boolean }[] =
    useMemo(() => {
      const res: {
        value: string;
        text: string;
        tipology: string,
        disabled?: boolean
      }[] = [
          { value: "all", text: "Todos", tipology: "all" },
        ];

        units
        .sort((a, b) => {
          if (unitSorter === "price") return a.price - b.price;
          else if (unitSorter === "is_promo"){
            return a.is_promo == b.is_promo ? (a.price - b.price) : a.is_promo ? -1 : 1;
          }
          else if (unitSorter === "priceM2")
            return Math.round(a.price / a.m2) - Math.round(b.price / b.m2);
          else if (unitSorter === "m2") return a.m2 - b.m2;
          else if (unitSorter === "bedrooms") return a.bedrooms - b.bedrooms;
          else if (unitSorter === "bathrooms") return a.bathrooms - b.bathrooms;
          else if (unitSorter === "listPrice") return a.list_price - b.list_price;
          else if (unitSorter === "workPrice") return a.work_price - b.work_price;
          else {
            if (a.number < b.number) return -1;
            if (a.number > b.number) return 1;
            return 0;
          }
        }).forEach((unit) => {
          const isFound = res.some(element => {
            if (unit.tipology !== "Dormitorio") {
              return element.text === unit.tipology
            } else {
              return element.value === unit.bedrooms.toString() && element.tipology == unit.tipology
            }
          });

          
          if (!isFound) {
            res.push({
              text:
                unit.bedrooms > 0
                  ? unit.bedrooms > 1
                    ? `${unit.bedrooms} ${translation.bedrooms}`
                    : `1 ${translation.single_bedroom}` 
                  : unit.tipology !== "Dormitorio"
                    ? unit.tipology
                    : translation.studio,
              value: unit.bedrooms.toString(),
              tipology: unit.tipology,
              disabled: !unit.active,
            })
          } else if (
            res.length > 0 &&
            res[res.length - 1].value === unit.bedrooms.toString() &&
            res[res.length - 1].disabled
          )
            {
              res[res.length - 1].disabled = !unit.active;
            }
        });
        
      return res;
    }, [units, unitSorter]);

  let columns: { text: ReactNode; name: string; visible: boolean }[] = useMemo(() => {
    let cols = [
      { text: !isLand ? "Unidad" : "Lote", name: "title", visible: true },
      { text: operationType === "Alquiler" ? "Precio" : translation.cash_price, name: "price", visible: !!property.countryFeatures?.pj_cash },
      { text: "Plazo de obra", name: "workPrice", visible: !isLand && !!property.countryFeatures?.pj_plazo_obra },
      { text: "Precio de lista", name: "listPrice", visible: !!property.countryFeatures?.pj_list_price },
      { text: "Descuento", name: "discountPercentage", visible: !!property.countryFeatures?.pj_dsct },
      { text: "Precio Final", name: "finalPrice", visible: !!property.countryFeatures?.pj_final_price },
      {
        text: (
          <div className="d-flex align-items-center justify-content-center">
            {/* <IconCrop width={14} height={14} className="me-1" /> */}
            {translation.m2_indoor}
            {!translation.m2_indoor && <IconCrop width={14} height={14} className="" />  }
          </div>
        ),
        name: "m2",
        visible: true
      },
      { text: "Piso", name: "floor", visible: !isLand && !!countryFeatures.units_show_floor },
      { text: translation.with_rent, name: "isRented", visible: !isLand && !!property.countryFeatures?.pj_units_rent },
      {
        text: (
          <VirtualTourIcon />
        ), name: "virtualTour", visible: true
      },
      { text: "", name: "action", visible: true },
    ];

    if (operationType === "Alquiler") cols = cols.filter((columns) => columns.name !== "workPrice" && columns.name !== "listPrice" && columns.name !== "isRented");

    return cols;
  }, [country])

  const data: any[] = useMemo(() => {
    const res: any[] = [];

    units
      .filter((u) => {
        if (unitsFilter.tipology !== "Dormitorio" && unitsFilter.tipology !== "all") return u.tipology === unitsFilter.tipology
        else if (unitsFilter.tipology === "Dormitorio") return Number(unitsFilter.value) === Number(u.bedrooms) && u.tipology === unitsFilter.tipology
        else return unitsFilter.value === "all"
      })
      .sort((a, b) => {
        if (unitSorter === "price") return a.price - b.price;
        else if (unitSorter === "is_promo"){
          return a.is_promo == b.is_promo ? (a.price - b.price) : a.is_promo ? -1 : 1;
          return (a.is_promo ? -1 : 1 ) || a.price - b.price ;
        }
        else if (unitSorter === "priceM2")
          return Math.round(a.price / a.m2) - Math.round(b.price / b.m2);
        else if (unitSorter === "m2") return a.m2 - b.m2;
        else if (unitSorter === "bedrooms") return a.bedrooms - b.bedrooms;
        else if (unitSorter === "bathrooms") return a.bathrooms - b.bathrooms;
        else if (unitSorter === "listPrice") return a.list_price - b.list_price;
        else if (unitSorter === "workPrice") return a.work_price - b.work_price;
        else {
          if (a.number < b.number) return -1;
          if (a.number > b.number) return 1;
          return 0;
        }
      })
      .forEach((u) => {
        res.push({
          id: u.id,
          title: (
            <div className={`d-inline-flex flex-column position-relative `}>
              <span
                className="fs-xxs fw-bold d-lg-none mb-1 mb-lg-0"
                children={"Unidad"}
              />
              <span className=" position-relative">
                {u.number}
                {
                  u.is_promo && 
                  <span className="position-absolute bg-warning promo-span text-black">PROMO</span>
                }
                </span>
              
            </div>
          ),
          price: (
            <div className="d-inline-flex flex-column">
              <span
                className="fs-xxs fw-bold d-lg-none mb-1 mb-lg-0"
                children={translation.cash_price}
              />
              {
                u.old_contado_price && 
                <span className="fs-xxs text-decoration-line-through text-secondary" style={{ whiteSpace: "nowrap" }}>
                  {u.currency} {formatMoney(u.old_contado_price)}
                </span>
              }
              <span className="fw-bold text-secondary" style={{ whiteSpace: "nowrap" }}>
                {u.currency} {formatMoney(u.contado_price)}
              </span>
            </div>
          ),
          workPrice: (
            <div className="d-inline-flex flex-column">
              <span
                className="fs-xxs fw-bold d-lg-none mb-1 mb-lg-0"
                children={"Precio de obra"}
              />
              {
                u.old_work_price && 
                <span className="fs-xxs text-decoration-line-through text-secondary text-black-50" style={{ whiteSpace: "nowrap" }}>
                {u.currency} {formatMoney(u.old_work_price)}
              </span>
              }
              <span style={{ whiteSpace: "nowrap" }}>
                {u.currency} {formatMoney(u.work_price)}
              </span>
            </div>
          ),
          listPrice: (
            <div className="d-inline-flex flex-column">
              <span
                className="fs-xxs fw-bold d-lg-none mb-1 mb-lg-0"
                children={"Precio de lista"}
              />
              {
                u.old_list_price && 
                <span className="fs-xxs text-decoration-line-through text-secondary text-black-50" style={{ whiteSpace: "nowrap" }}>
                {u.currency} {formatMoney(u.old_list_price)}
              </span>
              }
              <span style={{ whiteSpace: "nowrap" }}>
                {u.currency} {formatMoney(u.list_price)}
              </span>
            </div>
          ),
          discountPercentage: (
            <div className="d-inline-flex flex-column">
              <span
                className="fs-xxs fw-bold d-lg-none mb-1 mb-lg-0"
                children={"Descuento"}
              />
              <span style={{ whiteSpace: "nowrap" }}>
                {(u.max_discount ? u.max_discount : 0)}
              </span>
            </div>
          ),
          finalPrice: (
            <div className="d-inline-flex flex-column">
              <span
                className="fs-xxs fw-bold d-lg-none mb-1 mb-lg-0"
                children={"Precio final"}
              />
              <span style={{ whiteSpace: "nowrap" }}>
                {u.currency} {formatMoney(u.final_price)}
              </span>
            </div>
          ),
          isRented: (
            <div className="d-flex flex-column align-items-center">
              <span
                className="fs-xxs fw-bold d-lg-none mb-1 mb-lg-0"
                children={translation.with_rent}
              />
              {
                u.with_rent ? (
                  <IconCheckCircle width={20} height={20} className="text-primary" />
                ) : (
                  <IconX width={20} height={20} />
                )
              }
            </div>
          ),
          m2: (
            <div className="d-inline-flex align-items-center">
              <IconCrop width={14} height={14} className="d-lg-none me-2" />

              <span style={{ whiteSpace: "nowrap" }}>{u.m2 + " m²"}</span>
            </div>
          ),
          bedrooms: (
            <div className="d-inline-flex align-items-center">
              <span className="d-lg-none me-2">{<IconBed width={15} />}</span>
              <span className="d-lg-inline d-none">
                {
                  u.tipology !== "Dormitorio"
                    ? u.tipology
                    : u.bedrooms > 0
                      ? `${u.bedrooms} ${translation.short_bedroom ? translation.short_bedroom.substring(0,1) + '.' : ''}` : translation.abbrStudio
                }
              </span>
              <span className="d-lg-none">
                {u.bedrooms > 0 ? u.bedrooms : translation.abbrStudio}
              </span>
            </div>
          ),
          bathrooms: (
            <div className="d-inline-flex align-items-center">
              <span className="d-lg-none me-2">
                {<IconShower width={15} />}
              </span>
              <span className="d-lg-inline d-none">
                {u.bathrooms} {translation.short_one_bathroom}
              </span>
              <span className="d-lg-none">{u.bathrooms}</span>
            </div>
          ),
          garages: (
            <div className="d-inline-flex align-items-center">
              <span className="d-lg-none me-2">
                {<IconGarage width={15} />}
              </span>
              <span className="d-lg-inline d-none text-lowercase">
                {u.garages} {translation.garage_abrv}
              </span>
              <span className="d-lg-none">{u.garages}</span>
            </div>
          ),
          floor: (
            <div className="d-inline-flex align-items-center">
              <span className="d-lg-none me-2">
                Piso
              </span>
              <span className="d-lg-inline d-none text-lowercase">
                {u.floor} 
              </span>
              <span className="d-lg-none">{u.floor}</span>
            </div>
          ),
          virtualTour: (
            <div className="d-flex flex-column align-items-center">
              <span
                className="fs-xxs fw-bold d-lg-none mb-1 mb-lg-0"
                children={"Tour virtual"}
              />
              {
                u.virtual_tour ? (
                  <IconCheckCircle width={20} height={20} className="text-primary" />
                ) : (
                  <IconX width={20} height={20} />
                )
              }
            </div>
          ),
          action: (
            <i className="bi bi-chevron-right text-primary fs-5"></i>
          ),
        });
      });
    return res;
  }, [units, unitsFilter, unitSorter]);

  useEffect(() => {
    if (!isLand) {
      if (!columns.filter((column) => column.name === "bedrooms" || column.name === "bathrooms" || column.name === "garages").length) {
        const action: any = columns.pop();
        columns.push(
          { text: <IconBed />, name: "bedrooms", visible: true },
          { text: <IconShower />, name: "bathrooms", visible: true },
          { text: <IconGarage />, name: "garages", visible: !!countryFeatures.garages_in_units },
          action
        )
      }
    }
  }, [columns, property]);

  const handleUnitModal = (id: any) => {
    setUnitExpand(units.findIndex((un) => un.id === id));
  };

  return (
    <>
      <Row className={`gy-3 align-items-end ${operationType === "Alquiler" ? "mb-5" : ""}`}>
        <Col lg={12} xs={"auto"} className="flex-grow-1">
          <h3 className="fs-4 mb-0 fw-normal d-inline-flex flex-column flex-lg-row align-items-lg-end align-items-start">
            {isLand ? "Lotes" : "Unidades"} en {operationType}
            <span
              className="note ms-lg-2 mt-2 mt-lg-0 fs-xxs fw-500 bg-secondary text-white px-1 rounded-1 position-relative text-uppercase"
              style={{ bottom: 3, padding: "2px 0px" }}
            >
              {`${units?.filter((u) => u.active)?.length} Disponible`}
            </span>
          </h3>
        </Col>
        {!isLand && (
          <Col lg="12" xs={"auto"}>
            <UnitsSelect
              options={options}
              onChange={setUnitsFilter}
              value={unitsFilter}
            />
          </Col>
        )}
        <Col xs="12">
          <UnitsTable
            data={data}
            columns={columns}
            className="units"
            onSort={(s) => setUnitSorter(s)}
            unitExpand={unitExpand}
            handleModal={handleUnitModal}
          />
        </Col>
      </Row>
      <PropertyModal
        show={unitExpand !== null}
        onClose={() => setUnitExpand(null)}
        property={property}
        className={'property-modal-units '}
      >
        <Container className="h-100">
          {unitExpand !== null && (
            <Carousel
              className="carousel-100 h-100"
              activeIndex={unitExpand}
              onSelect={setUnitExpand}
              interval={null}
              indicators={false}
              controls={units.length > 1}
              wrap={false}
              nextIcon={
                <GalleryArrow
                  direction="right"
                  customClass="border shadow property-gallery-arrow"
                />
              }
              prevIcon={
                <GalleryArrow
                  direction="left"
                  customClass="border shadow property-gallery-arrow"
                />
              }
            >
              {units.map((u, index) => (
                <Carousel.Item key={"floor-modal-item_" + index}>
                  <UnitModalContent unit={u} currencyPrice={u.currency} propertyTypeId={property.type.identifier}
                    property={property} />
                </Carousel.Item>
              ))}
            </Carousel>
          )}
        </Container>
      </PropertyModal>
    </>
  );
};
