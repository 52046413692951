import { createContext } from "react";
import { PropertyType } from "../../interfaces/property";

export type PropertiesContextProps = {
  propertiesState: PropertyType[];
  setProperties: Function;
  isLoading: boolean;
};

const PropertiesContext = createContext<PropertiesContextProps>({} as PropertiesContextProps);

export default PropertiesContext;
