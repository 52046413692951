
import { Navigate, useLocation } from 'react-router-dom'
import PrivacyCL from '../fragments/terms/PrivacyCL'
import useConfig from '../hooks/config/useConfig'


const Privacy = () => {

    const {config,country} = useConfig()
    const { pathname } = useLocation();
    
    if(country){
        return <>
        { country == 'CL' && <PrivacyCL /> }
        { country != 'CL' && <Navigate to={'/404'} state={{ from: pathname }}/> }
        </>
    }

    return <div style={{minHeight:'600px'}}></div>
}

export default Privacy