import { Badge, Card, Image, OverlayTrigger, ProgressBar, Popover } from "react-bootstrap"
import useUser from "../../hooks/user/useUser";
import { LevelType } from "../../interfaces/levels";
import { useImage } from "../../hooks/image/useImage";
import { IconCheckCircleFill } from "../CustomIcons/IconCheckCircleFill";
import { AUTH_VALUES } from "../../utils/Constants";
import { useUserFetch } from "../../hooks/fetch/useUserFetch";
import { useEffect, useState } from "react";
import { StepsForNext } from "../../interfaces/user";
import { useLevel } from "../../hooks/level/useLevel";


export const FeedInfoInmobiliaria = () => {
    const { userState } = useUser();
    const {level , totalPercentage} = useLevel()

    const [levelInfo , setLevel] = useState<LevelType>()
    const [levelSteps , setLevelSteps] = useState<StepsForNext[]>()

    const { getLevelInfo } = useUserFetch()
    const percentageCompleted = 75;

    const optimizeSrc = useImage({ src: levelInfo?.icon!!, width: 150, height: 150 })
    
    useEffect(()=> {
        getLevelInfo().then(res=>{
            if(res && res.success && res.data){
                setLevel(res.data.current_level)
                setLevelSteps(res.data.steps_for_next)
            }
        })
    },[])

    const popover = (
        <Popover className="popover-level border-0 shadow text-secondary mt-5">
            <Popover.Header as="div" className="bg-transparent border-0  p-3 fw-bold">
                <h6 className="header-title d-flex align-items-center mb-0">Avanza al <Badge bg="primary" className="ms-1">NIVEL {levelInfo ? parseInt(levelInfo?.name) + 1 : ''}</Badge></h6>
                <span className="header-subtitle text-light-secondary">¡Cumple con los siguentes objetivos!</span>
            </Popover.Header>
            <Popover.Body className="pt-0 px-3">
                {
                    levelSteps?.filter(s=>s.next > 0 && s.new == true ).map((step, index) => (
                        <div key={index} className="my-2">
                            <IconCheckCircleFill width={20} height={20} className={`${step.valid ? "text-green" : "text-light-secondary"} me-2`} />
                            <span>{step.label}</span>
                        </div>
                    ))
                }
                
            </Popover.Body>
            <div className="d-flex px-3 py-2 bg-sm-light rounded-bottom">
                <i className="bi bi-exclamation-triangle-fill me-3"></i>
                <p className="footer-text mb-0">Si no puedes (o quieres) asistir a eventos presenciales motivo del COVID Saars 19, contactate con un coordinador IRIS para reemplazarlo por uno virtual y seguir avanzando de nivel</p>
            </div>
        </Popover>
    );



    return (
        <Card className="gy-1 border-0 p-3 shadow-sm mb-3 d-none d-lg-block">
            <div className="d-flex align-items-center">
                <Image width={60} height={60} src={optimizeSrc} roundedCircle fluid />
                <div className="ms-2 text-break">
                    <h6 className="m-0 text-secondary fw-bold fs-6">{userState?.name}</h6>
                    <span className="fs-xs text-light-secondary d-block me-2 lh-sm">{userState?.email}</span>
                    <Badge bg="primary" className="fs-xxs lh-1">NIVEL {userState?.level}</Badge>
                </div>
            </div>
            <div className="border-top mt-2 mb-1 pb-1"></div>
            <div>
                {parseInt(userState?.level) < (AUTH_VALUES.ADMIN_LEVEL - 1)
                    ? <span className="d-block text-secondary fs-sm">
                        <span className="me-1">Tu próximo nivel:</span>
                        <span className="text-primary me-1">Nivel {parseInt(userState?.level) + 1}</span>
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={popover}
                        >
                            <i className="bi bi-question-circle-fill text-light-secondary"></i>
                        </OverlayTrigger>
                    </span>
                    : ''
                }
                <span className="percentage d-block text-end text-secondary fw-bold fs-sm">{totalPercentage}%</span>
                <div className="container-progress-bar position-relative">
                    <div className="progress-dot bg-primary rounded-circle position-absolute shadow" style={{ marginLeft: `${totalPercentage - 3}%` }}></div>
                    <ProgressBar now={totalPercentage} variant="primary" key={1} />
                </div>
            </div>
        </Card>
    )
}