
import { UserRentGuarantees } from "../fragments/user/UserRentGuarantees"
import { Navigate, useLocation } from "react-router-dom";
import useUser from "../hooks/user/useUser";
import "../styles/user.scss";
import { useGoogleAnalytics } from "../hooks/googleAnalytics/useGoogleAnalytics";
import useConfig from '../hooks/config/useConfig'
import { useEffect } from "react";
import {useNavigate} from 'react-router-dom'

const RentGuarantees = () =>{

    const { isLoggedIn } = useUser();
    const { pathname } = useLocation();
    const GA = useGoogleAnalytics(true)
    const {countryFeatures} = useConfig()
    const navigate = useNavigate()

    useEffect(()=>{
        if (countryFeatures.id && (!countryFeatures.rentWarranty || !countryFeatures.rentWarrantyPanel) ){
            navigate('/feed')
        }
    },[countryFeatures])

    if (!isLoggedIn )
    return <Navigate to="/iniciar-sesion" replace state={{ from: pathname }} />;

    return (
        <UserRentGuarantees />
    )

}

export default RentGuarantees