

import { useEffect, useState } from "react"
import { useFetch } from "../../../hooks/fetch/useFetch"
import { useUserFetch } from "../../../hooks/fetch/useUserFetch"
import { UpdateUserBody } from "../../../interfaces/userActivity"
import Lottie from "lottie-react";
import GrayLoader from "../../../lotties/gray-loader.json";

import { HexColorPicker } from "react-colorful";
import { isValidHexaCode } from "../../../utils/Functions"
import { Dropdown, Form, OverlayTrigger, Tooltip } from "react-bootstrap"

import useAlert from "../../../hooks/alert/useAlert";
import useUser from "../../../hooks/user/useUser"
import useConfig from "../../../hooks/config/useConfig"

import "./UserProfileForm.scss"
import { IconInfoCircle } from "../../../components/CustomIcons/IconInfoCircle";
import TitleCardTS from "../../../daisyUI/components/Cards/TitleCardTs";
import { AlertVariants } from "../../../context/Alert/AlertContext";

const UserProfileForm = () => {

    const { getUserDetail } = useFetch()
    const { updateProfile } = useUserFetch()
    const { setUser , userState} = useUser()
    const { translation } = useConfig()

    const initialData: UpdateUserBody = {
        name : userState.name,
        phone : userState.phone || '',
        real_estate : userState.real_estate || '',
        city : userState.city || '',
        image : userState.image || '',
        primary_color : userState.primary_color || '',
        image_file : null,
        delete_image : false
    }
    const initialErrors = {
        name: '',
        real_estate: '',
        city: '',
        primary_color: '',
        phone: '',
        image: '',
        image_file: null
    }
    const [userData, setUserData] = useState<UpdateUserBody>(initialData)

    const [errors, setErrors] = useState<any>(initialErrors)
    const [sent, setSend] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const { setPositionAlert, setTypeGlobalAlert, setShowGlobalAlert, setMessageGlobalAlert } = useAlert()

    // useEffect(() => {
    //     loadUserData()
    // }, [])

    const loadUserData = () => {
        getUserDetail().then(data => {
            if (data.success && data.data) {
                let user = data.data
                setUserData({
                    name: user.name,
                    real_estate: user.real_estate || '',
                    city: user.city || '',
                    primary_color: user.primary_color || '',
                    phone: user.phone || '',
                    image: user.image || '',
                    image_file: null,
                    delete_image: false
                })
                setUser(user)
                
            }
        }).catch(err => console.error(err))
    }

    const setValue = (field: string, value: string) => {
        setUserData((prev: any) => { return { ...prev, [field]: value } })
        if (sent) {
            validateForm()
        }
    }

    const updateUser = () => {

        setSend(true)
        if (validateForm()) {
            setLoading(true)
            updateProfile(userData).then(res => {
                if (res.success) {
                    loadUserData()
                    showAlertMessage('success', 'Información actualizada')
                } else {
                    showAlertMessage('danger', res.message ? res.message : 'Ocurrió un error')
                }
                setLoading(false)
            }).catch(err => {

                setLoading(false)
            })
        }

    }

    const validateForm = () => {

        let isValid = true
        setErrors(initialErrors)

        if (!userData.name || userData.name == '') {
            setErrors((prev: any) => { return { ...prev, name: 'Este campo es requerido' } })
            isValid = false
        }

        if (!userData.real_estate || userData.real_estate == '') {
            setErrors((prev: any) => { return { ...prev, real_estate: 'Este campo es requerido' } })
            isValid = false
        }

        if (!userData.city || userData.city == '') {
            setErrors((prev: any) => { return { ...prev, city: 'Este campo es requerido' } })
            isValid = false
        }

        if (!userData.phone || userData.phone == '') {
            setErrors((prev: any) => { return { ...prev, phone: 'Este campo es requerido' } })
            isValid = false
        }

        if (userData.primary_color && !isValidHexaCode(userData.primary_color)) {
            setErrors((prev: any) => { return { ...prev, primary_color: 'Ingresa un color en formato Hex. Ejm: #5052b9 o elige uno' } })
            isValid = false
        }


        return isValid
    }

    const showAlertMessage = (type: AlertVariants, message: string) => {
        setPositionAlert('bottom')
        setTypeGlobalAlert(type)
        setMessageGlobalAlert(message)
        setShowGlobalAlert(true)
        setTimeout(() => {
            setShowGlobalAlert(false)
            setTypeGlobalAlert('success')
            setMessageGlobalAlert('')
            setPositionAlert('top')
        }, 3000);

    }


    return (
        <TitleCardTS title="" topMargin="tw-pt-4 tw-border user-form ">
            <div className="user-profile-form tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-6">
                <div className="tw-flex tw-justify-start tw-flex-col tw-px-2">

                    <div className="tw-flex tw-justify-center tw-items-center image-container" >
                        <img className="tw-mb-2"
                            src={userData?.image && userData?.image != '' ? userData.image : "https://cdn1.infocasas.com.uy/web/62bdd922446a2_user-no-image.png"}
                            alt="" width={250} height={250} />
                    </div>


                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Elige tu logo</Form.Label>
                        <Form.Control type="file" className=" tw-w-full shadow-none " accept=".jpg,.jpeg,.png"
                            onChange={(ev: any) => {
                                if (ev.target && ev.target.files && ev.target.files[0]) {
                                    setUserData((prev: any) => { return { ...prev, image_file: ev.target.files[0] } })
                                }
                            }}
                        />
                    </Form.Group>

                    {userData?.image &&
                        <div className="tw-mt-2 " >
                            <Form.Group className="mb-3" controlId="deleteImg">
                                <Form.Check  >
                                    <Form.Check.Input type='checkbox' checked={userData.delete_image}
                                        onChange={(e) => { setUserData((prev: any) => { return { ...prev, delete_image: e.target.checked } }) }} />
                                    <Form.Check.Label>Eliminar mi logo </Form.Check.Label>
                                </Form.Check>
                            </Form.Group>


                        </div>

                    }
                </div>

                <div className=" tw-px-2">

                    <Form.Group controlId="name">
                        <Form.Label>Nombre *</Form.Label>
                        <Form.Control type="text" className={`${errors.name ? 'is-invalid' : ''}`} value={userData.name}
                            onChange={(ev: any) => setValue('name', ev.target.value)}
                        />
                    </Form.Group>
                    {errors.name && <p className="text-danger tw-text-sm ">{errors.name}</p>}

                    <Form.Group controlId="real_estate" className='mt-3'>
                        <Form.Label>{translation.register_inmo_field} *</Form.Label>
                        <Form.Control type="text" className={`${errors.real_estate ? 'is-invalid' : ''}`} value={userData.real_estate}
                            onChange={(ev: any) => setValue('real_estate', ev.target.value)}
                        />
                    </Form.Group>
                    {errors.name && <p className="text-danger tw-text-sm ">{errors.real_estate}</p>}

                    <Form.Group controlId="city" className='mt-3'>
                        <Form.Label>Ciudad *</Form.Label>
                        <Form.Control type="text" className={`${errors.city ? 'is-invalid' : ''}`} value={userData.city}
                            onChange={(ev: any) => setValue('city', ev.target.value)}
                        />
                    </Form.Group>
                    {errors.city && <p className="text-danger tw-text-sm ">{errors.real_estate}</p>}

                    <Form.Group controlId="city" className='mt-3'>
                        <Form.Label>Teléfono *</Form.Label>
                        <Form.Control type="text" className={`${errors.phone ? 'is-invalid' : ''}`} value={userData.phone}
                            onChange={(ev: any) => setValue('phone', ev.target.value)}
                        />
                    </Form.Group>
                    {errors.phone && <p className="text-danger tw-text-sm ">{errors.phone}</p>}


                    <div className="tw-relative mt-3">

                        <div className={`tw-form-control tw-w-full `}>
                            <Form.Group controlId="color" className='w-100'>
                                <Form.Label className='text-black'>Color de {translation.developer}
                                    <OverlayTrigger placement="right" overlay={
                                        <Tooltip id="tooltip">
                                            Personaliza las páginas de proyectos que compartes 
                                        </Tooltip>
                                    }>
                                        <span ><IconInfoCircle width="15" className="mx-2" /></span>
                                    </OverlayTrigger>

                                </Form.Label>

                                <Dropdown autoClose={'outside'} align="end" drop="up" >
                                    <Dropdown.Toggle id="dropdown-button-dark-example1" className="position-relative" >


                                        <Form.Control type="text" className={` tw-w-full input-color ${errors.primary_color ? 'is-invalid' : ''}`} value={userData.primary_color}
                                            onChange={(ev: any) => setValue('primary_color', ev.target.value)}
                                        />
                                        {
                                            userData?.primary_color && <div className="color-show" style={{ backgroundColor: userData?.primary_color }}></div>
                                        }

                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="color-picker tw-shadow-xl bg-white rounded  w-auto">
                                        <Dropdown.Item>
                                            <div >
                                                <HexColorPicker className="tw-absolute tw-top-0 "
                                                    color={userData.primary_color} onChange={(val) => setValue('primary_color', val)} />
                                            </div>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>

                                </Dropdown>
                            </Form.Group>
                            {errors.primary_color && <p className="text-danger tw-text-sm ">{errors.primary_color}</p>}

                        </div>
                    </div>

                </div>

            </div>


            <div className="mt-4">
                <button className="btn-save-profile w-100 tw-btn tw-btn-primary tw-float-right text-white tw-relative tw-flex"
                    onClick={updateUser} disabled={loading} style={{ minWidth: 180 }}>
                    Guardar
                    {loading && <Lottie className="position-absolute loader-btn top-1" animationData={GrayLoader} style={{ height: 40, right: 10 }} loop />}
                </button>
            </div>
        </TitleCardTS>
    )
}

export default UserProfileForm