import useCountry from "../../hooks/config/useConfig";

export const PropertyAgentCommision = ({
  commision,
  promos,
  old_before_promo
}: {
  commision: number;
  promos : any;
  old_before_promo : any
}) => {
  const { translation } = useCountry();
  const promoCommission = promos && promos != null && old_before_promo ? true : false

  return (
    <div className="property-agent-commision text-uppercase text-secondary bg-white rounded-1 shadow-sm border mt-2 d-flex align-items-center">
      <span className="text-hoverable">{translation.commision}:</span>

      {
        promoCommission && 
        <>
          <span className="promo-commission position-relative text-hoverable">
            <span className="text-gray strikethrough">{old_before_promo}%</span>
          </span>
          <div className="promo-commission-now fw-bold d-flex flex-column align-items-center">
            <span className="text-before ">AHORA:</span>
            <span className="text-gray new-commission text-green">{commision}%</span>
          </div>
        </>
      }
      {
        !promoCommission && 
        <span className="text-green">{commision}%</span>
      }
      
      
    </div>
  );
};
