import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Image } from "react-bootstrap"
import { PostTypeType } from "../../../interfaces/post";
import useBreakpoint from "use-breakpoint";
import { BREAKPOINTS } from "../../../utils/Constants";
import { useImage } from "../../../hooks/image/useImage";

export const FeedPostGridGallery = ({ images = [], idProject, idPost, postType, onClick }: { images: string[]; idProject: string; idPost: string; postType: string; onClick: (index: number) => void }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");


    const OptimizeImage = ({
        src,
        width,
        height,
        alt,
        classes,
        rounded = false
    }: {
        src: string,
        width: number,
        height: number,
        alt: string,
        classes: string,
        rounded: boolean
    }) => {
        const srcOptimize = useImage({ src, width, height })

        return (
            <Image
                src={srcOptimize}
                alt={alt}
                className={classes}
                height={height}
                rounded={rounded}
            ></Image>
        )
    }

    return (
        <Row className="post-grid-gallery mb-3">
            <Col
                className={(images.length > 2 ? "p-0 " : "") + (breakpoint !== "xs" ? "ps-3" : "px-3")} md={images.length > 2 ? 7 : 12}
                onClick={
                    idProject !== undefined ?
                        images.length === 1 || postType != "Proyecto"
                            ? () => navigate(`/feed/${idPost}`, { state: { from: pathname } })
                            : () => onClick(0)
                        : () => navigate(`/feed/`)
                }
            >
                <OptimizeImage
                    src={images[0]}
                    height={300}
                    width={400}
                    alt="Post image"
                    classes={"w-100 " + ((images.length <= 2 && postType !== "Proyecto") ? "fit-contain" : "fit-cover")}
                    rounded
                />
            </Col>
            {
                images.length > 2 &&
                <Col className={"pe-3 " + (breakpoint !== "xs" ? "ps-2" : "px-3 d-flex")} md={5}>
                    {
                        images.slice(1, 3).map((image, index) =>
                            <Row
                                key={`image-${index}`}
                                className={
                                    "p-0 m-0 position-relative " +
                                    (index === 0 ? (breakpoint === "xs" ? "w-50 mt-2 me-1" : 'mb-2 ') : (breakpoint === "xs" ? "w-50 mt-2 ms-1" : 'mt-2'))
                                }
                            >
                                {
                                    images.length > 2 && index === 1 ?
                                        <>
                                            <div className="overlay-post-image bg-black position-absolute" onClick={
                                                postType === "Proyecto"
                                                    ? () => navigate(`/proyecto/${idProject}`, { state: { from: pathname } })
                                                    : () => navigate(`/feed/${idPost}`, { state: { from: pathname } })
                                            }></div>
                                            <div className="overlay-number position-absolute h-100 w-100 text-white d-flex justify-content-center align-items-center" onClick={
                                                postType === "Proyecto"
                                                    ? () => navigate(`/proyecto/${idProject}`, { state: { from: pathname } })
                                                    : () => navigate(`/feed/${idPost}`, { state: { from: pathname } })
                                            }><h1>+{images.length - 2}</h1></div>
                                            <OptimizeImage
                                                src={image}
                                                height={146}
                                                width={250}
                                                alt="Post image"
                                                classes="p-0 fit-cover"
                                                rounded
                                            />
                                        </>
                                        : <div onClick={() => onClick(1)} className="p-0">
                                            <OptimizeImage
                                                src={image}
                                                height={146}
                                                width={250}
                                                alt="Post image"
                                                classes="p-0 w-100 fit-cover"
                                                rounded
                                            />
                                        </div>
                                }
                            </Row>
                        )
                    }
                </Col>
            }
        </Row>
    )
}