import { useEffect, useState } from "react"
import useConfig from "./useConfig";
import { useProjects } from "../projects/useProjects";
import { CountryFeatures, HeaderTabs, ProjectUrlTypes } from "../../interfaces/config";
import { CountryType } from "../../interfaces/property";
import { ProjectTypeType } from "../../interfaces/project";
import { compareUrlsExceptParam } from "../../utils/Functions";
import { PROPERTIES_VALUES } from "../../utils/Constants";
import { countriesFeatures } from "../../data/countriesFeatures";

const {LAND_TYPE , HOUSE_TYPE , LOCAL_TYPE, OFFICE_TYPE, APARTMENT_TYPE , 
    INDUSTRIAL_LOCAL_TYPE} = PROPERTIES_VALUES

export const useLinks = () => {

    const initialHeaderLinks: ProjectUrlTypes = {
        all: '/proyectos',
        residency: '',
        comercial: '',
        lands: '',
        promos: '/proyectos'
    }
    const [headerLinks, setHeaderLinks] = useState<ProjectUrlTypes>(initialHeaderLinks)
    const [mainProjectsUrl, setMainProjectsUrl] = useState('')
    const [mainProjectsUrlByCountry, setAllMainProjectsUrlByCountry] = useState<any>()
    const [activeProjectsTab , setActiveProjectsTab] = useState<HeaderTabs>('')

    const { country, countryFeatures, config, allCountries } = useConfig()
    const { projectStatusList, projectTypesList, projectStatusUsed } = useProjects()


    useEffect(() => {
        if (country && projectStatusList?.length > 0 && projectTypesList?.length > 0 && allCountries.length>0) {

            const mainType = countryFeatures.main_projects_type || 'all'
            const urlProjects = getHeaderUrls(config.country.id , countryFeatures)
            if (urlProjects) {
                setHeaderLinks(urlProjects)
                setMainProjectsUrl(urlProjects[mainType])
            }

            if (projectTypesList) {
                let allMainUrls = {}
                allCountries.map(country => {
                    allMainUrls = { ...allMainUrls, [country.code]: getMainProjectUrlByCountry(country , countriesFeatures[country.code] ) }
                })
                
                setAllMainProjectsUrlByCountry(allMainUrls)
            }
        }
    }, [config, projectStatusList, projectTypesList, allCountries, countryFeatures])

    const getMainProjectUrlByCountry = (country: CountryType, countryFeatures : CountryFeatures) => {
        let urls = getHeaderUrls(country.id , countryFeatures)

        if (urls && country.code) {
            if (countryFeatures?.main_projects_type) {
                return urls[countryFeatures?.main_projects_type]
            }
        }
        return ''

    }


    useEffect(() => {
        getActiveTab()
    }, [headerLinks])

    const getActiveTab = () => {

        const operationType = localStorage.getItem('OperationType')
        const { pathname, search, host, protocol } = window.location
        const protocolHost = protocol + host
        const full = decodeURIComponent(protocolHost + pathname + search)

        const paramToExclude = "country";

        if (full.includes('proyectos') && operationType == 'rent') {
            setActiveProjectsTab('rent')
        } else {

            if (full.includes('proyectos') && compareUrlsExceptParam(full, protocolHost + headerLinks.all, paramToExclude)) {
                setActiveProjectsTab('sales')
            } else if (full.includes('proyectos') && compareUrlsExceptParam(full, protocolHost + headerLinks.residency, paramToExclude)) {
                setActiveProjectsTab('residency')
            } else if (full.includes('proyectos') && compareUrlsExceptParam(full, protocolHost + headerLinks.comercial, paramToExclude)) {
                setActiveProjectsTab('comercial')
            } else if (full.includes('proyectos') && compareUrlsExceptParam(full, protocolHost + headerLinks.lands, paramToExclude)) {
                setActiveProjectsTab('lands')
            } else {
                setActiveProjectsTab('')
            }
        }

    }

    const getHeaderUrls = (countryId: any , countryFeatures : CountryFeatures ) => {

        if (!countryId || !projectTypesList) {
            return false
        }
    
        const projectTypes = {
            land : projectTypesList.find(t => t.identifier == LAND_TYPE),
            house : projectTypesList.find(t => t.identifier == HOUSE_TYPE),
            apartment : projectTypesList.find(t => t.identifier == APARTMENT_TYPE),
            local :projectTypesList.find(t => t.identifier == LOCAL_TYPE),
            office : projectTypesList.find(t => t.identifier == OFFICE_TYPE),
            industrialLocal : projectTypesList.find(t => t.identifier == INDUSTRIAL_LOCAL_TYPE)
        }
    
    
        let startUrl = `/proyectos?country=${countryId}`

        //If has "used" projects, then in main sale section not list those used projects.
        if(countryFeatures.used_properties){
            const notUsedStatus = getNotUsedStatus()
            startUrl = `${startUrl}&state=${notUsedStatus}`
        }
    
        let urls: ProjectUrlTypes = {
            all: startUrl,
            residency: `${startUrl}&propertyType=${projectTypes?.house?.id},${projectTypes?.apartment?.id}`,
            comercial: `${startUrl}&propertyType=${projectTypes?.local?.id},${projectTypes?.office?.id},${projectTypes?.industrialLocal?.id}`,
            lands: `${startUrl}&propertyType=${projectTypes?.land?.id}`,
            promos: `${startUrl}&is_promo=1`,
        }
    
        return urls
    
    }
    
    const getNotUsedStatus = () => {
        return projectStatusList?.filter(status => status.id != projectStatusUsed)
        .map(status => status.id)
        .join(',')
    }

    return {
        headerLinks,
        mainProjectsUrl,
        mainProjectsUrlByCountry,
        activeProjectsTab
    }
}
