export const IconSeaView = ({ width = 20 }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 12.5C4.56917 12.5 6.12083 10 6.12083 10C6.12083 10 7.6725 12.5 9.74167 12.5C11.81 12.5 13.8792 10 13.8792 10C13.8792 10 15.9483 12.5 17.5 12.5"
        stroke={"currentColor"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 16.6667C4.56917 16.6667 6.12083 14.1667 6.12083 14.1667C6.12083 14.1667 7.6725 16.6667 9.74167 16.6667C11.81 16.6667 13.8792 14.1667 13.8792 14.1667C13.8792 14.1667 15.9483 16.6667 17.5 16.6667"
        stroke={"currentColor"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8332 8.33333C15.8332 7.56729 15.6823 6.80875 15.3891 6.10101C15.096 5.39328 14.6663 4.75022 14.1246 4.20854C13.583 3.66687 12.9399 3.23719 12.2322 2.94404C11.5244 2.65088 10.7659 2.5 9.99984 2.5C9.23379 2.5 8.47525 2.65088 7.76752 2.94404C7.05978 3.23719 6.41672 3.66687 5.87505 4.20854C5.33337 4.75022 4.90369 5.39328 4.61054 6.10101C4.31739 6.80875 4.1665 7.56729 4.1665 8.33333"
        stroke={"currentColor"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
