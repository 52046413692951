export const OverlayLevelBlock = ({listType , requiredLevel = '10'} : 
    {listType: "grid" | "list", requiredLevel ? : string}) => {
    return (
        <div
            className={`overlay-invalid-level position-absolute type-${listType}`}
        >
            <div className="lock-container shadow">
            <i className="bi bi-lock-fill"></i>
            </div>
            <p className="m-0">
            <span className="fw-bold text-secondary d-block">Avance al nivel {requiredLevel}</span>
            <span className="text-secondary">para desbloquear esta propiedad</span>
            </p>
        </div>
    )
}