export const useCookies = () => {
  const getCookie = (cookieName: string) => {
    var cookieArr = document.cookie.split(";");
    for (var i = 0; i < cookieArr.length; i++) {
      var cookiePair = cookieArr[i].split("=");
      if (cookieName === cookiePair[0].trim()) {
        return true;
      }
    }
    return false;
  };

  const setCookie = (cookieName: string, expires: string) => {
    document.cookie = cookieName + expires;
  };

  return {
    getCookie,
    setCookie,
  };
};
