import { Card, Placeholder } from "react-bootstrap"

export const SkeletonPropertyCard = () => {
    return (
        <Card>
            <div className="loader-img"></div>
            <Card.Body>
                <Placeholder as="span" animation="glow">
                    <Placeholder xs={5} /> <Placeholder xs={6} />
                </Placeholder>
                <Placeholder as={Card.Title} animation="glow">
                    <Placeholder xs={7} />
                </Placeholder>
                <Placeholder as={Card.Text} animation="glow">
                    <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                    <Placeholder xs={6} /> <Placeholder xs={8} />
                </Placeholder>
            </Card.Body>
        </Card>
    )
}