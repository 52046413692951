import { Link } from "react-router-dom"
import { PlanList } from "../../components/Services/VirtualSign/PlanList"

export const VirtualSign = () => {

    return <>
        <main className="main main-container container p-3 p-sm-5">
            <div className="d-flex justify-content-center justify-content-md-between mb-3 flex-wrap">
                <div className="d-flex align-items-center justify-content-center justify-content-md-start flex-wrap">
                    <img src="/images/services/firma.png" alt="" width={200} className="mx-2" />
                    <a href="https://empresa.portalfirma.cl" className="text-primary text-decoration-underline mt-1 mt-md-0" target="_blank">Accede a tu portal empresa</a>
                </div>
                <div className="mt-3 mt-md-0">
                    <Link to={'https://api.whatsapp.com/send?phone=56972525400&text=Hola, te escribo de Iris Yapo, tengo dudas, ¿me puedes ayudar?'} target="_blank" >
                        <button className="btn btn-green border-color-green bg-white  ml-2 mx-2" >
                            Chatea con nosotros <i className="bi bi-whatsapp"></i>
                        </button>
                    </Link>
                </div>
            </div>
            <PlanList />
        </main>

    </>
}