import { ProjectType, UnitType } from "../../../interfaces/project"
import useConfig from '../../../hooks/config/useConfig'

export const SmallFeatures = ({ isLand, project, unit, showOutdoorM2 }:
    { isLand: boolean, project: ProjectType, unit: UnitType, showOutdoorM2: boolean }) => {

    const { translation } = useConfig()
    return (
        <div className="text-center border-1 pb-3">
            <span className="fw-500 text-center  d-block">
                {isLand ? "Lote" : "Unidad"} {unit.number} ·{" "}
                {!isLand ? (unit.floor === '0' ? "Planta Baja" : `Piso ${unit.floor}`) : ""}
            </span>
            <span className="fw-500 text-center d-block">
                {`${unit.m2}m² ${isLand ? "totales" : translation.m2_indoor} ${showOutdoorM2 ? `· ${unit.m2_outdoor}m² ${translation.m2_outdoor}` : ""}`}
            </span>

            {!isLand && (
                <div className=" d-flex w-100 justify-content-center">
                    {
                        unit.tipology != 'Local Comercial' &&
                        <div className="">
                            <p>
                                {unit.bedrooms > 0
                                    ? unit.bedrooms > 1
                                        ? `${unit.bedrooms} ${translation.bedrooms}`
                                        : `1 ${translation.single_bedroom}`
                                    : translation.studio}
                            </p>
                        </div>
                    }

                    <div className="">
                        <p> - {unit.bathrooms} {`${unit.bathrooms !== 1 ? "baños" : "baño"}`}</p>
                    </div>
                </div>
            )}

            {project.country?.code == 'CL' && unit.orientation && (
                <div className="">
                    <p >Orientación: {unit.orientation}</p>
                </div>
            )}
        </div>
    )
}