import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import userFilter from "../../../hooks/filter/useFilter";
import { useSearchParams } from "react-router-dom";
import { uniqueFromArray, excludeFromArray } from "../../../utils/Functions";

export const FilterSubsidy = () => {
  const { setFilter } = userFilter();
  const [searchParams] = useSearchParams();
  const [currentSubsidy, setCurrentSubsidy] = useState([] as string[]);

  const subsidyLaws = [
    {
      value: "DFL2",
      label: "DFL2"
    },
    {
      value: "DS19",
      label: "DS19"
    },
    {
      value: "DS49",
      label: "DS49"
    },
    {
      value: "DS1",
      label: "DS1"
    }
  ]

  useEffect(() => {
    const parameter = searchParams.get("subsidy");

    setCurrentSubsidy(
      !!parameter
        ? subsidyLaws.filter((t) => parameter.split(",").includes(t.value)).map((t) => t.value)
        : ([] as string[])
    );
  }, [searchParams]);

  return (
    <Form>
      {subsidyLaws.map((f) => (
        <div key={`filter-${f.label}`} className="mb-1">
          <label className="d-inline-flex w-100 text-capitalize">
            <Form.Check
              className="me-2"
              type="checkbox"
              value={f.value}
              checked={currentSubsidy.includes(f.value) ?? false}
              onChange={(e) => {
                const current = currentSubsidy ?? [];
                setFilter({
                  subsidy: e.target.checked
                    ? (uniqueFromArray([
                      ...current,
                      f.value,
                    ]) as unknown as string[])
                    : (excludeFromArray(
                      current,
                      f.value
                    ) as unknown as string[]),
                });
              }}
            />
            {f.label}
          </label>
        </div>
      ))}
    </Form>
  );
};
