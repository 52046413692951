import { Col, Row } from "react-bootstrap";
import useUser from "../../../hooks/user/useUser";

export const PropertyTableHeader = ({showSocialHousing} : {showSocialHousing : boolean}) => {
    const { userState } = useUser();
    
    return (
        <Row className="property-header gx-2 gy-3 pt-1 pb-3">
            <Col className="first-column"></Col>
            <Col>Nombre</Col>
            <Col xs={2} sm={2} md={2} lg={2}>Zona</Col>
            <Col>Entrega</Col>
            <Col xs={2} sm={2} md={2} lg={2}>Precio desde</Col>
            <Col>Realizado por</Col>
            <Col xs={1} sm={1} md={1} lg={1}>Comisión</Col>
            {showSocialHousing && <Col xs={1} sm={1} md={1} lg={1}>Ley VP</Col> }
        </Row>
    )
}