import { useState, createRef, useEffect } from "react";
import { Button, Form, Row, Col, Alert } from "react-bootstrap";
import { register, login } from "../../data/api";
import { useLocation, useNavigate } from "react-router-dom";
import { RegisterResponseType, LoginResponseType, UserInfoResponseType, User } from "../../interfaces/user";

import useUser from "../../hooks/user/useUser";
import { useFetch } from "../../hooks/fetch/useFetch"
import useConfig from "../../hooks/config/useConfig";
import { institutionalLinks } from "../../data/countriesFeatures";
import { Institutional } from "../../interfaces/config";
import SpecialSelect from "../../components/ui/SpecialSelect";
import ReferredSection from "../../components/Register/ReferredSection";
import { useLinks } from "../../hooks/config/useLinks";

interface RegisterProps {
  changeType: any
}
export const RegisterForm = (props: RegisterProps) => {
  const { loginUser, saveAccessToken, saveRefreshToken } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const { getUserDetail } = useFetch()
  const { country, translation } = useConfig()
  const { mainProjectsUrl } = useLinks()
  const [showAlert, setShowAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState('success');
  const [messageAlert, setMessageAlert] = useState("");
  const [validated, setValidated] = useState(false);
  const [institutional, setInstitutional] = useState<Institutional>();
  const INITIAL_DATA = {
    name: "",
    phone: "",
    real_estate: "",
    city: "",
    email: "",
    password: "",
    repeat_password: "",
    showPassword: false,
    showRepeatPassword: false,
    refer_type: '',
    refer_real_estate: '',
    refer_name: '',
    refer_phone: '',
    referred: false
  }
  const [dataUser, setDataUser] = useState(INITIAL_DATA);
  const defaultEmailMessage = "Escriba un email válido"
  const defaultPasswordMessage = "Escriba una contraseña válida"
  const defaultNameMessage = "Escriba un nombre válido"
  const defaultPhoneMessage = "Escriba un teléfono válida"
  const defaultRealEstateMessage = "Escriba una " + translation.register_inmo_field + " válido(a)"
  const defaultCityMessage = "Escriba una ciudad válida"
  const defaultRepeatPasswordMessage = "Escriba una contraseña válida"

  const defaultMessages = {
    name: defaultNameMessage,
    phone: defaultPhoneMessage,
    real_estate: defaultRealEstateMessage,
    city: defaultCityMessage,
    email: defaultEmailMessage,
    password: defaultPasswordMessage,
    repeat_password: defaultRepeatPasswordMessage,
    terms: 'Debe aceptar los términos y condiciones',
    refer_real_estate : 'Campo requerido',
    refer_name : 'Campo requerido',
    refer_phone : 'Campo requerido'
  }
  const [fieldsErrorMessages, setFieldsErrorMessages] = useState(defaultMessages)
  const [termsAccepted, setTermsAccepted] = useState(false)

  const skipInputRef: React.RefObject<HTMLButtonElement> = createRef();
  const skipInputRefRepeat: React.RefObject<HTMLButtonElement> = createRef();

  useEffect(() => {
    if (skipInputRef.current) skipInputRef.current.tabIndex = -1;
    if (skipInputRefRepeat.current) skipInputRefRepeat.current.tabIndex = -1;
  }, []);

  useEffect(() => {
    if (country !== "") {
      const privacy = institutionalLinks[country].find((link) => link.code === "termsConditions")

      setInstitutional(privacy)
    }
  }, [country])

  const validateForm = () => {
    return dataUser.email.length > 0 && dataUser.password.length > 0 &&
      dataUser.name.length > 0 && dataUser.phone.length > 0 &&
      dataUser.real_estate.length > 0 && dataUser.city.length > 0 &&
      dataUser.repeat_password.length > 0 &&
      (!!institutional ? termsAccepted : true);
  };

  const onKeyUp = (event: any) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;
    Object.keys(defaultMessages).forEach(key => {
      form[key]?.setCustomValidity('')
    })
    setFieldsErrorMessages(defaultMessages);
    setValidated(true);
    dataUser.email = dataUser.email.toLowerCase()


    if (form.checkValidity() !== false) {
      let isValidate = validateFields();

      if (isValidate.status === "error" && isValidate.field) {
        let field = isValidate.field?.toString()
        setFieldsErrorMessages(prev => {
          return {
            ...prev,
            [field]: isValidate.message
          }
        })
        form[field]?.setCustomValidity('invalid')
        return;
      }

      if(!dataUser.referred){
        dataUser.refer_type = ''
        dataUser.refer_name = ''
        dataUser.refer_real_estate = ''
        dataUser.refer_phone = ''
      }


      let sendData = {
        ...dataUser,
        country: country
      };

      register(sendData).then(
        (response: RegisterResponseType) => {
          if (response.success) {
            
            if(response.data.allow_access){
              showAlertMessage('success', '¡Registro exitoso!')
              setTimeout(() => {
                //props.changeType()   
                loginUserRegistered(dataUser)
              }, 1000);
            }else{
              showAlertMessage('success', response.message)
              cleanData()
            }
            
          } else {
            showAlertMessage('danger', response.message)
          }
        }
      );
    }
  };

  const loginUserRegistered = (dataUser : any ) => {

    login(dataUser.email, dataUser.password).then(
      async (response: LoginResponseType) => {
        if (response.access_token) {
          //Se logueó
          saveAccessToken(response.access_token)
          if (response.refresh_token) {
            saveRefreshToken(response.refresh_token)
          }
          await getUserDetail().then(
            (result: UserInfoResponseType) => {

              if (result.success && result.data) {
                //TODO : Temporal hardcodeado false
                result.data['crm'] = false;
                let userFounded: User = result.data!

                loginUser(userFounded)

                let search = (location.state?.search && location.state?.search != '') ? location.state?.search : ''
                let full = (location.state as any)?.from
                if (full && search && search != 'undefined' && search != '') {
                  full += search
                }

                navigate(full || mainProjectsUrl);
              } else {
                showAlertMessage('danger', 'No se pudo encontrar el usuario')
              }
            })
            .catch(err => {
              console.log(err)
              showAlertMessage('danger', 'Ocurrió un error')
            })

        } else if (response.message) {
          showAlertMessage('danger', response.message)
        } else {
          showAlertMessage('danger', 'Ocurrió un error')
        }

      }).catch(err => {
        console.log(err)
        showAlertMessage('danger', 'Ocurrió un error')
      })
  }

  const cleanData = () => {
    setValidated(false);
    setDataUser(INITIAL_DATA)
  }

  const showAlertMessage = (type: string, message: string) => {
    setTypeAlert(type)
    setMessageAlert(message)
    setShowAlert(true)

  }

  const validateFields = () => {
    if (typeof dataUser.email === 'undefined' || dataUser.email.length === 0)
      return { status: 'error', message: 'Ingrese un correo', field: 'email' }
    if (!dataUser.email.match(/^[A-Z0-9._%+-\s]+@[A-Z0-9.-\s]+\.[A-Z]{2,4}$/i))
      return { status: 'error', message: 'Email Invalido', field: 'email' }

    if (typeof dataUser.password === 'undefined' || dataUser.password.length < 8)
      return {
        status: 'error',
        message: 'La contraseña debe tener mínimo 8 caracteres',
        field: 'password',
      }

    if (typeof dataUser.repeat_password === 'undefined' || dataUser.repeat_password.length < 8)
      return {
        status: 'error',
        message: 'La contraseña debe tener mínimo 8 caracteres',
        field: 'repeat_password',
      }
    if (dataUser.password !== dataUser.repeat_password) {
      return { status: 'error', message: 'La contraseña debe coincidir', field: 'repeat_password' }
    }

    if (typeof dataUser.name === 'undefined' || dataUser.name.trim() === '') return { status: 'error', message: 'Nombre requerido', field: 'name' }
    if (typeof dataUser.phone === 'undefined' || dataUser.phone.trim() === '') return { status: 'error', message: 'Teléfono requerido', field: 'phone' }
    else {
      if (!dataUser.phone.match(/^\d+$/)) {
        return { status: 'error', message: 'Teléfono inválido: solo números', field: 'phone' }
      }
      if (dataUser.phone.length < 5) return { status: 'error', message: 'Mínimo 5 dígitos', field: 'phone' }
    }
    if (typeof dataUser.real_estate === 'undefined' || dataUser.real_estate.trim() === '')
      return { status: 'error', message: translation.register_inmo_field + ' requerido', field: 'real_estate' }
    if (typeof dataUser.city === 'undefined' || dataUser.city.trim() === '') return { status: 'error', message: 'Ciudad requerida', field: 'city' }

    if (!termsAccepted && !!institutional) return { status: 'error', message: defaultMessages.terms, field: 'terms' }

    if(dataUser.referred && dataUser.refer_type == 'Inmobiliaria'){
      if(dataUser.refer_real_estate.trim() == '') return { status: 'error', message: defaultMessages.refer_real_estate, field: 'refer_real_estate' }
      if(dataUser.refer_name.trim() == '') return { status: 'error', message: defaultMessages.refer_name, field: 'refer_name' }
    }
    
    return { status: 'ok' }
  }

  return (
    <>

      <Form noValidate validated={validated} onSubmit={handleSubmit} className="login-form w-100">
        <h2 className="fw-bold mb-3">Regístrate</h2>
        <Row className="d-flex ">

          <Col lg='6' sm='12'>
            <Form.Group className="w-100 mb-3" controlId="name">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                className="text-input "
                value={dataUser.name}
                onChange={(e) => setDataUser({ ...dataUser, name: e.target.value })}
                autoFocus
                required
              />
              <Form.Control.Feedback type="invalid">
                {fieldsErrorMessages.name}
              </Form.Control.Feedback>
            </Form.Group>

          </Col>

          <Col lg='6' sm='12'>
            <Form.Group className="w-100 mb-3" controlId="real_estate">
              <Form.Label>{translation.register_inmo_field}</Form.Label>
              <Form.Control
                type="text"
                className="text-input "
                value={dataUser.real_estate}
                onChange={(e) => setDataUser({ ...dataUser, real_estate: e.target.value })}

                required
              />
              <Form.Control.Feedback type="invalid">
                {fieldsErrorMessages.real_estate}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg='6' sm='12'>
            <Form.Group className="w-100 mb-3" controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                className="email-input "
                value={dataUser.email}
                onChange={(e) => setDataUser({ ...dataUser, email: e.target.value })}
                required
                autoComplete="new-password"
              />
              <Form.Control.Feedback type="invalid">
                {fieldsErrorMessages.email}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg='6' sm='12'>
            <Form.Group className="w-100 mb-3" controlId="phone">
              <Form.Label>Teléfono</Form.Label>
              <Form.Control
                type="text"
                className="text-input w-100"
                value={dataUser.phone}
                onChange={(e) => setDataUser({ ...dataUser, phone: e.target.value.trim() })}
                required
              />
              <Form.Control.Feedback type="invalid">
                {fieldsErrorMessages.phone}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>


          <Col lg='6' sm='12'>
            <Form.Group className="w-100 mb-3" controlId="city">
              <Form.Label>Ciudad</Form.Label>
              <Form.Control
                type="text"
                className="text-input w-100"
                value={dataUser.city}
                onChange={(e) => setDataUser({ ...dataUser, city: e.target.value })}
                required
              />
              <Form.Control.Feedback type="invalid">
                {fieldsErrorMessages.city}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg='6' >
            <Form.Group
              className="w-100 mb-3 position-relative"
              controlId="password"
            >
              <Form.Label>Contraseña </Form.Label>
              <Form.Control
                value={dataUser.password}
                onChange={(e) =>
                  setDataUser({ ...dataUser, password: e.target.value })
                }
                onKeyUp={onKeyUp}
                className="password-input"
                type={dataUser.showPassword ? "text" : "password"}
                required
                minLength={1}
                autoComplete="new-password"

              />
              <button
                type="button"
                className="login-icon-password float-end"
                ref={skipInputRef}
                onClick={(e) => {
                  setDataUser({
                    ...dataUser,
                    showPassword: !dataUser.showPassword,
                  })
                }

                }
              >
                <i
                  className={`"bi" ${dataUser.showPassword ? "bi-eye-slash" : "bi-eye"
                    }`}
                ></i>
              </button>
              <Form.Control.Feedback type="invalid">
                {fieldsErrorMessages.password}
              </Form.Control.Feedback>
            </Form.Group>

          </Col>

          <Col lg='6' sm='12'>
            <Form.Group
              className="w-100 mb-3 position-relative"
              controlId="repeat_password"
            >
              <Form.Label>Repetir Contraseña</Form.Label>
              <Form.Control
                value={dataUser.repeat_password}
                onChange={(e) =>
                  setDataUser({ ...dataUser, repeat_password: e.target.value })
                }
                onKeyUp={onKeyUp}
                className="password-input"
                type={dataUser.showRepeatPassword ? "text" : "password"}
                required
                minLength={1}
              />
              <button
                type="button"
                className="login-icon-password float-end"
                ref={skipInputRefRepeat}
                onClick={(e) =>
                  setDataUser({
                    ...dataUser,
                    showRepeatPassword: !dataUser.showRepeatPassword,
                  })
                }
              >
                <i
                  className={`"bi" ${dataUser.showRepeatPassword ? "bi-eye-slash" : "bi-eye"
                    }`}
                ></i>
              </button>
              <Form.Control.Feedback type="invalid">
                {fieldsErrorMessages.repeat_password}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>


          <Col lg='12' sm='12'>
            <Form.Group className="w-100 mb-3" controlId="referred">

              <Form.Check  >
                <Form.Check.Input type='checkbox' checked={dataUser.referred}
                  onChange={(e) => { setDataUser((prev: any) => ( 
                    { ...prev, referred: e.target.checked , refer_type : 'Inmobiliaria' } )) }} />
                <Form.Check.Label>Fui referido </Form.Check.Label>
              </Form.Check>
            </Form.Group>
          </Col>

          {
            (dataUser.referred) &&
            <ReferredSection dataUser={dataUser} setDataUser={setDataUser} />
          }


          <Col lg='12' sm='12'></Col>

          {!!institutional && (
            <Col lg='6' sm='12'>
              <Form.Group className="mb-3" controlId="terms">

                <Form.Check  >
                  <Form.Check.Input type='checkbox' checked={termsAccepted}
                    onChange={(e) => { setTermsAccepted(e.target.checked) }} />
                  <Form.Check.Label>Acepto los  &nbsp;
                    <a href={institutional.link} target="_blank" rel="noreferrer">
                      términos y condiciones</a>  &nbsp;
                    de la plataforma. </Form.Check.Label>
                  <Form.Control.Feedback type="invalid">
                    {fieldsErrorMessages.terms}
                  </Form.Control.Feedback>
                </Form.Check>

              </Form.Group>
            </Col>
          )}

          <Col lg='12' className="text-center">
            <Alert variant={typeAlert} show={showAlert} onClose={() => setShowAlert(false)} dismissible>
              {messageAlert}
            </Alert>  <br></br>

            <Button
              className="mb-2 px-5 register-button"
              variant="primary"
              type="submit"
              disabled={!validateForm()}
            >
              Registrarme
            </Button>
            <p className="">
              ¿Ya tienes cuenta? &nbsp;
              <a href="" onClick={(e) => {
                e.preventDefault();
                props.changeType();
              }} target={"_blank"} >Ingresa aqui</a>
            </p>
          </Col>
        </Row>

      </Form>

    </>
  );
};
