import { IconElevator } from "../CustomIcons/IconElevator";
import { IconGarage } from "../CustomIcons/IconGarage";
import { IconAC } from "../CustomIcons/IconAC";
import { IconSecurity } from "../CustomIcons/IconSecurity";
import { IconBalcony } from "../CustomIcons/IconBalcony";
import { IconSwimmingPool } from "../CustomIcons/IconSwimmingPool";
import { IconLaundry } from "../CustomIcons/IconLaundry";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import useCountry from "../../hooks/config/useConfig";

export const PropertyCardAmenities = ({
  hasElevator,
  hasGarage,
  hasAC,
  hasSecurity,
  hasBalcony,
  hasSwimmingPool,
  hasLaundry,
}: {
  hasElevator: boolean;
  hasGarage: boolean;
  hasAC: boolean;
  hasSecurity: boolean;
  hasBalcony: boolean;
  hasSwimmingPool: boolean;
  hasLaundry: boolean;
}) => {
  const { translation } = useCountry();

  const amenities = [
    {
      title: "Ascensor",
      icon: <IconElevator width={19} />,
      has: hasElevator,
    },
    {
      title: translation.garage,
      icon: <IconGarage width={21} />,
      has: hasGarage,
    },
    {
      title: translation.air_conditioner,
      icon: <IconAC width={20} />,
      has: hasAC,
    },
    {
      title: "Seguridad",
      icon: <IconSecurity width={22} />,
      has: hasSecurity,
    },
    {
      title: "Balcón",
      icon: <IconBalcony width={22} />,
      has: hasBalcony,
    },
    {
      title: "Piscina",
      icon: <IconSwimmingPool width={23} />,
      has: hasSwimmingPool,
    },
    {
      title: "Lavanderia",
      icon: <IconLaundry width={21} />,
      has: hasLaundry,
    },
  ];
  return (
    <div className="d-flex justify-content-between w-100">
      {amenities.map(({ has, icon, title }, i) => (
        <OverlayTrigger
          key={"amenitie_card_" + i}
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={(props) => (
            <Tooltip id="button-tooltip" className={!has ? "d-none" : ""} {...props}>{has && title}</Tooltip>
          )}
        >
          <div className={has ? "text-secondary" : "text-light"}>{icon}</div>
        </OverlayTrigger>
      ))}
    </div>
  );
};
