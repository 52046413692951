import React from "react";
import { Row, Col } from "react-bootstrap";

import { PropertyType, DetailsType, OperationType, CountryType } from "../../interfaces/property";
import { cleanHtmlFromString } from "../../utils/Functions";
import { formatMoney } from "../../utils/Functions";

import { PropertyBedroom } from "../PropertyBedroom/PropertyBedroom";
import { PropertyGallery } from "../PropertyGallery/PropertyGallery";
import { PropertyMeters } from "../PropertyMeters/PropertyMeters";
import { PropertyPrice } from "../PropertyPrice/PropertyPrice";
import { PropertyAmenities } from "../PropertyAmenities/PropertyAmenities";
import { PropertyCaracteristics } from "../PropertyCaracteristics/PropertyCaracteristics";
import { PropertyFinancial } from "../PropertyFinancial/PropertyFinancial";
import { PropertyPayment } from "../PropertyFinancial/PropertyPayment";

import { IconBed } from "../../components/CustomIcons/IconBed";
import { IconShower } from "../../components/CustomIcons/IconShower";
import { IconWheelChair } from "../../components/CustomIcons/IconWheelChair";
import { IconElevator } from "../CustomIcons/IconElevator";
import { IconGarage } from "../CustomIcons/IconGarage";
import { IconLaundry } from "../CustomIcons/IconLaundry";
import { IconSecurity } from "../CustomIcons/IconSecurity";
import { IconSecurityCamera } from "../CustomIcons/IconSecurityCamera";
import { IconSwimmingPool } from "../CustomIcons/IconSwimmingPool";
import { IconGarden } from "../CustomIcons/IconGarden";
import { IconBarbacue } from "../CustomIcons/IconBarbacue";
import { IconPlayroom } from "../CustomIcons/IconPlayroom";
import { IconSeaView } from "../CustomIcons/IconSeaView";
import { IconSUM } from "../CustomIcons/IconSUM";
import { NeighbourhoodDetails } from "../../components/NeighbourhoodDetails/NeighbourhoodDetails";
import { PropertyTypeAndOperation } from "../PropertyTypeAndOperation/PropertyTypeAndOperation";
import { IconCrop } from "../CustomIcons/IconCrop";
import { IconX } from "../CustomIcons/IconX";
import { IconCheckCircle } from "../CustomIcons/IconCheckCircle";
import { ProjectType, UnitType, GarageType } from "../../interfaces/project";
import { Country } from "../../interfaces/locations";
import { CountryFeatures } from "../../interfaces/config";
import { PROPERTIES_VALUES } from "../../utils/Constants";

interface PdfData extends ProjectType {
    logo: string;
    operation?: OperationType;
    translation: any;
    property: ProjectType;
    propertyCountry: Country
    countryFeaturesWeb : CountryFeatures
}

export class DownloadComponent extends React.PureComponent<{}, {
    property: ProjectType | any,
    amenities: any[],
    operation: any,
    translation: any,
    country: Country,
    propertyCountry: Country,
    countryFeaturesWeb : CountryFeatures
}> {
    constructor(props: PdfData) {
        super(props);
        const translation = props.translation;

        const amenities = [
            {
                title: "Ascensor",
                icon: <IconElevator />,
                show: props.has_elevator,
            },
            {
                title: translation.garage,
                icon: <IconGarage />,
                show: props.has_garage,
            },
            {
                title: "Seguridad",
                icon: <IconSecurity width={19} />,
                show: props.has_security,
            },
            {
                title: "Piscina",
                icon: <IconSwimmingPool width={20} />,
                show: props.has_swimming_pool,
            },
            {
                title: "Lavanderia",
                icon: <IconLaundry />,
                show: props.has_laundry,
            },
            {
                title: "Vista al Mar",
                icon: <IconSeaView />,
                show: props.has_sea_view,
            },
            {
                title: "Jardin",
                icon: <IconGarden width={21} />,
                show: props.has_garden,
            },
            {
                title: "SUM",
                icon: <IconSUM width={20} />,
                show: props.has_sum ,
            },
            {
                title: "Playroom",
                icon: <IconPlayroom width={21} />,
                show: props.has_playroom,
            },
            {
                title: translation.griller,
                icon: <IconBarbacue width={23} />,
                show: props.has_barbecue,
            },
            {
                title: "Cámaras de Seguridad",
                icon: <IconSecurityCamera width={20} />,
                show: props.has_security_cameras,
            },
        ];

        const operation = props.operation;
        const propertyCountry = props.propertyCountry

        this.state = { property: { ...props }, amenities: amenities, operation, translation: translation, country: props.country, propertyCountry: propertyCountry, countryFeaturesWeb :props.countryFeaturesWeb };
    }

    render() {
        const zone = this.state.property.zone
        const commune = this.state.property.commune
        const haveDetails = this.state.property.zone ? (zone.has_bikeway || zone.has_parks_squares || zone.has_supermarkets || zone.is_dog_friendly || zone.is_quiet || zone.is_safe) : false
        const haveDetailsCommune = commune ? (commune.has_bikeway || commune.has_parks_squares || commune.has_supermarkets || commune.is_dog_friendly || commune.is_quiet || commune.is_safe) : false
        const hasPropertyPayment = typeof this.state.property.financial.payment_methods != "undefined" && this.state.property.financial.payment_methods !== ""
        const hasPropertyExpenses = this.state.property.financial.expenses ? (!!this.state.property.financial.expenses?.length && this.state.property.countryFeatures?.expenses_ocupation_connection) : false

        return (
            <Row className="px-5 lh-lg print-container d-block">
                <Row className="page-header pr-4 pb-0 d-flex align-items-center justify-content-between">
                    <Col>
                        <h1 className="title fs-4 mb-1 fw-bold lh-1">{this.state.property.title}</h1>
                    </Col>
                    <Col className="d-flex justify-content-center flex-column align-items-end">
                        {this.state.property.logo && (
                            <img
                                style={{ height: "38px" }}
                                src={this.state.property.logo}
                                alt="Iris"
                            />
                        )}
                        {
                            this.state.property.inmo_name && <p>{this.state.property.inmo_name}</p>
                        }
                    </Col>
                </Row>

                <table >
                    <thead>
                        <tr>
                            <td>
                                <div className="page-header-space"></div>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className="page">
                                    <Row>
                                        <Col>
                                            <PropertyTypeAndOperation propertyType={this.state.property.type} operation={this.state.operation} />

                                            <div className="d-flex justify-content-start flex-column fw-normal text-black-50 mt-lg-2 my-3">
                                                {this.state.property.type.identifier !== "3" && <PropertyBedroom units={this.state.property.units} />}
                                                <PropertyMeters units={this.state.property.units} />
                                            </div>

                                            <div className="my-3">
                                                <PropertyPrice units={this.state.property.units} garages={this.state.property.garages} isCol
                                                    propertyTypeId={this.state.property.type.identifier} operation={this.state.operation} />
                                            </div>

                                            <p className="mb-0 lh-sm">{this.state.property.address}</p>
                                            <p className="text-black-50 lh-sm mb-5">
                                                {this.state.property.commune ?
                                                    `${this.state.property.commune.name}, ` :
                                                    ''
                                                }
                                                {this.state.property.zone ?
                                                    (this.state.propertyCountry.code === "CL" ?
                                                        `Comuna ${this.state.property.zone.name}, ` :
                                                        `${this.state.property.zone.name}, `) :
                                                    ''
                                                }
                                                {this.state.propertyCountry.code === "CL" && "Región "}
                                                {this.state.property.department?.name}
                                            </p>
                                        </Col>
                                        <Col xs={7} sm={7} md={7} lg={7}>
                                            <PropertyGallery
                                                images={this.state.property.images.filter((e: any, i: any) => i === 0)}
                                                infinite={false}
                                                counter={false}
                                                expandable={false}
                                                property={this.state.property}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="text-secondary my-5">
                                        <h3 className="fs-4 fw-bold text-secondary">Características</h3>
                                        <PropertyCaracteristics property={this.state.property} isInPdf={true} operation={this.state.operation} />
                                    </Row>
                                    <Row className="">
                                        <h3 className="fs-4 fw-bold text-secondary">{this.state.translation.amenities}</h3>
                                        <PropertyAmenities amenities={this.state.amenities}  extra_amenities={this.state.property.extra_amenities} isInPdf={true} />
                                    </Row>
                                </div>

                                <div className="page">
                                    <Row>
                                        <Col>
                                            <h3 className="fs-4 fw-bold text-secondary">Descripción</h3>
                                            <p className="lh-sm text-secondary m-0 p-0"
                                                dangerouslySetInnerHTML={{
                                                    __html: cleanHtmlFromString(this.state.property.description, true),
                                                }}
                                            >
                                            </p>
                                        </Col>
                                    </Row>

                                    <Row className="mt-4">
                                        {
                                            this.state.property.units && this.state.property.units.length > 0 &&
                                            <table>
                                                <thead className="bg-secondary-light">
                                                    <tr className="text-center">
                                                        <th className="fw-normal fs-xs">Unidad</th>
                                                        
                                                        {this.state.property.countryFeatures?.pj_cash && <th className="fw-normal fs-xs">{this.state.translation.cash_price}</th>}
                                                        {((!this.state.operation || this.state.operation === 'Venta') && this.state.property.countryFeatures?.pj_plazo_obra) && <th className="fw-normal fs-xs">Plazo de obra</th>}
                                                        {((!this.state.operation || this.state.operation === 'Venta') && this.state.property.countryFeatures?.pj_list_price) && <th className="fw-normal fs-xs">Precio lista</th>}
                                                        {this.state.property.countryFeatures?.pj_dsct && <th className="fw-normal fs-xs">Descuento</th>}
                                                        {this.state.property.countryFeatures?.pj_final_price && <th className="fw-normal fs-xs">Precio Final</th>}
                                                        {this.state.property.countryFeatures?.pj_units_rent && <th className="fw-normal fs-xs">{this.state.translation.with_rent}</th>}
                                                        <th className="fw-normal fs-xs">{this.state.translation.common_expenses}</th>
                                                        <th className="fw-normal fs-xs"><IconCrop width={14} height={14} className="text-black-50" /> {this.state.translation.m2_indoor}</th>
                                                        {this.state.property.type.identifier !== PROPERTIES_VALUES.LAND_TYPE  && (
                                                            <>
                                                                {!!this.state.countryFeaturesWeb.units_show_floor && <th className="fw-normal fs-xs">Piso</th>}
                                                                <th className="fw-normal fs-xs"><IconBed /></th>
                                                                <th className="fw-normal fs-xs"><IconShower /></th>
                                                                { !!this.state.countryFeaturesWeb.garages_in_units && <th className="fw-normal fs-xs"><IconGarage /></th> }
                                                                
                                                            </>
                                                        )}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.property.units.filter((u: UnitType) => !this.state.operation || (this.state.operation === u.operation_type))
                                                        .map((unit: UnitType, index: number) => (
                                                            <tr key={`${unit.id}_${index}`} className={"text-center py-2 " + (index !== (this.state.property.units.length - 1) ? "border-bottom" : "")}>
                                                                <td className="fs-xs">{unit.number}</td>
                                                                {this.state.property.countryFeatures?.pj_cash && <td className={"fs-xs " + (unit.price ? "fw-bold" : "")}>{unit.price ? `${unit.currency} ${formatMoney(unit.price)}` : "No tiene"}</td>}
                                                                {((!this.state.operation || this.state.operation === 'Venta') && this.state.property.countryFeatures?.pj_plazo_obra) &&
                                                                    <td className={"fs-xs " + (unit.work_price ? "fw-bold" : "")}>{unit.work_price ? `${unit.currency} ${formatMoney(unit.work_price)}` : "No tiene"}</td>}
                                                                {
                                                                    ((!this.state.operation || this.state.operation === 'Venta') && this.state.property.countryFeatures?.pj_list_price) &&
                                                                    <td className={"fs-xs " + (unit.list_price ? "fw-bold" : "")}>{unit.list_price ? `${unit.currency} ${formatMoney(unit.list_price)}` : "No tiene"}</td>
                                                                }
                                                                {
                                                                    (this.state.property.countryFeatures?.pj_dsct) &&
                                                                    <td className={"fs-xs " + (unit.list_price ? "fw-bold" : "")}>{(unit.max_discount ? unit.max_discount : 0)}</td>
                                                                }
                                                                {
                                                                    (this.state.property.countryFeatures?.pj_final_price) &&
                                                                    <td className={"fs-xs " + (unit.list_price ? "fw-bold" : "")}>{unit.final_price ? `${unit.currency} ${formatMoney(unit.final_price)}` : "No tiene"}</td>
                                                                }
                                                                {this.state.property.countryFeatures?.pj_units_rent && <td className="fs-xs">{unit.with_rent ? <IconCheckCircle width={20} height={20} className="text-primary" /> : <IconX width={20} height={20} />}</td>}
                                                                {unit.common_expenses && parseFloat(unit.common_expenses) > 0 ? (
                                                                    <td className="fs-xs">{`${unit.expenses_currency} ${formatMoney(unit.common_expenses)}`}</td>
                                                                ) : (
                                                                    <td className="fs-xs">Consultar</td>
                                                                )}
                                                                <td className="fs-xs">{unit.m2 + " m²"}</td>
                                                                {this.state.property.type.identifier !== PROPERTIES_VALUES.LAND_TYPE  && (
                                                                    <>
                                                                        { !!this.state.countryFeaturesWeb.units_show_floor && <td className="fs-xs text-lowercase">{unit.floor} </td>}
                                                                        <td className="fs-xs">{unit.bedrooms > 0 ? `${unit.bedrooms} ${this.state.translation.short_bedroom}` : "Monoamb."}</td>
                                                                        <td className="fs-xs">{unit.bathrooms} {`${unit.bathrooms !== 1 ? this.state.translation.pdf_bathrooms : this.state.translation.pdf_single_bathroom}`}</td>
                                                                        { !!this.state.countryFeaturesWeb.garages_in_units && <td className="fs-xs text-lowercase">{unit.garages} {`${this.state.translation.garage_abrv}`}</td>}
                                                                    </>
                                                                )}
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        }


                                        {(!this.state.operation || this.state.operation == 'Venta') &&
                                            this.state.property.garages && this.state.property.garages?.length > 0 && (
                                                <table className="mt-5">
                                                    <thead className="bg-secondary-light">
                                                        <tr className="text-center">
                                                            <th className="fw-normal">{this.state.translation.garage}</th>
                                                            <th className="fw-normal">Precio</th>
                                                            <th className="fw-normal">Ubicación</th>
                                                            <th className="fw-normal"><IconCrop width={14} height={14} className="text-black-50" /> Medidas</th>
                                                            <th className="fw-normal"> <i className="bi bi-arrow-bar-down me-2"></i> Altura Máx.</th>
                                                            <th className="fw-normal"><IconWheelChair /></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.property.garages.map((garage: GarageType, index: number) => (
                                                            <tr key={`${garage.id}_${index}`} className={"text-center py-2 " + (this.state.property.garages && index !== (this.state.property.garages.length - 1) ? "border-bottom" : "")}>
                                                                <td>{garage.name}</td>
                                                                <td className="fw-bold">{`${garage.currency} ${formatMoney(garage.price)}`}</td>
                                                                <td>{garage.location}</td>
                                                                <td>{`${garage.width.toString().replace(".", ",")}m x ${garage.depth
                                                                    .toString()
                                                                    .replace(".", ",")}m`}
                                                                </td>
                                                                <td>{garage.height.toString().replace(".", ",")}m</td>
                                                                <td>{garage.accesible ? (
                                                                    <i className="bi bi-check-lg fs-5"></i>
                                                                ) : (
                                                                    <span>---</span>
                                                                )}
                                                                </td>
                                                            </tr>
                                                        ))
                                                        }
                                                    </tbody>
                                                </table>
                                            )
                                        }
                                    </Row>
                                </div>

                                <div className="page">
                                    <Row className="">
                                        <Col>
                                            <h3 className="fs-4 fw-bold text-secondary">Ubicación</h3>
                                            <div className="property-location">
                                                <p className="property-address mb-0 lh-sm">{this.state.property.address}</p>
                                                <p className="property-hood text-black-50 lh-sm mb-0">
                                                    {this.state.property.commune ? `${this.state.property.commune.name}, ` :
                                                        ''
                                                    }
                                                    {this.state.property.zone ?
                                                        (this.state.propertyCountry.code === "CL" ?
                                                            `Comuna ${this.state.property.zone.name}, ` :
                                                            `${this.state.property.zone.name}, `) :
                                                        ''
                                                    }
                                                    {this.state.propertyCountry.code === "CL" && "Región "}
                                                    {this.state.property.department?.name}
                                                </p>
                                            </div>

                                            {this.state.property.commune && (
                                                <>
                                                    <h3 className="fs-6 mb-2 fw-bold mt-4">{this.state.translation.commune} {this.state.property.commune?.name}</h3>

                                                    {this.state.property.commune.description && this.state.property.commune.description !== '' &&
                                                        <div>
                                                            <p
                                                                className="lh-sm fw-light fs-sm"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: cleanHtmlFromString(this.state.property.commune.description ?? "", true),
                                                                }}
                                                            ></p>
                                                        </div>
                                                    }

                                                    {haveDetailsCommune &&
                                                        <div className="pb-2 pb-lg-0">
                                                            <NeighbourhoodDetails neighbourhood={this.state.property.commune} isInPdf={true} />
                                                        </div>
                                                    }
                                                </>
                                            )}

                                            {this.state.property.zone && !this.state.property.commune && (
                                                <>
                                                    <h3 className="fs-6 mb-2 fw-bold mt-4">{this.state.translation.neighbourhood} {this.state.property.zone?.name}</h3>

                                                    {this.state.property.zone.description && this.state.property.zone.description !== '' &&
                                                        <div>
                                                            <p
                                                                className="lh-sm fw-light fs-sm"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: cleanHtmlFromString(this.state.property.zone.description ?? "", true),
                                                                }}
                                                            ></p>
                                                        </div>
                                                    }

                                                    {haveDetails &&
                                                        <div className="pb-2 pb-lg-0">
                                                            <NeighbourhoodDetails neighbourhood={this.state.property.zone} isInPdf={true} />
                                                        </div>
                                                    }
                                                </>
                                            )}


                                            {hasPropertyPayment &&
                                                <div className="mt-4">
                                                    <PropertyPayment financial={this.state.property.financial} isInPdf={true} />
                                                </div>
                                            }

                                            {hasPropertyExpenses &&
                                                <div className="mt-3">
                                                    <h3 className="fs-4 fw-bold text-secondary">Gastos</h3>
                                                    {this.state.property.financial.expenses.map((expense: DetailsType, index: number) => (
                                                        <div className="flex-column small" key={"expenses_" + index}>
                                                            <span className="text-dark fw-bold">{expense.description} · </span>
                                                            <span className="text-secondary m-0">{expense.name}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            }

                                            <div className="mt-5">
                                                {
                                                    this.state.property.countryFeatures?.financing &&
                                                    <PropertyFinancial financial={this.state.property.financial} isInPdf={true} propertyCountryFeatures={this.state.property.countryFeatures} />
                                                }

                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Row>
        );
    }
}