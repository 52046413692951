import { Button, Col, Container, Row } from "react-bootstrap";
import useBreakpoint from "use-breakpoint";
import { BREAKPOINTS } from "../../utils/Constants";

export const DescriptionSection = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");

  return (
    <div className={"home-description-section bg-sm-light pt-5 pb-5 " + (breakpoint === "xs" ? "px-4" : "")}>
      <Container className={(breakpoint === "xs" ? "px-0" : "")}>
        <Row>
          <Col lg={6} md={8}>
            <p className="fs-5 lh-sm mb-1">
              NUEVA HERRAMIENTA
            </p>
            <p className="fs-3 lh-sm mb-5">
              <b>Que la falta de propiedades no vuelva a ser un problema en tu negocio.</b>
            </p>
            <Button
              href="/iniciar-sesion"
              size="lg"
              className="d-inline-block"
              style={{ minWidth: 200 }}
            >
              Pruébalo ahora
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
