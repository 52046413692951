export const IconSUM = ({ width = 17 }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1984 2.15845L12.6836 2.3072L8.59934 3.46957L8.10156 3.61938L8.20144 3.98435L4.36581 2.87192L3.85156 2.72263L3.70228 3.23742L2.20788 8.31723C2.02725 8.94623 1.91728 9.69742 2.125 10.4258C2.29288 11.0154 2.71469 11.5818 3.37025 11.9202L2.88841 13.6627L1.36106 13.2148L1.0625 14.2444L5.14675 15.4227L5.44531 14.3937L3.91797 13.9453L4.39928 12.2187C4.73208 12.2471 5.06716 12.2072 5.38403 12.1016C5.7009 11.996 5.99291 11.8269 6.24219 11.6046C6.80691 11.0983 7.12406 10.4236 7.30469 9.79463L8.61634 5.41182L9.69531 9.24745V9.26338C9.87594 9.89239 10.1936 10.5671 10.7578 11.0734C11.2184 11.4861 11.8617 11.7353 12.6007 11.671L13.082 13.414L11.5547 13.8624L11.8538 14.8914L15.9375 13.7131L15.6384 12.6836L14.1111 13.1319L13.6133 11.3884C14.2688 11.0505 14.6901 10.4837 14.8585 9.89451C15.0657 9.16617 14.9727 8.41498 14.7921 7.78598L13.3477 2.6727L13.1984 2.15845ZM12.4844 3.46904L13.1484 5.84373H9.82812L9.41322 4.34932L12.4844 3.46904ZM4.58203 4.05076L7.63672 4.91404L7.20481 6.37498H3.8845L4.58203 4.05076ZM10.1272 6.90623H13.4475L13.7796 8.08507C13.9225 8.57913 13.9724 9.16617 13.846 9.61242C13.719 10.0587 13.4783 10.3721 12.8329 10.5586C12.1874 10.7456 11.8161 10.6021 11.4718 10.2929C11.127 9.98376 10.8513 9.45888 10.7079 8.96482L10.1272 6.90623ZM3.56947 7.43748H6.88978L6.29212 9.49607H6.27566C6.13222 9.99013 5.87297 10.515 5.52819 10.8242C5.18394 11.1334 4.81259 11.2768 4.16712 11.0898C3.52166 10.9028 3.281 10.5899 3.15403 10.1437C3.02759 9.69742 3.07753 9.10985 3.22044 8.61632L3.56947 7.43748Z"
        fill="currentColor"
      />
    </svg>
  );
};
