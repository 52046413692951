import { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { OperationType, PropertyType } from "../../interfaces/property";
import { PropertyDescription } from "../../components/PropertyDescription/PropertyDescription";
import { PropertyAmenities } from "../../components/PropertyAmenities/PropertyAmenities";
import { PropertyCaracteristics } from "../../components/PropertyCaracteristics/PropertyCaracteristics";
import { IconElevator } from "../../components/CustomIcons/IconElevator";
import { IconGarage } from "../../components/CustomIcons/IconGarage";
import { IconLaundry } from "../../components/CustomIcons/IconLaundry";
import { IconSecurity } from "../../components/CustomIcons/IconSecurity";
import { IconSecurityCamera } from "../../components/CustomIcons/IconSecurityCamera";
import { IconSwimmingPool } from "../../components/CustomIcons/IconSwimmingPool";
import { IconGarden } from "../../components/CustomIcons/IconGarden";
import { IconBarbacue } from "../../components/CustomIcons/IconBarbacue";
import { IconPlayroom } from "../../components/CustomIcons/IconPlayroom";
import { IconSeaView } from "../../components/CustomIcons/IconSeaView";
import { IconSUM } from "../../components/CustomIcons/IconSUM";
import { downloadDocument } from "../../data/api";
import { CustomImage } from "../../components/CustomImage/CustomImage";
import useCountry from "../../hooks/config/useConfig";
import { IconDownload } from "../../components/CustomIcons/IconDownload";
import { ProjectType } from "../../interfaces/project";
import { IconGymCustom } from "../../components/CustomIcons/IconGymCustom";
import { IconEventsRoomCustom } from "../../components/CustomIcons/IconEventsRoomCustom";
import { IconPetCustom } from "../../components/CustomIcons/IconPetCustom";

export const ResumeSection = ({ property, isInmoDetail, operation }: {
  property: ProjectType,
  isInmoDetail?: boolean, operation?: OperationType
}) => {
  const [modal, setModal] = useState(false);
  const { translation } = useCountry();

  const amenities = [
    {
      title: "Ascensor",
      icon: <IconElevator />,
      show: property.has_elevator,
    },
    {
      title: translation.garage,
      icon: <IconGarage />,
      show: property.has_garage,
    },
    {
      title: "Seguridad",
      icon: <IconSecurity width={19} />,
      show: property.has_security,
    },
    {
      title: "Piscina",
      icon: <IconSwimmingPool width={20} />,
      show: property.has_swimming_pool,
    },
    {
      title: "Lavanderia",
      icon: <IconLaundry />,
      show: property.has_laundry,
    },
    {
      title: "Vista al Mar",
      icon: <IconSeaView />,
      show: property.has_sea_view,
    },
    {
      title: "Jardin",
      icon: <IconGarden width={21} />,
      show: property.has_garden,
    },
    {
      title: "SUM",
      icon: <IconSUM width={20} />,
      show: property.has_sum,
    },
    {
      title: "Playroom",
      icon: <IconPlayroom width={21} />,
      show: property.has_playroom,
    },
    {
      title: translation.griller,
      icon: <IconBarbacue width={23} />,
      show: property.has_barbecue,
    },
    {
      title: "Cámaras de Seguridad",
      icon: <IconSecurityCamera width={20} />,
      show: property.has_security_cameras,
    },
    {
      title: "Gimnasio",
      icon: <IconGymCustom width={22} />,
      show: property.has_gym,
    },
    {
        title: "Salón de eventos",
        icon: <IconEventsRoomCustom width={20} />,
        show: property.has_events_room,
    },
    {
        title: translation.visit_garage,
        icon: <IconGarage width={20} />,
        show: property.has_visit_garage,
    },
    {
        title: "Pet friendly",
        icon: <IconPetCustom width={20} />,
        show: property.is_pet_friendly
    },
  ];

  const hasExtra = !!property.developers.filter((developer) => developer.brochure !== "" || developer.descriptive_memory !== "")

  const hasAmenities = (amenities && amenities.filter(a => a.show).length > 0 ) || property.extra_amenities ? true : false 
  const hasDevelopers = property.developers.filter(pd => !!pd.developer).length > 0 ? true : false

  const DeveloperButtons = ({ name, brochure, memory }: { name: string, brochure: string, memory: string }) => {
    return (
      <>
        {brochure !== "" && (
          <Button
            className="bg-white border rounded-pill px-3 py-1 d-flex align-items-center text-secondary"
            variant="light"
            onClick={() => downloadDocument(brochure, `${name}-brochure`, brochure.split('.').pop() ?? "pdf")}
          >
            <IconDownload width={20} height={20} className="text-primary" />
            <span className="d-none d-lg-inline ms-lg-2">Brochure</span>
          </Button>
        )}
        {memory !== "" && (
          <Button
            className="bg-white border rounded-pill px-3 py-1 d-flex align-items-center text-secondary"
            variant="light"
            onClick={() => downloadDocument(memory, `${name}-memory`, memory.split('.').pop() ?? "pdf")}
          >
            <IconDownload width={20} height={20} className="text-primary" />
            <span className="d-none d-lg-inline ms-lg-2">Memoria</span>
          </Button>
        )}
      </>
    )
  };

  return (
    <>
      <Row className="resume-section">
        <Col
          lg={{ span: 7, order: 1 }}
          xs={{ span: 12, order: 3 }}
          className="border-end pe-lg-4 py-4 resume-left"
        >
          {
            property.description &&
            <>
            <h3 className="fs-4 fw-normal mb-3">Descripción</h3>
            <div className="pb-2 pb-lg-0">
               <PropertyDescription description={property.description} />
            </div>
            </>
          }
          
          <Col className="d-lg-none">
            <div className="border-top mt-4 pb-1"></div>
          </Col>
          {
            !!hasAmenities &&
            <>
              <h3 className="fs-4 fw-normal mb-3 mt-lg-5 mt-4">{translation.amenities}</h3>
              <PropertyAmenities amenities={amenities} extra_amenities={property.extra_amenities} />
            </>

          }

        </Col>
        <Col xs={{ span: 12, order: 2 }} className="d-lg-none">
          <div className="border-bottom my-2"></div>
        </Col>
        <Col
          lg={{ span: 5, order: 2 }}
          xs={{ span: 12, order: 1 }}
          className="ps-lg-4 py-4"
        >
          <h3 className="fs-4 fw-normal mb-3">Características</h3>
          <PropertyCaracteristics property={property} operation={operation} />
        </Col>

        {hasDevelopers && (
          <Col xs={{ order: 3, span: 12 }} className="py-4">
            <Row>
              <Col>
                <div>
                  <span className="text-uppercase fs-xs fw-500 flex-grow-1 text-black-50">
                      ESTE ES UN PROYECTO DESARROLLADO POR
                    </span>
                </div>
                  
                <div className="d-flex w-100 flex-wrap mt-1">
                {property.developers.map((d, index) => (
                      <CustomImage
                        key={"developer_image_" + index}
                        src={d.developer?.logo}
                        alt={d.developer?.logo}
                        classes="ms-3 developer-image"
                        width={100}
                        height={100}
                      />
                    ))}
                </div>
                
              </Col>
              <Col  className="d-flex justify-content-end align-items-center">
                {hasExtra && (
                  <Button
                    className="bg-white border rounded-pill px-3 py-3 d-flex align-items-center text-secondary "
                    variant="light"
                    onClick={() => setModal(true)}
                  >Más información</Button>
                )}
              </Col>
            </Row>
          </Col>
        )}
      </Row>

      <Modal
        size="lg"
        show={modal}
        onHide={() => setModal(false)}
        centered
        contentClassName={"bg-xs-light border-0 " + (isInmoDetail ? 'inmo-detail-modal' : '')}
      >
        <Modal.Header closeButton>
          <Modal.Title
          >
            <span className="text-uppercase fs-xs fw-500 flex-grow-1 text-black-50 ps-5">
              ESTE ES UN PROYECTO DESARROLLADO POR
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-1 px-5 pb-3 px-lg-4 pb-lg-4">
          {property.developers.map((d, index) => {
            if(d.developer?.name){
              return (
                <Row className="py-4 px-4" key={"developer_row_" + index}>
                  <Col>
                    <CustomImage
                      key={"developer_" + index}
                      src={d.developer?.logo}
                      alt={d.developer?.logo}
                      classes="developer-image"
                      width={50}
                      height={50}
                      styles={{ height: "50px" }}
                    />
                  </Col>
                  <Col className="d-flex justify-content-end gap-3">
                    <DeveloperButtons name={d.developer?.name} brochure={d.brochure || ''} memory={d.descriptive_memory || ''} />
                  </Col>
                </Row>
              )
            }
            }
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};