import useBreakpoint from "use-breakpoint";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BREAKPOINTS } from "../../utils/Constants";
import { useLinks } from "../../hooks/config/useLinks";

export const JoinPlatform = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");
  const navigate = useNavigate();
  const {mainProjectsUrl} = useLinks()

  return (
    <div className={"home-join-platform pb-3 " + (breakpoint === "xs" ? "px-4" : "")}>
      <Container className={(breakpoint === "xs" ? "px-0" : "")}>
        <Card className="text-center text-lg-start">
          <Card.Header className="py-3 text-white fs-5 fw-light">
            Sumate a una plataforma exclusiva para profesionales inmobiliarios
          </Card.Header>
          <Card.Body>
            <Row className="align-items-lg-end gy-3 gx-2">
              <Col lg="3">
                <i className="bi bi-house-door text-primary fs-4"></i>
                <span className="d-block fw-500 mt-1">
                  Nuevos proyectos
                  <br /> todas las semanas.
                </span>
              </Col>
              <Col lg="3">
                <i className="bi bi-graph-up-arrow text-primary fs-4"></i>
                <span className="d-block fw-500 mt-1">
                  Lanzamientos, descuentos
                  <br /> y liquidaciones.
                </span>
              </Col>
              <Col lg="3">
                <i className="bi bi-journal-arrow-up text-primary fs-4"></i>
                <span className="d-block fw-500 mt-1">
                  Capacitaciones y
                  <br /> talleres exclusivos.
                </span>
              </Col>
              <Col
                lg="3"
                className="d-flex justify-content-lg-end align-items-end"
              >
                <Button
                  onClick={() => navigate(mainProjectsUrl)}
                  className="w-100 w-lg-75"
                  style={{ minHeight: 50 }}
                >
                  Pruebalo ahora
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};
