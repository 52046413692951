import { useEffect, useState } from "react";
import { Badge, Collapse, Dropdown, Form } from "react-bootstrap";
import userFilter from "../../../hooks/filter/useFilter";
import { FilterNeighbourhood } from "../FilterNeighbourhood/FilterNeighbourhood";
import "./FilterEstate.scss";
import useCountry from "../../../hooks/config/useConfig";
import { Commune, Department } from "../../../interfaces/locations";

export const FilterEstateDropdown = ({ estate, currentEstate }: { estate: Department, currentEstate: Department[] }) => {
  const { filterState, setFilter } = userFilter();
  const [active, setActive] = useState(false);
  const [openCollapse, setOpenCollapse] = useState<any[]>([]);
  const [counter, setCounter] = useState(0);
  const neighbourhoodsOfEstate = estate.zones
  let zoneCommunes : Commune[]= []
  estate.zones.map(z => {
    zoneCommunes.concat(z.communes)
  })
  const communes = zoneCommunes
  const [hasOpened , setHasOpened] = useState<any[]>([])
  const { country } = useCountry();

  useEffect(() => {
    setActive(currentEstate.findIndex((e) => e.id == estate.id) >= 0)
  }, [currentEstate, estate])

  const checkEstate = () =>{
    const current = currentEstate
    .filter((e) => e.id != estate.id)
    .map((e) => e.id);
    if(!active) {
      current.push(estate.id)
    }
    
    setActive(prev => !prev)
    //Quitamos los barrios
    const neighbourhoodsFilters = filterState.neighbourhood?.filter((n) => !neighbourhoodsOfEstate.map((n) => n.id).includes(n));
    //Quitamos las comunas 
    const communesFilters = filterState.commune?.filter(c=> !communes.map((co) => co.id).includes(c) )
    setFilter({ location: current ,neighbourhood: neighbourhoodsFilters , commune : communesFilters});
    
    setCounter(0);
  }

  const handleChange = (e: any) => {
    
    if (e.target.classList.contains('form-check-input')) {
      checkEstate()
    } else {
      //Abrir dropdown
      if(neighbourhoodsOfEstate.length >0 ){
        const collapseIndex = openCollapse.findIndex((collapse) => collapse == estate.id);

        if (collapseIndex >= 0) {
          setOpenCollapse([
            ...openCollapse.slice(0, collapseIndex),
            ...openCollapse.slice(collapseIndex + 1, openCollapse.length)
          ])
        } else {
          //Guarda que ya se renderizó
          if(!hasOpened.includes(estate.id)){
            setHasOpened(prev => [...prev,estate.id])
          }
          setOpenCollapse(openCollapse => [...openCollapse, estate.id])
          
        }
        
      }else{
        //No tiene barrios , marcar check
        checkEstate()
      }
      
    }
  };
  
  return (
    <>
      <Dropdown.Item
      onClick={(e) => handleChange(e)}
        key={`estate-${estate.id}`}
        className={`d-flex align-items-center px-2 py-2 my-1 rounded-1 ${active ? "bg-sm-light" : ""
          }`}
      >
        <Form.Check 
          className="me-2 d-flex align-items-center w-100 form-check-estate">
          <Form.Check.Input checked={active} id={`estate-${estate.id}`} type="checkbox" onClick={e => e.stopPropagation()} onChange={e => handleChange(e)} />
          <Form.Check.Label htmlFor={`estate-${estate.id}`} >{`${country === "CL" ? 'Región' : ''} ${estate.name}`}</Form.Check.Label>
        </Form.Check>

        {(!active && counter > 0) && (
          <small className="d-flex align-items-center">
            <Badge pill bg="primary" className="badge-check ms-2">
              {counter}
            </Badge>
          </small>
        )}
      </Dropdown.Item>

      <Collapse className={`parent-estate-${estate.id}`} key={`collapse-${estate.id}`} in={openCollapse.findIndex((collapse) => collapse == estate.id) >= 0}>
        <div className="neighbourhood-collapse">
          {
            hasOpened.includes(estate.id) && 
            <FilterNeighbourhood  estate={estate} activeParent={active} setActiveParent={setActive} setCounter={setCounter} />
          } 
        </div>
      </Collapse>
    </>
  );
};
