import OneSignal from 'react-onesignal';
import { useEffect ,useState} from "react";
import useConfig from "../../hooks/config/useConfig"
import useUser from "../../hooks/user/useUser"

export const useOneSignal = (initOneSignal = true) =>{

  const {config} = useConfig()
  const [isInitialized, setIsInitialized] = useState(false);
    const Init = async () =>{
      
      if(config.oneSignalId){
        let oneSignalID = config.oneSignalId
        if(oneSignalID && oneSignalID !== ''){
          try {
            // @ts-ignore
            if(!isInitialized && !window.OneSignal){
              
              OneSignal.init(
                { 
                  appId: oneSignalID ,
                  allowLocalhostAsSecureOrigin : true,
                  promptOptions: {
                    actionMessage: "¡Nos gustaría enviarte notificaciones de las últimas novedades!",
                    acceptButtonText: "Aceptar",
                    cancelButtonText: "No gracias"
                  },
                  welcomeNotification: {
                        //disable: true,//cambiar para mostrar mensaje
                        "title": "Iris",
                        "message": "¡Gracias por suscribirte!",
                        // "url": "https://infocasas.com.uy" /* Leave commented for the notification to not open a window on Chrome and Firefox (on Safari, it opens to your webpage) */    
                  },
                  notifyButton: {
                    enable: false,
                  },
                }).then(async () => {
                console.log("INICIALIZÓ ONE SIGNAL ",oneSignalID)
                setIsInitialized(true)
                // @ts-ignore
                //console.log("vamos a mostrar",window.OneSignal)
                OneSignal.showSlidedownPrompt().then((data) => {
                  // do other stuff
                  //console.log("ya mostró ")
                }).catch(err=>{
                  console.log(err)
                });

                
                
              }).catch(err=>{
                console.log("ERROR ONESIGNAL ",err.toString());
              });
            }
            
          } catch (error:any) {
            console.log("Ocurrio error")
            console.log(error.toString())
          }
          
        }else{
          console.log("ONESIGNAL NO KEY");
        }
      }else{
        console.log("ONESIGNAL NO KEY FROM API");
      }
        
    }

    const setExternalId = async (user_id : any) =>{
      let registeredExternalId 
      OneSignal.getExternalUserId().then(function(externalUserId){
        registeredExternalId = externalUserId
        if(user_id){
          if(registeredExternalId && registeredExternalId != user_id){
            OneSignal.removeExternalUserId();
          }
          OneSignal.setExternalUserId(user_id);
        } 
      }).catch(err=> console.log("error ",err));      
      
    }

    return {InitOneSignal : Init, setExternalId}
}