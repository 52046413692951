import { GetFavoritesResponse, GetOffersResponse, GetRequestedMortgageResponse, GetRequestedWarrantiesResponse, GetSalesResponse, GetVisitsResponse, generalDataResult} from "../../interfaces/apiTypes";

import useUser from "../user/useUser";
import useAlert from "../../hooks/alert/useAlert";
import { UpdateBillFileBody, UpdatePasswordBody, UpdateUserBody, UserClientsBody, UserFavoriteBody, UserOfferBody, UserRequestedMortgageBody, UserRequestedNotarialBody, UserRequestedWarrantyBody, UserVisitBody } from "../../interfaces/userActivity";
import {useFetch} from "./useFetch"
import { LevelInfoResponse, UserInfoResponseType } from "../../interfaces/user";
import { useEffect, useState } from "react";
import { LevelType } from "../../interfaces/levels";

export const useUserFetch = () => {
    const { setTypeGlobalAlert, setShowGlobalAlert, setMessageGlobalAlert } = useAlert()
    const { setUser} = useUser()

    const { authFetch , getUserDetail } = useFetch()


    const registerVisit : (userVisit : UserVisitBody) => Promise<generalDataResult> =
        async (userVisit) => {

            let result: generalDataResult = {
                success: false,
                data: [],
                message: ''
            }


            const url = `${process.env.REACT_APP_AUTH_API_URL}/api/user/register-visit`
        
            try {

                await authFetch(url,
                    {
                        cache: 'no-cache',
                        method : 'POST',
                        body : JSON.stringify(userVisit),
                        headers: {
                            'Content-Type': 'application/json',
                            'Cache-Control': 'no-cache'
                        },
                    }
                )
                .then((response) => {

                        if (response.error) {
                            if (response.message && response.message !== '') {
                                result.message = response.message
                            }
                        } else result = response

                })

                return result
            } catch (error) {
                console.log(error)
                return result
            }

    }

    const registerOffer : (userVisit : UserOfferBody) => Promise<generalDataResult> =
        async (userVisit) => {

            let result: generalDataResult = {
                success: false,
                data: [],
                message: ''
            }


            const url = `${process.env.REACT_APP_AUTH_API_URL}/api/user/register-offer`
        
            try {

                await authFetch(url,
                    {
                        cache: 'no-cache',
                        method : 'POST',
                        body : JSON.stringify(userVisit),
                        headers: {
                            'Content-Type': 'application/json',
                            'Cache-Control': 'no-cache'
                        },
                    }
                )
                .then((response) => {

                        if (response.error) {
                            if (response.message && response.message !== '') {
                                result.message = response.message
                            }
                        } else result = response

                })

                return result
            } catch (error) {
                console.log(error)
                return result
            }

    }

    const registerRequestedWarranty : (body : UserRequestedWarrantyBody) => Promise<generalDataResult> =
        async (body) => {

            let result: generalDataResult = {
                success: false,
                data: [],
                message: ''
            }

            const url = `${process.env.REACT_APP_AUTH_API_URL}/api/user/register-requested-warranty`
        
            try {

                await authFetch(url,
                    {
                        cache: 'no-cache',
                        method : 'POST',
                        body : JSON.stringify(body),
                        headers: {
                            'Content-Type': 'application/json',
                            'Cache-Control': 'no-cache'
                        },
                    }
                )
                .then((response) => {
                        if (response.error) {
                            if (response.message && response.message !== '') {
                                result.message = response.message
                            }
                        } else result = response

                })

                return result
            } catch (error) {
                console.error(error)
                return result
            }

    }

    const registerRequestedMortage : (body : UserRequestedMortgageBody) => Promise<generalDataResult> =
        async (body) => {

            let result: generalDataResult = {
                success: false,
                data: [],
                message: ''
            }

            const url = `${process.env.REACT_APP_AUTH_API_URL}/api/user/register-requested-mortage`
        
            try {

                await authFetch(url,
                    {
                        cache: 'no-cache',
                        method : 'POST',
                        body : JSON.stringify(body),
                        headers: {
                            'Content-Type': 'application/json',
                            'Cache-Control': 'no-cache'
                        },
                    }
                )
                .then((response) => {
                        if (response.error) {
                            if (response.message && response.message !== '') {
                                result.message = response.message
                            }
                        } else result = response

                })
            } catch (error) {
                console.error(error)
            }finally{
                return result
            }

    }

    const registerRequestedNotarial : (body : UserRequestedNotarialBody) => Promise<generalDataResult> =
        async (body) => {

            let result: generalDataResult = {
                success: false,
                data: [],
                message: ''
            }

            const url = `${process.env.REACT_APP_AUTH_API_URL}/api/user/register-requested-notarial`
        
            try {

                await authFetch(url,
                    {
                        cache: 'no-cache',
                        method : 'POST',
                        body : JSON.stringify(body),
                        headers: {
                            'Content-Type': 'application/json',
                            'Cache-Control': 'no-cache'
                        },
                    }
                )
                .then((response) => {
                        if (response.error) {
                            if (response.message && response.message !== '') {
                                result.message = response.message
                            }
                        } else result = response

                })
            } catch (error) {
                console.error(error)
            }finally{
                return result
            }

    }

    const registerFavorite : (body : UserFavoriteBody) => Promise<generalDataResult> =
        async (body) => {

            let result: generalDataResult = {
                success: false,
                data: [],
                message: ''
            }

            const url = `${process.env.REACT_APP_AUTH_API_URL}/api/user/register-favorite`
        
            try {

                await authFetch(url,
                    {
                        cache: 'no-cache',
                        method : 'POST',
                        body : JSON.stringify(body),
                        headers: {
                            'Content-Type': 'application/json',
                            'Cache-Control': 'no-cache'
                        },
                    }
                )
                .then((response) => {
                        if (response.error) {
                            if (response.message && response.message !== '') {
                                result.message = response.message
                            }
                        } else result = response

                })
            } catch (error) {
                console.error(error)
            }finally{
                return result
            }

    }

    const removeFavorite : (body : UserFavoriteBody) => Promise<generalDataResult> =
        async (body) => {

            let result: generalDataResult = {
                success: false,
                data: [],
                message: ''
            }

            const url = `${process.env.REACT_APP_AUTH_API_URL}/api/user/remove-favorite`
        
            try {

                await authFetch(url,
                    {
                        cache: 'no-cache',
                        method : 'POST',
                        body : JSON.stringify(body),
                        headers: {
                            'Content-Type': 'application/json',
                            'Cache-Control': 'no-cache'
                        },
                    }
                )
                .then((response) => {
                        if (response.error) {
                            if (response.message && response.message !== '') {
                                result.message = response.message
                            }
                        } else result = response

                })
            } catch (error) {
                console.error(error)
            }finally{
                return result
            }

    }


    const updateProfile : (body : UpdateUserBody) => Promise<generalDataResult> =
        async (body) => {

            let result: generalDataResult = {
                success: false,
                data: [],
                message: ''
            }

            const url = `${process.env.REACT_APP_AUTH_API_URL}/api/user/update-user`
            
            const form_data = new FormData()
            form_data.append('name',body.name)
            form_data.append('real_estate',body.real_estate)
            form_data.append('phone',body.phone)
            form_data.append('city',body.city)
            form_data.append('primary_color',body.primary_color)
            form_data.append('delete_image',body.delete_image.toString())
            form_data.append('image_file',body.image_file)
        
            try {

                await authFetch(url,
                    {
                        cache: 'no-cache',
                        method : 'POST',
                        body : form_data,
                        headers: {
                            'Cache-Control': 'no-cache'
                        },
                    }
                )
                .then((response) => {
                        if (response.error) {
                            if (response.message && response.message !== '') {
                                result.message = response.message
                            }
                        } else result = response

                })
            } catch (error) {
                console.error(error)
            }finally{
                return result
            }

    }

    const updatePassword : (body : UpdatePasswordBody) => Promise<generalDataResult> =
        async (body) => {

            let result: generalDataResult = {
                success: false,
                data: [],
                message: ''
            }

            const url = `${process.env.REACT_APP_AUTH_API_URL}/api/user/update-password`
            
        
            try {

                await authFetch(url,
                    {
                        cache: 'no-cache',
                        method : 'POST',
                        body : JSON.stringify(body),
                        headers: {
                            'Cache-Control': 'no-cache',
                            'Content-Type': 'application/json',
                        },
                    }
                )
                .then((response) => {
                        if (response.error) {
                            if (response.message && response.message !== '') {
                                result.message = response.message
                            }
                        } else result = response

                })
            } catch (error) {
                console.error(error)
            }finally{
                return result
            }

    }

    const getOffers : (search? :string, limit? : number , page? : number) =>  Promise<GetOffersResponse> =
     async(search,limit = 20,page = 1) => {
        let result: GetOffersResponse = {
            success: false,
            data: [],
            message: '',
            total : 0
        }

        const url = `${process.env.REACT_APP_AUTH_API_URL}/api/user/get-offers`
        
            try {
                await authFetch(url,
                    {
                        method : 'POST',
                        body : JSON.stringify({
                            search,
                            limit,
                            page
                        }),
                        headers: {
                            'Content-Type': 'application/json',
                            'Cache-Control': 'no-cache'
                        }
                    }
                )
                .then((response) => {
                        if (response.error) {
                            if (response.message && response.message !== '') {
                                result.message = response.message
                            }
                        } else result = response

                })
            } catch (error) {
                console.error(error)
            }finally{
                return result
            }

    }

    const getVisits : (search? :string, limit? : number , page? : number) =>  Promise<GetVisitsResponse> =
     async(search,limit = 20,page = 1) => {
        let result: GetVisitsResponse = {
            success: false,
            data: [],
            message: '',
            total : 0
        }

        const url = `${process.env.REACT_APP_AUTH_API_URL}/api/user/get-visits`
        
            try {
                await authFetch(url,
                    {
                        method : 'POST',
                        body : JSON.stringify({
                            search,
                            limit,
                            page
                        }),
                        headers: {
                            'Content-Type': 'application/json',
                            'Cache-Control': 'no-cache'
                        }
                    }
                )
                .then((response) => {
                        if (response.error) {
                            if (response.message && response.message !== '') {
                                result.message = response.message
                            }
                        } else result = response

                })
            } catch (error) {
                console.error(error)
            }finally{
                return result
            }

    }

    const getRequestedWarranties : (search? :string, limit? : number , page? : number) =>  Promise<GetRequestedWarrantiesResponse> =
     async(search,limit = 20,page = 1) => {
        let result: GetRequestedWarrantiesResponse = {
            success: false,
            data: [],
            message: '',
            total : 0
        }

        const url = `${process.env.REACT_APP_AUTH_API_URL}/api/user/get-requested-warranties`
        
            try {
                await authFetch(url,
                    {
                        method : 'POST',
                        body : JSON.stringify({ search, limit, page }),
                        headers: {
                            'Content-Type': 'application/json',
                            'Cache-Control': 'no-cache'
                        }
                    }
                )
                .then((response) => {
                        if (response.error) {
                            if (response.message && response.message !== '') {
                                result.message = response.message
                            }
                        } else result = response

                })
            } catch (error) {
                console.error(error)
            }finally{
                return result
            }

    }

    

    const getRequestedMortgages : (search? :string, limit? : number , page? : number) =>  Promise<GetRequestedMortgageResponse> =
     async(search,limit = 20,page = 1) => {
        let result: GetRequestedMortgageResponse = {
            success: false,
            data: [],
            message: '',
            total : 0
        }

        const url = `${process.env.REACT_APP_AUTH_API_URL}/api/user/get-requested-mortages`
        
            try {
                await authFetch(url,
                    {
                        method : 'POST',
                        body : JSON.stringify({ search, limit, page }),
                        headers: {
                            'Content-Type': 'application/json',
                            'Cache-Control': 'no-cache'
                        }
                    }
                )
                .then((response) => {
                        if (response.error) {
                            if (response.message && response.message !== '') {
                                result.message = response.message
                            }
                        } else result = response

                })
            } catch (error) {
                console.error(error)
            }finally{
                return result
            }

    }

    const getSales : (search? :string,status_filter ? : string, limit? : number , page? : number) =>  Promise<GetSalesResponse> =
     async(search,status_filter,limit = 20,page = 1) => {

        let result: GetSalesResponse = {
            success: false,
            data: [],
            message: '',
            total : 0,
            totals : {
                total_commission : 0,
                total_projects : 0,
                total_price : 0,
                pay_commission : 0
            }
        }

        const url = `${process.env.REACT_APP_AUTH_API_URL}/api/user/get-sales`
        
            try {
                await authFetch(url,
                    {
                        method : 'POST',
                        body : JSON.stringify({ project_search : search, status_filter, limit, page }),
                        headers: {
                            'Content-Type': 'application/json',
                            'Cache-Control': 'no-cache'
                        }
                    }
                )
                .then((response) => {
                        if (response.error) {
                            if (response.message && response.message !== '') {
                                result.message = response.message
                            }
                        } else result = response
                })
            } catch (error) {
                console.error(error)
            }finally{
                return result
            }

    }

    const updateBillFile : (body : UpdateBillFileBody) => Promise<generalDataResult> =
        async (body) => {

            let result: generalDataResult = {
                success: false,
                data: [],
                message: ''
            }

            const url = `${process.env.REACT_APP_AUTH_API_URL}/api/user/update-sale-bill`
            
            const form_data = new FormData()
            form_data.append('sale_id',body.sale_id)
            form_data.append('bill_file',body.bill_file)
        
            try {

                await authFetch(url,
                    {
                        cache: 'no-cache',
                        method : 'POST',
                        body : form_data,
                        headers: {
                            'Cache-Control': 'no-cache'
                        },
                    }
                )
                .then((response) => {
                        if (response.error) {
                            if (response.message && response.message !== '') {
                                result.message = response.message
                            }
                        } else result = response

                })
            } catch (error) {
                console.error(error)
            }finally{
                return result
            }

    }

    const getFavorites : (search? :string, limit? : number , page? : number) =>  Promise<GetFavoritesResponse> =
     async(search,limit = 20,page = 1) => {
        let result: GetFavoritesResponse = {
            success: false,
            data: [],
            message: '',
            total : 0
        }

        const url = `${process.env.REACT_APP_AUTH_API_URL}/api/user/get-favorites`
        
            try {
                await authFetch(url,
                    {
                        method : 'POST',
                        body : JSON.stringify({ search, limit, page }),
                        headers: {
                            'Content-Type': 'application/json',
                            'Cache-Control': 'no-cache'
                        }
                    }
                )
                .then((response) => {
                        if (response.error) {
                            if (response.message && response.message !== '') {
                                result.message = response.message
                            }
                        } else result = response

                })
            } catch (error) {
                console.error(error)
            }finally{
                return result
            }

    }

    const getLevelInfo = async (): Promise<LevelInfoResponse> => {

        const response:LevelInfoResponse = await authFetch(
            process.env.REACT_APP_AUTH_API_URL + '/api/user/level-info',
            {}
        )
        
        return response;
    }

    const updateUserStateData = () => {
        getUserDetail().then(data => {
            if(data && data.success && data.data){
                setUser(data.data)
            }
        })
    }

    const registerClients : (userVisit : UserClientsBody) => Promise<generalDataResult> =
        async (userVisit) => {

            let result: generalDataResult = {
                success: false,
                data: [],
                message: ''
            }


            const url = `${process.env.REACT_APP_AUTH_API_URL}/api/user/register-clients`
        
            try {

                await authFetch(url,
                    {
                        cache: 'no-cache',
                        method : 'POST',
                        body : JSON.stringify(userVisit),
                        headers: {
                            'Content-Type': 'application/json',
                            'Cache-Control': 'no-cache'
                        },
                    }
                )
                .then((response) => {

                        if (response.error) {
                            if (response.message && response.message !== '') {
                                result.message = response.message
                            }
                        } else result = response

                })

                return result
            } catch (error) {
                console.log(error)
                return result
            }

    }
    

    return {
        registerVisit,
        registerOffer,
        registerRequestedWarranty,
        registerRequestedMortage,
        registerFavorite,
        removeFavorite,
        updateProfile,
        getOffers,
        getVisits,
        getRequestedWarranties,
        getRequestedMortgages,
        getSales,
        updateBillFile,
        getFavorites,
        updatePassword,
        updateUserStateData,
        getLevelInfo,
        registerClients,
        registerRequestedNotarial
    }
}