import { useEffect, useState } from "react";
import { PropertyType, OperationType } from "../../interfaces/property";
import useUser from "../../hooks/user/useUser";
import domtoimage from 'dom-to-image';
import { PropertyPriceToShare } from "../../components/PropertyPrice/PropertyPriceToShare";
import { CustomImage } from "../CustomImage/CustomImage";
import { ProjectType } from "../../interfaces/project";

const ElementToShare = ({ property, setLoadingToShare, setBlobImg, operation }:
  {
    property: ProjectType, setLoadingToShare: Function,
    setBlobImg: Function, operation?: OperationType
  }) => {

  const [calling, setCalling] = useState(false)
  const MIN_BLOB_SIZE = 100000
  const { userState } = useUser()
  const initialWidth = window.screen.width //window.screen.width
  const initialHeigth = window.screen.width * 1.78 //para ratio 9:16
  const pixelRatio = 2// window.devicePixelRatio
  const [useWidth, setUseWidth] = useState(initialWidth * pixelRatio)//window.screen.width
  const [useHeigth, setUseHeigth] = useState(initialHeigth * pixelRatio)

  const hasSubsidy = property.subsidy_laws?.length != null && property.subsidy_laws?.length > 0 && property.country.code == 'CL' ? true : false

  useEffect(() => {
    if (property) {

      var shareImgs = document.getElementsByClassName('share-img') as HTMLCollectionOf<HTMLImageElement>
      var shareImg = shareImgs[0]

      var a = new Image;
      a.onload = async function () {
        setLoadingToShare(true)
        let calls = 0

        let interval = setInterval(async () => {
          calls++
          if (!calling) {
            setCalling(true)
            let blobResult = await loadShareBlob()
            setCalling(false)

            if (blobResult?.size >= MIN_BLOB_SIZE) {
              setBlobImg(blobResult)
              setLoadingToShare(false)
              clearInterval(interval)
            } else if (calls > 4) {
              setLoadingToShare(false)
              clearInterval(interval)
            }

          } else {

            clearInterval(interval)
          }
        }, 1000)
      };
      a.onerror = () => {
        console.error("ERROR CARGANDO IMG PARA COMPARTIR")
        setLoadingToShare(false)
      }

      //Obtenemos la imagen del fondo para verificar que ya cargó la parte más pesada, así recién hacer la generacion a imagen
      a.src = shareImg.src

    }

  }, [property])


  const loadShareBlob = async () => {

    let blobResult: Blob = new Blob
    var shareContainers = document.getElementsByClassName('share-container') as HTMLCollectionOf<HTMLImageElement>
    var shareImg = shareContainers[0]

    if (shareImg) {
      shareImg.style.display = 'flex'
      shareImg.style.position = 'relative'
      shareImg.style.left = '0px'

      await domtoimage.toPng(shareImg, { "cacheBust": true })
        .then(async function (dataUrl: any) {

          // Convert dataUrl into blob using browser fetch API
          blobResult = await (await fetch(dataUrl)).blob()

        })
        .catch(function (error: any) {
          //alert("error aqui "+error.toString())
          setLoadingToShare(false)
          console.error('oops, something went wrong!', error);
        });

    } else {
      setLoadingToShare(false)
    }

    return blobResult
  }



  return (

    <div id="shareImg"
      className="share-container"
      style={{ width: useWidth + 'px', height: useHeigth + 'px' }}
    >

      <CustomImage
        src={property.images[0]}
        alt={property.title}
        classes="mortgage-badge share-img"
        width={useWidth}
        height={useHeigth}
      />


      <div className={"share-top w-100 text-rigth " + (userState?.image && userState.image !== '' ? " has-image" : "")}>
        {userState?.image && userState.image !== '' &&
          <div className="share-top-left d-inline-block">
            <img id="share-property-img" className="inmo-logo mb-4"
              src={userState.image} alt="" />
          </div>

        }
        <br />
        <div className="share-top-title d-inline-block">
          <p>{property.title}</p>
        </div>

      </div>
      <div className="share-bottom-container">
        <div className="share-meetme">
          <p>¡Coordiná tu agenda para conocerlo!</p>
        </div>
        <div className="share-bottom">


          <div className="share-characteristics">
            <p className="property-handoverdate">
              Entrega: {property.handover_date_text}
            </p>
            {property.social_housing && (
              <p className="property-law">Bajo ley de vivienda promovida</p>
            )}
            {
              hasSubsidy && (
                <p className="property-law">Sujeto a subsidio :
                  {property.subsidy_laws?.map(sub => <span>{sub}</span>)}
                </p>
              )
            }
          </div>
          <div className="share-price">
            <PropertyPriceToShare units={property.units} isCol propertyTypeId={property.type.identifier}
              operation={operation} />
          </div>
          <div className="share-location">
            {/* <p className="find-us">Vení a conocerlo en:</p> */}
            <p className="location-icon"><i className="bi bi-geo-alt"></i></p>
            <p className="property-address mb-0 lh-sm">
              {(property.zone ? `${property.zone.name} ` : property.department?.name)}
            </p>
          </div>

        </div>
      </div>



    </div>
  )
}
export default ElementToShare