import { AppraisalProcesss } from "../../fragments/appraisal/AppraisalProcess"
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import useUser from "../../hooks/user/useUser";
import { useEffect } from "react";
import useConfig from '../../hooks/config/useConfig'

export const RequestAppraisal = () => {

    const { isLoggedIn } = useUser();
    const { pathname } = useLocation();
    const {countryFeatures} = useConfig()
    const navigate = useNavigate()

    useEffect(()=>{
        if (countryFeatures.id && !countryFeatures.enable_appraisal ){
            navigate('/feed')
        }
    },[countryFeatures])

    if (!isLoggedIn )
    return <Navigate to="/iniciar-sesion" replace state={{ from: pathname }} />;
    
    return <AppraisalProcesss />
}