import { Link } from "react-router-dom";
import { PropertyCard } from "../../components/PropertyCard/PropertyCard";
import { PropertyType } from "../../interfaces/property";
import Slider, { Settings } from "react-slick";
import { GalleryArrow } from "../../components/GalleryArrow/GalleryArrow";
import { useEffect, useState } from "react";
import {useFetch} from "../../hooks/fetch/useFetch"
import useConfig from "../../hooks/config/useConfig"
import { ProjectType } from "../../interfaces/project";

export const PropertySimilars = ({ id, countryId }: { id: string , countryId : string }) => {
  const [similars, setSimilars] = useState([] as ProjectType[])
  const {getListPropertySimilars} = useFetch()
  const {config} = useConfig()

  useEffect(() => {
    getListPropertySimilars(id,countryId).then((properties) => setSimilars(properties))
  }, [])

  var settings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      { breakpoint: 1200, settings: { slidesToShow: 3, slidesToScroll: 2 } },
      { breakpoint: 992, settings: { slidesToShow: 2, slidesToScroll: 2 } },
      { breakpoint: 750, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    ],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    
    <div>
      {
        similars.length > 0 && 
        <>
          <h3 className="fs-4 fw-normal mb-3">Propiedades Similares</h3>
          <div className="property-similars bg-xs-light px-2 px-lg-4 mb-4 rounded border border">
            <Slider {...settings}>
              {similars.map((property, i) => (
                <Link
                  to={`/proyecto/${property?.identifier}`}
                  target="_blank"
                  key={"carousel_" + i}
                  className={"py-4 px-2 w-100 h-100 text-decoration-none"}
                >
                  <PropertyCard data={property} fixed />
                </Link>
              ))}
            </Slider>
          </div>
        </>
      }
      
    </div>
  );
};

function NextArrow(props: any) {
  const { className, onClick } = props;
  return (
    <GalleryArrow
      direction="right"
      customClass={`shadow similar-arrow border ${className}`}
      size={24}
      onClick={onClick}
    />
  );
}
function PrevArrow(props: any) {
  const { className, onClick } = props;
  return (
    <GalleryArrow
      direction="left"
      customClass={`shadow similar-arrow border ${className}`}
      size={24}
      onClick={onClick}
    />
  );
}
