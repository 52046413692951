import { Container } from "react-bootstrap";
import { Empty } from "../components/Empty/Empty";

const NoPage = () => {
  return (
    <main className="bg-xs-light">
      <Container
        className="d-flex align-items-center"
        style={{ minHeight: "calc(100vh - 54px)" }}
      >
        <Empty type="404" />
      </Container>
    </main>
  );
};

export default NoPage;
