

import { createRef, useEffect, useState } from "react"
import { useFetch } from "../../../hooks/fetch/useFetch"
import { useUserFetch } from "../../../hooks/fetch/useUserFetch"
import { UpdatePasswordBody, UpdateUserBody } from "../../../interfaces/userActivity"
import Lottie from "lottie-react";
import GrayLoader from "../../../lotties/gray-loader.json";

import { HexColorPicker } from "react-colorful";
import { isValidHexaCode } from "../../../utils/Functions"
import { Col, Dropdown, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap"

import useAlert from "../../../hooks/alert/useAlert";
import useUser from "../../../hooks/user/useUser"
import useConfig from "../../../hooks/config/useConfig"

import "./UserProfileForm.scss"
import { IconInfoCircle } from "../../../components/CustomIcons/IconInfoCircle";
import TitleCardTS from "../../../daisyUI/components/Cards/TitleCardTs";
import { AlertVariants } from "../../../context/Alert/AlertContext";

const UserAccountForm = () => {

    const { getUserDetail } = useFetch()
    const { updateProfile, updatePassword } = useUserFetch()
    const { setUser, userState } = useUser()
    const { translation } = useConfig()

    const initialData: UpdatePasswordBody = {
        current_password: '',
        new_password: '',
        repeat_password: ''
    }
    const initialErrors = {
        current_password: '',
        new_password: '',
        repeat_password: ''
    }
    const [userData, setUserData] = useState<UpdatePasswordBody>(initialData)

    const [errors, setErrors] = useState<any>(initialErrors)
    const [sent, setSend] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [showCurrentPassword , setShowCurrentPassword] = useState(false)
    const [showNewPassword , setShowNewPassword] = useState(false)
    const [showRepeatPassword , setShowRepeatPassword] = useState(false)

    const skipInputCurrentRef : React.RefObject<HTMLButtonElement> = createRef()
    const skipInputRef : React.RefObject<HTMLButtonElement> = createRef();
    const skipInputRefRepeat : React.RefObject<HTMLButtonElement> = createRef();
    useEffect(() => {
        if(skipInputCurrentRef.current) skipInputCurrentRef.current.tabIndex = -1;
        if(skipInputRef.current) skipInputRef.current.tabIndex = -1;
        if(skipInputRefRepeat.current) skipInputRefRepeat.current.tabIndex = -1;
    }, []);

    const { setPositionAlert, setTypeGlobalAlert, setShowGlobalAlert, setMessageGlobalAlert } = useAlert()

    // useEffect(() => {
    //     loadUserData()
    // }, [])

    const setValue = (field: string, value: string) => {
        setUserData((prev: any) => { return { ...prev, [field]: value } })
        if (sent) {
            validateForm()
        }
    }

    const updateUser = (ev:any) => {
        ev.preventDefault()
        setSend(true)
        if (validateForm()) {
            setLoading(true)
            updatePassword(userData).then(res => {
                if (res.success) {
                    showAlertMessage('success', 'Contraseña actualizada')
                    setUserData(initialData)
                } else {
                    showAlertMessage('danger', res.message ? res.message : 'Ocurrió un error')
                }
                setLoading(false)
                
            }).catch(err => {

                setLoading(false)
            })
        }
        return true

    }

    const validateForm = () => {

        let isValid = true
        setErrors(initialErrors)

        if (!userData.current_password || userData.current_password == '') {
            setErrors((prev: any) => { return { ...prev, current_password: 'Este campo es requerido' } })
            isValid = false
        }else if (userData.current_password.length < 8) {
            setErrors((prev: any) => { return { ...prev, current_password: 'Mínimo 8 caracteres' } })
            isValid = false
        }

        if (!userData.new_password || userData.new_password == '') {
            setErrors((prev: any) => { return { ...prev, new_password: 'Este campo es requerido' } })
            isValid = false
        } else if (userData.new_password.length < 8) {
            setErrors((prev: any) => { return { ...prev, new_password: 'Mínimo 8 caracteres' } })
            isValid = false
        }

        if (!userData.repeat_password || userData.repeat_password == '') {
            setErrors((prev: any) => { return { ...prev, repeat_password: 'Este campo es requerido' } })
            isValid = false
        } else if (userData.repeat_password.length < 8) {
            setErrors((prev: any) => { return { ...prev, repeat_password: 'Mínimo 8 caracteres' } })
            isValid = false
        }

        if (userData.new_password != userData.repeat_password) {
            setErrors((prev: any) => { return { ...prev, repeat_password: 'La contraseña no coincide' } })
            isValid = false
        }

        return isValid
    }

    const showAlertMessage = (type: AlertVariants, message: string) => {
        setPositionAlert('bottom')
        setTypeGlobalAlert(type)
        setMessageGlobalAlert(message)
        setShowGlobalAlert(true)
        setTimeout(() => {
            setShowGlobalAlert(false)
            setTypeGlobalAlert('success')
            setMessageGlobalAlert('')
            setPositionAlert('top')
        }, 3000);

    }


    return (
        <TitleCardTS title="" topMargin="tw-pt-4 tw-border user-form ">
            <Form onSubmit={updateUser}>
                <Row>
                    <Col lg='6'>
                        <Form.Group controlId="name" className="mt-2">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="text" disabled value={userState.email} />
                        </Form.Group>
                    </Col>
                    <Col lg='6'>
                        <Form.Group controlId="current_password" className="mt-2">
                            <Form.Label>Contraseña Actual *</Form.Label>
                            <Form.Control  type={showCurrentPassword ? 'text': 'password'}  className={`${errors.current_password ? 'is-invalid' : ''}`} 
                            value={userData.current_password}
                                onChange={(ev: any) => setValue('current_password', ev.target.value)}
                            />
                            <button
                                type="button"
                                ref={skipInputCurrentRef}
                                className="login-icon-password float-end"
                                onClick={(e) => setShowCurrentPassword(prev => !prev)}
                            >
                                <i
                                className={`"bi" ${showCurrentPassword? "bi-eye-slash" : "bi-eye"
                                    }`}
                                ></i>
                            </button>
                        </Form.Group>
                        {errors.current_password && <p className="text-danger tw-text-sm ">{errors.current_password}</p>}
                    </Col>
                </Row>
                <Row >
                    <Col lg='6'>
                    <Form.Group controlId="new_password" className="position-relative mt-2">
                            <Form.Label>Contraseña Nueva *</Form.Label>
                            <Form.Control type={showNewPassword ? 'text': 'password'} className={`${errors.new_password ? 'is-invalid' : ''}`} 
                            value={userData.new_password}
                                onChange={(ev: any) => setValue('new_password', ev.target.value)}
                                autoComplete="new-password"
                            />
                            <button
                                type="button"
                                ref={skipInputRef}
                                className="login-icon-password float-end"
                                onClick={(e) => setShowNewPassword(prev => !prev)}
                            >
                                <i
                                className={`"bi" ${showNewPassword? "bi-eye-slash" : "bi-eye"
                                    }`}
                                ></i>
                            </button>
                        </Form.Group>

                        {errors.new_password && <p className="text-danger tw-text-sm ">{errors.new_password}</p>}

                    </Col>
                    <Col lg='6'>
                        <Form.Group controlId="repeat_password" className="mt-2">
                            <Form.Label>Repetir Contraseña *</Form.Label>
                            <Form.Control type={showRepeatPassword ? 'text': 'password'} className={`${errors.repeat_password ? 'is-invalid' : ''}`}
                             value={userData.repeat_password}
                                onChange={(ev: any) => setValue('repeat_password', ev.target.value)}
                            />
                            <button
                                type="button"
                                ref={skipInputRefRepeat}
                                className="login-icon-password float-end"
                                onClick={(e) => setShowRepeatPassword(prev => !prev)}
                            >
                                <i
                                className={`"bi" ${showRepeatPassword? "bi-eye-slash" : "bi-eye"
                                    }`}
                                ></i>
                            </button>
                        </Form.Group>
                        {errors.repeat_password && <p className="text-danger tw-text-sm ">{errors.repeat_password}</p>}
                    </Col>
                </Row>

                <div className="mt-4">
                    <button className="btn-save-profile w-100 tw-btn tw-btn-primary tw-float-right text-white tw-relative tw-flex"
                    type="submit" disabled={loading} style={{ minWidth: 180 }}>
                        Guardar
                        {loading && <Lottie className="position-absolute loader-btn top-1" animationData={GrayLoader} style={{ height: 40, right: 10 }} loop />}
                    </button>
                </div>

            </Form>


            
        </TitleCardTS>
    )
}

export default UserAccountForm