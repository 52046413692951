import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import useBreakpoint from "use-breakpoint";
import { PropertyCard } from "../../components/PropertyCard/PropertyCard";
import { BREAKPOINTS } from "../../utils/Constants";
import { useEffect, useState } from "react";
import {useFetch} from "../../hooks/fetch/useFetch"
import useConfig from "../../hooks/config/useConfig"
import { ProjectType } from "../../interfaces/project";
import { useLinks } from "../../hooks/config/useLinks";

export const Highlights = () => {
  
  const [highlightProperties , setHighlightProperties]  = useState<ProjectType[]>([])
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");
  const { getListHighlights} = useFetch()
  const {config } = useConfig()
  const { mainProjectsUrl  } = useLinks()

  useEffect(() => {
    
    if(config && config.country ){
      getListHighlights(config.country.id).then((props) => {
        if(props.success){
          setHighlightProperties(props.data);
        }else{
          setHighlightProperties([])
        }
        
      })
    }
    
  }, [config])
  

  return (
    <Container className={"highlights py-5 " + (breakpoint === "xs" ? "px-4" : "")}>
      <Row className="mb-4">
        <Col>
          <h3 className="fs-3 fw-bold">
            Propiedades Destacadas
          </h3>
        </Col>
      </Row>
      <Row className="g-3 mb-4">
        {highlightProperties.map((item, index) => (
          <Col lg="3" md="4" sm="6" key={"highlight_" + index}>
            <Link
              to={`/proyecto/${item.identifier}?operation=Venta`}
              target="_blank"
              className="text-decoration-none"
            >
              <PropertyCard data={item} />
            </Link>
          </Col>
        ))}
      </Row>
      <Row>
        <Col className="justify-content-center d-flex">
          <Button size="lg" href={mainProjectsUrl} style={{ minWidth: 200 }}>
            Ver todas las publicaciones
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
