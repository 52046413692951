import { useState, createRef,useEffect } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { login ,validateLoginFields} from "../../data/api";
import useUser from "../../hooks/user/useUser";
import { LoginResponseType, LoginType,UserInfoResponseType, User } from "../../interfaces/user";

import { LoginModalResetPass } from "./LoginModalResetPass";
import useAlert from "../../hooks/alert/useAlert";
import {useFetch} from "../../hooks/fetch/useFetch"
import { useLinks } from "../../hooks/config/useLinks";

interface LoginProps  {
  changeType : any 
}
export const LoginForm = (props : LoginProps) => {
  const { loginUser,saveAccessToken, saveRefreshToken} = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [validated, setValidated] = useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const defaultEmailMessage = "Escriba un email válido"
  const defaultPasswordMessage = "Escriba una contraseña válida"
  const [emailMessage, setEmailMessage] = useState(defaultEmailMessage);
  const [passwordMessage, setPasswordMessage] = useState(defaultPasswordMessage);
  const [modal, setModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [typeAlert,setTypeAlert] = useState('success');
  const [messageAlert, setMessageAlert] = useState("");
  const { mainProjectsUrl } = useLinks();
  const { setTypeGlobalAlert, setMessageGlobalAlert, setShowGlobalAlert } = useAlert();
  const {getUserDetail} = useFetch()

  const skipInputRef : React.RefObject<HTMLButtonElement> = createRef();

  useEffect(() => {
    if(skipInputRef.current) skipInputRef.current.tabIndex = -1;
  }, []);

  const validateForm = () => {
    return credentials.email.length > 0 && credentials.password.length > 0;
  };

  const onKeyUp = (event: any) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    setShowAlert(false)
    setTypeAlert('')
    setMessageAlert('')      

    const form = event.currentTarget;
    form.email?.setCustomValidity('')
    form.password?.setCustomValidity('')
    setEmailMessage(defaultEmailMessage)
    setPasswordMessage(defaultPasswordMessage)
    setValidated(true);
    credentials.email = credentials.email.toLowerCase()

    if(form.checkValidity() !== false){
      validateLoginFields(credentials.email, credentials.password).then(
        (response: LoginType) => {
          
          if (response.status === "error") {
  
            if (response.field === 'email') {
              setEmailMessage(response.message ?? "");
              form.email.setCustomValidity('invalid')
            }
            if (response.field === 'password') {
              setPasswordMessage(response.message ?? "");
              form.password.setCustomValidity('invalid')
            }

            return
  
          } else{
            login(credentials.email,credentials.password).then(
              async (response : LoginResponseType)=>{
              if(response.access_token){
                //Se logueó
                saveAccessToken(response.access_token)
                if(response.refresh_token){

                  saveRefreshToken(response.refresh_token)
                }
                
                
                await getUserDetail().then(
                  (result : UserInfoResponseType)=>{

                  if(result.success && result.data){
                    //TODO : Temporal hardcodeado false
                    result.data['crm'] = false;
                    let userFounded : User = result.data!          
                    loginUser(userFounded)
                    
                    let search = (location.state?.search && location.state?.search!='')? location.state?.search : ''
                    
                    let full = (location.state as any)?.from 
                    if(full && search && search != 'undefined' && search != ''){
                      full += search
                    }
                    navigate(full || mainProjectsUrl); 
                  }else{
                    showAlertMessage('danger', 'No se pudo encontrar el usuario')
                  }
                })
                .catch(err=> {
                  console.log(err)
                  showAlertMessage('danger', 'Ocurrió un error')
                })
                
              }else if(response.message){
                showAlertMessage('danger',response.message)
              }else{
                showAlertMessage('danger', JSON.stringify(response))
              }
              
            }).catch(err =>{
              console.log(err)
              showAlertMessage('danger', 'Ocurrió un error '+err.toString())
            })
          }
        }
      );
    }
    
  };

  const showAlertMessage = (type : string,message : string) => {
    setTypeAlert(type)
    setMessageAlert(message)
    setShowAlert(true)
  }

  return (
    <>
      <h2 className="fw-bold mb-3">Inicia Sesión</h2>
      <Form noValidate validated={validated} onSubmit={handleSubmit} className="login-form w-100">
        <Form.Group className="w-100 mb-3" controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            className="email-input"
            placeholder="correo@ejemplo.com"
            value={credentials.email}
            onChange={(e) => setCredentials({ ...credentials, email: e.target.value })}
            autoFocus
            required
          />
          <Form.Control.Feedback type="invalid">
            {emailMessage}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          className="w-100 mb-3 position-relative"
          controlId="password"
        >
          <Form.Label>Contraseña</Form.Label>
          <Form.Control
            value={credentials.password}
            onChange={(e) =>
              setCredentials({ ...credentials, password: e.target.value })
            }
            onKeyUp={onKeyUp}
            placeholder="********"
            className="password-input"
            type={credentials.showPassword ? "text" : "password"}
            required
            minLength={1}
          />
          <button
            type="button"
            ref={skipInputRef}
            className="login-icon-password float-end"
            onClick={(e) =>
              setCredentials({
                ...credentials,
                showPassword: !credentials.showPassword,
              })
            }
          >
            <i
              className={`"bi" ${credentials.showPassword ? "bi-eye-slash" : "bi-eye"
                }`}
            ></i>
          </button>
          <Form.Control.Feedback type="invalid">
            {passwordMessage}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="remember-me">
          <Form.Check id="remember-me" label="Recordar mis datos" />
        </Form.Group>
        <Button
          className="mb-2 w-100 login-button"
          variant="primary"
          type="submit"
          disabled={!validateForm()}
        >
          Iniciar sesión
        </Button>
        <a
          href=""
          onClick={(e) => {
            e.preventDefault();
            setModal(true)
          }}
          className="d-block text-primary text-center mb-2"
        >
          ¿Olvidaste tu contraseña?
        </a>
        <p className="">
          ¿No tienes cuenta? &nbsp;
          <a href="" className="text-primary "  onClick={(e) => {
            e.preventDefault();
            props.changeType();
          }} target={"_blank"} >Ingresa aqui</a>
        </p>
      </Form>

      <LoginModalResetPass email={credentials.email} modal={modal} setModal={setModal} setMessageAlert={setMessageAlert} setShowAlert={setShowAlert}  setTypeAlert={setTypeAlert}  />

      <Alert 
        variant={typeAlert}  
        show={showAlert} 
        onClose={() => setShowAlert(false)} 
        dismissible
        >
        <span dangerouslySetInnerHTML={{__html : messageAlert}}></span>
      </Alert>
    </>
  );
};
