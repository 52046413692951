export const IconShower = ({ width = 16 }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 8.74999H2.5V3.12499C2.49942 2.91316 2.54086 2.70331 2.62193 2.50761C2.703 2.3119 2.82208 2.13421 2.97228 1.98484L2.98478 1.97234C3.22018 1.73729 3.5224 1.58065 3.85016 1.52381C4.17792 1.46697 4.51524 1.51271 4.81603 1.65477C4.53196 2.12708 4.41389 2.68078 4.48057 3.22789C4.54726 3.775 4.79488 4.28412 5.18409 4.67437L5.52631 5.01658L4.89641 5.64652L5.60347 6.35358L6.23338 5.72368L9.72366 2.23346L10.3536 1.60356L9.64647 0.896463L9.01653 1.52637L8.67431 1.18415C8.26458 0.775566 7.72448 0.5237 7.14812 0.472442C6.57176 0.421185 5.99568 0.573785 5.52028 0.903651C5.01897 0.587061 4.4249 0.450387 3.83564 0.516081C3.24638 0.581775 2.69699 0.845929 2.27772 1.26515L2.26522 1.27765C2.02186 1.51968 1.82892 1.80758 1.69757 2.12467C1.56622 2.44177 1.49907 2.78177 1.5 3.12499V8.74999H0.5V9.74999H1.5V10.7094C1.49998 10.79 1.51298 10.8701 1.5385 10.9465L2.46875 13.7371C2.51839 13.8865 2.61385 14.0165 2.74156 14.1086C2.86928 14.2006 3.02275 14.2501 3.18019 14.25H3.58331L3.21875 15.5H4.26041L4.625 14.25H11.1281L11.5031 15.5H12.5469L12.1719 14.25H12.8197C12.9771 14.2501 13.1306 14.2007 13.2584 14.1086C13.3861 14.0165 13.4816 13.8866 13.5312 13.7371L14.4614 10.9465C14.487 10.8701 14.5 10.79 14.5 10.7094V9.74999H15.5V8.74999H14.5ZM5.89125 1.89124C6.16675 1.61634 6.54004 1.46196 6.92923 1.46196C7.31842 1.46196 7.69172 1.61634 7.96722 1.89124L8.30937 2.23346L6.23347 4.30937L5.89125 3.96721C5.61637 3.6917 5.46199 3.31841 5.46199 2.92923C5.46199 2.54004 5.61637 2.16675 5.89125 1.89124ZM13.5 10.6687L12.6396 13.25H3.36037L2.5 10.6687V9.74999H13.5V10.6687Z"
        fill="currentColor"
      />
    </svg>
  );
};
