import { useEffect, useState } from "react"
import { useAppraisal } from "../../hooks/appraisals/useAppraisal"
import { DataAYC, ValidateDataRequest } from "../../interfaces/appraisal"
import { formatMoney, formatMoneyDecimals } from "../../utils/Functions"

export const StepOne = ( 
    { data , setData , setCurrentStep} : 
    { data? : ValidateDataRequest,setData : Function , setCurrentStep : Function}) => {

    const { appraisalCompanies } = useAppraisal()
    const [ showMessage , setShowMessage] = useState(false)

    const selectType = (type : string) => {
        setData( (prev : ValidateDataRequest) => ({
            ...prev,
            type : type
        }))
    }

    const next = () => {
        if(data?.type){
            setCurrentStep((prev : number) => prev + 1)
        }else{
            setShowMessage(true)
            setTimeout(() => {
                setShowMessage(false)
            }, 3000);
        }
    }

    return (
        <div>
            <h3>Elige la empresa tasadora</h3>
            <div className="appraisal-companies-container d-flex justify-content-center mt-4">
            {
                appraisalCompanies.map(c => (
                    <div className={`appraisal_company border border-1 m-2 ${data?.type == c.code ? ' active' : ''}`} key={c.code}>
                        <img  src={c.logo} alt="" />
                        <p className="appraisal-name mt-3">{c.name}</p>
                        <p className="regular-price mt-1">Precio regular : <span>{c.currency} {formatMoneyDecimals(c.regular_price)}</span></p>
                        <p className="fw-bold">Precio Iris : {c.currency} {formatMoneyDecimals(c.iris_price)}</p>
                        {c.code == 'VTP' && <p><small>*Válido por 2 meses</small></p> } 
                        <button className="btn btn-outline-primary my-3" onClick={() => selectType(c.code)}
                        disabled={data?.type == c.code}
                        >
                            {data?.type == c.code && 'Elegida'}
                            {data?.type != c.code && 'Seleccionar'}
                        </button>

                    </div>
                ) )
            }
            </div>
            <div className="company-message">
                {showMessage && <p>Elige una empresa</p>}
                
            </div>
            
            <button  className="btn btn-primary mt-4" onClick={next} >SIGUIENTE</button>
        </div>
    )
}