export const IconForniture = ({ width = 16 }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_46_248)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.0402 0.303C5.07878 0.213 5.14294 0.13631 5.22471 0.0824451C5.30649 0.0285805 5.40228 -8.6892e-05 5.5002 1.97842e-07H10.5002C10.7002 1.97842e-07 10.8802 0.12 10.9602 0.303L13.9602 7.303C13.9895 7.37148 14.003 7.44562 14 7.52003C13.9969 7.59443 13.9773 7.66721 13.9425 7.73305C13.9077 7.79889 13.8586 7.85612 13.7989 7.90058C13.7392 7.94503 13.6703 7.97558 13.5972 7.99H13.5952C13.4452 8.02 13.2952 8.046 13.1452 8.071C11.6089 8.32295 10.0567 8.46498 8.5002 8.496V13.5C8.5002 13.6326 8.44752 13.7598 8.35375 13.8536C8.25998 13.9473 8.13281 14 8.0002 14C7.86759 14 7.74041 13.9473 7.64664 13.8536C7.55288 13.7598 7.5002 13.6326 7.5002 13.5V8.495C5.94373 8.4639 4.39146 8.32188 2.8552 8.07C2.7052 8.045 2.5552 8.02 2.4052 7.99H2.4022C2.3291 7.9755 2.26017 7.94484 2.20045 7.90026C2.14073 7.85569 2.09172 7.79833 2.05702 7.73238C2.02232 7.66643 2.0028 7.59356 1.99989 7.5191C1.99698 7.44463 2.01075 7.37046 2.0402 7.302L5.0402 0.302V0.303ZM3.2102 7.116C4.79465 7.36667 6.39604 7.49505 8.0002 7.5C9.60468 7.4951 11.2064 7.36673 12.7912 7.116L10.1712 1H5.8302L3.2092 7.116H3.2102Z"
          fill="currentColor"
        />
        <path
          d="M6.493 12.574C6.51469 12.7048 6.48358 12.8388 6.40651 12.9466C6.32944 13.0544 6.21272 13.1272 6.082 13.149C5.37 13.267 4.802 13.444 4.427 13.642C4.28924 13.7083 4.16405 13.7979 4.057 13.907C4.03596 13.9292 4.01842 13.9545 4.005 13.982L4.004 13.986L4 13.996V14L4.002 14.008C4.00593 14.0197 4.01131 14.0307 4.018 14.041C4.05751 14.0989 4.10654 14.1496 4.163 14.191C4.328 14.321 4.598 14.461 4.976 14.586C5.727 14.836 6.796 15 8 15C9.204 15 10.273 14.837 11.024 14.586C11.402 14.46 11.672 14.321 11.837 14.191C11.8938 14.1497 11.9432 14.099 11.983 14.041C11.9893 14.0307 11.9944 14.0196 11.998 14.008L12 14V13.996C11.9865 13.9629 11.9672 13.9324 11.943 13.906C11.8359 13.7973 11.7107 13.7079 11.573 13.642C11.197 13.444 10.63 13.267 9.918 13.149C9.85168 13.14 9.78783 13.1178 9.73025 13.0837C9.67267 13.0496 9.62253 13.0043 9.58279 12.9504C9.54306 12.8966 9.51454 12.8353 9.49893 12.7702C9.48332 12.7051 9.48093 12.6375 9.49191 12.5715C9.5029 12.5055 9.52702 12.4424 9.56286 12.3858C9.5987 12.3293 9.64552 12.2806 9.70055 12.2425C9.75558 12.2044 9.81769 12.1777 9.88322 12.1641C9.94874 12.1504 10.0163 12.1501 10.082 12.163C10.852 12.29 11.534 12.491 12.039 12.757C12.5 13 13 13.4 13 14C13 14.426 12.74 14.752 12.456 14.977C12.166 15.205 11.776 15.39 11.34 15.535C10.462 15.828 9.281 16 8 16C6.719 16 5.538 15.828 4.66 15.535C4.224 15.39 3.834 15.205 3.544 14.977C3.26 14.752 3 14.426 3 14C3 13.401 3.5 13 3.961 12.757C4.466 12.491 5.148 12.29 5.918 12.163C6.04874 12.1414 6.18275 12.1725 6.29057 12.2495C6.39838 12.3266 6.4712 12.4433 6.493 12.574Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_46_248">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
