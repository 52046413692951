import { useEffect, useState } from "react"
import "./Pagination.scss"

const Pagination = ({currentPage ,totalPages , handlePagination,maxPages}: {currentPage : number, totalPages : number,handlePagination : Function,maxPages : number}) => {

    const [ arrayPages , setArrayPages ] = useState<any[]>([])

    useEffect(()=> {
        let pages = generatePagination(totalPages,currentPage,maxPages)
        setArrayPages(pages)
    },[currentPage,totalPages])

    const generatePagination = (totalPages: any, currentPage: any, visiblePages: any) => {
        const ellipsis = '...';
        let pagination = [];

        if (totalPages <= visiblePages) {
            pagination = Array.from({ length: totalPages }, (_, index) => index + 1);
        } else {
            const halfVisible = Math.floor(visiblePages / 2);
            const firstVisiblePage = Math.max(currentPage - halfVisible, 1);
            const lastVisiblePage = Math.min(currentPage + halfVisible, totalPages);

            if (firstVisiblePage > 1) {
                pagination.push(1);
                if (firstVisiblePage > 2) {
                    pagination.push(ellipsis);
                }
            }

            for (let i = firstVisiblePage; i <= lastVisiblePage; i++) {
                pagination.push(i);
            }

            if (lastVisiblePage < totalPages) {
                if (lastVisiblePage < totalPages - 1) {
                    pagination.push(ellipsis);
                }
                pagination.push(totalPages);
            }
        }

        return pagination;
    }


    
    return (
        <>
        <div className="custom-pagination">
                    <ul className="d-flex justify-content-center align-items-center px-0">
                        <li className={`m-2 list-unstyled rounded p-2 pagination-move ${currentPage <= 1 ? 'disabled' : ''}`} title="Anterior"
                            onClick={() => handlePagination(currentPage - 1)}
                        >
                            <i className="bi bi-chevron-left"></i> </li>


                        {arrayPages && arrayPages.map((i, index) => {
                            if (i != '...') return <li className={`m-2 list-unstyled rounded p-2 px-3 ${currentPage == i? 'active' : ''}`}
                                onClick={() => handlePagination(i)} key={index}>{i}</li>
                            else return i
                        }
                        )
                        }

                        <li className={`m-2 list-unstyled rounded p-2 pagination-move ${currentPage >= arrayPages[arrayPages.length -1] ? 'disabled' : ''}`}
                            title="Siguiente"
                            onClick={() => handlePagination(currentPage + 1)}>
                            <i className="bi bi-chevron-right"></i> </li>
                    </ul>
                </div>
        </>
    )
}

export default Pagination