import { useEffect, useState,useMemo } from "react";
import { Carousel } from "react-bootstrap";
import { PropertyType } from "../../../interfaces/property";
import { GalleryArrow } from "../../GalleryArrow/GalleryArrow";
import { PropertyModal } from "../../PropertyModal/PropertyModal";
import { getEmbedLinkFromGoogleDriveLink, hasSomeParentTheClass, validateDriveFile } from "../../../utils/Functions";
import { GalleryCounter } from "../../GalleryCounter/GalleryCounter";
import useBreakpoint from "use-breakpoint";
import { BREAKPOINTS } from "../../../utils/Constants";
import { CustomImage } from "../../CustomImage/CustomImage";
import ReactPlayer from "react-player/youtube";
import { validateYoutubeVideo } from "../../../utils/Functions"
import { VirtualTour } from "../../VirtualTour/VirtualTour";
import { ProjectType } from "../../../interfaces/project";

interface GalleryType {
  url: string;
  isVideo: boolean;
  virtualTour: string | undefined;
  isDriveFile? : boolean
  driveFile? : string
}

export const GeneralGallery = ({
  images,
  infinite = false,
  counter = false,
  expandable,
  property,
  isCard = false,
  minProjectPrice ,
  widthImgCard ,
  heightImgCard ,
  videoMuted = true,
  videoShowControls = 0
}: {
  images: string[];
  infinite?: boolean;
  counter?: boolean;
  expandable?: boolean;
  property?: ProjectType;
  isCard?: boolean;
  minProjectPrice ? : {currency : string , price : number};
  widthImgCard ? : any
  heightImgCard? : any
  videoMuted? : boolean
  videoShowControls? : number
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [expand, setExpand] = useState(false);
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");
  const [ galleryAssets, setGalleryAssets ] = useState([] as GalleryType[]);

  useEffect(() => {
    setGalleryAssets([]);
    
    if (property) {
      
      if (property.virtual_tour && property.virtual_tour !== "") {
        setGalleryAssets((galleryAssets) => [...galleryAssets, {
          url: "",
          isVideo: false,
          virtualTour: property.virtual_tour
        }]);
      }
    };

    images.forEach(image => {
        let isDriveFile = false
        let embededLink : any
        if (validateDriveFile(image)) {
            embededLink = getEmbedLinkFromGoogleDriveLink(image)
            isDriveFile = true
        }

      setGalleryAssets((galleryAssets) => [...galleryAssets, {
        url: image,
        isVideo: validateYoutubeVideo(image),
        virtualTour: "",
        isDriveFile : isDriveFile,
        driveFile : embededLink
      }]);
    });
  }, [images])



  const gallery = (maxWidth = "w-100") => (
    <Carousel
      className="gallery-carousel"
      defaultActiveIndex={activeIndex}
      interval={null}
      indicators={false}
      onSelect={(eventKey) => setActiveIndex(eventKey)}
      wrap={infinite}
      nextIcon={<GalleryArrow direction="right" size={16} />}
      prevIcon={<GalleryArrow direction="left" size={16} />}
    >
      {galleryAssets.map((asset, index) => (
        <Carousel.Item key={"gallery_image_" + index}>
          <div className={`${maxWidth} h-100 m-auto`}>
            {asset.virtualTour !== "" ? (
              <VirtualTour url={asset.virtualTour} isModal={expand} />
            ) : asset.isVideo ? (
              <div className='player-wrapper'>
                <ReactPlayer
                  url={asset.url}
                  width={"100%"}
                  height={isCard ? heightImgCard : 380}
                  loop
                  muted={videoMuted}
                  controls={false}
                  config={{
                      playerVars: {
                        modestbranding: 0,
                        rel: 0,
                        showinfo: 0,
                        controls: videoShowControls
                      }
                  }}
                />
              </div>
              ) : asset.isDriveFile ? (
                <iframe src={asset.driveFile} width="100%" height="380" allow="autoplay"></iframe>
            ) : !isCard ? (
              <CustomImage
                classes="d-block w-100"
                src={asset.url}
                alt={"gallery " + index}
              />
            ) : (
              <CustomImage
                classes="d-block w-100"
                src={asset.url}
                alt={"gallery " + index}
                width={widthImgCard}
                height={heightImgCard}
              />
            )}
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );

  return (
    <>
      <div
        className="gallery"
        onClick={
          expandable
            ? (e) => {
              if (
                !hasSomeParentTheClass(e.target, "carousel-control-prev") &&
                !hasSomeParentTheClass(e.target, "carousel-control-next")
              ) {
                setExpand(true);
              }
            }
            : undefined
        }
      >
        {gallery()}
        {(counter && !!images.length) && <GalleryCounter activeIndex={activeIndex} imagesLength={images.length} />}
      </div>
      {expandable && typeof property !== "undefined" && (
        <PropertyModal
          className="modal-gallery"
          property={property}
          show={expand}
          onClose={() => setExpand(false)}
          minProjectPrice={minProjectPrice}

        >
          {gallery(`${breakpoint === "xs" ? 'w-100' : 'w-75'}`)}
          <div className="counter-container d-flex align-items-center justify-content-center">
            <GalleryCounter activeIndex={activeIndex} imagesLength={galleryAssets.length} />
          </div>
        </PropertyModal>
      )}
    </>
  );
};
