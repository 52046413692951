import { useEffect, useState } from "react";
import { ProjectContext } from "./ProjectContext"
import { getProjectStatus, getProjectTypes } from "../../data/api";
import useConfig from '../../hooks/config/useConfig'
import { PROPERTIES_VALUES } from "../../utils/Constants";
import { ProjectStatusType, ProjectTypeType } from "../../interfaces/project";
import { translateProjectTypes } from "../../utils/project_helper";

interface props {
    children: JSX.Element | JSX.Element[];
}

export const ProjectProvider = ({ children }: props) => {

    const { country, translation } = useConfig()
    const [projectTypesList, setProjectTypesList] = useState<ProjectTypeType[]>([])
    const [projectStatusList, setProjectStatusList] = useState<ProjectStatusType[]>([])
    const [rawTypesList, setRawTypesList] = useState<ProjectTypeType[]>([])
    const [loadingProjectStatus, setLoadingProjectStatus] = useState(true)
    const [projectStatusUsed, setProjectStatusUsed] = useState(0)
    const usedProjectIdentifier = PROPERTIES_VALUES.STATUS.USED

    useEffect(() => {

        if (translation && rawTypesList?.length > 0) {

            const typesList = translateProjectTypes(rawTypesList, translation, country)
            setProjectTypesList(typesList)
        }

    }, [translation, rawTypesList])


    useEffect(() => {

        getProjectStatus().then(response => {

            if (response && response.success && response.data) {
                if (response?.data) setProjectStatusList(response.data)
                const usedStatus = response.data?.find(status => status.identifier == usedProjectIdentifier)?.id
                if (usedStatus) {
                    setProjectStatusUsed(usedStatus)
                }
                setLoadingProjectStatus(false)
            }
        }).catch(err => {
            console.log(err)
            setLoadingProjectStatus(false)
        })

        getProjectTypes().then(response => {
            if (response && response.success && response.data) {
                setRawTypesList(response.data)
            }
        }).catch(err => console.log(err))

    }, [])


    return (
        <ProjectContext.Provider
            value={{
                projectStatusList,
                projectTypesList,
                loadingProjectStatus,
                projectStatusUsed
            }}
        >
            {children}
        </ProjectContext.Provider>
    )
}