
import { useEffect, useState } from "react"
import { useUserFetch } from "../../../hooks/fetch/useUserFetch"
import { UserSale } from "../../../interfaces/user"
import Pagination from "../../../components/ui/pagination/Pagination"
import "./UserSalesList.scss"
import Lottie from "lottie-react";
import loaderIris from "../../../lotties/orange-loader.json";
import GrayLoader from "../../../lotties/gray-loader.json";
import { formatMoney } from "../../../utils/Functions";
import { Modal , Form, Badge} from "react-bootstrap"
import { UpdateBillFileBody } from "../../../interfaces/userActivity"
import useAlert from "../../../hooks/alert/useAlert";
import useConfig from "../../../hooks/config/useConfig"
import { IconEye } from "../../../components/CustomIcons/IconEye"
import { downloadDocument } from "../../../data/api";
import { IconDownload } from "../../../components/CustomIcons/IconDownload"
import { IconCloudUpload } from "../../../components/CustomIcons/IconCloudUpload"
import { AlertVariants } from "../../../context/Alert/AlertContext"


const UserSalesList = ({ sales, loadSales, loading, hasSome, totalPages,searchTerm }:
    { sales: UserSale[], loadSales: Function, loading: boolean, hasSome: boolean, totalPages?: number ,searchTerm : string}) => {

    const MAX_PAGES = 3
    const LIMIT = 20
    const INITIAL_PAGE = 1
    const COBRADA_STATUS = 'Cobrada'
    const REGISTRADA_STATUS = 'Registrada'
    const FACTURADA_STATUS = 'Facturada'

    const [currentPage, setCurrentPage] = useState(INITIAL_PAGE)
    
    const [showTableBody, setShowTableBody] = useState(false)
    const [modal, setModal] = useState(false)
    const [selectedSale , setSelectedSale] = useState<UserSale>()
    const [billFile , setBillFile] = useState()
    const [loadingFile , setLoadingFile] = useState(false)
    const {updateBillFile} = useUserFetch()
    const {translation} = useConfig()

    const { setPositionAlert, setTypeGlobalAlert, setShowGlobalAlert, setMessageGlobalAlert } = useAlert()


    useEffect(() => {
        setShowTableBody(sales.length > 0 ? true : false)
    }, [sales, searchTerm])


    const handlePagination = (pageNav: number) => {
        if (pageNav != currentPage) {
            setCurrentPage(pageNav)
            loadSales(false, '', '', LIMIT, pageNav)
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }

    }

    const openBillModal = (sale : UserSale) => {

        setModal(true)
        setSelectedSale(sale)
    }

    const selectBillFile = (ev : any) => {
        if(ev.target?.files && ev.target?.files[0]){
            setBillFile(ev.target.files[0])
        }else{
            setBillFile(undefined)
        }
        
    }

    const sendBillFile = (e:any) => {
        e.preventDefault()
        if(selectedSale && billFile){
            let body : UpdateBillFileBody= {
                sale_id : selectedSale?.id.toString(),
                bill_file : billFile
    
            }
            setLoadingFile(true)
            updateBillFile(body).then(result => {
                setLoadingFile(false)
                if(result.success){
                    setModal(false)
                    showAlertMessage('success','Factura actualizada')
                    
                    loadSales(true,'','', LIMIT, INITIAL_PAGE)
                }else{
                    showAlertMessage('danger',result.message?result.message : 'Ocurrió un error')
                }
                // setModal(false)
                
            }).catch(err => {
                setLoadingFile(false)
                console.error(err)
                showAlertMessage('danger','Ocurrió un error')
                // setModal(false)
            })
        }
        
    }

    const handleDowloadVoucher = (ev:any,o:UserSale) => {
        ev.preventDefault()
        let name_file = `Comprobante_${o.project_name}_Unidad_${o.unit_name}`
        downloadDocument((o.voucher_file ?? ""), name_file , o.voucher_file?.split('.').pop() ?? "pdf");

    }

    const showAlertMessage = (type: AlertVariants, message: string) => {
        setPositionAlert('bottom')
        setTypeGlobalAlert(type)
        setMessageGlobalAlert(message)
        setShowGlobalAlert(true)
        setTimeout(() => {
          setShowGlobalAlert(false)
          setTypeGlobalAlert('success')
          setMessageGlobalAlert('')
          setPositionAlert('top')
        }, 3000);
    
    }

    return (
        <>
            <div className={` ${(!sales || sales.length == 0) && !loading ? 'empty' : ''}`}>
            <div className="tw-overflow-x-auto tw-w-full tw-mt-6">
                        {/* Loader */}
                        {
                            loading && <main className="user-guarantees main container p-3 p-sm-5">
                                <div style={{ height: 400 }} className="w-100 d-flex align-items-center justify-content-center">
                                    <Lottie animationData={loaderIris} style={{ height: 100 }} loop /></div>
                            </main>
                        }

                        {/* Table */}
                        {
                            !loading &&
                            <>
                                <table className="tw-table tw-w-full responsive-table ">
                                    <thead>
                                        <tr>
                                            <th>Proyecto</th>
                                            <th>Unidad</th>
                                            <th>{translation.garage}</th>
                                            <th>Total USD</th>
                                            <th>Comisión</th>
                                            <th>Estado</th>
                                            <th>Factura</th>
                                            <th>Comprobante de Pago</th>
                                        </tr>
                                    </thead>
                                    {
                                        showTableBody ?
                                            <tbody>
                                                {
                                                    sales.map((o, k) => {
                                                        return (
                                                            <tr key={k} >
                                                                <td>
                                                                    <span className="mobile-title">Projecto: </span> <p>{o.project_name}</p>
                                                                </td>
                                                                <td><span className="mobile-title">Unidad: </span>
                                                                    <p className="unit-name">Unidad {o.unit_name}</p>
                                                                    <p className="unit-price"> {o.unit_price_currency} {formatMoney(o.unit_price)}</p>
                                                                </td>
                                                                
                                                                <td>
                                                                    {
                                                                        o.garage_name && 
                                                                        <>
                                                                        <span className="mobile-title">{translation.garage}: </span>
                                                                        <p className="unit-name">{o.garage_name}</p>
                                                                        <p className="unit-price"> {o.garage_price_currency} {formatMoney(o.garage_price)}</p>
                                                                        </>
                                                                    }
                                                                    
                                                                </td>
                                                                <td ><span className="mobile-title">Total: </span> USD {formatMoney(o.total_usd)}</td>
                                                                
                                                                <td>
                                                                    <span className="mobile-title">Comisión: </span>
                                                                    <p className="unit-name">{o.commission_percentage}%</p>
                                                                    <p className="unit-price">{o.commission_currency} {formatMoney(o.commission)}</p>
                                                                </td>
                                                                
                                                                <td className="text-center sales-status">
                                                                    <span className="mobile-title">Estado: </span> 
                                                                    <Badge pill bg={
                                                                        o.status === REGISTRADA_STATUS ? 'info' : (o.status === FACTURADA_STATUS ? 'primary' : 
                                                                        (o.status === COBRADA_STATUS ? 'success' : ''))
                                                                    }>
                                                                        {o.status}
                                                                    </Badge>
                                                                    
                                                                </td>

                                                                <td className="text-center sales-bill">
                                                                    <span className="mobile-title">Factura: </span>
                                                                    <div >
                                                                        {
                                                                            o.bill_file && 
                                                                            <a href={o.bill_file} className="me-1 text-primary" title="Ver archivo" target="_blank" >
                                                                                <button className="btn btn-outline-primary border-0"  >
                                                                                <IconEye width="25" />
                                                                                </button>
                                                                            </a>
                                                                        }
                                                                        {
                                                                            o.status != COBRADA_STATUS && 
                                                                            <button className="btn btn-outline-primary  border-0" onClick={() => openBillModal(o)} title="Subir archivo">
                                                                                <IconCloudUpload width="25" />
                                                                            </button>
                                                                        }
                                                                        
                                                                    </div>
                                                                    
                                                                    
                                                                </td>
                                                                <td className="text-center sales-voucher ">
                                                                    <span className="mobile-title">Comprobante de pago: </span>
                                                                    {
                                                                        o.voucher_file && 
                                                                        <a href={o.voucher_file} target="_blank" title="Descargar Archivo" onClick={(ev:any) => handleDowloadVoucher(ev,o)}>
                                                                            <button className="btn btn-outline-primary border-0 "  >
                                                                            
                                                                            <IconDownload width="25" />
                                                                            </button>
                                                                        </a>
                                                                    }
                                                                    {
                                                                        !o.voucher_file && 
                                                                        <p>--- </p>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody> : ''
                                    }

                                </table>

                                {showTableBody && totalPages && <Pagination currentPage={currentPage} handlePagination={handlePagination} totalPages={totalPages} maxPages={MAX_PAGES} />}
                            </>
                        }


                        {/* Message */}
                        {
                            (!sales || sales.length == 0) && !loading &&
                            <p className="text-center mt-4">{searchTerm == '' || !searchTerm ? 'Aún no hay ventas registradas' :
                                'No se encontró ninguna venta que coincida'}
                            </p>
                        }

                    </div>

                    <Modal
                        show={modal}
                        onHide={() => setModal(false)}
                        centered
                        contentClassName="bg-md-light border-0 "
                        size="lg"
                    >
                        
                            <Modal.Header closeButton className="border-bottom-0 pb-0 mb-4">
                                <Modal.Title
                                    className="d-flex flex-column w-100 text-center"
                                    style={{ marginRight: -24 }}
                                >
                                    <span className="text-primary">Proyecto: {selectedSale?.project_name} - Unidad {selectedSale?.unit_name}</span>
                                    
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="pt-1 px-3 pb-3 px-lg-4 pb-lg-4 mt-4 ">
                                {selectedSale && 
                                    <div >
                                        <div >
                                        
                                            <Form onSubmit={sendBillFile} >
                                                <div className="mb-3">
                                                    <Form.Group controlId="formFile" >
                                                        <Form.Label className=" text-left"><strong>{selectedSale.bill_file ? 'Actualiza tu factura:': 'Carga tu factura: '}</strong> </Form.Label>
                                                        <Form.Control type="file" className=" tw-w-full shadow-none " accept=".jpg,.jpeg,.png,.pdf" required 
                                                        onChange={selectBillFile}
                                                        />
                                                    </Form.Group>
                                                    <div className="text-center mt-4">
                                                        <button type="submit" className="btn btn-primary btn-upload-bill-file position-relative mt-4 " disabled={loadingFile}>
                                                            CARGAR
                                                            {loadingFile && <Lottie className="position-absolute loader-btn top-1" animationData={GrayLoader} style={{ height: 30 , right : 5,top:2 }} loop />}
                                                        </button>
                                                    </div>
                                                    
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                }
                            </Modal.Body>
                        
                    </Modal>
            </div>


        </>
    )
}


export default UserSalesList