export const IconGarden = ({ width = 20 }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9996 6.50001C16.2218 3.22224 13.3329 0.944458 9.99957 0.944458C5.99957 0.944458 2.77734 4.16668 2.77734 8.16668C2.77734 11.9445 5.72179 15.0556 9.44401 15.3333V18.1111C9.44401 18.4445 9.66623 18.6667 9.99957 18.6667C10.3329 18.6667 10.5551 18.4445 10.5551 18.1111V12.2222V10.1667L13.1662 7.55557C13.3885 7.33335 13.3885 7.00001 13.1662 6.77779C12.944 6.55557 12.6107 6.55557 12.3885 6.77779L10.5551 8.66668V7.00001L8.72179 5.16668C8.49957 4.94446 8.16623 4.94446 7.94401 5.16668C7.72179 5.3889 7.72179 5.72224 7.94401 5.94446L9.44401 7.44446V10.8889L7.3329 8.77779C7.11068 8.55557 6.77734 8.55557 6.55512 8.77779C6.3329 9.00001 6.3329 9.33335 6.55512 9.55557L9.44401 12.4445V14.2222C6.3329 13.9445 3.88845 11.3333 3.88845 8.16668C3.88845 4.77779 6.61068 2.05557 9.99957 2.05557C13.3885 2.05557 16.1107 4.77779 16.1107 8.16668C16.1107 10.8889 14.2773 13.2778 11.6662 14.0556V15.2222C15.5551 14.2778 17.944 10.3889 16.9996 6.50001Z"
        fill="currentColor"
      />
    </svg>
  );
};
