import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import userFilter from "../../../hooks/filter/useFilter";
import { useSearchParams } from "react-router-dom";
import { uniqueFromArray, excludeFromArray } from "../../../utils/Functions";

export const FilterFinancing = () => {
  const { setFilter } = userFilter();
  const [searchParams] = useSearchParams();
  const [currentFinancing, setCurrentFinancing] = useState([] as string[]);

  const financingTypes = [
    {
      value: "hasOwnFinancial",
      label: "Propia"
    },
    {
      value: "hasBankFinancial",
      label: "Bancaria"
    }
  ]

  useEffect(() => {
    const parameter = searchParams.get("financing");

    setCurrentFinancing(
      !!parameter
        ? financingTypes.filter((t) => parameter.split(",").includes(t.value)).map((t) => t.value)
        : ([] as string[])
    );
  }, [searchParams]);

  return (
    <Form>
      {financingTypes.map((f) => (
        <div key={`filter-${f.label}`} className="mb-1">
          <label className="d-inline-flex w-100 text-capitalize">
            <Form.Check
              className="me-2"
              type="checkbox"
              value={f.value}
              checked={currentFinancing.includes(f.value) ?? false}
              onChange={(e) => {
                const current = currentFinancing ?? [];
                setFilter({
                  financing: e.target.checked
                    ? (uniqueFromArray([
                      ...current,
                      f.value,
                    ]) as unknown as string[])
                    : (excludeFromArray(
                      current,
                      f.value
                    ) as unknown as string[]),
                });
              }}
            />
            {f.label}
          </label>
        </div>
      ))}
    </Form>
  );
};
