import { CountUpStatistics } from "../fragments/home/CountUpStatistics";
import { BannerTop } from "./../fragments/home/BannerTop";
import { HowItWork } from "./../fragments/home/HowItWork";
import { Highlights } from "./../fragments/home/Highlights";
import { DescriptionSection } from "./../fragments/home/DescriptionSection";
import { JoinPlatform } from "./../fragments/home/JoinPlatform";
import "./../styles/home.scss";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useUser from "../hooks/user/useUser"
import { Categories } from "../fragments/home/Categories";
import { Benefits } from "../fragments/home/Benefits";
import useCountry from "../hooks/config/useConfig";
import { useEffect } from "react";
import { useLinks } from "../hooks/config/useLinks";

const Home = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useUser();
  const { countryFeatures } = useCountry();
  const { mainProjectsUrl } = useLinks()

  useEffect(() => {
    window.localStorage.removeItem("OperationType");
  },[])
  
  return (
    <main>
      <BannerTop />

      <Container>
        <Row>
          <Col lg={{ span: 12 }} >
            <HowItWork />
            <Container>
              <Row className="mb-5 justify-content-center d-lg-none">
                <Col xs="auto">
                  <Button
                    onClick={() => navigate(mainProjectsUrl)}
                    style={{ minWidth: 200 }}
                  >
                    Pruébalo Ahora
                  </Button>
                </Col>
              </Row>
              <hr className="border-bottom-light" />
            </Container>
          </Col>
          <Col lg={{ span: 12 }}>
            <CountUpStatistics />
          </Col>
          {/* @ts-ignore */}
          <Col xs={{ span: 12, order: isLoggedIn ? "first" : "auto" }}>
            <Categories />
          </Col>
          {/* @ts-ignore */}
          {(countryFeatures.mortgageLoan || countryFeatures.rentWarranty ) && <Col xs={{ span: 12, order: isLoggedIn ? "first" : "auto" }}>
            <Benefits />
          </Col>}
          <Col lg={{ span: 12 }}>
            <JoinPlatform />
          </Col>
          {isLoggedIn &&
            <Col lg={{ span: 12 }}>
              <Highlights />
            </Col>
          }
          <Col lg={{ span: 12 }}>
            <DescriptionSection />
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default Home;
