import { ReactNode, useMemo, useState } from "react";
import { Button, Carousel, Col, Container, Row } from "react-bootstrap";
import { IconWheelChair } from "../../components/CustomIcons/IconWheelChair";
import { GalleryArrow } from "../../components/GalleryArrow/GalleryArrow";
import { PropertyModal } from "../../components/PropertyModal/PropertyModal";
import { UnitsSelect } from "../../components/UnitsSelect/UnitsSelect";
import { UnitsTable } from "../../components/UnitsTable/UnitsTable";
import { PropertyType } from "../../interfaces/property";
import { formatMoney } from "../../utils/Functions";
import { GarageModalContent } from "./GarageModalContent";
import useCountry from "../../hooks/config/useConfig";
import { ProjectType , GarageType } from "../../interfaces/project";

export const Garages = ({
  garages = [],
  property
}: {
  garages?: GarageType[];
  property: ProjectType;
}) => {
  const [garageFilter, setGarageFilter] = useState({ value: "all", tipology: "all" });
  const [garageSorter, setGarageSorter] = useState("price");
  const [garageExpand, setGarageExpand] = useState<number | null>(null);
  const { translation } = useCountry();

  // units options
  const options: { value: string; text: string; tipology: string; disabled?: boolean }[] =
    useMemo(() => {
      const disabledAccesible =
        garages.filter((g) => g.active && g.accesible).length === 0;
      const disabled =
        garages.filter((g) => g.active && !g.accesible).length === 0;

      const res: {
        value: string;
        text: string;
        tipology: "Estandar" | "Adaptado" | "all",
        disabled?: boolean
      }[] = [
          { value: "all", text: "Todos", tipology: "all" }
        ];

      garages
        .sort((a, b) => {
          if (garageSorter === "price") return parseFloat(a.price) - parseFloat(b.price);
          else if (garageSorter === "location") {
            if (a.location < b.location) return -1;
            if (a.location > b.location) return 1;
            return 0;
          } else if (garageSorter === "dimension")
            return parseFloat(a.width) * parseFloat(a.depth) - parseFloat(b.width) * parseFloat(b.depth);
          else if (garageSorter === "height") return parseFloat(a.height) - parseFloat(b.height);
          else {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          }
        })
        .forEach((garage, index) => {
          let accesible: string;

          if (garage.accesible) accesible = "accesible";
          else accesible = "notaccesible"

          const isFound = res.some(element => {
            if (element.value === accesible) {
              return true;
            }
            return false;
          });

          if (isFound === false)
            res.push({
              text: garage.accesible ? "Adaptado" : "Estandar",
              value: garage.accesible ? "accesible" : "notaccesible",
              tipology: garage.accesible ? "Adaptado" : "Estandar",
              disabled: garage.accesible ? disabledAccesible : disabled,
            });
          else if (
            res.length > 0 &&
            res[res.length - 1].value === accesible &&
            res[res.length - 1].disabled
          )
            res[res.length - 1].disabled = garage.accesible ? !disabledAccesible : !disabled;
        })

      return res;
    }, [garages, garageFilter, garageSorter]);

  const columns: { text: ReactNode; name: string; visible: boolean }[] = [
    { text: translation.garage, name: "title", visible: true },
    { text: "Precio", name: "price", visible: true },
    { text: "Ubicación", name: "location", visible: true },
    { text: <IconWheelChair />, name: "accesible", visible: true },
    { text: "", name: "action", visible: true },
  ];

  const data: any[] = useMemo(() => {
    const res: any[] = [];
    garages
      .filter((g) => {
        if (garageFilter.value === "notaccesible") return !g.accesible;
        else if (garageFilter.value === "accesible") return g.accesible;
        return garageFilter.value === "all";
      })
      .sort((a, b) => {
        if (garageSorter === "price") return parseFloat(a.price) - parseFloat(b.price);
        else if (garageSorter === "location") {
          if (a.location < b.location) return -1;
          if (a.location > b.location) return 1;
          return 0;
        } else if (garageSorter === "dimension")
          return parseFloat(a.width) * parseFloat(a.depth) - parseFloat(b.width) * parseFloat(b.depth);
        else if (garageSorter === "height") return parseFloat(a.height) - parseFloat(b.height);
        else {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        }
      })
      .forEach((g) => {
        res.push({
          id: g.id,
          title: (
            <div className="d-inline-flex flex-column">
              <span
                className="fs-xxs fw-bold d-lg-none mb-1 mb-lg-0"
                children={translation.garage}
              />
              {g.name}
            </div>
          ),
          price: (
            <div className="d-inline-flex flex-column">
              <span
                className="fs-xxs fw-bold d-lg-none mb-1 mb-lg-0"
                children={"Precio"}
              />
              <span className="fw-bold text-secondary">
                {g.currency} {formatMoney(g.price)}
              </span>
            </div>
          ),
          location: (
            <div className="d-inline-flex flex-column">
              <span
                className="fs-xxs fw-bold d-lg-none mb-1 mb-lg-0"
                children={"Ubicación"}
              />
              {g.location}
            </div>
          ),
          accesible: g.accesible ? (
            <i className="bi bi-check-lg fs-5"></i>
          ) : (
            <span className="d-none d-lg-inline">---</span>
          ),
          action: (
            <Button
              onClick={() =>
                setGarageExpand(garages.findIndex((gr) => gr.id === g.id))
              }
              children={
                <div>
                  <i className="bi bi-plus-circle d-lg-none fs-5"></i>
                  <span className="d-none d-lg-inline">Ver más</span>
                </div>
              }
              variant="outline-primary"
              className="border-0"
              size="sm"
              disabled={g.plan == ""}
            />
          ),
        });
      });
    return res;
  }, [garages, garageFilter, garageSorter]);

  const handleGarageModal = (id: any) => {
    setGarageExpand(garages.findIndex((garage) => garage.id === id));
  };

  return (
    <>
      <Row className="gy-3 align-items-end">
        <Col lg={12} xs={"auto"} className="flex-grow-1">
          <h3 className="fs-4 fw-normal mb-0 mt-0 d-flex flex-column flex-lg-row align-items-lg-end align-items-start">
            {translation.garage}s
            <span
              className="note ms-lg-2 mt-2 mt-lg-0 fs-xxs fw-500 bg-secondary text-white px-1 rounded-1 position-relative text-uppercase"
              style={{ bottom: 3, padding: "2px 0px" }}
            >
              {`${property.garages?.filter((g) => g.active)?.length
                } Disponible`}
            </span>
          </h3>
        </Col>
        <Col lg="12" xs={"auto"}>
          <UnitsSelect
            options={options}
            onChange={setGarageFilter}
            value={garageFilter}
          />
        </Col>
        <Col xs="12">
          <UnitsTable
            data={data}
            columns={columns}
            onSort={(s) => setGarageSorter(s)}
            className="garages"
            handleModal={handleGarageModal}
          />
        </Col>
      </Row>
      <PropertyModal
        show={garageExpand !== null}
        onClose={() => setGarageExpand(null)}
        property={property}
        className="property-modal-garages"
      >
        <Container className="h-100">
          {garageExpand !== null && (
            <Carousel
              className="carousel-100 h-100"
              activeIndex={garageExpand}
              onSelect={setGarageExpand}
              interval={null}
              indicators={false}
              wrap={false}
              controls={garages.length > 1}
              nextIcon={
                <GalleryArrow
                  direction="right"
                  customClass="border shadow property-gallery-arrow"
                />
              }
              prevIcon={
                <GalleryArrow
                  direction="left"
                  customClass="border shadow property-gallery-arrow"
                />
              }
            >
              {garages.map((g, index) => (
                <Carousel.Item key={"floor-modal-item_" + index}>
                  <GarageModalContent garage={g} />
                </Carousel.Item>
              ))}
            </Carousel>
          )}
        </Container>
      </PropertyModal>
    </>
  );
};
