import { Link } from "react-router-dom";
import { PropertyType } from "../../interfaces/property";
import { Col, Row } from "react-bootstrap";
import { PropertyTableRow } from "./PropertyTableRow/PropertyTableRow";
import { PropertyTableHeader } from "./PropertyTableHeader/PropertyTableHeader";
import { OverlayLevelBlock } from "../OverlayLevelBlock/OverlayLevelBlock";
import useUser from "../../hooks/user/useUser";
import userFilter from "../../hooks/filter/useFilter";
import { ProjectType } from "../../interfaces/project";

export const PropertyTable = ({ propertyList , operationLabel } : { propertyList: ProjectType[], operationLabel : string }) => {
    const { userState } = useUser();
    const { filterState } = userFilter();
    const currentCountryId = filterState && filterState.country && filterState.country[0]? filterState.country[0] : null
    //TODO ARI : Corregir validacion de social housing
    const showSocialHousing = currentCountryId ? (currentCountryId == 1) : true

    return (
      <div className="responsive-container mt-4">
        <div className="property-table">
            <PropertyTableHeader showSocialHousing={showSocialHousing}/>
            <Row className="gx-2 gy-3 mb-4 mt-1 mt-lg-0">
              {propertyList.map((item, index) => (
                <Col
                  className="table-row p-0 m-0 position-relative"
                  xl="12"
                  lg="12"
                  md="12"
                  sm="12"
                  xs="12"
                  key={"list_" + index}
                >
                  <Link
                    to={item.level > Number(userState.level) ? "#" : `/proyecto/${item.identifier}${operationLabel}`}
                    target="_blank"
                    className={`text-decoration-none position-relative d-block ${item.level > Number(userState.level) ? "invalid-link" : ""}`}
                  >
                    {item.level > Number(userState.level) && <OverlayLevelBlock listType={"list"} requiredLevel={item.level} />}
                    <PropertyTableRow data={item} showSocialHousing={showSocialHousing} />
                  </Link>
                </Col>
              ))}
            </Row>
        </div>
      </div>
    )
}