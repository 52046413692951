import { useState, useEffect, useRef } from "react";
import { Card } from "react-bootstrap";
import { PostType } from "../../interfaces/post";
import { FeedPostDescription } from "./FeedPostDescription/FeedPostDescription";
import { FeedPostHeader } from "./FeedPostHeader/FeedPostHeader";
import { FeedPostGridGallery } from "./FeedPostGridGallery/FeedPostGridGallery";
import { FeedPostFooter } from "./FeedPostFooter/FeedPostFooter";
import { FeedModal } from "./FeedModal/FeedModal";
import { FeedPostGallery } from "./FeedPostGallery/FeedPostGallery";
import { GalleryCounter } from "../GalleryCounter/GalleryCounter";
import useBreakpoint from "use-breakpoint";
import useUser from "../../hooks/user/useUser";
import { BREAKPOINTS } from "../../utils/Constants";
import ReactPlayer from "react-player/youtube";
import { getEmbedLinkFromGoogleDriveLink, validateDriveFile, validateYoutubeVideo } from "../../utils/Functions";

export const FeedPostCard = ({ post }: { post: PostType }) => {
  const [expand, setExpand] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [orderedImages, setOrderedImages] = useState([]);
  const [hasVideo, setHasVideo] = useState(false);
  const [hasDriveFile, setHasDriveFile] = useState(false);
  const [driveEmbeddedDriveFile, setEmbeddedDriveFile] = useState('')
  const [hasPdf, setHasPdf] = useState(false);
  const [playVideo, setPlayVideo] = useState(false);
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");
  const { userState } = useUser();
  const videoRef = useRef(null);

  
  useEffect(() => {
    if (post.details) {
      const youtubeUrls : any = [];
      const driveUrls : any = [];

      let filteresImages = post.details.images.filter((image) => {
        if (validateYoutubeVideo(image)) youtubeUrls.push(image)
        else return image
      })

      filteresImages = post.details.images.filter((image) => {
        
        if (validateDriveFile(image)) {
          const embededLink = getEmbedLinkFromGoogleDriveLink(image)
          driveUrls.push(embededLink)
        }
        else return image
      })

      setHasVideo(!!youtubeUrls.length)
      setHasDriveFile(!!driveUrls.length)
      setEmbeddedDriveFile(driveUrls[0])
      setOrderedImages(youtubeUrls.concat(driveUrls).concat(filteresImages))
      
      if (post.details.pdf  && typeof post.details.pdf !== "undefined" && post.details.pdf !== "") {
        setHasPdf(true)
      }
    }
  }, []);

  const handleClick = (index: number) => {
    setExpand(true);
    setActiveIndex(index);
  };

  const handleSelect = (eventKey: number) => {
    setActiveIndex(eventKey);
  };

  return (
    <div className="position-relative full-post">
      {Number(userState?.level) < Number(post.level) && (
        <div className="overlay-blocked rounded w-100 h-100 position-absolute text-center d-flex flex-column justify-content-center align-items-center">
          <img
            className="blocked-img"
            alt="Avanza al siguiente nivel"
            src={window.location.origin + "/images/blocked.png"}
          />
          <p className="text-secondary fs-5">
            <strong>Avanza al NIVEL {post.level}</strong>
            <br />
            para desbloquear esta publicación
          </p>
        </div>
      )}
      <Card
        className="post-card border-0 rounded w-100 d-flex flex-column bg-white mb-3 shadow-sm"
        onMouseEnter={() => {
          if (hasVideo) setPlayVideo(true)
        }}
        onMouseLeave={() => {
          if (hasVideo) {
            //@ts-ignore
            videoRef.current?.seekTo(0)
            setPlayVideo(false)
          }
        }}
      >
        <Card.Header
          className={
            "bg-white border-0 p-4 pb-3 " +
            (breakpoint !== "xs" ? "d-flex justify-content-between" : "")
          }
        >
          <FeedPostHeader post={post} />
        </Card.Header>
        <Card.Body className="post-card-cover justify-content-end py-0 rounded">
          {hasVideo ?
            <ReactPlayer
              ref={videoRef}
              url={orderedImages[0]}
              width={"100%"}
              height={380}
              loop
              playing={playVideo}
              muted
              config={{
                  playerVars: {
                    modestbranding: 0,
                    rel: 0,
                    showinfo: 0,
                    controls: 0
                  }
              }}
            /> : hasDriveFile ? 
            <iframe src={driveEmbeddedDriveFile} width="100%" height="380" allow="autoplay"></iframe>
            : hasPdf ? 
            <embed width="100%" height="400" src={post.details?.pdf} type="application/pdf" /> :
            (
              post.details && post.details.identifier && 
                <FeedPostGridGallery
                images={post.details?.images}
                onClick={handleClick}
                postType={post.type }
                idPost={post.identifier}
                idProject={post.details.identifier}
              />
            )
            
          }
          <FeedPostDescription
            post={post}
            collapse
          />
        </Card.Body>
        <Card.Footer className="bg-white border-0">
          {/* Aún no tiene funcionalidad */}
          {/* <div className="border-top mt-2 pb-1"></div>
          <FeedPostFooter likes={post.likes} postType={post.postType} /> */}
        </Card.Footer>

        <FeedModal
          className="modal-gallery"
          show={expand}
          onClose={() => setExpand(false)}
          title={post.details?.title}
        >
          <FeedPostGallery
            width="w-75"
            activeIndex={activeIndex}
            onSelect={handleSelect}
            images={post.details?.images}
          />
          <div className="counter-container d-flex align-items-center justify-content-center">
            <GalleryCounter
              activeIndex={activeIndex}
              imagesLength={post.details?.images.length}
            />
          </div>
        </FeedModal>
      </Card>
    </div>
  );
};
