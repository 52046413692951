import { ReactNode } from "react";
import { Modal } from "react-bootstrap";

export const FeedModal = ({
    className = "",
    show,
    onClose,
    title,
    children,
}: {
    className?: string;
    show: boolean;
    onClose: () => void;
    title: string;
    children: ReactNode;
}) => {

    return (
        <Modal
            fullscreen
            show={show}
            onHide={onClose}
            className={`property-modal text-white ${className}`}
            backdropClassName="property-modal-backdrop"
        >
            <Modal.Header
                closeButton
                closeVariant="white"
                className="border-bottom-0"
            >
                <Modal.Title className="lh-1">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer className="bg-white d-lg-none px-1 py-2">
            </Modal.Footer>
        </Modal>
    )
}