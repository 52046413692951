import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { ToggleButtons } from "../PropertyForm/ToggleButtons";

export const UnitsTableFooter = ({
    range,
    setPage,
    page,
    slice
}: {
    range: any[],
    setPage: Function,
    page: number,
    slice: any[]
}) => {
    useEffect(() => {
        if (slice.length < 1 && page !== 1) {
            setPage(page - 1);
        }
    }, [slice, page, setPage]);

    return (
        <div className="table-footer w-100 d-flex flex-wrap justify-content-center align-items-center gap-1 my-3">
            <Button
                className={`button-footer border-primary bg-white text-secondary`}
                onClick={() => setPage(page - 1)}
                disabled={page === 1}
            >
                <i className="bi bi-chevron-left"></i>
            </Button>
            <Button
                className={`button-footer border-primary bg-white text-secondary`}
                onClick={() => setPage(page + 1)}
                disabled={page === range[range.length - 1]}
            >
                <i className="bi bi-chevron-right"></i>
            </Button>
            <span className="w-100 text-center">Página <span className="fw-bold text-secondary">{page}</span> de <span className="fw-bold text-secondary">{range[range.length - 1]}</span></span>
        </div>
    );
};