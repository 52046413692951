import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { AdminHeader } from "../fragments/header/AdminHeader";
import {useUserFetch} from "../hooks/fetch/useUserFetch"

const AdminLayout = () => {
  const {updateUserStateData} = useUserFetch()
  
  useEffect(() => {
    updateUserStateData()
  },[])

  return (
    <main className="admin-layout">
      <AdminHeader />
      <Outlet />
    </main>
  );
};

export default AdminLayout;
