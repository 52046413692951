import { CustomImage } from "../CustomImage/CustomImage";

export const CountryFlag = ({
  flag,
  border = false,
  shadow = false,
}: {
  flag: string;
  border?: boolean;
  shadow?: boolean;
}) => {
  return (
    <div
      className={
        "country-flag rounded-1" +
        (border ? "" : " border-0") +
        (shadow ? " shadow-sm" : "")
      }
    >
      <CustomImage src={flag} alt="flag" width={20} height={20} />
    </div>
  );
};
