import { useEffect, useState } from "react";
import { FormCheck } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import userFilter from "../../../hooks/filter/useFilter";
import { FilterState } from "../FilterState/FilterState";

export const FilterUnitsRented = () => {
  const { setFilter, filterState } = userFilter();
  const [searchParams] = useSearchParams();
  const [current, setCurrent] = useState<boolean>(false);

  useEffect(() => {
    const parameter = searchParams.get("units_rented");
    setCurrent(!!parameter && parameter === "true");
  }, [searchParams]);

  return (
    <div className="mb-1 d-flex justify-content-start">
      <FormCheck
        type="switch"
        size={40}
        checked={current}
        className="position-relative"
        style={{ left: "0.5rem" }}
        onChange={() => {
          setFilter({ units_rented: !current })
        }}
      />
    </div>
  );
};
