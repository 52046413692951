import { useEffect, useState } from "react"
import { DeveloperType } from "../../interfaces/project"
import useConfig from "../config/useConfig"
import userFilter from "../filter/useFilter";
import { useProjectFetch } from "../fetch/useProjectFetch";


export const useDevelopers = () => {

    const {country , config } = useConfig()
    const { filterState  ,countryFilterID } = userFilter()
    const { getProjectDevelopers } = useProjectFetch()
    const [ developers , setDevelopers ] = useState<DeveloperType[]>([])

    useEffect(() => {

        if(countryFilterID){
            getProjectDevelopers(countryFilterID).then(result => {
                if(result.success && result.data){
                    setDevelopers(result.data)
                }
            })
        }

    },[countryFilterID])


    return {
        developers
    }

}