import { Col, Row } from "react-bootstrap";
import { ProjectFinancialType } from "../../interfaces/project";

export const PropertyExpenses = ({ financial }: { financial: ProjectFinancialType }) => {
  return (
    <Row className="d-flex gy-2">
      <Col lg={12} className="d-flex align-items-center mb-1">
        <h3 className="fs-4 fw-normal m-0">Gastos</h3>
        <i className="ms-2 bi bi-info-circle small text-light"></i>
      </Col>
      {financial.expenses.map((v, index) => (
        <Col lg={6} className="flex-column small" key={"expenses_" + index}>
          <p className="text-light-secondary m-0">{v.name}</p>
          <p className="text-dark m-0">
            <span className="fw-bold">{v.description}</span>
          </p>
        </Col>
      ))}

    </Row>
  );
};
