import { useState } from "react"
import Lottie from "lottie-react";
import lottieUserDoc from "../../lotties/document-user-orange.json";
import lottieApproved from "../../lotties/approved-checked-orange.json";
import lottieMoneyBag from "../../lotties/money-bag-orange.json";
import lottieMortgageLoan from "../../lotties/mortgage-loan-orange.json";
import { IconArrowRight } from "../CustomIcons/IconArrowRight";
import { WarrantyModal } from "../FeedBenefitsModal/WarrantyModal";
import useConfig from "../../hooks/config/useConfig"

export const EmptyWarranties = () => {
    const [showWarranty, setShowWarranty] = useState(false)
    const {translation, country} = useConfig()

    return (
        <div>
            <div className="p-3 text-center total-empty mb-5">
                <p className="mb-0 d-flex justify-content-center align-items-center">Total de <strong className="m-1">{translation.warranty}s</strong>
                <span className="fs-1 fw-bold mx-2">0</span> </p> 
            </div>
            <div className="content-empty pt-5 p-3" >
                <div className="text-center top-benefit">
                    <h4 className="mb-3">¡{translation.leverage} este beneficio!</h4>
                    <p className="mb-3" dangerouslySetInnerHTML={{__html : translation.warranty_description} }>
                    </p>
                    <p>La inmobiliaria se encarga únicamente del contrato y del inventario del inmueble</p>
                </div>

                <div className="d-flex align-items-center warranty-steps">
                    <div>
                        <Lottie id='user-doc' animationData={lottieUserDoc} style={{ height: 100 }} />
                        <p>{translation.warranty_first_step}</p>
                    </div>
                    <div className="arrow-icon">
                        <IconArrowRight width={30} />
                    </div>
                    
                    <div>
                        <Lottie animationData={lottieMortgageLoan} style={{ height: 100 }}  />
                        <p>{translation.warranty_second_step}</p>
                    </div>
                    <div className="arrow-icon">
                        <IconArrowRight width={30} />
                    </div>
                    <div className="aval">
                        <Lottie animationData={lottieApproved} style={{ height: 100 }}  />
                        <p className="position-relative">{translation.warranty_third_step}
                        {/* {
                            country == 'UY' &&
                            <img className="position-absolute extra-info-aval" src={window.location.origin + '/images/warranty/all-gray.png'} alt="" /> 
                        } */}
                         </p>
                        
                    </div>
                    <div className="arrow-icon">
                        <IconArrowRight width={30} />
                    </div>

                    <div>
                        <Lottie animationData={lottieMoneyBag} style={{ height: 100 }}/>
                        <p className="position-relative">Se acredita la comisión a la inmobiliaria
                        {
                            country == 'UY' &&
                            <span className="position-absolute extra-info">
                                {/* <img src={window.location.origin + '/images/warranty/comision.png'} alt="" /> */}
                                <small>Sobre la prima de la póliza. Pago a mes vencido</small>
                            </span>
                        }
                        {
                            country == 'CO' &&
                            <>
                                <small>(Comisión por medio de Sura 18% y por medio de Afiancol 26%)</small>
                            </>
                        }
                             
                        </p>                        
                        
                    </div>
                </div>

                <div className="text-center my-3 btn-open">
                    <button className="btn btn-primary " onClick={() => setShowWarranty(true)}>Derivar {translation.warranty}</button>
                </div>
            </div>

            <WarrantyModal showWarranty={showWarranty} setShowWarranty={setShowWarranty} />
        </div>
    )
}