import {
    NavDropdown,
} from "react-bootstrap";
import dayjs from "dayjs";
import { NotificationsType } from "../../interfaces/notifications";
import { useNavigate } from "react-router-dom";
import useUser from "../../hooks/user/useUser";
import { Image } from "react-bootstrap";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import useAlert from "../../hooks/alert/useAlert";
import {useFetch} from "../../hooks/fetch/useFetch"
import useConfig from "../../hooks/config/useConfig"
import { AlertVariants } from "../../context/Alert/AlertContext";

export const NotificationsItem = ({ data, sliceQty,reloadList }: 
    { data: NotificationsType[], sliceQty: number ,reloadList:Function}) => {
    const { userState } = useUser();
    const navigate = useNavigate();
    const {setTypeGlobalAlert,setShowGlobalAlert,setMessageGlobalAlert} = useAlert()
    const {markViewedNotifications } = useFetch()
    const {translation} = useConfig()

    dayjs.extend(utc);
    dayjs.extend(timezone);

    const showAlertMessage = (type : AlertVariants ,message : string) => {
        setTypeGlobalAlert(type)
        setMessageGlobalAlert(message)
        setShowGlobalAlert(true)
        setTimeout(() => {
          setShowGlobalAlert(false)
          setTypeGlobalAlert('success')
          setMessageGlobalAlert('')      
        }, 3000);
        
    }

    const goToProject = (notification : NotificationsType) =>{
        
        if (notification.is_viewed) {
            if(notification.link ){
                 window.location.href = notification.link
            }
            
        } else if(notification.id) {
            
            markViewedNotifications(userState.id, notification.id.toString(),true)
            .then(result=>{
                if(result.success === false && result.message != ''){
                    showAlertMessage('danger',result.message)
                }else if(result.success === true){
                    if(notification.link && notification.link !='') window.location.href = notification.link;
                    else {
                        reloadList()
                    }
                }
            })
        }
    }
    


    function dateDiffToString(a: any, b: any) {
        let diff = Math.abs(a - b);
        let miliseconds = diff % 1000;
        diff = (diff - miliseconds) / 1000
        let seconds = diff % 60;
        diff = (diff - seconds) / 60
        let minutes = diff % 60;
        diff = (diff - minutes) / 60
        let hours = diff % 24;
        let days = (diff - hours) / 24

        return { days, hours, minutes, seconds, miliseconds };
    }


    return (
        <>
            {data.slice(0, sliceQty).map((notification, index) => {
                
                const date1 = new Date()
                const date2 = notification.type=='manual' && notification.created_at ? 
                    new Date(notification.created_at): (notification.date? new Date(notification.date) : new Date())
                const { days, hours, minutes, seconds, miliseconds } = dateDiffToString(date2, date1);

                const differenceText = days >= 1 ? `${days} ${days === 1 ? "día" : "días"}` :
                    hours >= 1 ? `${hours} ${hours === 1 ? "hora" : "horas"} ${minutes >= 1 ? `${minutes} ${minutes === 1 ? "minuto" : "minutos"}` : ""}` :
                        minutes >= 1 ? `${minutes} ${minutes === 1 ? "minuto" : "minutos"}` :
                            seconds >= 1 ? `${seconds} ${seconds === 1 ? "segundo" : "segundos"}` : ""

                return (
                    <NavDropdown.Item
                        onClick={() => {if(notification.id)goToProject(notification)}}
                        key={"drop_" + index}
                        className={`${notification.title?.includes(translation.warranty+' de')?' warranty-notification text-capitalize':'' } ${(index + 1) !== sliceQty ? "border-bottom" : ""}` }
                        
                    >
                        <Image
                            width={60}
                            height={60}
                            src={
                                notification.image !== "" ?
                                    notification.image :
                                    "https://cdn2.infocasas.com.uy/web/62ab96629ac14_default-img-property.png"
                            }
                            
                        />
                        <div className="description">
                            {

                                notification.type_notification && notification.type_notification === "unit_price_change" ? (
                                    //@ts-ignore
                                    <p><strong>{notification.project_title}</strong> modificó sus precios.</p>
                                ) :
                                // notification.type_notification === "new_project" ? (
                                //     <p>NUEVO INGRESO: conoce <strong>{notification.title}</strong>.</p>
                                // ) : notification.type_notification === "new_unit" ? (
                                //     <p>El proyecto <strong>{notification.project_title}</strong> tiene nuevas unidades.</p>
                                // ) :  notification.type_notification === "unit_sold" ? ( //unit_sold
                                //     <p>VENDIDO! unidad <strong>{notification.unit_title}</strong> de <strong>{notification.project_title}.</strong></p>
                                // ) : notification.type_notification === "project_hand_over_date_change" ? ( //project_hand_over_date_change
                                //     <p><strong>{notification.project_title}</strong> actualizÃ³ su fecha de entrega a: <strong>{notification.handOverDate}.</strong></p>
                                // ) : notification.type_notification === "project_commission_change" ? ( //project_commission_change
                                //     <p>IRIS te da MÃS COMISIÃ“N: <strong>{notification.project_title}</strong> ahora ofrece <strong>{notification.commission}% + IVA por venta.</strong></p>
                                // ) : notification.type_notification === "project_has_rented" ? ( //project_has_rented
                                //     <p>Alquileres disponibles! <strong>{notification.project_title}</strong> ya tiene unidades disponibles para alquilar.</p>
                                // ) : 
                                ( //custom
                                    !notification.type_notification &&<p><strong>{notification.title}</strong> {notification.message}</p>
                                ) 
                                
                            }
                            <span className="datetime">Hace {differenceText}</span>
                        </div>
                        <i className={`bi bi-dot ${notification.is_viewed ? "" : "text-primary"}`}></i>
                    </NavDropdown.Item>
                )
            })}
        </>
    )
}