import { IconArrowLeft } from "../CustomIcons/IconArrowLeft";
import { IconArrowRight } from "../CustomIcons/IconArrowRight";

export const GalleryArrow = ({
    direction,
    customClass,
    size,
    onClick = () => { },
}: {
    direction: "left" | "right";
    customClass?: string;
    size?: number;
    onClick?: () => void;
}) => {
    return (
        <div
            onClick={onClick}
            className={`rounded-circle gallery-arrow ${customClass ?? ""
                } ${direction}`}
        >
            {direction === "left" ? (
                <IconArrowLeft width={size ?? 16} />
            ) : (
                <IconArrowRight width={size ?? 16} />
            )}
        </div>
    );
};