
import { Card, Image } from "react-bootstrap";
import { useImage } from "../../hooks/image/useImage";
import {  UserRankingType } from "../../interfaces/ranking";

import { SkeletonFeedRanking } from "../Skeleton/SkeletonFeedRanking";

export const FeedRankingUsersCard = ({
  rankingUsersData,
  isLoading,
  isModal = false,
  setShowModal = () => { }
}: {
  rankingUsersData : UserRankingType[],
  isLoading : boolean,
  isModal?: boolean,
  setShowModal?: (x: boolean) => void
}) => {
 
  const loaderQty = 3;

  const handleClose = () => setShowModal(false);
  
  if (rankingUsersData.length == 0) return null

  // Se comenta para el demo
  // if (!rankingUsersData.includes(rankingUserLoggedData)) {
  //   rankingUsersData.splice(
  //     rankingUsersData.length - 1,
  //     1,
  //     rankingUserLoggedData
  //   );
  // }

  const OptimizeImage = ({
    src,
    width,
    height,
    alt,
    roundedCircle = false
  }: {
    src: string,
    width: number,
    height: number,
    alt: string,
    roundedCircle: boolean
  }) => {
    const srcOptimize = useImage({ src, width, height })

    return (
      <Image
        src={srcOptimize}
        alt={alt}
        height={height}
        width={width}
        roundedCircle={roundedCircle}
      ></Image>
    )
  }
  
  return (
    <Card className="border-0 py-2 px-1 pb-1 shadow-sm">
      <div className="mt-1">
        <span className="text-green me-3 ms-3 ps-1">
          <i className="bi bi-trophy-fill text-green"></i>
        </span>
        <span className="fw-500 text-secondary">Top Ranking</span>
        {isModal &&
          <i onClick={() => handleClose()} className="bi bi-x text-light-secondary float-end"></i>
        }
      </div>
      <div className="border-top mt-2 mb-1 mx-1"></div>
      <div className="ranking-items">
        {!isLoading ? (
          <table className="w-100">
            <tbody className="w-100">
              {rankingUsersData.map((ranking, index) => (
                <tr
                  key={"ranking_" + index}
                  className="p-2"
                >
                  <td className="fw-bold text-secondary text-center ps-2">
                    {index + 1}
                  </td>
                  <td>
                    {/* {ranking.indicator === "up" ? (
                      <i className="bi bi-arrow-up-short text-green fs-5"></i>
                    ) : ranking.indicator === "down" ? (
                      <i className="bi bi-arrow-down-short text-light-secondary fs-5"></i>
                    ) : (
                      <i className="bi bi-arrow-down-short text-light-secondary fs-5 opacity-0"></i>
                    )} */}
                    <i className="bi bi-arrow-down-short text-light-secondary fs-5 opacity-0"></i>
                  </td>
                  <td className="p-1">
                    <OptimizeImage
                      width={30}
                      height={30}
                      src={ranking.level_icon ?? ""}
                      roundedCircle
                      alt="User ranking"
                    />
                  </td>
                  <td className="p-1">
                    <p className="username mb-0">{(ranking.real_estate || ranking.name)?? ""}</p>
                  </td>
                  <td className="level text-secondary text-uppercase text-end p-1 pe-3">
                    Nivel {ranking.level ?? ""}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          [...Array(loaderQty)].map((item, index) => (
            <div key={index} className="py-1 px-3 text-decoration-none text-body rounded-1 bg-sm-light-hover">
              <SkeletonFeedRanking />
            </div>
          ))
        )}
      </div>
    </Card>
  );
};
