export const IconBalcony = ({ width = 16 }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.33333 8.33335V10H6.66667V8.33335H8.33333ZM13.3333 10V8.33335H11.6667V10H13.3333ZM17.5 11.6667V18.3334H2.5V11.6667H3.33333V8.33335C3.33333 4.65002 6.31667 1.66669 10 1.66669C13.6833 1.66669 16.6667 4.65002 16.6667 8.33335V11.6667H17.5ZM5.83333 13.3334H4.16667V16.6667H5.83333V13.3334ZM9.16667 13.3334H7.5V16.6667H9.16667V13.3334ZM9.16667 3.40002C6.8 3.80002 5 5.85835 5 8.33335V11.6667H9.16667V3.40002ZM10.8333 11.6667H15V8.33335C15 5.85835 13.2 3.80002 10.8333 3.40002V11.6667ZM12.5 13.3334H10.8333V16.6667H12.5V13.3334ZM15.8333 13.3334H14.1667V16.6667H15.8333V13.3334Z"
        fill="currentColor"
      />
    </svg>
  );
};
