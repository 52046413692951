import Subtitle from "../Typography/Subtitle"

  
  function TitleCardTS({title, children, topMargin, TopSideButtons} : 
    {title : any , children : any, topMargin? : any,TopSideButtons? : any }){
      return(
          <div className={"tw-card tw-w-full tw-p-4 md:tw-p-6 tw-bg-base-100 tw-shadow-xl " + (topMargin || "tw-mt-6")} style={{minHeight : '500px'}}>

            {/* Title for Card */}
              <Subtitle styleClass={TopSideButtons ? "tw-inline-block" : ""}>
                {title}

                {/* Top side button, show only if present */}
                {
                    TopSideButtons && <div className="tw-inline-block tw-float-right search-bar">{TopSideButtons}</div>
                }
              </Subtitle>
              
              <div className="tw-divider tw-mt-2"></div>
          
              {/** Card Body */}
              <div className='tw-h-full tw-w-full  tw-pb-6 tw-bg-base-100'>
                  {children}
              </div>
          </div>
          
      )
  }
  
  
  export default TitleCardTS