export const IconBed = ({ width = 16, height = 13 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 0C1.1775 0 0.5 0.6775 0.5 1.5V4.89C0.195 5.166 0 5.561 0 6V10.5H2.5V9.5H11.5V10.5H14V6C14 5.561 13.805 5.166 13.5 4.89V1.5C13.5 0.6775 12.8225 0 12 0H2ZM2 1H12C12.2775 1 12.5 1.2225 12.5 1.5V4.5H11.5V4C11.5 3.1775 10.8225 2.5 10 2.5H8C7.6165 2.5 7.2665 2.65 7 2.89C6.72656 2.64049 6.37016 2.50149 6 2.5H4C3.1775 2.5 2.5 3.1775 2.5 4V4.5H1.5V1.5C1.5 1.2225 1.7225 1 2 1ZM4 3.5H6C6.2775 3.5 6.5 3.7225 6.5 4V4.5H3.5V4C3.5 3.7225 3.7225 3.5 4 3.5ZM8 3.5H10C10.2775 3.5 10.5 3.7225 10.5 4V4.5H7.5V4C7.5 3.7225 7.7225 3.5 8 3.5ZM1.5 5.5H12.5C12.7775 5.5 13 5.7225 13 6V9.5H12.5V8.5H1.5V9.5H1V6C1 5.7225 1.2225 5.5 1.5 5.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
