import { useEffect } from "react";
import { useLocation } from "react-router";

const ScrollToTop = (props: any) => {
  const location = useLocation();

  useEffect(() => {
    if(typeof location.hash !== "undefined" && location.hash.length >0){
      const element = document.getElementById(location.hash.replace("#",""));
      if (typeof element !== "undefined" && element !== null) {
        window.scrollTo({
          top: element.offsetTop,
          behavior: "smooth"
        })
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return <>{props.children}</>;
};

export default ScrollToTop;
