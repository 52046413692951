import { Carousel } from "react-bootstrap"
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { PreHeader } from "./PreHeader";
import { useGoogleAnalytics } from "../../hooks/googleAnalytics/useGoogleAnalytics";
import { WarrantyModal } from "../FeedBenefitsModal/WarrantyModal";
import { PreheaderData } from "../../interfaces/ui";
import { getDateYMD } from "../../utils/Functions";

export const CarouselPreheaders = (
    { banners , shuffle } : 
    { banners : PreheaderData[] , shuffle? : boolean}
) => {

    const [randomBanners, setRandomBanners] = useState<any[]>([])
    const containerRef = useRef(null)
    const [showWarranty,setShowWarranty] = useState(false)
    

    useEffect(()=>{
        const nowDate = new Date(getDateYMD())
        banners = banners.filter( b => {
            const end_date = b.endDate ? new Date(b.endDate) : null
            return !end_date || end_date > nowDate ? true : false
        })
                
        
        if(shuffle){
            const newRandomBanners = shuffleArray(banners).map(v => v)
            setRandomBanners(newRandomBanners)
        }else{
            setRandomBanners(banners)
        }
        
    },[shuffle])
    


    const shuffleArray = (array: any[]) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        console.log("array ", array)
        return [...array]
    }

    const changeBanner = (index: any) => {
    }

    return <div ref={containerRef} className="container mb-2 p-0">
    
        <Carousel id={`carouselColombia`} fade controls={false} indicators={false} interval={7000} onSlide={changeBanner} pause={'hover'} >
            {randomBanners.map((banner: PreheaderData, index: number) => (
                <Carousel.Item key={"carousel_" + index} className="cursor-pointer">
                    <PreHeader {...banner}/>
                </Carousel.Item>
            ))}
        </Carousel>
        <WarrantyModal showWarranty={showWarranty} setShowWarranty={setShowWarranty} />
    </div>

}