import ChevronDownIcon from  '@heroicons/react/24/outline/ChevronDownIcon'
import {useEffect, useState} from 'react'
import { Link, useLocation } from 'react-router-dom'


function SidebarSubmenu({submenu, name, icon}){
    const location = useLocation()
    const [isExpanded, setIsExpanded] = useState(false)


    /** Open Submenu list if path found in routes, this is for directly loading submenu routes  first time */
    useEffect(() => {
        if(submenu.filter(m => {return m.path === location.pathname})[0])setIsExpanded(true)
    }, [])

    return (
        <div className='tw-flex-col'>

            {/** Route header */}
            <div className='tw-w-full' onClick={() => setIsExpanded(!isExpanded)}>
                {icon} {name} 
                <ChevronDownIcon className={'tw-w-5 tw-h-5 tw-mt-1 tw-float-right tw-delay-400 tw-duration-500 tw-transition-all  ' + (isExpanded ? 'tw-rotate-180' : '')}/>
            </div>

            {/** Submenu list */}
            <div className={` tw-w-full `+ (isExpanded ? "" : "tw-hidden")}>
                <ul className={`tw-menu tw-menu-compact`}>
                {
                    submenu.map((m, k) => {
                        return(
                            <li key={k}>
                                <Link to={m.path}>
                                    {m.icon} {m.name}
                                    {
                                            location.pathname == m.path ? (<span className="tw-absolute tw-mt-1 tw-mb-1 tw-inset-y-0 tw-left-0 w-1 tw-rounded-tr-md tw-rounded-br-md tw-bg-primary "
                                                aria-hidden="true"></span>) : null
                                    }
                                </Link>
                            </li>
                        )
                    })
                }
                </ul>
            </div>
        </div>
    )
}

export default SidebarSubmenu