
import "../../fragments/appraisal/appraisal.scss"

export const AppraisalSteps = (
    {steps , currentStep , setCurrentStep , completedStep } : 
    { steps : number[] , currentStep : number , setCurrentStep : Function , completedStep : number}
) => {

    // const stepClick = (step : number) => {
    //     if(step <= currentStep){
    //         setCurrentStep(step)
    //     }
    // }
    
    return (

        <div className="top-steps d-flex justify-content-center mb-4  align-items-center m-auto">
            {
                steps.map((step,index) => (
                    <>

                        <div className={'step p-2 d-flex justify-content-center align-items-center '  
                        + `${step <= currentStep ? ' passed ' : ''}` + `${step == currentStep ? 'active ' : ''}`} key={step}>
                            <span >
                                {step}
                            </span>
                        </div>
                        {
                            index < steps.length -1 && 
                            <div className="connector"></div>
                        }
                        
                    </>
                    
                ))
            }
        
        </div>
        
    )
}