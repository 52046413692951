import { useState } from "react";
import { cleanHtmlFromString } from "../../utils/Functions";

export const PropertyDescription = ({
  description,
}: {
  description: string;
}) => {
  const [collapse, setCollapse] = useState(true);

  return (
    <div className={`property-description ${collapse ? "" : "expand"}`}>
      <p
        className="lh-sm fw-light"
        dangerouslySetInnerHTML={{
          __html: cleanHtmlFromString(description, true),
        }}
      ></p>

      {description.length > 0 && (
        <span
          role={"button"}
          onClick={() => setCollapse(!collapse)}
          className="more bg-xs-light fw-light d-flex align-items-baseline justify-content-between"
        >
          {collapse && "... "}
          <span className="text-primary fs-sm fw-500">
            {collapse ? "Más" : "Menos"}
          </span>
        </span>
      )}
    </div>
  );
};
