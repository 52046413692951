import { useEffect, useState } from "react";
import { Button, Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import StickyBox from "react-sticky-box";
import useBreakpoint from "use-breakpoint";
import { CoordinatiorSection } from "../components/Coordinator/CoordinatorSection";
import { FeedBenefitsCard } from "../components/FeedBenefitsCard/FeedBenefitsCard";
import { FeedInfoInmobiliaria } from "../components/FeedInfoInmobiliaria/FeedInfoInmobiliaria";
import { FeedPropertyCard } from "../components/FeedPropertyCard/FeedPropertyCard";
import { FeedRankingButtons } from "../components/FeedRankingButtons/FeedRankingButtons";
import { FeedRankingModal } from "../components/FeedRankingModal/FeedRankingModal";
import { FeedRankingProjectsCard } from "../components/FeedRankingProjectsCard/FeedRankingProjectsCard";
import { FeedRankingUsersCard } from "../components/FeedRankingUsersCard/FeedRankingUsersCard";
import { useGoogleAnalytics } from "../hooks/googleAnalytics/useGoogleAnalytics";
import useUser from "../hooks/user/useUser";
import { RankingProjectsType, RankingUserType } from "../interfaces/ranking";
import "../styles/feed.scss";
import { BREAKPOINTS } from "../utils/Constants";
import useCountry from "../hooks/config/useConfig";
import {useFetch} from "../hooks/fetch/useFetch"
import { rankingUsers } from "../data/api";
import { useCookies } from "../hooks/cookies/useCookies";
import { count } from "console";
import { useRanking } from "../hooks/ranking/useRanking";

const FeedLayout = () => {
  const { isLoggedIn, userState } = useUser();
  const { pathname } = useLocation();
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");
  const [rankingProjectsData, setRankingProjectsData] = useState([] as RankingProjectsType[]);
  const [rankingUsersData, setRankingUsersData] = useState([] as RankingUserType[]);
  const [showRankingUsers, setShowRankingUsers] = useState(false);
  const [showRankingProjects, setShowRankingProjects] = useState(false);
  const GA = useGoogleAnalytics(true);
  const { country , countryFeatures,translation} = useCountry();
  const [isLoading, setIsLoading] = useState(true);
  const {listRankingProjects} = useFetch()
  const {usersRanking , loadingUsersRanking , projectsRanking , loadingProjectsRanking} = useRanking()
  
  useEffect(() => {
    GA.Event({ category: "User open feed", action: "user_open_feed", label: `${userState.name}`, value: Number(userState.id) })

  }, [])

  useEffect(() => {
    if (country !== "") {
      listRankingProjects(country).then((ranking) => {
        setRankingProjectsData(ranking);
        setIsLoading(false);
      });
      rankingUsers(country).then((users) => {
        if (users.success) {
          setRankingUsersData(users.data);
        }
  
      })
    }
  }, [country])


  const benefits = [
    {
      title: 'SERVICIOS NOTARIALES',
      image: 'https://cdn1.infocasas.com.uy/web/66a12bfca974b_estudio360x2.png',
      text: '¿Tenés un <strong>cliente potencial</strong>? Derivá sus datos y <strong>recibí una comisión</strong> al cierre del negocio',
      type: 'notarial',
      visible : countryFeatures.notarial_service
    },
    {
      title: 'PRÉSTAMOS HIPOTECARIOS',
      badge: 'https://cdn3.infocasas.com.uy/web/62cd6d69be203_mortgagebadge.png',
      image: 'https://cdn3.infocasas.com.uy/web/62cd8ffa6757e_mortgage.png',
      text: '¿Tenés un <strong>cliente potencial</strong>? Derivá sus datos y <strong>recibí una comisión</strong> al cierre del negocio',
      type: 'mortgage',
      visible : countryFeatures.mortgageLoan
    },
    {
      title: translation.rent_warranties,
      badge: 'https://cdn3.infocasas.com.uy/web/6361609bec59e_seguros.png',
      image: 'https://cdn3.infocasas.com.uy/web/62cd8ffa6401e_warranty.png',
      text: `Fácil, rápido y seguro. <strong>${translation.warranty_earning}</strong> por cada ${translation.rent_warranty} que derives`,
      type: 'warranty',
      visible : countryFeatures.rentWarranty
    },
    {
      title: 'MIS ESTADOS DE ' + translation.warranty+'S',
      badge: 'https://cdn3.infocasas.com.uy/web/6361609bec59e_seguros.png',
      image: 'https://cdn1.infocasas.com.uy/web/64b9551659006_img-iris-familia-garantias-b.jpg',
      text: 'Consultá <strong>ahora mismo</strong> el listado de clientes con <strong>'+translation.rent_warranties+'</strong> en proceso',
      type: 'warranty_status',
      visible : countryFeatures.rentWarrantyPanel
    }
  ]

  

  if (!isLoggedIn)
    return <Navigate to={"/iniciar-sesion"} state={{ from: pathname }} />;

  return (
    <main className="feed-container bg-sm-light py-3">
      <Container fluid>
        <Row className="gx-3 justify-content-center">
          {(breakpoint !== "xs" && breakpoint !== "sm" && breakpoint !== "md") &&
            <Col lg="3" style={{ maxWidth: 375 }}>
              <StickyBox offsetTop={50} offsetBottom={20}>
                <FeedInfoInmobiliaria />
                <div className="d-none d-lg-block mb-3">
                  <FeedRankingUsersCard rankingUsersData={usersRanking} isLoading={loadingUsersRanking} />
                </div>
                <div className="d-none d-lg-block mb-3">
                  <FeedRankingProjectsCard rankingData={projectsRanking} isLoading={loadingProjectsRanking} />
                </div>
                <div className="feed-iris-coordinator d-none d-lg-block">
                  <CoordinatiorSection />
                </div>
              </StickyBox>
            </Col>
          }

          <Col lg="6" style={{ maxWidth: 750 }} className={breakpoint === "md" ? "px-4" : ""}>
            <Outlet />
          </Col>

          {country !== "PY" && (
            <Col
              lg="3"
              xs={{ order: breakpoint === "xs" || breakpoint === "sm" || breakpoint === "md" ? "first" : "last" }}
              md={{ span: 12 }}
              style={{ maxWidth: (breakpoint === "lg" || breakpoint === "xl" || breakpoint === "xxl" ? 375 : "auto") }}
            >
              <StickyBox offsetTop={50} offsetBottom={20}>
                <div className={"d-grid gap-3 " + (breakpoint === "sm" || breakpoint === "md" ? "px-4" : "")}>
                  {/* <Search /> Aun no tiene funcionalidad*/}
                  {(breakpoint === "xs" || breakpoint === "sm" || breakpoint === "md") && (
                    <FeedRankingButtons setShowRankingProjects={setShowRankingProjects} setShowRankingUsers={setShowRankingUsers} rankingProjectsData={rankingProjectsData} 
                    rankingUsersData={rankingUsersData} isLoadingProjects={isLoading}/>
                  )}

                  {(breakpoint !== "xs" && breakpoint !== "sm" && breakpoint !== "md") &&
                    (countryFeatures.rentWarranty || countryFeatures.mortgageLoan) ? (
                      benefits.filter(b=>!!b.visible).map((benefit, index) => (
                        <FeedBenefitsCard key={index} benefit={benefit} source="feed" />
                      ))
                    ) : (
                      rankingProjectsData.map((property) => (
                        <FeedPropertyCard property={property} key={property.project?.identifier} />
                      ))
                    )
                  }
                </div>
              </StickyBox>
            </Col>
          )}
        </Row>
      </Container>

      <FeedRankingModal show={showRankingUsers} onClose={() => setShowRankingUsers(false)}>
        <FeedRankingUsersCard rankingUsersData={usersRanking} isLoading={loadingUsersRanking} isModal={true} setShowModal={setShowRankingUsers} />
      </FeedRankingModal>

      <FeedRankingModal show={showRankingProjects} onClose={() => setShowRankingProjects(false)}>
        <FeedRankingProjectsCard rankingData={projectsRanking} isModal={true} setShowModal={setShowRankingProjects} isLoading={isLoading} />
      </FeedRankingModal>
    </main>
  );
};

export default FeedLayout;
