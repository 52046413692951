import { Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import useUser from "../../hooks/user/useUser";
import { PostType } from "../../interfaces/post";
import { FeedPostCard } from "../../components/FeedPostCard/FeedPostCard";
import { SkeletonFeedCard } from "../../components/Skeleton/SkeletonFeedCard";
import useCountry from "../../hooks/config/useConfig";
import useAlert from "../../hooks/alert/useAlert";
import {useFetch} from "../../hooks/fetch/useFetch"
import { AlertVariants } from "../../context/Alert/AlertContext";

export const Posts = () => {
    const [posts, setPosts] = useState([] as PostType[]);
    const [isLoading, setIsLoading] = useState(true);
    const { userState } = useUser();
    const loaderQty = 3;
    const { country,config } = useCountry();
    const {setTypeGlobalAlert,setShowGlobalAlert,setMessageGlobalAlert} = useAlert()
    const {getListPosts} = useFetch()

    const showAlertMessage = (type : AlertVariants,message : string) => {
        setTypeGlobalAlert(type)
        setMessageGlobalAlert(message)
        setShowGlobalAlert(true)
        setTimeout(() => {
          setShowGlobalAlert(false)
          setTypeGlobalAlert('success')
          setMessageGlobalAlert('')      
        }, 3000);
        
    }

    useEffect(() => {
        //Validamos para que dentro de getListPost se use country(config)
        if (config && config.country && config.country.name && config.country.name !== "") {
            
            getListPosts().then((postsList) => {

                if(postsList.success){
                    
                    const firstPostFinder = (post: PostType) => post.level <= userState.level;
                    const firstPostIndex = postsList.data.findIndex(firstPostFinder);
    
                    setIsLoading(false);
    
                    if (firstPostIndex !== 0 && postsList.data.length > 0) {
                        const firstPostData = postsList.data[firstPostIndex];
    
                        postsList.data.splice(firstPostIndex, 1);
                        postsList.data.splice(0, 0, firstPostData);
                    }
    
                    setPosts(postsList.data);
                }else{
                    setPosts([])
                    if(postsList.message && postsList.message !==''){
                        showAlertMessage('danger',postsList.message)
                    }
                }
               
            });

            return () => {
                setPosts([] as PostType[]);
            }
        }
    }, [config]);

    return (
        <main className="mb-3">
            {isLoading && (
                [...Array(loaderQty)].map((item, index) => (
                    <div key={index} className="py-1 px-3 text-decoration-none text-body rounded-1 bg-sm-light-hover">
                        <SkeletonFeedCard />
                    </div>
                ))
            )}
            {posts.length > 0 ? (
                posts.map((post, index) => (
                    <Col key={"post_" + index}>
                        <FeedPostCard post={post} />
                    </Col>
                ))
            ) : (
                <div className="text-center mt-3 text-muted"><span>No hay posts disponibles</span></div>
            )}
        </main>
    );
};
