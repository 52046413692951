import { Col, Row } from "react-bootstrap";
import { OperationType, PropertyType } from "../../interfaces/property";
import useCountry from "../../hooks/config/useConfig";
import { IconCheckCircleFill } from "../CustomIcons/IconCheckCircleFill";
import { ProjectType } from "../../interfaces/project";
import { IconXCircleFill } from "../CustomIcons/IconXCircleFill";

export const PropertyCaracteristics = ({
  property,
  isInPdf = false,
  operation
}: {
  property: ProjectType;
  isInPdf?: boolean;
  operation?: OperationType
}) => {
  const { translation } = useCountry();

  const caracteristics = [
    { title: "Entrega", value: property.handover_date_text },
    { title: translation.floors, value: property.floors },
    {
      title: "Unidades",
      note: `${property.units?.filter((u) => u.active &&
        (!operation || (u.operation_type == operation)))?.length} Disponible`,
      value: property.units?.filter((u) => (!operation || (u.operation_type == operation)))?.length,
    },
    { title: translation.garage+'s', value: (!operation || operation == 'Venta') ? property.garages?.length : 0 },
  ];

  const hasSubsidy = property.subsidy_laws?.length != null && property.subsidy_laws.length > 0 && property.countryFeatures?.subsidyLaws ? true : false

  return (
    <Row className={"property-caracteristics " + (isInPdf ? "gy-1" : "gy-3")} style={{ marginLeft: isInPdf ? "5px" : "" }}>
      {caracteristics.map((c, index) => (
        <Col
          xs={12}
          key={"caracteristics_" + index}
          className="d-flex align-items-baseline justify-content-between"
          style={{ paddingLeft: "31px" }}
        >
          <span className="title fw-light">{c.title}</span>
          {typeof c.note !== "undefined" && (
            <span
              className={"note ms-2 fs-xxs fw-bold px-1 rounded-1 position-relative text-uppercase " + (isInPdf ? "bg-secondary-light text-secondary mb-0" : "bg-secondary text-white")}
              style={{ bottom: 5, padding: "2px 0px" }}
            >
              {c.note}
            </span>
          )}
          <div className="border-bottom flex-grow-1 mx-2"></div>
          <span className="value fw-light">{c.value}</span>
        </Col>
      ))}
      {property.social_housing && property.countryFeatures?.socialHousing && (
        <Col
          xs={12}
          key={"caracteristics_" + caracteristics.length}
          className="d-flex align-items-center justify-content-center"
        >
          <IconCheckCircleFill width={16} height={16} className="text-primary me-2" />
          <span className="fw-500 fs-sm">
            Está bajo la ley de vivienda promovida
          </span>
        </Col>
      )}

        {
          property.countryFeatures?.pieBonus && (
            <Col
                xs={12}
                key={"caracteristics_" + caracteristics.length}
                className="d-flex align-items-center justify-content-center"
              >
                
                  {property.pie_bonus ? (
                    <>
                    <IconCheckCircleFill width={16} height={16} className="text-primary me-2" />
                      <span className="fw-500 fs-sm">
                        Tiene Bono de Pie
                      </span>
                    </>
                  ): (
                    <>
                      <IconXCircleFill width={16} height={16} className="text-light-secondary me-2" />
                      <span className="fw-500 fs-sm">
                        No tiene Bono de Pie
                      </span>
                    </>)}
                
              </Col>
          )
        }
      

      {hasSubsidy && (
        <Col
          xs={12}
          key={"caracteristics_" + caracteristics.length}
          className="d-flex align-items-center justify-content-center flex-column"
        >
          <span className="fw-500 fs-sm w-100">
            Está sujeto a subsidio:
          </span>
          {
            property.subsidy_laws?.map(subsidy =>
              <div>
                <IconCheckCircleFill width={16} height={16} className="text-primary me-2 mb-1" />
                <span className="fw-500 fs-sm">{subsidy}</span>
              </div>
            )
          }

        </Col>
      )}
    </Row>
  );
};
