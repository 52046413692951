import { useEffect, useState } from "react";
import { Dropdown, Offcanvas } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import useBreakpoint from "use-breakpoint";
import userFilter from "../../hooks/filter/useFilter";
import { BREAKPOINTS } from "../../utils/Constants";
import { IconOrder } from "../CustomIcons/IconOrder";
import { IconTrash } from "../CustomIcons/IconTrash";

export const Sorter = () => {
  const { setFilter } = userFilter();
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");
  const [searchParams] = useSearchParams();
  const [currentOrder, setCurrentOrder] = useState(["promos","popularity"] as string[]);
  const [open, setOpen] = useState(false);

  const orderType = [
    { name: "Popularidad", type: "popularity"},
    { name: "Mayor comisión", type: "commision" },
    { name: "Más reciente", type: "date" },
    { name: "Mayor Precio", type: "maxPrice" },
    { name: "Menor Precio", type: "minPrice" },
    { name: "Mayor m2", type: "maxM2" },
    { name: "Menor m2", type: "minM2" },
    { name: "Zona A-Z", type: "addressAsc" },
    { name: "Zona Z-A", type: "addressDesc" },
  ];

  useEffect(() => {
    const parameter = searchParams.get("order")?.split(',') ?? ["promos","popularity"];
    setCurrentOrder(parameter);
  }, [searchParams]);

  const dropdowns = orderType.map((o, i) => {
    
    let active = false
    if(currentOrder.includes(o.type)) active = true
    
    const handleChange = () => {
      let orderList = o.type == 'popularity' ? ["promos"] : []
      orderList.push(o.type)
      setFilter({ order: active ? ["promos","popularity"] : orderList })
    }

    return (
      <Dropdown.Item
        onClick={handleChange}
        key={`estate-${i}`}
        className={`d-flex align-items-center px-2 py-2 rounded-1 ${active ? "bg-sm-light" : ""
          }`}
      >
        <div
          className="rounded-circle border-light border d-flex align-items-center justify-content-center me-2"
          style={{ width: 17, height: 17 }}
        >
          {active && (
            <div
              className="rounded-circle bg-primary d-flex align-items-center justify-content-center"
              style={{ width: 10, height: 10 }}
            ></div>
          )}
        </div>
        {o.name}
      </Dropdown.Item>
    );
  });

  if (["xs", "sm", "md"].includes(breakpoint)) {
    return (
      <>
        <div
          role={"button"}
          onClick={() => setOpen(true)}
          className="d-flex align-items-center"
        >
          <i className="text-primary bi bi-sort-down fs-4" />
        </div>
        <Offcanvas show={open} onHide={() => setOpen(false)}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Ordenar por</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="pt-0">{dropdowns}</Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="info"
        className="bg-white m-0 p-0 border-0 shadow-none sorter d-flex align-items-center"
      >
        <IconOrder width={24} height={24} className="text-primary" />
        <span className="text-button mx-2">Ordenar</span>
      </Dropdown.Toggle>
      <Dropdown.Menu className="px-1 py-1 border shadow">
        {dropdowns}
      </Dropdown.Menu>
    </Dropdown>
  );
};
