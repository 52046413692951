import useConfig from "../../../hooks/config/useConfig"

export const WarrantyFeatures = () => {

    const { country, translation } = useConfig()
    const listInsurance = country == 'UY' ? 'Porto Seguros, MAPFRE, Sura o Alucerto' : ''

    const DetailCommission = (
        <>
            {
                country == 'UY' &&
                <>
                    8% + IVA con Porto y Sura, 12% + IVA Mapfre, 20% + IVA Alucerto, sobre la prima de la póliza <br />
                    <span className="text-light-secondary">(se pagan a mes vencido)</span>
                </>
            }

            {
                country == 'CO' && <small>(Comisión por medio de Sura 18% y por medio de Afiancol 26%)</small>
            }

            {
                (country == 'CL' || country == 'PY') && <small>20% + IVA con Alucerto</small>
            }
        </>
    )
    
    return (
        <>
            {
                country != 'CO' && 
                <>
                    <li>{translation.warranty_first_step}</li>
                    <li>{translation.warranty_second_step}</li>
                    <li>{translation.warranty_third_step} {listInsurance}</li>
                    <li>Se acredita la comisión a la Inmobiliaria <br></br>
                        {DetailCommission}
                    </li>
                </>
            }

            {
                country == 'CO' && 
                <>
                    <li>{translation.warranty_first_step}</li>
                    <li> Iris Fincaraiz, mediante sus  aliados estratégicos realiza proceso de estudio sin costo para el inquilino.</li>
                    <li>Resultado del estudio en 3 horas.</li>
                    <li>Te ayudamos con la elaboración de contrato de arrendamiento y envío a firma digital.</li>
                    <li>{translation.warranty_third_step}</li>
                    <li>Llévate la mejor comisión por cada caso de corretaje de arrendamiento.</li>
                </>
            }

        </>
    )
}