import { useEffect, useState } from "react"
import { ProjectRankingType, UserRankingType } from "../../interfaces/ranking"
import { useRankingFetch } from "../fetch/useRankingFetch"
import useConfig from "../../hooks/config/useConfig"

export const useRanking = () => {

    const [usersRanking , setUsersRanking] = useState<UserRankingType[]>([])
    const [loadingUsersRanking , setLoadingUsersRanking] = useState(false)
    const [projectsRanking , setProjectsRanking] = useState<ProjectRankingType[]>([])
    const [loadingProjectsRanking , setLoadingProjectsRanking] = useState(false)
    const {getUsersRanking , getProjectsRanking} = useRankingFetch()
    const {config} = useConfig()

    useEffect(() => {
        if(config.country.id){
            setLoadingUsersRanking(true)
            setLoadingProjectsRanking(true)

            getUsersRanking(config.country.id).then(data => {
                setLoadingUsersRanking(false)
                if(data.success){
                    setUsersRanking(data.data)
                }else{
                    setUsersRanking([])
                }
                
            }).catch(err => {
                setLoadingUsersRanking(false)
                setUsersRanking([])
            })

            getProjectsRanking(config.country.id).then(data => {
                setLoadingProjectsRanking(false)
                if(data.success){
                    setProjectsRanking(data.data)
                }else{
                    setProjectsRanking([])
                }
                
            }).catch(err => {
                setLoadingProjectsRanking(false)
                setProjectsRanking([])
            })
        }
        
    },[config])


    return {
        usersRanking , 
        loadingUsersRanking ,
        projectsRanking, 
        loadingProjectsRanking
    }
}