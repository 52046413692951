import { Posts } from "../fragments/feed/Posts";

const Feed = () => {
  return (
    <div className="feed" style={{ minHeight: 3000 }}>
      <Posts />
    </div>
  );
};

export default Feed;
