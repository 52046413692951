import { Button, Col, Row } from "react-bootstrap";
import userFilter from "../../hooks/filter/useFilter";

export const Empty = ({
  type = "404",
  href = "/",
}: {
  type?: "404" | "500" | "empty";
  href?: string;
}) => {
  const { removeFilter } = userFilter();

  const errors = {
    "404": {
      image:
        "https://cdn1.infocasas.com.uy/web/620f96b688c1b_infocdn__iris_404.png",
      title: "Ups...",
      text: "No pudimos encontrar esta página. Quizá fue removida o está en mantenimiento.",
      textBold: "Te sugerimos que vuelvas al inicio :)",
      btnText: "Ir al inicio",
    },
    "500": {
      image:
        "https://cdn1.infocasas.com.uy/web/620f96b688dd6_infocdn__iris_500.png",
      title: "Problemas técnicos",
      text: "Hubo un incoveniente y ya estamos trabajando para solucionarlo.",
      textBold: "Te sugerimos que vuelvas al inicio :)",
      btnText: "Ir al inicio",
    },
    empty: {
      image:
        "https://cdn1.infocasas.com.uy/web/620eab7d84d22_infocdn__empty_search.gif",
      title: "Ups...",
      text: "No encontramos ningún resultado para esa busqueda.",
      textBold: "Intenta de nuevo cambiando algunos filtros :)",
      btnText: "Volver a buscar",
    },
  };

  return (
    <Row className="h-100 align-items-center py-5 justify-content-center justify-content-lg-start">
      <Col
        lg="4"
        className="d-flex flex-column justify-content-center align-items-center align-items-lg-start text-center text-lg-start"
      >
        <span
          className={`${type === "500" ? "display-5" : "display-1"
            } d-block fw-bold mb-3 lh-1`}
        >
          {errors[type].title}
        </span>
        <span className="fs-5 fw-light text-black-50 mb-4 d-block">
          {errors[type].text}{" "}
          <b className="fw-bold text-secondary mb-3">{errors[type].textBold}</b>
        </span>
        {
          type === "empty" ? (
            <Button onClick={() => removeFilter()} size="lg" className="fw-500 d-none d-lg-block px-5">
              {errors[type].btnText}
            </Button>
          ) : (
            <Button href={href} size="lg" className="fw-500 d-none d-lg-block px-5">
              {errors[type].btnText}
            </Button>
          )
        }
      </Col>
      <Col lg="8">
        <img
          src={errors[type].image}
          className="w-100 h-100"
          style={{ objectFit: "contain" }}
          alt="error_image"
        />
      </Col>
      <Col
        lg="12"
        sm="8"
        md="6"
        className="d-lg-none d-flex justify-content-center"
      >
        {
          type === "empty" ? (
            <Button onClick={() => removeFilter()} className="fw-500 w-75 mt-4">
              {errors[type].btnText}
            </Button>
          ) : (
            <Button href={href} className="fw-500 w-75 mt-4">
              {errors[type].btnText}
            </Button>
          )
        }
      </Col>
    </Row>
  );
};
