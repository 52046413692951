import { useReducer, useState } from "react";
import { LevelsType } from "../../interfaces/levels";
import { CountryType } from "../../interfaces/property";
import { User } from "../../interfaces/user";
import UserContext from "./UserContext";
import { UserReducer } from "./UserReducer";
import { validateChangePasswordFields, logout} from "../../data/api";
import {useOneSignal} from "../../hooks/oneSignal/useOneSignal"
import { AUTH_VALUES } from "../../utils/Constants";

const { ADMIN_LEVEL , ADMIN_ROLE } = AUTH_VALUES

interface props {
  children: JSX.Element | JSX.Element[];
}

export const INITIAL_STATE: User = {
  id: "-1",
  name: "",
  email: "",
  password: "",
  favorites: [],
  country: {} as CountryType,
  level: "",
  levelData: {} as LevelsType,
  image: "",
  crm: false
};
const INITIAL_ACCESS_TOKEN = ''
const INITIAL_REFRESH_TOKEN = ''

export const LS_USER_ID = "userID";
export const LS_USER_DATA = "userDATA_1";
export const LS_ACCESS_TOKEN = "accessToken";
export const LS_REFRESH_TOKEN = "refreshToken";

export const UserProvider = ({ children }: props) => {
  
  const {setExternalId} = useOneSignal()

  const getIntialState = () => {
    
    const userDATA = window.localStorage.getItem(LS_USER_DATA);
    if (userDATA) {
       const user = JSON.parse(userDATA);
      if (user){
        try {setExternalId(user.id)} catch (error) {}        
        return user;
      } 
    }
    

    return INITIAL_STATE;
  };
  const getInitialAccessToken = () =>{
    const accessToken = window.localStorage.getItem(LS_ACCESS_TOKEN)
    if(accessToken) return accessToken
    return INITIAL_ACCESS_TOKEN
  }
  const getInitialRefreshToken = () =>{
    const refreshToken = window.localStorage.getItem(LS_REFRESH_TOKEN)
    if(refreshToken) return refreshToken
    return INITIAL_REFRESH_TOKEN
  }

  const checkAdmin = (user : User) => {
    return user &&  user.level && user.level === ADMIN_LEVEL.toString() && user.role === ADMIN_ROLE ? true : false
  }

  const [userState, dispatch] = useReducer(UserReducer, getIntialState());
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(userState.id !== "-1");
  const [isAdmin, setIsAdmin] = useState<boolean>(checkAdmin(userState));
  const [accessToken , setAccessToken] = useState<string>(getInitialAccessToken())
  const [refreshToken , setRefreshToken] = useState<string>(getInitialRefreshToken())


  const loginUser = (user: User) => {
    dispatch({ type: "login", payload: user });
    setIsAdmin(checkAdmin(user))
    setIsLoggedIn(true);
  };

  const logoutUser = (user: User) => {
    
    dispatch({ type: "logout", payload: user });    
    setIsLoggedIn(false);      
    setIsAdmin(false)
    setAccessToken('');
    
  };

  const setUser = (user: User) => dispatch({ type: "set", payload: user });

  const setFavorites = (favorites: number[]) =>
    setUser({ ...userState, favorites });

  const saveAccessToken = (token:string) =>{
    setAccessToken(token)
    if(window) window.localStorage.setItem(LS_ACCESS_TOKEN, token)
  }
  const saveRefreshToken = (token:string) =>{
    setRefreshToken(token)
    if(window) window.localStorage.setItem(LS_REFRESH_TOKEN, token)
  }


  return (
    <UserContext.Provider
      value={{
        userState,
        isLoggedIn,
        accessToken,
        refreshToken,
        isAdmin,
        loginUser,
        logoutUser,
        setFavorites,
        saveAccessToken,
        saveRefreshToken,
        setUser
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
