import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import userFilter from "../../../hooks/filter/useFilter";
import useBreakpoint from "use-breakpoint";
import "./FilterCountry.scss";
import { BREAKPOINTS } from "../../../utils/Constants";
import { CustomImage } from "../../CustomImage/CustomImage";
import useCountry from "../../../hooks/config/useConfig";
import { getFiltersByCountry } from "../../../utils/Functions";
import { filtersByCountryInitialState } from "../../../context/Config/ConfigProvider";
import { CountryCodesType } from "../../../interfaces/config";
import { Country } from "../../../interfaces/locations";
import { useSearchParams } from "react-router-dom";

export const FilterCountry = () => {
  const [countries, setCountries] = useState([] as Country[]);
  const { setFilter,filterState, setCountryFilterID } = userFilter();
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");
  const [currentCountry, setCurrentCountry] = useState([] as Country[]);
  const { country, setFiltersByCountry, setCountryFilter , allCountries } = useCountry();
  const [searchParams ] = useSearchParams()
  const [assignInitialCountry , setAssignInitialCountry] = useState(false)

  useEffect(() => {
    //Usar solo los paises con show_dropdown true
    if(allCountries.length > 0) setCountries(allCountries.filter(c=>c.show_dropdown))
    
  }, [allCountries]);


  //Si no tiene filtro, Le asignamos el pais de la web por defecto 
  useEffect(()=> {
    if(!searchParams.get('country')){
      
      let found = countries.find(c=> c.code == country)
      if(found){
        setFilter({country : [found.id]})
        setCountryFilterID(found.id)
      }
    }
  },[country,countries])
  
  //Setear el dropdown cuando cambia el filtro y se hayan cargado todos los countries del api
  useEffect(()=> {
    
    if(countries && countries.length > 0 && !assignInitialCountry){
      let filteredCountry = filterState.country ? filterState.country[0] : null
      let found : any = countries.filter((c) => c.id == filteredCountry && c.autoload_projects ==true)

      if(found && found.length>0){
        setCurrentCountry(found)
        setAssignInitialCountry(true)
      }
    }
    
  },[filterState,countries])


  const handleChange = (id: number, code?: CountryCodesType) => {
    if (code) {
      const filtersByCountryAllowed = getFiltersByCountry(code)
      if (filtersByCountryAllowed) {
        setFiltersByCountry(filtersByCountryAllowed)
        setCountryFilter(code)
      }
    } else {
      setFiltersByCountry(filtersByCountryInitialState)
      setCountryFilter("UY")
    }
    
    setCurrentCountry(countries.filter((country) => country.id === id))
    if(id>0){
      setCountryFilterID(id)
      setFilter({ country: [id] , location : [] , neighbourhood : [], commune : [] , developers : []});
    }else{
      setFilter({ country: [] , location : [] , neighbourhood : [], commune : [] , developers : []});
    }
    
  }

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="info"
        className="bg-white m-0 p-0 border-0 filter-country float-end d-flex align-items-center"
      >
        {currentCountry.length === 0 && (
          <>
            <span className="me-2">Todos</span>
            <i className="bi bi-chevron-down text-light-secondary ms-2"></i>
          </>
        )}
        {currentCountry.length > 0 && (
          <>
            <CustomImage
              width={25}
              height={25}
              src={currentCountry[0]?.flag}
              alt={currentCountry[0]?.name}
              classes="border border-white rounded"
              styles={{ width: "auto", height: "25px" }}
            />
            <span className="text-button mx-2 fw-bold d-none d-lg-inline">
              {currentCountry[0]?.code}
            </span>
            <i className={"bi bi-chevron-down text-light-secondary " + (breakpoint === "md" || breakpoint === "sm" || breakpoint === "xs" ? "ms-2" : "")}></i>
          </>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu className="shadow">
        <Dropdown.Item
          key={"dropdown_" + -1}
          className="d-flex align-items-center py-2 px-3"
          onClick={(e) => handleChange(0)}
        >
          <CustomImage
            width={25}
            height={25}
            src="https://cdn1.infocasas.com.uy/web/627e91352943b_icono-todos.png"
            alt="Todos los países"
            classes="me-2"
            styles={{ width: "auto", height: "22px" }}
          />
          Todos
        </Dropdown.Item>
        {countries.map((country, index) => (
          <Dropdown.Item
            key={"dropdown_" + index}
            className="d-flex align-items-center py-2 px-3"
            onClick={(e) => handleChange(country.id, country.code)}
          >
            <CustomImage
              width={25}
              height={25}
              src={country.flag}
              alt={country.name}
              classes="border rounded me-2"
              styles={{ width: "auto", height: "25px" }}
            />
            {country.code}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
