
import { preheadersInfo } from '../../data/preheaders-popup/preheaders'
import useConfig from '../../hooks/config/useConfig'
import { PreheaderData } from '../../interfaces/ui'
import { CarouselPreheaders } from './CarouselPreheaders'
import { PreHeader } from './PreHeader'

export const PreheaderMain = () => {
    
    const { country } = useConfig()
    const preheadersInfoCountry = preheadersInfo.find( p => p.country == country)
    const preheadersRows = preheadersInfoCountry?.preheaderRows.filter( r => !!r.active) || []
    
    return (
        <>
        <div className="container">
            {
                preheadersRows.map( (row,index) => {
                    if(row.type == 'single'){
                        const preheaderData : PreheaderData= row.preheaders[0]
                        return <PreHeader {...preheaderData} key={index} /> 
                    }else if(row.type == 'carousel'){
                        return <CarouselPreheaders banners={row.preheaders} shuffle={row.shuffle} key={index}/>
                    }
                })
            }
        </div>
        </>
    )

}