import { useEffect, useRef, useState } from "react";
import { Alert, Button, Form, Row, Col, Modal } from "react-bootstrap";
import { useFetch } from "../../../hooks/fetch/useFetch";
import { useAdminFetch } from "../../../hooks/fetch/useAdminFetch";
import { Link, useNavigate, useParams } from "react-router-dom";
import SpecialSelect from "../../../components/ui/SpecialSelect";
import "./AppraisalForm.scss"
import useAlert from "../../../hooks/alert/useAlert";
import Lottie from "lottie-react";
import GrayLoader from "../../../lotties/gray-loader.json";
import loaderIris from "../../../lotties/orange-loader.json";
import { formatDateToDMY, formatMoneyDecimals, getDateYMD } from "../../../utils/Functions";
import { Appraisal, UpdateAppraisalBody } from "../../../interfaces/appraisal";
import { communesAYC } from "../../../data/appraisal/communesAYC";
import { AlertVariants } from "../../../context/Alert/AlertContext";


export const AppraisalForm = ({ type = 'create' }) => {

    const GARAGE_TYPE_IDENTIFIER = '8'
    const statusOptions = [
        { label: 'SOLICITADA', value: 'SOLICITADA' },
        { label: 'EN TRÁMITE', value: 'EN TRÁMITE' },
        { label: 'ENTREGADA', value: 'ENTREGADA' },
        { label: 'FALLIDA', value: 'FALLIDA' },
    ]
    const { id = "" } = useParams();
    const navigate = useNavigate()
    const formRef = useRef(null);

    const defaultAppraisal: Appraisal = {
        id: 0,
        status: 'SOLICITADA',
        user_id: 0,
        company_code: '',
        company_name: '',
        currency: '',
        price: 0,
        valid_data: false,
        external_id: 0,
        validation_request: '',
        validation_response: '',
        appraisal_document: '',
        appraisal_request: '',
        appraisal_response: '',
        user: {
            id: 0,
            name: '',
            real_estate: '',
            email: ''
        }
    }

    const { setPositionAlert, setTypeGlobalAlert, setShowGlobalAlert, setMessageGlobalAlert } = useAlert()
    const { getAdminAppraisalById, updateAppraisal } = useAdminFetch()

    const [loading, setLoading] = useState(false)

    const [validated, setValidated] = useState(false);
    const [appraisal, setAppraisal] = useState<Appraisal>(defaultAppraisal)
    const [showAlert, setShowAlert] = useState(false);
    const [typeAlert, setTypeAlert] = useState('success');
    const [messageAlert, setMessageAlert] = useState("");
    const [invalidStatus, setInvalidStatus] = useState(false);

    const [voucherFile, setVoucherFile] = useState()
    const [appraisalFile, setAppraisalFile] = useState()
    const [appraisalToEdit, setappraisalToEdit] = useState<Appraisal>()
    const [userName, setUserName] = useState('')
    const [showRequestsModal, setShowRequestsModal] = useState(false)

    const isEdit = type == 'edit' ? true : false

    const [loadingEdit, setLoadingEdit] = useState(isEdit)
    const validNumber = new RegExp(/^\d*\.?\d{0,2}$/);
    const [originalStatus, setOriginalStatus] = useState('')
    const [modalRequest, setModalRequest] = useState(false)
    const [propertyInfo, setPropertyInfo] = useState('')

    const defaultMessages = {
        status: 'Estado inválido'

    }
    const [fieldsErrorMessages, setFieldsErrorMessages] = useState(defaultMessages)

    useEffect(() => {

        if (isEdit) { getSale() }
    }, [])



    const getSale = () => {
        const appraisal_id = parseInt(id)
        getAdminAppraisalById(appraisal_id).then(async response => {

            setLoadingEdit(false)

            if (response.success && response.data) {
                const data = response.data
                const inmoName = data.user.real_estate ? data.user.name + ' - ' + data.user.real_estate : data.user.name
                setappraisalToEdit(data)
                setAppraisal(prev => {
                    return {
                        ...prev,
                        ...data
                    }
                })
                setOriginalStatus(data.status)
                if(data.validation_request) {
                    const formattedInfo = formatPropertyInfo(data)
                    setPropertyInfo(formattedInfo)
                }
                setUserName(inmoName)
            }
        }).catch(err => {
            console.error(err)
            setLoadingEdit(false)
        })
    }

    const loadSale = async () => {

        setLoadingEdit(false)
        if (appraisalToEdit) {

            let saleData = appraisalToEdit
            setappraisalToEdit(appraisalToEdit)
           
        }

    }


    const showAlertMessage = (type: AlertVariants, message: string) => {
        setPositionAlert('bottom')
        setTypeGlobalAlert(type)
        setMessageGlobalAlert(message)
        setShowGlobalAlert(true)
        setTimeout(() => {
            setShowGlobalAlert(false)
            setTypeGlobalAlert('success')
            setMessageGlobalAlert('')
            setPositionAlert('top')
        }, 3000);

    }


    const validateCustom = (form: any) => {
        let valid = true

        if (appraisal.status == 'EN TRÁMITE' && !appraisal.valid_data) {

            setFieldsErrorMessages(prev => ({ ...prev, status: 'Los datos no han sido validados por la empresa' }))
            setInvalidStatus(true)
            valid = false

        }

        if (appraisal.status == 'ENTREGADA' && !appraisal.valid_final_request) {

            setFieldsErrorMessages(prev => ({ ...prev, status: 'La tasación no ha sido procesada por la empresa' }))
            setInvalidStatus(true)
            valid = false

        }

        return valid
    }



    const finishOperation = (response: any, form: any) => {
        showAlertMessage('success', response.message ? response.message : 'Éxito')
        setAppraisal(defaultAppraisal)

        setValidated(false);


        resetForm(form)
        //form.reset()
        navigate('/admin/appraisals')
        setTimeout(() => {
            setShowAlert(false)
            setMessageAlert('')

        }, 4000);
    }

    const handleSubmit = (event: any) => {

        event.preventDefault()
        
        setShowAlert(false)
        setMessageAlert('')
        setTypeAlert('')

        let form = event.currentTarget;
        resetForm(form)
        setValidated(true);
        let validForm = form.checkValidity()
        Object.keys(defaultMessages).forEach(key => {
            try {
                form[key]?.setCustomValidity('')
            } catch (error: any) { console.log("error aqui : " + key + " " + error.toString()) }

        })
        setFieldsErrorMessages(defaultMessages);

        let validCustom = validateCustom(form)


        if (validForm && validCustom) {

            if (originalStatus == 'SOLICITADA' && appraisal.status == 'EN TRÁMITE' && !appraisal.valid_final_request) {
                setModalRequest(true)
            } else {
                submitForm()
            }

        } else {
            setLoading(false)
        }
        

        return
    }

    const submitForm = () => {

        
        if (isEdit) {
            setLoading(true)

            let editData: UpdateAppraisalBody = {
                ...appraisal,
                id: parseInt(id),
                appraisal_f: appraisalFile,
                voucher_f: voucherFile
            }

            updateAppraisal(editData).then(res => {
                if (res.success) {
                    finishOperation(res, formRef)
                } else {
                    let msg = res.message ? res.message : 'Ocurrió un error'
                    showAlertMessage('danger', msg)
                }
                setLoading(false)

            }).catch(err => {
                setLoading(false)
                console.error(err)
            })
        }
    }

    const resetForm = (form: any) => {
        Object.keys(defaultMessages).forEach(key => {
            try {
                form[key]?.setCustomValidity('')
            } catch (error: any) {
                console.log(error.toString())
            }

        })
        setFieldsErrorMessages(defaultMessages);
    }

    const selectVoucherFile = (ev: any) => {
        if (ev.target?.files && ev.target?.files[0]) {
            setVoucherFile(ev.target.files[0])
        } else {
            setVoucherFile(undefined)
        }
    }

    const selectAppraisalFile = (ev: any) => {
        if (ev.target?.files && ev.target?.files[0]) {
            setAppraisalFile(ev.target.files[0])

        } else {
            setAppraisalFile(undefined)
        }
    }

    const formatPropertyInfo = (data : Appraisal) => {
        if(data.validation_request){
            const info = JSON.parse(data.validation_request) || {}

            if(data?.company_code == 'VTP'){       
                if(info?.propiedad){
                    return `Comuna : ${info.propiedad.comuna} \nDirección : ${info.propiedad.calle} ${info.propiedad.numero}  `
                }
            }else if(data.company_code == 'AYC'){
                const comuna_name = communesAYC.find(c => c.code == info.comuna_sii)?.name
                return `Comuna : ${comuna_name} \nRol : ${info.manzana} - ${info.predial}  `
            }
        }
        
        return ''
    }

    return (
        <div className="sale-form">


            <Form noValidate validated={validated}
                ref={formRef}
                onSubmit={handleSubmit} className="login-form w-100">

                <Row className="d-flex flex-row">

                    <Col lg='6' sm='12' className="columna header-form d-flex align-items-center" >
                        <Link to="/admin/appraisals" className="fs-2 go-back mt-3  ms-3 ms-md-4" title="Volver">
                            <i className="text-light bi bi-arrow-left" ></i>
                        </Link>
                        <h3 className="mx-2">{isEdit ? 'Editar Tasación ' : 'Nueva Tasación'}</h3>
                        <small></small>

                    </Col>
                    <Col lg='6' sm='12'>
                        <div className="d-flex w-100 h-100 align-items-center justify-content-end">
                            <button type="button" className=" btn btn-outline-primary"
                                onClick={() => setShowRequestsModal(true)}
                            >Ver detalle de solicitudes</button>
                        </div>
                    </Col>

                    {
                        isEdit && loadingEdit &&
                        <div style={{ height: 400 }} className="w-100 d-flex align-items-center justify-content-center"><Lottie animationData={loaderIris} style={{ height: 100 }} loop /></div>
                    }

                    {
                        (!isEdit || (isEdit && !loadingEdit && appraisalToEdit?.id)) &&
                        <>
                            <Col lg='12' sm='12' className=" ">
                                <br />
                            </Col>

                            <Col lg='4' sm='12' className="columna col-left mt-3">
                                <Form.Group className="w-100 mb-3" controlId="garage_currency">
                                    <Form.Label>Inmobiliaria / Broker </Form.Label>
                                    <Form.Control
                                        value={userName}
                                        disabled
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg='4' sm='12' className="columna col-left mt-3">
                                <Form.Group className="w-100 mb-3" controlId="garage_currency">
                                    <Form.Label>Email </Form.Label>
                                    <Form.Control
                                        value={appraisal.user.email}
                                        disabled
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg='4' sm='12' className="columna col-left mt-3">
                                <Form.Group className="w-100 mb-3" controlId="garage_currency">
                                    <Form.Label>Empresa </Form.Label>
                                    <Form.Control
                                        value={appraisal.company_name}
                                        disabled
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg='2' sm='12' className="columna col-left mt-3">

                                <Form.Group className="w-100 mb-3" controlId="garage_currency">
                                    <Form.Label>ID Externo </Form.Label>
                                    <Form.Control
                                        value={appraisal.external_id}
                                        disabled
                                    />
                                </Form.Group>
                            </Col>

                            <Col lg='2' sm='12' className="columna col-left mt-3">
                                <Form.Group className="w-100 mb-3" controlId="garage_currency">
                                    <Form.Label>Precio </Form.Label>
                                    <Form.Control
                                        value={`${appraisal.currency} ${formatMoneyDecimals(appraisal.price)}`}
                                        disabled
                                    />
                                </Form.Group>
                            </Col>

                            {
                                appraisal.created_at &&
                                <Col lg='4' sm='12' className="columna col-left mt-3">
                                    <Form.Group className="w-100 mb-3" controlId="sale_date">
                                        <Form.Label>Fecha de Solicitud </Form.Label>
                                        <Form.Control
                                            as={'input'}
                                            type="date"
                                            value={`${getDateYMD(new Date(appraisal.created_at), '-')}`}
                                            disabled
                                        />

                                    </Form.Group>

                                </Col>
                            }

                            {
                                propertyInfo && 
                                <>
                                <Col lg='4' sm='12' className="columna col-left mt-3">
                                    <Form.Group className="w-100 mb-3" controlId="sale_date">
                                        <Form.Label>Información de Propiedad</Form.Label>
                                        <Form.Control
                                            as={'textarea'}
                                            value={propertyInfo}
                                            disabled
                                        />

                                    </Form.Group>

                                </Col>
                                </>
                            }


                            <Col lg='4' sm='12' className="columna col-left mt-3">
                                <Form.Group className="w-100 mb-3" controlId="status">
                                    <Form.Label>Estado </Form.Label>
                                    <SpecialSelect
                                        options={statusOptions}
                                        onChange={(item: any) => setAppraisal(prev => { return { ...prev, status: item.value } })}
                                        selected={{ value: appraisal.status, label: appraisal.status || '' }}
                                        placeholder="Elige..."
                                        noOptionsMessage={'No se encontraron estados'}
                                        setInvalid={invalidStatus}
                                    />

                                    {invalidStatus &&
                                        <div className="invalid-feedback d-block">{fieldsErrorMessages.status}</div>
                                    }

                                </Form.Group>

                            </Col>



                            <Col lg='12'>
                                <hr className="my-3" style={{ color: "#a1a1a1" }} />
                            </Col>

                            <Col lg='6' className="mt-4">
                                <h2 className="mb-3"><strong>Comprobante de Pago:</strong></h2>
                                {
                                    appraisal.voucher_document &&
                                    <>
                                        <a href={appraisal.voucher_document} target="_blank">
                                            <button type="button" className="btn btn-outline-primary mb-2 me-2">Ver Comprobante de Pago</button>
                                        </a>

                                    </>

                                }
                                {
                                    !appraisal.voucher_document &&
                                    <p>Cargar comprobante:</p>
                                }
                                {
                                    appraisal.voucher_document &&
                                    <p>Actualizar comprobante:</p>
                                }
                                <Form.Group controlId="formFile"  >

                                    <Form.Control type="file" className="mt-2 tw-w-full shadow-none " accept=".jpg,.jpeg,.png,.pdf"
                                        onChange={selectVoucherFile}
                                    />
                                </Form.Group>
                                {
                                    isEdit && appraisal.voucher_document &&
                                    <Form.Group className="mt-1 mb-3" controlId="delete_voucher_file">
                                        <Form.Check  >
                                            <Form.Check.Input type='checkbox' checked={appraisal.delete_voucher_file}
                                                onChange={(e) => { setAppraisal((prev: any) => { return { ...prev, delete_voucher_file: e.target.checked } }) }} />
                                            <Form.Check.Label>Eliminar comprobante </Form.Check.Label>
                                        </Form.Check>
                                    </Form.Group>
                                }
                            </Col>

                            <Col lg='6' className="mt-4">

                                <h2 className="mb-3"><strong>Informe de tasación:</strong></h2>
                                {
                                    appraisal.appraisal_document &&
                                    <>
                                        <a href={appraisal.appraisal_document} target="_blank">
                                            <button type="button" className="btn btn-outline-primary mb-2 me-2">Ver Informe Tasación</button>
                                        </a>
                                    </>

                                }

                                {
                                    !appraisal.appraisal_document &&
                                    <p>Cargar Tasación:</p>
                                }
                                {
                                    appraisal.appraisal_document &&
                                    <p>Actualizar informe de tasación:</p>
                                }
                                <Form.Group controlId="formBillFile"  >

                                    <Form.Control type="file" className="mt-2 tw-w-full shadow-none " accept=".jpg,.jpeg,.png,.pdf"
                                        onChange={selectAppraisalFile}
                                    />
                                </Form.Group>
                                {
                                    isEdit && appraisal.appraisal_document &&
                                    <Form.Group className="mt-1 mb-3" controlId="delete_bill_file">
                                        <Form.Check  >
                                            <Form.Check.Input type='checkbox' checked={appraisal.delete_appraisal_file}
                                                onChange={(e) => { setAppraisal((prev: any) => { return { ...prev, delete_appraisal_file: e.target.checked } }) }} />
                                            <Form.Check.Label>Eliminar informe </Form.Check.Label>
                                        </Form.Check>
                                    </Form.Group>
                                }

                            </Col>



                            <Col lg='12' sm='12' className="columna text-center mt-3">
                                <div style={{ minHeight: 50 }}>
                                    <Alert variant={typeAlert} show={showAlert} onClose={() => setShowAlert(false)} dismissible>
                                        {messageAlert}
                                    </Alert>
                                </div>
                                <div className="d-flex justify-content-center w-100">
                                    <Button
                                        className="mb-2 px-5 create-button mx-1 position-relative"
                                        variant="primary"
                                        type="submit"
                                        disabled={loading}>
                                        GUARDAR
                                        {loading && <Lottie className="position-absolute loader-btn top-1" animationData={GrayLoader} style={{ height: 30, right: 5, top: 2 }} loop />}
                                    </Button>
                                </div>

                            </Col>
                        </>
                    }

                    {
                        (isEdit && !appraisalToEdit?.user_id && !loadingEdit) &&
                        <h4>No se encontró esta venta</h4>
                    }

                </Row>

            </Form>

            <Modal show={showRequestsModal} onHide={() => setShowRequestsModal(false)} >
                <Modal.Header closeButton>
                    <Modal.Title
                        className="d-flex flex-column w-100 text-center"
                        style={{ marginRight: -24 }}
                    >
                        <span className="text-primary">Detalle de solicitudes</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-1 px-3 pb-3 px-lg-4 pb-lg-4">
                    <div>
                        <Row>
                            <Col lg='12' className="mt-2">
                                <Form.Group className="w-100 mb-3" controlId="validation_request">
                                    <Form.Label>Validación - Datos enviados </Form.Label>
                                    <Form.Control
                                        as={'textarea'}
                                        rows={2}
                                        value={appraisal.validation_request}
                                        disabled
                                    />
                                </Form.Group>
                            </Col>

                            <Col lg='12' className="mt-2">
                                <Form.Group className="w-100 mb-3" controlId="validation_request">
                                    <Form.Label>Validación - Respuesta recibida </Form.Label>
                                    <Form.Control
                                        as={'textarea'}
                                        rows={2}
                                        value={appraisal.validation_response}
                                        disabled
                                    />
                                </Form.Group>
                            </Col>


                            <Col lg='12' className="mt-2">
                                <Form.Group className="w-100 mb-3" controlId="validation_request">
                                    <Form.Label>Solicitud de Tasación - Datos enviados </Form.Label>
                                    <Form.Control
                                        as={'textarea'}
                                        rows={2}
                                        value={appraisal.appraisal_request}
                                        disabled
                                    />
                                </Form.Group>
                            </Col>

                            <Col lg='12' className="mt-2">
                                <Form.Group className="w-100 mb-3" controlId="validation_request">
                                    <Form.Label>Solicitud de Tasación - Respuesta recibida </Form.Label>
                                    <Form.Control
                                        as={'textarea'}
                                        rows={2}
                                        value={appraisal.appraisal_response}
                                        disabled
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={modalRequest}
                onHide={() => setModalRequest(false)}
                centered
                contentClassName="bg-md-light border-0 "

            >

                <Modal.Header closeButton className="border-bottom-0 pb-0 mb-4">
                    <Modal.Title
                        className="d-flex flex-column w-100 text-center"
                        style={{ marginRight: -24 }}
                    >
                        <span className="text-primary">¿Está seguro de tramitar la tasación?</span>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-1 px-3 pb-3 px-lg-4 pb-lg-4 text-center">
                    <p>Se <strong>solicitará</strong> tasación a la empresa: "{appraisal.company_name}" </p>
                    <button type="submit" className="btn btn-primary btn-upload-bill-file position-relative mt-4 px-4 "
                        disabled={loading}
                        onClick={submitForm}>
                        TRAMITAR
                        {loading && <Lottie className="position-absolute loader-btn top-1" animationData={GrayLoader} style={{ height: 28, right: 0, top: 4 }} loop />}
                    </button>
                </Modal.Body>

            </Modal>
        </div >

    )
}
