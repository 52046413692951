import { Col, Row } from "react-bootstrap";
import { OperationType, PropertyType } from "../../interfaces/property";
import useCountry from "../../hooks/config/useConfig";
import { IconCheckCircleFill } from "../CustomIcons/IconCheckCircleFill";
import { ProjectType } from "../../interfaces/project";
import { IconXCircleFill } from "../CustomIcons/IconXCircleFill";
import { Course } from "../../interfaces/academy";

export const CourseCaracteristics = ({
  course,
  isInPdf = false
}: {
  course: Course;
  isInPdf?: boolean;
}) => {
  const { translation } = useCountry();

  const caracteristics = [
    { title: "Modalidad", value: course.modality },
    { title: "Duración", value: course.duration },
    { title: "Certificación", value: course.certification },
  ];

  return (
    <Row className={"property-caracteristics " + (isInPdf ? "gy-1" : "gy-3")} style={{ marginLeft: isInPdf ? "5px" : "" }}>
      {caracteristics.map((c, index) => (
        <Col
          xs={12}
          key={"caracteristics_" + index}
          className="d-flex align-items-baseline justify-content-between"
          style={{ paddingLeft: "31px" }}
        >
          <span className="title fw-light">{c.title}</span>
          <div className="border-bottom flex-grow-1 mx-2"></div>
          <span className="value fw-light">{c.value}</span>
        </Col>
      ))}
      
    </Row>
  );
};
