import { useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { Estimator } from "./Estimator"
import { IconFileText } from "../CustomIcons/IconFileText"
import { IconMoneyEstimation } from "../CustomIcons/IconMoneyEstimation"
import { ProjectType , UnitType} from "../../interfaces/project"

export const EstimatorButton = (
    {
        project,
        unit ,
        pie 
    } : {
        project : ProjectType,
        unit  : UnitType,
        pie : Number
    }
) => {

    const [showModal , setShowModal] = useState(false)

    return (
        <>
        <Button 
        className="property-share fw-normal d-flex align-items-center 
        text-secondary lh-1 p-0 position-relative"
        variant="outline-ligh" 
        onClick={() => setShowModal(true)}>
            <IconMoneyEstimation className='text-primary' width={24} />
            <span className="d-none d-lg-inline ms-2">
                Cotizar
            </span>
            
        </Button>
        <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            centered
            size="lg"
            contentClassName="bg-md-light border-0"
        >
            <Modal.Header closeButton className="border-bottom-0 pb-0">
            </Modal.Header>
            <Modal.Body>
                <Estimator project={project} />
            </Modal.Body>
        </Modal>
        </>
        
    )
}