export const BREAKPOINTS = {
  xs: 0,
  sm: 574,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1500,
};

export const AUTH_VALUES = {
  ADMIN_LEVEL : 11,
  TOP_GAMIFICATION_LEVEL : 10,
  ADMIN_ROLE : 'admin'
}

export const PROPERTIES_VALUES = {
  LAND_TYPE : '3',
  APARTMENT_TYPE : '2',
  HOUSE_TYPE : '1',
  LOCAL_TYPE : '4',
  OFFICE_TYPE : '5',
  INDUSTRIAL_LOCAL_TYPE : '12',
  GARAGE : '8',
  RANCH : '6',
  SPECIAL_BUSINESS : '9',
  APARTAESTUDIO : '16',
  STATUS : {
    USED : '10'
  }
}