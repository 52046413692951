import { Placeholder, Card } from "react-bootstrap"

export const SkeletonFeedDetail = () => {
    return (
        <Card className="post-card border-0 rounded bg-white mb-3 shadow-sm p-3">
            <div className="post-ppl-img mb-3"></div>
            <Placeholder as="div" animation="glow">
                <Placeholder xs={8} size="lg" />
                <Placeholder xs={5} size="sm" />
            </Placeholder>
            <Placeholder as="div" animation="glow" className="mt-3">
                <Placeholder xs={12} />
                <Placeholder xs={12} />
            </Placeholder>
            <Placeholder as="div" animation="glow" className="mt-3">
                <Placeholder xs={4} size="sm" />
            </Placeholder>
            <Placeholder as="div" animation="glow" className="mt-1">
                <Placeholder xs={3} size="sm" />
            </Placeholder>
        </Card>
    )
}