import { useEffect, useState } from "react"
import { useAdminUsersFetch } from "../../../hooks/fetch/admin/users/useAdminUsersFetch"
import { useSearchParams } from "react-router-dom";
import loaderIris from "../../../lotties/orange-loader.json";
import Lottie from "lottie-react";
import { IconCheckCircleFill } from "../../../components/CustomIcons/IconCheckCircleFill";


export const ActivateUser = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const { activateUserService } = useAdminUsersFetch()
    const [ loading , setLoading ] = useState(true)
    const [ message , setMessage ] = useState('')
    const [ successful , setSuccessful ] = useState(false)

    useEffect(()=> {
        const code = searchParams.get("code") || ''
        const id : number = parseInt(searchParams.get("id")||'0') 
        activateUserService( { code , id })
        .then((data) => {
            setLoading(false)
            if(data.success && data.data){
                setSuccessful(true)
                setMessage(`El usuario ${data.data} se activó correctamente.`)
            }else{
                setMessage(data.message)
            }
        })
        .catch(err => {
            setLoading(false)
        })
    }, [])
    
    return <>
        <main className="p-5 text-center ">
            <div className="centered-section p-4 " style={{maxWidth : 500 , minHeight : 250}}>
                <h2 className="mb-4">Activar usuario</h2>
                {
                    loading &&
                    <div  className="w-100 d-flex align-items-center justify-content-center"><Lottie animationData={loaderIris} style={{ height: 100 }} loop /></div>
                }

                {
                    successful && 
                    <div className="mb-3" style={{color : 'green'}}>
                        <IconCheckCircleFill  width='80' />
                    </div>
                }

                {
                    !loading && 
                    <p>{message}</p>
                }
            </div>
            
        </main>
    </>
}