import { useMemo } from "react";
import { UnitType } from "../../interfaces/project";
import { IconCrop } from "../CustomIcons/IconCrop";

export const PropertyMeters = ({ units = [] }: { units?: UnitType[] }) => {
  const text = useMemo(() => {
    units.sort((a, b) => a.m2 - b.m2);
    let res = "";
    if (units.length > 0) {
      res += units[0].m2;
      if (units[0].m2 < units[units.length - 1].m2) {
        res += " a ";
        res += units[units.length - 1].m2;
      }
      res += " m²";
    }
    return res;
  }, [units]);

  if (text.length === 0) return null;

  return (
    <div className="property-meters d-flex align-items-center flex-lg-row">
      <IconCrop width={14} height={14} className="text-black-50" />
      <span className="me-lg-0 mx-2">{text}</span>
    </div>
  );
};
