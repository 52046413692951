import { Col, Row } from "react-bootstrap";
import { Link, Navigate , useNavigate} from "react-router-dom";
import { LoginCarousel } from "../fragments/login/LoginCarousel";
import { LoginForm } from "../fragments/login/LoginForm";
import useUser from "../hooks/user/useUser";
import useBreakpoint from "use-breakpoint";
import { BREAKPOINTS } from "../utils/Constants";
import "./../styles/login.scss";
import { useGoogleAnalytics } from "../hooks/googleAnalytics/useGoogleAnalytics";
import { CustomImage } from "../components/CustomImage/CustomImage";
import { useState } from "react";
import { RegisterForm } from "../fragments/login/RegisterForm";
import useConfig from "../hooks/config/useConfig";

const Login = () => {
  const { isLoggedIn, userState } = useUser();
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");
  const GA = useGoogleAnalytics(true);
  const navigate = useNavigate();
  const {country} = useConfig()

  const logoImg = "https://cdn1.infocasas.com.uy/web/61eee8ead10a6_infocdn__brand_logo_400x200.png"


  if (isLoggedIn) {
    /* GA.Event({ category: "User login", action: "user_login", label: `${userState.name}`, value: Number(userState.id) }); */
    return <Navigate to={"/feed"} />
  }

  const goBack = ()=>{
    navigate('/');
  }

  return (
    <Row className="login w-100 p-0 m-0 g-0 d-flex flew-nowrap align-items-stretch">
      <Col
        className={"col-lg-7 col-md-6 login-left p-3 p-md-4 p-lg-5 d-flex align-items-center justify-content-center position-relative " + (breakpoint === "xs" ? "px-4" : "") 
      }
      >
        <Link to="/" onClick={(e)=>{
          e.preventDefault();
          goBack()
        }} className="fs-2 login-back mt-3 ms-3 ms-md-4">
          <i className="text-light bi bi-arrow-left"></i>
        </Link>
        <div className="login-container w-100">
          <CustomImage
            src={logoImg}
            alt="Iris"
            width={20}
            height={20}
            classes="mb-5"
            styles={{ height: "50px" }}
          />
          <LoginForm changeType={()=>navigate('/registro')} />
        </div>
      </Col>
      <Col lg="5" md="6" className="d-none d-md-block login-right">
        <LoginCarousel />
      </Col>
      
    </Row>
  );
};

export default Login;
