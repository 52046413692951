import { ProjectType, UnitType } from "../../../interfaces/project"
import { getDateYMD } from "../../../utils/Functions"
import useUser from '../../../hooks/user/useUser'

export const FeaturesTop = ({ project, unit, inmo_logo_encoded }: 
    { project: ProjectType, unit: UnitType, inmo_logo_encoded : string }) => {
    
    const {userState} = useUser()

    return (
        <>
            <div className="img-inmobiliaria d-flex justify-content-end" >
                
            </div>
            <div className="d-flex position-relative" style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                <h1 className="fw-bold fs-5 mt-3">COTIZACIÓN</h1>
                {inmo_logo_encoded && inmo_logo_encoded != '' && 
                    <img src={inmo_logo_encoded} alt="" width={80} height={80}
                        style={{ objectFit: 'contain', position : 'absolute' ,  top : 0 ,right : 0 }}
                    />}
            </div>

            <div className="mt-4">
                <table>
                    <tbody>
                        <tr>
                            <td>Proyecto</td>
                            <td>: {project.title}</td>
                        </tr>
                        
                        <tr>
                            <td>Dirección</td>
                            <td>: {project.address}</td>
                        </tr>
                        <tr>
                            <td>Comuna</td>
                            <td>: {project.zone?.name}</td>
                        </tr>
                        <tr>
                            <td>Unidad</td>
                            <td>: {unit.number}</td>
                        </tr>
                        <tr>
                            <td>Fecha</td>
                            <td>: {getDateYMD(new Date(), '-')}</td>
                        </tr>
                        <tr>
                            <td>Agente Broker</td>
                            <td>: {userState.real_estate}</td>
                        </tr>
                        <tr>
                            <td>Teléfono</td>
                            <td>: {userState.phone}</td>
                        </tr>
                        
                    </tbody>
                </table>
            </div>
        </>

    )
}