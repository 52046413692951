import { VirtualSignPlan } from "../../../interfaces/services";

export const VirtualSignPlans : VirtualSignPlan[] = [
    {
        name : 'Plan Básico',
        irisPrice : 9840,
        currency : '$',
        features : [
            'Una operación',
            'Firmas electrónicas',
            'Legalización del documento',
            'Certificación notarial',
            'Panel de administración',
            'Asistencia permanente'
        ],
    },
    {
        name : 'Más vendido',
        irisPrice : 46400,
        currency : '$',
        features : [
            '5 operaciones',
            'Firmas electrónicas',
            'Legalización del documento',
            'Certificación notarial',
            'Panel de administración',
            'Asistencia permanente'
        ],
        highlight : true
    },
    {
        name : 'Plan Pro',
        price : 0,
        irisPrice : 89400,
        currency : '$',
        features : [
            '10 operaciones',
            'Firmas electrónicas',
            'Legalización del documento',
            'Certificación notarial',
            'Panel de administración',
            'Asistencia permanente'
        ]
    },
    {
        name : 'Plan a tu medida',
        irisPrice : 98340,
        currency : '$',
        features : [
            'Firmas electrónicas',
            'Legalización del documento',
            'Certificación notarial',
            'Panel de administración',
            'Asistencia permanente'
        ],
        custom : true,
        qtyOperations : 11
    }
]