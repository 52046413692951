
import { useEffect, useMemo, useState } from 'react'
import useConfig from '../hooks/config/useConfig'
import {TermsConditionsPA, TermsConditionsCL, TermsConditionsUY} from '../fragments/terms'

const TermsConditions = () => {

    const {country} = useConfig()

    const termsByCountry = useMemo(() => {
        if(country == 'CL') return <TermsConditionsCL />
        else if(country == 'PA') return <TermsConditionsPA />
        else return <TermsConditionsUY />
    },[country])

    if(country){
        return <>
        { termsByCountry}
        </>
    }

    return <div style={{minHeight:'600px'}}></div>
}

export default TermsConditions