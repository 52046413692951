import { createContext } from "react";
import { Promo } from "../../interfaces/promo";

export type PromoContextProps = {
  countryPromo? : Promo
};

export const PromoContext = createContext<PromoContextProps>({} as PromoContextProps);

// export  PromoContext;
