import { useEffect, useState } from "react"
import { useFetch } from "../fetch/useFetch"
import { CurrencyType } from "../../interfaces/auxiliar"

export const useCurrency = () => {

    const [currencies , setCurrencies] = useState<CurrencyType[]>([])
    const { getCurrencies } = useFetch()

    useEffect(() => {
        getCurrencies().then( res => {
            if(res.success && res.data){
                setCurrencies(res.data)
            }
        })
    },[])

    const getCurrencyByName = (name : string) => {
        return currencies.find( c =>  c.name == name)
    }

    const convertCurrency = (currencyNameFrom : string, currencyNameTo : string, value : number) => {
        let response = {
            success : false,
            value : 0,
            message : '',
            exchange_rate_to_from : 0
        }

        let currencyFrom = getCurrencyByName(currencyNameFrom)

        let currencyTo = getCurrencyByName(currencyNameTo)

        if(currencyFrom && currencyTo){
            try {
                response.value = (currencyTo.exchange_rate / currencyFrom.exchange_rate) * value
                response.exchange_rate_to_from = currencyFrom.exchange_rate / currencyTo.exchange_rate
                response.success = true
            } catch (error : any) {
                response.message = error.toString()
            } finally{
                return response
            }
            

        }else{
            response.message = 'No se encontraron ambas monedas'
            return response
        }

    }

    return {
        convertCurrency,
        currencies
    }
}