import { useState, useEffect } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { Col } from "react-bootstrap";
import useUser from "../hooks/user/useUser";
import { PostType } from "../interfaces/post"
import { hasSomeParentTheClass } from "../utils/Functions";
import { FeedPostGallery } from "../components/FeedPostCard/FeedPostGallery/FeedPostGallery";
import { FeedModal } from "../components/FeedPostCard/FeedModal/FeedModal";
import { GalleryCounter } from "../components/GalleryCounter/GalleryCounter";
import { FeedPostDescription } from "../components/FeedPostCard/FeedPostDescription/FeedPostDescription";
import { SkeletonFeedDetail } from "../components/Skeleton/SkeletonFeedDetail";
import { FeedGoBackArrow } from "../components/FeedPostCard/FeedGoBackArrow/FeedGoBackArrow";
// import { FeedPostFooter } from "../components/FeedPostCard/FeedPostFooter/FeedPostFooter";
import useCountry from "../hooks/config/useConfig";
import { validateYoutubeVideo } from "../utils/Functions";
import {useFetch} from "../hooks/fetch/useFetch"
import dayjs from "dayjs";

const FeedDetail = () => {
  const [expand, setExpand] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [post, setPost] = useState<PostType>();
  const [isLoading, setIsLoading] = useState(true);
  const [orderedImages, setOrderedImages] = useState([]);
  const [hasPdf, setHasPdf] = useState(false);
  const { isLoggedIn } = useUser();
  const { id = "" } = useParams();
  const { pathname } = useLocation();
  const { country } = useCountry();
  const {getPostDetail} = useFetch()

  useEffect(() => {
    if(id && id!=''){
      getPostDetail(id).then((post) => {
        if(post && post.success){
          setPost(post.data);
        }
        
        setIsLoading(false);
      })
    }
    
  
  }, [id])

  useEffect(() => {
    if(post && post !== null) {
      const youtubeUrls : any = [];

      const filteresImages = post.details?.images.filter((image: any) => {
        if (validateYoutubeVideo(image)) {
          youtubeUrls.push(image)

          return null
        } else return image
      })

      setOrderedImages(youtubeUrls.concat(filteresImages))

      if (post.details.pdf && typeof post.details.pdf !== "undefined" && post.details.pdf !== "") {
        setHasPdf(true)
      }
    }
  }, [post])
  

  if (!isLoggedIn)
    return <Navigate to="/iniciar-sesion" state={{ from: pathname }} />;
  if (post == null && isLoading) {
    return <SkeletonFeedDetail />
  } else if (post == null && !isLoading) {
    return <Navigate to="/404" state={{ from: pathname }} />;
  }

  const handleSelect = (eventKey: number) => {
    setActiveIndex(eventKey)
  }

  const formatDate = (date: string) => {
    
    try {
      const finalDate = dayjs(date, "YYYY-MM-DD");
      if (finalDate.isValid()) { return finalDate.format("DD/MM/YYYY")  }
      return date
    } catch (error) {
      console.log(error)
      return date
    }
    
  }
  return (
    <>
    {post &&
    <Col lg={{ order: 1 }} xs={{ order: 1 }}>
      <FeedGoBackArrow text={post.type === "Evento" ? "Eventos" : "Noticias"} />
      <div className="bg-white rounded p-4 shadow-sm">
        <div className="feed-detail-gallery">
          <div className="w-100 h-100 position-relative border rounded-3">
            <div className="gallery" onClick={
              (e) => {
                if (
                  !hasSomeParentTheClass(e.target, "carousel-control-prev") &&
                  !hasSomeParentTheClass(e.target, "carousel-control-next")
                ) {
                  setExpand(true);
                }
              }
            }>
              {hasPdf ?
                <div className="container-pdf">
                  <a href={post.details.pdf} target="_blank" rel="noreferrer" className="link-pdf btn btn-primary">Ver PDF</a>
                  <embed width="100%" height="400" src={post.details.pdf} type="application/pdf" />
                </div> :
                <>
                  <FeedPostGallery width="w-100" activeIndex={activeIndex} onSelect={handleSelect} images={orderedImages} />
                  <GalleryCounter activeIndex={activeIndex} imagesLength={orderedImages.length} />
                </>
              }
            </div>
          </div>

          <FeedModal
            className="modal-gallery"
            show={expand}
            onClose={() => setExpand(false)}
            title={post.details.title}
          >
            <FeedPostGallery width="w-75" activeIndex={activeIndex} onSelect={handleSelect} images={orderedImages} />
            <div className="counter-container d-flex align-items-center justify-content-center">
              <GalleryCounter activeIndex={activeIndex} imagesLength={orderedImages.length} />
            </div>
          </FeedModal>
        </div>

        <div className="mt-3">
          <h3 className="text-secondary fw-bold">{post?.details.title}</h3>
          
          <span className="text-light-secondary">{
            (post?.details.publish_date ? formatDate(post.details.publish_date) : '')
          } · Por</span> <span className="text-primary">{post.publish_by.name}</span>

          <FeedPostDescription post={post} collapse={false} />

          <span className="text-light-secondary">Publicado por </span> <span className="text-primary">{post.publish_by.name}</span>

          {/* <div className="border-top mt-4 pb-1"></div>
          <FeedPostFooter likes={post?.likes} postType={post?.postType} /> */}
        </div>
      </div>
    </Col >
    }
    </>
    
  );
};

export default FeedDetail;
