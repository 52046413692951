import { FeedBenefitsForm } from "./FeedBenefitsForm"
import { FeedBenefitsModal } from "./FeedBenefitsModal"
import useConfig from "../../hooks/config/useConfig"
import { WarrantyFeatures } from "./Descriptions/WarrantyFeatures"

export const WarrantyModal = ({ showWarranty, setShowWarranty }: { showWarranty: boolean, setShowWarranty: (x: boolean) => void }) => {

    const { translation, countryFeatures} = useConfig()


    return (
        <FeedBenefitsModal show={showWarranty} setShow={setShowWarranty} type={'warranty'}>
            <div className="row gx-5">
                <div className="col px-5">
                    <p className="mb-3" dangerouslySetInnerHTML={{ __html: translation.warranty_description }}></p>
                    <p>La inmobiliaria se encarga únicamente del contrato y del inventario del inmueble</p>
                    <ul className="ps-3 d-grid gap-3">
                        <WarrantyFeatures />
                    </ul>
                    <div className="modal-warranty-contact d-flex justify-content-start align-items-center mt-4 mb-5">
                        <p className="pb-0 mb-0 fw-bold me-3">¿Alguna duda?</p>
                        <a href={`https://api.whatsapp.com/send?phone=${countryFeatures.warranty_help_wpp}&text=${translation.warranty_ask_wpp}`}
                                target="_blank"><button className="text-green border-color-green bg-white rounded p-2 fw-bold" >Contacta un asesor</button></a>
                    </div>

                </div>
                <div className="col px-5">
                    <FeedBenefitsForm show={showWarranty} type={'warranty'} onSend={() => setShowWarranty(false)} />
                </div>
            </div>
        </FeedBenefitsModal>
    )
}