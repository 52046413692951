import { useState, useEffect } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap"
import { resetPassword } from "../../data/api";
import { FunctionBody } from "typescript";

export const LoginModalResetPass = ({ email, modal, setModal, setMessageAlert, setShowAlert , setTypeAlert}: { email: string, modal: boolean, setModal: Function, setMessageAlert: Function, setShowAlert: Function, setTypeAlert:Function }) => {
    const defaultEmailMessage = "Escriba un email válido"
    const [validatedModalForm, setValidatedModalForm] = useState(false);
    const [emailMessage, setEmailMessage] = useState(defaultEmailMessage);
    const [loadind, setLoading] = useState(false);
    const [credentialsModalForm, setCredentialsModalForm] = useState({
        email: "",
    });

    useEffect(() => {
        email !== "" && setCredentialsModalForm({ email })
    }, [email])


    const validateModalForm = () => {
        return credentialsModalForm.email.length > 0;
    };

    const handleForgotPassword = (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;
        form.email.setCustomValidity('');
        setEmailMessage(defaultEmailMessage)
        setValidatedModalForm(true);
        setLoading(true);
        
        if (form.checkValidity() !== false) {
            resetPassword(form.email.value).then((response) => {
                setLoading(false);
                
                if (response?.success !== true) {
                    setEmailMessage(response?.message ?? "");
                    form.email.setCustomValidity('invalid')
                } else {
                    setModal(false);
                    setMessageAlert(response?.message ?? "");
                    setShowAlert(true);
                    setTypeAlert('success')
                }
            });
        }else{
            setLoading(false);
        }
    }

    return (
        <Modal
            show={modal}
            onHide={() => setModal(false)}
            centered
            contentClassName="bg-xs-light border-0"
        >
            <Modal.Header closeButton className="border-bottom-0 pb-0">
                <Modal.Title
                    className="d-flex flex-column w-100 text-center h5"
                >
                    Dejanos tu email y te enviaremos los pasos a seguir para recuperar tu cuenta
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-1 px-3 pb-3 px-lg-4 pb-lg-4">
                <Form noValidate validated={validatedModalForm} onSubmit={handleForgotPassword} className="login-form w-100">
                    <Form.Group className="w-100 mb-3" controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            className="email-input w-100"
                            placeholder="correo@ejemplo.com"
                            value={credentialsModalForm.email}
                            onChange={(e) =>
                                setCredentialsModalForm({ email: e.target.value })
                            }
                            autoFocus
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            {emailMessage}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Button
                        className="mb-3 w-100 login-button"
                        variant="primary"
                        type="submit"
                        disabled={!validateModalForm()}
                    >
                        {loadind ? (
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="me-2"
                            />
                        ) : null}
                        Cambiar contraseña
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}