import { useEffect, useState } from "react";
import CountryContext from "./ConfigContext";
import { ConfigType, CountryCodesType, CountryFeatures, FiltersByCountry} from "../../interfaces/config"
import { useTranslation } from "../../hooks/translation/useTranslation";
import { getCountryFeatures, getFiltersByCountry } from "../../utils/Functions"
import { Country } from "../../interfaces/locations";
import { getLocations } from "../../data/api"

interface props {
  children: JSX.Element | JSX.Element[];
}

const domainList: any = {
  'iris.infocasas.com.uy': 'UY',
  'iris.infocasas.com.py': 'PY',
  'iris.yapo.cl': 'CL',
  'iris.fincaraiz.com.co': 'CO',
  'iris.encuentra24.com': 'PA',
  'localhost': 'UY',
  'iris-dev-57115cc4dce8.herokuapp.com' : 'UY'
};

export const filtersByCountryInitialState = {
  propertyType: true,
  price: true,
  estates: true,
  bethrooms: true,
  badrooms: true,
  amenities: true,
  searchProject: true,
  subjectToSubsidy: true,
  financing: true,
  handOverDate: true,
  promotedHousing: true,
  unitsRented: true,
  pieBonus : false,
  serviceRoom : false,
  developers : true
}

export const ConfigProvider = ({ children }: props) => {
  const [country, setCountry] = useState<CountryCodesType>("");
  const [countryFeatures, setCountryFeatures] = useState<CountryFeatures>({
    id: "",
    name: ""
  });
  const [config, setConfig] = useState<ConfigType>({
    id: 0,
    oneSignalId: "",
    short_link : "",
    is_test: false,
    country : {
      id : 0,
      name : "",
      identifier : "",
      code : "",
      flag  :"",
      show_dropdown : false
    }
  });
  const [filtersByCountry, setFiltersByCountry] = useState<FiltersByCountry>(filtersByCountryInitialState);
  const [countryFilter, setCountryFilter] = useState<CountryCodesType>("");

  const translation: any = useTranslation(country.toLowerCase());

  const [allCountries , setFullCountries] = useState<Country[]>([])


  useEffect(() => {

    const hostname = window.location.hostname
    const currentDomain = Object.keys(domainList).find(domain => hostname.includes(domain)) || '';
    const currentCountryCode = domainList[currentDomain] ?? "UY"

    const countryAllowed = getCountryFeatures(currentCountryCode)
    if (countryAllowed) {
      setCountryFeatures(countryAllowed)
     
    }
    const filtersByCountryAllowed = getFiltersByCountry(currentCountryCode)
    if (filtersByCountryAllowed) setFiltersByCountry(filtersByCountryAllowed)


    setCountry(currentCountryCode)
    setCountryFilter(currentCountryCode)

    //Setear estilos al setear país
    if(currentCountryCode){
      // document.body.classList.add(`country-style-${currentCountryCode.toLowerCase()}`);
    }

    //Setear all countries
    saveAllCountries()
    
  }, [])


  const setDomainConfig = (config: ConfigType) => {
    setConfig(config)
    
  };


  const saveAllCountries = () => {
    getLocations()
    .then((countries) => {
      if(countries && countries.success && countries.data){
        const data: Country[] = countries.data;
        setFullCountries(data)

      }
      
    })
  }

  return (
    <CountryContext.Provider
      value={{ country, setDomainConfig, config, translation, countryFeatures, filtersByCountry, 
        setFiltersByCountry, countryFilter, setCountryFilter, allCountries
       }}
    >
      {children}
    </CountryContext.Provider>
  );
};