import { Button, Col, Dropdown, Row } from "react-bootstrap";
import { ReactNode } from "react";

export const UnitsSelect = ({
  options,
  value,
  onChange,
}: {
  options: { value: string; text: string; tipology: string; disabled?: boolean; icon?: ReactNode }[];
  value: { value: string, tipology: string };
  onChange: ({ value, tipology }: { value: string, tipology: string }) => void;
}) => {
  return (
    <>
      <Row className="units-select gx-2 d-none d-lg-flex">
        {options.map((opt, index) => {
          const disabled = typeof opt.disabled !== "undefined" && opt.disabled;
          const active = value.value === "0" ?
            (opt.tipology === value.tipology && opt.value === value.value) :
            opt.value === value.value;
          const handleChange = () => {
            onChange({ value: opt.value, tipology: opt.tipology })
          };

          return (
            <Col key={"unit-select-item" + index} xs="auto">
              <Button
                disabled={disabled}
                onClick={handleChange}
                variant={active ? "outline-primary" : "outline-secondary"}
                className={`text-capitalize w-100 fs-sm unit-select-btn d-inline-flex align-items-center ${active ? "text-secondary activo" : ""
                  }`}
              >
                {!!opt.icon && opt.icon}
                {opt.text}
              </Button>
            </Col>
          );
        })}
      </Row>
      <Dropdown className="d-lg-none" align={"end"}>
        <Dropdown.Toggle
          variant="outline-primary"
          className="w-100 fs-sm unit-select-btn text-secondary activo d-inline-flex align-items-center"
        >
          {options.find((o) => o.value === value.value)?.icon}
          {options.find((o) => o.value === value.value)?.text}
        </Dropdown.Toggle>

        <Dropdown.Menu className="px-1 py-1 border shadow">
          {options.map((opt, index) => {
            const disabled =
              typeof opt.disabled !== "undefined" && opt.disabled;
            const active = opt.value === value.value;
            const handleChange = () => onChange({ value: opt.value, tipology: opt.tipology });
            return (
              <Dropdown.Item
                onClick={handleChange}
                disabled={disabled}
                key={"drop_" + index}
                className={`d-flex align-items-center px-2 py-2 rounded-1 ${active ? "bg-sm-light" : ""
                  }`}
              >
                <div
                  className="rounded-circle border-light border d-flex align-items-center justify-content-center me-2"
                  style={{ width: 17, height: 17 }}
                >
                  {active && (
                    <div
                      className="rounded-circle bg-primary d-flex align-items-center justify-content-center"
                      style={{ width: 10, height: 10 }}
                    ></div>
                  )}
                </div>
                {!!opt.icon && opt.icon}
                {opt.text}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};
