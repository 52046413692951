import { Form } from "react-bootstrap"
import { ClientRegisterFormType } from "../ClientRegister"

export const ClientForm = ({
    form,
    setForm,
    send,
    index,
    errorMessages,
    requiredOtherThanName
}: {
    form: ClientRegisterFormType,
    setForm: Function,
    send?: string | null,
    index: number,
    errorMessages : any;
    requiredOtherThanName? : boolean
}) => {

    const tituloCliente = index == 0 ? "Cliente:" : "Otro Cliente:"

    return (

        <>  
        <div className="mt-3">
            <h3 className="mb-2 fw-bold fs-6"> {tituloCliente}</h3>
            <Form.Group className="mb-2" controlId={`formClientName_${index}`}>
                <Form.Label className="fw-bold fs-xs mb-1">Nombre</Form.Label>
                <Form.Control
                    type="text"
                    required
                    value={form.clients[index].name}
                    onChange={(value: any) =>
                        setForm({ type: "change", payload: { input: `clients_${index}_name`, value: value.target.value } })
                    }
                    className="fs-sm"
                />
                <Form.Control.Feedback type="invalid">
                    {errorMessages.clients[index].name}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-2" controlId={`formClientLastname_${index}`}>
                <Form.Label className="fw-bold fs-xs mb-1">Apellido</Form.Label>
                <Form.Control
                    type="text"
                    required
                    value={form.clients[index].lastname}
                    onChange={(value: any) =>
                        setForm({ type: "change", payload: { input: `clients_${index}_lastname`, value: value.target.value } })
                    }
                    className="fs-sm"
                />
               <Form.Control.Feedback type="invalid">
                    {errorMessages.clients[index].lastname}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-2" controlId={`formClientEmail_${index}`}>
                <Form.Label className="fw-bold fs-xs mb-1">Email</Form.Label>
                <Form.Control
                    type="text"
                    required={requiredOtherThanName}
                    value={form.clients[index].email}
                    onChange={(value: any) =>
                        setForm({ type: "change", payload: { input: `clients_${index}_email`, value: value.target.value } })
                    }
                    className="fs-sm"
                />
                <Form.Control.Feedback type="invalid">
                    {errorMessages.clients[index].email}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-2" controlId={`formClientPhone_${index}`}>
                <Form.Label className="fw-bold fs-xs mb-1">Teléfono</Form.Label>
                <Form.Control
                    type="text"
                    required={requiredOtherThanName}
                    value={form.clients[index].phone}
                    onChange={(value: any) =>
                        setForm({ type: "change", payload: { input: `clients_${index}_phone`, value: value.target.value } })
                    }
                    className="fs-sm"
                />
                <Form.Control.Feedback type="invalid">
                    {errorMessages.clients[index].phone}
                </Form.Control.Feedback>
            </Form.Group>
        </div>


        </>
    )
}