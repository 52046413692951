
import { GetActivePromoResponse } from "../../interfaces/promo"

export const usePromoFetch = () => {

    

    const getCountryPromo = async (country_id : number) => {

        const url = process.env.REACT_APP_AUTH_API_URL + '/api/promos/get-active-promo?country_id=' + country_id
        let response : GetActivePromoResponse = await fetch(
            url,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache'
                },
            }
        ).then(data => data.json()).catch(err => {
            return {
                error : err.toString()
            }
        })

        return response;
    }

    return {
        getCountryPromo
    }
}