import {  useState } from "react";
import AlertContext, { AlertVariants } from "./AlertContext";

interface props {
  children: JSX.Element | JSX.Element[];
}


export const AlertProvider = ({ children }: props) => {
  
 
  const [typeGlobalAlert , setTypeGlobalAlert] = useState<AlertVariants>('')
  const [showGlobalAlert , setShowGlobalAlert] = useState<boolean>(false)
  const [messageGlobalAlert , setMessageGlobalAlert] = useState<string>('')
  const [positionAlert , setPositionAlert] = useState<'top'| 'bottom'>('top')

  return (
    <AlertContext.Provider
      value={{
        positionAlert,
        typeGlobalAlert,
        showGlobalAlert,
        messageGlobalAlert,
        setTypeGlobalAlert,
        setShowGlobalAlert,
        setMessageGlobalAlert,
        setPositionAlert
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};
