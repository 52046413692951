import { Button, Col, Row } from "react-bootstrap";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/es"; // import locale
import { useMemo, useState } from "react";

dayjs.locale("es"); // use locale

export const DATE_FORMAT = "MM-DD-YYYY";
export const DateCalendar = ({
  value,
  onChange,
}: {
  value?: string;
  onChange: (x: string) => void;
}) => {
  const selectedDay: Dayjs = useMemo(() => {
    if (typeof value == "undefined" || !dayjs(value, DATE_FORMAT).isValid())
      return dayjs();
    else return dayjs(value, DATE_FORMAT);
  }, [value]);

  const getWeekStart = (day: Dayjs): Dayjs => day.startOf("week");
  const [currentWeek, setCurrentWeek] = useState(getWeekStart(selectedDay));

  return (
    <div className="date-calendar border rounded-2 py-2 px-2">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <span className="fw-bold fs-xs text-capitalize">
          {currentWeek.format("MMMM")} {currentWeek.format("YYYY")}
        </span>
        <div>
          <Button
            variant="outline-primary"
            className="me-2 rounded-circle border"
            size="sm"
            disabled={currentWeek.isBefore(dayjs(), "day")}
            onClick={() => {
              if (!currentWeek.isBefore(dayjs(), "day"))
                setCurrentWeek(currentWeek.subtract(1, "week"));
            }}
          >
            <i className="bi bi-caret-left-fill"></i>
          </Button>
          <Button
            variant="outline-primary"
            className="rounded-circle border"
            size="sm"
            onClick={() => setCurrentWeek(currentWeek.add(1, "week"))}
          >
            <i className="bi bi-caret-right-fill"></i>
          </Button>
        </div>
      </div>
      <Row className="d-flex justify-content-between align-items-center gx-1 flex-nowrap">
        {[0, 1, 2, 3, 4, 5, 6].map((addDays, index) => {
          const day: Dayjs = currentWeek.add(addDays, "day");
          const selected = day.isSame(selectedDay, "day");
          const disabled = day.isBefore(dayjs(), "day");
          return (
            <Col
              className="d-flex flex-column align-items-center"
              key={"date_" + index}
            >
              <span className="fs-xxs text-black-50 mb-2 text-capitalize">
                {day.format("ddd").replace(".", "")}
              </span>
              <Button
                variant="outline-primary"
                className={` text-secondary fs-xs w-100 px-0 ${
                  disabled ? "bg-xs-light" : ""
                } ${selected ? "fw-bold selected" : "border"}`}
                size="sm"
                disabled={disabled}
                onClick={() => onChange(day.format(DATE_FORMAT))}
              >
                {day.format("D")}
              </Button>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};
