import { ProjectType } from "../../interfaces/project"
import { IconCheckCircle } from "../CustomIcons/IconCheckCircle"
import { IconCheckCircleFill } from "../CustomIcons/IconCheckCircleFill"
import { IconXCircleFill } from "../CustomIcons/IconXCircleFill"


export const PropertyRules = ({project } : {project : ProjectType}) => {


    const rules = [
        {
            title : 'publicar en portales',
            allowed : project.allow_share_portal
        },
        {
            title : 'publicar y hacer campañas en redes sociales',
            allowed : project.allow_share_social
        },
        {
            title : 'publicar en página web',
            allowed : project.allow_share_web
        }
    ]

    return (
        <div className="rules-section border-top py-4">
            <h3 className={"fs-4 fw-normal m-0 mb-3"}>Formas de promocionar este proyecto</h3>
            {
                rules.map((rule,i) => <p key={i} className="text-dark m-0 mb-2">
                        {
                            rule.allowed ? <IconCheckCircleFill width={16} height={16} className="text-primary"/> : <IconXCircleFill width={16} height={16} className="text-light-secondary"/>
                        }
                        <span className={"mx-2 "+(!rule.allowed?'':'')}>{rule.allowed? 'Sí ' : 'No '}{rule.title}</span>
                </p>)
            }
            
            
        </div>
    )
}