import { CountryCodesType, CountryFeatures, ProjectUrlTypes } from "../interfaces/config";
import { ProjectTypeType } from "../interfaces/project";
import { CountryType } from "../interfaces/property";
import { PROPERTIES_VALUES } from "./Constants";

const {LAND_TYPE , HOUSE_TYPE , LOCAL_TYPE, OFFICE_TYPE, APARTMENT_TYPE , 
    INDUSTRIAL_LOCAL_TYPE} = PROPERTIES_VALUES

export const translateProjectTypes = (types: ProjectTypeType[], translation: any, country_code: CountryCodesType) => {
    try {

        let typesFormatted = types.map((type: ProjectTypeType) => {
            let name = type.name
            switch (type.identifier) {
                case PROPERTIES_VALUES.LAND_TYPE:
                    name = translation.lands
                    break;
                case PROPERTIES_VALUES.GARAGE:
                    name = translation.garage_filter
                    break;
                default:
                    break;
            }
            return { ...type, name }
        })


        //Quitar algunas para colombia
        if (country_code == 'CO') {
            typesFormatted = typesFormatted.filter(t =>
                t.identifier != PROPERTIES_VALUES.RANCH &&
                t.identifier != PROPERTIES_VALUES.INDUSTRIAL_LOCAL_TYPE &&
                t.identifier != PROPERTIES_VALUES.SPECIAL_BUSINESS
            )
        }

        if (country_code != 'CO') {
            typesFormatted = typesFormatted.filter(t =>
                t.identifier != PROPERTIES_VALUES.APARTAESTUDIO
            )
        }

        return typesFormatted
    } catch (error) {
        console.log(error)
        return []
    }



}

export const getHeaderUrls = (countryId: any , projectTypesList : ProjectTypeType[]) => {

    if (!countryId || !projectTypesList) {
        return false
    }

    const projectTypes = {
        land : projectTypesList.find(t => t.identifier == LAND_TYPE),
        house : projectTypesList.find(t => t.identifier == HOUSE_TYPE),
        apartment : projectTypesList.find(t => t.identifier == APARTMENT_TYPE),
        local :projectTypesList.find(t => t.identifier == LOCAL_TYPE),
        office : projectTypesList.find(t => t.identifier == OFFICE_TYPE),
        industrialLocal : projectTypesList.find(t => t.identifier == INDUSTRIAL_LOCAL_TYPE)
    }


    let startUrl = `/proyectos?country=${countryId}`

    let urls: ProjectUrlTypes = {
        all: startUrl,
        residency: `${startUrl}&propertyType=${projectTypes?.house?.id},${projectTypes?.apartment?.id}`,
        comercial: `${startUrl}&propertyType=${projectTypes?.local?.id},${projectTypes?.office?.id},${projectTypes?.industrialLocal?.id}`,
        lands: `${startUrl}&propertyType=${projectTypes?.land?.id}`,
        promos: `${startUrl}&is_promo=1`,
    }

    return urls

}
