import { ReactNode, useState } from "react";
import { PostType } from "../../../interfaces/post";
import { OverlayTrigger, Popover, Row, Image, Col } from "react-bootstrap";
import { useImage } from "../../../hooks/image/useImage";

export const FeedPostTooltip = ({
  children,
  post,
}: {
  children: ReactNode;
  post: PostType;
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };
  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const OptimizeImage = ({
    src,
    width,
    height,
    alt,
    classes,
    rounded = false
  }: {
    src: string,
    width: number,
    height: number,
    alt: string,
    classes: string,
    rounded: boolean
  }) => {
    const srcOptimize = useImage({ src, width, height })

    return (
      <Image
        src={srcOptimize}
        alt={alt}
        className={classes}
        height={height}
        rounded={rounded}
      ></Image>
    )
  }

  return (
    <OverlayTrigger
      key="bottom"
      placement="bottom"
      show={showTooltip}
      overlay={
        <Popover
          id="popover-positioned-bottom"
          className="popover-custom border-0 shadow-lg ms-5"
          onMouseLeave={handleMouseLeave}
        >
          <Popover.Header as="h3" className="bg-white border-0 p-3">
            {children}
          </Popover.Header>
          <Popover.Body className="text-secondary px-4 pt-0">
            {/* <p className="publish-by-description">
              {post.publishBy.description}
            </p> */}
            <Row className="gap-2 flex-nowrap w-100 px-2 my-3">
              {post.details?.images.slice(0, 4).map((image, index) => (
                <Col
                  lg={3}
                  key={`image-${index}`}
                  className="p-0 m-0 position-relative"
                >
                  {post.details?.images.length > 3 && index === 3 ? (
                    <>
                      <div className="overlay-post-image bg-black position-absolute"></div>
                      <div className="overlay-number position-absolute h-100 w-100 text-white d-flex justify-content-center align-items-center pt-1">
                        <h5>+{post.details?.images.length - 3}</h5>
                      </div>
                      {/* onClick ir a detalle */}
                      <OptimizeImage
                        src={image}
                        alt="Post image"
                        classes="p-0 fit-cover w-100"
                        height={50}
                        width={50}
                        rounded
                      />
                    </>
                  ) : (
                    <OptimizeImage
                      src={image}
                      alt="Post image"
                      classes="p-0 fit-cover w-100"
                      height={50}
                      width={50}
                      rounded
                    />
                  )}
                </Col>
              ))}
            </Row>
          </Popover.Body>
        </Popover>
      }
    >
      <div onMouseEnter={handleMouseEnter}>{children}</div>
    </OverlayTrigger>
  );
};
