import { Col, Row, Badge } from "react-bootstrap";
import { PropertyType } from "../../interfaces/property";
import { PropertyLocation } from "../../components/PropertyLocation/PropertyLocation";
import { NeighbourhoodDescription } from "../../components/NeighbourhoodDescription/NeighbourhoodDescription";
import { NeighbourhoodTendency } from "../../components/NeighbourhoodTendency/NeighbourhoodTendency";
import { NeighbourhoodDetails } from "../../components/NeighbourhoodDetails/NeighbourhoodDetails";
import useCountry from "../../hooks/config/useConfig";
import { ProjectType } from "../../interfaces/project";

export const LocationSection = ({ property }: { property: ProjectType }) => {
    const { zone , commune} = property;
    const { country, translation } = useCountry();
    const haveDetails: boolean = zone ? (zone.is_quiet == true || zone.is_safe == true || zone.has_parks_squares == true || zone.has_supermarkets == true ||zone.has_bikeway == true)  : false
    const haveDetailsCommune: boolean = commune ? (commune.is_quiet == true || commune.is_safe == true || commune.has_parks_squares == true || commune.has_supermarkets == true ||commune.has_bikeway == true) : false
    const hasTranslations = !!Object.values(translation).length

    return (
        <Row className="location-section">
            <Col
                lg={7}
                xs={12}
                className="pe-lg-4 pt-4 resume-left"
            >
                <h3 className="fs-4 fw-normal m-0 mb-3 mt-2">Ubicación</h3>
                <div className="property-location">
                    <p className="property-address mb-0 lh-sm">{property.address}</p>
                    <p className="property-hood text-black-50 lh-sm mb-0">
                        {commune ? `${commune.name}, ` : ''}
                        {zone ? (property.country.code === "CL" ? `Comuna ${zone.name}, ` : `${zone.name}, `) : ''}
                        {property.country.code === "CL" && "Región "}
                        {property.department?.name}
                    </p>
                </div>

                {commune && (
                    <>
                        {commune.description && commune.description !== '' &&
                            <>
                                <h3 className="fs-6 mb-2 fw-bold mt-4">{hasTranslations && translation.commune} {property.commune?.name}</h3>
                                <div className="pb-2 pb-lg-0">
                                    <NeighbourhoodDescription description={commune.description} />
                                </div>
                            </>
                        }

                        {haveDetailsCommune &&
                            <>
                                <h3 className="fs-6 mb-3 fw-bold mt-4">Algunos Detalles</h3>
                                <div className="pb-2 pb-lg-0">
                                    <NeighbourhoodDetails neighbourhood={commune} />
                                </div>
                            </>
                        }
                    </>
                )}

                {!commune && zone && (
                    <>
                        {zone?.description && zone.description !== '' &&
                            <>
                                <h3 className="fs-6 mb-2 fw-bold mt-4">{hasTranslations && translation.neighbourhood} {property.zone?.name}</h3>
                                <div className="pb-2 pb-lg-0">
                                    <NeighbourhoodDescription description={zone.description} />
                                </div>
                            </>
                        }

                        {haveDetails &&
                            <>
                                <h3 className="fs-6 mb-3 fw-bold mt-4">Algunos Detalles</h3>
                                <div className="pb-2 pb-lg-0">
                                    <NeighbourhoodDetails neighbourhood={zone} />
                                </div>
                            </>
                        }
                    </>
                )}
            </Col>

            {(zone && !!zone.trends?.length) && <Col
                lg={5}
                xs={12}
                className="px-lg-4 pt-4 pb-4 resume-left border-start"
            >
                <hr className="d-lg-none mt-0 mb-4 text-gray-50" />
                <h3 className="fs-4 fw-normal mb-3 mt-2 d-flex align-items-center lh-1">
                    Tendencias
                    <Badge className="ms-2 fs-xxs text-dark mt-1" bg="light">
                        EN {country !== "CL" ? "ESTE" : "ESTA"} {hasTranslations && translation.neighbourhood.toUpperCase()}
                    </Badge>
                </h3>
                <div className="pb-2 pb-lg-0">
                    <NeighbourhoodTendency neighbourhood={zone} />
                </div>
                <hr className="d-lg-none mt-0 mb-3 text-gray-50" />
            </Col>}
            <Col
                lg={12}
                className="pe-lg-4 pb-2 pt-lg-5 resume-left"
            >
                <div className="">
                    <PropertyLocation property={property} />
                </div>
            </Col>
        </Row >
    );
};
