import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useFetch } from "../hooks/fetch/useFetch";
import useUser from "../hooks/user/useUser";

export const ForbiddenRegularUser = () => {

    const navigate = useNavigate();
    const {  state} = useLocation();
    const { logout } = useFetch()
    const { userState, isAdmin } = useUser()

    const goLogin = async (ev: any) => {

        ev.preventDefault()
        await logout(userState)
        //Vamos a login y luego debe enviar a la url de donde vino antes de forbidden
        navigate(`/iniciar-sesion/`, { state: { from: state.from , search : state.search } })
    }

    if (isAdmin) {
        return <Navigate to='/' />
    }

    return <>
        <main className="container main-container" >
            <div className="centered-section error-bg p-4 rounded-3">
                <h3>Sección Restringida</h3>
                <p>No cuentas con permisos de administrador.</p>
                <a href="/iniciar-sesion" onClick={goLogin}>
                    <button className="btn btn-primary">
                        Ingresar con una cuenta de administrador
                    </button>
                </a>

            </div>
        </main>
    </>
}