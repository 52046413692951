import { useMemo } from "react";
import { PopupData } from "../../interfaces/ui";
import { ImageModal } from "./ImageModal";


export const RandomImagePopup = ( {
    popups
}: {
    popups : PopupData[]
}  ) => {

    const randomIndex = useMemo(() => Math.floor(Math.random() * (popups.length) ),[])
    const randomPopup =  popups[randomIndex]
    
    return (
        <>
        {
            randomPopup && 
            <ImageModal 
                cookieName={randomPopup.cookieName}  
                desktopImg={randomPopup.desktopImg}
                mobileImg={randomPopup.mobileImg}
                link={randomPopup.link}
                endDate={randomPopup.endDate}
                name={randomPopup.name}
            />
        }
        </>
    )


}