import { createContext } from "react";
import { ConfigType, CountryCodesType, CountryFeatures, FiltersByCountry, HeaderTabs, ProjectUrlTypes } from "../../interfaces/config";
import { Country } from "../../interfaces/locations";

export type CountryContextProps = {
  country: CountryCodesType;
  setDomainConfig: (config: ConfigType) => void;
  config: ConfigType;
  translation: any;
  countryFeatures: CountryFeatures;
  filtersByCountry: FiltersByCountry;
  setFiltersByCountry: (filter: FiltersByCountry) => void;
  countryFilter: CountryCodesType;
  setCountryFilter: (filter: CountryCodesType) => void;
  allCountries : Country[]
  
};

const CountryContext = createContext<CountryContextProps>({} as CountryContextProps);

export default CountryContext;
