function DashboardStats({title, icon, value, description, colorIndex}){

    const COLORS = ["primary", "primary"]

    const getDescStyle = () => {
        if(description.includes("↗︎"))return "tw-font-bold tw-text-green-700 dark:tw-text-green-300"
        else if(description.includes("↙"))return "tw-font-bold tw-text-rose-500 dark:tw-text-red-400"
        else return ""
    }

    return(
        <div className="tw-stats tw-shadow">
            <div className="tw-stat">
                
                <div className="tw-stat-title dark:tw-text-slate-300 d-flex justify-content-between w-100 text-primary">
                    
                    <span>{title}</span>
                    {icon}
                </div>
                <div className={`tw-stat-value dark:tw-text-slate-300 tw-text-${COLORS[colorIndex%2]}`}>{value}</div>
                <div className={"tw-stat-desc  " + getDescStyle()}>{description}</div>
            </div>
        </div>
    )
}

export default DashboardStats