import { Alert} from "react-bootstrap";
import useAlert from "../../hooks/alert/useAlert"
import "./GlobalAlert.scss"

export const GlobalAlert = () => {

    const { positionAlert, typeGlobalAlert, showGlobalAlert, messageGlobalAlert ,setShowGlobalAlert} = useAlert();

    return (
        <Alert variant={typeGlobalAlert}  show={showGlobalAlert} 
        onClose={() => setShowGlobalAlert(false)} 
        dismissible
        className={`fixed-alert fixed-alert-${positionAlert}`}>
            {messageGlobalAlert}
        </Alert>
    )
}