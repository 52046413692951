
export const translations = {
    uy: {
      rent: 'Alquiler',
      lands : 'Terrenos',
      news: 'Novedades',
      apartment: 'Apartamento',
      commision: 'Comisión Inmobiliaria',
      studio: 'Monoambiente',
      abbrStudio: 'Mono.',
      financing: 'Financiación',
      garage: 'Garaje',
      garage_abrv : 'Gge.',
      floors: 'Plantas',
      developer: 'Inmobiliaria',
      register_inmo_field : 'Inmobiliaria',
      neighbourhood: 'Barrio',
      commune: 'Comuna/Localidad',
      estate: 'Estado',
      extraDocument: '',
      warehouse: 'Box',
      warehouses: 'Boxes',
      constructionStatus: [
        {id: "1", text: "A estrenar"},
        {id: "8", text: "En construcción"},
        {id: "9", text: "En pozo"}
      ],
      tooltip_list : 'Ahora podés ver las propiedades en lista y en grilla',
      filter_type : "Tipo de Propiedad",
      bedrooms : "Dormitorios",
      amenities : 'Amenities',
      social_housing : "Vivienda Promovida",
      cash_price : "Contado",
      with_rent : "Con renta",
      units_rent : "Unidades con renta",
      m2_indoor : 'Internos',
      m2_outdoor : 'Terraza',
      warranty : 'garantía',
      leverage : 'Aprovechá',
      warranty_description : 'Ahora puedes ofrecer un servicio más integral y ganar una <strong>comisión</strong> por cada Garantía de Alquiler que derives.<strong> Nosotros nos encargamos de todo.</strong>',
      warranty_first_step : 'Brindá los datos del cliente',
      warranty_second_step : 'InfoCasas gestiona el análisis del crédito',
      warranty_third_step : 'Se recibe el aval de la aseguradora',
      rent_warranties : 'Garantías de Alquiler',
      tooltip_warranties  : 'Ahora podés ver el estado de tus garantías',
      rent_warranty : 'garantía de alquiler',
      identity_doc : 'C.I',
      warranty_insurance_label : '¿A quiénes solicitas esta garantía?',
      tooltip_profile  : 'Accedé a tu perfil y llevá el registro de tu gestión en Iris',
      common_expenses : 'Gastos comunes',
      short_bedroom : 'dorm.',
      single_bedroom : 'dormitorio',
      griller : 'Parrillero',
      visit_garage: 'Garaje de visita',
      short_one_bathroom : 'b.',
      pdf_bathrooms : 'baños',
      pdf_single_bathroom : 'baño',
      warranty_ask_wpp : 'Hola,%20soy%20de%20Uruguay,%20necesito%20informaci%C3%B3n%20sobre%20Garant%C3%ADas%20de%20Alquiler',
      warranty_earning : 'Ganá una comisión',
      garage_filter: 'Garaje o Cocheras',
      referred_inmo : 'Inmobiliaria',
      furnished : 'Amueblado',
      social_room : 'Salón de eventos',
      filter_developers : "Desarrolladoras",
      air_conditioner : 'Previsión AA'
    },
    cl: {
      rent: 'Arriendo',
      lands : 'Terrenos',
      news: 'Noticias',
      apartment: 'Departamento',
      commision: 'Comisión por venta',
      studio: 'Estudio',
      abbrStudio: 'Estudio',
      financing: 'Financiamiento',
      garage: 'Estacionamiento',
      garage_abrv : 'Estac.',
      floors: 'Pisos',
      developer: 'Broker',
      register_inmo_field : 'Nombre de Broker / Corredor',
      neighbourhood: 'Comuna',
      commune: 'Comuna/Localidad',
      estate: 'Región',
      extraDocument: 'Condiciones comerciales',
      warehouse: 'Bodega',
      warehouses: 'Bodegas',
      constructionStatus: [
        {id: "1", text: "En recepción final"},
        {id: "8", text: "En verde"},
        {id: "9", text: "En blanco"}
      ],
      tooltip_list : 'Ahora puedes ver las propiedades en lista y en grilla',
      filter_type : "Tipo de Propiedad",
      bedrooms : "Dormitorios",
      amenities : 'Amenities',
      social_housing : "Vivienda Promovida",
      cash_price : "Contado",
      with_rent : "Con renta",
      units_rent : "Unidades con renta",
      m2_indoor : 'Internos',
      m2_outdoor : 'Terraza',
      warranty : 'garantía',
      leverage : 'Aprovecha',
      warranty_description : 'Ahora puedes ofrecer un servicio más integral y ganar una <strong>comisión</strong> por cada Aval de Arriendo que derives.<strong> Nosotros nos encargamos de todo.</strong>',
      warranty_first_step : 'Brinda los datos del cliente',
      warranty_second_step : 'Yapo gestiona el análisis del crédito',
      warranty_third_step : 'Se recibe el aval de la aseguradora',
      rent_warranties : 'Avales de Arriendo',
      tooltip_warranties  : 'Ahora podés ver el estado de tus avales',
      rent_warranty : 'Aval de Arriendo',
      identity_doc : 'C.I',
      warranty_insurance_label : '¿A quiénes solicitas este aval?',
      tooltip_profile  : 'Accede a tu perfil y lleva el registro de tu gestión en Iris',
      common_expenses : 'Gastos comunes',
      short_bedroom : 'dorm.',
      single_bedroom : 'dormitorio',
      griller : 'Parrillero',
      visit_garage: 'Garage de visita',
      short_one_bathroom : 'b.',
      pdf_bathrooms : 'baños',
      pdf_single_bathroom : 'baño',
      warranty_ask_wpp : 'Hola,%20necesito%20informaci%C3%B3n%20sobre%20Avales%20de%20Arriendo',
      warranty_earning : 'Gana una comisión',
      garage_filter: 'Garaje o Cocheras',
      referred_inmo : 'Broker / Corredor',
      furnished : 'Amueblado',
      social_room : 'Salón de eventos',
      filter_developers : "Inmobiliarias",
      air_conditioner : 'Aire Acondicionado'
    },
    py: {
      rent: 'Alquiler',
      lands : 'Terrenos',
      news: 'Novedades',
      apartment: 'Apartamento',
      commision: 'Comisión Inmobiliaria',
      studio: 'Monoambiente',
      abbrStudio: 'Mono.',
      financing: 'Financiación',
      garage: 'Garage',
      garage_abrv : 'Gge.',
      floors: 'Plantas',
      developer: 'Inmobiliaria',
      register_inmo_field : 'Inmobiliaria',
      neighbourhood: 'Barrio',
      commune: 'Comuna/Localidad',
      estate: 'Estado',
      extraDocument: '',
      warehouse: 'Box',
      warehouses: 'Boxes',
      constructionStatus: [
        {id: "1", text: "A estrenar"},
        {id: "8", text: "En construcción"},
        {id: "9", text: "En pozo"}
      ],
      tooltip_list : 'Ahora podés ver las propiedades en lista y en grilla',
      filter_type : "Tipo de Propiedad",
      bedrooms : "Dormitorios",
      amenities : 'Amenities',
      social_housing : "Vivienda Promovida",
      cash_price : "Contado",
      with_rent : "Con renta",
      units_rent : "Unidades con renta",
      m2_indoor : 'Internos',
      m2_outdoor : 'Terraza',
      warranty : 'garantía',
      leverage : 'Aprovecha',
      warranty_description : 'Ahora puedes ofrecer un servicio más integral y ganar una <strong>comisión</strong> por cada Garantía de Alquiler que derives.<strong> Nosotros nos encargamos de todo.</strong>',
      warranty_first_step : 'Brindá los datos del cliente',
      warranty_second_step : 'InfoCasas gestiona el análisis del crédito',
      warranty_third_step : 'Se recibe el aval de la aseguradora',
      rent_warranties : 'Garantías de Alquiler',
      tooltip_warranties  : 'Ahora podés ver el estado de tus garantías',
      rent_warranty : 'Garantía de alquiler',
      identity_doc : 'C.I',
      warranty_insurance_label : '¿A quiénes solicitas esta garantía?',
      tooltip_profile  : 'Accedé a tu perfil y lleva el registro de tu gestión en Iris',
      common_expenses : 'Gastos comunes',
      short_bedroom : 'dorm.',
      single_bedroom : 'dormitorio',
      griller : 'Parrillero',
      visit_garage: 'Garage de visita',
      short_one_bathroom : 'b.',
      pdf_bathrooms : 'baños',
      pdf_single_bathroom : 'baño',
      warranty_ask_wpp : 'Hola,%20soy%20de%20Paraguay,%20necesito%20informaci%C3%B3n%20sobre%20Garant%C3%ADas%20de%20Alquiler',
      warranty_earning : 'Gana una comisión',
      garage_filter: 'Garaje o Cocheras',
      referred_inmo : 'Inmobiliaria',
      furnished : 'Amueblado',
      social_room : 'Salón de eventos',
      filter_developers : "Desarrolladoras",
      air_conditioner : 'Aire Acondicionado'
    },
    pa: {
      rent: 'Alquiler',
      lands : 'Terrenos',
      news: 'Novedades',
      apartment: 'Apartamento',
      commision: 'Comisión Inmobiliaria',
      studio: 'Monoambiente',
      abbrStudio: 'Mono.',
      financing: 'Financiación',
      garage: 'Estacionamiento',
      garage_abrv : '',
      floors: 'Plantas',
      developer: 'Broker',
      register_inmo_field : 'Broker',
      neighbourhood: 'Barrio',
      commune: 'Comuna/Localidad',
      estate: 'Estado',
      extraDocument: '',
      warehouse: 'Depósito',
      warehouses: 'Depósitos',
      constructionStatus: [
        {id: "1", text: "Entrega inmediata"},
        {id: "8", text: "En construcción"},
        {id: "9", text: "Preventa"}
      ],
      tooltip_list : 'Ahora puedes ver las propiedades en lista y en grilla',
      filter_type : "Tipo de Propiedad",
      bedrooms : "Recámaras",
      amenities : 'Amenidades',
      social_housing : "Vivienda Promovida",
      cash_price : "Contado",
      with_rent : "Con renta",
      units_rent : "Unidades con renta",
      m2_indoor : '',
      m2_outdoor : 'Terraza',
      warranty : 'garantía',
      leverage : 'Aprovecha',
      warranty_description : 'Ahora puedes ofrecer un servicio más integral y ganar una <strong>comisión</strong> por cada Garantía de Alquiler que derives.<strong> Nosotros nos encargamos de todo.</strong>',
      warranty_first_step : 'Brindá los datos del cliente',
      warranty_second_step : 'InfoCasas gestiona el análisis del crédito',
      warranty_third_step : 'Se recibe el aval de la aseguradora',
      rent_warranties : 'Garantías de Alquiler',
      tooltip_warranties  : 'Ahora podés ver el estado de tus garantías',
      rent_warranty : 'Garantía de alquiler',
      identity_doc : 'C.I',
      warranty_insurance_label : '¿A quiénes solicitas esta garantía?',
      tooltip_profile  : 'Accede a tu perfil y lleva el registro de tu gestión en Iris',
      common_expenses : 'Gastos comunes',
      short_bedroom : '',
      single_bedroom : 'recámara',
      griller : 'Área de barbacoa',
      visit_garage: 'Estacionamiento de visita',
      short_one_bathroom : '',
      pdf_bathrooms : '',
      pdf_single_bathroom : '',
      warranty_ask_wpp : '',
      warranty_earning : 'Gana una comisión',
      garage_filter: 'Garaje o Cocheras',
      referred_inmo : 'Broker',
      furnished : 'Amueblado',
      social_room : 'Salón de eventos',
      filter_developers : "Promotoras",
      air_conditioner : 'Aire Acondicionado'
    },
    do: {
      rent: 'Alquiler',
      lands : 'Terrenos',
      news: 'Novedades',
      apartment: 'Apartamento',
      commision: 'Comisión Inmobiliaria',
      studio: 'Monoambiente',
      abbrStudio: 'Mono.',
      financing: 'Financiación',
      garage: 'Garage',
      garage_abrv : 'Gge.',
      floors: 'Plantas',
      developer: 'Inmobiliaria',
      register_inmo_field : 'Inmobiliaria',
      neighbourhood: 'Barrio',
      commune: 'Comuna/Localidad',
      estate: 'Estado',
      extraDocument: '',
      warehouse: 'Box',
      warehouses: 'Boxes',
      constructionStatus: [
        {id: "1", text: "A estrenar"},
        {id: "8", text: "En construcción"},
        {id: "9", text: "En pozo"}
      ],
      tooltip_list : 'Ahora puedes ver las propiedades en lista y en grilla',
      filter_type : "Tipo de Propiedad",
      bedrooms : "Dormitorios",
      amenities : 'Amenities',
      social_housing : "Vivienda Promovida",
      cash_price : "Contado",
      with_rent : "Con renta",
      units_rent : "Unidades con renta",
      m2_indoor : 'Internos',
      m2_outdoor : 'Terraza',
      warranty : 'garantía',
      leverage : 'Aprovecha',
      warranty_description : 'Ahora puedes ofrecer un servicio más integral y ganar una <strong>comisión</strong> por cada Garantía de Alquiler que derives.<strong> Nosotros nos encargamos de todo.</strong>',
      warranty_first_step : 'Brindá los datos del cliente',
      warranty_second_step : 'InfoCasas gestiona el análisis del crédito',
      warranty_third_step : 'Se recibe el aval de la aseguradora',
      rent_warranties : 'Garantías de Alquiler',
      tooltip_warranties  : 'Ahora podés ver el estado de tus garantías',
      rent_warranty : 'Garantía de alquiler',
      identity_doc : 'C.I',
      warranty_insurance_label : '¿A quiénes solicitas esta garantía?',
      tooltip_profile  : 'Accede a tu perfil y lleva el registro de tu gestión en Iris',
      common_expenses : 'Gastos comunes',
      short_bedroom : 'dorm.',
      single_bedroom : 'dormitorio',
      griller : 'Parrillero',
      visit_garage: 'Garage de visita',
      short_one_bathroom : 'b.',
      pdf_bathrooms : 'baños',
      pdf_single_bathroom : 'baño',
      warranty_ask_wpp : '',
      warranty_earning : 'Gana una comisión',
      garage_filter: 'Garaje o Cocheras',
      referred_inmo : 'Inmobiliaria',
      furnished : 'Amueblado',
      social_room : 'Salón de eventos',
      filter_developers : "Promotoras",
      air_conditioner : 'Aire Acondicionado'
    },
    co: {
      rent: 'Arriendo',
      lands : 'Lotes',
      news: 'Novedades',
      apartment: 'Apartamento',
      commision: 'Comisión Inmobiliaria',
      studio: 'Monoambiente',
      abbrStudio: 'Mono.',
      financing: 'Financiación',
      garage: 'Parqueadero',
      garage_abrv : 'Parq.',
      floors: 'Plantas',
      developer: 'Inmobiliaria',
      register_inmo_field : 'Inmobiliaria',
      neighbourhood: 'Ciudad',
      commune: 'Comuna/Localidad',
      estate: 'Departamento',
      extraDocument: '',
      warehouse: 'Box',
      warehouses: 'Boxes',
      constructionStatus: [
        {id: "1", text: "A estrenar"},
        {id: "8", text: "En construcción"},
        {id: "9", text: "En pozo"}
      ],
      tooltip_list : 'Ahora puedes ver los inmuebles en lista y en grilla',
      filter_type : "Tipo de Inmueble",
      bedrooms : "Habitaciones",
      amenities : 'Zonas Comunes',
      social_housing : "Subsidios",
      cash_price : "Precio",
      with_rent : "Con arriendo",
      units_rent : "Unidades con arriendo",
      m2_indoor : 'Área Construida',
      m2_outdoor : 'Balcón',
      warranty : 'contrato',
      leverage : 'Aprovecha',
      warranty_description : 'Ofrece un servicio integral y gana <strong>comisión</strong> por cada Contrato de Arrendamiento que gestiones.<strong> Nosotros nos encargamos de todo.</strong>',
      warranty_first_step : 'Diligencia los datos del cliente',
      warranty_second_step : 'Fincaraíz gestiona el análisis del crédito',
      warranty_third_step : 'Se recibe la aprobación de la afianzadora',
      rent_warranties : 'Contratos de arrendamiento',
      tooltip_warranties  : 'Ahora podés ver el estado de tus garantías',
      rent_warranty : 'contrato de arrendamiento',
      identity_doc : 'Cédula RL',
      warranty_insurance_label : '¿A quiénes solicitas este contrato?',
      tooltip_profile  : 'Accede a tu perfil y lleva el registro de tu gestión en Iris',
      common_expenses : 'Administración',
      short_bedroom : 'hab.',
      single_bedroom : 'habitación',
      griller : 'BBQ',
      visit_garage: 'Parqueadero de visita',
      short_one_bathroom : 'b.',
      pdf_bathrooms : 'baños',
      pdf_single_bathroom : 'baño',
      warranty_ask_wpp : 'Hola,%20necesito%20informaci%C3%B3n%20sobre%20Contratos%20de%20arrendamiento%20',
      warranty_earning : 'Gana una comisión',
      garage_filter: 'Parqueadero',
      referred_inmo : 'Inmobiliaria',
      furnished : 'Amoblado',
      social_room : 'Salón Social',
      filter_developers : "Constructoras",
      air_conditioner : 'Aire Acondicionado'
    },
    es: {
      rent: 'Alquiler',
      news: 'Novedades',
      apartment: 'Apartamento',
      commision: 'Comisión Inmobiliaria',
      studio: 'Monoambiente',
      abbrStudio: 'Mono.',
      financing: 'Financiación',
      garage: 'Garaje',
      garage_abrv : 'Gge.',
      floors: 'Plantas',
      developer: 'Inmobiliaria',
      register_inmo_field : 'Inmobiliaria',
      neighbourhood: 'Barrio',
      estate: 'Estado',
      extraDocument: '',
      warehouse: 'Box',
      warehouses: 'Boxes',
      constructionStatus: [
        {id: "1", text: "A estrenar"},
        {id: "8", text: "En construcción"},
        {id: "9", text: "En pozo"}
      ],
      tooltip_list : 'Ahora podés ver las propiedades en lista y en grilla',
      bedrooms : "Dormitorios",
      amenities : 'Amenities',
      social_housing : "Vivienda Promovida",
      cash_price : "Contado",
      with_rent : "Con renta",
      units_rent : "Unidades con renta",
      m2_indoor : 'Internos',
      m2_outdoor : 'Terraza',
      warranty : 'garantía',
      leverage : 'Aprovecha',
      warranty_description : 'Ahora puedes ofrecer un servicio más integral y ganar una <strong>comisión</strong> por cada Garantía de Alquiler que derives.<strong> Nosotros nos encargamos de todo.</strong>',
      warranty_first_step : 'Brindá los datos del cliente',
      warranty_second_step : 'InfoCasas gestiona el análisis del crédito',
      warranty_third_step : 'Se recibe el aval de la aseguradora',
      rent_warranties : 'Garantías de Alquiler',
      tooltip_warranties  : 'Ahora podés ver el estado de tus garantías',
      rent_warranty : 'Garantía de alquiler',
      identity_doc : 'C.I',
      warranty_insurance_label : '¿A quiénes solicitas esta garantía?',
      tooltip_profile  : 'Accede a tu perfil y lleva el registro de tu gestión en Iris',
      common_expenses : 'Gastos comunes',
      short_bedroom : 'dorm.',
      single_bedroom : 'dormitorio',
      griller : 'Parrillero',
      visit_garage: 'Garage de visita',
      short_one_bathroom : 'b.',
      pdf_bathrooms : 'baños',
      pdf_single_bathroom : 'baño',
      warranty_ask_wpp : '',
      warranty_earning : 'Gana una comisión',
      garage_filter: 'Garaje o Cocheras',
      referred_inmo : 'Inmobiliaria',
      furnished : 'Amueblado',
      social_room : 'Salón de eventos',
      filter_developers : "Desarrolladoras",
      air_conditioner : 'Aire Acondicionado'
    },
    bo: {
      rent: 'Alquiler',
      lands : 'Terrenos',
      news: 'Novedades',
      apartment: 'Apartamento',
      commision: 'Comisión Inmobiliaria',
      studio: 'Monoambiente',
      abbrStudio: 'Mono.',
      financing: 'Financiación',
      garage: 'Garaje',
      garage_abrv : 'Gge.',
      floors: 'Plantas',
      developer: 'Inmobiliaria',
      register_inmo_field : 'Inmobiliaria',
      neighbourhood: 'Barrio',
      estate: 'Estado',
      extraDocument: '',
      warehouse: 'Box',
      warehouses: 'Boxes',
      constructionStatus: [
        {id: "1", text: "A estrenar"},
        {id: "8", text: "En construcción"},
        {id: "9", text: "En pozo"}
      ],
      tooltip_list : 'Ahora podés ver las propiedades en lista y en grilla',
      filter_type : "Tipo de Propiedad",
      bedrooms : "Dormitorios",
      amenities : 'Amenities',
      social_housing : "Vivienda Promovida",
      cash_price : "Contado",
      with_rent : "Con renta",
      units_rent : "Unidades con renta",
      m2_indoor : 'Internos',
      m2_outdoor : 'Terraza',
      warranty : 'garantía',
      leverage : 'Aprovecha',
      warranty_description : 'Ahora puedes ofrecer un servicio más integral y ganar una <strong>comisión</strong> por cada Garantía de Alquiler que derives.<strong> Nosotros nos encargamos de todo.</strong>',
      warranty_first_step : 'Brindá los datos del cliente',
      warranty_second_step : 'InfoCasas gestiona el análisis del crédito',
      warranty_third_step : 'Se recibe el aval de la aseguradora',
      rent_warranties : 'Garantías de Alquiler',
      tooltip_warranties  : 'Ahora podés ver el estado de tus garantías',
      rent_warranty : 'Garantía de alquiler',
      identity_doc : 'C.I',
      warranty_insurance_label : '¿A quiénes solicitas esta garantía?',
      tooltip_profile  : 'Accede a tu perfil y lleva el registro de tu gestión en Iris',
      common_expenses : 'Gastos comunes',
      short_bedroom : 'dorm.',
      single_bedroom : 'dormitorio',
      griller : 'Parrillero',
      visit_garage: 'Garage de visita',
      short_one_bathroom : 'b.',
      pdf_bathrooms : 'baños',
      pdf_single_bathroom : 'baño',
      warranty_ask_wpp : '',
      garage_filter: 'Garaje o Cocheras',
      referred_inmo : 'Inmobiliaria',
      furnished : 'Amueblado',
      social_room : 'Salón de eventos',
      air_conditioner : 'Aire Acondicionado'

    },
};