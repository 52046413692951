export const IconMoneyEstimation = (props : any) => {
  return (
<svg fill="currentColor"  viewBox="0 0 32 32" 
{...props} xmlns="http://www.w3.org/2000/svg">

<g data-name="32. Clipboard" id="_32._Clipboard">

<path d="M25,2H22a2,2,0,0,0-2-2H12a2,2,0,0,0-2,2H7A3,3,0,0,0,4,5V29a3,3,0,0,0,3,3H25a3,3,0,0,0,3-3V5A3,3,0,0,0,25,2ZM12,2h8V3h0V4H12ZM26,29a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V5A1,1,0,0,1,7,4h3a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2h3a1,1,0,0,1,1,1Z"/>

<path d="M15,19a1,1,0,0,0,2,0v-.18A3,3,0,0,0,16,13a1,1,0,0,1,0-2h2a1,1,0,0,0,0-2H17a1,1,0,0,0-2,0v.18A3,3,0,0,0,16,15a1,1,0,0,1,0,2H14a1,1,0,0,0,0,2Z"/>

<path d="M11,22H9a1,1,0,0,0,0,2h2a1,1,0,0,0,0-2Z"/>

<path d="M23,22H15a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Z"/>

<path d="M11,26H9a1,1,0,0,0,0,2h2a1,1,0,0,0,0-2Z"/>

<path d="M23,26H15a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Z"/>

</g>

</svg>
  );
};

