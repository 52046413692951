
import {useUserFetch} from "../../../hooks/fetch/useUserFetch"
import { useEffect, useState } from "react"
import { ProjectType } from "../../../interfaces/project"
import { Button, Col, ProgressBar, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import useUser from "../../../hooks/user/useUser"
import { OverlayLevelBlock } from "../../../components/OverlayLevelBlock/OverlayLevelBlock"
import { PropertyCard } from "../../../components/PropertyCard/PropertyCard"
import { SkeletonPropertyCard } from "../../../components/Skeleton/SkeletonPropertyCard"
import { formatMoney } from "../../../utils/Functions"
import Lottie from "lottie-react"
import GrayLoader from "../../../lotties/gray-loader.json";

const UserFavoritesList = () => {

    const LIMIT = 16
    const {getFavorites} = useUserFetch()
    const {userState} = useUser()
    const [favorites,setFavorites ] = useState<ProjectType[]>([])
    const [open, setOpen] = useState(false);
    const [page , setPage] = useState(1)
    const [isLoadingProps, setIsLoadingProps] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [visible, setVisible] = useState(0);
    const [propertyTotal, setPropertyTotal] = useState(0);

    const operationLabel = '?operation=Venta'
    useEffect(() => {
        loadFavorites(1,LIMIT,false)
    },[])

    const loadFavorites = async (page : number,limit : number,addToCurrent : boolean) => {
        setIsLoadingProps(true)
        await getFavorites('',limit,page).then(response => {
            setIsLoadingProps(false)
            setLoadingMore(false)
            console.log(response)
            if(response.success){
                
                if(addToCurrent){
                  setFavorites(prev => [...prev,...response.data])
                  setVisible(prev => prev + response.data.length)
                }else{
                  setFavorites(response.data)
                  setVisible(response.data.length)
                }
                setPropertyTotal(response.total)
            }
        }).catch(err => {
            setIsLoadingProps(false)
            console.log(err)
        })
    }

    const loadMore = async () => {
      setLoadingMore(true)
      await loadFavorites(page + 1,LIMIT,true)
      setPage(prev => prev + 1);
    };
  
    const PaginationText = ({ classesTitle }: { classesTitle: string }) => (
      <h3 className={classesTitle}>
        Estas viendo{" "}
        <span className="fw-bold">
          {formatMoney(visible)} de {formatMoney(propertyTotal)}
          {visible === 1 ? " favorito" : " favoritos"}
        </span>
      </h3>
    )

    return(
        <>
            {isLoadingProps && !loadingMore ?  (
            <Row className="gx-2 gy-3 mb-4 mt-1 mt-lg-0">
              {[...Array(8)].map((item, index) => (
                <Col
                  className="px-2"
                  xl={open ? "4" : "3"}
                  lg={open ? "6" : "4"}
                  md={open ? "12" : "6"}
                  sm="12"
                  xs="12"
                  key={"list_" + index}
                >
                  <SkeletonPropertyCard />
                </Col>
              ))}
            </Row>
          ) : propertyTotal === 0 ? (
            <div className="empty-search d-flex align-items-center justify-content-center">
              <p className="text-center">Aún no has agregado favoritos</p>
            </div>
          ) 
          : propertyTotal >  0 && 
          <>
            <Row className="gx-2 gy-3 mb-4 mt-1 mt-lg-0">
                {favorites?.map((item, index) => (
                  <Col
                    className="px-2"
                    xl={open ? "4" : "3"}
                    lg={open ? "6" : "4"}
                    md={open ? "12" : "6"}
                    sm="12"
                    xs="12"
                    key={"list_" + index}
                  >
                    <Link
                      to={item.level > Number(userState.level)? "#" : `/proyecto/${item.identifier}${operationLabel}`}
                      target="_blank"
                      className={`text-decoration-none position-relative d-block ${item.level > Number(userState.level) ? "invalid-link" : ""}`}
                    >
                      {item.level > Number(userState.level) && <OverlayLevelBlock listType={"grid"} />}
                      <PropertyCard data={item} download_brochure={true}/>
                    </Link>
                  </Col>
                ))}
              </Row>

              <Row className="mt-5">
                <Col className="align-items-center d-flex flex-column">
                  <PaginationText classesTitle={"fs-5 fw-light mb-3 pagination-custom"} />
                  <ProgressBar
                    className="progress-custom"
                    now={visible}
                    max={propertyTotal}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="justify-content-center d-flex mb-4">
                  
                  <Button
                    disabled={visible >= propertyTotal || loadingMore}
                    onClick={loadMore}
                    className="pt-2"
                    style={{ minWidth: 200 }}
                    children={
                    <div className="d-flex justify-content-center align-items-center position-relative">
                      Cargar más  
                      {loadingMore && <Lottie className="position-absolute loader-btn top-1" animationData={GrayLoader} style={{ height: 40 , right : 0}} loop />}
                      </div>
                      }
                  />
                </Col>
              </Row>

            </>
              
          }
        </>
    )
}


export default UserFavoritesList