import { GetSaleResponse, GetSalesResponse, GetUsersResponse, generalDataResult, interfaceObjectData, projectByIdentifier} from "../../interfaces/apiTypes";

import useAlert from "../alert/useAlert";
import { UpdateSaleBody} from "../../interfaces/userActivity";
import {useFetch} from "./useFetch"
import { GetAppraisalResponse, GetAppraisalsResponse, UpdateAppraisalBody } from "../../interfaces/appraisal";
import { AlertVariants } from "../../context/Alert/AlertContext";

export const useAdminFetch = () => {
    const { setTypeGlobalAlert, setShowGlobalAlert, setMessageGlobalAlert } = useAlert()

    const { authFetch} = useFetch()


    const getAdminSales = async ( page : number,limit : number, status_filter? : string, user_search? : string ) => {

        let result: any = {
            success: false,
            data: [],
            message: '',
            goLogin: false
        }

        try {
            let params: RequestInit = {
                method: "POST",
                body : JSON.stringify({
                    page ,
                    limit,
                    status_filter,
                    user_search                    
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache'
                },
            }
    
            const response = await authFetch(
                process.env.REACT_APP_AUTH_API_URL + '/api/admin/sales/get-sales',
                params
            )
            

            if (response.error) {
                if (response.message && response.message !== '') {
                    result.message = response.message
                }
            } else {
                result = response
            }
            return result;

        } catch (error) {
            console.log(error)
            return result;
        }


    }

    const updateSale : (body : UpdateSaleBody) => Promise<generalDataResult> =
        async (body) => {
            let result: generalDataResult = {
                success: false,
                data: [],
                message: ''
            }

            const url = `${process.env.REACT_APP_AUTH_API_URL}/api/admin/sales/update-sale`
            
            const form_data = new FormData()

            form_data.append('sale_id',body.sale_id.toString())
            if(body.user_id) form_data.append('user_id',body.user_id.toString())
            if(body.project_id) form_data.append('project_id',body.project_id.toString())
            if(body.unit_id) form_data.append('unit_id',body.unit_id.toString())
            if(body.garage_id) form_data.append('garage_id',body.garage_id.toString())
            if(body.unit_price_currency) form_data.append('unit_price_currency',body.unit_price_currency.toString())
            if(body.unit_price) form_data.append('unit_price',body.unit_price.toString())
            if(body.commission_currency) form_data.append('commission_currency',body.commission_currency.toString())
            if(body.commission) form_data.append('commission',body.commission.toString())
            if(body.garage_price_currency) form_data.append('garage_price_currency',body.garage_price_currency.toString())
            if(body.garage_price) form_data.append('garage_price',body.garage_price.toString())
            if(body.commission_percentage) form_data.append('commission_percentage',body.commission_percentage.toString())
            if(body.operation_expenses_percentage) form_data.append('operation_expenses_percentage',body.operation_expenses_percentage.toString())
            if(body.status) form_data.append('status',body.status.toString())
            if(body.delete_bill_file) form_data.append('delete_bill_file',body.delete_bill_file.toString())
            if(body.delete_voucher_file) form_data.append('delete_voucher_file',body.delete_voucher_file.toString())
            if(body.bill_f) form_data.append('bill_f',body.bill_f)
            if(body.voucher_f) form_data.append('voucher_f',body.voucher_f)
            if(body.total_usd) form_data.append('total_usd',body.total_usd.toString())
            if(body.project_name) form_data.append('project_name',body.project_name.toString())
            if(body.unit_name) form_data.append('unit_name',body.unit_name.toString())
            if(body.garage_name) form_data.append('garage_name',body.garage_name.toString())
            if(body.sale_date) form_data.append('sale_date',body.sale_date.toString())
            
        
            try {

                await authFetch(url,
                    {
                        cache: 'no-cache',
                        method : 'POST',
                        body : form_data,
                        headers: {
                            'Cache-Control': 'no-cache'
                        },
                    }
                )
                .then((response) => {
                        if (response.error) {
                            if (response.message && response.message !== '') {
                                result.message = response.message
                            }
                        } else result = response

                })
            } catch (error) {
                console.error(error)
            }finally{
                return result
            }

    }

    const getProjectsForSale: () => Promise<interfaceObjectData> =
        async () => {

            let result: interfaceObjectData = {
                success: false,
                data: [],
                message: '',
                goLogin: false
            }


            const url = `${process.env.REACT_APP_AUTH_API_URL}/api/projects/get-projects-basic`
        

            try {

                const res = await authFetch(url,
                    {
                        cache: 'no-cache',
                        method : 'POST',
                        body : JSON.stringify({list_inactives : true}),
                        headers: {
                            'Content-Type': 'application/json',
                            'Cache-Control': 'no-cache'
                        },
                    }
                )
                    .then((properties) => {

                        if (properties.error) {
                            if (properties.message && properties.message !== '') {
                                result.message = properties.message
                            }
                        } else {                           
                            result = properties
                        }

                    })

                return result
            } catch (error) {
                console.log(error)
                return result
            }

    }

    const getProjectByIdentifierForSales = async (identifier: string, operationType? : string) => {
        let result: projectByIdentifier = {
            success: false,
            data: undefined,
            message: '',
            goLogin: false
        }

        const url = `${process.env.REACT_APP_AUTH_API_URL}/api/admin/sales/get-project-for-sales`

        const body = {
            identifier : identifier,
            operation_type : operationType
        }
        
        try {
            await authFetch(
                url,
                {
                    cache: 'no-cache',
                    method : 'POST',
                    body : JSON.stringify(body),
                    headers: {
                        'Content-Type': 'application/json',
                        'Cache-Control': 'no-cache'
                    },
                },
            )
                .then((property) => {
                    
                    if(!property){
                        return result
                    }
                    if (property.error) {
                        if (property.message && property.message !== '') {
                            result.message = property.message
                        }
                    } else {
                        result = property
                    }

                })
        } catch (error) {
            console.log(error)
            result.message = 'Ocurrió un error'
        }

        return result
    }

    const getUsersForSales: () => Promise<GetUsersResponse> =
        async () => {

            let result: GetUsersResponse = {
                success: false,
                data: [],
                message: ''
            }


            const url = `${process.env.REACT_APP_AUTH_API_URL}/api/admin/sales/get-users-for-sales`
        

            try {

                const res = await authFetch(url,
                    {
                        cache: 'no-cache',
                        headers: {
                            'Content-Type': 'application/json',
                            'Cache-Control': 'no-cache'
                        },
                    }
                )
                    .then((properties) => {

                        if (properties.error) {
                            if (properties.message && properties.message !== '') {
                                result.message = properties.message
                            }
                        } else {                           
                            result = properties
                        }

                    })

                return result
            } catch (error) {
                console.log(error)
                return result
            }

    }

    const registerSale : (body : any) => Promise<generalDataResult> =
        async (body) => {

            let result: generalDataResult = {
                success: false,
                data: [],
                message: ''
            }

            const url = `${process.env.REACT_APP_AUTH_API_URL}/api/admin/sales/register-sale`
            
            const form_data = new FormData()

            if(body.user_id) form_data.append('user_id',body.user_id.toString())
            if(body.project_id) form_data.append('project_id',body.project_id.toString())
            if(body.unit_id) form_data.append('unit_id',body.unit_id.toString())
            if(body.garage_id) form_data.append('garage_id',body.garage_id.toString())
            if(body.unit_price_currency) form_data.append('unit_price_currency',body.unit_price_currency.toString())
            if(body.unit_price  != null) form_data.append('unit_price',body.unit_price.toString())
            if(body.commission_currency) form_data.append('commission_currency',body.commission_currency.toString())
            if(body.commission != null) form_data.append('commission',body.commission.toString())
            if(body.garage_price_currency) form_data.append('garage_price_currency',body.garage_price_currency.toString())
            if(body.garage_price != null) form_data.append('garage_price',body.garage_price.toString())
            if(body.commission_percentage) form_data.append('commission_percentage',body.commission_percentage.toString())
            if(body.operation_expenses_percentage) form_data.append('operation_expenses_percentage',body.operation_expenses_percentage.toString())
            if(body.status) form_data.append('status',body.status.toString())
            if(body.delete_bill_file) form_data.append('delete_bill_file',body.delete_bill_file.toString())
            if(body.delete_voucher_file) form_data.append('delete_voucher_file',body.delete_voucher_file.toString())
            if(body.bill_f) form_data.append('bill_f',body.bill_f)
            if(body.voucher_f) form_data.append('voucher_f',body.voucher_f)
            if(body.total_usd != null) form_data.append('total_usd',body.total_usd.toString())
            if(body.project_name) form_data.append('project_name',body.project_name.toString())
            if(body.unit_name) form_data.append('unit_name',body.unit_name.toString())
            if(body.garage_name) form_data.append('garage_name',body.garage_name.toString())
            if(body.sale_date) form_data.append('sale_date',body.sale_date.toString())
            
            try {

                await authFetch(url,
                    {
                        cache: 'no-cache',
                        method : 'POST',
                        body : form_data,
                        headers: {
                            'Cache-Control': 'no-cache'
                        },
                    }
                )
                .then((response) => {
                        if (response.error) {
                            if (response.message && response.message !== '') {
                                result.message = response.message
                            }
                        } else result = response

                })
            } catch (error) {
                console.error(error)
            }finally{
                return result
            }


    }

    const getAdminSaleById : (sale_id : string) => Promise<GetSaleResponse> = async ( sale_id : string) => {

        let result: GetSaleResponse = {
            success: false,
            data: undefined,
            message: ''
        }

        try {
            let params: RequestInit = {
                method: "POST",
                body : JSON.stringify({sale_id}),
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache'
                },
            }
    
            const response = await authFetch(
                process.env.REACT_APP_AUTH_API_URL + '/api/admin/sales/get-sale',
                params
            )
            

            if (response.error) {
                if (response.message && response.message !== '') {
                    result.message = response.message
                }
            } else {
                result = response
            }
            return result;

        } catch (error) {
            console.log(error)
            return result;
        }


    }

    const deleteSaleById : (sale_id : string) => Promise<GetSaleResponse> = async ( sale_id : string) => {

        let result: GetSaleResponse = {
            success: false,
            data: undefined,
            message: ''
        }

        try {
            let params: RequestInit = {
                method: "POST",
                body : JSON.stringify({sale_id}),
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache'
                },
            }
    
            const response = await authFetch(
                process.env.REACT_APP_AUTH_API_URL + '/api/admin/sales/delete-sale',
                params
            )
            

            if (response.error) {
                if (response.message && response.message !== '') {
                    result.message = response.message
                }
            } else {
                result = response
            }
            return result;

        } catch (error) {
            console.log(error)
            return result;
        }


    }

    const exportSales = async ( page : number,limit : number, status_filter? : string, user_search? : string ) => {

        let result: any = {
            success: false,
            data: [],
            message: '',
            goLogin: false
        }

        try {
            let params: RequestInit = {
                method: "POST",
                body : JSON.stringify({
                    page ,
                    limit,
                    status_filter,
                    user_search                    
                }),
            
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache'
                },
            }
    
            
            const response = await authFetch(
                process.env.REACT_APP_AUTH_API_URL + '/api/admin/sales/export-sales-excel',
                params, true
            ).then(res => {
                result.data = res
                result.success = true
            }).catch(err => {
                console.error("OCURRIÓ ERROR")
            })
            

            return result

        } catch (error) {
            console.log(error)
            return result;
        }


    }

    const getAdminAppraisals
    = async ( page : number,limit : number, status_filter? : string, user_search? : string ) => {

        let result: GetAppraisalsResponse = {
            success: false,
            data: [],
            message: '',
            total : 0,
            total_filter : 0
        }

        try {
            let params: RequestInit = {
                method: "POST",
                body : JSON.stringify({
                    page ,
                    limit,
                    status_filter,
                    user_search                    
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache'
                },
            }
    
            const response = await authFetch(
                process.env.REACT_APP_AUTH_API_URL + '/api/admin/appraisal/get-appraisals',
                params
            )
            

            if (response.error) {
                if (response.message && response.message !== '') {
                    result.message = response.message
                }
            } else {
                result = response
            }
            return result;

        } catch (error) {
            console.log(error)
            return result;
        }


    }

    const getAdminAppraisalById : (appraisal_id : number) => Promise<GetAppraisalResponse> = async ( appraisal_id : number) => {

        let result = {
            success: false,
            data: undefined,
            message: ''
        }

        try {
            let params: RequestInit = {
                method: "POST",
                body : JSON.stringify({id : appraisal_id}),
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache'
                },
            }
    
            const response = await authFetch(
                process.env.REACT_APP_AUTH_API_URL + '/api/admin/appraisal/get-appraisal',
                params
            )
            

            if (response.error) {
                if (response.message && response.message !== '') {
                    result.message = response.message
                }
            } else {
                result = response
            }
            return result;

        } catch (error) {
            console.log(error)
            return result;
        }


    }

    const updateAppraisal : (body : UpdateAppraisalBody) => Promise<generalDataResult> =
        async (body) => {
            let result: generalDataResult = {
                success: false,
                data: [],
                message: ''
            }

            const url = `${process.env.REACT_APP_AUTH_API_URL}/api/admin/appraisal/update-appraisal`
            
            const form_data = new FormData()

            form_data.append('id',body.id.toString())
            if(body.status) form_data.append('status',body.status.toString())
            if(body.appraisal_f) form_data.append('appraisal_f',body.appraisal_f)
            if(body.voucher_f) form_data.append('voucher_f',body.voucher_f)
            if(body.delete_appraisal_file) form_data.append('delete_appraisal_file',body.delete_appraisal_file.toString())
            if(body.delete_voucher_file) form_data.append('delete_voucher_file',body.delete_voucher_file.toString())
            
        
            try {

                await authFetch(url,
                    {
                        cache: 'no-cache',
                        method : 'POST',
                        body : form_data,
                        headers: {
                            'Cache-Control': 'no-cache'
                        },
                    }
                )
                .then((response) => {
                        if (response.error) {
                            if (response.message && response.message !== '') {
                                result.message = response.message
                            }
                        } else result = response

                })
            } catch (error) {
                console.error(error)
            }finally{
                return result
            }

    }

    const exportAppraisals = async ( page : number,limit : number, status_filter? : string, user_search? : string ) => {

        let result: any = {
            success: false,
            data: [],
            message: '',
            goLogin: false
        }

        try {
            let params: RequestInit = {
                method: "POST",
                body : JSON.stringify({
                    page ,
                    limit,
                    status_filter,
                    user_search                    
                }),
            
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache'
                },
            }
    
            
            const response = await authFetch(
                process.env.REACT_APP_AUTH_API_URL + '/api/admin/appraisal/export-excel',
                params, true
            ).then(res => {
                result.data = res
                result.success = true
            }).catch(err => {
                console.error("OCURRIÓ ERROR")
            })
            

            return result

        } catch (error) {
            console.log(error)
            return result;
        }


    }



    const showGlobalAlert = (type: AlertVariants, message: string) => {
        setTypeGlobalAlert(type)
        setMessageGlobalAlert(message)
        setShowGlobalAlert(true)
        setTimeout(() => {
            setShowGlobalAlert(false)
            setTypeGlobalAlert('success')
            setMessageGlobalAlert('')
        }, 3000);

    }


    return {
        getAdminSales,
        updateSale,
        getProjectsForSale,
        getUsersForSales,
        getProjectByIdentifierForSales,
        registerSale,
        getAdminSaleById,
        deleteSaleById,
        exportSales,
        getAdminAppraisals,
        getAdminAppraisalById,
        updateAppraisal,
        exportAppraisals
    }
}