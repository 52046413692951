
import { useEffect, useState } from "react"
import TitleCard from "../../daisyUI/components/Cards/TitleCardTs"
import { useUserFetch } from "../../hooks/fetch/useUserFetch"
import { UserSale } from "../../interfaces/user"
import "../../fragments/user/activity/UserOffers"
import UserSalesList from "../../fragments/user/sales/UserSalesList"
import CircleStackIcon from "@heroicons/react/24/outline/CircleStackIcon"
import DashboardStats from "../../components/User/Sales/DashboardStats"
import { formatMoney } from "../../utils/Functions"
import { IconBuilding } from "../../components/CustomIcons/IconBuilding"
import { IconCoin } from "../../components/CustomIcons/IconCoin"
import { IconCheckCircle } from "../../components/CustomIcons/IconCheckCircle"
import SearchBar from "../../daisyUI/components/Input/SearchBar"
import { useGoogleAnalytics } from "../../hooks/googleAnalytics/useGoogleAnalytics";
import useUser from "../../hooks/user/useUser";

const TopSideButtons = ({ applySearch, className ,statsData}:
    { applySearch: any, className?: string, statsData : {
        [key : string] : {
            title : string
            value : string | number
            icon : any
            description : string
        }
    } }) => {

    const [searchText, setSearchText] = useState("")

    useEffect(() => {
        applySearch(searchText)
    }, [searchText])

    return (
        <div className={"tw-inline-block  " + className}>
            <div className="tw-divider divider-show" ></div>
            <div className="tw-hidden md:tw-grid lg:tw-grid-cols-4 mt-2 md:tw-grid-cols-2 tw-grid-cols-1 tw-gap-6 tw-mb-4 mt-4">
                    {
                        Object.values(statsData).map((d, k) => {
                            return (
                                <DashboardStats key={k} {...d} colorIndex={k}/>
                            )
                        })
                    }
                </div>
            <div className="tw-float-right mt-4">
                <SearchBar searchText={searchText} styleClass="tw-mr-4 w-100" setSearchText={setSearchText} placeholderText={'Buscar proyecto'} />
            </div>
            
            
        </div>
    )
}

const  UserSales =() => {

    const GA = useGoogleAnalytics(true);
    const {userState} = useUser()
    useEffect(()=> {
        GA.Event({ category: "User open sales", action: "user_open_sales", label: `${userState.name}`, value: Number(userState.id) });
    },[])

    const LIMIT = 10
    const INITIAL_PAGE = 1
    const { getSales} = useUserFetch()
    const [sales, setSales] = useState<UserSale[]>([])
    const [loading, setLoading] = useState(false)
    const [totalPages, setTotalPages] = useState<number>()
    const [hasSome , setHasSome] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    

    const initialStats = {
        'total_projects' : {title : "Proyectos Vendidos", value : "0", icon : <IconBuilding width={25}/>, description : ""},
        'total_price' : {title : "Total Vendido", value : "USD 0,00", icon : <IconCoin  width={25}/>, description : ""},
        'total_commission' : {title : "Total Comisiones", value : "USD 0,00", icon : <CircleStackIcon className='tw-w-8 tw-h-8 text-primary'/>, description : ""},
        'pay_commission' : {title : "Comisión Cobrada", value : "USD 0,00", icon : <IconCheckCircle width={25}/>, description : ""},
    }
    
    const [statsData, setStatsData] = useState<{
        [key : string] : {
            title : string
            value : string | number
            icon : any
            description : string
        }
    }>(initialStats)
    
    useEffect(() => {
        setLoading(true)
        loadSales(true,'','', LIMIT, INITIAL_PAGE)
        
    }, [])


    const loadSales = async (firstLoad : boolean, status_filter?: string ,search?: string, loadLimit?: number, page?: number) => {

        return getSales(search, status_filter, loadLimit, page).then(response => {
            setLoading(false)
            if (response.success) {
                setSales(response.data)
                if (!loadLimit) loadLimit = LIMIT
                let total_pages = Math.ceil(response.total / loadLimit)
                setTotalPages(total_pages)

                if(response.data.length > 0 && firstLoad) {
                    setHasSome(true)
                    setStatsData((prev ) => {
                        return {
                            ...prev,
                            total_projects : {
                                ...prev.total_projects,
                                value : response.totals.total_projects
                            },
                            total_price : {
                                ...prev.total_price,
                                value : 'USD '+ formatMoney(response.totals.total_price)
                            },
                            total_commission : {
                                ...prev.total_commission,
                                value : 'USD '+ formatMoney(response.totals.total_commission)
                            },
                            pay_commission : {
                                ...prev.pay_commission,
                                value : 'USD '+ formatMoney(response.totals.pay_commission)
                            }
                        }
                    })
                }

            } else {
                setSales([])
                setTotalPages(0)
            }
            return true
        }).catch(err => {
            console.error(err)
            setLoading(false)
        })
    }

    const applySearch = (value: any) => {
        setSearchTerm(value)
        loadSales(false, '', value, LIMIT, INITIAL_PAGE)
    }


    return (
        <>
            <div className={`user-sales ${(!sales || sales.length == 0) && !loading ? 'empty' : ''}`}>
                <TitleCard title={'Ventas Realizadas'} topMargin="tw-pt-4 tw-border " TopSideButtons={
                    <TopSideButtons applySearch={applySearch} className={loading || !hasSome ? ' tw-hidden ' : ''}
                    statsData={statsData} />
                
                }>
                    
                    <UserSalesList sales={sales} loadSales={loadSales} loading={loading} hasSome={hasSome} totalPages={totalPages}
                    searchTerm={searchTerm}/>

                </TitleCard>
            </div>


        </>
    )
}


export default UserSales