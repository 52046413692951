
import { Button, Col, Modal, Row } from "react-bootstrap";
import { PropertyDescription } from "../../components/PropertyDescription/PropertyDescription";

import { CourseCaracteristics } from "../../components/Academy/CourseCaracteristics";
import { Course } from "../../interfaces/academy";

export const CourseResumeSection = ({ course,  }: {
  course: Course
}) => {
  

  return (
    <>
      <Row className="resume-section">
        <Col
          lg={{ span: 7, order: 1 }}
          xs={{ span: 12, order: 3 }}
          className="border-end pe-lg-4 py-4 resume-left"
        >
          {
            course.description &&
            <>
            <h3 className="fs-4 fw-normal mb-3">Descripción</h3>
            <div className="pb-2 pb-lg-0">
               <PropertyDescription description={course.description} />
            </div>
            </>
          }
          
          <Col className="d-lg-none">
            <div className="border-top mt-4 pb-1"></div>
          </Col>

        </Col>
        <Col xs={{ span: 12, order: 2 }} className="d-lg-none">
          <div className="border-bottom my-2"></div>
        </Col>
        <Col
          lg={{ span: 5, order: 2 }}
          xs={{ span: 12, order: 1 }}
          className="ps-lg-4 py-4"
        >
          <h3 className="fs-4 fw-normal mb-3">Características</h3>
          <CourseCaracteristics course={course} />
        </Col>

      </Row>
    </>
  );
};