import DatePicker, { DateObject } from "react-multi-date-picker";
import userFilter from "../../../hooks/filter/useFilter";
import "react-multi-date-picker/styles/colors/teal.css"
import "react-multi-date-picker/styles/layouts/mobile.css"
import InputIcon from "react-multi-date-picker/components/input_icon";
import "./FilterHandOverDate.scss"
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const FilterHandOverDate = () => {
    const { setFilter } = userFilter();
    const { innerWidth } = window;
    const [searchParams] = useSearchParams();
    const [currentHandoverDate, setCurrentHandoverDate] = useState([] as any[]);

    const handeChangeDate = (dates: any) => {
        
        setCurrentHandoverDate(dates)
        if (dates && dates.length > 1) {
            const range: any = []
            dates.forEach((date: any) => {
                // const dateToString = date.toDate()
                //@ts-ignore
                const formattedDate = new DateObject({ date, format: "YYYY-MM-DD" }).format()
                range.push(formattedDate)
            });

            setFilter({
                hand_over_date: range
            })
            
        }

        if (!dates) {
            setFilter({
                hand_over_date: []
            })
            setCurrentHandoverDate([])
        }
    }

    useEffect(() => {
        const parameter = searchParams.get("hand_over_date");
        
        setCurrentHandoverDate(
          !!parameter
            ? parameter.split(',').map(d => new DateObject({ date:d}))
            : ([] as string[])
        );
      }, [searchParams]);


    return (
        <>
            <DatePicker
                onlyMonthPicker
                value={currentHandoverDate}
                range
                //@ts-ignore
                onChange={handeChangeDate}
                className={`teal ${innerWidth < 700 && "rmdp-mobile"}`}
                style={{
                    width: "100%",
                    boxSizing: "border-box"
                }}
                render={<InputIcon />}
                mobileLabels={{
                    OK: "Aceptar",
                    CANCEL: "Cerrar",
                }}
                inputClass="month-picker-custom"
            />
        </>
    )
}