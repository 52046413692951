import { useEffect, useState } from "react";
import { Button, Table,  Form} from "react-bootstrap";

import Lottie from "lottie-react";
import loaderIris from "../../../../lotties/orange-loader.json";
import { formatDateToDMY,formatMoneyDecimals,getDateYMD } from "../../../../utils/Functions";
import "./ListNotarial.scss"
import useAlert from "../../../../hooks/alert/useAlert";
import Pagination from "../../../../components/ui/pagination/Pagination";
import { IconFileEamarkExcelFill } from "../../../../components/CustomIcons/IconFileEamarkExcelFill";
import { AlertVariants } from "../../../../context/Alert/AlertContext";
import { NotarialService } from "../../../../interfaces/services";
import { useNotarialFetch } from "../../../../hooks/fetch/admin/services/notarial/useNotarialFetch";
import { Link } from "react-router-dom";

export const ListNotarials = () => {

    const MAX_PAGES = 4
    const LIMIT = 20
    const LIMIT_EXCEL = 1000
    const [notarials, setNotarials] = useState<NotarialService[]>([])
    const [page, setPage] = useState(1)
    const [searchName, setSearchName] = useState('')
    const [loading, setLoading] = useState(false)
    const { getNotarials , exportNotarials } = useNotarialFetch()
    const [totalAppraisals, setTotalAppraisals] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const { setPositionAlert, setTypeGlobalAlert, setShowGlobalAlert, setMessageGlobalAlert } = useAlert()

    useEffect(() => {
        setLoading(true)
        loadAppraisals(page, LIMIT, '')
    }, [])

    const loadAppraisals = (loadPage: number, loadLimit: number ,searchTerm?: string) => {
        
        setPage(loadPage)
        if(searchTerm) setSearchName(searchTerm)
        
        getNotarials({ page : loadPage, limit : loadLimit ,user_search : searchTerm} ).then(data => {
            setLoading(false)
            if (data.success) {
                setNotarials(data.data)
                setTotalAppraisals(data.total)
                let total_pages = Math.ceil(data.total_filter / loadLimit)
                setTotalPages(total_pages)
            }


        }).catch(err => {
            console.log(err)
        })
    }
    
    const handlePagination = (pageNav: number) => {
        if (pageNav != page) {
            setPage(pageNav)
            loadAppraisals(pageNav, LIMIT)
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }

    }

 
    const showAlertMessage = (type: AlertVariants, message: string) => {
        setPositionAlert('bottom')
        setTypeGlobalAlert(type)
        setMessageGlobalAlert(message)
        setShowGlobalAlert(true)
        setTimeout(() => {
            setShowGlobalAlert(false)
            setTypeGlobalAlert('success')
            setMessageGlobalAlert('')
            setPositionAlert('top')
        }, 3000);

    }

    const delayValue = 250
    let timer: any = 0;
    const searchNameInput = (ev: any) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            //Hacemos algo
            if (ev.target.value.length > 1) {
                loadAppraisals(1,LIMIT,searchName)
            } else if (ev.target.value.length == 0) {
                loadAppraisals(1,LIMIT,'')
            }

        }, delayValue);
    }

    const keyDownHandle = (ev: any) => {
        clearTimeout(timer)
    }

    const exportExcel = () => {

        exportNotarials({page : 1, limit : LIMIT_EXCEL , user_search : searchName}).then(res => {
            
            if(res && res.success){
                const content = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

                const encodedUri = window.URL.createObjectURL(content);
                const link = document.createElement("a");
                let date = getDateYMD()
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", `LISTADO-SERVICIOS-NOTARIALES-${date}.xlsx`);
                link.click();

            }else{
                showAlertMessage('danger', 'Ocurrió un error')
            }
            
        }).catch(err => console.error)
    }

    return (
        <div className="p-5 admin-sales-list">
            <div className="mb-4 d-flex py-2 px-4 justify-content-between flex-wrap position-relative align-items-center">
                <Link to="/admin" className="fs-2 go-back mt-3 ms-3 ms-md-4" title="Volver">
                    <i className="text-light bi bi-arrow-left" ></i>
                </Link>
                <h3 >SERVICIOS NOTARIALES </h3>
            </div>
            <div className="d-flex mb-2 sales-filter align-items-center flex-wrap justify-content-between">
                <div className="d-flex  align-items-center flex-wrap ">
                    <Form.Group className="sales-filter-search me-2 mb-2">
                        <Form.Control placeholder="Buscar inmobiliaria o email..." value={searchName} onKeyUp={searchNameInput} onChange={(ev) => setSearchName(ev.target.value)}
                            onKeyDown={keyDownHandle} />
                    </Form.Group>
                
                </div>

                <div className="export-excel">
                    <Button variant="outline-primary" 
                        onClick={exportExcel}>Exportar <IconFileEamarkExcelFill width='20' /> 
                    </Button>
                </div>
                
            
            </div>
            <div className=" text-end">
            <p className="mb-4 fs-small" style={{fontSize : '14px'}}>Mostrando {notarials.length || 0} de {totalAppraisals}</p>
            </div>
            
            
            <div>
                {
                    notarials?.length > 0 &&
                    <Table bordered hover responsive>
                        <thead>
                            <tr>
                                <th style={{width : 250}}>Inmobiliaria</th>
                                <th>Email</th>
                                <th>Teléfono Inmobiliaria</th>
                                <th>Cliente</th>
                                <th>Teléfono Cliente</th>
                                <th>Financiación Bancaria</th>
                                <th>Monto</th>
                                <th>Fecha</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                notarials.map(notarial => {

                                    //Convertir a fecha de zona de usuario y formatear
                                    const inmoName = notarial.user.real_estate ? notarial.user.name + ' - ' + notarial.user.real_estate : notarial.user.name

                                    return (
                                        <tr key={notarial.id}>
                                            <td>{inmoName}</td>
                                            <td>{notarial.user.email}</td>
                                            <td>{notarial.user.phone}</td>
                                            <td>{notarial.client_name}</td>
                                            <td>{notarial.client_phone}</td>
                                            <td>{notarial.bank_financing} </td>
                                            <td >USD {formatMoneyDecimals(notarial.amount)}</td>
                                            <td>{notarial.created_at ? formatDateToDMY(notarial.created_at,'-') : ''}</td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </Table>
                }
                {
                    (!notarials || loading) &&
                    <div style={{ height: 400 }} className="w-100 d-flex align-items-center justify-content-center"><Lottie animationData={loaderIris} style={{ height: 100 }} loop /></div>
                }
                {notarials?.length > 0 && totalPages && <Pagination currentPage={page} handlePagination={handlePagination} totalPages={totalPages} maxPages={MAX_PAGES} />}

            </div>
            {
                !loading && (!notarials || notarials.length == 0) &&
                <div className="text-center">
                    <hr />
                    <p className="mt-4">No hay ningún registro.</p>
                </div>

            }

        </div>
    )
}