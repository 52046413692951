
export const communesAYC = [
    
{ code : "5401" , name : "SAN ANTONIO" },
{ code : "5402" , name : "SANTO DOMINGO" },
{ code : "5403" , name : "CARTAGENA" },
{ code : "5404" , name : "EL TABO" },
{ code : "5405" , name : "EL QUISCO" },
{ code : "5406" , name : "ALGARROBO" },
{ code : "5501" , name : "QUILLOTA" },
{ code : "5502" , name : "NOGALES" },
{ code : "5503" , name : "HIJUELAS" },
{ code : "5504" , name : "LA CALERA" },
{ code : "5505" , name : "LA CRUZ" },
{ code : "5506" , name : "LIMACHE" },
{ code : "5507" , name : "OLMUÉ" },
{ code : "5601" , name : "SAN FELIPE" },
{ code : "5602" , name : "PANQUEHUE" },
{ code : "5603" , name : "CATEMU" },
{ code : "5604" , name : "PUTAENDO" },
{ code : "5605" , name : "SANTA MARIA" },
{ code : "5606" , name : "LLAY-LLAY" },
{ code : "5701" , name : "LOS ANDES" },
{ code : "5702" , name : "CALLE LARGA" },
{ code : "5703" , name : "SAN ESTEBAN" },
{ code : "5704" , name : "RINCONADA" },
{ code : "6101" , name : "RANCAGUA" },
{ code : "6102" , name : "MACHALÍ" },
{ code : "6103" , name : "GRANEROS" },
{ code : "6104" , name : "SAN FRANCISCO DE MOSTAZAL" },
{ code : "6105" , name : "DOÑIHUE" },
{ code : "6106" , name : "COLTAUCO" },
{ code : "6107" , name : "CODEGUA" },
{ code : "6108" , name : "PEUMO" },
{ code : "6109" , name : "LAS CABRAS" },
{ code : "6110" , name : "SAN VICENTE" },
{ code : "6111" , name : "PICHIDEGUA" },
{ code : "6112" , name : "RENGO" },
{ code : "6113" , name : "REQUÍNOA" },
{ code : "6114" , name : "OLIVAR" },
{ code : "6115" , name : "MALLOA" },
{ code : "6116" , name : "COINCO" },
{ code : "6117" , name : "QUINTA DE TILCOCO" },
{ code : "6201" , name : "SAN FERNANDO" },
{ code : "6202" , name : "CHIMBARONGO" },
{ code : "6203" , name : "NANCAGUA" },
{ code : "6204" , name : "PLACILLA" },
{ code : "6205" , name : "SANTA CRUZ" },
{ code : "6206" , name : "LOLOL" },
{ code : "6207" , name : "PALMILLA" },
{ code : "6208" , name : "PERALILLO" },
{ code : "6209" , name : "CHÉPICA" },
{ code : "6214" , name : "PUMANQUE" },
{ code : "6301" , name : "PICHILEMU" },
{ code : "6302" , name : "NAVIDAD" },
{ code : "6303" , name : "LITUECHE" },
{ code : "6304" , name : "LA ESTRELLA" },
{ code : "6305" , name : "MARCHIGÜE" },
{ code : "6306" , name : "PAREDONES" },
{ code : "7101" , name : "CURICÓ" },
{ code : "7102" , name : "TENO" },
{ code : "7103" , name : "ROMERAL" },
{ code : "7104" , name : "RAUCO" },
{ code : "7105" , name : "LICANTÉN" },
{ code : "7106" , name : "VICHUQUEN" },
{ code : "7107" , name : "HUALAÑE" },
{ code : "7108" , name : "MOLINA" },
{ code : "7109" , name : "SAGRADA FAMILIA" },
{ code : "7201" , name : "TALCA" },
{ code : "7202" , name : "SAN CLEMENTE" },
{ code : "7203" , name : "PELARCO" },
{ code : "7204" , name : "RIO CLARO" },
{ code : "7205" , name : "PENCAHUE" },
{ code : "7206" , name : "MAULE" },
{ code : "7207" , name : "CUREPTO" },
{ code : "7208" , name : "CONSTITUCIÓN" },
{ code : "7209" , name : "EMPEDRADO" },
{ code : "7210" , name : "SAN RAFAEL" },
{ code : "7301" , name : "LINARES" },
{ code : "7302" , name : "YERBAS BUENAS" },
{ code : "7303" , name : "COLBÚN" },
{ code : "7304" , name : "LONGAVI" },
{ code : "7305" , name : "PARRAL" },
{ code : "7306" , name : "RETIRO" },
{ code : "7309" , name : "VILLA ALEGRE" },
{ code : "7310" , name : "SAN JAVIER" },
{ code : "7401" , name : "CAUQUENES" },
{ code : "7402" , name : "PELLUHUE" },
{ code : "7403" , name : "CHANCO" },
{ code : "8101" , name : "CHILLÁN" },
{ code : "8102" , name : "PINTO" },
{ code : "8103" , name : "COIHUECO" },
{ code : "8104" , name : "QUIRIHUE" },
{ code : "8105" , name : "NINHUE" },
{ code : "8106" , name : "PORTEZUELO" },
{ code : "8107" , name : "COBQUECURA" },
{ code : "8108" , name : "TREHUACO" },
{ code : "8109" , name : "SAN CARLOS" },
{ code : "8110" , name : "ÑIQUÉN" },
{ code : "8111" , name : "SAN FABIAN" },
{ code : "8112" , name : "SAN NICOLAS" },
{ code : "8113" , name : "BULNES" },
{ code : "8114" , name : "SAN IGNACIO" },
{ code : "8115" , name : "QUILLON" },
{ code : "8116" , name : "YUNGAY" },
{ code : "8117" , name : "PEMUCO" },
{ code : "8118" , name : "EL CARMEN" },
{ code : "8119" , name : "RANQUIL" },
{ code : "8120" , name : "COELEMU" },
{ code : "8121" , name : "CHILLÁN VIEJO" },
{ code : "8201" , name : "CONCEPCIÓN" },
{ code : "8202" , name : "PENCO" },
{ code : "8203" , name : "HUALQUI" },
{ code : "8204" , name : "FLORIDA" },
{ code : "8205" , name : "TOMÉ" },
{ code : "8206" , name : "TALCAHUANO" },
{ code : "8207" , name : "CORONEL" },
{ code : "8208" , name : "LOTA" },
{ code : "8209" , name : "SANTA JUANA" },
{ code : "8210" , name : "SAN PEDRO DE LA PAZ" },
{ code : "8211" , name : "CHIGUAYANTE" },
{ code : "8212" , name : "HUALPÉN" },
{ code : "8301" , name : "ARAUCO" },
{ code : "8302" , name : "CURANILAHUE" },
{ code : "8303" , name : "LEBU" },
{ code : "8304" , name : "LOS ALAMOS" },
{ code : "8305" , name : "CAÑETE" },
{ code : "8306" , name : "CONTULMO" },
{ code : "8307" , name : "TIRUA" },
{ code : "8401" , name : "LOS ANGELES" },
{ code : "8402" , name : "SANTA BARBARA" },
{ code : "8403" , name : "LAJA" },
{ code : "8404" , name : "QUILLECO" },
{ code : "8405" , name : "NACIMIENTO" },
{ code : "8406" , name : "NEGRETE" },
{ code : "8407" , name : "MULCHEN" },
{ code : "8408" , name : "QUILACO" },
{ code : "8409" , name : "YUMBEL" },
{ code : "8410" , name : "CABRERO" },
{ code : "8411" , name : "SAN ROSENDO" },
{ code : "8412" , name : "TUCAPEL" },
{ code : "8413" , name : "ANTUCO" },
{ code : "8414" , name : "ALTO BIOBIO" },
{ code : "9101" , name : "ANGOL" },
{ code : "9102" , name : "PUREN" },
{ code : "9103" , name : "LOS SAUCES" },
{ code : "9104" , name : "RENAICO" },
{ code : "9105" , name : "COLLIPULLI" },
{ code : "9106" , name : "ERCILLA" },
{ code : "9107" , name : "TRAIGUEN" },
{ code : "9108" , name : "LUMACO" },
{ code : "9109" , name : "VICTORIA" },
{ code : "9110" , name : "CURACAUTÍN" },
{ code : "9111" , name : "LONQUIMAY" },
{ code : "9201" , name : "TEMUCO" },
{ code : "9202" , name : "VILCÚN" },
{ code : "9203" , name : "FREIRE" },
{ code : "9204" , name : "CUNCO" },
{ code : "9205" , name : "LAUTARO" },
{ code : "9206" , name : "PERQUENCO" },
{ code : "9207" , name : "GALVARINO" },
{ code : "9208" , name : "NUEVA IMPERIAL" },
{ code : "9209" , name : "CARAHUE" },
{ code : "9210" , name : "PUERTO SAAVEDRA" },
{ code : "9211" , name : "PITRUFQUEN" },
{ code : "9212" , name : "GORBEA" },
{ code : "9213" , name : "TOLTEN" },
{ code : "9214" , name : "LONCOCHE" },
{ code : "9215" , name : "VILLARRICA" },
{ code : "9216" , name : "PUCÓN" },
{ code : "9217" , name : "MELIPEUCO" },
{ code : "9218" , name : "CURARREHUE" },
{ code : "9219" , name : "TEODORO SCHMIDT" },
{ code : "9220" , name : "PADRE LAS CASAS" },
{ code : "9221" , name : "CHOLCHOL" },
{ code : "10101" , name : "VALDIVIA" },
{ code : "10102" , name : "MARIQUINA" },
{ code : "10103" , name : "LANCO" },
{ code : "10104" , name : "LOS LAGOS" },
{ code : "10105" , name : "FUTRONO" },
{ code : "10106" , name : "CORRAL" },
{ code : "10107" , name : "MAFIL" },
{ code : "10108" , name : "PANGUIPULLI" },
{ code : "10109" , name : "LA UNIÓN" },
{ code : "10110" , name : "PAILLACO" },
{ code : "10111" , name : "RÍO BUENO" },
{ code : "10112" , name : "LAGO RANCO" },
{ code : "10201" , name : "OSORNO" },
{ code : "10202" , name : "SAN PABLO" },
{ code : "10203" , name : "PUERTO OCTAY" },
{ code : "10204" , name : "PUYEHUE" },
{ code : "10205" , name : "RIO NEGRO" },
{ code : "10206" , name : "PURRANQUE" },
{ code : "10207" , name : "SAN JUAN DE LA COSTA" },
{ code : "10301" , name : "PUERTO MONTT" },
{ code : "10302" , name : "COCHAMO" },
{ code : "10303" , name : "PUERTO VARAS" },
{ code : "10304" , name : "FRESIA" },
{ code : "10305" , name : "FRUTILLAR" },
{ code : "10306" , name : "LLANQUIHUE" },
{ code : "10307" , name : "MAULLIN" },
{ code : "10308" , name : "LOS MUERMOS" },
{ code : "10309" , name : "CALBUCO" },
{ code : "10401" , name : "CASTRO" },
{ code : "10402" , name : "CHONCHI" },
{ code : "10403" , name : "QUEILEN" },
{ code : "10404" , name : "QUELLON" },
{ code : "10405" , name : "PUQUELDON" },
{ code : "10406" , name : "ANCUD" },
{ code : "10407" , name : "QUEMCHI" },
{ code : "10408" , name : "DALCAHUE" },
{ code : "10410" , name : "CURACO DE VELEZ" },
{ code : "10415" , name : "QUINCHAO" },
{ code : "10501" , name : "CHAITEN" },
{ code : "10502" , name : "HUALAIHUE" },
{ code : "10503" , name : "FUTALEUFU" },
{ code : "10504" , name : "PALENA" },
{ code : "11101" , name : "AISÉN" },
{ code : "11102" , name : "CISNES" },
{ code : "11104" , name : "GUAITECAS" },
{ code : "11201" , name : "CHILE CHICO" },
{ code : "11203" , name : "RIO IBAÑEZ" },
{ code : "11301" , name : "COCHRANE" },
{ code : "11302" , name : "O HIGGINS" },
{ code : "11303" , name : "TORTEL" },
{ code : "11401" , name : "COYHAIQUE" },
{ code : "11402" , name : "LAGO VERDE" },
{ code : "12101" , name : "NATALES" },
{ code : "12103" , name : "TORRES DEL PAINE" },
{ code : "12202" , name : "RIO VERDE" },
{ code : "12204" , name : "SAN GREGORIO" },
{ code : "12205" , name : "PUNTA ARENAS" },
{ code : "12206" , name : "LAGUNA BLANCA" },
{ code : "12301" , name : "PORVENIR" },
{ code : "12302" , name : "PRIMAVERA" },
{ code : "12304" , name : "TIMAUKEL" },
{ code : "12401" , name : "CABO DE HORNOS" },
{ code : "13101" , name : "SANTIAGO" },
{ code : "13133" , name : "SANTIAGO NORTE" },
{ code : "13134" , name : "SANTIAGO OESTE" },
{ code : "13135" , name : "SANTIAGO SUR" },
{ code : "13159" , name : "RECOLETA" },
{ code : "13167" , name : "INDEPENDENCIA" },
{ code : "14107" , name : "QUINTA NORMAL" },
{ code : "14109" , name : "MAIPU" },
{ code : "14111" , name : "PUDAHUEL" },
{ code : "14113" , name : "RENCA" },
{ code : "14114" , name : "QUILICURA" },
{ code : "14127" , name : "CONCHALÍ" },
{ code : "14155" , name : "LO PRADO" },
{ code : "14156" , name : "CERRO NAVIA" },
{ code : "14157" , name : "ESTACION CENTRAL" },
{ code : "14158" , name : "HUECHURABA" },
{ code : "14166" , name : "CERRILLOS" },
{ code : "14201" , name : "COLINA" },
{ code : "14202" , name : "LAMPA" },
{ code : "14203" , name : "TIL-TIL" },
{ code : "14501" , name : "TALAGANTE" },
{ code : "14502" , name : "ISLA DE MAIPO" },
{ code : "14503" , name : "EL MONTE" },
{ code : "14504" , name : "PEÑAFLOR" },
{ code : "14505" , name : "PADRE HURTADO" },
{ code : "14601" , name : "MELIPILLA" },
{ code : "14602" , name : "MARIA PINTO" },
{ code : "14603" , name : "CURACAVÍ" },
{ code : "14604" , name : "SAN PEDRO" },
{ code : "14605" , name : "ALHUE" },
{ code : "15103" , name : "PROVIDENCIA" },
{ code : "15105" , name : "ÑUÑOA" },
{ code : "15108" , name : "LAS CONDES" },
{ code : "15128" , name : "LA FLORIDA" },
{ code : "15132" , name : "LA REINA" },
{ code : "15151" , name : "MACUL" },
{ code : "15152" , name : "PEÑALOLEN" },
{ code : "15160" , name : "VITACURA" },
{ code : "15161" , name : "LO BARNECHEA" },
{ code : "16106" , name : "SAN MIGUEL" },
{ code : "16110" , name : "LA CISTERNA" },
{ code : "16131" , name : "LA GRANJA" },
{ code : "16153" , name : "SAN RAMÓN" },
{ code : "16154" , name : "LA PINTANA" },
{ code : "16162" , name : "PEDRO AGUIRRE CERDA" },
{ code : "16163" , name : "SAN JOAQUIN" },
{ code : "16164" , name : "LO ESPEJO" },
{ code : "16165" , name : "EL BOSQUE" },
{ code : "16301" , name : "PUENTE ALTO" },
{ code : "16302" , name : "PIRQUE" },
{ code : "16303" , name : "SAN JOSÉ DE MAIPO" },
{ code : "16401" , name : "SAN BERNARDO" },
{ code : "16402" , name : "CALERA DE TANGO" },
{ code : "16403" , name : "BUIN" },
{ code : "16404" , name : "PAINE" },
{ code : "1101" , name : "ARICA" },
{ code : "1106" , name : "CAMARONES" },
{ code : "1201" , name : "IQUIQUE" },
{ code : "1203" , name : "PICA" },
{ code : "1204" , name : "POZO ALMONTE" },
{ code : "1206" , name : "HUARA" },
{ code : "1208" , name : "CAMINA" },
{ code : "1210" , name : "COLCHANE" },
{ code : "1211" , name : "ALTO HOSPICIO" },
{ code : "1301" , name : "PUTRE" },
{ code : "1302" , name : "GENERAL LAGOS" },
{ code : "2101" , name : "TOCOPILLA" },
{ code : "2103" , name : "MARIA ELENA" },
{ code : "2201" , name : "ANTOFAGASTA" },
{ code : "2202" , name : "TALTAL" },
{ code : "2203" , name : "MEJILLONES" },
{ code : "2206" , name : "SIERRA GORDA" },
{ code : "2301" , name : "CALAMA" },
{ code : "2302" , name : "OLLAGAÑE" },
{ code : "2303" , name : "SAN PEDRO DE ATACAMA" },
{ code : "3101" , name : "CHAÑARAL" },
{ code : "3102" , name : "DIEGO DE ALMAGRO" },
{ code : "3201" , name : "COPIAPÓ" },
{ code : "3202" , name : "CALDERA" },
{ code : "3203" , name : "TIERRA AMARILLA" },
{ code : "3301" , name : "VALLENAR" },
{ code : "3302" , name : "FREIRINA" },
{ code : "3303" , name : "HUASCO" },
{ code : "3304" , name : "ALTO DEL CARMEN" },
{ code : "4101" , name : "LA SERENA" },
{ code : "4102" , name : "LA HIGUERA" },
{ code : "4103" , name : "COQUIMBO" },
{ code : "4104" , name : "ANDACOLLO" },
{ code : "4105" , name : "VICUÑA" },
{ code : "4106" , name : "PAIHUANO" },
{ code : "4201" , name : "OVALLE" },
{ code : "4203" , name : "MONTE PATRIA" },
{ code : "4204" , name : "PUNITAQUI" },
{ code : "4205" , name : "COMBARBALA" },
{ code : "4206" , name : "RIO HURTADO" },
{ code : "4301" , name : "ILLAPEL" },
{ code : "4302" , name : "SALAMANCA" },
{ code : "4303" , name : "LOS VILOS" },
{ code : "4304" , name : "CANELA" },
{ code : "5101" , name : "ISLA DE PASCUA" },
{ code : "5201" , name : "LA LIGUA" },
{ code : "5202" , name : "PETORCA" },
{ code : "5203" , name : "CABILDO" },
{ code : "5204" , name : "ZAPALLAR" },
{ code : "5205" , name : "PAPUDO" },
{ code : "5301" , name : "VALPARAISO" },
{ code : "5302" , name : "VIÑA DEL MAR" },
{ code : "5303" , name : "VILLA ALEMANA" },
{ code : "5304" , name : "QUILPUÉ" },
{ code : "5305" , name : "CASABLANCA" },
{ code : "5306" , name : "QUINTERO" },
{ code : "5307" , name : "PUCHUNCAVÍ" },
{ code : "5308" , name : "JUAN FERNANDEZ" },
{ code : "5309" , name : "CONCÓN" },
]