import { memo, useEffect, useState } from "react";
import { Button, Col, Container, ProgressBar, Row, Alert, Carousel } from "react-bootstrap";
import { Link, useSearchParams, useLocation } from "react-router-dom";
import useBreakpoint from "use-breakpoint";
import { Empty } from "../../components/Empty/Empty";
import { OverlayLevelBlock } from "../../components/OverlayLevelBlock/OverlayLevelBlock";
import { PromoModal } from "../../components/PromoModal/PromoModal";
import { PropertyCard } from "../../components/PropertyCard/PropertyCard";
import { PropertyTable } from "../../components/PropertyTable/PropertyTable";
import { SkeletonPropertyCard } from "../../components/Skeleton/SkeletonPropertyCard";
import { SkeletonPropertyList } from "../../components/Skeleton/SkeletonPropertyList";
import { TogglePropertyList } from "../../components/TogglePropertyList/TogglePropertyList";
import userFilter from "../../hooks/filter/useFilter";
import { useGoogleAnalytics } from "../../hooks/googleAnalytics/useGoogleAnalytics";
import useUser from "../../hooks/user/useUser";
import { Filter, SpecificFilter } from "../../interfaces/filter";
import { BREAKPOINTS } from "../../utils/Constants";
import { formatMoney } from "../../utils/Functions";
import "./../../styles/list.scss";
import { ListHeader } from "./ListHeader";
import { useCookies } from "../../hooks/cookies/useCookies"
import useCountry from "../../hooks/config/useConfig";

import Lottie from "lottie-react";
import GrayLoader from "../../lotties/gray-loader.json";

import { useFetch } from "../../hooks/fetch/useFetch"
import { PreHeader } from "../../components/PreHeader/PreHeader";
import { CarouselColombia } from "../../components/PreHeader/CarouselColombia";
import { ProjectType } from "../../interfaces/project";
import {ImageModal} from "../../components/PromoModal/ImageModal"
import { PopupData } from "../../interfaces/ui";
import { RandomImagePopup } from "../../components/PromoModal/RandomImagePopup";
import { CarouselPreheaders } from "../../components/PreHeader/CarouselPreheaders";
import { PreheaderMain } from "../../components/PreHeader/PreheaderMain";
import { PopupMain } from "../../components/PromoModal/PopupMain";

export const List = ( { specificFilter  } : { specificFilter? : SpecificFilter }) => {

  const { userState } = useUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const { filterState ,setFilter} = userFilter();
  const [propertyTotal, setPropertyTotal] = useState(0);
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");
  const [open, setOpen] = useState(false);
  const pagination = 8;
  const [visible, setVisible] = useState(0);
  const GA = useGoogleAnalytics(true);
  const [listType, setListType] = useState<"grid" | "list">(window.localStorage.getItem("ListType") as "grid" | "list" ?? "grid");
  const [operationType, setOperationType] = useState(window.localStorage.getItem("OperationType") as "sale" | "rent" ?? "sale");
  const [operationLabel, setOperationLabel] = useState<any>();
  const { getCookie, setCookie } = useCookies();
  const [modal, setModal] = useState(false);
  const [showImgModal, setShowImgModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState('success');
  const [messageAlert, setMessageAlert] = useState("");
  const { pathname } = useLocation();

  const [isLoadingProps, setIsLoadingProps] = useState(true);
  const [loadingMore, setLoadingMore] = useState(true);
  const { country } = useCountry();
  const { getListProjects } = useFetch()
  
  const [projects , setProjects] = useState<ProjectType[]>([])
  
  const LIMIT_PROJECTS = 12;
  const [page , setPage] = useState(1)

  const [timer,setTimer] = useState<any>(0)

  // const PopupMainMemo = memo(()=> {
  //   return <PopupMain />
  // },);


  useEffect(() => {
    GA.Event({ category: "Project list", action: "project_list_open", label: `${userState.name}`, value: Number(userState.id) });
    const source = searchParams.get("source")

    if (!!source) {
      GA.Event({ category: "User comes from Panel", action: `user_comes_from_${source}`, label: `${userState.name}`, value: Number(userState.id) });
    }
  }, [])


  const delayValue = 500

  useEffect(()=>{

    if(!specificFilter?.use || (specificFilter?.use && specificFilter?.filter)){
      
      clearTimeout(timer)
      setTimer(0)
      setTimer(setTimeout(async () => {
          setIsLoadingProps(true)
          await loadProjects(1,LIMIT_PROJECTS,false)
          
      }, delayValue))
      setPage(1)
      
    }
    
    
  },[filterState,operationType, specificFilter])

  useEffect(()=>{
    if (operationType) {
      const op = operationType == 'sale' ? 'Venta' : (operationType == 'rent' ? 'Alquiler' : '')
      if (op != '') {
        setOperationLabel('?operation=' + op)
      }
    }
  },[operationType])

  useEffect(()=>{

    if( specificFilter?.use ){

      if(!specificFilter.loading){
        if(specificFilter?.filter) {
          setFilter(specificFilter?.filter)
        }else{
          //We couldnt load the filter, so stop loading and show empty list of projects
          setIsLoadingProps(false)
        }
      }

    }else{

      clearTimeout(timer)
      setTimer(0)
      setTimer(setTimeout(async () => {
          setIsLoadingProps(true)
          await loadProjects(page,LIMIT_PROJECTS,false)
          
      }, delayValue))
      
      setPage(1)
    }
    
    
  },[specificFilter])

  useEffect(() => {
    if (!getCookie("eventIris04_24") && (country == 'UY') ) {
      const now = new Date();
      const time = now.getTime();
      const expireTime = time + 86400 * 1000; //24h
      now.setTime(expireTime);
      const expires = '=ok;expires=' + now.toUTCString() + ';path=/;SameSite=none;Secure';

      setCookie("eventIris04_24", expires)
      setModal(true);
    } else {
      setModal(false)
    };
  }, [country])


  //effect para query strings
  useEffect(() => {
    
    let searchParamsObj : {[k:string]:any} = {}
    let allFilterKeys = ['location','propertyType','minPrice','maxPrice','state','rooms','bathrooms','amenities','order',
    'financing','launching','garanteed_income','social_housing','hand_over_date','neighbourhood','search','subsidy',
    'commune','units_rented','currency_minmax','developers']
    
    searchParams.forEach((v,k : string)=>{
      searchParamsObj[k] = v
    })

    //Vaciamos por defecto los query del filtro (excepto país que se elige aparte)
    allFilterKeys.forEach(k=> {
      searchParamsObj[k] = null
    })

    //Luego asignamos del filter
    Object.entries(filterState)
    .map(([k, v]) => {
      searchParamsObj[k] = v
    })
    
    let stringSearch = Object.entries(searchParamsObj)
    .map(([k,v])=> {
      return v && ((typeof v === "number" && v.toString().length) || v.length || (typeof v === "boolean" && v))
        ? k + "=" + v
        : ""
    }).join("&")

    setSearchParams(stringSearch);

  }, [filterState, setSearchParams]);

  const loadProjects = async (page : number ,limit : number,addToCurrent : boolean) => {

    return getListProjects(filterState,limit,page, [] ,operationType).then(response => {

        setIsLoadingProps(false)
        setLoadingMore(false)

        if(response.success && response.data){
          
          if(addToCurrent){
            setProjects(prev => [...prev,...response.data])
            setVisible(prev => prev + response.total_listed)
          }else{
            setProjects(response.data)
            setVisible(response.total_listed)
          }
          
          setPropertyTotal(response.total)
          
        }

        return response
        
      }).catch(err => {
        setIsLoadingProps(false)
        console.log(err)
      })

    
  }

  const loadMore = async () => {
    setLoadingMore(true)
    await loadProjects(page + 1,LIMIT_PROJECTS,true)
    setPage(prev => prev + 1);
  };

  const PaginationText = ({ classesTitle }: { classesTitle: string }) => (
    <h3 className={classesTitle}>
      Estas viendo{" "}
      <span className="fw-bold">
        {formatMoney(visible)} de {formatMoney(propertyTotal)}
        {visible === 1 ? " oportunidad" : " oportunidades"}
      </span>
    </h3>
  )

  const showAlertMessage = (type: string, message: string) => {
    setTypeAlert(type)
    setMessageAlert(message)
    setShowAlert(true)
    setTimeout(() => {
      setShowAlert(false)
      setTypeAlert('success')
      setMessageAlert('')
    }, 3000);
  }


  const pickListType = (type : "grid" | "list") => {
    
    if(listType != type){

      setIsLoadingProps(true)
      setListType(type)
      if(type == 'grid'){
        setFilter({ order: ["promos","popularity"] })
      }else{
        setFilter({ order: ["promos","addressAsc"] });
      }
    }
    
    
  }


  return (
    <main className="list bg-xs-light">

      <ListHeader total={propertyTotal} open={open} setOpen={setOpen} listType={listType} setListType={pickListType} operationType={operationType} setOperationType={setOperationType} />

      {/* Preheaders */}
      {/* All Preheaders go inside PreheaderMain!*/}
      <PreheaderMain />

      {/* {(pathname === "/proyectos" && country === "CO") && <CarouselColombia /> }  */}

      {/* POPUPS: */}
      {/* All POPUPS go inside PopupMain!*/}
      <PopupMain />


      <Container className={open ? "sidebarOpen" : ""}>
        <Row>
          {(breakpoint === "xs" || breakpoint === "sm" || breakpoint === "md" || breakpoint === "lg") && (
            <Col xs={6} className="my-3 d-flex align-items-center">
              <PaginationText classesTitle={"fs-6 fw-light pagination-custom mb-0 text-start"} />
            </Col>
          )
          }

          {(breakpoint === "xs" || breakpoint === "sm" || breakpoint === "md" || breakpoint === "lg")
            && (
              <Col xs={6} className="my-3 toggles">
                <TogglePropertyList listType={listType} setListType={pickListType} classes="in-list toggle-list" />
                {/* <TogglePropertyOperation operationType={operationType} setOperationType={setOperationType} classes="in-list" /> */}
              </Col>
            )
          }
        </Row>

        {isLoadingProps ?
          listType === "list" ? (
            <SkeletonPropertyList />
          ) : (
            <Row className="gx-2 gy-3 mb-4 mt-1 mt-lg-0">
              {[...Array(pagination)].map((item, index) => (
                <Col
                  className="px-2"
                  xl={open ? "4" : "3"}
                  lg={open ? "6" : "4"}
                  md={open ? "12" : "6"}
                  sm="12"
                  xs="12"
                  key={"list_" + index}
                >
                  <SkeletonPropertyCard />
                </Col>
              ))}
            </Row>
          ) : propertyTotal === 0 ? (
            <div className="empty-search d-flex align-items-center">
              <Empty type="empty" />
            </div>
          ) : propertyTotal > 0 && listType === "grid" ? (
            <>
              <Row className="gx-2 gy-3 mb-4 mt-1 mt-lg-0">
                {projects.map((item, index) => (
                  <Col
                    className="px-2"
                    xl={open ? "4" : "3"}
                    lg={open ? "6" : "4"}
                    md={open ? "12" : "6"}
                    sm="12"
                    xs="12"
                    key={"list_" + index}
                  >
                    <Link
                      to={item.level > Number(userState.level) ? "#" : `/proyecto/${item.identifier}${operationLabel}`}
                      target="_blank"
                      className={`text-decoration-none position-relative d-block ${item.level > Number(userState.level) ? "invalid-link" : ""}`}
                    >
                      {item.level > Number(userState.level) && <OverlayLevelBlock listType={"grid"} requiredLevel={item.level}/>}
                      <PropertyCard data={item} />
                    </Link>
                  </Col>
                ))}
              </Row>

              <Row className="mb-4 mt-5">
                <Col className="align-items-center d-flex flex-column">
                  <PaginationText classesTitle={"fs-5 fw-light mb-3 pagination-custom"} />
                  <ProgressBar
                    className="progress-custom"
                    now={visible}
                    max={propertyTotal}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="justify-content-center d-flex mb-4">
                  <Button
                    disabled={visible >= propertyTotal || loadingMore}
                    onClick={loadMore}
                    className="pt-2"
                    style={{ minWidth: 200 }}
                    children={
                    <div className="d-flex justify-content-center align-items-center position-relative">
                      Cargar más  
                      {loadingMore && <Lottie className="position-absolute loader-btn top-1" animationData={GrayLoader} style={{ height: 40 }} loop />}
                      </div>
                      }
                  />
                </Col>
              </Row>
            </>
          ) : (

            <>
            <PropertyTable propertyList={projects} operationLabel={operationLabel} />
            <Row>
                <Col className="justify-content-center d-flex mb-4">
                  <Button
                    disabled={visible === propertyTotal}
                    onClick={loadMore}
                    className="pt-2"
                    style={{ minWidth: 200 }}
                    children={
                      <div className="d-flex justify-content-center align-items-center position-relative">
                        Cargar más  
                        {loadingMore && <Lottie className="position-absolute loader-btn top-1" animationData={GrayLoader} style={{ height: 40 }} loop />}
                        </div>
                        }
                  />
                </Col>
              </Row>
            </>
            
          )
        }
      </Container>
      <Alert variant={typeAlert} show={showAlert} onClose={() => setShowAlert(false)} dismissible
        className="fixed-alert">
        {messageAlert}
      </Alert>
    </main>
  );
};
