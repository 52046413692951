import { useState, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { PropertyType } from "../../interfaces/property";
import { IconBed } from "../../components/CustomIcons/IconBed";
import { IconShower } from "../../components/CustomIcons/IconShower";
import { formatMoney } from "../../utils/Functions";
import { IconAC } from "../../components/CustomIcons/IconAC";
import { IconBalcony } from "../../components/CustomIcons/IconBalcony";
import { IconForniture } from "../../components/CustomIcons/IconForniture";
import { PropertyAmenities } from "../../components/PropertyAmenities/PropertyAmenities";
import { downloadDocument } from "../../data/api"
import { CustomImage } from "../../components/CustomImage/CustomImage";
import { VirtualTour } from "../../components/VirtualTour/VirtualTour";
import useCountry from "../../hooks/config/useConfig";
import { IconCrop } from "../../components/CustomIcons/IconCrop";
import { IconDownload } from "../../components/CustomIcons/IconDownload";
import { ProjectType, UnitType } from "../../interfaces/project";
import { IconGarage } from "../../components/CustomIcons/IconGarage";

export const UnitModalContent = (
  { unit: u, currencyPrice, propertyTypeId, property }:
    { unit: UnitType, currencyPrice: string, propertyTypeId?: string, property: ProjectType }) => {
  const [showVirtualTour, setShowVirtualTour] = useState(true);
  const { translation, countryFeatures } = useCountry();

  const amenities = [
    {
      title: translation.air_conditioner,
      icon: <IconAC width={19} />,
      show: u.has_ac,
    },
    {
      title: "Balcón",
      icon: <IconBalcony width={20} />,
      show: u.has_balcony,
    },
    {
      title: "Amueblado",
      icon: <IconForniture width={18} />,
      show: u.has_forniture,
    },
  ];

  const extension = u.plan?.split('.').pop() ?? "png";
  const isLand = propertyTypeId === "3";
  const showOutdoorM2 = !isLand && ((property.countryFeatures?.hide_m2_outdoor && u.m2_outdoor && u.m2_outdoor > 0) || !property?.countryFeatures?.hide_m2_outdoor)
  const commonExpensesAprox = (!property.countryFeatures?.common_expenses_exact) ? true : false

  useEffect(() => {

    if (u.virtual_tour && u.virtual_tour !== "") {
      setShowVirtualTour(true);
    }
  }, [])

  return (
    <div className="unit-modal-content bg-white w-100 h-100 rounded-3 text-secondary px-5 px-lg-4 px-xl-5 py-2 py-lg-3 py-xl-4">
      <Row className="d-flex flex-lg-nowrap h-100">
        <Col
          xl="3"
          lg={{ order: 1, span: 5 }}
          xs={{ order: 2, span: 12 }}
          className="flex-shrink-1 mt-4 mt-xl-5 pb-3 pb-lg-0"
        >
          <span className="fw-500 text-center text-lg-start d-block position-relative">
            {isLand ? "Lote" : "Unidad"} {u.number} ·{" "}
            {!isLand ? (u.floor === '0' ? "Planta Baja" : `Piso ${u.floor}`) : ""}
            {
              u.is_promo &&
              <span className="position-absolute bg-warning promo-span">PROMO</span>
            }
          </span>
          {
            u.old_price &&
            <span className="fs-6 text-decoration-line-through text-secondary" style={{ whiteSpace: "nowrap" }}>
              {u.currency} {formatMoney(u.old_price)}
            </span>
          }
          <h2 className="mt-0 fw-bold mb-0 text-center text-lg-start">
            {currencyPrice} {formatMoney(u.price)}
          </h2>
          <div className="d-flex align-items-center mb-2">
            <IconCrop width={16} height={16} />
            <span className="ms-2 text-black-50 text-lowercase">
              {`${u.m2}m² ${isLand ? "totales" : translation.m2_indoor} ${showOutdoorM2 ? `· ${u.m2_outdoor}m² ${translation.m2_outdoor}` : ""}`}
            </span>
          </div>
          {!isLand && (
            <div className="d-flex flex-wrap flex-column flex-lg-row">
              {
                u.tipology != 'Local Comercial' &&
                <div className="d-flex align-items-center mb-2 me-3">
                  <IconBed />
                  <span className="ms-2 text-black-50 text-lowercase">
                    {u.bedrooms > 0
                      ? u.bedrooms > 1
                        ? `${u.bedrooms} ${translation.bedrooms}`
                        : `1 ${translation.single_bedroom}`
                      : translation.studio}
                  </span>
                </div>
              }

              <div className="d-flex align-items-center mb-2">
                <IconShower width={17} />
                <span className="ms-2 text-black-50">
                  {u.bathrooms} {`${u.bathrooms !== 1 ? "baños" : "baño"}`}
                </span>
              </div>
              {
                !!countryFeatures.garages_in_units &&
                <div className="d-flex align-items-center mb-2">
                  <IconGarage width={17} />
                  <span className="ms-2 text-black-50 text-lowercase">
                    {u.garages || '0'} {`${u.garages !== 1 ? translation.garage + 's' : translation.garage}`}
                  </span>
                </div>
              }

              {
                countryFeatures.show_service_room && (u.tipology != 'Local Comercial' && !!u.service_rooms) &&
                <div className="d-flex align-items-center mb-2 me-3">
                  <IconBed />
                  <span className="ms-2 text-black-50">
                    {!!u.service_rooms && 'Cuarto y baño de servicio'}
                  </span>
                </div>
              }

            </div>
          )}
          {/* @ts-ignore */}
          {property.country?.code != 'CL' && u.disposition && u.disposition !== "" && (
            <div className="d-flex align-items-center mb-2">
              <i className="bi bi-arrows-fullscreen"></i>
              <span className="ms-2 text-black-50">
                Disposición: {u.disposition}
              </span>
            </div>
          )}
          {property.country?.code == 'CL' && u.orientation && u.orientation != '' && (
            <div className="d-flex align-items-center mb-2">
              <i className="bi bi-arrows-fullscreen"></i>
              <span className="ms-2 text-black-50">
                Orientación: {u.orientation}
              </span>
            </div>
          )}
          <div className="d-flex align-items-start mb-2">
            <span className="text-black-50">
              {translation.common_expenses}: {u.common_expenses && parseFloat(u.common_expenses) > 0 ? `${u.expenses_currency} ${formatMoney(u.common_expenses)} ${commonExpensesAprox ? 'aprox.' : ''}` : u.common_expenses && parseFloat(u.common_expenses) === -1 ? "Consultar" : "No tiene"}
            </span>
          </div>
          {u.description && u.description !== "" && (<div>
            <span className="text-black-50">
              Descripción:
            </span>
            <p
              className="text-black-50"
              dangerouslySetInnerHTML={{ __html: u.description }}
            ></p>
          </div>)}
          <div className="mt-3">
            <PropertyAmenities amenities={amenities} />
          </div>

        </Col>
        <Col
          xl="9"
          lg={{ order: 2, span: 7 }}
          xs={{ order: 1, span: 12 }}
          className="image flex-grow-1 h-100 py-4 py-lg-0"
        >
          {showVirtualTour && u.virtual_tour && u.virtual_tour !== "" ?
            <VirtualTour url={u.virtual_tour} isModal /> :
            <div className="position-relative">
              <CustomImage
                alt="plano"
                src={(u.plan && u.plan !== "" ? u.plan : "https://cdn1.infocasas.com.uy/web/62bf337c0227f_np-planes.png")}
                width={700}
                height={700}
                classes="w-100 h-100"
                styles={{ objectFit: "contain", maxHeight: "60vh", marginBottom: 20 }}
              />
              {
                u.plan && u.plan !== "" &&
                <Button className="btn-download-plane fw-normal text-secondary lh-1 px-0 px-lg-2 bg-white" variant="outline-light" onClick={() => { if (u.plan) downloadDocument(u.plan, `unidad-${u.number}`, extension) }} style={{ zIndex: "2" }}>
                  <IconDownload width={20} height={20} className="text-primary mb-1" />
                  <span className="ms-2">Plano</span>
                </Button>
              }

            </div>
          }

          {u.virtual_tour && u.virtual_tour !== "" &&
            <div className="toggle-tour-virtual bg-white shadow d-flex align-items-center py-1 px-1 rounded-3">
              <div
                className={`toggle-button ${!showVirtualTour ? "text-black-50" : "bg-sm-light text-primary"
                  } px-2 py-1 me-1 rounded-2`}
                onClick={() => setShowVirtualTour(true)}
              >
                Tour Virtual
              </div>
              <div
                className={`toggle-button ${showVirtualTour ? "text-black-50" : "bg-sm-light text-primary"
                  } px-2 py-1 rounded-2`}
                onClick={() => setShowVirtualTour(false)}
              >
                Plano
              </div>
            </div>
          }
        </Col>
      </Row>
    </div>
  );
};
