import { Card } from "react-bootstrap";
import { IconBuilding } from "../CustomIcons/IconBuilding";
import { CountryFlag } from "../CountryFlag/CountryFlag";
import { ProjectRankingType, RankingProjectsType } from "../../interfaces/ranking";
import { SkeletonFeedRanking } from "../Skeleton/SkeletonFeedRanking";

export const FeedRankingProjectsCard = ({
  rankingData,
  isLoading,
  isModal = false,
  setShowModal = () => { }
}: {
  rankingData: ProjectRankingType[],
  isLoading: boolean,
  isModal?: boolean,
  setShowModal?: (x: boolean) => void
}) => {
  const handleClose = () => setShowModal(false);
  const loaderQty = 3;

  if (rankingData.length == 0 && !isLoading) return null

  return (
    <Card className="border-0 py-2 px-1 pb-1  shadow-sm">
      <div>
        <span className="text-green me-3 ms-3 ps-1">
          <IconBuilding width={20} />
        </span>
        <span className="fw-500 text-secondary">Lo más vendido</span>
        {isModal &&
          <i onClick={() => handleClose()} className="bi bi-x text-light-secondary float-end"></i>
        }
      </div>
      <div className="border-top mt-2 mb-1 mx-1"></div>
      <div className="ranking-items">
        {rankingData.map((ranking , index) => (
          <a
            href={"/proyecto/" + ranking.identifier+ "?operation=Venta"}
            target={"_blank"}
            key={ranking.identifier ?? ""}
            className="d-flex align-items-center justify-content-between py-1 px-3 text-decoration-none text-body rounded-1 bg-sm-light-hover"
            role={"button"}
          >
            <div className="main-info d-flex align-items-center flex-nowrap">
              <div className="fw-bold text-secondary pe-0">
                {index + 1}
              </div>
              <div className="ps-1" style={{ width: 20 }}>
                {ranking.indicator === "up" ? (
                  <i className="bi bi-arrow-up-short text-green fs-5"></i>
                ) : ranking.indicator === "down" ? (
                  <i className="bi bi-arrow-down-short text-light-secondary fs-5"></i>
                ) : null}
              </div>
              <div className="ps-2">{ranking.title ?? ""}</div>
            </div>
            <div className="d-flex justify-content-end">
              <CountryFlag
                flag={ranking.country?.flag ?? ""}
                shadow
                border
              />
            </div>
          </a>
        ))}
        {isLoading && (
          [...Array(loaderQty)].map((item, index) => (
            <div key={index} className="py-1 px-3 text-decoration-none text-body rounded-1 bg-sm-light-hover">
              <SkeletonFeedRanking />
            </div>
          ))
        )}
      </div>
    </Card>
  );
};
