
export const TermsConditionsCL = () => {

    return (
        <div className="container p-3 py-5">
            <h3 className="text-center mb-2 fw-bold">Términos & Condiciones</h3>
            <p className="text-center mb-5">Última Actualización: 4 de Agosto de 2023</p>
            <p>Este es un acuerdo entre Yapo.cl SpA, una compañía chilena (“Yapo” o “Nosotros”), dueña y operadora de www.iris.yapo.cl, el software Iris (“Iris”) y Usted, un usuario del servicio (“Broker” o “Corredor”).</p>
            <p>Yapo provee un software que tiene el objeto de proveer una amplia oferta de propiedades nuevas provistas por varias Inmobiliarias presentes en Chile y demás países de la región donde se encuentra presente. Éstos son proveídos a través del sitio web  www.iris.yapo.cl (el “Sitio”).</p>
            <p>Por favor lea cuidadosamente los siguientes términos y condiciones (“Términos del Servicio”) y nuestra Política de Privacidad, la que puede ser encontrada en nuestro sitio web <a href="https://iris.yapo.cl/privacidad" target="_blank">https://www.iris.yapo.cl/privacidad</a> , la cual se entiende incorporada por referencia a los Términos del Servicio.</p>
            <p>Estos Términos del Servicio regulan su acceso y uso del Servicio Iris y todo el Contenido del Sitio, constituyendo un contrato vinculante entre Iris y Usted.</p>
            <br />
            <p>USTED DECLARA CONOCER Y ACEPTAR QUE AL HACER CLICK EN EL BOTÓN DE ACEPTACIÓN DE LOS TÉRMINOS DEL SERVICIO, REGISTRARSE EN UNA CUENTA DE IRIS, ACCEDER O USAR EL SERVICIO IRIS, O AL DESCARGAR O INGRESAR CUALQUIER CONTENIDO DESDE, EN O A TRAVÉS DEL SERVICIO IRIS, ESTÁ INDICANDO QUE USTED HA LEÍDO, ENTENDIDO Y ACEPTADO OBLIGARSE DE ACUERDO A LOS TÉRMINOS DEL SERVICIO.</p>

            <p>SI USTED NO ACEPTA LOS TÉRMINOS DEL SERVICIO, ENTONCES NO TIENE DERECHO A ACCEDER O UTILIZAR EL SERVICIO IRIS O EL CONTENIDO DEL SITIO.</p>

            <p>Si Usted acepta estos Términos del Servicio en representación de una empresa u otra entidad legal, Usted declara y garantiza que tiene la facultad para obligarla a estos Términos del Servicio y, en tal evento, “Usted” y “Su” se referirá y aplicará a dicha empresa o entidad legal.</p>

            <br />

            <p><strong>Definiciones.</strong></p>

            <p>“Contenido” se refiere a texto, gráficos, u otro tipo de información o material.</p>
            <p>“Usuario Corporativo” se refiere a un Miembro que es una empresa u otra entidad legal.</p>
            <p>“Contenido IRIS” significa aquel Contenido que Yapo pone a disposición a través del Servicio Iris, incluyendo cualquier contenido licenciado a Iris por un tercero, y excluyendo el contenido proporcionado por un Usuario.</p>
            <p>“Usuario” se refiere a cualquier persona o entidad que completa el proceso de Registro de su Cuenta en Iris, según se describe más abajo.</p>
            <p>“Contenido del Usuario” se refiere al Contenido que el Usuario anuncia, carga, publica, entrega o transmite a través del Servicio Iris.</p>
            <p>“Contenido del Sitio” se refiere al Contenido Iris y el Contenido del Usuario.</p>

            <p><strong>Capacidad.</strong></p>

            <p>Al acceder o usar el Servicio Iris Usted declara y garantiza a Yapo que: (i) Usted tiene la edad legal para celebrar un contrato vinculante, o, si Usted es menor de edad, tiene la autorización de su representante legal para usar el Servicio Iris; (ii) que toda la información de registro es precisa, actual y completa; (iii) que Usted mantendrá dicha información en forma precisa, completa y actualizada; (iv) si Usted está aceptando estos Términos del Servicio en representación de una empresa u otra entidad legal, Usted tiene la capacidad para obligarla a estos Términos del Servicio. Asimismo, Usted certifica que está legalmente autorizado para usar  y acceder al Servicio Iris y que será responsable por la selección, uso y acceso al Servicio Iris.  </p>

            <p>Estos Términos del Servicio no aplicarán donde estén prohibidos por ley, y en dichas jurisdicciones el derecho a acceder al Servicio Iris está prohibido.</p>

            <br />
            <p><strong>Registro en Iris.</strong></p>
            <p>Para acceder al Servicio Iris Usted debe registrarse y crear una cuenta (la “Cuenta”) y convertirse en Usuario. Al registrarse en Iris Usted acepta: (i) proporcionar información verídica, precisa, actualizada y completa acerca de Usted (o, en caso sea aplicable, del Usuario Corporativo que representa) según sea solicitado por el Formulario de Registro del Servicio Iris (siendo dicha información la “Información de Registro”) y (ii) mantener y actualizar debidamente la Información de Registro para mantenerla veraz, precisa, actualizada y completa.</p>

            <p>Si Usted proporciona información falsa, imprecisa, desactualizada o incompleta, o tenemos antecedentes para sospechar que dicha información es falsa, imprecisa, desactualizada o incompleta, podremos suspender o terminar su cuenta y rechazar cualquier uso del Servicio Iris (o cualquier parte del mismo). Yapo se reserva el derecho de solicitar cualquier comprobante y/o dato adicional a efectos de corroborar los Datos Personales.</p>

            <p>Usted no puede autorizar a terceros (salvo a, en caso sea aplicable, aquellos empleados, consultores o agentes del Usuario Corporativo que Usted represente) para acceder o usar el Servicio Iris en su representación.</p>

            <p>Usted es responsable de mantener la confidencialidad de su Identificación de Usuario y clave, y es absolutamente responsable de toda la actividad que ocurra bajo su Identificación de Usuario y clave. Usted se obliga a notificar a Iris respecto de cualquier uso no autorizado de su Identificación de usuario y clave o cualquier otra violación de seguridad. Iris no puede ni será responsable de las pérdidas o daños que sobrevengan del uso no autorizado de su cuenta.</p>

            <p>Al proporcionarnos su dirección de correo electrónico, Usted acepta recibir todas las notificaciones que sean necesarias a dicha dirección de correo electrónico. Usted autoriza expresamente a Iris a utilizar dicha dirección de correo electrónico para enviarle notificaciones acerca de actualizaciones y mejoras de nuestros productos, noticias y eventos de la empresa, así como novedades de nuestra comunidad.</p>


            <br />
            <p><strong>Publicidad sobre el Proyecto.</strong></p>

            <p>El Broker se compromete a respetar los materiales de venta otorgados por la inmobiliaria a través del servicio Iris, para ser usados en su web, redes sociales y base de datos.
                El objeto de esto es que los broker o corredores se conviertan en un canal de venta extra para las inmobiliarias.</p>


            <p><strong>Comisiones por ventas. </strong></p>

            <p>El porcentaje de comisión correspondiente a cada proyecto, establecido por la inmobiliaria, más el impuesto al valor agregado (IVA), será abonado por parte de YAPO de manera íntegra al Broker / corredor que intermedie en la venta, entendiéndose como tal, aquellas operaciones cerradas con un compromiso de compraventa de por medio. El porcentaje establecido por la inmobiliaria será sobre el precio de venta del inmueble.</p>

            <p>Yapo procederá al pago mencionado al Broker / corredor en un plazo máximo de 10 días hábiles posteriores a que se acredite el pago de parte de la inmobiliaria en las cuentas bancarias de Yapo, siempre y cuando se haya firmado el compromiso de compraventa, así como también el Broker / corredor haya emitido la factura fiscal correspondiente.</p>
            <br />

            <p><strong>Política de precios y descuentos – Confidencialidad.</strong></p>

            <p>Yapo  pondrá a disposición  a través de su Servicio Iris la política de precios y descuentos de los distintos productos.</p>

            <p>En el sitio iris.yapo.cl se actualizarán todas las listas de precios, descuentos y disponibilidad en línea de los diferentes proyectos inmobiliarios, lo cual deberá ser respetado por el Broker / corredor.</p>

            <p>El Broker / corredor  acepta que la información relacionada con la Política de Precios y Descuentos se deberá mantener en forma estrictamente confidencial en forma indefinida, independientemente de la vigencia o no de este acuerdo.  Asimismo, se obliga a guardar la más estricta confidencialidad de toda información que IRIS le haya suministrado anteriormente a la aceptación de los presentes términos y condiciones o se le suministre en el futuro o a la que acceda en virtud o en ocasión de las relaciones entabladas con Yapo en virtud de este Proyecto. El Broker / corredor declara conocer y aceptar que las obligaciones de confidencialidad aquí acordadas constituyen un elemento esencial del contrato. </p>


            <br />
            <p><strong>Reserva de Unidades.</strong></p>

            <p>El Broker / corredor  podrá reservar la unidad a un cliente siempre y cuando este último abone el monto correspondiente a las políticas de cada inmobiliaria.</p>
            <p>Cada inmobiliaria establecerá el tiempo máximo en el que una reserva será válida para el Broker / corredor , y en caso de que no se firme el compromiso de compraventa dentro del plazo establecido,  el Broker / corredor ya no podrá reclamar y/o demandar el pago de la comisión. La política de cada desarrollador estará visible y actualizada en IRIS.</p>
            
            <br />
            <p><strong>Entrega de materiales.</strong></p>

            <p>IRIS entregará materiales de “marca blanca” de modo de que el broker / corredor publique en los sitios permitidos, así como también se la envíe a clientes directos interesados en el Proyecto.
            Se entiende por “marca blanca” a la ausencia de datos propios de modo que el broker / corredor pueda incluir sus datos de contacto para que el comprador final pueda comercializar a través de ella.</p>
            
            <br />
            <p><strong>Independencia de las partes, cumplimiento de normas.</strong></p>

            <p>La presente relación no tiene naturaleza laboral o de dependencia y no implica efecto societario alguno. La única relación jurídica que existe entre las Partes contratantes es aquella que existe entre partes independientes en donde una presta un servicio sin existir subordinación alguna y la otra paga un precio como contraprestación de dicho servicio. Ni el presente Contrato ni los Servicios deben ser considerados como la constitución de una asociación, sociedad, empresa conjunta (joint venture), o una relación principal-agente entre el Cliente y el Proveedor, y ninguna de las Partes tendrá derecho, o autoridad (ya sea explícita o implícita) para asumir ninguna obligación en nombre de la otra Parte.</p>
            
            <br />
            <p><strong>Responsabilidad laboral</strong></p>

            <p>El Broker / corredor es el único responsable por el cumplimiento de las obligaciones laborales, previsionales, de seguridad e higiene en el trabajo, tributarias y de cualquier otra naturaleza de su cargo respecto de su personal afectado al Proyecto.</p>
            
            <p>El Broker / corredor será exclusivamente responsable por cualquier accidente que pueda sufrir su personal (o cualquier persona contratada a tales efectos) afectado al cumplimiento de este contrato, como consecuencia de la realización de las actividades objeto del contrato o en ocasión de las mismas, obligándose la Inmobiliaria a tener al personal asegurado conforme a las normas vigentes.</p>
            
            <br />
            <p><strong>Tarifas y Pago.</strong></p>
            
            <p>Registrarse y crear una cuenta en Iris podrá ser gratis para aquellos Usuarios que Iris determine a su discreción. El uso gratuito podrá estar limitado a algunas de las funcionalidades y herramientas del Servicio Iris. A la presente fecha no existen servicios pagados para IRIS, sin embargo, Yapo podría establecer a su sola discreción un cobro para acceder a los servicios de IRIS notificando a los usuarios por correo electrónico con una antelación de 15 días.</p>
            
            <br />
            <p><strong>Modificaciones.</strong></p>
            
            <p>Iris se reserva el derecho, a su sola discreción, para modificar, descontinuar o terminar el Servicio Iris o modificar los Términos del Servicio, en cualquier tiempo y sin necesidad de notificación previa.</p>
            <p>Cualquier modificación a los Términos del Servicio será anunciada en el Sitio y/o le enviaremos una notificación de las modificaciones. Adicionalmente, actualizaremos la “Fecha de Última Actualización” en la parte superior de los Términos del Servicio o en el Sitio o Aplicación.</p>
            <p>Al acceder o usar el Servicio Iris luego de que hayamos anunciado y/o notificado una modificación a los  Términos del Servicio se entiende que Usted acepta y se obliga por los nuevos Términos del Servicio.</p>

            <p>Si los nuevos Términos del Servicio no son aceptables para Usted deberá cesar el uso del Servicio Iris.</p>
            <p>Algunas partes del Servicio Iris podrían tener en el futuro diferentes términos y condiciones a los anunciados en el Sitio o podrían requerir de Usted la aceptación de términos y condiciones adicionales. En caso hubiere algún conflicto entre los Términos del Servicio y aquellos anunciados para una parte específica del Servicio Iris, los últimos prevalecerán en relación con su uso o acceso a dicha parte específica del Servicio Iris.</p>
            
            <p>Iris podrá, a su sola discreción, hacer nuevas aplicaciones, software o servicios que podrán estar sujetos a diferentes términos y condiciones y licencias más restrictivas que los enunciados.</p>
            
            <br />
            <p><strong>Propiedad intelectual.</strong></p>
            
            <p>(i) Servicio Iris: Yapo será dueño y retendrá todo derecho, título e interés en y del Servicio Iris (excepto cualquier contenido licenciado y componentes de software incluidos en él). Los Usuarios aceptan no hacer ingeniería inversa, descomponer, distribuir, licenciar, sub-licenciar, vender, transferir, desarmar, copiar, alterar, modificar o crear obras derivadas del Servicio Iris usarlo en cualquier forma que resulte en una violación de las restricciones contenidas en estos Términos del Servicio. Yapo no otorga licencia alguna, expresa o implícita, a la propiedad intelectual de Iris o sus licenciantes.</p>

            <p>El Usuario declara conocer y aceptar que cualquier información relacionada al diseño, “look and feel”, especificaciones, componentes, funcionalidades u operación y condiciones de pago y precios (si fuere aplicable) del Servicio Iris es considerado confidencial y de propiedad de Yapo (todo lo anterior, la “Información Confidencial de Yapo”).</p>
            <p>(ii) Logo de Iris, Yapo y Diseño: Los gráficos, logos, diseños, encabezados de página, íconos de los botones, secuencias y nombre de los servicios de Iris son marcas registradas de Yapo en Chile y/u otros países.  Las Marcas comerciales de Yapo no podrán ser utilizadas, ni como parte de marcas registradas ni como parte de los nombres de dominio, en relación a cualquier producto o servicio de manera tal que pueda causar confusión. Respecto de éstas, tampoco se podrá copiar, imitar o utilizar, en su totalidad o en parte, sin el permiso previo y por escrito de Yapo.</p>
            <br />
            <p><strong>Privacidad.</strong></p>

            <p>Vea las políticas de privacidad de Iris en <a href="https://www.iris.yapo.cl/privacidad" target="_blank">https://www.iris.yapo.cl/privacidad</a>  para información y notificaciones concernientes a la recopilación y uso de su información personal.</p>

            <br />
            <p><strong>Prohibiciones Generales.</strong></p>

            <p>El uso, la exhibición, la copia o el engaño del Servicio Iris, o cualquier elemento dentro del Servicio Iris, el nombre de Iris, el nombre de Yapo, cualquier marca comercial, logotipo de propiedad de Yapo u otra información de propiedad de Yapo, o la distribución y el diseño de cualquier página o formulario contenido en una página, sin el expreso consentimiento de Yapo por escrito.</p>
            <p>Acceder, manipular o utilizar áreas no públicas del Servicio Iris, los sistemas informáticos de Iris o los sistemas de los proveedores de Iris.</p>
            <p>Intento de sondear, escanear o probar la vulnerabilidad de cualquier sistema o red de Yapo o violar las medidas de seguridad o autenticación.</p>
            <p>Difamar, abusar, acosar, acechar, amenazar o de alguna forma violar los derechos legales (como los de privacidad y publicidad) de cualquier otro uso del Servicio Iris o miembro del equipo de Yapo.</p>
            <p>Evitar, evadir, remover, desactivar, deteriorar, descodificar o de otra forma eludir una medida tecnológica implementada por Yapo o cualquiera de los proveedores de Yapo o cualquier tercero (incluyendo otro Usuario) para proteger el Servicio Iris o contenido del Sitio.</p>
            <p>Enviar cualquier publicidad no solicitada o no autorizada, materiales promocionales, correo electrónico, mensajes de correo basura o no deseado, spam, cartas en cadena o otra forma de solicitud (incluyendo el envío de invitaciones para utilizar el Servicio Iris a los números de teléfonos de personas que Usted no conoce).</p>
            <p>Usar o revelar cualquier Información Confidencial de Iris a excepción de lo establecido expresamente en el presente documento.</p>
            <p>Intentar descifrar, descompilar, desensamblar o realizar ingeniería inversa a cualquier software utilizado para proporcionar el Servicio Iris o Contenido del Sitio.</p>
            <p>Recoger o almacenar cualquier información de identificación personal del Servicio Iris de otros Usuarios sin su permiso expreso por escrito;</p>
            <p>Suplantar o falsificar su afiliación con cualquier persona o entidad;</p>
            <p>Violar cualquier ley o regulación aplicable; o</p>
            <p>Alentar o permitir a cualquier otra persona física o entidad hacer cualquiera de lo anteriormente señalado.</p>
            <p>Yapo tendrá el derecho de investigar y demandar en caso de violación de lo anteriormente señalado con el máximo rigor de la ley. Yapo puede hacerse parte y cooperar con las autoridades legales para perseguir a los Usuarios que incumplan los presentes Términos del Servicio.</p>
            <p>Usted declara conocer que Yapo no tiene obligación de controlar su acceso o el uso del Servicio Iris, pero que sí tiene el derecho de hacerlo con el fin de operar el Servicio Iris, para asegurar el cumplimiento de estos Términos del Servicio, o para cumplir con la ley aplicable o una orden o requerimiento de un tribunal, agencia administrativa u otra entidad gubernamental.</p>

            <br />
            <p><strong>Terminación y cancelación de la cuenta.</strong></p>
            <p>Si usted viola estos Términos del Servicio, Yapo tendrá el derecho de suspender, desactivar o cancelar su cuenta o terminar estos Términos del Servicio, a su entera discreción y sin previo aviso. Yapo se reserva el derecho de revocar su acceso y uso del Servicio Iris en cualquier momento, con o sin causa. En caso Yapo ponga término a los Términos de Servicio por su incumplimiento, Usted seguirá siendo responsable por cualquier y todas las cantidades adeudadas bajo el presente contrato. Usted puede cancelar su cuenta en cualquier momento enviando un correo electrónico a iris@yapo.cl.</p>

            <br />
            <p><strong>Aviso legal.</strong></p>
            
            <p>EL SERVICIO IRIS Y EL CONTENIDO DEL SITIO SE OFRECEN "TAL CUAL", SIN GARANTÍA DE NINGÚN TIPO, SEA EXPRESA O IMPLÍCITA. SIN PERJUICIO DE LO ANTERIOR, YAPO RECHAZA EXPRESAMENTE CUALQUIER GARANTÍA DE COMERCIALIZACIÓN, IDONEIDAD PARA UN PROPÓSITO PARTICULAR O NO INFRACCIÓN, Y CUALQUIER TIPO DE GARANTÍA DERIVADAS DEL CURSO DE NEGOCIACIÓN O USO COMERCIAL. YAPO NO GARANTIZA QUE EL SERVICIO IRIS  O QUE EL CONTENIDO DEL SITIO CUMPLAN CON SUS NECESIDADES, NI ESTARÁN DISPONIBLES DE MANERA ININTERRUMPIDA, SEGURA O LIBRE DE ERRORES. YAPO NO GARANTIZA LA CALIDAD DE CUALQUIER PRODUCTO, SERVICIO O CONTENIDO DEL SERVICIO IRIS O LA PRECISIÓN, OPORTUNIDAD, VERACIDAD, INTEGRIDAD O CONFIABILIDAD DE CUALQUIER CONTENIDO OBTENIDO A TRAVÉS DEL SERVICIO IRIS.</p>
            <p>NINGÚN CONSEJO O INFORMACIÓN, YA SEA ORAL O ESCRITA, OBTENIDO DE YAPO O A TRAVÉS DEL SERVICIO IRIS, OTORGARÁ UNA GARANTÍA QUE NO EXPRESAMENTE PREVISTA EN ESTE DOCUMENTO.</p>
            <p>USTED ES EL ÚNICO RESPONSABLE DE TODAS SUS COMUNICACIONES E INTERACCIONES CON OTROS USUARIOS DEL SERVICIO IRIS Y CON OTRAS PERSONAS CON QUIEN USTED SE HAYA COMUNICADO O INTERACTUADO COMO RESULTADO DE SU USO DEL SERVICIO IRIS. USTED ACEPTA TOMAR PRECAUCIONES RAZONABLES EN TODAS LAS COMUNICACIONES E INTERACCIONES CON OTRAS PERSONAS CON QUIEN USTED PUEDA COMUNICARSE O INTERCATUAR COMO RESULTADO DE SU USO DEL SERVICIO IRIS.</p>
            
            <br />
            <p><strong>Indemnización.</strong></p>
            
            <p>Usted acepta defender, indemnizar y mantener a Yapo, sus funcionarios, directores, empleados y agentes, libres de y contra cualquier reclamo, responsabilidad, daño, pérdidas y gastos, incluyendo, sin limitación, honorarios legales y contables razonables, que surjan de o de cualquier manera relacionados con su acceso o uso del Servicio Iris, o de su violación de estos Términos del Servicio, incluyendo el incumplimiento de alguna representación o garantía de este documento.</p>
            
            <br />
            <p><strong>Acuerdo completo.</strong></p>
            <p>Estos Términos del Servicio constituyen la totalidad y exclusivo entendimiento y acuerdo entre Yapo y Usted en relación con el Servicio Iris y  Contenido del Sitio, y estas Términos del Servicio sustituyen y reemplazan cualquier y todos los acuerdos anteriores escritos o verbales o acuerdos entre Yapo y Usted en relación con el Servicio Iris .</p>
            <br />
            <p><strong>Avisos.</strong></p>
            <p>Todas las notificaciones u otras comunicaciones permitidas o requeridas bajo el presente, incluyendo las relativas a modificaciones de estos Términos del Servicio, se harán por escrito y serán dadas: por Yapo a través de correo electrónico (en cada caso a la dirección que usted proporciona). Para notificaciones hechas por correo electrónico, la fecha de recepción, se considerará la fecha en que se transmite dicha notificación.</p>
            
            <br />
            <p><strong>Resolución de conflictos, ley y jurisdicción</strong></p>
            <p>Para todos los efectos del presente acuerdo, las partes fijan su domicilio en la ciudad y comuna de Santiago y se someten a la jurisdicción de sus Tribunales de Justicia. Este Contrato se regulará, interpretará y aplicará de acuerdo con la legislación chilena.</p>
            
            <br />
            <p><strong>Representante legal</strong></p>
            <p>Yapo.cl SpA designa como su representante legal para todos los efectos relacionados con los presentes Términos y Condiciones y con el uso del sitio, al Sr. Eduardo Andrés Brügmann, RUT 14.632.301-4, domiciliado en Av. Mariano Sánchez Fontecilla 310, piso 14, Las Condes, Santiago - Chile.</p>
            
            <br />
            <p><strong>Domicilio y contacto</strong></p>

            <p>Yapo.cl SpA. para los fines aplicables a su Servicio Iris fija su domicilio en Av. Mariano Sánchez Fontecilla 310, piso 14, Las Condes, Santiago - Chile.</p>
            <p>Cualquier información adicional sobre los presentes Términos y Condiciones, y sobre el uso del sitio en general podrá obtenerse directamente a iris@yapo.cl.</p>
            

        </div>
    )
}
