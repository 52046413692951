import { ReactNode } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { PropertyType } from "../../interfaces/property";
import { formatMoney } from "../../utils/Functions";
import { PropertyFavorite } from "../PropertyFavorite/PropertyFavorite";
import { PropertyFormButton } from "../PropertyFormButton/PropertyFormButton";
import useBreakpoint from "use-breakpoint";
import { BREAKPOINTS } from "../../utils/Constants";
import { ProjectType } from "../../interfaces/project";

export const PropertyModalInmo = ({
  property,
  show,
  onClose,
  children,
  className = "",
  minProjectPrice
}: {
  show: boolean;
  onClose: () => void;
  children: ReactNode;
  property: ProjectType;
  className?: string;
  minProjectPrice? : { currency : string , price : number}
}) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");

  return (
    <Modal
      fullscreen
      show={show}
      onHide={onClose}
      className={`property-modal text-white inmo-detail-modal ${className}`}
      backdropClassName="property-modal-backdrop"
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        className="border-bottom-0"
      >
        <Modal.Title className="lh-1">
          <h2 className="fs-4 my-0 lh-1">{property.title}</h2>
          {
            minProjectPrice && 
            <span className="fs-xs lh-1 text-uppercase fw-light">
            Desde {minProjectPrice?.currency} {" "}
            {formatMoney(minProjectPrice.price)}{" "}
            {property.zone && `· ${property.zone.name}`}
          </span>
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={breakpoint === 'xs' ? "px-0" : ""}>{children}</Modal.Body>
      
    </Modal>
  );
};
