// data
import rankingUsersData from '../data/rankingUsersDemo.json'
import map from '../data/map.json'

import levels from '../data/levels.json'
import propertyTypes from '../data/propertyType.json'
import states from '../data/state.json'
//interfaces
import { LoginType, RegisterResponseType, LoginResponseType,
  UserInfoResponseType,BasicResponseType } from '../interfaces/user'
import { BenefitsType } from '../interfaces/benefits'
import { RankingUserType } from '../interfaces/ranking'
import { LevelsType } from '../interfaces/levels'
import {
  PropertyTypeType,
  StateType,
} from './../interfaces/property'

import { interfaceRanking } from './../interfaces/apiTypes';

import {
  MortgageBodyType,
  WarrantyBodyType,
  SqueduleBodyType,
  OfferBodyType,
  ValueFormDataType,
  UtmArgs,
  SendDataType,
  EstimatorBodyType,
  ClientRegisterBodyType,
} from '../interfaces/email'
import { CountryCodesType } from '../interfaces/config'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { Country } from '../interfaces/locations'
import { ProjectStatusType, ProjectTypeType } from '../interfaces/project'
import { translateProjectTypes } from '../utils/project_helper'
import { getDownloadLinkFromGoogleDriveLink, validateDriveFile } from '../utils/Functions'
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);

/*
 ******************************
 ********* AUXILIARES *********
 ******************************
 */
const getIconLevelUsers = (level: string): LevelsType => (levels as any)[level]


/*
 ******************************
 ********* PROPERTIES *********
 ******************************
 */



// devuelve 8 propiedades ramdom distintas


// Listado general con filtrado


/*
 ******************************
 ************ USER ************
 ******************************
 */


export const validateLoginFields = async (
  email: string,
  password: string,
): Promise<LoginType> => {
  if (typeof email === 'undefined' || email.length === 0)
    return { status: 'error', message: 'Ingrese un correo', field: 'email' }
  if (!email.match(/^[A-Z0-9._%+-\s]+@[A-Z0-9.-\s]+\.[A-Z]{2,4}$/i))
    return { status: 'error', message: 'Email Invalido', field: 'email' }

  if (typeof password === 'undefined' || password.length < 8)
    return {
      status: 'error',
      message: 'La contraseña debe tener mínimo 8 caracteres',
      field: 'password',
    }

  return { status: 'ok' }
}


export const login = async (
  email: string,
  password: string
): Promise<LoginResponseType> => {

  const CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID || ''
  
  let params: RequestInit = {
    method: "POST",
    body: new URLSearchParams({
      'username' : email.toString(), 
      'password' : password.toString(),
      'client_id': CLIENT_ID.toString()
    }),
    headers : {
      'Content-Type' : 'application/x-www-form-urlencoded'
    }
  }
  
  const response = await fetch(
    process.env.REACT_APP_AUTH_API_URL+'/api/auth/login',    
    params
  )
  return await response.json()  
}

// ranking users
export const rankingUsers = async (
  country?: CountryCodesType,
  number?: number,
): Promise<interfaceRanking> => {
  let result : interfaceRanking = {
    success :  false,
    data : [] ,
    goLogin : false,
    message : ''
  }
  const response: any[] = rankingUsersData.ranking
  const filteredRanking = response.filter((res) => res.country === country)

  filteredRanking.forEach((ranking) => {
    const levelData: LevelsType = getIconLevelUsers(ranking.user?.level)
    ranking['user']['levelData'] = levelData
  })
  result.success = true;
  result.data = filteredRanking.slice(0, number ?? 4)

  return result
  
}

// ranking user logged
export const rankingUserLogged = (id: string): RankingUserType => {
  const response = rankingUsersData.ranking.filter(
    (ranking: RankingUserType) => ranking.user?.id === id,
  )
  return response[0]
}


export const register = async (
  data : {
      name : string,
      phone : string,
      real_estate : string,
      city : string,
      email: string,
      password: string,
      refer_type : string,
      refer_real_estate : string,
      refer_name : string
  }

): Promise<RegisterResponseType> => {

  let params: RequestInit = {
    method: "POST",
    body: JSON.stringify(data),
    headers : {
      'Content-Type' : 'application/json'
    }
  }
  
  const response = await fetch(
    process.env.REACT_APP_AUTH_API_URL+'/api/auth/register',    
    params
  )
  .then(async (data) =>{
    return await data.json()
  })
  .catch(err => {
    console.error(err)
    return false
  })

  return response

  
}




/*
 ******************************
 *********** OTHERS ***********
 ******************************
 */
export const getMapMarkers = () => {
  const markers = map
  markers.sort(() => Math.random() - 0.5)
  return markers.slice(0, 10).map((m, i) => {
    return { ...m, lat: Number(m.lat), lng: Number(m.lng) }
  })
}

export const requestBenefits = async (
  type: string,
  name: string,
  email: string,
  phone: string,
  ci?: string,
  supplier?: string,
  requiredCI? : boolean
): Promise<BenefitsType> => {
  if (typeof name === 'undefined' || email.length === 0)
    return {
      status: 'error',
      message: 'Ingrese nombre.',
      field: 'name',
    }

  if (typeof email === 'undefined' || email.length === 0)
    return {
      status: 'error',
      message: 'Ingrese un correo',
      field: 'email',
    }
  if (!email.match(/^[A-Z0-9._%+-\s]+@[A-Z0-9.-\s]+\.[A-Z]{2,4}$/i))
    return { status: 'error', message: 'Email Inválido', field: 'email' }

  if (typeof phone === 'undefined' || phone.length === 0)
    return {
      status: 'error',
      message: 'Ingrese teléfono.',
      field: 'phone',
    }
    
  if (requiredCI && (typeof ci === 'undefined' || ci?.length === 0))
    return {
      status: 'error',
      message: 'Ingrese Documento',
      field: 'ci',
    }

  if (type === 'warranty')
    if (typeof supplier === 'undefined' || supplier.length === 0)
      return {
        status: 'error',
        message: 'Ingrese Proveedor',
        field: 'supplier',
      }

  return { status: 'ok' }
}


export const validRequestNotarial = (
  name: string,
  phone: string,
  bank_financing: string,
  amount : string
): BenefitsType => {
  if (typeof name === 'undefined' )
    return {
      status: 'error',
      message: 'Ingrese nombre.',
      field: 'name',
    }


  if (typeof phone === 'undefined' || phone.length === 0)
    return {
      status: 'error',
      message: 'Ingrese teléfono.',
      field: 'phone',
    }
  

  if (typeof bank_financing === 'undefined' || bank_financing.length === 0)
    return {
      status: 'error',
      message: 'Ingrese financiación bancaria.',
      field: 'bank_financing',
    }

  if (typeof amount === 'undefined' || amount.length === 0)
    return {
      status: 'error',
      message: 'Ingrese monto.',
      field: 'amount',
    }
  return { status: 'ok' }
}
export const getCountries = async () => {
  const res = await fetch(
    process.env.REACT_APP_AUTH_API_URL+'/api/auxiliary/get-countries',
    {
      method : 'GET',
      headers : {
        'Content-Type' : 'application/json'
      }
    }
  ).then(data => data.json())

  return res
}

export const getLocations = async () => {
  const res : {success : boolean , data? : Country[] , message? : string} = await fetch(
    process.env.REACT_APP_AUTH_API_URL+'/api/locations/get-locations',
    {
      method : 'GET',
      headers : {
        'Content-Type' : 'application/json'
      }
    }
  ).then(data => data.json())
  .catch(err => {
    console.log(err)
    return {
      success : false
      
    }
  })

  return res
}


export const getProjectTypes = async () => {
  const res : {success : boolean , data? : ProjectTypeType[] , message? : string} = await fetch(
    process.env.REACT_APP_AUTH_API_URL+'/api/projects/get-types',
    {
      method : 'GET',
      headers : {
        'Content-Type' : 'application/json'
      }
    }
  ).then(async data => {
    let result : any = await data.json()

    // result.data = translateProjectTypes(result.data,translation,country_code)
    return result
    
  })
  .catch(err => {
    console.log(err)
    return {
      success : false
      
    }
  })

  return res
}

export const getProjectStatus = async () => {
  const res : {success : boolean , data? : ProjectStatusType[] , message? : string} = await fetch(
    process.env.REACT_APP_AUTH_API_URL+'/api/projects/get-status',
    {
      method : 'GET',
      headers : {
        'Content-Type' : 'application/json'
      }
    }
  ).then(data => data.json())
  .catch(err => {
    console.log(err)
    return {
      success : false
      
    }
  })

  return res
}

export const getPropertyTypes = (): PropertyTypeType[] => {
  const res: PropertyTypeType[] = Object.values(propertyTypes)
  return res
}

export const getStates = (country: string): StateType[] => {
  //@ts-ignore
  const res: StateType[] = Object.values(states[country])
  return res
}


export const sendEmail = (
  data: MortgageBodyType | WarrantyBodyType | SqueduleBodyType | OfferBodyType | EstimatorBodyType | ClientRegisterBodyType,
): any => {

  const response = fetch(
    `${process.env.REACT_APP_AUTH_API_URL}/api/auxiliary/send-iris-mail`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
  ).then(res => res.json())
  .catch(err => console.log(err))

  return response
}

export const sendFormulario = async (
  values: ValueFormDataType,
  IDform: number,
  IDpais: number,
  utm?: UtmArgs,
) => {
  const ENDPOINT = `${process.env.REACT_APP_URL_ENDPOINT}/?mid=formulario&func=ajax_save&json=true`
  const utms = utm ? utm : {}
  const sendData: SendDataType = { IDform, IDpais, ...values, ...utms }

  const res = await fetch(ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(sendData),
  }).then((res) => res.json())

  let data = {
    status: '',
    message: '',
  }

  if (res) {
    switch (res.error) {
      case '00':
        data.status = 'success'
        data.message = 'Consulta enviada con éxito.'
        break
      case '02':
        data.status = 'warning'
        data.message = 'Su consulta ya ha sido enviada antes.'
        break
      default:
        data.status = 'error'
        data.message = 'Hubo un error. Vuelva a intentarlo.'
        break
    }
  } else {
    data.status = 'error'
    data.message = 'Hubo un error. Vuelva a intentarlo.'
    new Error('Error Sending Form to IC1')
  }

  return data
}


export const downloadDocument = (
  url: string,
  title: string,
  extension: string,
) => {

  //Specially for larger files that couldn't be uploaded to Infocasas CDN
  if(validateDriveFile(url)){
    const linkDownload = getDownloadLinkFromGoogleDriveLink(url)

    if(linkDownload){
      const link = document.createElement('a')
      link.href = linkDownload
      link.setAttribute('download', `${title}.pdf`)
      document.body.appendChild(link)
      link.click()
    }
    
  }else{
    //Normal brochure
    fetch(url, {
      method: 'GET',
      headers: {},
    })
      .then((response) => {
        console.log(response)
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${title}.${extension}`)
          document.body.appendChild(link)
          link.click()
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }
  
}



export const resetPassword = (email: string) => {
  const res = fetch(
    `${process.env.REACT_APP_AUTH_API_URL}/api/auth/reset-password`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email : email
      }),
    },
  )
    .then((res) => res.json())
    .then((data) => {
      return data;
      
    })

  return res
}

export const validateChangePasswordFields = async (
  password: string,
  repeat_password: string,
): Promise<LoginType> => {
 
  if (typeof password === 'undefined' || password.length < 8)
    return {
      status: 'error',
      message: 'La contraseña debe tener mínimo 8 caracteres',
      field: 'password',
    }

    if (typeof repeat_password === 'undefined' || repeat_password.length < 8)
    return {
      status: 'error',
      message: 'La contraseña debe tener mínimo 8 caracteres',
      field: 'repeat_password',
    }

    if(password!==repeat_password){
      return  {
        status: 'error',
        message: 'Las contraseñas deben coincidir',
        field: 'repeat_password',
      }
    }

  return { status: 'ok' }
}

export const changePassword = async (
  password: string,
  repeat_password: string,
  url_code : string,
  user_id : number
): Promise<BasicResponseType> => {
   
  const response = await fetch(
    process.env.REACT_APP_AUTH_API_URL+'/api/auth/change-password',    
    {
      method: "POST",
      body: JSON.stringify({
        'password' : password.toString(), 
        'repeat_password' : repeat_password.toString(),
        url_code,
        user_id
      }),
      headers : {
        'Content-Type' : 'application/json'
      }
    }
  )
  return await response.json()  
}

export const logout = async (accessToken : string): Promise<UserInfoResponseType> => {
  
  let params: RequestInit = {
    method: "POST",   
    headers : {
      'Authorization' : 'Bearer '+ accessToken
    }
  }
  
  const response = await fetch(
    process.env.REACT_APP_AUTH_API_URL+'/api/auth/logout',    
    params
  )
  return await response.json()
  
}


export const getDomainConfig = async () => {
  const res = await await fetch(
    process.env.REACT_APP_AUTH_API_URL+'/api/auxiliary/get-domain-config',
    {
      method : 'POST',
      body : JSON.stringify({
        domain : window?.location.hostname,//'https://iris.fincaraiz.com.co',//  window?.location.hostname, //'https://iris.fincaraiz.com.co',// 'https://iris.yapo.cl/',//,// 'https://iris.fincaraiz.com.co',// window?.location.hostname
        is_test : window?.location.hostname.includes('localhost') || 
                    window?.location.hostname.includes('127.0.0.1')    
      }),
      headers : {
        'Content-Type' : 'application/json'
      }
    }
  ).then(data => data.json())
  .catch(err => console.log(err))

  return res;
};

export const crypt = (salt : string, text :any) => {
  try {

    const textToChars = (text:any) => { return text.split("").map((c:any) => c.charCodeAt(0))};
    const byteHex = (n:any) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code:any) => textToChars(salt).reduce((a : any, b:any) => a ^ b, code);

    return text
      .split("")
      .map(textToChars)
      .map(applySaltToChar)
      .map(byteHex)
      .join("");
    
  } catch (error) {
    console.log(error)
    return text
  }
  
};


export const decrypt = (salt:string, encoded :any) => {
  const textToChars = (text: any) =>{ return text?.split("").map((c : any) => c.charCodeAt(0))};
  const applySaltToChar = (code :any) => textToChars(salt).reduce((a :any, b:any) => a ^ b, code);
  if(encoded && encoded !=null){
    // @ts-ignore
    return encoded
    .match(/.{1,2}/g)
    .map((hex:any) => parseInt(hex, 16))
    .map(applySaltToChar)
    .map((charCode:any) => String.fromCharCode(charCode))
    .join("");
  }
  
};