import { ProjectType, UnitType } from "../../../interfaces/project"
import useConfig from '../../../hooks/config/useConfig'
import { PROPERTIES_VALUES } from "../../../utils/Constants";
import { useMemo } from "react";
const {LAND_TYPE} = PROPERTIES_VALUES

export const Features = ({typeName , project  , unit} : {typeName : string, project : ProjectType , unit : UnitType}) => {

    const {translation} = useConfig()
    const isLand = useMemo(() => {
        return project.type?.identifier === LAND_TYPE;
    },[project]) 

    const tipology = () => {
        let textType = ''
        if(unit.bedrooms > 0){
            textType += `${unit.bedrooms}D `
        }else{
            textType += (unit.tipology !== "Dormitorio" && unit.tipology !== "Oficina" ? unit.tipology : translation.studio)
        }
        textType += `, ${unit.bathrooms}B`
        return textType
    }

    return (
        <>
            <p className="fw-bold">I. CARACTERÍSTICAS</p>
            <div className="px-4">
                <table className=" mt-4 " style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="border-1 border-dark text-center pb-3">TIPO</th>
                            <th className="border-1 border-dark text-center pb-3" style={{ borderLeft: 0 }} >NÚMERO</th>
                            {
                                !isLand && 
                                <>
                                <th className="border-1 border-dark text-center pb-3" style={{ borderLeft: 0 }}>PISO</th>
                                <th className="border-1 border-dark text-center pb-3" style={{ borderLeft: 0 }}>TIPOLOGÍA</th>
                                <th className="border-1 border-dark text-center pb-3" style={{ borderLeft: 0 }}>ORIENTACIÓN</th>
                                </>
                            }

                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="border-1 border-dark text-center pb-3" style={{ borderTop: 0 }}>{typeName}</td>
                            <td className="border-1 border-dark text-center pb-3" style={{ borderTop: 0, borderLeft: 0 }} >{unit.number}</td>
                            {
                                !isLand && 
                                <>
                                <td className="border-1 border-dark text-center pb-3" style={{ borderTop: 0, borderLeft: 0 }}>{unit.floor}</td>
                                <td className="border-1 border-dark text-center pb-3" style={{ borderTop: 0, borderLeft: 0 }}>
                                    {tipology()}
                                </td>
                                <td className="border-1 border-dark text-center pb-3" style={{ borderTop: 0, borderLeft: 0 }}>{unit.orientation}</td>
                                </>
                            }
                            
                        </tr>
                    </tbody>
                </table>
            </div>

        </>
    )
}