import { ActivateUserRequest, ActivateUserResponse } from "../../../../interfaces/fetch/admin"
import { useFetchAdapter } from "../../useFetchAdapter"



export const useAdminUsersFetch = () => {

    const { postRequest } = useFetchAdapter()

    const activateUserService = async ( data : ActivateUserRequest) => {
        
        let result : ActivateUserResponse = {
            success : false,
            data : null,
            message : ""
        }

        const urlPath = `/api/admin/users/activate-user` 

        result = await postRequest(urlPath , data)

        return result
    }


    return {
        activateUserService
    }
}