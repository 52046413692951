import { useEffect, useState } from "react";
import { Button, Col, Container, ProgressBar, Row, Alert, Table } from "react-bootstrap";
import { useFetch } from '../../../hooks/fetch/useFetch'
import { NotificationsType } from "../../../interfaces/notifications";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import loaderIris from "../../../lotties/orange-loader.json";

export const ListNotifications = () => {

    const [notifications, setNotifications] = useState<NotificationsType[]>([])
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(20)
    const [loading,setLoading] = useState(true)
    const { getAdminNotifications } = useFetch()

    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.tz.guess() //Adivina el timezone del usuario

    useEffect(() => {
        
        getAdminNotifications(page, limit).then(data => {
            setLoading(false)
            if (data.success) {
                setNotifications(data.data.notifications)
            }
        }).catch(err => {
            console.log(err)
        })
    }, [])
    
    return (
        <div className="p-5">
            <div className="mb-4 py-2 px-4 d-flex justify-content-between flex-wrap position-relative">
                <Link to="/admin" className="fs-2 go-back mt-3 ms-3 ms-md-4" title="Volver">
                    <i className="text-light bi bi-arrow-left" ></i>
                </Link>
                <h3>NOTIFICACIONES</h3>
                <Link to={'create'}><button className="btn btn-primary">CREAR NUEVA</button></Link>
                
            </div>
            <div>
                {
                    notifications.length > 0 &&
                    <Table bordered hover responsive>
                        <thead>
                            <tr>
                                <th>#</th>
                                
                                <th>Título</th>
                                <th>Mensaje</th>
                                {/* <th>Link</th> */}
                                <th>País</th>
                                <th>Dominio</th>
                                <th>Fecha</th>                                
                                {/* <th>Clicks Web</th> */}
                                <th>Información</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                notifications.map(notification => {
                                    let onesignalData = (<div>
                                        <p className="text-nowrap">Enviado OneSignal {notification.onesignal_data ? ': '+ notification.onesignal_data.successful : ''}</p>
                                        <p>Clicks Web: {notification.web_views}</p>
                                        {/* <p>Click OneSignal: {
                                    notification.onesignal_data?notification.onesignal_data.converted:'0'}</p> */}

                                    </div>)
                                    //Convertir a fecha de zona de usuario y formatear
                                    let formatDate = dayjs(notification.created_at).tz().format('YYYY-MM-DD HH:mm:ss')
                                    return (
                                        <tr key={notification.id}>
                                            <td>{notification.id}</td>

                                            
                                            <td>{notification.title}</td>
                                            <td>{notification.message}</td>
                                            
                                            {/* <td>{notification.link}</td> */}
                                            <td>{notification.country ? notification.country.toUpperCase() :
                                                'Todos'}</td>
                                            <td>{notification.domain}</td>
                                            <td>{formatDate} </td>
                                            {/* <td>{notification.web_views}</td> */}
                                            <td className="notification-info">{onesignalData}</td>

                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </Table>
                }
                {
                    (!notifications || loading) &&
                    <div style={{height : 400}} className="w-100 d-flex align-items-center justify-content-center"><Lottie animationData={loaderIris} style={{ height: 100 }} loop /></div>
                }

            </div>
            {
                !loading && (!notifications ||notifications.length==0) &&
                <div className="text-center">
                 <p>No hay ninguna notificación</p>
                </div>                   
                   
            }
            
        </div>
    )
}