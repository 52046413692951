
const PrivacyCL = () => {

    return (
        <div className="container py-5">
            
            <h2 className="text-center mb-5">Políticas de privacidad</h2>
            <p>La protección de datos personales de nuestros usuarios es muy importante para YAPO.cl SpA.
            La presente Política de Privacidad describe cómo YAPO.cl SpA trata y protege los datos de los usuarios del sitio web iris.yapo.cl.</p>
            
            <br />
            <p><strong>Recopilación y uso de datos</strong></p>
            <p>YAPO.cl SpA obtiene los datos de los usuarios a través de mecanismos automatizados y mediante peticiones de registro.</p>
            <p>Los mecanismos automatizados generan registros de actividades de los usuarios, tendientes a establecer patrones de actividad y tráfico al interior del sitio. Se trata de información sobre la URL y nombre de dominio Agit log desde la cual se accede al sitio web, el número IP y browser que utiliza el usuario, el día y hora de acceso al sitio.</p>

            <p>Esta información no es registrada en forma personalizada y YAPO.cl SpA generalmente la borra después de 24 meses.</p>
            <p>Las peticiones de registro a los usuarios, tienen por objeto recopilar básicamente su nombre, teléfono, casilla electrónica y domicilio. En el caso de los usuario profesionales se pide adicionalmente su número de RUT. Esta información es recopilada y se obtiene solo si el usuario lo ha autorizado explícitamente.</p>
            <p>YAPO.cl SpA recopila esta información para identificar al usuario que establece una relación con el interesado en su producto a través de su sitio web yapo.cl, y poder prestarle los servicios asociados al uso de este. En el mismo sentido, YAPO.cl SpA utiliza cookies para recolectar información personal de los usuarios con el objeto de hacerles más eficiente el uso del sitio web yapo.cl, ya sea ahorrándole tiempo para publicar sus avisos o encontrar aquellos avisos que guardó como favoritos, entre otras cosas.</p>
            <p>La información personal recogida por YAPO.cl SpA, es usada como parte de sus actividades normales, pero además, estos datos podrán ser compartidos con las demás empresas pertenecientes al grupo Frontier Digital Venutes (360 Latam) si se considera necesario.</p>
            
            <br />
            <p><strong>Seguridad</strong></p>
            <p>YAPO.cl SpA utiliza un sistema de encriptación SSL para proteger la transmisión de información que entregan los usuarios de manera voluntaria en el sitio web iris.yapo.cl. Esta información es almacenada en forma segura, estableciendo mecanismos de control sobre las posibles pérdidas, mal uso, alteración o entrega no autorizada de dicha información.</p>
            
            <br />
            <p><strong>Calidad</strong></p>
            <p>YAPO.cl SpA recolecta, mantiene, usa, publica o distribuye la información personal vinculada con los usuarios, verificando que los datos sean correctos, completos y adecuados para cumplir con los fines para los que serán utilizados.</p>
            
            <br />
            <p><strong>Publicación e intercambio</strong></p>
            <p>Mediante la aceptación de esta Política de Privacidad el usuario autoriza expresamente a YAPO.cl SpA para comunicar sus datos a sus empresas afiliadas o relacionadas con las mismas finalidades que se han indicado para la recolección y uso de los datos personales por parte de YAPO.cl SpA, en relación con sus respectivos productos y servicios.</p>
            <p>El usuario autoriza también en forma expresa a YAPO.cl SpA para comunicar sus datos al Ministerio Público, Tribunales, Policía de Investigaciones y demás autoridades que requieran dicha información, sea en el marco de una investigación, por razones de interés público, o de cualquier otra manera, siempre con pleno resguardo a la Ley 19.628 sobre Protección a la Vida Privada o la legislación y normativa que la reemplace.</p>
            
            <br />
            <p><strong>Envío de información publicitaria a tu correo electrónico</strong></p>
            <p>La aceptación de las condiciones de uso y política de privacidad, ligada al uso de cualquiera de los servicios de YAPO.cl SpA, implica tu autorización expresa en favor de empresa para el envío de información comercial a la dirección de correo electrónico. Dicha autorización comprende el envío de publicidad comercial y publicitaria relacionada con las actividades, productos y servicios de YAPO.cl SpA sea por la empresa o sus proveedores especialmente contratados, quienes podrán tratar los datos personales de los usuarios de yapo.cl para este solo efecto.</p>
            
            <br />
            <p><strong>Verificación, corrección y eliminación de datos</strong></p>
            <p>En todo caso los usuarios podrán verificar, corregir y/o eliminar los datos que hayan registrado en el sitio web iris.yapo.cl, así como solicitar se deje sin efecto la autorización indicada en el acápite anterior. Para cualquiera de estas peticiones el usuario deberá contactarnos a iris@yapo.cl.</p>
            <p>Hecha la petición YAPO.cl SpA confirmará al usuario su recepción y la verificación, corrección y eliminación practicada según la solicitud realizada.</p>
            
            <br />
            <p><strong>Cambios a la Política de Privacidad</strong></p>
            <p>YAPO.cl SpA se reserva el derecho a modificar la presente Política de Privacidad, con el objeto de adaptarla a cambios legislativos o jurisprudenciales, a prácticas generales de la industria o a políticas internas de YAPO.cl SpA. Las modificaciones a la Política de Privacidad serán anunciadas a los usuarios en la misma forma que cualquier cambio a los Términos y Condiciones para el uso del sitio web iris.yapo.cl.</p>
            
            <br />
            <p><strong>Consultas</strong></p>
            <p>Si tiene alguna consulta acerca de la presente Política de Privacidad o las prácticas usadas en el sitio web iris.yapo.cl, sírvase a contactarnos a iris@yapo.cl.</p>
            

        </div>
    )
}

export default PrivacyCL