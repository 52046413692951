export const IconAC = ({ width = 18 }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4995 2.7V8.25H1.49951V2.7C1.49951 2.58065 1.54692 2.46619 1.63131 2.3818C1.71571 2.29741 1.83016 2.25 1.94951 2.25H16.0495C16.1689 2.25 16.2833 2.29741 16.3677 2.3818C16.4521 2.46619 16.4995 2.58065 16.4995 2.7V2.7Z"
        stroke="currentColor"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.499 5.25006H14.249"
        stroke="currentColor"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.49951 8.24994L2.09201 10.1879C2.18581 10.4952 2.37577 10.7642 2.63392 10.9554C2.89207 11.1465 3.20478 11.2498 3.52601 11.2499H4.49951"
        stroke="currentColor"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.499 8.24994L15.9065 10.1879C15.8127 10.4952 15.6228 10.7642 15.3646 10.9554C15.1065 11.1465 14.7938 11.2498 14.4725 11.2499H13.499"
        stroke="currentColor"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.12402 10.8751C7.12402 10.8751 7.12402 16.1251 4.49902 16.1251"
        stroke="currentColor"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.875 10.8751C10.875 10.8751 10.875 16.1251 13.5 16.1251"
        stroke="currentColor"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99902 10.8751V16.1251"
        stroke="currentColor"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
