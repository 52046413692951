import { Navigate, useLocation } from "react-router-dom";
import useUser from "../hooks/user/useUser";
import { List } from "./../fragments/listings/List";

const Listings = () => {
  const { isLoggedIn } = useUser();
  const { pathname } = useLocation();

  if (!isLoggedIn)
    return <Navigate to="/iniciar-sesion" replace state={{ from: pathname }} />;

  return (
    <main>
      <List />
    </main>
  );
};

export default Listings;
