import { useLocation, useNavigate } from "react-router-dom";

export const FeedGoBackArrow = ({ text }: { text: string }) => {
    const navigate = useNavigate();
    const { pathname, state } = useLocation();

    return (
        <button className="go-back d-flex align-items-center text-decoration-none mb-2 p-1 border-0 bg-transparent" onClick={() => navigate((state as any)?.from || "/feed")}>
            <i className="bi bi-arrow-left text-secondary fs-2"></i> <h4 className="mb-0 ms-3 text-secondary">{text}</h4>
        </button>)
}