import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { OperationType } from "../../interfaces/property";
import { UnitType } from "../../interfaces/project";
import { formatMoney } from "../../utils/Functions";
import useBreakpoint from "use-breakpoint";
import userFilter from "../../hooks/filter/useFilter";
import { BREAKPOINTS } from "../../utils/Constants";
import { Placeholder } from "react-bootstrap";
import useCountry from "../../hooks/config/useConfig";

export const PropertyPriceToShare = ({ units = [], currencyPrice = undefined, isCol = false, isBlocked = false, propertyTypeId , operation}:
  { units?: UnitType[], currencyPrice?: string, isCol?: boolean, isBlocked?: boolean, propertyTypeId: string,operation? : OperationType }) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");
  const [searchParams] = useSearchParams();
  const { filterState } = userFilter();
  const [currentMinPrice, setCurrentMinPrice] = useState(0);
  const [currentMaxPrice, setCurrentMaxPrice] = useState(0);
  const [filteredUnitByPrice, setFilteredUnitByPrice] = useState<UnitType>({} as UnitType)
  const [bedroomText, setBedroomText] = useState("");
  const [validFloat, setValidFloat] = useState("");
  const { translation } = useCountry();

  useEffect(() => {
    if (filterState.minPrice) setCurrentMinPrice(filterState.minPrice)
    else setCurrentMinPrice(0)

    if (filterState.maxPrice) setCurrentMaxPrice(filterState.maxPrice)
    else setCurrentMaxPrice(0)
  }, [searchParams]);

  useEffect(() => {
    if(operation){
      units = [...units.filter(u=>u.operation_type==operation)]
    }
    let orderedUnit = [...units];
    let res = "";

    if (filterState.minPrice) {
      // @ts-ignore
      units = units.filter((unit) => Number(unit.price_by_usd) >= filterState.minPrice && Number(unit.price_by_usd) > 0)
      orderedUnit = units.sort((a, b) => Number(a.price_by_project_currency) - Number(b.price_by_project_currency))
    }

    if (filterState.maxPrice && !filterState.minPrice) {
      // @ts-ignore
      units = units.filter((unit) => Number(unit.price_by_usd) <= filterState.maxPrice && Number(unit.price_by_usd) > 0)
      orderedUnit = units.sort((a, b) => Number(b.price_by_project_currency) - Number(a.price_by_project_currency))
    }

    if (filterState.order) {
      if (filterState.order?.includes("maxPrice")) {
        orderedUnit = orderedUnit.sort((a, b) => Number(b.price_by_project_currency) - Number(a.price_by_project_currency));
      }

      if (filterState.order?.includes("minPrice")) {
        orderedUnit = orderedUnit.sort((a, b) => Number(a.price_by_project_currency) - Number(b.price_by_project_currency));
      }
    }

    if (filterState.minPrice || filterState.maxPrice) {
      if (units.length > 0) {
        if (units[0].bedrooms === units[units.length - 1].bedrooms) { // Si es al unica unidad que hace fit con el precio
          res += getBedroomText(units[0].bedrooms);
        } else { // La primera unidad que hace fit con el filtro
          res += units[0].bedrooms > 0 ? `${units[0].bedrooms}` : translation.abbrStudio;
        }
        if (units[0].bedrooms < units[units.length - 1].bedrooms) { // La última unidad que hace fit con el filtro
          res += " a ";
          res += getBedroomText(units[units.length - 1].bedrooms);
        }
      }
    }

    if (!filterState.minPrice &&
      !filterState.maxPrice &&
      filterState.order?.includes("minPrice") &&
      filterState.order?.includes("maxPrice")
    ) orderedUnit = units.sort((a, b) => Number(a.price_by_project_currency) - Number(b.price_by_project_currency))

    const float = (orderedUnit[0]?.m2 ?? 0).toString().replace(",", ".")

    setBedroomText(res);
    setFilteredUnitByPrice(orderedUnit[0])
    setValidFloat(float)
  }, [units]);

  const getBedroomText = (count: number) => {
    return count > 0
      ? count > 1
        ? `${count} ${translation.short_bedroom}`
        : `1 ${translation.short_bedroom}`
      : translation.abbrStudio;
  };


  if (filteredUnitByPrice == null) return null; // retornar 404, validar cuando no haya rango ejemplo desde 10.000

  return (
    <div className={"property-price w-100 " + (breakpoint === "xs" ? "order-1" : "")}>
      <div className="price d-flex flex-column w-100 align-items-center">
        <small className="property-units-price lh-sm ">
            {operation === 'Venta' || operation === 'Alquiler'? operation:'' }
          </small>
        <small className="property-units-price lh-sm ">
          {
            
          }
          {currentMinPrice != 0 || currentMaxPrice != 0
            ? `${bedroomText} ${currentMinPrice != 0
              ? "desde"
              : "hasta"
            }`
            : propertyTypeId === "3" ? "Lotes desde" :'Unidades desde' 
          }
        </small>
        <div className={(!isCol ? "d-flex align-items-baseline justify-content-between w-100 flex-wrap" : "")}>
          {isBlocked ? (
            <Placeholder xs={5} className="text-light rounded mt-2" />
          ) : (
            <div className="price lh-1 mb-1">
              {currencyPrice ?? filteredUnitByPrice.currency} {formatMoney(filteredUnitByPrice.price)}
            </div>
          )}
          
        </div>
      </div>
    </div>
  );
};
