import { Navigate, useLocation } from "react-router-dom";
import useUser from "../../hooks/user/useUser";
import { List } from ".././../fragments/listings/List";
import { SpecificFilter } from "../../interfaces/filter";
import { useEffect, useState } from "react";
import { useProjects } from "../../hooks/projects/useProjects";

export const UsedProjects = () => {

  const { isLoggedIn } = useUser();
  const { pathname } = useLocation();
  const { loadingProjectStatus, projectStatusUsed } = useProjects()
  const [specificFilter, setSpecificFilter] = useState<SpecificFilter>({
    filter : undefined,
    use : true,
    loading : true
  })


  useEffect(() => {

    if (projectStatusUsed) {
      setSpecificFilter({
        use : true,
        filter : {
          state: [projectStatusUsed]
        },
        loading : false
      })
    }

  }, [projectStatusUsed])

  useEffect(() =>{
    if(!loadingProjectStatus) setSpecificFilter((prev : SpecificFilter) => ({...prev , loading : false }))
  },[loadingProjectStatus])


  if (!isLoggedIn)
    return <Navigate to="/iniciar-sesion" replace state={{ from: pathname }} />;


  return (
    <main >
      <List specificFilter={specificFilter} />
    </main>
  );


}
