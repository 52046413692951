
import JsPDF from 'jspdf';
import { EstimationGenerated } from './EstimationGenerated';
import { Button, Form, Col, Spinner, Row } from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';
import { GarageType, ProjectType, UnitType, WarehouseType } from '../../interfaces/project';
import { NumberInput } from '../ui/Forms/NumberInput';
import { useCurrency } from '../../hooks/currency/useCurrency';
import SpecialSelect from '../ui/SpecialSelect';
import { getBase64ImageFromUrl } from '../../utils/Functions';
import useUser from '../../hooks/user/useUser';
import { sendEmail } from '../../data/api';
import { EstimatorBodyType } from '../../interfaces/email';
import { useGoogleAnalytics } from '../../hooks/googleAnalytics/useGoogleAnalytics';
import useConfig from '../../hooks/config/useConfig';

const CURRENCY_TO_ESTIMATE = 'UF'
export interface EstimatorData {
    unit?: UnitType,
    unit_id: number,
    garage?: GarageType,
    garage_id?: number,
    warehouse?: WarehouseType,
    warehouse_id?: number,
    price_selected: any,
    price: string,
    garage_price_selected: any,
    garage_price: string,
    warehouse_price_selected: any,
    warehouse_price: string,
    pie: string,
    bank_financing: number,
    unit_plan: string,
    inmo_img: string,
    exchange_rate: number,
    garage_exchange_rate?: number,
    warehouse_exchange_rate?: number
}
export const Estimator = (
    { project }: { project: ProjectType }
) => {

    const initialData: EstimatorData = {
        unit: project.units[0] || undefined,
        unit_id: project.units[0]?.id,
        garage: undefined,
        warehouse: undefined,
        garage_id: project.garages ? project.garages[0]?.id : undefined,
        warehouse_id: project.warehouses ? project.warehouses[0]?.id : undefined,
        price_selected: '',
        price: '',
        garage_price_selected: '',
        garage_price: '',
        warehouse_price_selected: '',
        warehouse_price: '',
        pie: '',
        bank_financing: 0,
        unit_plan: '',
        inmo_img: '',
        exchange_rate: 0,
        garage_exchange_rate: 0,
        warehouse_exchange_rate: 0
    }

    const defaultMessages = {
        unit_id: 'Este campo es requerido',
        price_selected: 'Este campo es requerido',
        price: 'Este campo es requerido',
        garage_id: 'Este campo es requerido',
        garage_price_selected: 'Este campo es requerido',
        garage_price: 'Este campo es requerido',
        warehouse_id: 'Este campo es requerido',
        warehouse_price_selected: 'Este campo es requerido',
        warehouse_price: 'Este campo es requerido',
        pie: 'Este campo es requerido',

    }
    const [fieldsErrorMessages, setFieldsErrorMessages] = useState(defaultMessages)


    const componentRef = useRef(null);
    const { convertCurrency } = useCurrency()
    const [validated, setValidated] = useState(false)
    const [optionsUnits, setOptionsUnits] = useState<any[]>([])
    const [errorMessage, setErrorMessage] = useState('')
    const [pageHeight, setPageHeight] = useState(0)

    const [data, setData] = useState(initialData)
    const [loading, setLoading] = useState(false)
    const { userState } = useUser()
    const { country, translation } = useConfig()

    const GA = useGoogleAnalytics()

    const garagesOptions = project.garages?.map(g => ({ value: g.id, label: g.name })) || []
    const warehousesOptions = project.warehouses?.map(g => ({ value: g.id, label: g.name })) || []

    useEffect(() => {
        let unitsOptions: any[] = []

        unitsOptions = unitsOptions.concat(project.units.map(u => {
            return { value: u.id, label: u.number }
        }))
        setOptionsUnits(unitsOptions)
    }, [])

    const setValue = (e: any) => {
        let newValue = e.target.value

        setData((prev) => ({ ...prev, [e.target.name]: newValue }))

        if (e.target.name == 'unit_id') {
            let unitData = project.units.find(u => u.id == newValue)
            setData(prev => ({ ...prev, unit: unitData , price : '', price_selected : '', exchange_rate : 0 }))
        }

        if (e.target.name == 'garage_id') {
            let garageData = project.garages?.find(u => u.id == newValue)

            setData(prev => (
                {
                    ...prev,
                    garage: garageData,
                    garage_price:  '',
                    garage_price_selected: '',
                    garage_exchange_rate : 0
                }))
        }
        if (e.target.name == 'warehouse_id') {
            let warehouseData = project.warehouses?.find(u => u.id == newValue)
            setData(prev => (
                {
                    ...prev,
                    warehouse: warehouseData,
                    warehouse_price:  '',
                    warehouse_price_selected:   '',
                    warehouse_exchange_rate : 0
                }))
        }

        if (e.target.name == 'price_selected') {
            if (newValue == 'Otro' || newValue == 'Ninguno') setData(prev => ({ ...prev, price: '' }))
            else setData(prev => ({ ...prev, price: newValue }))
        }

        if (e.target.name == 'garage_price_selected') {
            if (newValue == 'Otro' || newValue == 'Ninguno') setData(prev => ({ ...prev, garage_price: '' }))
            else setData(prev => ({ ...prev, garage_price: newValue }))
        }

        if (e.target.name == 'warehouse_price_selected') {
            if (newValue == 'Otro' || newValue == 'Ninguno') setData(prev => ({ ...prev, warehouse_price: '' }))
            else setData(prev => ({ ...prev, warehouse_price: newValue }))
        }

    }


    const resetForm = (form: any) => {
        Object.keys(defaultMessages).forEach(key => {
            try {
                form[key]?.setCustomValidity('')
            } catch (error: any) {
                console.log(error.toString())
            }

        })
        setFieldsErrorMessages(defaultMessages);
    }

    const sendMailEstimator = () => {
        if (data.unit) {
            const dataEmail: EstimatorBodyType = {
                form_type: 'estimator',
                countryCode: country,
                unit: data.unit.identifier,
                unit_number: data.unit.number,
                garage : data.garage?.identifier,
                garage_name : data.garage?.name,
                garage_price : data.garage_price,
                warehouse : data.warehouse?.identifier,
                warehouse_name : data.warehouse?.name,
                warehouse_price : data.warehouse_price,
                id_project: project.identifier,
                title_project: project.title,
                currency: CURRENCY_TO_ESTIMATE,
                price: data.price,
                pie: data.pie,
                id_inmo: userState.id,
                name_inmo: `${userState.name} - ${userState.real_estate}`,
                email_inmo: userState.email,
                phone_inmo: userState.phone,
                project_country: project.country
            }
            sendEmail(dataEmail)
        }

    }
    const handleGenerate = async (ev: any) => {

        ev.preventDefault()
        setErrorMessage('')
        let form = ev.currentTarget;

        Object.keys(data).forEach(key => {
            try {
                form[key]?.setCustomValidity('')
            } catch (error: any) { console.log("error aqui : " + key + " " + error.toString()) }

        })

        let priceUF = data.price
        let validPrice = true

        //Si eligió uno de los que tiene la unidad
        if (data.price_selected != 'Otro' && data.unit?.currency && data.unit?.currency != CURRENCY_TO_ESTIMATE) {
            validPrice = false
            let _data = { ...data }
            const convert = convertCurrency(data.unit?.currency, CURRENCY_TO_ESTIMATE, parseFloat(data.price))

            if (convert && convert.success) {
                priceUF = convert.value.toString()
                validPrice = true
                setData(prev => ({ ...prev, price: priceUF, exchange_rate: convert.exchange_rate_to_from }))
            }
        }

        //Si eligió uno de los que tiene el garage
        if (data.garage_price_selected != 'Otro' && data.garage?.currency && data.garage?.currency != CURRENCY_TO_ESTIMATE) {
            validPrice = false
            const convert = convertCurrency(data.garage?.currency, CURRENCY_TO_ESTIMATE, parseFloat(data.garage_price))

            if (convert && convert.success) {
                priceUF = convert.value.toString()
                validPrice = true
                setData(prev => ({ ...prev, garage_price: priceUF, garage_exchange_rate: convert.exchange_rate_to_from }))
            }
        }

        //Si eligió uno de los que tiene la bodega
        if (data.warehouse_price_selected != 'Otro' && data.warehouse?.currency && data.warehouse?.currency != CURRENCY_TO_ESTIMATE) {
            validPrice = false
            const convert = convertCurrency(data.warehouse?.currency, CURRENCY_TO_ESTIMATE, parseFloat(data.warehouse_price))

            if (convert && convert.success) {
                priceUF = convert.value.toString()
                validPrice = true
                setData(prev => ({ ...prev, warehouse_price: priceUF, warehouse_exchange_rate: convert.exchange_rate_to_from }))
            }
        }


        if (!validPrice) {
            setErrorMessage('No se pudo convertir a ' + CURRENCY_TO_ESTIMATE)
            return false;
        }


        resetForm(form)
        setValidated(true);
        let validForm = form.checkValidity()

        if (parseFloat(data.pie) > 100) {
            setFieldsErrorMessages(prev => ({ ...prev, pie: 'El pie debe ser <= 100' }))
            form['pie']?.setCustomValidity('invalid')
            return false;
        }

        console.log("data ", data)

        if (validForm) {
            setLoading(true)

            const report = new JsPDF({
                orientation: 'portrait',
                unit: 'px',
                hotfixes: ['px_scaling']
            });
            const widthPage = report.internal.pageSize.getWidth();
            const heightPage = report.internal.pageSize.getHeight();
            setPageHeight(heightPage - 1)

            //Convertir imágenes
            if (data.unit?.plan) {
                await getBase64ImageFromUrl(data.unit?.plan).then(res => {

                    if (res && typeof res == 'string') {
                        setData(prev => ({ ...prev, unit_plan: res }))
                    }
                }).catch(err => console.log(err))
            }

            if (userState.image) {
                await getBase64ImageFromUrl(userState.image).then(res => {

                    if (res && typeof res == 'string') {
                        setData(prev => ({ ...prev, inmo_img: res }))
                    }
                }).catch(err => console.log(err))
            }


            try {

                const fileName = `Cotización ${project.title} Unidad ${data.unit?.number} .pdf`
                const element = componentRef.current
                if (element) {

                    //@ts-ignore
                    report.html(element, {
                        autoPaging: 'text',
                        width: widthPage,
                        windowWidth: widthPage,

                    }).then(() => {
                        setLoading(false)
                        report.save(fileName);
                    }).catch(err => {
                        setLoading(false)
                        console.log(err)
                    })
                } else {
                    setLoading(false)
                }

            } catch (error) {
                console.error(error)
                setLoading(false)
            } finally {

                sendMailEstimator()
                GA.Event({ category: "User estimates unit", action: "user_estimates_unit", label: project.title , value: Number(userState.id) })

            }
        }

    }

    return (
        <div className="container">

            <div className="row">
                <div className="col ">
                    <h2 className='fs-5 fw-bold mb-3 text-center'>Cotizador</h2>


                    <Form onSubmit={handleGenerate} noValidate validated={validated}>

                        <Row>
                            <Col lg="6" sm="12">
                                <Form.Group controlId="unit_id">
                                    <Form.Label className="fw-bold text-secondary">Unidad</Form.Label>
                                    <SpecialSelect
                                        options={optionsUnits}
                                        onChange={(option: any) => setValue({ target: { name: 'unit_id', value: option.value } })}
                                        selected={{ value: data.unit_id, label: data.unit?.number }}
                                        placeholder="Elige una unidad..."
                                        noOptionsMessage={'No se encontraron unidades'}
                                        setInvalid={!data.unit_id ? true : false}
                                    />
                                </Form.Group>

                            </Col>

                            <Col lg="6" sm="12">

                                <Form.Group controlId="price" >
                                    <Form.Label className="fw-bold text-secondary">Precio</Form.Label>
                                    <div className='d-flex align-items-start'>
                                        <Form.Select name='price_selected' required
                                            className=" w-50 " style={{ flex: 1 }}
                                            value={data.price_selected}
                                            onChange={setValue}>
                                            <option disabled value={''} >Elige un precio</option>
                                            {
                                                data.unit &&
                                                <>
                                                    <option value={data.unit?.list_price}>
                                                        Precio de lista: {data.unit?.currency} {data.unit?.list_price}
                                                    </option>
                                                    {
                                                        data.unit?.final_price && data.unit?.final_price != data.unit?.list_price && <option value={data.unit?.final_price}>Precio final: {data.unit?.currency}  {data.unit?.final_price}</option>
                                                    }
                                                </>
                                            }

                                            <option value={'Otro'}>Otro (en {CURRENCY_TO_ESTIMATE})</option>
                                        </Form.Select>
                                        {
                                            data.price_selected == 'Otro' &&
                                            <div style={{ flex: 1 }} className='ms-2'>
                                                <NumberInput
                                                    required={data.price_selected == 'Otro'}
                                                    onChange={setValue}
                                                    name='price'

                                                />
                                                <div className="invalid-feedback">
                                                    {fieldsErrorMessages.price}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <Form.Control.Feedback type="invalid">
                                        {fieldsErrorMessages.price_selected}
                                    </Form.Control.Feedback>


                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className='mt-0 mt-md-2'>

                            <Col lg="6" sm="12">
                                <Form.Group controlId="garage_id">
                                    <Form.Label className="fw-bold text-secondary">{translation.garage}</Form.Label>
                                    <SpecialSelect
                                        options={garagesOptions}
                                        onChange={(option: any) => setValue({ target: { name: 'garage_id', value: option.value } })}
                                        selected={{ value: data.garage_id, label: data.garage?.name }}
                                        placeholder={`Elige una ${translation.garage}`}
                                        noOptionsMessage={'No se encontraron estacionamientos'}
                                    />

                                </Form.Group>
                            </Col>

                            <Col lg="6" sm="12">


                                <Form.Group controlId="garage_price" >
                                    <Form.Label className="fw-bold text-secondary">Precio</Form.Label>
                                    <div className='d-flex align-items-start'>
                                        <Form.Select name='garage_price_selected'
                                            required={!!data.garage}
                                            className=" w-50 " style={{ flex: 1 }}
                                            value={data.garage_price_selected}
                                            onChange={setValue}>
                                            <option disabled value={''} >Elige un precio</option>
                                            {
                                                data.garage &&
                                                <>
                                                    <option value={data.garage?.price}>
                                                        Precio: {data.garage?.currency} {data.garage?.price}
                                                    </option>
                                                    <option value={'Otro'}>Otro (en {CURRENCY_TO_ESTIMATE})</option>
                                                </>
                                            }

                                        </Form.Select>
                                        {
                                            data.garage_price_selected == 'Otro' &&
                                            <div style={{ flex: 1 }} className='ms-2'>
                                                <NumberInput
                                                    required={data.garage_price_selected == 'Otro'}
                                                    onChange={setValue}
                                                    name='garage_price'

                                                />
                                                <div className="invalid-feedback">
                                                    {fieldsErrorMessages.garage_price}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <Form.Control.Feedback type="invalid">
                                        {fieldsErrorMessages.price_selected}
                                    </Form.Control.Feedback>
                                </Form.Group>



                            </Col>

                        </Row>

                        <Row className='mt-0 mt-md-2'>

                            <Col lg="6" sm="12">
                                <Form.Group controlId="warehouse_id">
                                    <Form.Label className="fw-bold text-secondary">{translation.warehouse}</Form.Label>
                                    <SpecialSelect
                                        options={warehousesOptions}
                                        onChange={(option: any) => setValue({ target: { name: 'warehouse_id', value: option.value } })}
                                        selected={{ value: data.warehouse_id, label: data.warehouse?.name }}
                                        placeholder={`Elige una ${translation.warehouse}`}
                                        noOptionsMessage={'No se encontraron ' + translation.warehouses.toLowerCase()}
                                    />

                                </Form.Group>
                            </Col>

                            <Col lg="6" sm="12">

                                <Form.Group controlId="warehouse_price" >
                                    <Form.Label className="fw-bold text-secondary">Precio</Form.Label>
                                    <div className='d-flex align-items-start'>
                                        <Form.Select name='warehouse_price_selected' 
                                            required={!!data.warehouse}
                                            className=" w-50 " style={{ flex: 1 }}
                                            value={data.warehouse_price_selected}
                                            onChange={setValue}>
                                            <option disabled value={''} >Elige un precio</option>
                                            {
                                                data.warehouse && <>
                                                <option value={data.warehouse?.price}>
                                                    Precio: {data.warehouse?.currency} {data.warehouse?.price}
                                                </option>
                                                <option value={'Otro'}>Otro (en {CURRENCY_TO_ESTIMATE})</option></>
                                            }
                                            
                                        </Form.Select>
                                        {
                                            data.warehouse_price_selected == 'Otro' &&
                                            <div style={{ flex: 1 }} className='ms-2'>
                                                <NumberInput
                                                    required={data.warehouse_price_selected == 'Otro'}
                                                    onChange={setValue}
                                                    name='warehouse_price'
                                                />
                                                <div className="invalid-feedback">
                                                    {fieldsErrorMessages.warehouse_price}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <Form.Control.Feedback type="invalid">
                                        {fieldsErrorMessages.price_selected}
                                    </Form.Control.Feedback>


                                </Form.Group>

                            </Col>

                        </Row>

                        <Row className='mt-0 mt-md-2'>
                            <Col lg="12" sm="12">
                                <Form.Group controlId="pie" className='mt-2 ' >
                                    <Form.Label className="fw-bold text-secondary">Pie (%)</Form.Label>
                                    <NumberInput
                                        required={true}
                                        onChange={setValue}
                                        name='pie'
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {fieldsErrorMessages.pie}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>


                        </Row>


                        <div className='text-center'>
                            <button className="btn btn-primary mt-4" type='submit' disabled={loading} >
                                Generar cotización
                                {
                                    loading &&
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="me-2"
                                    />
                                }

                            </button>
                            <div className="invalid-feedback d-block">
                                {errorMessage}
                            </div>
                        </div>

                    </Form>


                    <div style={{ display: 'none' }}>
                        <div ref={componentRef}  >
                            {
                                data.unit &&
                                <EstimationGenerated
                                    project={project}
                                    allData={data}
                                    unit={data.unit}
                                    pie={parseFloat(data.pie)}
                                    price={parseFloat(data.price)}
                                    unit_plan_encoded={data.unit_plan}
                                    inmo_logo_encoded={data.inmo_img}
                                    pageHeight={pageHeight}
                                    currency={CURRENCY_TO_ESTIMATE}
                                    exchange_rate={data.exchange_rate}
                                />
                            }

                        </div>

                    </div>

                </div>
            </div>

        </div>
    )
} 