
import { useEffect } from "react";
import TitleCardTS from "../../daisyUI/components/Cards/TitleCardTs"
import UserFavoritesList from "../../fragments/user/favorites/UserFavoritesList"
import { useGoogleAnalytics } from "../../hooks/googleAnalytics/useGoogleAnalytics";
import useUser from "../../hooks/user/useUser";

const UserFavorites = () => {

    const GA = useGoogleAnalytics(true);
    const {userState} = useUser()
    useEffect(()=> {
        GA.Event({ category: "User open favorites", action: "user_open_favorites", label: `${userState.name}`, value: Number(userState.id) });
    },[])

    return(
        <>
            <TitleCardTS title="Mis Favoritos" topMargin="tw-mt-2">
                <UserFavoritesList />
            </TitleCardTS>
        </>
    )
}


export default UserFavorites