import { PopupInfo } from "../../interfaces/ui";

export const PopupsInfo: PopupInfo[] = [
    {
        country: 'UY',
        popups: [
            {
                name : 'Talks',
                mobileImg: '/images/preheaders-popups/talks-Popup-Iris-Mobile.png',
                desktopImg: '/images/preheaders-popups/talks-Popup-Iris-Desk.png',
                link: 'https://www.infocasas.com.uy/proyectos/infocasas-talks/amp?utm_source=web&utm_medium=iris_popup',
                cookieName: 'talks2UY',
                endDate: '2024-09-11',
                active : true
            }
        ]
    },
    {
        country: 'CO',
        popups: [
            {
                name : 'Iris Academy',
                mobileImg: 'https://cdn1.infocasas.com.uy/web/6706ba3392cdd_mobile.png',
                desktopImg: 'https://cdn1.infocasas.com.uy/web/6706ba33b2a42_desktop.png',
                link: 'https://amp.fincaraiz.com.co/iris-webinar-academic-15-octubre/amp?utm_campaign=iris-webinar-academic&utm_source=Iris&utm_medium=Banner-feed&utm_content=20241011-banner--&utm_term=eventos-suscription-oct2024-iriswebinaracademic',
                cookieName: 'irisAcademy',
                active : true,
                endDate : '2024-10-15'
            },
        ]
    },
    {
        country : 'PA',
        popups : [
            {
                name : 'IrisOpenDayPA',
                desktopImg: 'https://cdn1.infocasas.com.uy/web/66f5cfedda7ae_desktop.png',
                mobileImg: 'https://cdn1.infocasas.com.uy/web/66f5cfeda9e07_mobile.png',
                link: "https://amp.encuentra24.com/od_iris_panama_pacifico/pa?utm_source=web&utm_medium=iris_popup",
                cookieName: 'IrisOpenDayPA',
                active: true,
                endDate : '2024-10-03'
            }
        ]
    },
    {
        country : 'CL',
        popups : [
            {
                name : 'Yapo Iris cyber',
                desktopImg: 'https://cdn1.infocasas.com.uy/web/66fad9fa69225_pop-up-generico-desktop.png',
                mobileImg: 'https://cdn1.infocasas.com.uy/web/66fad9fa63863_pop-up-generico-mobile.png',
                link: "https://landing.yapo.cl/iris-cyber-2024",
                cookieName: 'yapoIrisCyber',
                active: true,
                endDate : '2024-10-14'
            }
        ]
    }
]