import moment from "moment"
import { useEffect, useState } from "react"
import TitleCard from "../../../daisyUI/components/Cards/TitleCardTs"
import SearchBar from "../../../daisyUI/components/Input/SearchBar"
import { useUserFetch } from "../../../hooks/fetch/useUserFetch"
import useConfig from "../../../hooks/config/useConfig"
import { UserOffer } from "../../../interfaces/user"
import { Link } from "react-router-dom"
import Pagination from "../../../components/ui/pagination/Pagination"
import "./UserActivities.scss"
import Lottie from "lottie-react";
import loaderIris from "../../../lotties/orange-loader.json";

const TopSideButtons = ({ applySearch, className }:
    { applySearch: any, className?: string }) => {

    const [searchText, setSearchText] = useState("")

    useEffect(() => {
        applySearch(searchText)
    }, [searchText])

    return (
        <div className={"tw-inline-block tw-float-right " + className}>
            <SearchBar searchText={searchText} styleClass="tw-mr-4" setSearchText={setSearchText} placeholderText={'Buscar proyecto'} />
        </div>
    )
}


function UserOffers() {

    const MAX_PAGES = 3
    const LIMIT = 10
    const INITIAL_PAGE = 1
    const { getOffers } = useUserFetch()
    const [offers, setOffers] = useState<UserOffer[]>([])
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(INITIAL_PAGE)
    const [totalPages, setTotalPages] = useState<number>()
    const [searchTerm, setSearchTerm] = useState('')
    const [showTableBody, setShowTableBody] = useState(false)
    const [hasSome , setHasSome] = useState(false)
    const {translation} = useConfig()

    useEffect(() => {
        setLoading(true)
        loadOffers(true,'', LIMIT, INITIAL_PAGE)
        
    }, [])

    useEffect(() => {
        setShowTableBody(offers.length > 0 ? true : false)
    }, [offers,searchTerm])

    const loadOffers = async (firstLoad : boolean ,search?: string, loadLimit?: number, page?: number) => {

        return getOffers(search, loadLimit, page).then(response => {
            setLoading(false)
            if (response.success) {
                setOffers(response.data)
                if (!loadLimit) loadLimit = LIMIT
                let total_pages = Math.ceil(response.total / loadLimit)
                setTotalPages(total_pages)

                if(response.data.length > 0 && firstLoad) setHasSome(true)

            } else {
                setOffers([])
                setTotalPages(0)
            }
            return true
        }).catch(err => {
            console.error(err)
            setLoading(false)
        })
    }


    // Search according to name
    const applySearch = (value: any) => {
        setSearchTerm(value)
        loadOffers(false,value, LIMIT, INITIAL_PAGE)
    }

    const handlePagination = (pageNav: number) => {
        if (pageNav != currentPage) {
            setCurrentPage(pageNav)
            loadOffers(false,'', LIMIT, pageNav)
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }

    }


    return (
        <>
            <div className={`user-activities ${(!offers || offers.length == 0) && !loading ? 'empty' : ''}`}>
                <TitleCard title={'Ofertas Enviadas'} topMargin="tw-pt-4 tw-border "
                    TopSideButtons={ <TopSideButtons applySearch={applySearch} className={loading || !hasSome  ? ' tw-hidden ' : ''} /> }>


                    <div className="tw-overflow-x-auto tw-w-full tw-mt-6">
                        {/* Loader */}
                        {
                            loading && <main className="user-guarantees main container p-3 p-sm-5">
                                <div style={{ height: 400 }} className="w-100 d-flex align-items-center justify-content-center">
                                    <Lottie animationData={loaderIris} style={{ height: 100 }} loop /></div>
                            </main>
                        }

                        {/* Table */}
                        {
                            !loading &&
                            <>
                                <table className="tw-table tw-w-full responsive-table ">
                                    <thead>
                                        <tr>
                                            <th>Proyecto</th>
                                            <th>Unidad</th>
                                            <th>{translation.garage}</th>
                                            <th>Forma de Pago</th>
                                            <th>Oferta Enviada</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    {
                                        showTableBody ?
                                            <tbody>
                                                {
                                                    offers.map((o, k) => {
                                                        return (
                                                            <tr key={k}>
                                                                <td>
                                                                    <span className="mobile-title">Projecto: </span> <p>{o.project.title}</p>
                                                                </td>
                                                                <td><span className="mobile-title">Unidad: </span> {o.unit.number}</td>
                                                                <td><span className="mobile-title">{translation.garage}: </span> {o.garage?.name}</td>
                                                                <td><span className="mobile-title">Pago: </span> {o.payment_method ? o.payment_method : '-'}</td>
                                                                <td><span className="mobile-title">Fecha: </span> {moment(o.created_at).format("D/M/yyyy")}</td>
                                                                <td>
                                                                    <Link target="_blank" to={`/proyecto/${o.project.identifier}?operation=Venta`} >
                                                                        <button className="btn btn-outline-primary" >Ver proyecto</button>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody> : ''
                                    }

                                </table>

                                {showTableBody && totalPages && <Pagination currentPage={currentPage} handlePagination={handlePagination} totalPages={totalPages} maxPages={MAX_PAGES} />}
                            </>
                        }


                        {/* Message */}
                        {
                            (!offers || offers.length == 0) && !loading &&
                            <p className="text-center mt-4">{searchTerm == '' || !searchTerm ? 'Aún no existen ofertas registradas' :
                                'No se encontró ninguna oferta que coincida'}
                            </p>
                        }

                    </div>

                </TitleCard>
            </div>


        </>
    )
}


export default UserOffers