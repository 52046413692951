export const IconLaundry = ({ width = 20 }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0002 1.67502L5.00016 1.66669C4.07516 1.66669 3.3335 2.40835 3.3335 3.33335V16.6667C3.3335 17.5917 4.07516 18.3334 5.00016 18.3334H15.0002C15.9252 18.3334 16.6668 17.5917 16.6668 16.6667V3.33335C16.6668 2.40835 15.9252 1.67502 15.0002 1.67502ZM15.0002 16.6667H5.00016L4.99183 3.33335H15.0002V16.6667Z"
        fill="currentColor"
      />
      <path
        d="M6.66683 5.83335C7.12707 5.83335 7.50016 5.46026 7.50016 5.00002C7.50016 4.53978 7.12707 4.16669 6.66683 4.16669C6.20659 4.16669 5.8335 4.53978 5.8335 5.00002C5.8335 5.46026 6.20659 5.83335 6.66683 5.83335Z"
        fill="currentColor"
      />
      <path
        d="M9.16683 5.83335C9.62707 5.83335 10.0002 5.46026 10.0002 5.00002C10.0002 4.53978 9.62707 4.16669 9.16683 4.16669C8.70659 4.16669 8.3335 4.53978 8.3335 5.00002C8.3335 5.46026 8.70659 5.83335 9.16683 5.83335Z"
        fill="currentColor"
      />
      <path
        d="M10.0002 15.8333C12.3002 15.8333 14.1668 13.9667 14.1668 11.6667C14.1668 9.36667 12.3002 7.5 10.0002 7.5C7.70016 7.5 5.8335 9.36667 5.8335 11.6667C5.8335 13.9667 7.70016 15.8333 10.0002 15.8333ZM11.9668 9.7C13.0502 10.7833 13.0502 12.55 11.9668 13.6333C10.8835 14.7167 9.11683 14.7167 8.0335 13.6333L11.9668 9.7Z"
        fill="currentColor"
      />
    </svg>
  );
};
