import { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { CustomImage } from "../CustomImage/CustomImage";
import useCountry from "../../hooks/config/useConfig";

export const CoordinatiorSection = () => {
  const [modal, setModal] = useState(false);
  const { country } = useCountry();

  const coordinators = [
    {
      image:
        "https://cdn1.infocasas.com.uy/web/627d7242efcfd_noelia-rivera.jpeg",
      name: "Noelia Rivera",
      phone: "+598 97 259 343",
      email: "iris@infocasas.com.uy",
      country: "UY"
    },
    {
      image:
        "https://cdn1.infocasas.com.uy/web/636c09604b43f_coordinadora-py.jpeg",
      name: "Andrea Garcia de Zuñiga",
      phone: "+595 991 850714",
      email: "agarcia@infocasas.com.py",
      country: "PY"
    },
    {
      image:
        "https://cdn1.infocasas.com.uy/web/64c1865e8f7e6_maritza.png",
      name: "Maritza Lamila",
      phone: "+56 9 8310 6930",
      email: "iris@yapo.cl",
      country: "CL"
    },
    {
      image: "https://cdn1.infocasas.com.uy/web/654a3c0bdc100_diana2.png",
      name: "Diana León",
      phone: "+57 316 0188420",
      email: "iris@fincaraiz.com.co",
      country: "CO"
    },
    {
      image: "https://cdn1.infocasas.com.uy/web/656f399ed5939_thairy-foto-short.jpg",
      name: "Thairy Padilla",
      phone: "+5076923-4819",
      email: "iris@encuentra24.com",
      country: "PA"
    },
  ];

  const coordinatorsCountry = coordinators.filter((c) => c.country === country)

  return (
    <>
      <div className="coordinator-section d-flex align-items-center justify-content-between flex-lg-row flex-column">
        <span className="fs-5 mb-3 mb-lg-0 text-lg-start text-center">
          ¿Te quedo alguna duda o necesitás más información?
        </span>
        <Button
          variant="outline-primary"
          className="fs-6 fw-500 py-2 px-4 fw-normal"
          onClick={() => setModal(true)}
        >
          Contacta un coordinador IRIS
        </Button>
      </div>
      <Modal
        show={modal}
        onHide={() => setModal(false)}
        centered
        contentClassName="bg-xs-light border-0 coordinator-modal"
      >
        <Modal.Header closeButton className="border-bottom-0 pb-0">
          <Modal.Title
            className="d-flex flex-column w-100 text-center"
            style={{ marginRight: -24 }}
          >
            <span className="text-primary">¡Estoy para ayudarte!</span>
            <span className="fs-sm fw-normal">
              Envíame un mensaje y responderé todas tus dudas
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-1 px-3 pb-3 px-lg-4 pb-lg-4">
          {coordinatorsCountry.map((c, index) => (
            <div
              className="bg-white rounded-3 shadow-lg px-2 px-lg-3 py-3 py-lg-4 d-flex align-items-center justify-content-center mt-3 flex-column flex-lg-row "
              key={"coordinator_" + index}
            >
              <CustomImage
                src={c.image}
                alt={c.name}
                classes="rounded-circle flex-shrink-1"
                styles={{ width: 110, height: 110, objectFit: "cover" }}
                width={150}
                height={150}
              />
              <div className="d-flex flex-column align-items-center align-items-lg-start ms-lg-3 mt-2 mt-lg-0">
                <span className="mb-1">{c.name}</span>
                <span className="bg-light text-secondary text-uppercase fs-xxs px-1 py-1 rounded-1 fw-500 lh-1 mb-2">
                  Coordinador Iris
                </span>
                <a
                  className="d-flex align-items-center text-primary px-2 py-1 rounded-pill fs-sm text-decoration-none mt-1 bg-primary-light"
                  role={"button"}
                  href={
                    "https://wa.me/" +
                    c.phone.replaceAll(" ", "").replace("+", "").replace("-", "")
                  }
                >
                  <i className="bi bi-whatsapp me-2"></i>
                  {c.phone}
                </a>
                <a
                  className="d-flex align-items-center text-primary px-2 py-1 rounded-pill fs-sm  text-decoration-none mt-1 bg-primary-light"
                  role={"button"}
                  href={"mailto:" + c.email}
                >
                  <i className="bi bi-envelope me-2"></i>
                  {c.email}
                </a>
              </div>
            </div>
          ))}
        </Modal.Body>
      </Modal>
    </>
  );
};
