
export const TermsConditionsPA = () => {

    return (
        <div className="container p-3 py-5">
            <h3 className="text-center mb-2 fw-bold">TÉRMINOS Y CONDICIONES CON EMPRESAS DE BIENES RAÍCES Y BROKERS INDEPENDIENTES ASOCIADAS IRIS</h3>
            <p className="mt-4 mb-5 text-start fs-5" style={{textDecoration : 'underline' }}>Encuentra24 LE DA LA BIENVENIDA Y LE INFORMA E INVITA A TENER PRESENTE, QUE:</p>

            <p className="mt-2">Los Términos “nosotros”, “nuestro”, “IP”, “ENCUENTRA24” se refieren a “SWISS PANAMA GROUP CORP,”. El término “Usted”, “Usuario” , “Agente de bienes raíces” o “Brokers”, se refiere al operador inmobiliario que participe del servicio Iris <a href="https://iris.encuentra24.com" target="_blank" className="text-primary" style={{textDecoration : 'underline'}}>https://iris.encuentra24.com</a></p>

            <p className="mt-2">Encuentra24 es una empresa cuyo objeto principal es el desarrollo de un portal informático y de servicios a través el cual realiza publicidad de distintos operadores que permita satisfacer a sus clientes mediante el ofrecimiento de inmuebles para su venta, encontrándose debidamente inscrita ante los organismos correspondientes a dichos efectos.</p>
            <p className="mt-2">Encuentra24 ha desarrollado un servicio llamado Iris, que tiene el objeto de proveer una amplia oferta de obra nueva, a todas las Bienes raíces de los países donde se encuentre presente, para que puedan comercializar en un entorno claro, justo, y transparente.</p>
            

            <p className="mt-4"><strong>1. Capacidad</strong></p>

            <p>La aceptación de estos términos y condiciones solo podrán efectuarse por personas con capacidad legal para contratar. Los actos que incapaces realicen en el sitio serán responsabilidad de sus padres, tutores o curadores, y por tanto se considerarán realizados por éstos en ejercicio de la representación legal con la que cuentan. En caso que se registre como usuario una persona jurídica, quien la representa, deberá tener capacidad legal para contratar a nombre y representación de la misma y obligarla en los términos de este acuerdo.</p>
           
            <p className="mt-4"><strong>2. Registro</strong></p>
            <p>Es obligatorio completar todos los datos requeridos en el formulario de registro por el usuario. Los Usuarios garantizan y responden, en cualquier caso, de la veracidad, exactitud, vigencia y autenticidad de los Datos Personales ingresados y serán responsables de los eventuales perjuicios que padezca a consecuencia de informar erróneamente sus datos personales, eximiendo de todo tipo de responsabilidad a IP en este respecto.</p>

            <p>IP se reserva el derecho de solicitar cualquier comprobante y/o dato adicional a efectos de corroborar los Datos Personales, así como de suspender temporal o definitivamente a aquellos Usuarios cuyos datos no hayan podido ser confirmados.</p>

            <p>En estos casos de inhabilitación, se dará de baja la compra efectuada, sin que ello genere algún derecho a resarcimiento.</p>

            <p>El Usuario reconoce y acepta que la información a ser proporcionada a <a href="https://iris.encuentra24.com" target="_blank" className="text-primary" style={{textDecoration : 'underline'}}>www.iris.encuentra24.com</a> podrá ser almacenada y que podrá ser utilizada por ésta a fin de acreditar cualquier circunstancia que tuviera relación con el vínculo comercial entablado.</p>

            <p>El Usuario podrá acceder a su información personal almacenada en cualquier momento durante la vigencia de este contrato. Si en ese período cualquiera de los datos informados por el usuario a IP fuera modificado, el Usuario deberá informar esa circunstancia a IP, quien dispondrá de un plazo de 20 días para efectuar las modificaciones correspondientes en su base de datos.</p>

            <p>Todos aquellos datos personales del Usuario de esta página Web obtenidos por Encuentra24 serán tratados y procesados de conformidad con todas las leyes aplicables de la República de Panamá y sus decretos reglamentarios.</p>


            <p className="mt-4"><strong>3. Publicidad sobre el Proyecto y Comisiones.</strong></p>

            <p>La Empresa de bienes raíces y/o Brokers independientes se compromete a no comunicar ninguno de los proyectos que están dentro de Iris en los portales inmobiliarios.</p>
            <p>También se compromete a respetar los materiales de venta otorgados por el desarrollador a través de la plataforma Iris, para ser usados en su web, redes sociales y base de datos.</p>
            <p>El objeto de esto es que las empresas de bienes raíces y/o Brokers independientes se conviertan en un canal de venta extra para los desarrolladores y que el producto no se encuentre publicado infinidad de veces en los distintos portales.</p>
            <p>Las empresas de bienes raíces y/o brokers independientes no podrán comunicar, cualquiera sea el medio o canal publicitario, una comisión al comprador final distinta a la establecida en el portal de Iris.</p>


            <p className="mt-4"><strong>4. Comisiones por ventas </strong></p>

            <p>El porcentaje de comisión correspondiente a cada proyecto, establecido por el desarrollador, será abonado por parte de ENCUENTRA24 de manera íntegra a la empresa de bienes raíces y/o brokers independientes que intermedie en la venta, entendiéndose como tal, aquellas operaciones cerradas con un compromiso de compraventa de por medio. El porcentaje establecido por el desarrollador será sobre el precio de venta del inmueble sin incluir los gastos legales y fondo de amoblamiento y/o otros gastos derivados de la compra.</p>

            <p>Se entiende como “gastos de conexiones” el precio adicional sobre el precio del inmueble de modo de cubrir equipamiento en áreas comunes y alojamiento, gastos legales o, entre otros posibles y/o similares.</p>
            <p>Encuentra 24 procederá al pago mencionado a la empresa de Bienes raíces y/o brokers independiente en un plazo de hasta 10 días hábiles posteriores a que se acredite el pago en las cuentas bancarias de Encuentra24, siempre y cuando se haya firmado el compromiso de compraventa, así como también la empresa de bienes raíces y/o brokers independientes haya emitido la factura fiscal correspondiente.</p>


            <p className="mt-4"><strong>5. Política de precios y descuentos – Confidencialidad.</strong></p>

            <p>Encuentra 24 tendrá disponible en Iris la política de precios y descuentos de los distintos productos.</p>
            <p>IP actualizará todas las listas de precios, descuentos y disponibilidad en línea, todo lo cual deberá ser respetado por la empresa de bienes raíces y/o brokers independientes.</p>
            <p>La empresa de bienes raíces y/o brokers independientes acepta que la información relacionada con la Política de Precios y Descuentos se deberá mantener en forma
            estrictamente confidencial en forma indefinida, independientemente de la vigencia o no de este acuerdo. Asimismo, se obliga a guardar la más estricta confidencialidad de toda información que IP le haya suministrado anteriormente a la aceptación de los presentes términos y condiciones o se le suministre en el futuro o a la que acceda en virtud o en ocasión de las relaciones entabladas con IP en virtud de este Proyecto. La empresa de bienes raíces y/o brokers independientes declaran conocer y aceptar que las obligaciones de confidencialidad aquí acordadas constituyen un elemento esencial del contrato.</p>

            <p className="mt-4"><strong>6. Reserva de Unidades</strong> </p>
            <p>La empresa de bienes raíces y/o brokers independientes podrán reservar la unidad a un cliente siempre y cuando este último abone el monto correspondiente a las políticas de cada desarrollador.</p>
            <p>Cada desarrollador establecerá el tiempo máximo en el que una reserva será válida para la empresa de bienes raíces y/o brokers independientes , y en caso de que no se firme el compromiso de compraventa dentro del plazo establecido, la empresa de bienes raíces y/o brokers independientes ya no podrá reclamar y/o demandar el pago de la comisión. La política de cada desarrollador estará visible y actualizada en Iris.</p>

            <p className="mt-4"><strong>7. Entrega de materiales</strong> </p>
            <p>ENCUENTRA24 entregará materiales de “marca blanca” de modo de que la empresa de bienes raíces y/o brokers independientes publique en los sitios permitidos, así como también se la envíe a clientes directos interesados en el Proyecto.</p>
            <p>Se entiende por “marca blanca” a la ausencia de datos propios de modo que la Inmobiliaria pueda incluir sus datos de contacto para que el comprador final pueda comercializar a través de ella.</p>

            <p className="mt-4"><strong>8. Independencia de las partes, cumplimiento de normas</strong> </p>
            <p>La presente relación no tiene naturaleza laboral o de dependencia y no implica efecto societario alguno. La única relación jurídica que existe entre las Partes contratantes es aquella que existe entre partes independientes en donde una presta un servicio sin existir subordinación alguna y la otra paga un precio como contraprestación de dicho servicio. Ni el presente Contrato ni los Servicios deben ser considerados como la constitución de una asociación, sociedad, empresa conjunta (joint venture), o una relación principal-agente entre el Cliente y el Proveedor, y ninguna de las Partes tendrá derecho, o autoridad (ya sea explícita o implícita) para asumir ninguna obligación en nombre de la otra Parte. El presente contrato por tanto no representa ninguna forma de subcontratación ni queda comprendido en los parámetros de la ley, siendo empresas totalmente independientes.</p>

            <p className="mt-4"><strong>9. Responsabilidad laboral</strong> </p>
            <p>La empresa de bienes raíces y/o brokers independientes es el único responsable por el cumplimiento de las obligaciones laborales, previsionales, de seguridad e higiene en el trabajo, tributarias y de cualquier otra naturaleza de su cargo respecto de su personal afectado al Proyecto.</p>
            <p>La empresa de bienes raíces y/o brokers independientes serán exclusivamente responsables por cualquier accidente que pueda sufrir su personal (o cualquier persona contratada a tales efectos) afectado al cumplimiento de este contrato, como consecuencia de la realización de las actividades objeto del contrato o en ocasión de las mismas, obligándose la empresa de bienes raíces y/o brokers independientes a tener al personal asegurado conforme a las normas vigentes.</p>
            <p>Sin perjuicio de lo anterior, la empresa de bienes raíces y/o brokers independientes se obliga a reembolsar a ENCUENTRA24 de inmediato cualquier suma de dinero que, en aplicación de cualquier norma laboral, de seguridad social, de seguridad e higiene o de accidentes de trabajo, actual o futura, IP se viera obligado a abonar a los empleados de la Inmobiliariala empresa de bienes raíces y/o brokers independientes o a terceros, así como cualquier gasto en que debiera incurrir (sin que signifique limitación, los gastos a reembolsarse incluirán los honorarios profesionales de los abogados de IP) y que tuviera relación con el incumplimiento de las normas laborales, de seguridad social, de seguridad e higiene en el trabajo o de accidentes de trabajo de cargo de la empresa de bienes raíces y/o brokers independientes, obligándose desde ya a mantener indemne a ENCUENTRA24 por cualquier reclamo o pago a que se vea obligado.</p>

            <p className="mt-4"><strong>10. Indemnidad</strong> </p>
            <p>La empresa de bienes raíces y/o brokers independientes se obliga a mantener indemne a Ip así como a sus directores, agentes, representantes, empleados y personal en general, respecto de: (i) cualquier y todo daño, deuda, gastos, costos, cargas, pérdidas o reclamos (incluido los honorarios razonables de los asesores legales o de otra naturaleza) de cualquier naturaleza en virtud de omisiones que pueda tener en la difusión de los servicios ofrecidos o por su participación en el Proyecto.</p>

            <p className="mt-4"><strong>11. Condición resolutoria expresa</strong> </p>
            <p>Sin perjuicio de lo dispuesto en otras cláusulas de estos términos y condiciones, ambas partes podrán dar por resuelto de pleno derecho este acuerdo, sin necesidad de interpelación judicial o extrajudicial ni de gestión o noticia alguna más que una comunicación con 10 días de anterioridad, sin responsabilidad para ambas.</p>
            <p>El contrato podrá ser resuelto sin responsabilidad alguna para IP respecto de la empresa de bienes raíces y/o brokers independientes en caso de que cesara por cualquier causa el vínculo comercial de IP con el titular del inmueble asociado al Proyecto o en caso de que por cualquier causa se hiciera imposible la utilización del Showroom.</p>
            <p>Se pacta la condición resolutoria expresa, y sin perjuicio de otros incumplimientos que así lo ameriten: a) por incumplimiento de la empresa de bienes raíces y/o brokers independientes de las obligaciones asumidas en virtud de estos términos y condiciones, b) por incumplimiento de las obligaciones de confidencialidad asumidas por la empresa de bienes raíces y/o brokers independientes, c) cualquier otra causa establecida expresamente en estos términos y condiciones.</p>

            <p className="mt-4"><strong>12. Violaciones del Sistema o Bases de Datos</strong> </p>
            <p>No está permitida ninguna acción o uso de dispositivo, software, u otro medio tendiente a interferir tanto en las actividades y operatoria de ENCUENTRA24 como en las ofertas, descripciones, cuentas o bases de datos de IP. Cualquier intromisión, tentativa o actividad violatoria o contraria a las leyes sobre derecho de propiedad intelectual y/o a las prohibiciones estipuladas en este contrato harán pasible a su
            responsable de las acciones legales pertinentes, y a las sanciones previstas por este acuerdo, así como lo hará responsable de indemnizar los daños ocasionados.</p>

            <p className="mt-4"><strong>13. Propiedad Intelectual</strong> </p>
            <p>Los contenidos de las pantallas relativas a los servicios de www.iris.ENCUENTRA24.com como así también la información, datos, textos gráficos, imágenes, fotografías, audio y video clips, logotipos, iconos, programas, bases de datos, redes, archivos que permiten a la Inmobiliaria acceder y usar su cuenta, son de propiedad de IP, o está autorizado IP a su uso o reproducción y están protegidas por las leyes y los tratados internacionales de derecho de autor, marcas, patentes, modelos y diseños industriales.</p>


            <p className="mt-4"><strong>14. Modificación de Términos y Condiciones</strong> </p>
            <p>ENCUENTRA24 podrá modificar estos Términos y Condiciones Generales en cualquier momento haciendo públicos en el sitio los términos modificados. Todos los términos modificados entrarán en vigor a los 5 (cinco) días de su publicación. Dentro de los 5 (cinco) días siguientes a la publicación de las modificaciones introducidas, el Usuario deberá comunicar por e-mail haciendo clic aquí si no acepta las mismas; en ese caso quedará disuelto el vínculo contractual Vencido dicho plazo, se considerará que la Inmobiliaria acepta los nuevos términos y el contrato continuará vinculando a ambas partes.</p>

            <p className="mt-4"><strong>15. Divisibilidad</strong> </p>
            <p>En caso que cualquiera de las estipulaciones de estos términos y condiciones resultara inválida, ilegal o no ejecutable, la validez, legalidad y ejecutabilidad de las restantes cláusulas no se verá afectada, dañada o perjudicada de manera alguna.</p>


            <p className="mt-4"><strong>16. Jurisdicción y Ley Aplicable</strong> </p>
            <p>Este acuerdo estará regido en todos sus puntos por las normativas legales vigentes en la República de Panamá. Cualquier controversia derivada del presente acuerdo, su existencia, validez, interpretación, alcance o cumplimiento, será sometida a los tribunales de la ciudad de Panamá y los procedimientos se llevarán a cabo en idioma español, renunciando en forma expresa a cualquier otro fuero o jurisdicción.</p>

            <p className="mt-4"><strong>17. Domicilio</strong> </p>
            <p>Se fija como domicilio de Encuentra24 en Calle Elvira Mendez, Tower Financial Center, piso 19, Bella Vista, corregimiento de Bella Vista, Provincia de Panamá.</p>

        </div>
    )
}
