import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import userFilter from "../../hooks/filter/useFilter";
import useUser from "../../hooks/user/useUser";
import { IconObraNueva } from "../../components/CustomIcons/IconObraNueva";
import { IconAlquileres } from "../../components/CustomIcons/IconAlquileres";
import { IconTerrenos } from "../../components/CustomIcons/IconTerrenos";
import useCountry from "../../hooks/config/useConfig";
import { useEffect, useState } from "react";
import { ProjectTypeType } from "../../interfaces/project";
import { getProjectTypes } from "../../data/api";
import { useLinks } from "../../hooks/config/useLinks";

export const Categories = () => {
  const navigate = useNavigate();
  const { setFilter, removeFilter } = userFilter();
  const { isLoggedIn } = useUser();
  const { country, translation, countryFeatures } = useCountry();
  const { headerLinks, mainProjectsUrl } = useLinks()



  const categories = [
    {
      title: "Venta Obra Nueva",
      image: <IconObraNueva />,
      show: true,
      link : mainProjectsUrl
    },
    {
      title: translation.rent,
      filter: () => setFilter({ units_rented: true }),
      image: <IconAlquileres />,
      show: !!countryFeatures.rent,
      link : headerLinks.all
    },
    {
      title: "Terrenos",
      link : headerLinks.lands,
      image: <IconTerrenos />,
      show: !!countryFeatures.land ,
    },
  ];

  return (
    <Container className={`pb-5 ${isLoggedIn ? "pt-5" : ""} count-up`}>
      {
        categories.filter(c=>c.show).length>1 && 
        <h3 className="fs-3 fw-bold mb-5">Busca por categoría</h3>
      }
      {
        categories.filter(c=>c.show).length<=1 && 
        <h3 className="fs-3 fw-bold mb-5">Comienza a buscar:</h3>
      }

      <Row className="gx-md-3 gx-lg-5 gy-4 justify-content-center">
        {categories.map(({ title, filter, image, show ,link}, index) => {
          if (show) return (
            <Col
              sm={12} md={6} lg={4} key={"counup_" + index}
              className="container-category"
            >
              <div
                className="card-categories"
                onClick={() => {
                  navigate(link);
                 
                  if(filter) {
                    removeFilter();
                    filter()
                  }
                }}
              >
                {image}
                <h5 className="title-categories">{title}</h5>
              </div>
            </Col>)
          else return null
        })}
      </Row>
    </Container>
  );
};
