import { Col, Row } from "react-bootstrap";
import { WarehouseType } from "../../interfaces/project";
import { formatMoney } from "../../utils/Functions";
import { CustomImage } from "../../components/CustomImage/CustomImage";
import useCountry from "../../hooks/config/useConfig";
import { IconCrop } from "../../components/CustomIcons/IconCrop";

export const WarehouseModalContent = ({ warehouse }: { warehouse: WarehouseType }) => {
  const { translation } = useCountry();

  return (
    <div className="garage-modal-content bg-white w-100 h-100 rounded-3 text-secondary px-5 px-lg-4 px-xl-5 py-2 py-lg-3 py-xl-4">
      <Row className="d-flex flex-lg-nowrap h-100">
        <Col
          xl="3"
          lg={{ order: 1, span: 5 }}
          xs={{ order: 2, span: 12 }}
          className="flex-shrink-1 mt-lg-3 mt-xl-5 pb-3 pb-lg-0"
        >
          <span className="fw-500 d-block text-center text-lg-start">
            {translation.warehouse} {warehouse.name}
          </span>
          <h2 className="mt-0 fw-bold mb-3 text-center text-lg-start">
            {warehouse.currency} {formatMoney(warehouse.price)}
          </h2>
          <div className="d-flex align-items-center mb-2">
            <IconCrop width={16} height={16} />

            <span className="ms-2 text-black-50">
              {`${warehouse.width.toString().replace(".", ",")}m x ${warehouse.depth
                .toString()
                .replace(".", ",")}m`}
            </span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="bi bi-arrow-bar-down"></i>
            <span className="ms-2 text-black-50">
              {`Altura máx.: ${warehouse.height.toString().replace(".", ",")}m`}
            </span>
          </div>
          <p className="mt-4">{warehouse.description}</p>
        </Col>
        <Col
          xl="9"
          lg={{ order: 2, span: 7 }}
          xs={{ order: 1, span: 12 }}
          className="image flex-grow-1 h-100 py-4 py-lg-0 d-flex justify-content-center align-items-center"
        >
          <CustomImage
            src={(warehouse.plan !== "" ? warehouse.plan : "https://cdn1.infocasas.com.uy/web/64ad8ffb1961c_plano-no.png")}
            alt="plano"
            classes="h-75 w-75"
            styles={{ objectFit: "contain" }}
            width={700}
            height={700}
          />
        </Col>
      </Row>
    </div>
  );
};
