
import {NotificationForm} from '../../../fragments/admin/notifications/NotificationForm'

const AdminCreateNotification = () =>{

    return (
        <main className='p-5'>
            <NotificationForm />
        </main>
    )
}
export default AdminCreateNotification