export const IconElevator = ({ width = 19 }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.375 2.77075H16.625V16.2291H2.375V2.77075Z"
        stroke="currentColor"
        strokeWidth="1.58333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.1875 2.77081H17.8125"
        stroke="currentColor"
        strokeWidth="1.58333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.1875 16.2291H17.8125"
        stroke="currentColor"
        strokeWidth="1.58333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 2.77075V16.2291"
        stroke="currentColor"
        strokeWidth="1.58333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0625 7.91663V11.0833"
        stroke="currentColor"
        strokeWidth="1.58333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.875 9.10413L13.0625 7.91663L14.25 9.10413"
        stroke="currentColor"
        strokeWidth="1.58333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.9375 11.0833V7.91663"
        stroke="currentColor"
        strokeWidth="1.58333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.75 9.89575L5.9375 11.0833L7.125 9.89575"
        stroke="currentColor"
        strokeWidth="1.58333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.375 2.77075H16.625V16.2291H2.375V2.77075Z"
        stroke="currentColor"
        strokeWidth="1.58333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.1875 2.77081H17.8125"
        stroke="currentColor"
        strokeWidth="1.58333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.1875 16.2291H17.8125"
        stroke="currentColor"
        strokeWidth="1.58333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 2.77075V16.2291"
        stroke="currentColor"
        strokeWidth="1.58333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0625 7.91663V11.0833"
        stroke="currentColor"
        strokeWidth="1.58333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.875 9.10413L13.0625 7.91663L14.25 9.10413"
        stroke="currentColor"
        strokeWidth="1.58333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.9375 11.0833V7.91663"
        stroke="currentColor"
        strokeWidth="1.58333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.75 9.89575L5.9375 11.0833L7.125 9.89575"
        stroke="currentColor"
        strokeWidth="1.58333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
