import { useEffect, useState } from "react"
import { useUserFetch } from "../fetch/useUserFetch"
import { LevelType } from "../../interfaces/levels"
import { StepsForNext } from "../../interfaces/user"

export const useLevel = () => {

    const {getLevelInfo} = useUserFetch()
    const [level , setLevel] = useState<LevelType>()
    const [ nextSteps, setNextSteps ] = useState<StepsForNext[]>()
    const [ totalPercentage , setTotalPercentage] = useState(0)
    const [ hiddenMore , setHiddenMore ] = useState(false)

    useEffect(() => {
        getLevelInfo().then(res => {
            if(res.success && res.data){
                const data = res.data

                setLevel(res.data.current_level)
                setNextSteps(res.data.steps_for_next)
                setTotalPercentage(data.total_percentage)
                if(res.data.steps_for_next?.filter(s=> s.new)?.length>2) {
                    setHiddenMore(true)
                }
    
            }
        })
    },[])

    return {
        level,
        nextSteps,
        totalPercentage,
        hiddenMore,
        setHiddenMore
    }
}