import { createContext } from "react";
import { PropertyType } from "../../interfaces/property";
import { ProjectStatusType, ProjectTypeType } from "../../interfaces/project";

export type ProjectContextProps = {
    projectStatusList : ProjectStatusType[],
    loadingProjectStatus : boolean ,
    projectStatusUsed? : number ,
    projectTypesList : ProjectTypeType[]
};

export const ProjectContext = createContext<ProjectContextProps>({} as ProjectContextProps);
