import { useState } from "react";
import { Form } from "react-bootstrap"

export const NumberInput = (
    {
        required ,
        onChange ,
        name ,
        max 
    } : {
        required : boolean,
        onChange : Function,
        name ? : string,
        max ? : number
    }
) => {

    const validNumber = new RegExp(/^\d*\.?\d{0,2}$/);
    const [value,setValue] = useState('')

    const handleChangeUnitPrice = (ev: any) => {

        let lastValid = value
        if (validNumber.test(ev.target.value)) {
            lastValid = ev.target.value;
        }
        onChange({target : { name : ev.target.name , value : lastValid}})
        setValue(lastValid)
       
    }


    return (
        <Form.Control
                type="text"
                name={name}
                onChange={handleChangeUnitPrice}
                value={value}
                required={required}
                max={max}
            />
    )
}