import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Header } from "../fragments/header/Header";
import { Footer } from "../fragments/footer/Footer";
import useCountry from "../hooks/config/useConfig";
import { ImageModal } from "../components/PromoModal/ImageModal";
import useConfig from "../hooks/config/useConfig"
import useUser from "../hooks/user/useUser"
import {useUserFetch} from "../hooks/fetch/useUserFetch"
import { useCookies } from "../hooks/cookies/useCookies"

const Layout = () => {

  const [showImgModal, setShowImgModal] = useState(false);

  const imgPopupDesktop = 'https://cdn1.infocasas.com.uy/web/651adf75582f1_desktop_promosiris.png'
  const imgPopupMobile = 'https://cdn1.infocasas.com.uy/web/651adf7559a2b_mobile_promosiris.png'
  const linkPopup = 'https://www.infocasas.com.uy/iris/amp4'

  const {country} = useConfig()
  const { getCookie, setCookie } = useCookies();
  const { userState } = useUser()
  const { updateUserStateData} = useUserFetch()

  // useEffect(() => {
  //   if (country == 'UY' && !getCookie("oportunidadesModal")) {
  //     const now = new Date();
  //     const time = now.getTime();
  //     const expireTime = time + 86400 * 1000; //24h
  //     now.setTime(expireTime);
  //     const expires = '=ok;expires=' + now.toUTCString() + ';path=/;SameSite=none;Secure';

  //     setCookie("oportunidadesModal", expires)
  //     setShowImgModal(true);
  //   } else {
  //     setShowImgModal(false)
  //   };
  // }, [country])

  useEffect(() =>{
    updateUserStateData()
  }, [])
  

  return (
    <>
      {/* {
        country == 'UY' && 
        <ImageModal cookieName="oportunidadesModal"
        mobileImg={imgPopupMobile}
        desktopImg={imgPopupDesktop} link={linkPopup} />
      } */}
      

      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;
