import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import userFilter from "../../../hooks/filter/useFilter";
import { useSearchParams } from "react-router-dom";
import { formatMoney } from "../../../utils/Functions";
import useConfig from "../../../hooks/config/useConfig"
import "./FilterPrice.scss";

export const FilterPrice = () => {
    const { setFilter, filterState } = userFilter();
    const [searchParams] = useSearchParams();
    const [currentMinPrice, setCurrentMinPrice] = useState("");
    const [currentMaxPrice, setCurrentMaxPrice] = useState("");
    const {config} = useConfig()
    
    const [currency , setCurrency] = useState('USD')
    const currencies = ['USD','UYU','UF','CLP','COP','GS']
    const [defaultSelected , setDefaultSelected] = useState(false)

    useEffect(() => {
        if(config && config.country && !defaultSelected){
            const defaultCurrency = config.country.default_currency ? config.country.default_currency : 'USD' 
            setDefaultSelected(true)
            setCurrency(defaultCurrency)
        }
    },[config])

    useEffect(() => {
        const min: number = parseFloat(searchParams.get("minPrice") ?? "");
        setCurrentMinPrice(isNaN(min) ? "" : formatMoney(min))
        const max: number = parseFloat(searchParams.get("maxPrice") ?? "");
        setCurrentMaxPrice(isNaN(max) ? "" : formatMoney(max))
        const currency_minmax : string  = searchParams.get("currency_minmax") ?? ""

        if(currency_minmax != '') setCurrency(currency_minmax)
        
    }, [searchParams]);

    return (
        <Form>

            <Form.Group className="d-flex justify-content-between align-items-center mb-3 mx-1">
                <Form.Label className="me-2 mt-2">
                    Moneda: 
                </Form.Label>
                <Form.Select aria-label="" value={currency} onChange={(ev) => setCurrency(ev.target.value)} className="select-currency">
                    {
                        currencies.map((c,index) => <option key={index} value={c}>{c}</option>)
                    }
                </Form.Select>
            </Form.Group>
            <Form.Group className="d-flex justify-content-between">
                <Form.Control
                    type="text"
                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                    placeholder="Mínimo"
                    className="mx-1 input-price"
                    value={currentMinPrice}
                    onChange={(e) => {
                        setCurrentMinPrice(formatMoney(e.target.value))
                    }} />
                <Form.Control
                    type="text"
                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                    placeholder="Máximo"
                    className="mx-1 input-price"
                    value={currentMaxPrice}
                    onChange={(e) => {
                        setCurrentMaxPrice(formatMoney(e.target.value))
                    }} />
            </Form.Group>
            <Button
                variant="primary"
                className="mx-1 my-2 input-price"
                style={{ width: "47.5%" }}
                onClick={(e) => {
                    let auxCurrentMinPrice: any = currentMinPrice;
                    auxCurrentMinPrice = auxCurrentMinPrice.replaceAll('.', '')
                    auxCurrentMinPrice = parseFloat(auxCurrentMinPrice);

                    let auxCurrentMaxPrice: any = currentMaxPrice;
                    auxCurrentMaxPrice = auxCurrentMaxPrice.replaceAll('.', '')
                    auxCurrentMaxPrice = parseFloat(auxCurrentMaxPrice);

                    setFilter({
                        currency_minmax : (currentMaxPrice || currentMinPrice ) ? (currency ? currency : 'USD'):'',
                        maxPrice: currentMaxPrice ? auxCurrentMaxPrice : 0,
                        minPrice: currentMinPrice ? auxCurrentMinPrice : 0
                    })
                }}
            >Aplicar rango</Button>{' '}
        </Form>
    )
}
