
import TitleCard from "../../daisyUI/components/Cards/TitleCardTs"
import UserOffers from "../../fragments/user/activity/UserOffers"
import { Tab, Tabs } from "react-bootstrap"
import UserVisits from "../../fragments/user/activity/UserVisits"
import UserRequestedMortgages from "../../fragments/user/activity/UserRequestedMortgages"
import { useGoogleAnalytics } from "../../hooks/googleAnalytics/useGoogleAnalytics";
import useUser from "../../hooks/user/useUser";
import useConfig from "../../hooks/config/useConfig";
import { useEffect } from "react"

function UserActivity(){

    const GA = useGoogleAnalytics(true);
    const {userState} = useUser()
    const {countryFeatures} = useConfig()
    const defaultTab = 'offers'
    useEffect(()=> {
        GA.Event({ category: "User open activity "+ defaultTab , action: "user_open_activity_"+defaultTab, label: `${userState.name}`, value: Number(userState.id) });
    },[])

    const is_mobile = () => {
        return window.innerWidth < 600
    }

    const handleSelect = (tab : any) => {
        GA.Event({ category: "User open activity " + tab, action: "user_open_activity_"+tab, label: `${userState.name}`, value: Number(userState.id) });
    }

    return(
        <div className="user-activity">
            
            <TitleCard title="Mi Actividad" topMargin="tw-mt-2" >


             <Tabs
                defaultActiveKey={defaultTab}
                id="uncontrolled-tab-example"
                onSelect={handleSelect}
            
                >
                
                <Tab eventKey="offers" title="Ofertas">
                    <UserOffers />
                </Tab>
                <Tab eventKey="visits" title="Visitas" >
                    <UserVisits />
                </Tab>
                {/* <Tab eventKey="warranties" title={is_mobile() ? "Garantías Alquiler" : "Garantías de Alquiler" }>
                    <UserRequestedWarranties />
                </Tab> */}
                {
                    !!countryFeatures.mortgageLoan && 
                    <Tab eventKey="mortgage" title={is_mobile() ? "Préstamos Hipoteca" : "Préstamos Hipotecarios" } >
                        <UserRequestedMortgages />
                    </Tab>
                }
                
                
            </Tabs>
            </TitleCard>
        </div>
    )
}


export default UserActivity