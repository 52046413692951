import { useEffect, useState } from "react";
import { FormCheck } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import userFilter from "../../../hooks/filter/useFilter";

export const FilterSocialHousing = () => {
  const { setFilter } = userFilter();
  const [searchParams] = useSearchParams();
  const [current, currentSocialHousing] = useState<boolean>(false);

  useEffect(() => {
    const parameter = searchParams.get("social_housing");
    currentSocialHousing(!!parameter && parameter === "true");
  }, [searchParams]);

  return (
    <div className="mb-1 d-flex justify-content-start">
      <FormCheck 
        type="switch"
        size={40}
        checked={current}
        className="position-relative"
        style={{left: "0.5rem"}}
        onChange={()=>setFilter({social_housing: !current})}
      />
    </div>
  );
};
