import { CountryFeatures } from "../interfaces/config";
import { Country } from "../interfaces/locations";
import useConfig from '../hooks/config/useConfig'

export const getFooterInfo = (codeCountry : string, allCountries : Country[] , 
    countryFeatures : CountryFeatures, mainProjectsUrlByCountry : any) => {

    let countriesByCode : {[key:string] : Country} = {}
    allCountries.map(c=> {
        countriesByCode[c.code] = c
    })

    const CONTACT_CONTENT = [
        {
            "icon": "phone",
            "text": countryFeatures.contact_phone, //"+598 2605 55 55",
            "link": "tel:"+countryFeatures.contact_tel
        },
        {
            "icon": "whatsapp",
            "text": countryFeatures.contact_wpp, //"+598 97 259 343",
            "link": countryFeatures.contact_wame,// "https://wa.me/59897259343"
        },
        {
            "icon": "email",
            "text": countryFeatures.contact_email,
            "link": "mailto:"+countryFeatures.contact_email
        }
    ]

    const FIND_IN_IRIS =  [
        {
            "text": "Propiedades en Uruguay",
            "link": mainProjectsUrlByCountry?.UY
        },
        {
            "text": "Propiedades en Paraguay",
            "link": mainProjectsUrlByCountry?.PY
        },                    
        {
            "text": "Propiedades en Chile",
            "link": mainProjectsUrlByCountry?.CL
        },
        {
            "text": "Propiedades en Colombia",
            "link": mainProjectsUrlByCountry?.CO
        },
        {
            "text": "Propiedades en Panamá",
            "link": mainProjectsUrlByCountry?.PA
        }
    ]

    const WEBS_DATA = [
        {
            "text": "InfoCasas Uruguay",
            "link": "https://www.infocasas.com.uy"
        },
        {
            "text": "InfoCasas Paraguay",
            "link": "https://www.infocasas.com.py"
        },
        {
            "text": "Yapo Chile",
            "link": "https://www.yapo.cl/"
        },
        {
            "text": "Fincaraíz Colombia",
            "link": "https://www.fincaraiz.com.co/"
        },
        {
            "text": "Encuentra24 Panamá",
            "link": "https://www.encuentra24.com"
        }
    ]

    const LINKS_INSIDE = [
        {
            "text": "Registrate y vende",
            "link": "/registro"
        },
        {
            "text": "Noticias",
            "link": "/feed"
        },
        {
            "text": "Entra en tu cuenta",
            "link": "/iniciar-sesion"
        }
    ]

    const TOP_DATA = [
        {
            "title": "Contáctanos",
            "content": CONTACT_CONTENT
        },
        {
            "title": "Encuentra en Iris",
            "content": FIND_IN_IRIS
        },
        {
            "title": "Para Profesionales",
            "content": LINKS_INSIDE
        }
    ]
    
    
    const allFooter : any = {
        "UY": {
            "top": TOP_DATA,
            "bottom": {
                "left": {
                    "text": "Powered by",
                    "image": "https://cdn2.infocasas.com.uy/web/th.outside100x100.61f806aab1ae4_infocdn__logo-infocasas-blanco@2x.png" 
                },
                "right": WEBS_DATA
            }
        },
        "PY": {
            "top": TOP_DATA,
            "bottom": {
                "left": {
                    "text": "Powered by",
                    "image": "https://cdn2.infocasas.com.uy/web/th.outside100x100.61f806aab1ae4_infocdn__logo-infocasas-blanco@2x.png" 
                },
                "right": WEBS_DATA
            }
        },
        "CL": {
            "top": TOP_DATA,
            "bottom": {
                "left": {
                    "text": "Powered by",
                    "image": "https://cdn1.infocasas.com.uy/web/64a5d5c47b50c_logo_yapo_blanco.png" 
                },
                "right": []
            }
        },
        "CO": {
            "top": TOP_DATA,
            "bottom": {
                "left": {
                    "text": "Powered by",
                    "image": "https://cdn1.infocasas.com.uy/web/64f0b20b918ca_fincaraiz-logo.png" 
                },
                "right": WEBS_DATA
            }
        },
        "PA": {
            "top": TOP_DATA,
            "bottom": {
                "left": {
                    "text": "Powered by",
                    "image": "https://cdn1.infocasas.com.uy/web/656e4c124113c_encuentra24.png" 
                },
                "right": WEBS_DATA
            }
        },
        "BO": {
            "top": TOP_DATA,
            "bottom": {
                "left": {
                    "text": "Powered by",
                    "image": "https://cdn2.infocasas.com.uy/web/th.outside100x100.61f806aab1ae4_infocdn__logo-infocasas-blanco@2x.png" 
                },
                "right": WEBS_DATA
            }
        },
        "DO": {
            "top": TOP_DATA,
            "bottom": {
                "left": {
                    "text": "Powered by",
                    "image": "https://cdn2.infocasas.com.uy/web/th.outside100x100.61f806aab1ae4_infocdn__logo-infocasas-blanco@2x.png" 
                },
                "right": WEBS_DATA
            }
        }
    }

    return allFooter[codeCountry]
}