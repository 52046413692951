import { CountryFeatures, FiltersByCountry, Institutional } from "../interfaces/config";

export const countriesFeatures: {[key : string] : CountryFeatures} = {
    "UY":{
        name: "Uruguay",
        id: "1",
        socialHousing : true,
        subsidyLaws : false,
        rent  : false,
        land : true,
        units_rent : true,
        financing : true,
        rentWarranty : true,
        rentWarrantyPanel : true,
        mortgageLoan : true,
        expenses_ocupation_connection : true,
        pj_cash : true,
        pj_dsct : false,
        pj_final_price : false,
        pj_plazo_obra : true,
        pj_list_price : true,
        pj_units_rent : true,
        type_offer : 'offer',
        max_commission : false,
        file_commission : false,
        contact_email : 'iris@infocasas.com.uy',
        contact_phone : '+598 2605 55 55',
        contact_wpp : '+598 97 259 343',
        contact_wame : 'https://wa.me/59897259343',
        contact_tel : '+59826055555',
        powered_by_img : 'https://cdn2.infocasas.com.uy/web/th.outside100x100.61f806aab1ae4_infocdn__logo-infocasas-blanco@2x.png',
        show_share_rules : true,
        garages_in_units : false,
        show_service_room : false,
        main_projects_type : 'all',
        plus_iva : true,
        warranty_help_wpp : '59897014188',
        enable_iris_academy : true,
        notarial_service : true,
        group_services : true
        
    },
    "PY":{
        name: "Paraguay",
        id: "2",
        socialHousing : true,
        subsidyLaws : false,
        rent  : true,
        land : true,
        units_rent : true,
        financing : true,
        rentWarranty : true,
        rentWarrantyPanel : false,
        mortgageLoan : false,
        expenses_ocupation_connection : true,
        pj_cash : true,
        pj_dsct : false,
        pj_final_price : false,
        pj_plazo_obra : true,
        pj_units_rent : true,
        pj_list_price : true,
        type_offer : 'offer',
        max_commission : false,
        file_commission : false,
        contact_email : 'iris@infocasas.com.py',
        contact_phone : '+598 2605 55 55',
        contact_wpp : '+595 991 850714',
        contact_wame : 'https://wa.me/595991850714',
        contact_tel : '+59826055555',
        powered_by_img : 'https://cdn2.infocasas.com.uy/web/th.outside100x100.61f806aab1ae4_infocdn__logo-infocasas-blanco@2x.png',
        garages_in_units : true,
        show_service_room : false,
        main_projects_type : 'all',
        plus_iva : true,
        warranty_help_wpp : '59897014188',
        enable_iris_academy : true
        
    },
    "BO":{
        "name": "Bolivia",
        "id": "5",
        powered_by_img : 'https://cdn2.infocasas.com.uy/web/th.outside100x100.61f806aab1ae4_infocdn__logo-infocasas-blanco@2x.png',
        main_projects_type : 'all'
    },
    "ES":{
        "name": "España",
        "id": "E1",
        main_projects_type : 'all'
    },
    "CO":{
        "name": "Colombia",
        "id": "8",
        socialHousing : false,
        subsidyLaws : false,
        rent  : true,
        land : true,
        units_rent : true,
        financing : true,
        rentWarranty : true,
        rentWarrantyPanel : false,
        mortgageLoan : false,
        expenses_ocupation_connection : true,
        pj_cash : true,
        pj_dsct : false,
        pj_final_price : false,
        pj_plazo_obra : false,
        pj_list_price : false,
        pj_units_rent : true,
        type_offer : 'offer',
        max_commission : false,
        file_commission : false,
        contact_email : 'iris@fincaraiz.com.co',
        contact_phone : '+57 316 0188420',
        contact_wpp : '+573160188420',
        contact_wame : 'https://wa.me/573160188420',
        contact_tel : '+573160188420',
        powered_by_img : 'https://cdn1.infocasas.com.uy/web/64f0b20b918ca_fincaraiz-logo.png',
        garages_in_units : true,
        show_service_room : false,
        main_projects_type : 'all',
        hide_bank_financing_info : true,
        hide_own_financing : true,
        warranty_help_wpp : '573158143646',
        allow_external_client_register : true,
        use_client_register_inside : true,
        use_some_client_register : true,
        warranty_options : {
            not_ask_amount : true,
            not_ask_ci : true
        }
    },
    "PA":{
        "name": "Panamá",
        "id": "9",
        socialHousing : false,
        subsidyLaws : false,
        rent  : true,
        land : true,
        units_rent : false,
        financing : true,
        rentWarranty : false,
        rentWarrantyPanel : false,
        mortgageLoan : false,
        expenses_ocupation_connection : true,
        pj_cash : false,
        pj_dsct : false,
        pj_final_price : false,
        pj_plazo_obra : false,
        pj_list_price : true,
        pj_units_rent : false,
        type_offer : 'offer',
        max_commission : false,
        file_commission : false,
        contact_email : 'iris@encuentra24.com',
        contact_phone : '+5076923-4819',
        contact_wpp : '+5076923-4819',
        contact_wame : 'https://wa.me/50769234819',
        contact_tel : '+50769234819',
        powered_by_img : 'https://cdn2.infocasas.com.uy/web/th.outside100x100.61f806aab1ae4_infocdn__logo-infocasas-blanco@2x.png',
        hide_m2_outdoor : true,
        garages_in_units : true,
        show_service_room : true,
        common_expenses_exact : true,
        hide_sales : true,
        main_projects_type : 'residency',
        header_residency : true,
        header_comercial: true,
        use_client_register_outside : true,
        use_some_client_register : true,
        units_show_floor : true,
        group_services : true,
        notarial_service : true
    },
    "CL":{
        name: "Chile",
        id: "10",
        socialHousing : false,
        subsidyLaws : true,
        rent  : false,
        land : true,
        units_rent : false,
        financing : false,
        rentWarranty : true,
        rentWarrantyPanel : false,
        mortgageLoan : false,
        expenses_ocupation_connection : false,
        pj_cash : false,
        pj_dsct : true,
        pj_final_price : true,
        pj_plazo_obra : false,
        pj_list_price : true,
        pj_units_rent : false,
        type_offer : 'reservation',
        max_commission : true,
        file_commission : true,
        contact_email : 'iris@yapo.cl',
        contact_phone : '+56 9 8310 6930',
        contact_wpp : '+56 9 8310 6930',
        contact_wame : 'https://wa.me/56983106930',
        contact_tel : '+56983106930',
        powered_by_img : 'https://cdn1.infocasas.com.uy/web/64a5d5c47b50c_logo_yapo_blanco.png',
        pieBonus : true,
        show_service_room : false,
        has_estimation : true,
        main_projects_type : 'all',
        warranty_help_wpp : '56983106930',
        enable_iris_academy : true,
        enable_appraisal : true,
        used_properties : true,
        virtual_sign_service : false,
        group_services : true,
        show_academy_in_services : true
    },
    "DO":{
        "name": "República Dominicana",
        "id": "DO1",
        socialHousing : true,
        subsidyLaws : false,
        rent  : true,
        land : true,
        units_rent : true,
        financing : true,
        rentWarranty : false,
        rentWarrantyPanel : false,
        mortgageLoan : false,
        expenses_ocupation_connection : true,
        pj_cash : true,
        pj_dsct : false,
        pj_final_price : false,
        pj_plazo_obra : true,
        pj_list_price : true,
        pj_units_rent : true,
        type_offer : 'offer',
        max_commission : false,
        file_commission : false,
        contact_email : 'iris@infocasas.com.uy',
        contact_phone : '+598 2605 55 55',
        contact_wpp : '+598 97 259 343',
        contact_wame : 'https://wa.me/59897259343',
        contact_tel : '+59826055555',
        powered_by_img : 'https://cdn2.infocasas.com.uy/web/th.outside100x100.61f806aab1ae4_infocdn__logo-infocasas-blanco@2x.png',
        show_service_room : false,
        main_projects_type : 'all',
        hide_bank_financing_info : true,
        hide_own_financing : true
    },
}


export const filtersByCountry: {[key : string] : FiltersByCountry} = {
    "UY":{
        propertyType: true,
        price: true,
        estates: true,
        bethrooms: true,
        badrooms: true,
        amenities: true,
        searchProject: true,
        subjectToSubsidy: false,
        financing: true,
        handOverDate: true,
        promotedHousing: true,
        unitsRented: true,
        pieBonus : false,
        serviceRoom : false,
        developers : true
    },
    "PY":{
        propertyType: true,
        price: true,
        estates: true,
        bethrooms: true,
        badrooms: true,
        amenities: true,
        searchProject: true,
        subjectToSubsidy: false,
        financing: true,
        handOverDate: true,
        promotedHousing: true,
        unitsRented: true,
        pieBonus : false,
        serviceRoom : false,
        developers : true
    },
    "BO":{
        propertyType: true,
        price: true,
        estates: true,
        bethrooms: true,
        badrooms: true,
        amenities: true,
        searchProject: true,
        subjectToSubsidy: true,
        financing: true,
        handOverDate: true,
        promotedHousing: true,
        unitsRented: true,
        pieBonus : false,
        serviceRoom : false,
        developers : false
    },
    "ES":{
        propertyType: true,
        price: true,
        estates: true,
        bethrooms: true,
        badrooms: true,
        amenities: true,
        searchProject: true,
        subjectToSubsidy: true,
        financing: true,
        handOverDate: true,
        promotedHousing: true,
        unitsRented: true,
        pieBonus : false,
        serviceRoom : false,
        developers : false
    },
    "CO":{
        propertyType: true,
        price: true,
        estates: true,
        bethrooms: true,
        badrooms: true,
        amenities: true,
        searchProject: true,
        subjectToSubsidy: false,
        financing: true,
        handOverDate: true,
        promotedHousing: true,
        unitsRented: true,
        pieBonus : false,
        serviceRoom : false,
        developers : true
    },
    "PA":{
        propertyType: true,
        price: true,
        estates: true,
        bethrooms: true,
        badrooms: true,
        amenities: true,
        searchProject: true,
        subjectToSubsidy: false,
        financing: true,
        handOverDate: true,
        promotedHousing: false,
        unitsRented: false,
        pieBonus : false,
        serviceRoom : true,
        developers : true
    },
    "DO":{
        propertyType: true,
        price: true,
        estates: true,
        bethrooms: true,
        badrooms: true,
        amenities: true,
        searchProject: true,
        subjectToSubsidy: true,
        financing: true,
        handOverDate: true,
        promotedHousing: true,
        unitsRented: true,
        pieBonus : false,
        serviceRoom : false,
        developers : true
    },
    "CL":{
        propertyType: true,
        price: true,
        estates: true,
        bethrooms: true,
        badrooms: true,
        amenities: true,
        searchProject: true,
        subjectToSubsidy: true,
        financing: false,
        handOverDate: true,
        promotedHousing: false,
        unitsRented: false,
        pieBonus : true,
        serviceRoom : false,
        developers : false
    }
}

export const institutionalLinks: {[key : string] : Institutional[]} = {
    "UY": [
        {
            "text": "Política de privacidad",
            "link": "https://iris.infocasas.com.uy/terminos-y-condiciones",
            "code": "termsConditions"
        },
        {
            "text": "Política de cookies",
            "link": "https://iris.infocasas.com.uy/terminos-y-condiciones",
            "code": "cookiesPolicies"
        }
    ],
    "PY": [
        {
            "text": "Política de privacidad",
            "link": "https://iris.infocasas.com.py/terminos-y-condiciones",
            "code": "termsConditions"
        },
        {
            "text": "Política de cookies",
            "link": "https://iris.infocasas.com.py/terminos-y-condiciones",
            "code": "cookiesPolicies"
        }
    ],
    "BO": [
        {
            "text": "Política de privacidad",
            "link": "https://iris.infocasas.com.uy/terminos-y-condiciones",
            "code": "termsConditions"
        },
        {
            "text": "Política de cookies",
            "link": "https://iris.infocasas.com.uy/terminos-y-condiciones",
            "code": "cookiesPolicies"
        }
    ],
    "ES": [
        {
            "text": "Política de privacidad",
            "link": "https://iris.infocasas.com.uy/terminos-y-condiciones",
            "code": "termsConditions"
        },
        {
            "text": "Política de cookies",
            "link": "https://iris.infocasas.com.uy/terminos-y-condiciones",
            "code": "cookiesPolicies"
        }
    ],
    "CO": [
        {
            "text": "Política de privacidad",
            "link": "https://iris.fincaraiz.com.co/terminos-y-condiciones",
            "code": "termsConditions"
        },
        {
            "text": "Política de cookies",
            "link": "https://iris.fincaraiz.com.co/terminos-y-condiciones",
            "code": "cookiesPolicies"
        }
    ],
    "PA": [
        {
            "text": "Términos y Condiciones",
            "link": "https://iris.encuentra24.com/terminos-y-condiciones",
            "code": "termsConditions"
        }
    ],
    "CL": [
        {
            "text": "Términos y condiciones",
            "link": "https://iris.yapo.cl/terminos-y-condiciones",
            "code": "termsConditions"
        },
        {
            "text": "Política de privacidad",
            "link": "https://iris.yapo.cl/privacidad",
            "code": "privacyPolicies"
        }
    ],
    "DO": [
        {
            "text": "Política de privacidad",
            "link": "https://iris.infocasas.com.uy/terminos-y-condiciones",
            "code": "termsConditions"
        },
        {
            "text": "Política de cookies",
            "link": "https://iris.infocasas.com.uy/terminos-y-condiciones",
            "code": "cookiesPolicies"
        }
    ],
}
