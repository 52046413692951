
import { RankingProjectsResponse, RankingUsersResponse } from "../../interfaces/ranking"
import { LevelInfoResponse } from "../../interfaces/user"
import {useFetch} from "./useFetch"

export const useRankingFetch = () => {

    const {authFetch} = useFetch()

    const getUsersRanking = async (country_id : number): Promise<RankingUsersResponse> => {

        const response : RankingUsersResponse = await authFetch(
            process.env.REACT_APP_AUTH_API_URL + '/api/ranking/get-users-ranking',
            {
                method : 'POST',
                body : JSON.stringify({country_id : country_id}),
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache'
                },
            }
        )
        
        return response;
    }

    const getProjectsRanking = async (country_id : number): Promise<RankingProjectsResponse> => {

        const response : RankingProjectsResponse = await authFetch(
            process.env.REACT_APP_AUTH_API_URL + '/api/ranking/get-projects-ranking',
            {
                method : 'POST',
                body : JSON.stringify({country_id : country_id}),
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache'
                },
            }
        )
        
        return response;
    }

    return {
        getUsersRanking,
        getProjectsRanking
    }
}