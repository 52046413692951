import { PostType } from "../../../interfaces/post";
import { CountryFlag } from "../../CountryFlag/CountryFlag";
import { Image } from "react-bootstrap";
import { FeedPostTooltip } from "../FeedPostTooltip/FeedPostTooltip";
import useBreakpoint from "use-breakpoint";
import { BREAKPOINTS } from "../../../utils/Constants";
import dayjs from "dayjs";
import { useImage } from "../../../hooks/image/useImage";
import { IconCheckCircleFill } from "../../CustomIcons/IconCheckCircleFill";

export const FeedPostHeader = ({ post }: { post: PostType }) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");
  const optimizeSrc = useImage({
    src: !!post.publish_by.image
      ? post.publish_by.image
      : "https://cdn1.infocasas.com.uy/web/62bdd922446a2_user-no-image.png",
    width: 50,
    height: 50
  });
  
  const formatDate = (date: string) => {
    
    try {
      const finalDate = dayjs(date, "YYYY-MM-DD");
      if (finalDate.isValid()) { return finalDate.format("DD/MM/YYYY")  }
      return date
    } catch (error) {
      console.log(error)
      return date
    }
    
  }

  return (
    <>
      <FeedPostTooltip post={post}>
        <div className="post-profile d-flex flex-row">
          <div role="button" className="post-profile-image">
            <Image
              src={optimizeSrc}
              roundedCircle
              fluid
            />
          </div>
          <div className="d-flex flex-column justify-content-end">
            <p className="ms-3 fw-bold me-2 me-lg-0 text-secondary post-card-location mb-0">
              {post.publish_by.name}{" "}
              {!!post.publish_by.verified && (
                <IconCheckCircleFill width={16} height={16} className="ms-1 text-green me-2" />
              )}
            </p>
            {post.details?.publish_date && <p className="publish-date ms-3 text-light-secondary me-lg-0 mb-0">
              Publicado {formatDate(post.details?.publish_date)}
            </p>}
            
          </div>
        </div>
      </FeedPostTooltip>

      <div
        className={
          "post-info-header d-flex " +
          (breakpoint !== "xs"
            ? "flex-column align-items-end justify-content-between"
            : "mt-3")
        }
      >
        <CountryFlag flag={post.details?.country?.flag ?? ""} shadow border />
        {post.type === "Proyecto" && (
          <p
            className={
              "label-detail fw-bold me-lg-0 text-primary mb-0 " +
              (breakpoint !== "xs" ? "me-2 text-end" : "ms-2")
            }
          >
            Proyecto nuevo · Lanzamiento
          </p>
        )}
      </div>
    </>
  );
};
