import { Button, Col, Row } from "react-bootstrap";

export const ToggleButtons = ({
  options,
  value,
  onChange,
}: {
  options: { value: string; text: string }[];
  value?: string;
  onChange: (x: string) => void;
}) => {
  return (
    <Row className="gx-2">
      {options.map((opt, index) => (
        <Col key={"option_" + index}>
          <Button
            variant={value === opt.value ? "outline-primary" : "outline-light"}
            className={`w-100 fs-sm schedule-visit-button ${
              value === opt.value ? "text-secondary activo" : "text-black-50"
            }`}
            children={opt.text}
            onClick={() => onChange(opt.value)}
          />
        </Col>
      ))}
    </Row>
  );
};
