import { Col, Row } from "react-bootstrap"
import { useCounterDays } from "../../hooks/counterDays/useCounterDays";

export const Countdown = ({ date } : { date: any}) => {
    const [days, hours, minutes, seconds] = useCounterDays(date);
    
    return (
        <div className="contador-container">
            <span className="text">FALTAN</span>

            <div className="contador">
                <p className="time-block">
                    <span className="time-block_number">{days}:</span>
                    <span className="time-block_text">días</span>
                </p>

                <div className="separator"></div>

                <p className="time-block">
                    <span className="time-block_number">{hours}:</span>
                    <span className="time-block_text">horas</span>
                </p>
                {/* <p className="time-block">
                    <span className="time-block_number">{minutes}</span>
                    <span className="time-block_text">mins</span>
                </p> */}
            </div>
        </div>
    );
};