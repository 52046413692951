import { useReducer, useState } from "react";
import { Filter } from "../../interfaces/filter";
import FilterContext from "./FilterContext";
import { FilterReducer } from "./FilterReducer";
import { useSearchParams } from "react-router-dom";

interface props {
  children: JSX.Element | JSX.Element[];
}

let INITIAL_STATE: Filter = {};

export const FilterProvider = ({ children }: props) => {
  const [searchParams] = useSearchParams();

  const getIntialState = () => {

    searchParams.forEach((value: any, key) => {
      
      switch (key) {
        case "amenities":
          INITIAL_STATE.amenities = value.split(',')
          break;

        case "bathrooms":
          INITIAL_STATE.bathrooms = value.split(',')
          break;

        case "country":
          INITIAL_STATE.country = value.split(',')
          break;

        case "location":
          INITIAL_STATE.location = value.split(',')
          break;

        case "neighbourhood":
          INITIAL_STATE.neighbourhood = value.split(',')
          break;
      
        case "commune":
          INITIAL_STATE.commune = value.split(',')
          break;

        case "minPrice":
          INITIAL_STATE.minPrice = value
          break;

        case "maxPrice":
          INITIAL_STATE.maxPrice = value
          break;

        case "propertyType":
          INITIAL_STATE.propertyType = value.split(',')
          break;

        case "rooms":
          INITIAL_STATE.rooms = value.split(',')
          break;

        case "state":
          INITIAL_STATE.state = value.split(',')
          break;

        case "order":
          INITIAL_STATE.order = value
          break;

        case "search":
          INITIAL_STATE.search = value
          break;

        case "units_rented":
          INITIAL_STATE.units_rented = value
          break;

        case "social_housing":
          INITIAL_STATE.social_housing = value
          break;

        case "hand_over_date":
          INITIAL_STATE.hand_over_date = value.split(',')
          break;

        case "financing":
          INITIAL_STATE.financing = value.split(',')
          break;
        
        case "subsidy":
          INITIAL_STATE.subsidy = value.split(',')
          break;

        case "currency_minmax":
          INITIAL_STATE.currency_minmax = value
          break;

        case "is_promo":
          INITIAL_STATE.is_promo = value
          break;
        case "developers":
          INITIAL_STATE.developers = value.split(',')
          break;
        default:
          break;
      }
    });

    return INITIAL_STATE;
  };
  
  const [filterState, dispatch] = useReducer(FilterReducer, getIntialState());
  const initialCountry = parseInt(searchParams.get('country') || '') || 0
  const [ countryFilterID , setCountryFilterID] = useState(initialCountry)

  const setFilter = (filter: Filter) => {
    dispatch({ type: "setFilter", payload: filter });
  };

  const removeFilter = () => {
    dispatch({ type: "removeFilter" });
  };

  return (
    <FilterContext.Provider
      value={{ filterState, setFilter, removeFilter, setCountryFilterID, countryFilterID }}
    >
      {children}
    </FilterContext.Provider>
  );
};
