import { useEffect, useState } from "react";
import { Button, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import useBreakpoint from "use-breakpoint";
import { BREAKPOINTS } from "../../utils/Constants";
import { useCookies } from "../../hooks/cookies/useCookies"
import { IconGrid } from "../CustomIcons/IconGrid";
import { IconCheckList } from "../CustomIcons/IconCheckList";
import useConfig from "../../hooks/config/useConfig"

export const TogglePropertyList = ({
    setListType,
    listType,
    classes,
    isInHeader
}: {
    setListType: Function;
    listType: "grid" | "list";
    classes?: string;
    isInHeader?: boolean
}) => {
    const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");
    const [openNew, setOpenNew] = useState(true);
    const { getCookie, setCookie } = useCookies();
    const {translation} = useConfig()

    useEffect(() => {
        if (isInHeader) {
            if (!getCookie("showNewTooltipDesktop")) {
                setCookie("showNewTooltipDesktop", "=1;expires=-1;path=/;SameSite=none;Secure")
                setOpenNew(true);
            } else {
                setOpenNew(false)
            };
        } else {
            if (!getCookie("showNewTooltipMobile")) {
                setCookie("showNewTooltipMobile", "=1;expires=-1;path=/;SameSite=none;Secure")
                setOpenNew(true);
            } else {
                setOpenNew(false)
            };
        }
    }, []);

    useEffect(() => {
        window.localStorage.setItem("ListType", listType);
    }, [listType])

    return (
        <OverlayTrigger
            show={openNew}
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={(props) => (
                <Tooltip className="new-list" {...props}>
                    <div>
                        <Button className="new-list-close" onClick={() => setOpenNew(false)}>
                            <i className="bi bi-x-lg"></i>
                        </Button>
                        <div className="pt-2 pe-3 text-start">
                            <span className="fw-bold">¡NUEVO! </span>
                            <span>{translation.tooltip_list}</span>
                        </div>
                    </div>
                </Tooltip>
            )}
        >
            <Col
                xs={{ order: 2, span: "auto" }}
                lg={{ order: 0 }}
                className={"container-toggle-list flex-shrink-1 " + (classes ?? "") + (openNew ? " active-new" : "")}
            >
                <Button
                    onClick={() => setListType("grid")}
                    className={"btn-toogle-list " + (listType === "grid" ? "active" : "")}
                >
                    <IconGrid width={16} height={16} className="mb-1" />
                    {(listType === "grid" &&
                        (breakpoint === "lg" ||
                            breakpoint === "xl" ||
                            breakpoint === "xxl"
                        )) && <span className="ms-2">Grilla</span>}
                </Button>
                <Button
                    onClick={() => setListType("list")}
                    className={"btn-toogle-list " + (listType === "list" ? "active" : "")}
                >
                    <IconCheckList width={17} height={17} className="mb-1" />
                    {(listType === "list" &&
                        (breakpoint === "lg" ||
                            breakpoint === "xl" ||
                            breakpoint === "xxl"
                        )) && <span className="ms-2">Lista</span>}
                </Button>
            </Col>
        </OverlayTrigger>
    )
}