import { useEffect, useState } from "react"
import { useAppraisalFetch } from "../fetch/useAppraisalFetch"
import { AppraisalCompany, GetAppraisalCompaniesResponse } from "../../interfaces/appraisal"

export const useAppraisal = () => {

    const [ appraisalCompanies , setAppraisalCompanies ] = useState<AppraisalCompany[]>([])
    const { getAppraisalCompanies } = useAppraisalFetch()

    useEffect(() => {
        getAppraisalCompanies().then((data : GetAppraisalCompaniesResponse) => {
            if(data.success){
                setAppraisalCompanies(data.data)
            }
        })
        
    },[])

    return {
        appraisalCompanies
    }
}