
import { Course } from "../../interfaces/academy"
import { CustomImage } from "../CustomImage/CustomImage"
import "./CourseCard.scss"
import { formatMoney, validateYoutubeVideo } from "../../utils/Functions"
import { useEffect, useRef, useState } from "react"
import ReactPlayer from "react-player"
import { GeneralGallery } from "../ui/gallery/GeneralGallery"

export const CourseCard = ({ course }: { course: Course }) => {

    const defaultImg = 'https://cdn1.infocasas.com.uy/web/62ab96629ac14_default-img-property.png'
    const [hasVideo, setHasVideo] = useState(false)
    const videoRef = useRef(null);

    useEffect(()=> {
        if (course.image && validateYoutubeVideo(course.image)) setHasVideo(true)
    },[])

    return (
        <div className="course-card border rounded w-100 d-flex flex-column bg-white shadow-sm pb-2">
            <div className=" d-flex flex-column justify-content-center img-container"  >

            {
                course.image && 
                <GeneralGallery 
                heightImgCard={250}
                images={[course.image]}
                isCard={true}
                />
            }   
            
            </div>
            
            <div className="course-content p-2 d-flex flex-column justify-content-between h-100 ">
                <p className="fw-bold text-secondary property-card-title lh-sm mb-1"> {course.name}</p>
                <div>
                <p className="text-end mb-0"><small ><span className="me-2">Precio regular:</span><span className="text-decoration-line-through">{course.currency} {formatMoney(course.regular_price)}</span></small></p>
                <p className="text-end">
                    <span className="me-2">Precio Iris:</span>
                    <span className="fw-bold">{course.currency} {formatMoney(course.iris_price)}</span></p>
                </div>
                
            </div>
            
        </div>

    )
}