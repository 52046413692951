import { Col, Container, Row, Button, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import useBreakpoint from "use-breakpoint";
import { BREAKPOINTS } from "../../utils/Constants";
import { downloadDocument } from "../../data/api";
import { useGoogleAnalytics } from "../../hooks/googleAnalytics/useGoogleAnalytics";
import useUser from "../../hooks/user/useUser";
import { IconBookmark } from "../../components/CustomIcons/IconBookmark";
import { Course } from "../../interfaces/academy";


export const CourseDetailHeader = ({ course }:
  { course: Course }) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");
  const { userState } = useUser();
  const GA = useGoogleAnalytics();

  const handleDownloadBrochure = () => {
    downloadDocument((course.brochure ?? ""), course.name, course.brochure?.split('.').pop() ?? "pdf");

    GA.Event({ category: "User download course brochure", action: "user_download_course_brochure", label: `${course.name}`, value: Number(userState.id) });
  }


  return (
    <div className="detail-header w-100 bg-white border-bottom">
      <Container className={"h-100 " + (breakpoint === "xs" ? "px-4" : "")}>
        <Row className="h-100 d-flex align-items-center gx-3 position-relative">
          <Col className="d-flex flex-grow-1 align-items-center gap-2">

            <h1 className="title fs-4 mb-1 fw-bold lh-1">{course?.name}</h1>
          </Col>

          {
            (course.brochure && course.brochure !== "") &&
            <Col xs="auto" className="d-flex gap-1 gap-md-3 px-0">
              <Button
                className="property-download px-2 py-0 w-100 text-start"
                onClick={handleDownloadBrochure}
                variant="outline"
              >
                <IconBookmark width={20} height={20} className="text-primary" />
                <span className="ms-2">Descargar brochure</span>
              </Button>
            </Col>
          }

        </Row>
      </Container>
    </div>
  );
};
