export const IconEventsRoomCustom = ({ width = 20 }) => {
  return (
<svg fill="#000000" height={width} width={width} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
	 viewBox="0 0 392.424 392.424" >
<g>
	<path d="M381.471,153.621h-21.562c-1.33-11.813-7.624-22.14-16.746-28.842v-6.77c0-20.936-17.032-37.969-37.969-37.969h-16.488
		c-20.937,0-37.969,17.033-37.969,37.969v29.013c-0.773-2.739-1.819-5.364-3.109-7.844v-21.169
		c0-20.936-17.032-37.969-37.969-37.969H193.17c-20.936,0-37.969,17.033-37.969,37.969v21.169c-1.29,2.48-2.336,5.104-3.109,7.844
		v-29.013c0-20.936-17.033-37.969-37.969-37.969H97.635c-20.937,0-37.97,17.033-37.97,37.969v6.771
		c-9.121,6.702-15.416,17.029-16.745,28.841H10.953C4.913,153.621,0,158.534,0,164.574v121.147c0,5.523,4.478,10,10,10
		s10-4.477,10-10v0v-87.383h22.649v32.979c0,5.523,4.478,10,10,10h7.016v5.369c-5.443,0.092-9.829,4.526-9.829,9.991
		s4.386,9.899,9.829,9.991v19.051v16.664c0,5.523,4.478,10,10,10s10-4.477,10-10V285.72v-19.043h27.748v19.043v16.664
		c0,5.523,4.478,10,10,10s10-4.477,10-10V285.72v-19.051c5.443-0.092,9.83-4.526,9.83-9.991s-4.387-9.9-9.83-9.991v-5.369h7.017
		c5.522,0,10-4.477,10-10v-32.979h6.095v32.979c0,5.523,4.478,10,10,10h7.016v5.369c-5.443,0.092-9.829,4.526-9.829,9.991
		s4.386,9.899,9.829,9.991v19.051v16.664c0,5.523,4.478,10,10,10s10-4.477,10-10V285.72v-19.043h27.748v19.043v16.664
		c0,5.523,4.478,10,10,10s10-4.477,10-10V285.72v-19.051c5.443-0.092,9.829-4.526,9.829-9.991s-4.386-9.899-9.829-9.991v-5.369
		h7.017c5.522,0,10-4.477,10-10v-32.979h6.095v32.979c0,5.523,4.478,10,10,10h7.016v5.369c-5.443,0.092-9.829,4.526-9.829,9.991
		s4.386,9.899,9.829,9.991v19.051v16.664c0,5.523,4.478,10,10,10s10-4.477,10-10V285.72v-19.043h27.748v19.043v16.664
		c0,5.523,4.478,10,10,10s10-4.477,10-10V285.72v-19.051c5.443-0.092,9.829-4.526,9.829-9.991s-4.386-9.899-9.829-9.991v-5.369
		h7.017c5.522,0,10-4.477,10-10v-32.979h12.244v87.383v0c0,5.523,4.478,10,10,10s10-4.477,10-10V164.574
		C392.424,158.534,387.511,153.621,381.471,153.621z M323.163,241.317v5.36h-27.748v-5.36H323.163z M340.18,221.317h-61.78V158.3
		c0-11.9,9.682-21.581,21.581-21.581h18.618c11.899,0,21.581,9.681,21.581,21.581V221.317z M193.17,100.04h16.489
		c9.908,0,17.969,8.061,17.969,17.969v2.316c-5.169-2.31-10.886-3.605-16.904-3.605h-18.618c-6.018,0-11.735,1.295-16.904,3.605
		v-2.316C175.201,108.1,183.262,100.04,193.17,100.04z M305.194,100.04c9.557,0,17.373,7.505,17.916,16.929
		c-1.483-0.161-2.987-0.249-4.512-0.249H299.98c-11.392,0-21.726,4.608-29.243,12.054v-10.765c0-9.908,8.061-17.969,17.969-17.969
		H305.194z M144.43,173.621h6.095v4.717h-6.095V173.621z M252.305,173.621h6.095v4.717h-6.095V173.621z M132.092,118.008v10.765
		c-7.518-7.446-17.851-12.054-29.243-12.054H84.23c-1.525,0-3.03,0.088-4.513,0.249c0.543-9.423,8.36-16.929,17.917-16.929h16.488
		C124.031,100.04,132.092,108.1,132.092,118.008z M20,173.621h22.649v4.717H20V173.621z M107.413,241.317v5.36H79.665v-5.36H107.413
		z M124.43,221.317h-61.78V158.3c0-11.9,9.682-21.581,21.581-21.581h18.618c11.899,0,21.581,9.681,21.581,21.581V221.317z
		 M215.288,241.317v5.36H187.54v-5.36H215.288z M232.305,221.317h-61.78V158.3c0-11.9,9.682-21.581,21.581-21.581h18.618
		c11.899,0,21.581,9.681,21.581,21.581V221.317z M360.18,173.621h12.244v4.717H360.18V173.621z"/>
</g>
</svg>
  );
};

