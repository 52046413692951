import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { PropertyFormButton } from "../../components/PropertyFormButton/PropertyFormButton";
import { PropertyGallery } from "../../components/PropertyGallery/PropertyGallery";
import { PropertyPrice } from "../../components/PropertyPrice/PropertyPrice";
import { OperationType } from "../../interfaces/property";
import useBreakpoint from "use-breakpoint";
import { BREAKPOINTS } from "../../utils/Constants";
import { CustomImage } from "../../components/CustomImage/CustomImage";
import useUser from "../../hooks/user/useUser";
import JSZip from "jszip";
import fileDownload from 'js-file-download';
import { IconDownloadImage } from "../../components/CustomIcons/IconDownloadImage";
import { formatMoney, validateYoutubeVideo } from "../../utils/Functions";
import { useGoogleAnalytics } from "../../hooks/googleAnalytics/useGoogleAnalytics";
import useConfig from "../../hooks/config/useConfig";

import { Course } from "../../interfaces/academy";
import { Link, useLocation } from "react-router-dom";
import { GeneralGallery } from "../../components/ui/gallery/GeneralGallery";

export const CourseTopSection = ({ course , setActiveTab  }: { course: Course , setActiveTab : Function }) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");
  const { pathname, search } = useLocation();
  const {country} = useConfig()

  const handleClick = (e : any) => {

    e.stopPropagation()
    
    if(course.link_type == 'PAGO'){
      window.open(pathname + (search || '') + "#FINANCIAL" , "_self")

      setActiveTab('FINANCIAL')
    }else if(course.link_type == 'REDIRECCIÓN'){
      window.open(course.link,"_blank")
    }
  }


  return (
    <>
      <Row className="d-flex align-items-stretch justify-content-between gx-4 gy-3">
        <Col
          lg={{ order: 1, span: 4 }}
          xs={{ order: 2 }}
          className={"d-flex flex-column align-items-start " + (breakpoint === "xs" ? "mt-5" : "")}
        >
          {
            country == 'CL' && 
            <div>
              <img 
                    width={180}
                    src="https://cdn1.infocasas.com.uy/web/661868409b153_logo-b-4.png" 
                    />
            </div>
        }
          <div className="detail-price flex-grow-1 py-lg-4 pt-2 pb-3 d-flex flex-column justify-content-center">
            <p >
              <small ><span className="me-2">Precio regular:</span><span className="text-decoration-line-through fs-6">{course.currency} {formatMoney(course.regular_price)}</span></small></p>
            <p >
              <span className="me-2">Precio Iris:</span>
              <span className="fw-bold fs-4">{course.currency} {formatMoney(course.iris_price)}</span></p>


          </div>

          <div className="d-none d-lg-block">
            {
              
              <button className="btn btn-primary" onClick={handleClick}> Consíguelo Aquí </button>
            }

          </div>

        </Col>
        <Col lg={{ order: 1, span: 8 }} xs={{ order: 1 }} className="detail-gallery">
          <div className="w-100 h-100 position-relative border rounded-3">

            {
              course.image &&
              <GeneralGallery 
                images={[course.image]}
                videoMuted={false}
                videoShowControls={1}
              />
            }
          </div>
        </Col>
      </Row>
    </>
  );
};
