import { useEffect, useState } from "react";
import {
    NavDropdown,
} from "react-bootstrap";
import useUser from "../../hooks/user/useUser";
import { NotificationsType } from "../../interfaces/notifications";
import { Offcanvas } from "react-bootstrap"
import { NotificationsItem } from "./NotificationsItem";
import { CustomImage } from "../../components/CustomImage/CustomImage";
import useCountry from "../../hooks/config/useConfig";
import useAlert from "../../hooks/alert/useAlert";
import { useFetch } from '../../hooks/fetch/useFetch'
import { IconBell } from "../CustomIcons/IconBell";
import { IconCircleFill } from "../CustomIcons/IconCircleFill";
import { AlertVariants } from "../../context/Alert/AlertContext";

export const NotificationsDropdown = ({ type, viewedExist, setViewedExist }: { type: "mobile" | "desktop", viewedExist: boolean, setViewedExist: Function }) => {
    const { userState } = useUser();
    const [notifications, setNotifications] = useState<NotificationsType[]>([]);
    const sliceQty = 10;
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const { country, config } = useCountry();
    const { setTypeGlobalAlert, setShowGlobalAlert, setMessageGlobalAlert } = useAlert()
    const { getListNotifications, markViewedNotifications } = useFetch()
    const [showDropdown, setShowDropdown] = useState(false)

    const showAlertMessage = (type: AlertVariants, message: string) => {
        setTypeGlobalAlert(type)
        setMessageGlobalAlert(message)
        setShowGlobalAlert(true)
        setTimeout(() => {
            setShowGlobalAlert(false)
            setTypeGlobalAlert('success')
            setMessageGlobalAlert('')
        }, 3000);

    }

    useEffect(() => {
        //Validamos para que dentro de getListNotifications se use country(config)
        if (country !== "" && config && config.id) {
            listAllNotifications()
        }
    }, [country, config]);

    const listAllNotifications = async () => {
        let countManualNotifications = 0
        let allNotifications: NotificationsType[] = []
        await getListNotifications({ userId: userState.id })
            .then(async (data) => {
                if (data.success) {
                    let dataset = [...data.data].slice(0, sliceQty);
                    allNotifications = dataset;
                    countManualNotifications = dataset.length;
                } else {
                    setNotifications([])
                    if (data.message && data.message !== '') {
                        showAlertMessage('danger', data.message)
                    }
                }
                return data
            });

  

        //Por si acaso redondeamos a 10
        let fullDataSet = [...allNotifications].slice(0, sliceQty);
        setViewedExist(!!fullDataSet.filter((noti) => !noti.is_viewed).length)
        setNotifications(fullDataSet);

    }

    const handleMarkAll = async () => {
        const manualIds = notifications.filter((noti) => !noti.is_viewed && noti.type == 'manual').map((noti) => noti.id);

        if (manualIds && manualIds.toString() != '') {
            await markViewedNotifications(userState.id, manualIds.toString(), false)
                .then(async result => {
                    if (!result.success && result.message && result.message !== '') {
                        showAlertMessage('danger', result.message)
                    }
                })
        }
        
        listAllNotifications()
        setShowDropdown(false)

    }

    const handleClose = () => setShowOffcanvas(false);
    const handleShow = () => setShowOffcanvas(true);

    if (type === "desktop") {
        return (
            <NavDropdown
                title={
                    <button type="button" className="btn btn-secondary bg-sm-light notifications-button"
                        onClick={() => setShowDropdown((prev) => !prev)}>

                        {viewedExist && <IconCircleFill width={12} height={12} className="text-primary notification-dot" />}
                        <IconBell width={18} height={18} className="text-dark" />
                    </button>
                }
                className="notifications-dropdown"
                align="end"
                autoClose="outside"
                show={showDropdown}
                onToggle={(opened)=>{setShowDropdown(opened)}}

            >
                <div className="notifications-header">
                    <span>Notificaciones</span>
                    <button
                        onClick={handleMarkAll}
                        disabled={
                            notifications.length === 0 ||
                            notifications.filter((not) => !not.is_viewed).length === 0
                        }
                    >Marcar todas como leídas</button>
                </div>
                {notifications.length === 0 ? (
                    <div className="empty-notifications">
                        <i className="bi bi-inbox"></i>
                        No hay notificaciones
                    </div>
                ) : (
                    <NotificationsItem data={notifications} sliceQty={sliceQty}
                        reloadList={listAllNotifications} />
                )}
            </NavDropdown>
        )
    } else {
        return (
            <>
                <button
                    className="mobile-notifications"
                    onClick={handleShow}
                >
                    <span>Notificaciones</span>
                    {viewedExist && <i className="bi bi-circle-fill text-primary notification-dot"></i>}
                </button>
                <Offcanvas show={showOffcanvas} onHide={handleClose} className="offcanvas-notifications">
                    <Offcanvas.Header>
                        <div className="container-offcanvas-logo">
                            <CustomImage
                                classes="offcanvas-logo"
                                src="https://cdn1.infocasas.com.uy/web/61eee8ead10a6_infocdn__brand_logo_400x200.png"
                                alt="Iris"
                                width={10}
                                height={10}
                            />
                        </div>

                        <Offcanvas.Title>
                            <button onClick={handleClose} className="close-button">
                                <i className="bi bi-arrow-left me-2"></i>
                            </button>
                            <span>Notificaciones</span>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        {notifications.length === 0 ? (
                            <div className="empty-notifications">
                                <i className="bi bi-inbox"></i>
                                No hay notificaciones
                            </div>
                        ) : (
                            <NotificationsItem data={notifications} sliceQty={sliceQty}
                                reloadList={listAllNotifications} />
                        )}
                        <button
                            onClick={handleMarkAll}
                            className="button-mark"
                            disabled={
                                notifications.length === 0 ||
                                notifications.filter((not) => !not.is_viewed).length === 0
                            }
                        >Marcar todas como leídas</button>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        )
    }
}