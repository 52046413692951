
export const TermsConditionsUY = () => {
    const fullUrl = window.location.protocol + '//' + window.location.hostname
    const wwwUrl = 'www.'+window.location.hostname
    return (
        <div>
            <div className="container py-5">
                <div className="row">
                    <div className="col-12">
                        <p className="title text-center fw-bold">TÉRMINOS Y CONDICIONES INMOBILIARIAS ASOCIADAS IRIS</p>
                        <p>
                            <span className="text-decoration-underline">INFOCASAS LE DA LA BIENVENIDA Y LE INFORMA E INVITA A TENER PRESENTE, que:</span><br></br> <br />Los Términos “nosotros”, “nuestro”, “IC”, “INFOCASAS” se refieren a “RELAXED S.A.”. El término “Usted”, “Usuario” o “Inmobiliaria”, se refiere al operador inmobiliario que participe del servicio Iris <a href={fullUrl} target="_blank">{fullUrl}</a>
                        </p>
                        <p>
                            INFOCASAS es una empresa cuyo objeto principal es el desarrollo de un portal informático y de servicios a través el cual realiza publicidad de distintos operadores que permita satisfacer a sus clientes mediante el ofrecimiento de inmuebles para su venta o alquiler, encontrándose debidamente inscripta ante los organismos correspondientes a dichos efectos. <br />
                            INFOCASAS ha desarrollado un servicio llamado Iris, que tiene el objeto de proveer una amplia oferta de obra nueva, a todas las inmobiliarias de los países donde se encuentre presente, para que puedan comercializar en un entorno claro, justo, y transparente.
                        </p>
                        <p>
                            <span className="fw-bold">1. Capacidad.</span><br />
                            La aceptación de estos términos y condiciones solo podrán efectuarse por personas con capacidad legal para contratar. Los actos que incapaces realicen en el sitio serán responsabilidad de sus padres, tutores o curadores, y por tanto se considerarán realizados por éstos en ejercicio de la representación legal con la que cuentan. En caso que se registre como usuario una persona jurídica, quien la representa, deberá tener capacidad legal para contratar a nombre y representación de la misma y obligarla en los términos de este acuerdo.
                        </p>
                        <p>
                            <span className="fw-bold">2. Registro.</span><br />
                            Es obligatorio completar todos los datos requeridos en el formulario de registro por el usuario. Los Usuarios garantizan y responden, en cualquier caso, de la veracidad, exactitud, vigencia y autenticidad de los Datos Personales ingresados y serán responsables de los eventuales perjuicios que padezca a consecuencia de informar erróneamente sus datos personales, eximiendo de todo tipo de responsabilidad a IC a este respecto. <br />
                            IC se reserva el derecho de solicitar cualquier comprobante y/o dato adicional a efectos de corroborar los Datos Personales, así como de suspender temporal o definitivamente a aquellos Usuarios cuyos datos no hayan podido ser confirmados. En estos casos de inhabilitación, se dará de baja la compra efectuada, sin que ello genere algún derecho a resarcimiento. <br />
                            El Usuario reconoce y acepta que la información a ser proporcionada a {wwwUrl} podrá ser almacenada y que podrá ser utilizada por ésta a fin de acreditar cualquier circunstancia que tuviera relación con el vínculo comercial entablado. <br />
                            El Usuario podrá acceder a su información personal almacenada en cualquier momento durante la vigencia de este contrato. Si en ese período cualquiera de los datos informados por el usuario a IC fuera modificado, el Usuario deberá informar esa circunstancia a IC, quien dispondrá de un plazo de 20 días para efectuar las modificaciones correspondientes en su base de datos. <br />
                            Todos aquellos datos personales del Usuario de esta página Web obtenidos por INFOCASAS serán tratados y procesados de conformidad con todas las leyes aplicables de la República Oriental del Uruguay y especialmente la ley Nº 18.331 y sus decretos reglamentarios.
                        </p>
                        <p>
                            <span className="fw-bold">4. Publicidad sobre el Proyecto y Comisiones.</span><br />
                            La Inmobiliaria se compromete a no comunicar ninguno de los proyectos que están dentro de Iris en los portales inmobiliarios. <br />
                            También se compromete a respetar los materiales de venta otorgados por el desarrollador a través de la plataforma Iris, para ser usados en su web redes sociales y base de datos. <br></br>
                                El objeto de esto es que las inmobiliarias se conviertan en un canal de venta extra para los desarrolladores y que el producto no se encuentre publicado infinidad de veces en los distintos portales.
                                <p>La inmobiliaria no podrá comunicar, cualquiera sea el medio o canal publicitario, una comisión al comprador final distinta al 3% (tres por ciento) más IVA del precio del inmueble.</p>
                        </p>
                        <p>
                            <span className="fw-bold">5. Comisiones por ventas.</span><br />
                            El porcentaje de comisión correspondiente a cada proyecto, establecido por el desarrollador, más el impuesto al valor agregado (IVA), será abonado por parte de INFOCASAS de manera íntegra a la inmobiliaria que intermedie en la venta, entendiéndose como tal, aquellas operaciones cerradas con un compromiso de compraventa de por medio. El porcentaje establecido por el desarrollador será sobre el precio de venta del inmueble sin incluir los gastos de conexiones. <br></br>
                                <p>Se entiende como “gastos de conexiones” el precio adicional sobre el precio del inmueble de modo de cubrir equipamiento en áreas comunes y alhajamiento, gastos por agrimensor para planos de mensura, entre otros posibles y/o similares.</p>
                                <p>INFOCASAS procederá al pago mencionado a la inmobiliaria en un plazo de 10 días hábiles posteriores a que se acredite el pago en las cuentas bancarias de INFOCASAS, siempre y cuando se haya firmado el compromiso de compraventa, así como también la inmobiliaria haya emitido la factura fiscal correspondiente.</p>
                        </p>
                        <p>
                            <span className="fw-bold">Política de precios y descuentos – Confidencialidad.</span><br />
                            INFOCASAS tendrá disponible en Iris la política de precios y descuentos de los distintos productos.
                            <p>IC actualizará todas las listas de precios, descuentos y disponibilidad en línea, todo lo cual deberá ser respetado por la Inmobiliaria.</p>
                            <p>La Inmobiliaria acepta que la información relacionada con la Política de Precios y Descuentos se deberá mantener en forma estrictamente confidencial en forma indefinida, independientemente de la vigencia o no de este acuerdo. Asimismo, se obliga a guardar la más estricta confidencialidad de toda información que IC le haya suministrado anteriormente a la aceptación de los presentes términos y condiciones o se le suministre en el futuro o a la que acceda en virtud o en ocasión de las relaciones entabladas con IC en virtud de este Proyecto. La Inmobiliaria declara conocer y aceptar que las obligaciones de confidencialidad aquí acordadas constituyen un elemento esencial del contrato.</p>
                        </p>
                        <p>
                            <span className="fw-bold">7. Reserva de Unidades.</span><br />
                            La Inmobiliaria podrá reservar la unidad a un cliente siempre y cuando este último abone el montocorrespondiente a las políticas de cada desarrollador.
                            <p>Cada desarrollador establecerá el tiempo máximo en el que una reserva será válida para la inmobiliaria, y en caso de que no se firme el compromiso de compraventa dentro del plazo establecido, la inmobiliaria ya no podrá reclamar y/o demandar el pago de la comisión. La política de cada desarrollador estará visible y actualizado en Iris.</p>
                        </p>
                        <p>
                            <span className="fw-bold">8. Entrega de materiales.</span><br />
                            INFOCASAS entregará materiales de “marca blanca” de modo de que la inmobiliaria publique en los sitios permitidos, así como también se la envíe a clientes directos interesados en el Proyecto. <br></br>
                                Se entiende por “marca blanca” a la ausencia de datos propios de modo que la Inmobiliaria pueda incluir sus datos de contacto para que el comprador final pueda comercializar a través de ella.
                        </p>
                        <p>
                            <span className="fw-bold">9. Independencia de las partes, cumplimiento de normas.</span><br />
                            La presente relación no tiene naturaleza laboral o de dependencia y no implica efecto societario alguno. La única relación jurídica que existe entre las Partes contratantes es aquella que existe entre partes independientes en donde una presta un servicio sin existir subordinación alguna y la otra paga un precio como contraprestación de dicho servicio. Ni el presente Contrato ni los Servicios deben ser considerados como la constitución de una asociación, sociedad, empresa conjunta (joint venture), o una relación principal-agente entre el Cliente y el Proveedor, y ninguna de las Partes tendrá derecho, o autoridad (ya sea explícita o implícita) para asumir ninguna obligación en nombre de la otra Parte. El presente contrato por tanto no representa ninguna forma de subcontratación ni queda comprendido en los parámetros de la ley 18.251, siendo empresas totalmente independientes.
                        </p>
                        <p>
                            <span className="fw-bold">10. Responsabilidad laboral</span><br />
                            <p>La Inmobiliaria es el único responsable por el cumplimiento de las obligaciones laborales, previsionales, de seguridad e higiene en el trabajo, tributarias y de cualquier otra naturaleza de su cargo respecto de su personal afectado al Proyecto.</p>
                            <p>La inmobiliaria será exclusivamente responsable por cualquier accidente que pueda sufrir su personal (o cualquier persona contratada a tales efectos) afectado al cumplimiento de este contrato, como consecuencia de la realización de las actividades objeto del contrato o en ocasión de las mismas, obligándose la Inmobiliaria a tener al personal asegurado conforme a las normas vigentes.</p>
                            <p>Sin perjuicio de lo anterior, la Inmobiliaria se obliga a reembolsar a INFOCASAS de inmediato cualquier suma de dinero que, en aplicación de cualquier norma laboral, de seguridad social, de seguridad e higiene o de accidentes de trabajo, actual o futura, IC se viera obligado a abonar a los empleados de la Inmobiliaria o a terceros, así como cualquier gasto en que debiera incurrir (sin que signifique limitación, los gastos a reembolsarse incluirán los honorarios profesionales de los abogados de IC) y que tuviera relación con el incumplimiento de las normas laborales, de seguridad social, de seguridad e higiene en el trabajo o de accidentes de trabajo de cargo de la Inmobiliaria, obligándose desde ya a mantener indemne a INFOCASAS por cualquier reclamo o pago a que se vea obligado.</p>
                        </p>
                        <p>
                            <span className="fw-bold">12. Indemnidad</span><br />
                            La Inmobiliaria se obliga a mantener indemne a IC así como a sus directores, agentes, representantes, empleados y personal en general, respecto de: (i) cualquier y todo daño, deuda, gastos, costos, cargas, pérdidas o reclamos (incluido los honorarios razonables de los asesores legales o de otra naturaleza) de cualquier naturaleza en virtud de omisiones que pueda tener en la difusión de los servicios ofrecidos o por su participación en el Proyecto.
                        </p>
                        <p>
                            <span className="fw-bold">13. Condición resolutoria expresa.</span><br />
                            Sin perjuicio de lo dispuesto en otras cláusulas de estos términos y condiciones, ambas partes podrán dar por resuelto de pleno derecho este acuerdo, sin necesidad de interpelación judicial o extrajudicial ni de gestión o noticia alguna más que una comunicación con 10 días de anterioridad, sin responsabilidad para ambas. <br></br>El contrato podrá ser resuelto sin responsabilidad alguna para IC respecto de la Inmobiliaria en caso de que cesara por cualquier causa el vínculo comercial de IC con el titular del inmueble asociado al Proyecto o en caso de que por cualquier causa se hiciera imposible la utilización del Showroom. <br></br>
                                    Se pacta la condición resolutoria expresa, y sin perjuicio de otros incumplimientos que así lo ameriten: a) por incumplimiento de la Inmobiliaria de las obligaciones asumidas en virtud de estos términos y condiciones, b) por incumplimiento de las obligaciones de confidencialidad asumidas por la Inmobiliaria, c) cualquier otra causa establecida expresamente en estos términos y condiciones.
                                </p>
                                <p>
                                    <span className="fw-bold">14. Violaciones del Sistema o Bases de Datos.</span><br />
                                    No está permitida ninguna acción o uso de dispositivo, software, u otro medio tendiente a interferir tanto en las actividades y operatoria de INFOCASAS como en las ofertas, descripciones, cuentas o bases de datos de IC. Cualquier intromisión, tentativa o actividad violatoria o contraria a las leyes sobre derecho de propiedad intelectual y/o a las prohibiciones estipuladas en este contrato harán pasible a su responsable de las acciones legales pertinentes, y a las sanciones previstas por este acuerdo, así como lo hará responsable de indemnizar los daños ocasionados.
                                </p>
                                <p>
                                    <span className="fw-bold">15. Propiedad Intelectual.</span><br />
                                    Los contenidos de las pantallas relativas a los servicios de {wwwUrl} como así también la información, datos, textos gráficos, imágenes, fotografías, audio y video clips, logotipos, iconos, programas, bases de datos, redes, archivos que permiten a la Inmobiliaria acceder y usar su cuenta, son de propiedad de IC, o está autorizado IC a su uso o reproducción y están protegidas por las leyes y los tratados internacionales de derecho de autor, marcas, patentes, modelos y diseños industriales.
                                </p>
                                <p>
                                    <span className="fw-bold">16. Modificación de Términos y Condiciones.</span><br />
                                    INFOCASAS podrá modificar estos Términos y Condiciones Generales en cualquier momento haciendo públicos en el sitio los términos modificados. Todos los términos modificados entrarán en vigor a los 5(cinco) días de su publicación. Dentro de los 5 (cinco) días siguientes a la publicación de las modificaciones introducidas, el Usuario deberá comunicar por e-mail haciendo clic aquí si no acepta las mismas; en ese caso quedará disuelto el vínculo contractual Vencido dicho plazo, se considerará que la Inmobiliaria acepta los nuevos términos y el contrato continuará vinculando a ambas partes.
                                </p>
                                <p>
                                    <span className="fw-bold">17. Divisibilidad.</span><br />
                                    En caso que cualquiera de las estipulaciones de estos términos y condiciones resultara inválida, ilegal o no ejecutable, la validez, legalidad y ejecutabilidad de las restantes cláusulas no se verá afectada, dañada o perjudicada de manera alguna.
                                </p>
                                <p>
                                    <span className="fw-bold">18. Jurisdicción y Ley Aplicable.</span><br />
                                    Este acuerdo estará regido en todos sus puntos por las normativas legales vigentes en la República Oriental del Uruguay. Cualquier controversia derivada del presente acuerdo, su existencia, validez, interpretación, alcance o cumplimiento, será sometida a los tribunales de la ciudad de Montevideo y los procedimientos se llevarán a cabo en idioma español, renunciándose en forma expresa a cualquier otro fuero o jurisdicción.
                                </p>
                                <p>
                                    <span className="fw-bold">19. Domicilio.</span><br />
                                    Se fija como domicilio de INFOCASAS en Miraflores 1353, de la Ciudad de Montevideo, República Oriental del Uruguay.
                                </p>
                                
                            </div>
                    </div>
                </div>
            </div>
    )
}
