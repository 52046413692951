import { DeveloperType } from "../../interfaces/project"

export const useProjectFetch = () => {

    const getProjectDevelopers = async ( country_id : number) => {

        const url = `${process.env.REACT_APP_AUTH_API_URL}/api/projects/get-developers?country_id=${country_id}`

        const res : {success : boolean , data? : DeveloperType[] , message? : string} = await fetch(url,
          {
            headers : {
              'Content-Type' : 'application/json'
            }
          }
        ).then(async data => {
          return await data.json()
        })
        .catch(err => {
          console.log(err)
          return {
            success : false
          }
        })
      
        return res
    }

    return {
        getProjectDevelopers
    }
}