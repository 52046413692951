import { Col, Row } from "react-bootstrap";
import { IconTree } from "../CustomIcons/IconTree";
import { IconBike } from "../CustomIcons/IconBike";
import { IconCart } from "../CustomIcons/IconCart";
import { IconDog } from "../CustomIcons/IconDog";
import { IconQuiet } from "../CustomIcons/IconQuiet";
import { IconSafe } from "../CustomIcons/IconSafe";
import { CommuneType, NeighbourhoodType } from "../../interfaces/property";
import { Commune, Zone } from "../../interfaces/locations";

export const NeighbourhoodDetails = ({ neighbourhood, isInPdf = false }: { neighbourhood: Zone | Commune, isInPdf?: boolean }) => {
  const details = [
    {
      title: "Es tranquilo",
      icon: <IconQuiet width={19} />,
      show: neighbourhood.is_quiet,
    },
    {
      title: "Es seguro",
      icon: <IconSafe width={19} />,
      show: neighbourhood.is_safe,
    },
    {
      title: "Hay supermercados",
      icon: <IconCart width={19} />,
      show: neighbourhood.has_supermarkets,
    },
    {
      title: "Hay parques y plazas",
      icon: <IconTree width={19} />,
      show: neighbourhood.has_parks_squares,
    },
    {
      title: "Dog friendly",
      icon: <IconDog width={19} />,
      show: neighbourhood.is_dog_friendly,
    },
    {
      title: "Hay ciclovías",
      icon: <IconBike width={20} />,
      show: neighbourhood.has_bikeway,
    }
  ];

  return (
    <Row className="gx-2 gy-2">
      {details
        .filter((a) => a.show)
        .map((a, index) => (
          <Col xs={!isInPdf ? "auto" : "4"} key={"amenitie_" + index}>
            <div
              className={"px-3 py-1 fw-500 fs-sm d-flex align-items-center " + (!isInPdf ? "rounded-pill border" : "")}
              style={{ height: 35 }}
            >
              {a.icon}
              <span className="ms-2">{a.title}</span>
            </div>
          </Col>
        ))}
    </Row>
  );
};
