import { Carousel } from "react-bootstrap";
import { CustomImage } from "../../components/CustomImage/CustomImage";
import useConfig from '../../hooks/config/useConfig'

export const LoginCarousel = () => {

  const {countryFeatures,country} = useConfig()

  const carouselCountryImages = [
    {
      link : 'https://cdn1.infocasas.com.uy/web/64f9ec1e627e6_cordon-uy.png',
      country : 'UY'
    },
    {
      link : 'https://cdn1.infocasas.com.uy/web/64f9ec1e905d6_florida-cl.png',
      country : 'CL'
    },
    {
      link : 'https://cdn1.infocasas.com.uy/web/64f9ec1a97a5d_malvin-uy.png',
      country : 'UY'
    },
    {
      link : 'https://cdn1.infocasas.com.uy/web/64f9ec1cf2bd4_santiago-cl.png',
      country : 'CL'
    }
  ]

  const compareCountry = (a : any, b:any)=> {
    if (a.country === country && b.country !== country) {
      return -1; // Mover 'a' antes de 'b'
    } else if (a.country !== country && b.country === country) {
      return 1; // Mover 'b' antes de 'a'
    } else {
      return 0; // Mantener el orden original
    }
  }

  const carouselImages = carouselCountryImages.sort(compareCountry)


  return (
    <div className="login-carousel h-100 w-100">
      <Carousel fade controls={false} indicators={false} interval={2500}>
        {carouselImages.map((image, index) => (
          <Carousel.Item key={"carousel_" + index}>
            <CustomImage
              classes="w-100 h-100"
              src={image.link}
              alt={"carousel_" + index}
              width={800}
              height={800}
            />
          </Carousel.Item>
        ))}
      </Carousel>
      <div className="login-carousel-caption w-100 px-5 py-4">
        <h3 className="text-white mb-3">Contáctanos</h3>
        <p>
          <a className="no-link" href={`tel:${countryFeatures.contact_tel}`} target={"_blank"}>
            <i className="bi bi-telephone-fill me-2"></i> {countryFeatures.contact_phone}
          </a>
        </p>
        <p>
          <a className="no-link" href={countryFeatures.contact_wame} target={"_blank"}>
            <i className="bi bi-whatsapp me-2"></i> {countryFeatures.contact_wpp}
          </a>
        </p>
        <p>
          <a className="no-link" href={`mailto:${countryFeatures.contact_email}`} target={"_blank"}>
            <i className="bi bi-envelope-fill me-2"></i> {countryFeatures.contact_email}
          </a>
        </p>
      </div>
    </div>
  );
};
