import { ReactNode } from "react";
import useBreakpoint from "use-breakpoint";
import { BREAKPOINTS } from "../../utils/Constants";

export const Tabs = ({
  options,
  extra,
  onChange,
  value,
}: {
  options: { title: string; value: string }[];
  onChange: (x: any) => void;
  extra?: ReactNode;
  value?: string;
}) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");

  return (
    <div className="tabs-container d-flex align-items-center justify-content-between border-bottom">
      <div className={"tabs d-flex align-items-end " + (breakpoint === "xs" ? "w-100 justify-content-between" : "")}>
        {options.map((opt, index) => {
          const active = opt.value === value;
          return (
            <div
              className={`tab ${active ? "tab-active" : ""
                } px-lg-3 px-2 mx-lg-1 py-2`}
              role={"button"}
              key={"tab_" + index}
              onClick={(e) => {
                onChange(opt.value)
              }}
            >
              <span
                className={`text-uppercase fs-sm ${active ? "text-primary fw-500" : ""
                  }`}
              >
                {opt.title}
              </span>
            </div>
          );
        })}
      </div>
      {typeof extra !== "undefined" && (
        <div className="tabs-extra d-none d-lg-block">{extra}</div>
      )}
    </div>
  );
};
