import { useEffect, useState } from "react";
import { Alert, Button, Form, Row, Col } from "react-bootstrap";
import { useFetch } from "../../../hooks/fetch/useFetch";
import { useAdminFetch } from "../../../hooks/fetch/useAdminFetch";
import { Link, useNavigate, useParams } from "react-router-dom";
import SpecialSelect from "../../../components/ui/SpecialSelect";
import { GarageType, ProjectType, UnitType } from "../../../interfaces/project";
import { User } from "../../../interfaces/user";
import "./SaleForm.scss"
import { CurrencyType } from "../../../interfaces/auxiliar";
import { UserSaleInfo } from "../../../interfaces/apiTypes";
import moment from "moment";
import useAlert from "../../../hooks/alert/useAlert";
import Lottie from "lottie-react";
import GrayLoader from "../../../lotties/gray-loader.json";
import loaderIris from "../../../lotties/orange-loader.json";
import { getDateYMD } from "../../../utils/Functions";
import { AlertVariants } from "../../../context/Alert/AlertContext";


export const SaleForm = ({ type = 'create' }) => {

    const GARAGE_TYPE_IDENTIFIER = '8'
    const statusOptions = [
        { label : 'Registrada', value : 'Registrada'},
        { label : 'Facturada', value : 'Facturada'},
        { label : 'Cobrada', value : 'Cobrada'},
    ]
    const { id = "" } = useParams();
    const navigate = useNavigate()

    const defaultSale: UserSaleInfo = {
        id: 0,
        user_id: 0,
        project_id: 0,
        unit_id: 0,
        garage_id: 0,
        unit_price_currency: '',
        unit_price: '',
        garage_price_currency: '',
        garage_price: '',
        commission_currency: 'USD',
        commission_percentage: '',
        commission: '',
        status: 'Registrada',
        operation_expenses_percentage : '',
        delete_bill_file : false,
        delete_voucher_file : false,
        total_usd : '',
        sale_date : getDateYMD(new Date(),'-')

    }

    const { setPositionAlert, setTypeGlobalAlert, setShowGlobalAlert, setMessageGlobalAlert } = useAlert()
    const [optionsProjects, setOptionsProjects] = useState<{ value: any, label: string }[]>([])
    const [optionsUnits, setOptionsUnits] = useState<{ value: any, label: string }[]>([])
    const [optionsGarages, setOptionsGarages] = useState<{ value: any, label: string }[]>([])
    const [optionsUsers, setOptionsUsers] = useState<{ value: any, label: string }[]>([])
    const [projects, setProjects] = useState<ProjectType[]>([])
    const { getCurrencies } = useFetch()
    const { getProjectsForSale, getUsersForSales, registerSale, getProjectByIdentifierForSales, getAdminSaleById, updateSale } = useAdminFetch()

    const [project, setProject] = useState<ProjectType>()

    const [loading, setLoading] = useState(false)
    const [loadingProjects, setLoadingProjects] = useState(false)
    const [loadingUsers, setLoadingUsers] = useState(false)
    const [loadingUnits, setLoadingUnits] = useState(false)

    const [validated, setValidated] = useState(false);
    const [sale, setSale] = useState<UserSaleInfo>(defaultSale)
    const [showAlert, setShowAlert] = useState(false);
    const [typeAlert, setTypeAlert] = useState('success');
    const [messageAlert, setMessageAlert] = useState("");
    const [invalidProject, setInvalidProject] = useState(false);
    const [invalidOtherProject, setInvalidOtherProject] = useState(false);
    const [invalidUnitCurrency, setInvalidUnitCurrency] = useState(false);
    const [invalidCommissionCurrency, setInvalidCommissionCurrency] = useState(false);
    const [invalidUser, setInvalidUser] = useState(false);
    const [invalidUnit, setInvalidUnit] = useState(false);
    const [invalidOtherUnit, setInvalidOtherUnit] = useState(false);
    const [invalidGarage, setInvalidGarage] = useState(false);
    const [invalidOtherGarage, setInvalidOtherGarage] = useState(false);
    const [invalidGarageCurrency, setInvalidGarageCurrency] = useState(false);
    const [invalidStatus, setInvalidStatus] = useState(false);

    const [units, setUnits] = useState<UnitType[]>([])
    const [unit, setUnit] = useState<UnitType>()
    const [garages, setGarages] = useState<GarageType[]>([])
    const [users, setUsers] = useState<User[]>([])
    const [unitPricesOptions, setUnitPricesOptions] = useState<{ value: any, label: string }[]>([])
    const [unitPriceType, setUnitPriceType] = useState<{ value: any, label: string }>({ value: 0, label: '' })

    const [currencies, setCurrencies] = useState<CurrencyType[]>([])
    const [currenciesOptions, setCurrenciesOptions] = useState<{ value: any, label: string }[]>([])

    const [total, setTotal] = useState(0)
    const [calculateCommission, setCalculateCommission] = useState(true)
    const [voucherFile, setVoucherFile] = useState()
    const [billFile, setBillFile] = useState()
    const [saleToEdit, setSaleToEdit] = useState<UserSaleInfo>()

    const isEdit = type == 'edit' ? true : false

    const [loadingEdit, setLoadingEdit] = useState(isEdit)
    const [ showOtherProject , setShowOtherProject] = useState(false)
    const [ showOtherUnit , setShowOtherUnit] = useState(false)
    const [ showOtherGarage , setShowOtherGarage] = useState(false)

    const validNumber = new RegExp(/^\d*\.?\d{0,2}$/);

    const defaultMessages = {
        user: 'Este campo es requerido',
        project: 'Elige un proyecto',
        unit: 'Este campo es requerido',
        unit_price_currency: 'Este campo es requerido',
        unit_price_type: 'Este campo es requerido',
        unit_price: 'Este campo es requerido',
        garage: 'Este campo es requerido',
        garage_price_currency: 'Este campo es requerido',
        garage_price: 'Este campo es requerido',
        commission_percentage: 'Este campo es requerido',
        commission_currency: 'Este campo es requerido',
        commission: 'Este campo es requerido',
        operation_expenses_percentage : 'Este campo debe ser numérico',
        status : 'Estado inválido',
        total_usd : 'Este campo es requerido',
        sale_date : 'Este campo es requerido'

    }
    const [fieldsErrorMessages, setFieldsErrorMessages] = useState(defaultMessages)

    useEffect(() => {

        loadUsers().then(res => {
            loadProjects().then(res => {
                fillUnits([],false)
                fillGarages([],false)
            })
        })

        if (isEdit) {  getSale() }

        loadCurrencies()

    }, [])


    useEffect(() => {
        if (users && users.length > 0 && projects && projects.length > 0 && saleToEdit && saleToEdit.id && isEdit) {
            loadSale()
        }
    }, [users, projects, saleToEdit])

    const getSale = () => {
        getAdminSaleById(id).then(async response => {

            setLoadingEdit(false)

            if (response.success && response.data) {
                setSaleToEdit(response.data)
            }
        }).catch(err => {
            console.error(err)
            setLoadingEdit(false)
        })
    }

    const loadSale = async () => {

        setLoadingEdit(false)
        if (saleToEdit) {

            let saleData = saleToEdit
            setSaleToEdit(saleToEdit)
            setSale(prev => {
                return {
                    ...prev,
                    ...saleData
                }
            })

            if (saleData.project?.identifier) {
                await setProjectData(saleData.project?.identifier).then((result: any) => {
                    let newUnit = { value: saleData.unit_id, label: saleData.unit?.number }

                    let typePrice = loadInfoUnit(newUnit, result.units)
                    if (typePrice) setUnitPriceType(typePrice)
                }).catch(err => console.error(err))
            }else{
                
                setSale((prev: any) => {
                    return {
                        ...prev,
                        project_id: 'Otro',
                        project: {
                            title: 'Otro'
                        },
                        unit_id : saleData.unit_name? 'Otra' : 'Ninguna',
                        unit : {
                            number : saleData.unit_name? 'Otra' : 'Ninguna',
                        },
                        garage_id : saleData.garage_name? 'Otro' : 'Ninguno',
                        garage : {
                            name : saleData.garage_name? 'Otro' : 'Ninguno',
                        }
                    }
                })
                setShowOtherProject(true)
                if(saleData.unit_name) setShowOtherUnit(true)
                if(saleData.garage_name) setShowOtherGarage(true)
                
            }
        }


    }


    const loadProjects = async () => {
        return new Promise(async (resolve, reject) => {
            setLoadingProjects(true)
            getProjectsForSale().then((props: any) => {
                setLoadingProjects(false)
                if (props.success) {
                    let allProjects = props.data.map((project: ProjectType) => {
                        return {
                            value: project.id,
                            label: project.title
                        }
                    })
                    allProjects.push({value: 0, label: 'Otro'})
                    setProjects(props.data)
                    setOptionsProjects(allProjects)
                } else {
                    showAlertMessage('danger', 'Ocurrió un error al listar los proyectos')
                }

                resolve(true)

            }).catch(err => {
                reject(false)
            })
        })


    }
    const loadUsers = async () => {
        return new Promise(async (resolve, reject) => {
            setLoadingUsers(true)
            getUsersForSales().then(result => {
                setLoadingUsers(false)
                if (result.success) {
                    let items = result.data.map((item: any) => {
                        return {
                            value: item.id,
                            label: `${item.email} - ${item.real_estate}` 
                        }
                    })
                    setUsers(result.data)
                    setOptionsUsers(items)
                }
                resolve(true)
            }).catch(err => {
                console.error(err)
                setLoadingUsers(false)
                reject(false)
            })
        })

    }

    const loadCurrencies = () => {
        return new Promise(async (resolve, reject) => {
            await getCurrencies().then(result => {

                if (result.success) {
                    let options = result.data.map((item: CurrencyType) => {
                        return { value: item.name, label: item.name }
                    })
                    setCurrencies(result.data)
                    setCurrenciesOptions(options)
                    resolve(true)
                }
            }).catch(err => {
                console.error(err)
                setLoadingUsers(false)
            })
        })

    }
    

    const calculateUSDTotal = (ev : any) => {
        ev.preventDefault()
            let unit_price = sale.unit_price || '0'
            let garage_price = sale.garage_price || '0'
            calculateTotal(parseFloat(unit_price), sale.unit_price_currency, parseFloat(garage_price), sale.garage_price_currency)
        
    }

    const setProjectData = async (project_identifier: string) => {
        return new Promise(async (resolve, reject) => {

            await loadInfoProject(project_identifier).then(data => {
                resolve(data)
            })
        })
    }

    const fillUnits = (units: UnitType[], garageType: boolean) => {
        setUnits(units)
        let unitsOptions : any[] = []
        if (garageType) unitsOptions.push({ value: 'Ninguna', label: 'Ninguna' })
        unitsOptions = unitsOptions.concat(units.map(u => {
            return { value: u.id, label: u.number }
        }))
        unitsOptions.push({value : 'Otra' , label : 'Otra'})
        setOptionsUnits(unitsOptions)
    }

    const fillGarages = (garages: GarageType[], garageType: boolean) => {
        setGarages(garages)
        let garagesOptions : any[] = []
        if (!garageType) garagesOptions.push({ value: 'Ninguno', label: 'Ninguno' })
        garagesOptions = garagesOptions.concat(garages.map(u => {
            return {
                value: u.id,
                label: u.name
            }
        }))
        garagesOptions.push({value : 'Otro' , label : 'Otro'})
        setOptionsGarages(garagesOptions)
    }


    const loadInfoProject = async (identifier: any) => {

        return new Promise(async (resolve, reject) => {

            // clearUnit()

            setUnit(undefined)
            // clearGarage()
            setUnitPriceType({ value: '', label: '' })
            setUnitPricesOptions([])
            setTotal(0)
            
            setUnitPricesOptions([])

            await getProjectByIdentifierForSales(identifier, undefined).then(response => {
                setLoadingUnits(false)
                if (response.success && response.data) {

                    setInvalidProject(false)

                    let projectData = response.data
                    setProject(projectData)

                    setSale((prev: any) => {
                        return {
                            ...prev,
                            commission_percentage: projectData.financial?.commission.percent,
                            project_id: projectData.id,
                            project: {
                                identifier: projectData.identifier,
                                title: projectData.title
                            },
                            operation_expenses_percentage : projectData.operation_expenses_percentage
                        }
                    })

                    let garageType = projectData.type?.identifier == GARAGE_TYPE_IDENTIFIER ? true : false
                    fillUnits(response.data.units, garageType)
                    if (response.data.garages) fillGarages(response.data.garages, garageType)


                } else {
                    setSale((prev: any) => {
                        return {
                            ...prev,
                            project_id: undefined,
                            project: {
                                title: undefined
                            }
                        }
                    })
                }

                resolve(response.data)
            }).catch(err => {
                setLoadingUnits(false)
                console.error(err)
                reject(false)
            })
        })

    }


    const loadInfoUnit = (unit: any, units: UnitType[]) => {

        setInvalidUnit(false)
        if (units) {

            let item = units.find(p => p.id == unit.value)

            if (item) {

                setUnit(item)
                //Llenar tipos de precio

                let allTypes = []
                let selectedType: any = false
                let type
                if (item.contado_price) {
                    type = { value: 'contado_price', label: 'Precio Contado' }
                    if (isEdit &&saleToEdit?.unit_price && parseFloat(saleToEdit?.unit_price) == item.contado_price) selectedType = type
                    allTypes.push(type)
                }
                if (item.list_price) {
                    type = { value: 'list_price', label: 'Precio de Lista' }
                    if (isEdit && saleToEdit?.unit_price  && parseFloat(saleToEdit?.unit_price) == item.list_price && !selectedType) selectedType = type
                    allTypes.push(type)
                }
                if (item.work_price) {
                    type = { value: 'work_price', label: 'Precio de Obra' }
                    if (isEdit && saleToEdit?.unit_price && parseFloat(saleToEdit?.unit_price) == item.work_price && !selectedType) selectedType = type
                    allTypes.push(type)
                }

                setUnitPricesOptions(allTypes)
                if (item?.currency) {
                    setSale(prev => {
                        return {
                            ...prev,
                            unit_price_currency: item?.currency
                        }
                    })
                    setInvalidUnitCurrency(false)
                }

                return type

            }

        }
    }

    const pickUser = (user: { value: string, label: string }) => {

        const foundUser = users.find(u => u.id == user.value)

        setSale((prev: any) => {
            return {
                ...prev,
                user_id: foundUser?.id,
                user: {
                    name: foundUser?.name,
                    email: foundUser?.email,
                    real_estate: foundUser?.real_estate,
                    id: foundUser?.id
                }
            }
        })
    }

    const pickProject = (project: { value: string, label: string }) => {


        let fullProject = projects.find(p => p.id.toString() == project.value)

        //VACIAR UNIDADES
        fillUnits([],false)
        fillGarages([],false)

        if (project.value != sale.project_id?.toString()) {
            setSale((prev: any) => {
                return {
                    ...prev,
                    unit_id: undefined,
                    unit: {
                        number: undefined,
                    },
                    garage_id: undefined,
                    garage: {
                        name: undefined
                    },
                    unit_price: undefined,
                    unit_name : '',
                    garage_name : '',
                    unit_price_currency: '',
                    garage_price: undefined,
                    garage_price_currency: '',
                    commission: undefined,
                    commission_currency: 'USD',
                    total_usd : ''

                }
            })
        }
        if(project.label == 'Otro'){
            setShowOtherProject(true)
            pickUnit({value:'Otra' , label : 'Otra'})
            pickGarage({value:'Otro' , label : 'Otro'})
            setShowOtherUnit(true)
            setShowOtherGarage(true)
        
        }else {
            setShowOtherProject(false)
            setShowOtherUnit(false)
            setShowOtherGarage(false)
        }

        setSale((prev: any) => {
            return {
                ...prev,
                project_id: project.value,
                project: {
                    title: project.label
                },
                project_name : fullProject ? fullProject.title : ''
            }
        })
        if (fullProject) {
            setProjectData(fullProject.identifier)
        }

    }


    const pickUnit = (unit: { value: string, label: string }) => {

        setSale((prev: any) => {
            return {
                ...prev,
                unit_id: unit.value ,
                unit: {
                    number: unit.label
                },
                unit_price_currency: '',
                unit_price: undefined,
                unit_name : (!isNaN(parseInt(unit.value))) ? unit.label : '',
                total_usd : ''

            }
        })
        setUnitPricesOptions([])
        setUnitPriceType({ value: '', label: '' })

        if(unit.label == 'Otra') setShowOtherUnit(true)
        else setShowOtherUnit(false)

        loadInfoUnit(unit, units)

    }

    const pickGarage = (garage: { value: string, label: string }) => {

        setInvalidGarage(false)
        setSale((prev: any) => {
            return {
                ...prev,
                garage_id: garage.value,
                garage: { name: garage.label },
                garage_name :(!isNaN(parseInt(garage.value))) ? garage.label : ''
            }
        })
        if (garages) {

            let item = garages.find(p => p.id.toString() == garage.value)

            setSale(prev => {
                return {
                    ...prev,
                    garage_price_currency: '',
                    // garage_price: undefined
                }
            })

            if (item) {
                if (item?.currency) {
                    setSale(prev => {
                        return {
                            ...prev,
                            garage_price_currency: item?.currency,
                            // garage_price: item?.price ? parseFloat(item.price) : undefined
                        }
                    })

                    setInvalidGarageCurrency(false)
                }

            }

        }
        if(garage.label == 'Otro') setShowOtherGarage(true)
        else setShowOtherGarage(false)

    }

    const pickUnitCurrency = (unit: { value: string, label: string }) => {
        setInvalidUnitCurrency(false)
        setSale((prev) => { return { ...prev, unit_price_currency: unit.value } })

    }

    const pickGarageCurrency = (e: { value: string, label: string }) => {
        setInvalidGarageCurrency(false)
        setSale(prev => { return { ...prev, garage_price_currency: e.value } })
    }

    const calculateTotal = (unit_price?: number, unit_price_currency?: string, garage_price?: number, garage_price_currency?: string) => {

        let totalTemp = 0

        if (unit_price && unit_price_currency) totalTemp += exchange(unit_price_currency, 'USD', unit_price)
        if (garage_price && garage_price_currency) totalTemp += exchange(garage_price_currency, 'USD', garage_price)
        totalTemp = parseFloat(totalTemp.toFixed(2))
        setTotal(totalTemp)
        setSale(prev => ({...prev,total_usd : totalTemp.toString()}))
    }

    const calcCommission = (ev: any) => {
        ev.preventDefault()
        const totalTemp = total
        const commissionCurrency = sale.commission_currency
        if (sale.commission_percentage) {
            let commission_amount = parseFloat(((parseFloat(sale.commission_percentage) / 100) * totalTemp).toFixed(2))
            if (commissionCurrency) {
                let commissionByCurrency = parseFloat(exchange('USD', commissionCurrency, commission_amount).toFixed(2))
                setSale(prev => { return { ...prev, commission: commissionByCurrency.toString() } })
            }
        }
    }


    const handleChangeUnitPrice = (ev: any) => {

        let lastValid = sale.unit_price
        if (validNumber.test(ev.target.value)) {
            lastValid = ev.target.value;
        }
        setSale(prev => ({...prev,unit_price : lastValid}))
       
    }
    
    const handleChangeGaragePrice = (ev: any) => {
        
        let lastValid = sale.garage_price
        if (validNumber.test(ev.target.value)) {
            lastValid = ev.target.value;
        }
        setSale(prev => ({...prev,garage_price : lastValid}))
    }

    
    const handleChangeTotal = (ev: any) => {
        let lastValid = sale.total_usd
        if (validNumber.test(ev.target.value)) {
            lastValid = ev.target.value;
        }
        setSale(prev => ({...prev,total_usd : lastValid}))
    }
    const exchange = (from: string, to: string, value: number) => {

        let currenciesByName: any = {}
        currencies.map(c => {
            currenciesByName[c.name] = c
        })


        let currencyFrom = currenciesByName[from]
        let currencyTo = currenciesByName[to]

        if (currencyFrom && currencyTo) {
            try {
                value = (currencyTo.exchange_rate / currencyFrom.exchange_rate) * value
            } catch (error) {
            }
        }
        return value

    }

    const showAlertMessage = (type: AlertVariants, message: string) => {
        setPositionAlert('bottom')
        setTypeGlobalAlert(type)
        setMessageGlobalAlert(message)
        setShowGlobalAlert(true)
        setTimeout(() => {
            setShowGlobalAlert(false)
            setTypeGlobalAlert('success')
            setMessageGlobalAlert('')
            setPositionAlert('top')
        }, 3000);

    }

    const setInvalidFalse = () => {
        setInvalidUser(false)
        setInvalidProject(false)
        setInvalidOtherProject(false)
        setInvalidGarage(false)
        setInvalidOtherGarage(false)
        setInvalidGarageCurrency(false)
        setInvalidUnit(false)
        setInvalidOtherUnit(false)
        setInvalidUnitCurrency(false)
    }

    const validateCustom = (form : any) => {
        let valid = true
        
        setInvalidFalse()

        if (!sale.user_id) {
            setInvalidUser(true)
            valid = false
        }

        if(!sale.project_id){
            if(showOtherProject&& !sale.project_name){
                setFieldsErrorMessages(prev => ({...prev,project : 'Ingresa un proyecto'}))
                setInvalidOtherProject(true)
                valid = false
            }
            if(!showOtherProject){
                setFieldsErrorMessages(prev => ({...prev,project : 'Elige un proyecto'}))
                setInvalidProject(true)
                valid = false
            }
            
        }
        

        if (project) {

            if (project.type?.identifier == GARAGE_TYPE_IDENTIFIER) {

                //Exigir garage
                if (!sale.garage_id) {
                    setInvalidGarage(true)
                    valid = false
                }
                if (!sale.garage_price_currency) {
                    setInvalidGarageCurrency(true)
                    valid = false
                }

            } else {
                //Exigir unidad
                if (!sale.unit_id) {
                    if(showOtherUnit&& !sale.unit_name){
                        setFieldsErrorMessages(prev => ({...prev,unit : 'Ingresa una unidad'}))
                        setInvalidOtherUnit(true)
                    }
                    if(!showOtherProject){
                        setFieldsErrorMessages(prev => ({...prev,unit : 'Elige una unidad'}))
                        setInvalidUnit(true)
                    }
                    valid = false
                }
                if (!sale.unit_price_currency) {
                    setInvalidUnitCurrency(true)
                    valid = false
                }

            }
        }
        
        //Ha elegido otra unidad 
        if(showOtherUnit){
            if(!sale.unit_name){
                setFieldsErrorMessages(prev => ({...prev,unit : 'Ingresa una unidad'}))
                setInvalidOtherUnit(true)
                valid = false
            }
            
            if(!sale.unit_price_currency){
                setInvalidUnitCurrency(true)
                valid = false
            }
            
        }
        //He elegido otro garage
        if(showOtherGarage){
            if(!sale.garage_name){
                setFieldsErrorMessages(prev => ({...prev,garage : 'Ingresa un garage'}))
                setInvalidOtherGarage(true)
                valid = false
            }
            if (!sale.garage_price_currency) {
                setInvalidGarageCurrency(true)
                valid = false
            }
        }

        //Validate total:
        if(((sale.unit_price != null && parseFloat(sale.unit_price) > 0) || (sale.garage_price != null && parseFloat(sale.garage_price) > 0))
         && sale.total_usd != undefined && parseFloat(sale.total_usd) <= 0 ){
            
            valid = false
            setFieldsErrorMessages(prev => ({...prev, total_usd : 'El total debe ser mayor a 0'}))
            form['total_usd'].setCustomValidity('Error')
        }


        return valid
    }

    const clearUnit = () => {
        setSale((prev: any) => ({
                ...prev,
                unit_id: undefined,
                unit: {
                    number: undefined
                }
        }))
    }

    const clearGarage = () => {
        setSale((prev: any) => ({
            ...prev,
            garage_id: undefined,
            garage: { name: undefined }
        }))
    }

    const finishOperation = (response: any, form: any) => {
        showAlertMessage('success', response.message ? response.message : 'Éxito')
        setSale(defaultSale)

        setValidated(false);

        setCalculateCommission(true)
        clearUnit()
        clearGarage()

        setTotal(0)
        setUnitPriceType({ value: '', label: '' })

        setSale(defaultSale)

        resetForm(form)
        //form.reset()
        navigate('/admin/sales')
        setTimeout(() => {
            setShowAlert(false)
            setMessageAlert('')

        }, 4000);
    }

    const handleSubmit = (event: any) => {
        event.preventDefault()

        setLoading(true)
        setShowAlert(false)
        setMessageAlert('')
        setTypeAlert('')

        let form = event.currentTarget;
        resetForm(form)
        setValidated(true);
        let validForm = form.checkValidity()
        Object.keys(defaultMessages).forEach(key => {
            try {
                form[key]?.setCustomValidity('')
            } catch (error : any) {console.log("error aqui : "+key + " " + error.toString())}
            
        })
        setFieldsErrorMessages(defaultMessages);

        let validCustom = validateCustom(form)


        if (validForm && validCustom) {
            let sendData = { 
                ...sale ,
                project_id :sale.project_id && !isNaN(sale.project_id) ? sale.project_id : undefined, 
                unit_id :sale.unit_id && !isNaN(sale.unit_id) ? sale.unit_id : undefined,
                garage_id :sale.garage_id && !isNaN(sale.garage_id) ? sale.garage_id : undefined,
                voucher_f: voucherFile,  
                bill_f: billFile ,
                unit_price :sale.unit_price ? parseFloat(sale.unit_price) : 0,
                garage_price :sale.garage_price ? parseFloat(sale.garage_price) : 0,
                commission :sale.commission ? parseFloat(sale.commission) : 0,
                commission_percentage :sale.commission_percentage ? parseFloat(sale.commission_percentage) : 0,
                total_usd :sale.total_usd ? parseFloat(sale.total_usd) : 0,
                

            }
            
            if (isEdit) {
                let editData = { ...sendData, sale_id: parseInt(id)}
                updateSale(editData).then(res => {
                    if(res.success){
                        finishOperation(res, form)
                    }else{
                        let msg = res.message ? res.message : 'Ocurrió un error'
                        showAlertMessage('danger', msg)
                    }
                    setLoading(false)
                    
                }).catch(err => {
                    setLoading(false)
                    console.error(err)
                })
            } else {
                registerSale(sendData).then(
                    (response: any) => {
                        setLoading(false)
                        if (response.success) {

                            finishOperation(response, form)

                        } else {
                            let msg = response.message ? response.message : 'Ocurrió un error'
                            showAlertMessage('danger', msg)
                        }
                    }
                );
            }

        } else {
            setLoading(false)
        }

        return
    }

    const resetForm = (form: any) => {
        Object.keys(defaultMessages).forEach(key => {
            try {
                form[key]?.setCustomValidity('')
            } catch (error : any) {
                console.log(error.toString())
            }
            
        })
        setFieldsErrorMessages(defaultMessages);
    }

    const selectVoucherFile = (ev: any) => {
        if (ev.target?.files && ev.target?.files[0]) {
            setVoucherFile(ev.target.files[0])
            setSale(prev => ({...prev,status : 'Cobrada'}))
        } else {
            setVoucherFile(undefined)
        }
    }

    const selectBillFile = (ev: any) => {
        if (ev.target?.files && ev.target?.files[0]) {
            setBillFile(ev.target.files[0])
            if(!voucherFile) setSale(prev => ({...prev,status : 'Facturada'}))
            
        } else {
            setBillFile(undefined)
        }
    }

    const getUserName = () => {
        return sale.user?.real_estate ? `${sale.user.name} - ${sale.user.real_estate}` : sale.user?.name
    }

    return (
        <div className="sale-form">


            <Form noValidate validated={validated}
                onSubmit={handleSubmit} className="login-form w-100">

                <Row className="d-flex flex-row">

                    <Col lg='12' sm='12' className="columna header-form d-flex align-items-center" >
                        <Link to="/admin/sales" className="fs-2 go-back mt-3  ms-3 ms-md-4" title="Volver">
                            <i className="text-light bi bi-arrow-left" ></i>
                        </Link>
                        <h3 className="mx-2">{isEdit ? 'Editar Venta ' : 'Nueva venta'}</h3>
                    </Col>

                    {
                        isEdit && loadingEdit &&
                        <div style={{ height: 400 }} className="w-100 d-flex align-items-center justify-content-center"><Lottie animationData={loaderIris} style={{ height: 100 }} loop /></div>
                    }

                    {
                        (!isEdit || (isEdit && !loadingEdit && saleToEdit?.id)) &&
                        <>
                            <Col lg='12' sm='12' className=" ">
                                <br />
                            </Col>

                            <Col lg='4' sm='12' >
                                <Form.Group controlId="user">
                                    <Form.Label>Usuario</Form.Label>
                                    <SpecialSelect
                                        options={optionsUsers}
                                        onChange={pickUser}
                                        selected={{ value: sale.user_id, label: sale.user?.email }}
                                        loading={loadingUsers}
                                        loadingMessage="Cargando..."
                                        placeholder="Elige un usuario..."
                                        noOptionsMessage={'No se encontraron usuarios'}
                                        setInvalid={invalidUser}

                                    />

                                    {
                                        invalidUser &&
                                        <div className="invalid-feedback d-block">{fieldsErrorMessages.user}</div>
                                    }
                                </Form.Group>
                            </Col>
                            <Col lg='4' sm='12' className=" col-left ">
                                <Form.Group className="w-100 mb-3" controlId="title">
                                    <Form.Label>Nombre - Inmobiliaria *</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="text-input "
                                        value={getUserName()}
                                        disabled

                                        required
                                    />
                                </Form.Group>

                            </Col>


                            <Col lg='4' sm='12' className="d-flex align-items-start">
                                <Form.Group controlId="project" className={`${!showOtherProject ? 'w-100' : ''} `}>
                                    <Form.Label>Proyecto</Form.Label>
                                        <SpecialSelect
                                                options={optionsProjects}
                                                onChange={pickProject}
                                                selected={{ value: sale.project_id, label: sale.project?.title }}
                                                loading={loadingProjects}
                                                loadingMessage="Cargando..."
                                                placeholder="Elige un proyecto..."
                                                noOptionsMessage={'No se encontraron proyectos'}
                                                setInvalid={invalidProject}
                                                
                                        />
                                    
                                    { invalidProject &&
                                        <div className="invalid-feedback d-block">{fieldsErrorMessages.project}</div>
                                    }

                                </Form.Group>

                                {
                                    showOtherProject && 
                                    <Form.Group controlId="project" className="align-items-end w-100" style={{marginLeft: 10}} >
                                        <Form.Label className="text-white">Nombre</Form.Label>
                                        <Form.Control 
                                                className="flex "
                                                type="text"
                                                value={sale.project_name}
                                                onChange={(e : any) => setSale(prev=> ({...prev,project_name : e.target.value}))}
                                                required={showOtherProject}
                                        />
                                        {
                                            (invalidOtherProject) &&
                                            <div className="invalid-feedback d-block mx-2">{fieldsErrorMessages.project}</div>

                                        }

                                    </Form.Group>
                                }
                                
                            </Col>


                            <Col lg='4' sm='12' className=" specific-project mt-3">
                                <Form.Group controlId="unit">
                                    <Form.Label>Unidad</Form.Label>
                                    <div className="d-flex">

                                        <SpecialSelect
                                            options={optionsUnits}
                                            onChange={pickUnit}
                                            selected={{ value: sale.unit_id, label: sale.unit?.number }}
                                            loading={loadingUnits}
                                            loadingMessage="Cargando..."
                                            placeholder="Elige una unidad..."
                                            noOptionsMessage={'No se encontraron unidades'}
                                            setInvalid={invalidUnit}
                                            className={`${!showOtherUnit ? 'w-100' : ''} `}

                                        />

                                        {
                                            showOtherUnit && <Form.Control 
                                            className="flex "
                                            type="text"
                                            value={sale.unit_name}
                                            onChange={(e : any) => setSale(prev=> ({...prev,unit_name : e.target.value}))}
                                            style={{marginLeft: 10}}
                                            required={showOtherUnit}
                                            />
                                        }

                                    </div>
                                    
                                    {
                                        (invalidUnit || invalidOtherUnit) &&
                                        <div className="invalid-feedback d-block">{fieldsErrorMessages.unit}</div>
                                    }

                                </Form.Group>

                            </Col>

                            <Col lg='4' sm='12' className="columna col-left mt-3">
                                <Form.Group controlId="unit_price_currency">
                                    <Form.Label>Moneda de Unidad *</Form.Label>
                                    <SpecialSelect
                                        options={currenciesOptions}
                                        onChange={pickUnitCurrency}
                                        selected={{ value: sale.unit_price_currency, label: sale.unit_price_currency }}
                                        // loading={loadingUnits}
                                        loadingMessage="Cargando..."
                                        placeholder="Elige..."
                                        noOptionsMessage={'No se encontraron monedas'}
                                        setInvalid={invalidUnitCurrency}
                                    />

                                    {invalidUnitCurrency &&
                                        <div className="invalid-feedback d-block">{fieldsErrorMessages.unit_price_currency}</div>
                                    }

                                </Form.Group>

                            </Col>
                            <Col lg='4' sm='12' className="columna col-left mt-3">
                                <Form.Group className="w-100 mb-3" controlId="unit_price">
                                    <Form.Label>Precio de Unidad *</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="text-input "
                                        value={sale.unit_price || ''}
                                        onChange={handleChangeUnitPrice}

                                        required={( project && project.type.identifier != GARAGE_TYPE_IDENTIFIER ) || showOtherUnit}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {fieldsErrorMessages.unit_price}
                                    </Form.Control.Feedback>
                                </Form.Group>

                            </Col>

                            <Col lg='4' sm='12' className="columna specific-project mt-3">
                                <Form.Group controlId="garage">
                                    <Form.Label>Garage</Form.Label>
                                    
                                    <div className="d-flex">
                                        <SpecialSelect
                                            options={optionsGarages}
                                            onChange={pickGarage}
                                            selected={{ value: sale.garage_id, label: sale.garage?.name }}
                                            loading={loadingUnits}
                                            loadingMessage="Cargando..."
                                            placeholder="Elige un garage..."
                                            noOptionsMessage={'No se encontraron garages'}
                                            setInvalid={invalidGarage}
                                            className={`${!showOtherGarage ? 'w-100' : ''} `}

                                        />

                                        {
                                                showOtherGarage && <Form.Control 
                                                className="flex "
                                                type="text"
                                                value={sale.garage_name}
                                                onChange={(e : any) => setSale(prev=> ({...prev,garage_name : e.target.value}))}
                                                style={{marginLeft: 10}}
                                                required={showOtherGarage}
                                                />
                                        }
                                    </div>
                                    

                                    {
                                        (invalidGarage || invalidOtherGarage) &&
                                        <div className="invalid-feedback d-block">{fieldsErrorMessages.garage}</div>
                                    }

                                </Form.Group>

                            </Col>

                            <Col lg='4' sm='12' className="columna col-left mt-3">
                                <Form.Group className="w-100 mb-3" controlId="garage_currency">
                                    <Form.Label>Moneda de Garage </Form.Label>
                                    <SpecialSelect
                                        options={currenciesOptions}
                                        onChange={pickGarageCurrency}
                                        selected={{ value: sale.garage_price_currency, label: sale.garage_price_currency || '' }}
                                        // loading={loadingUnits}
                                        loadingMessage="Cargando..."
                                        placeholder="Elige..."
                                        noOptionsMessage={'No se encontraron monedas'}
                                        setInvalid={invalidGarageCurrency}
                                        disabled={sale.garage_id?.toString()=='Ninguno'}
                                    />

                                    {
                                        invalidGarageCurrency &&
                                        <div className="invalid-feedback d-block">{fieldsErrorMessages.garage_price_currency}</div>
                                    }
                                </Form.Group>

                            </Col>


                            <Col lg='4' sm='12' className="columna col-left mt-3">
                                <Form.Group className="w-100 mb-3" controlId="garage_price">
                                    <Form.Label>Precio de Garage *</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="text-input "
                                        value={sale.garage_price || ''}
                                        onChange={handleChangeGaragePrice}

                                        required={ (project && project.type.identifier === GARAGE_TYPE_IDENTIFIER ) || showOtherGarage}
                                        disabled={sale.garage_id?.toString()=='Ninguno'}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {fieldsErrorMessages.garage_price}
                                    </Form.Control.Feedback>
                                </Form.Group>

                            </Col>

                            <Col lg='4' sm='12' className="columna col-left mt-3">
                                <Form.Group className="w-100 mb-3" controlId="total_usd">
                                    <Form.Label className="d-flex justify-content-between">
                                        <strong>Total USD aprox.</strong> 
                                        <a href="" className="text-primary float-rigth"
                                        style={{textDecoration: 'underline'}}
                                         onClick={calculateUSDTotal} >Calcular</a> </Form.Label>
                                    <Form.Control
                                        as={'input'}
                                        type="text"
                                        className="text-input "
                                        value={sale.total_usd}
                                        onChange={handleChangeTotal}
                                        required
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        {fieldsErrorMessages.total_usd}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                

                            </Col>

                            <Col lg='2' sm='12' className="columna col-left mt-3">
                                <Form.Group className="w-100 mb-3" controlId="commission_percentage">
                                    <Form.Label>Comisión Inmo % *</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="text-input "
                                        value={sale.commission_percentage?.toString()}
                                        onChange={(e) => setSale(prev => { return { ...prev, commission_percentage: e.target.value } })}

                                        required

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {fieldsErrorMessages.commission_percentage}
                                    </Form.Control.Feedback>
                                </Form.Group>

                            </Col>

                            <Col lg='2' sm='12' className="columna col-left mt-3">
                                <Form.Group className="w-100 mb-3" controlId="commission_currency">
                                    <Form.Label>Moneda *</Form.Label>
                                    <SpecialSelect
                                        options={currenciesOptions}
                                        onChange={(e: any) => {
                                            setSale(prev => { return { ...prev, commission_currency: e.value } })
                                        }}
                                        selected={{ value: sale.commission_currency, label: sale.commission_currency || '' }}
                                        loadingMessage="Cargando..."
                                        placeholder="Elige..."
                                        noOptionsMessage={'No se encontraron monedas'}
                                        setInvalid={invalidCommissionCurrency}

                                    />

                                    {
                                        invalidCommissionCurrency &&
                                        <div className="invalid-feedback d-block">{fieldsErrorMessages.project}</div>
                                    }
                                </Form.Group>

                            </Col>

                            <Col lg='4' sm='12' className="columna col-left mt-3">
                                <Form.Group className="w-100 mb-3" controlId="commission">
                                    <Form.Label className="d-flex justify-content-between">Monto de Comisión *
                                        <a href="" className="text-primary float-rigth"
                                        style={{textDecoration: 'underline'}}
                                         onClick={calcCommission} >Calcular</a>
                                    </Form.Label>
                                    <Form.Control
                                        as={'input'}
                                        type="text"
                                        className="number-input "
                                        value={sale.commission}
                                        onChange={(e) => setSale(prev => { return { ...prev, commission: e.target.value} })}

                                        required
                                        onKeyUp={(e: any) => {
                                            setCalculateCommission(false)
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {fieldsErrorMessages.commission}
                                    </Form.Control.Feedback>
                                </Form.Group>

                            </Col>

                            <Col lg='4' sm='12' className="columna col-left mt-3">
                                <Form.Group className="w-100 mb-3" controlId="operation_expenses_percentage">
                                    <Form.Label>% Gastos ocupación y conexión </Form.Label>
                                    <Form.Control
                                        as={'input'}
                                        type="text"
                                        className="number-input "
                                        value={sale.operation_expenses_percentage || ''}
                                        onChange={(e) => setSale(prev => { return { ...prev, operation_expenses_percentage: e.target.value } })}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {fieldsErrorMessages.operation_expenses_percentage}
                                    </Form.Control.Feedback>
                                </Form.Group>

                            </Col>

                            <Col lg='4' sm='12' className="columna col-left mt-3">
                                <Form.Group className="w-100 mb-3" controlId="garage_currency">
                                    <Form.Label>Estado </Form.Label>
                                    <SpecialSelect
                                        options={statusOptions}
                                        onChange={(item : any) => setSale(prev => { return { ...prev, status: item.value } })}
                                        selected={{ value: sale.status, label: sale.status || '' }}
                                        placeholder="Elige..."
                                        noOptionsMessage={'No se encontraron estados'}
                                        setInvalid={invalidStatus}
                                    />

                                    {/* {
                                        invalidStatus &&
                                        <div className="invalid-feedback d-block">{fieldsErrorMessages.status}</div>
                                    } */}
                                </Form.Group>

                            </Col>

                            <Col lg='4' sm='12' className="columna col-left mt-3">
                                <Form.Group className="w-100 mb-3" controlId="sale_date">
                                    <Form.Label>Fecha de Venta </Form.Label>
                                    <Form.Control
                                        as={'input'}
                                        type="date"
                                        value={sale.sale_date || ''}
                                        onChange={(e) => setSale(prev => { return { ...prev, sale_date: e.target.value } })}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {fieldsErrorMessages.sale_date}
                                    </Form.Control.Feedback>

                                </Form.Group>

                            </Col>

                            <Col lg='12'>
                               <hr className="my-3" style={{color : "#a1a1a1"}}/>
                            </Col>


                            <Col lg='6' className="mt-4">

                                <h2 className="mb-3"><strong>Factura:</strong></h2>
                                {
                                    sale.bill_file &&
                                    <>
                                        <a href={sale.bill_file} target="_blank">
                                            <button type="button" className="btn btn-outline-primary mb-2 me-2">Ver Factura</button>
                                        </a>
                                        {sale.bill_date && <span>Actualización : {moment(sale.bill_date).format('DD/MM/YYYY')}</span>}
                                    </>

                                }
                                
                                {
                                    !sale.bill_file &&
                                    <p>Cargar factura:</p>
                                }
                                {
                                    sale.bill_file &&
                                    <p>Actualizar factura:</p>
                                }
                                <Form.Group controlId="formBillFile"  >

                                    <Form.Control type="file" className="mt-2 tw-w-full shadow-none " accept=".jpg,.jpeg,.png,.pdf"
                                        onChange={selectBillFile}
                                    />
                                </Form.Group>
                                {
                                    isEdit && sale.bill_file &&
                                    <Form.Group className="mt-1 mb-3" controlId="delete_bill_file">
                                        <Form.Check  >
                                            <Form.Check.Input type='checkbox' checked={sale.delete_bill_file}
                                                onChange={(e) => { setSale((prev: any) => { return { ...prev, delete_bill_file: e.target.checked } }) }} />
                                            <Form.Check.Label>Eliminar factura </Form.Check.Label>
                                        </Form.Check>
                                    </Form.Group>
                                }

                            </Col>
                            <Col lg='6' className="mt-4">
                                <h2 className="mb-3"><strong>Comprobante de Pago:</strong></h2>
                                {
                                    sale.voucher_file &&
                                    <>
                                        <a href={sale.voucher_file} target="_blank">
                                            <button type="button" className="btn btn-outline-primary mb-2 me-2">Ver Comprobante de Pago</button>
                                        </a>
                                        {sale.voucher_date && <span >Actualización : {moment(sale.voucher_date).format('DD/MM/YYYY')}</span>}
                                    </>

                                }
                                {
                                    !sale.voucher_file &&
                                    <p>Cargar comprobante:</p>
                                }
                                {
                                    sale.voucher_file &&
                                    <p>Actualizar comprobante:</p>
                                }
                                <Form.Group controlId="formFile"  >

                                    <Form.Control type="file" className="mt-2 tw-w-full shadow-none " accept=".jpg,.jpeg,.png,.pdf"
                                        onChange={selectVoucherFile}
                                    />
                                </Form.Group>
                                {
                                    isEdit && sale.voucher_file &&
                                    <Form.Group className="mt-1 mb-3" controlId="delete_voucher_file">
                                        <Form.Check  >
                                            <Form.Check.Input type='checkbox' checked={sale.delete_voucher_file}
                                                onChange={(e) => { setSale((prev: any) => { return { ...prev, delete_voucher_file: e.target.checked } }) }} />
                                            <Form.Check.Label>Eliminar comprobante </Form.Check.Label>
                                        </Form.Check>
                                    </Form.Group>
                                }
                            </Col>



                            <Col lg='12' sm='12' className="columna text-center mt-3">
                                <div style={{ minHeight: 50 }}>
                                    <Alert variant={typeAlert} show={showAlert} onClose={() => setShowAlert(false)} dismissible>
                                        {messageAlert}
                                    </Alert>
                                </div>
                                <div className="d-flex justify-content-center w-100">
                                    <Button
                                        className="mb-2 px-5 create-button mx-1 position-relative"
                                        variant="primary"
                                        type="submit"
                                        disabled={loading}>
                                        GUARDAR
                                        {loading && <Lottie className="position-absolute loader-btn top-1" animationData={GrayLoader} style={{ height: 30, right: 5, top: 2 }} loop />}
                                    </Button>
                                </div>

                            </Col>
                        </>
                    }

                    {
                        (isEdit && !saleToEdit?.user_id && !loadingEdit) &&
                        <h4>No se encontró esta venta</h4>
                    }

                </Row>

            </Form>
        </div>

    )
}
