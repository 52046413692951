export const IconBuilding = ({ width = 18 }) => {
    return (
        <svg
            width={width}
            height={width}
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M21.5278 5.55566H15.2778V22.9168H22.9167V6.94455C22.9167 6.5762 22.7704 6.22293 22.5099 5.96246C22.2495 5.70199 21.8962 5.55566 21.5278 5.55566V5.55566ZM18.0556 17.3612H16.6667V15.9723H18.0556V17.3612ZM18.0556 13.889H16.6667V12.5001H18.0556V13.889ZM18.0556 10.4168H16.6667V9.02789H18.0556V10.4168ZM20.8334 17.3612H19.4445V15.9723H20.8334V17.3612ZM20.8334 13.889H19.4445V12.5001H20.8334V13.889ZM20.8334 10.4168H19.4445V9.02789H20.8334V10.4168Z" fill="currentColor" />
            <path d="M12.4167 2.08301H4.25005C3.8596 2.08301 3.48513 2.23812 3.20904 2.51421C2.93294 2.79031 2.77783 3.16477 2.77783 3.55523V22.9163H6.25005V20.833H10.4167V22.9163H13.8889V3.55523C13.8889 3.16477 13.7338 2.79031 13.4577 2.51421C13.1816 2.23812 12.8072 2.08301 12.4167 2.08301V2.08301ZM6.25005 17.3608H4.86117V15.9719H6.25005V17.3608ZM6.25005 13.8886H4.86117V12.4997H6.25005V13.8886ZM6.25005 10.4163H4.86117V9.02745H6.25005V10.4163ZM6.25005 6.94412H4.86117V5.55523H6.25005V6.94412ZM9.02783 17.3608H7.63894V15.9719H9.02783V17.3608ZM9.02783 13.8886H7.63894V12.4997H9.02783V13.8886ZM9.02783 10.4163H7.63894V9.02745H9.02783V10.4163ZM9.02783 6.94412H7.63894V5.55523H9.02783V6.94412ZM11.8056 17.3608H10.4167V15.9719H11.8056V17.3608ZM11.8056 13.8886H10.4167V12.4997H11.8056V13.8886ZM11.8056 10.4163H10.4167V9.02745H11.8056V10.4163ZM11.8056 6.94412H10.4167V5.55523H11.8056V6.94412Z" fill="currentColor" />
        </svg>
    )
}