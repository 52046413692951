import { PropertyType } from '../../interfaces/property'

type PropertiesAction = { type: 'setProperties'; payload: PropertyType[] }

export const PropertiesReducer = (state: PropertyType[], action: PropertiesAction) => {
  switch (action.type) {
    case 'setProperties':
      return [ ...action.payload ]

    default:
      return state
  }
}
