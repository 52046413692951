import { createContext } from "react";
export type AlertVariants = '' | 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light' 
export type AlertContextProps = {
  typeGlobalAlert: AlertVariants;
  showGlobalAlert: boolean;
  messageGlobalAlert : string;
  positionAlert : string;
  setTypeGlobalAlert : (type:AlertVariants) =>void;
  setShowGlobalAlert : (show:boolean) =>void
  setMessageGlobalAlert : (message:string) =>void
  setPositionAlert : (position : 'top'|'bottom') => void
};

const AlertContext = createContext<AlertContextProps>({} as AlertContextProps);

export default AlertContext;
