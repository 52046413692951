export const IconSafe = ({ width = 20 }) => {
  return (
    <svg width={width} height={width} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M3.0625 1.78638C3.0625 0.91525 7 0.25 7 0.25C7 0.25 10.9375 0.91525 10.9375 1.78638C10.9375 2.94775 10.2213 3.4375 10.2213 3.4375H3.77875C3.77875 3.4375 3.0625 2.94775 3.0625 1.78638ZM7.5625 1.9375C7.5625 2.08668 7.50324 2.22976 7.39775 2.33525C7.29226 2.44074 7.14918 2.5 7 2.5C6.85082 2.5 6.70774 2.44074 6.60225 2.33525C6.49676 2.22976 6.4375 2.08668 6.4375 1.9375C6.4375 1.78832 6.49676 1.64524 6.60225 1.53975C6.70774 1.43426 6.85082 1.375 7 1.375C7.14918 1.375 7.29226 1.43426 7.39775 1.53975C7.50324 1.64524 7.5625 1.78832 7.5625 1.9375Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.93035 4.855C10.4516 4.44813 10.3706 4 10.3706 4H3.6296C3.6296 4 3.54823 4.44813 4.06985 4.855C3.97383 5.29311 3.97726 5.74717 4.07989 6.18378C4.18252 6.62038 4.38174 7.02842 4.66289 7.37787C4.94404 7.72731 5.29996 8.00927 5.70447 8.203C6.10898 8.39673 6.55178 8.49729 7.00029 8.49729C7.44879 8.49729 7.89159 8.39673 8.2961 8.203C8.70061 8.00927 9.05654 7.72731 9.33769 7.37787C9.61884 7.02842 9.81806 6.62038 9.92069 6.18378C10.0233 5.74717 10.0267 5.29311 9.93072 4.855H9.93035ZM7.0001 5.5C8.02048 5.5 8.73372 5.38225 9.2321 5.21275C9.27265 5.52975 9.24521 5.85175 9.1516 6.15731C9.05799 6.46288 8.90036 6.745 8.68921 6.98488C8.47805 7.22477 8.21822 7.41692 7.927 7.54855C7.63578 7.68018 7.31987 7.74826 7.00029 7.74826C6.68071 7.74826 6.36479 7.68018 6.07358 7.54855C5.78236 7.41692 5.52253 7.22477 5.31137 6.98488C5.10022 6.745 4.94259 6.46288 4.84898 6.15731C4.75537 5.85175 4.72792 5.52975 4.76848 5.21275C5.26648 5.38225 5.97973 5.5 7.0001 5.5Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M7.5625 9.25C7.66196 9.25 7.75734 9.28951 7.82767 9.35983C7.89799 9.43016 7.9375 9.52554 7.9375 9.625V10.1432C7.93746 10.2129 7.91804 10.2811 7.88142 10.3403C7.84479 10.3995 7.79241 10.4474 7.73013 10.4785L7.5625 10.5625L7.77887 11.6444L9.36887 9.25937C9.47762 9.25937 9.57888 9.26012 9.67037 9.26087C11.6973 9.72325 13.75 10.666 13.75 12.0842V13.75H0.25V12.0842C0.25 10.666 2.39237 9.71238 4.41925 9.25C4.41213 9.2515 4.48825 9.25263 4.62738 9.25375L6.23388 11.6631L6.4375 10.5625L6.26987 10.4785C6.20759 10.4474 6.15521 10.3995 6.11858 10.3403C6.08196 10.2811 6.06254 10.2129 6.0625 10.1432V9.625C6.0625 9.52554 6.10201 9.43016 6.17233 9.35983C6.24266 9.28951 6.33804 9.25 6.4375 9.25H7.5625ZM11.125 11.2499C11.125 11.2499 10.6251 11.0001 10.375 10.75C10.1249 11.0001 9.625 11.2499 9.625 11.2499C9.625 11.2499 9.88675 12.25 10.375 12.25C10.8632 12.25 11.125 11.2499 11.125 11.2499Z" fill="currentColor" />
    </svg>
  );
};

