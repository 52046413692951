import { useEffect, useState } from "react"
import { Carousel } from "react-bootstrap"
import { GalleryArrow } from "../../GalleryArrow/GalleryArrow";
import { CustomImage } from "../../CustomImage/CustomImage";
import ReactPlayer from "react-player/youtube";
import { getEmbedLinkFromGoogleDriveLink, validateDriveFile, validateYoutubeVideo } from "../../../utils/Functions";

interface GalleryType {
    url: string;
    isVideo: boolean;
    isDriveVideo : boolean
}

export const FeedPostGallery = ({ width, activeIndex, onSelect, images }: { width: string; activeIndex: number; onSelect: (eventKey: number) => void; images: string[]; }) => {
    const [ galleryAssets, setGalleryAssets ] = useState([] as GalleryType[])

    useEffect(() => {
        setGalleryAssets([]);
        images.forEach(image => {
            setGalleryAssets((galleryAssets) => [...galleryAssets, {
                url: image,
                isVideo: validateYoutubeVideo(image),
                isDriveVideo : validateDriveFile(image)
            }])
    });
    }, [images])

    return (
        <Carousel
            className="gallery-carousel"
            defaultActiveIndex={activeIndex}
            interval={null}
            indicators={false}
            onSelect={(eventKey) => onSelect(eventKey)}
            wrap={true}
            nextIcon={images.length != 1 && <GalleryArrow direction="right" size={16} />}
            prevIcon={images.length != 1 && <GalleryArrow direction="left" size={16} />}
        >
            {galleryAssets.map((image, index) => (
                <Carousel.Item key={"gallery_image_" + index}>
                    <div className={`${width} h-100 m-auto`}>
                        {image.isVideo ? (
                            <div className='player-wrapper h-100'>
                                <ReactPlayer
                                    url={image.url}
                                    width={"100%"}
                                    height={"100%"}
                                    loop
                                    muted={true}
                                    controls={false}
                                    config={{
                                        playerVars: {
                                            modestbranding: 0,
                                            rel: 0,
                                            showinfo: 0,
                                            controls: 0
                                        }
                                    }}
                                />
                            </div> 
                        ):image.isDriveVideo ? <iframe src={getEmbedLinkFromGoogleDriveLink(image.url) || ''} width="100%" height="100%" allow="autoplay"></iframe> 
                        : (
                            <CustomImage
                                src={image.url}
                                classes="d-block w-100 fit-contain"
                                alt={"gallery " + index}
                                width={500}
                                height={500}
                            />
                        )}
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
    )
}