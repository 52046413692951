import { Col, Row } from "react-bootstrap";
import { ProjectFinancialType } from "../../interfaces/project";
import { IconBoxArrowUpRight } from "../CustomIcons/IconBoxArrowUpRight";

export const PropertyPayment = ({ financial, isInPdf = false }: { financial: ProjectFinancialType, isInPdf?: boolean }) => {
  return (
    <Row className="d-flex gy-2 ">
      <Col lg={12} className={"d-flex align-items-center justify-content-between " + (!isInPdf ? "mb-1" : "")}>
        <h3 className={"fs-4 " + (isInPdf ? "fw-bold text-secondary mb-2" : "fw-normal m-0")}>Formas de Pago</h3>
        {
          !isInPdf && financial.payment_file && <a className="mx-2 fs-6 d-block text-primary d-flex align-items-center payment_file" href={financial.payment_file} target="_blank">
            <span className="me-1">Ver archivo</span> <IconBoxArrowUpRight width={16} /> 
            </a>
        }
        
      </Col>

      <div className="payment-methods-container" dangerouslySetInnerHTML={{ __html: financial.payment_methods ?? "" }}></div>

      {isInPdf && (
        <Col xs={2}></Col>
      )}
    </Row>
  );
};
