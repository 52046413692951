import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { PropertyAgentCommision } from "../../components/PropertyAgentCommision/PropertyAgentCommision";
import { PropertyBedroom } from "../../components/PropertyBedroom/PropertyBedroom";

import { PropertyMeters } from "../../components/PropertyMeters/PropertyMeters";
import { PropertyPrice } from "../../components/PropertyPrice/PropertyPrice";
import { OperationType, PropertyType } from "../../interfaces/property";
import useBreakpoint from "use-breakpoint";
import { BREAKPOINTS } from "../../utils/Constants";
import { CustomImage } from "../../components/CustomImage/CustomImage";
import useUser from "../../hooks/user/useUser";
import JSZip from "jszip";
import { PropertyGalleryInmo } from "../../components/PropertyGallery/PropertyGalleryInmo";
import { PropertyTypeAndOperation } from "../../components/PropertyTypeAndOperation/PropertyTypeAndOperation";
import { ProjectType } from "../../interfaces/project";

export const TopSectionInmo = ({ property, isInmoDetail, operation }: {
  property: ProjectType,
  isInmoDetail?: boolean, operation?: OperationType
}) => {
  const [map, setMap] = useState(false);
  const [minProjectPrice , setMinProjectPrice] = useState<{currency : string , price : number}>()
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");
  const { userState } = useUser();
  const zip = new JSZip();

  async function getBase64ImageFromUrl(imageUrl: string) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject("error");
      };
      reader.readAsDataURL(blob);
    })
  }

  return (
    <>
      <Row className="d-flex align-items-stretch justify-content-between gx-4 gy-3">
        <Col
          lg={{ order: 1, span: 4 }}
          xs={{ order: 2 }}
          className={"d-flex flex-column align-items-start " + (breakpoint === "xs" ? "mt-5" : "")}
        >
          <div className="d-flex justify-content-between w-100 align-items-start d-lg-block">
            <PropertyTypeAndOperation propertyType={property.type} operation={operation} />

            <div className="fw-normal text-black-50 mt-lg-2">
              {property.type.identifier !== "3" && <PropertyBedroom units={property.units} />}
              <PropertyMeters units={property.units} />
            </div>
          </div>
          <div className="detail-price flex-grow-1 py-lg-4 pt-2 pb-3 d-flex flex-column justify-content-center">
            <PropertyPrice units={property.units} garages={property.garages} isCol propertyTypeId={property.type.identifier} operation={operation}  setMinProjectPrice={setMinProjectPrice}  />
          </div>
          <div className="property-location">
            <p className="property-address mb-0 lh-sm">{property.address}</p>
            <p className="property-hood text-black-50 lh-sm mb-0 mb-lg-3">
              {property.commune ?  `${property.commune.name}, `: ''}
              {(property.zone && `${property.zone.name}, `)} {property.department?.name}
            </p>
          </div>
          <div>
            <p><strong>Entrega :</strong> {property.handover_date_text}</p>
          </div>
        </Col>
        <Col lg={{ order: 1, span: 8 }} xs={{ order: 1 }} className="detail-gallery">
          <div className="w-100 h-100 position-relative border rounded-3">
            <div className="country-flag rounded-1 shadow-sm" style={{ width: "60px", height: "40px" }}>
              <CustomImage src={property.country?.flag} width={50} height={50} />
            </div>
            <PropertyGalleryInmo
              images={property.images}
              infinite
              counter
              expandable
              property={property}
              isInmoDetail={isInmoDetail}
              minProjectPrice={minProjectPrice}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
