import { useEffect, useState } from "react";
import { FormCheck } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import userFilter from "../../../hooks/filter/useFilter";

export const FilterServiceRoom = () => {
  const { setFilter } = userFilter();
  const [searchParams] = useSearchParams();
  const [current, currentSocialHousing] = useState<any>(false);

  useEffect(() => {
    const parameter = searchParams.get("service_room");
    if(!!parameter && parameter === 'true'){
      currentSocialHousing(!!parameter && parameter === "true");
    }else{
      currentSocialHousing(null);
    }
    
  }, [searchParams]);

  return (
    <div className="mb-1 d-flex justify-content-start">
      <FormCheck 
        type="switch"
        size={40}
        checked={current}
        className="position-relative"
        style={{left: "0.5rem"}}
        onChange={()=>setFilter({service_room: !current})}
      />
    </div>
  );
};
