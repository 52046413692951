import { SaleForm } from '../../../fragments/admin/sales/SaleForm'

const AdminCreateSale = () =>{

    return (
        <main className='p-5'>
            <SaleForm type='edit' />
        </main>
    )
}
export default AdminCreateSale