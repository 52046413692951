import { Col, Row } from "react-bootstrap";
import { NeighbourhoodType } from "../../interfaces/property";
import { Zone } from "../../interfaces/locations";

export const NeighbourhoodTendency = ({
  neighbourhood,
}: {
  neighbourhood: Zone;
}) => {

  return (
    <Row className="d-flex mt-4 px-3">
      {(neighbourhood.trends ?? []).map((v, index) => (
        <Col lg={12} className="m-0 p-0 d-flex" key={"tendency_" + index}>
          <Col lg={"auto"} className="text-center">
            <i className={`bi bi-${index === 0 ? "tags" : index === 1 ? "key" : "clock"} fs-3 text-light-secondary`}></i>
          </Col>
          <Col lg={10} className="flex-row flex-lg-column small ms-3 mb-4 mb-lg-4">
            <p className="text-light-secondary m-0">{v.name}</p>
            <p className="text-dark m-0">
              <span className="fw-bold">{v.description}</span>
            </p>
          </Col>
        </Col>
      ))}
    </Row>
  );
};
