

import React, { useEffect } from 'react'

function SearchBar({searchText, styleClass, placeholderText, setSearchText}) {



const updateSearchInput = (value) => {
    setSearchText(value)
}

  return (
    <div className={"tw-inline-block " + styleClass}>
    {/* <div className="tw-input-group  tw-relative tw-flex tw-flex-wrap tw-items-stretch tw-w-full "> */}
      <input type="search" value={searchText} placeholder={placeholderText || "Search"} onChange={(e) => updateSearchInput(e.target.value)} className="tw-input tw-input-sm tw-input-bordered  tw-w-full tw-max-w-xs" />
  {/* </div> */}
</div>
  )
}

export default SearchBar
