import { Col, Form } from "react-bootstrap"
import useConfig from "../../hooks/config/useConfig"


const ReferredSection = ({dataUser, setDataUser } : {dataUser : any, setDataUser : Function}) => {

    const {translation} = useConfig()

    return (<>
        <Col lg='3' sm='12'>
          <Form.Group className="w-30 mb-3" controlId="refer_type">
            <Form.Label>Referido por</Form.Label>
            <Form.Select className="text-input"
              value={dataUser.refer_type}
              onChange={(e) => setDataUser({ ...dataUser, refer_type: e.target.value })}
              required >
              <option value={'Inmobiliaria'}>{translation.referred_inmo}</option>
              <option value={'Iris'}>Agente de Iris</option>
            </Form.Select>
          </Form.Group>
        </Col>
        {
          dataUser.refer_type == 'Inmobiliaria' &&
          <Col lg='3' sm='12'>
            <Form.Group className="w-100 mb-3" controlId="refer_real_estate">
              <Form.Label>{translation.referred_inmo} que refirió</Form.Label>
              <Form.Control
                type="text"
                className="text-input w-100"
                value={dataUser.refer_real_estate}
                onChange={(e) => setDataUser({ ...dataUser, refer_real_estate: e.target.value })}
                required
              />
              <Form.Control.Feedback type="invalid">
                Campo requerido
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        }

        <Col lg='3' sm='12'>
          <Form.Group className="w-100 mb-3" controlId="refer_name">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              className="text-input w-100"
              value={dataUser.refer_name}
              onChange={(e) => setDataUser({ ...dataUser, refer_name: e.target.value })}
              required={dataUser.refer_type == 'Inmobiliaria'}
            />
            <Form.Control.Feedback type="invalid">
              Campo requerido
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        {
          dataUser.refer_type == 'Inmobiliaria' &&
          <Col lg='3' sm='12'>
            <Form.Group className="w-100 mb-3" controlId="refer_phone">
              <Form.Label>Teléfono</Form.Label>
              <Form.Control
                type="text"
                className="text-input w-100"
                value={dataUser.refer_phone}
                onChange={(e) => setDataUser({ ...dataUser, refer_phone: e.target.value })}
              />
            </Form.Group>
          </Col>
        }

      </>)

}

export default ReferredSection