import { Col, Placeholder, Row } from "react-bootstrap"

export const SkeletonPropertyList = () => {
    return (
        <Row className="my-2 loader-list">
            {[...Array(15)].map((item, index) => (
            <Col
                xs="12"
                key={"list_" + index}
            >
                <Row className={"border-bottom mb-3 " + (index === 0 ? "property-header-loader" : "")}>
                    <Col>
                        <Placeholder as="p" animation="glow" className="shadow-list">
                            <Placeholder xs={12} />
                        </Placeholder>
                    </Col>
                    <Col>
                        <Placeholder as="p" animation="glow" className="shadow-list">
                            <Placeholder xs={12} />
                        </Placeholder>
                    </Col>
                    <Col>
                        <Placeholder as="p" animation="glow" className="shadow-list">
                            <Placeholder xs={12} />
                        </Placeholder>
                    </Col>
                    <Col>
                        <Placeholder as="p" animation="glow" className="shadow-list">
                            <Placeholder xs={12} />
                        </Placeholder>
                    </Col>
                    <Col>
                        <Placeholder as="p" animation="glow" className="shadow-list">
                            <Placeholder xs={12} />
                        </Placeholder>
                    </Col>
                    <Col>
                        <Placeholder as="p" animation="glow" className="shadow-list">
                            <Placeholder xs={12} />
                        </Placeholder>
                    </Col>
                    <Col className="shorter">
                        <Placeholder as="p" animation="glow" className="shadow-list">
                            <Placeholder xs={12} />
                        </Placeholder>
                    </Col>
                    <Col className="shorter">
                        <Placeholder as="p" animation="glow" className="shadow-list">
                            <Placeholder xs={12} />
                        </Placeholder>
                    </Col>
                </Row>
            </Col>
            ))}
        </Row>
    )
}