import dayjs from "dayjs";
import { useReducer, useState } from "react";
import { Alert, Button, Dropdown, Form, Spinner } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { sendEmail, sendFormulario } from "../../data/api";
import { useGoogleAnalytics } from "../../hooks/googleAnalytics/useGoogleAnalytics";
import useUser from "../../hooks/user/useUser";
import { UtmArgs } from "../../interfaces/email";
import { OperationType, PropertyType } from "../../interfaces/property";
import useCountry from "../../hooks/config/useConfig";
import { ProjectType } from "../../interfaces/project";
import { UpdateUserBody, UserOfferBody } from "../../interfaces/userActivity";
import {useUserFetch} from "../../hooks/fetch/useUserFetch";

interface SendOfferFormType {
  unit?: string;
  garage?: string;
  message?: string;
}

const defaultSendForm: SendOfferFormType = {
  unit: undefined,
  garage: undefined,
  message: "",
};

type SendOfferFormAction =
  | { type: "set"; payload: SendOfferFormType }
  | {
    type: "change";
    payload: {
      input: "unit" | "garage"  | "message";
      value: string;
    };
  }
  | { type: "reset" };

const reducer = (
  state: SendOfferFormType,
  action: SendOfferFormAction
): SendOfferFormType => {
  if (action.type === "set") return action.payload;
  else if (action.type === "change") {
    let res = { ...state };
    res[action.payload.input] = action.payload.value;
    return res;
  } else return defaultSendForm;
};

export const SendReservation = ({ property, operation }: { property: ProjectType, operation?: OperationType }) => {
  const { userState } = useUser();
  const [searchParams] = useSearchParams();
  const [form, setForm] = useReducer(reducer, defaultSendForm);
  const [send, setSend] = useState<null | "sending" | "error" | "sended">(null);
  const [showError, setShowError] = useState(false);
  const [ unitId, setUnitId] = useState<number>(0);
  const [ garageId, setGarageId] = useState<number>(0);
  const [currentPayment, setCurrentPayment] = useState<string[]>([]);
  const [errorPayment, setErrorPayment] = useState(false);
  const defaultMessage = "Hola, vi esta propiedad en IRIS y tengo un cliente al que puede interesarle. ¿Pueden darme más información? Gracias."
  const GA = useGoogleAnalytics(true);
  const { translation ,country} = useCountry();
  const { registerOffer, updateProfile} = useUserFetch()
  const [userPhone , setUserPhone] = useState('')

  const requirePhone = !userState.phone || userState.phone == '' ? true  : false

  const validate = (): boolean => {
    form.message = (typeof form.message !== "undefined" && !!form.message?.length) ? form.message : defaultMessage

    return (
      typeof form.unit !== "undefined"  && form.unit != '' &&
      form.message?.length > 0 &&
      (!requirePhone || (requirePhone && userPhone.length > 0))
    );
  };

  const onSend = (e: any) => {
    e.preventDefault();

    if (!validate()) {
      setSend("error")
    } else {
      setSend("sending");
      const formFormat: any = form;
      formFormat.form_type = "reservation";
      formFormat['id_project'] = property.identifier;
      formFormat['title_project'] = property.title;
      formFormat['project_country'] = property.country
      formFormat['id_inmo'] = userState.id;
      formFormat['name_inmo'] = `${userState.name} - ${userState.real_estate}`
      formFormat['email_inmo'] = userState.email;
      formFormat['phone_inmo'] = userState.phone || userPhone;
      formFormat['countryCode'] = country

      const idForm = process.env.NODE_ENV == "development" || process.env.NODE_ENV == "test" ? 480 : 924
      const idPais = 1
      const textGarage = formFormat.garage != undefined ? formFormat.garage : "No tiene"
      const extra = `
        Unidad de Interés: ${formFormat.unit}.
        ${translation.garage}: ${textGarage}.
        Mensaje: ${formFormat.message}.
      `
      const formData = {
        IDuser: parseInt(userState.id),
        nombre: userState.name,
        email: userState.email,
        extra,
        fecha: dayjs().format("YYYY-MM-DD HH:mm"),
      }

      const utms: UtmArgs = {
        utm_content: `${property.title}-${property.identifier}`,
        utm_source: searchParams.get("utm_source") != null ? searchParams.get("utm_source") : undefined,
        utm_medium: searchParams.get("utm_medium") != null ? searchParams.get("utm_medium") : undefined,
        utm_campaign: searchParams.get("utm_campaign") != null ? searchParams.get("utm_campaign") : undefined,
      }

      sendEmail(formFormat)
        .then((res: any) => {
          if (res.success) setSend("sended");
          else {
            setSend("error")
            setShowError(true)
          };
        })
        .finally(() => {
          GA.Event({ category: "User send offer", action: "user_send_offer", label: `${property.title}`, value: Number(userState.id) })
        })

      sendFormulario(formData, idForm, idPais, utms)
        .then((res) => console.log(res))
        .catch((err) => console.log(err))

      const offer : UserOfferBody = {
          project_id : property.id,
          project_name : property.title,
          unit_id : unitId,
          garage_id : garageId != 0 ? garageId : undefined,
          payment_method : formFormat.financingMethod,
          message : formFormat.message
          
        }
        registerOffer(offer)

        if(requirePhone){
          updatePhone()
        }

    }
  };

  const updatePhone = () => {

    let userData : UpdateUserBody = {
      ...userState,
      phone : userPhone,
      real_estate : userState.real_estate || '',
      city : userState.city || '',
      primary_color : userState.primary_color || '',
      image : userState.image || '',
      image_file: null,
      delete_image: false,
    }
    updateProfile(userData).then(res => {}).catch(err => {
        console.log(err)
    })
  }

  const setUnitIdValue = (identifier : string) => {
    let unitVal = property.units.find(u => u.identifier.toString() == identifier)
    if(unitVal) setUnitId(unitVal?.id)
    else setUnitId(0)
  } 

  const setGarageIdValue = (identifier : string) => {
    let unitVal = property.garages?.find(u => u.identifier.toString() == identifier)
    if(unitVal) setGarageId(unitVal?.id)
    else setUnitId(0)
  } 

  return (
    <Form
      className="form-send-offer"
      validated={send === "sended" || send === "error"}
    >
      <Form.Group className="mb-1" controlId="formSendOfferUnits">
        <Form.Label className="fw-bold fs-xs mb-1">
          Unidad de Interés
        </Form.Label>
        <Form.Select
          aria-label="Selecciona una unidad"
          required
          value={form.unit}
          className="text-lowercase"
          onChange={(v) => {
            setUnitIdValue(v.target.value)
            setForm({
              type: "change",
              payload: { input: "unit", value: v.target.value },
            })
          }
            
          }
        >
          <option value={""}>Selecciona una unidad</option>
          {property.units?.filter((un) => !operation || un.operation_type == operation)
            .map((u) => (
              <option key={"unit_" + u.id} value={u.identifier}>
                {u.number}
                {
                  u.tipology != 'Local Comercial' &&
                  (
                    " - " + (u.bedrooms > 0
                      ? u.bedrooms > 1
                        ? `${u.bedrooms} ${translation.bedrooms}`
                        : `1 ${translation.single_bedroom}`
                      : translation.studio
                    ))
                }

              </option>
            ))}
        </Form.Select>
        {typeof form.unit === "undefined" && send === "error" && (
          <Form.Text className="fs-xs lh-1 mt-0 text-danger">
            Este campo es requerido
          </Form.Text>
        )}
      </Form.Group>

      {
        (!operation || operation == 'Venta') &&
        <Form.Group className="mb-1" controlId="formSendOfferGarages">
          <Form.Label className="fw-bold fs-xs mb-1">
            {translation.garage}s <span className="fw-light">(opcional)</span>
          </Form.Label>
          <Form.Select
            aria-label="Selecciona una unidad"
            disabled={property.garages?.length === 0}
            value={form.garage}
            onChange={(v) => {
              setGarageIdValue(v.target.value)
              setForm({
                type: "change",
                payload: { input: "garage", value: v.target.value },
              })
            }
              
            }
          >
            <option value={""}>Selecciona un {translation.garage}</option>
            {property.garages?.map((g) => (
              <option key={"garage_" + g.id} value={g.identifier}>
                {g.name} -{" "}
                {`${g.width.toString().replace(".", ",")}m x ${g.depth
                  .toString()
                  .replace(".", ",")}m`}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      }

      {
        requirePhone && 
        <Form.Group className="mb-2" controlId="formSendOfferPhone">
          <Form.Label className="fw-bold fs-xs mb-1">Teléfono</Form.Label>
          <Form.Control
            type="number"
            required={requirePhone}
            value={userPhone}
            className="fs-sm"
            onChange={(v) => {
              setUserPhone(v.target.value);
            }}
          />
          <Form.Text className="fs-xs lh-1 mt-0 ">
                Se registrará como teléfono principal. <br />
          </Form.Text>
          <p></p>
          {(!userPhone  || userPhone == '') &&
            send === "error" && (
              <Form.Text className="fs-xs lh-1 mt-0 text-danger">
                Este campo es requerido
              </Form.Text>
            )}
        </Form.Group>
      }


      <Form.Group className="mb-2" controlId="formSendOfferMessage">
        <Form.Label className="fw-bold fs-xs mb-1">Mensaje</Form.Label>
        <Form.Control
          as="textarea"
          rows={5}
          required
          value={form.message}
          onChange={(v) => {
            setForm({
              type: "change",
              payload: { input: "message", value: v.target.value },
            });
          }}
          placeholder={defaultMessage}
        />
        {(typeof form.message === "undefined" || form.message.length === 0) &&
          send === "error" && (
            <Form.Text className="fs-xs lh-1 mt-0 text-danger">
              Este campo es requerido
            </Form.Text>
          )}
      </Form.Group>

      <Button
        variant={send === "sended" ? "success" : "primary"}
        type="submit"
        className="send-button w-100"
        onClick={onSend}
        disabled={send === "sending" || send === "sended"}
      >
        {send === "sending" ? (
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
            className="me-2"
          />
        ) : null}
        {send === "sending"
          ? "Enviando"
          : send === "sended"
            ? "Reserva enviada"
            : "Reservar"}
      </Button>

      <Alert variant="danger" show={showError}>
        <Button className="alert-btn" onClick={() => setShowError(false)}>
          <i className="bi bi-x"></i>
        </Button>
        <p>
          No pudimos enviar tu mensaje. <br />
          Por favor, intenta nuevamente.
        </p>
      </Alert>
    </Form>
  );
};
