import { createContext } from "react";
import { Filter } from "../../interfaces/filter";

export type FilterContextProps = {
  filterState: Filter;
  setFilter: (filter: Filter) => void;
  removeFilter: () => void;
  setCountryFilterID : ( country_id  : number ) => void
  countryFilterID : number
};

const FilterContext = createContext<FilterContextProps>({} as FilterContextProps);

export default FilterContext;
