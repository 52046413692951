import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
} from "react-bootstrap";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import useUser from "../../hooks/user/useUser";
import useBreakpoint from "use-breakpoint";
import { BREAKPOINTS } from "../../utils/Constants";
import { CustomImage } from "../../components/CustomImage/CustomImage";
import { FeedBenefitsModal } from "../../components/FeedBenefitsModal/FeedBenefitsModal";
import { FeedBenefitsForm } from "../../components/FeedBenefitsModal/FeedBenefitsForm";
import useCountry from "../../hooks/config/useConfig";
import { useFetch } from "../../hooks/fetch/useFetch";
import { IconUser } from "../../components/CustomIcons/IconUser";

export const AdminHeader = () => {
  const { userState, isLoggedIn , isAdmin } = useUser();
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");
  const { pathname } = useLocation();
  const { id } = useParams();
  const [viewedExist, setViewedExist] = useState(false);
  const [showWarranty, setShowWarranty] = useState(false);
  const [showMortgage, setShowMortgage] = useState(false);
  const query = useQuery();
  const navigate = useNavigate()
  const { translation } = useCountry();
  const {logout} = useFetch()

  const userOptions = [
    {
      title: (
        <div >
          <i className="bi bi-lock fs-md me-2 "></i>
          Notificaciones
        </div>
      ),
      action: () => {
        navigate('/admin/notifications')
      } ,
      visible : isAdmin
    },
    {
      title: (
        <div >
          <i className="bi bi-lock fs-md me-2 "></i>
          Ventas
        </div>
      ),
      action: () => {
        navigate('/admin/sales')
        
      } ,
      visible : isAdmin
    },
    {
      title: (
        <div >
          <i className="bi bi-lock fs-md me-2 "></i>
          Tasaciones
        </div>
      ),
      action: () => {
        navigate('/admin/appraisals')
        
      } ,
      visible : isAdmin
    },
    {
      title: (
        <div >
          <i className="bi bi-arrow-right fs-md me-2 "></i>
          Ir a Plataforma Iris
        </div>
      ),
      action: () => {
        navigate('/feed')
        
      } ,
      visible : isAdmin
    },
    {
      title: (
        <>
          <i className="bi bi-box-arrow-left fs-md me-2"></i>
          Salir
        </>
      ),
      action: () => logout(userState),
      visible : true
    },
  ];

  const nameHeader = userState.real_estate? userState.name + ' - ' + userState.real_estate : userState.name

  const titleSession = (
    <span className="text-muted me-2 d-inline-flex align-items-center lh-sm">
      <>
        <img
          alt={userState?.name}
          src={(userState?.image != "" && userState?.image != null) ? userState?.image : "https://cdn1.infocasas.com.uy/web/62bdd922446a2_user-no-image.png"}
          width={50}
          height={50}
          className="rounded-circle me-2 mt-1"
          style={{ width: "35px", height: "35px" ,objectFit:'contain'}}
        />
      </>
      {nameHeader}
      <i className="bi bi-chevron-down ms-2"></i>
    </span>
  )

  const links = [

    {
      title: (
        <span>
          <i className="bi bi-person me-2 fs-5"></i>
          Acceder
        </span>
      ),
      link: "/iniciar-sesion",
      show: !isLoggedIn,
      placement: "right",
      variant: "primary",
    },

    {
      title: titleSession,
      link: "/iniciar-sesion",
      show: isLoggedIn,
      placement: "right",
      dropdown: (
        <NavDropdown
          title={titleSession}
          className="header-dropdown"
          align="end"
        >
          {userOptions.map((d, i) => (
            d.visible &&
            <NavDropdown.Item
              onClick={d.action}
              key={"drop_" + i}
            >
              {d.title}
            </NavDropdown.Item>
          ))}
        </NavDropdown>
      ),
      mobileDropdown: (
        <>
          <Nav.Item className="mb-2 mt-3">{titleSession}</Nav.Item>
          {userOptions.map((d, i) => (
            d.visible &&
            <Nav.Item
              className="ms-4 text-secondary"
              onClick={d.action}
              key={"drop_" + i + "_" + i}
            >
              {d.title}
            </Nav.Item>
          ))}
        </>
      )
    },
  ];

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  return (
    <>
      <Navbar bg="white" fixed="top" className="header border-bottom">
        <Container>
          <Navbar.Brand
            href="/feed"
            className={
              "py-0 " +
              (breakpoint === "xs" || breakpoint === "sm" || breakpoint === "md"
                ? "h-100 d-flex align-items-center gx-3"
                : "")
            }
          >
            {(breakpoint === "xs" ||
              breakpoint === "sm" ||
              breakpoint === "md") &&
              pathname === `/proyecto/${id}` ? (
              <Col className="back-arrow me-3" xs="auto">
                <div
                  onClick={() => window.history.back()}
                  className="fs-2 back"
                  role="button"
                >
                  <i className="text-light bi bi-arrow-left"></i>
                </div>
              </Col>
            ) : (
              ""
            )}
            <CustomImage
              classes="header-logo"
              src="https://cdn1.infocasas.com.uy/web/61eee8ead10a6_infocdn__brand_logo_400x200.png"
              alt="Iris"
              width={50}
              height={50}
            />
          </Navbar.Brand>

          <Navbar.Collapse>
            {links
              .filter((l) => l.placement === "left" && l.show)
              .map(({ link, title, dropdown }, index) => {
                let isActive = false;

                if (typeof dropdown !== "undefined") {
                  return (
                    <div
                      key={"link_desktop_" + index}
                      className="d-none d-lg-block"
                    >
                      {dropdown}
                    </div>
                  )
                } else {
                  return (
                    <Nav.Link
                      key={"link_desktop_" + index}
                      href={link}
                      active={isActive}
                      className={`${isActive ? "" : "text-secondary"
                        } d-none d-lg-block links-hoverable`}

                    >
                      {title}
                    </Nav.Link>
                  )
                }
              })}
            <Nav className="ms-auto d-none d-lg-flex align-items-stretch">
              {links
                .filter((l) => l.placement === "right" && l.show)
                .map((l, index) =>
                  typeof l.dropdown !== "undefined" ? (
                    <div key={index}>
                      {l.dropdown}
                    </div>
                  ) : (
                    <Nav.Item
                      className="me-2"
                      key={"link_desktop_right_" + index}
                    >
                      <Button
                        variant={l.variant || "text"}
                        href={l.link}
                        className="h-100 d-flex align-items-center"
                      >
                        {l.title}
                      </Button>
                    </Nav.Item>
                  )
                )}
            </Nav>
          </Navbar.Collapse>

          <Navbar.Toggle
            aria-controls="offcanvasNavbar"
            className="d-lg-none d-block border-0 ms-auto toggle-button"
          >
            <i className="bi bi-list"></i>
            {viewedExist && <i className="bi bi-circle-fill text-primary notification-dot"></i>}
          </Navbar.Toggle>

          <Navbar.Offcanvas
            id="offcanvasNavbar"
            className="border-right"
            placement="start"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                <CustomImage
                  classes="offcanvas-logo"
                  src="https://cdn1.infocasas.com.uy/web/61eee8ead10a6_infocdn__brand_logo_400x200.png"
                  alt="Iris"
                  width={20}
                  height={20}
                />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                {links
                  .filter((l) => l.placement === "left" && l.show)
                  .map((l, index) => {
                    if (typeof l.mobileDropdown !== "undefined") {
                      return (
                        <React.Fragment key={"link_right_" + index}>
                          {l.mobileDropdown}
                        </React.Fragment>
                      )
                    } else {
                      return (
                        <Nav.Link
                          key={"link_" + index}
                          href={l.link}
                          className="text-secondary"

                        >
                          {l.title}
                        </Nav.Link>
                      )
                    }
                  })}
                {links
                  .filter((l) => l.placement === "right" && l.show)
                  .map((l, index) =>
                    typeof l.mobileDropdown !== "undefined" ? (
                      <React.Fragment key={"link_right_" + index}>
                        {l.mobileDropdown}
                      </React.Fragment>
                    ) : (
                      <Nav.Item key={"link_right_" + index}>
                        <Button
                          variant={l.variant || "text"}
                          href={l.link}
                          className="w-100 mt-2 text-secondary"
                        >
                          {l.title}
                        </Button>
                      </Nav.Item>
                    )
                  )}
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>

      <div className="header-ghost"></div>


    </>
  );
};
