import { OperationType, PropertyTypeType } from "../../interfaces/property";
import useCountry from "../../hooks/config/useConfig";
import { ProjectType, ProjectTypeType } from "../../interfaces/project";

export const PropertyTypeAndOperation = ({
  propertyType,
  operation
}: {
  propertyType: ProjectTypeType;
  operation: OperationType | undefined
}) => {
  const { translation } = useCountry();

  return (
    <>
      <small className="bg-light text-secondary rounded-1 property-type fw-bold px-1 text-uppercase mt-1">
        {propertyType.identifier === "2" ? (translation.apartment) : (propertyType.identifier === "3" ? (translation.lands) : (propertyType.name))}
      </small>
      {
        operation &&
        <>
          <small className="mx-2 text-black border-dark border rounded-1 property-type fw-bold px-1 text-uppercase mt-1">
            {operation}
          </small>
        </>

      }
    </>
  );
};
