import { useState, ReactElement } from "react";
import { MapContainer, Marker, TileLayer, ZoomControl, LayerGroup } from "react-leaflet";
import { PropertyType } from "../../interfaces/property";
import { MarkerGroup } from "../../interfaces/markerGroup";
import { PropertyModal } from "../PropertyModal/PropertyModal";
import ReactDOMServer from 'react-dom/server';
import { ProjectType } from "../../interfaces/project";
const L = require("leaflet");

export const PropertyMap = ({
  latitude,
  longitude,
  zoomControl = false,
  zoomable = false,
  dragable = false,
  expandable = false,
  property,
  group = [],
  icon = <></>,
}: {
  latitude: number;
  longitude: number;
  zoomControl?: boolean;
  zoomable?: boolean;
  dragable?: boolean;
  expandable?: boolean;
  property?: ProjectType;
  group?: MarkerGroup[];
  icon?: ReactElement;
}) => {
  const [expand, setExpand] = useState(false);
  return (
    <>
      <div
        className="w-100 h-100"
        role={"button"}
        onClick={() => setExpand(true)}
      >
        <Map
          latitude={latitude}
          longitude={longitude}
          zoomControl={zoomControl}
          zoomable={zoomable}
          dragable={dragable}
          group={group}
          icon={icon}
        />
      </div>
      {expandable && typeof property !== "undefined" && (
        <PropertyModal
          show={expand}
          onClose={() => setExpand(false)}
          property={property}
          className="property-modal-map"
        >
          <Map
            latitude={latitude}
            longitude={longitude}
            zoomControl={true}
            zoomable={true}
            dragable={true}
            group={group}
            icon={icon}
          />
        </PropertyModal>
      )}
    </>
  );
};

export const Map = ({
  latitude: lat,
  longitude: lng,
  zoomControl = false,
  zoomable = false,
  dragable = false,
  group = [],
  icon = <></>,
}: {
  latitude: number;
  longitude: number;
  zoomControl?: boolean;
  zoomable?: boolean;
  dragable?: boolean;
  group?: MarkerGroup[]
  icon?: ReactElement;
}) => {
  
  return (
    <div className="w-100 h-100 bg-light rounded-3 overflow-hidden">
      <MapContainer
        center={{ lat, lng }}
        zoom={15}
        zoomControl={false}
        scrollWheelZoom={zoomable}
        doubleClickZoom={zoomable}
        dragging={dragable}
        className="w-100 h-100"
      >
        <TileLayer url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png" />
        <Marker position={{ lat, lng }} icon={IconMarker}></Marker>
        {zoomControl && zoomable && <ZoomControl position="topright" />}
        {
          group?.length &&
          <LayerGroup>
            {group.map((loc, index) => {
              const { lat, lng } = loc;
              return <Marker key={"marker_" + index} position={{ lat, lng }} icon={icon ? CustomIcon(icon) : IconMarker}></Marker>
            })}
          </LayerGroup>
        }
      </MapContainer>
    </div >
  );
};

export let DotMarker = new L.divIcon({
  className: "",
  html: `<div class="dot_marker"></div>`,
  iconSize: [24, 24],
  iconAnchor: [12, 12],
});
export let IconMarker = new L.divIcon({
  className: "",
  html: `<div class="icon-marker fs-1 text-primary"><i class="bi bi-geo-alt-fill"></i></div>`,
  iconSize: [40, 47],
  iconAnchor: [20, 47],
});
export let CustomIcon = (icon: ReactElement) =>
  new L.divIcon({
    className: "",
    html: ReactDOMServer.renderToString(icon),
    iconSize: [35, 35],
    iconAnchor: [35, 35],
  })

