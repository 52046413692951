import { Col, Row } from "react-bootstrap";
import { PropertyType } from "../../interfaces/property";
import { PropertyPayment } from "../../components/PropertyFinancial/PropertyPayment";
import { PropertyCommission } from "../../components/PropertyFinancial/PropertyCommission";
import { PropertyExpenses } from "../../components/PropertyFinancial/PropertyExpenses";
import { PropertyBenefits } from "../../components/PropertyFinancial/PropertyBenefits";
import { PropertyFinancial } from "../../components/PropertyFinancial/PropertyFinancial";
import { PropertyCommissionRent } from "../../components/PropertyFinancial/PropertyCommissionRent";
import { ProjectType } from "../../interfaces/project";

export const FinancialSection = ({ property, isInmoDetail, operation }: {
  property: ProjectType,
  isInmoDetail?: boolean
  operation: "Venta" | "Alquiler"
}) => {
  //TODO HAS FINANCIAL
  const hasBenefits = typeof property.financial.benefits != "undefined" && !!property.financial.benefits.length
  const hasFinancial = property.countryFeatures?.financing 
  const hasPropertyPayment = (typeof property.financial.payment_methods != "undefined" && property.financial.payment_methods !== "") || !!property.financial.payment_file 
  const hasPropertyExpenses = typeof property.financial.expenses != "undefined" && !!property.financial.expenses?.length && property.countryFeatures?.expenses_ocupation_connection
  const hasPropertyCommission = property.financial.commission.percent !== 0 || 
  (property.financial.commission.full_value )?true:false 
  const hasRentCommision = property.financial.commision_rent && property.financial.commision_rent !== "" && property.financial.commision_rent >0 ? true : false

  return (
    <div className="overflow-hidden">
      <Row className="financial-section">
        {operation === "Venta" ? (
          hasPropertyCommission && !isInmoDetail && (
            <Col
              lg={5}
              className={
                "py-4 border-top border-bottom pe-lg-4 border-right order-lg-1 " +
                (hasBenefits ? " order-5" : " order-4")
              }
            >
              <PropertyCommission financial={property.financial} propertyCountryFeatures={property.countryFeatures} 
              promos={property.promos} />
            </Col>
          )
        ) : operation === "Alquiler" ? (
          hasRentCommision && !isInmoDetail && (
            <Col
              lg={5}
              className={
                "py-4 border-top border-bottom pe-lg-4 border-right order-lg-1 " +
                (hasBenefits ? " order-5" : " order-4")
              }
            >
              <PropertyCommissionRent commisionRent={property.financial.commision_rent} projectCountryFeatures={property.countryFeatures} />
            </Col>
          )
        ) :
          (
            <>
              {hasPropertyCommission && !isInmoDetail && (
                <Col
                  lg={5}
                  className={
                    "py-4 border-top border-bottom pe-lg-4 border-right order-lg-1 " +
                    (hasBenefits ? " order-5" : " order-4")
                  }
                >
                  <PropertyCommission financial={property.financial} propertyCountryFeatures={property.countryFeatures}
                  promos={property.promos} />
                  {hasRentCommision && !isInmoDetail && (
                    <PropertyCommissionRent commisionRent={property.financial.commision_rent} projectCountryFeatures={property.countryFeatures} />
                  )}
                </Col>
              )}
            </>
          )}

        {hasPropertyPayment &&
          <Col
            lg={7}
            className={'py-4 border-bottom ps-lg-4 order-lg-2 order-1 border-top '}
          >
            <PropertyPayment financial={property.financial} />
          </Col>
        }
        {operation == 'Venta' && hasPropertyExpenses &&
          <Col lg={5} className={"py-4 pe-lg-4 order-lg-3 order-2 " +
            (hasFinancial || hasBenefits ? "border-right" : "") + (isInmoDetail ? ' border' : '')}>
            <PropertyExpenses financial={property.financial} />
          </Col>
        }
        {hasFinancial &&
          <Col lg={hasBenefits ? 12 : 7} className={"py-4 order-5 order-lg-5 " + (hasBenefits ? "border-top " : "")}>
            <PropertyFinancial financial={property.financial} propertyCountryFeatures={property.countryFeatures} />
          </Col>
        }
        {hasBenefits &&
          <Col lg={7} className={"ps-lg-4 py-4 order-lg-4 order-3 "}>
            <PropertyBenefits financial={property.financial} />
          </Col>
        }
      </Row>
    </div>
  );
};
