import { useState, createRef ,useEffect} from "react";
import { Button, Form, Alert } from "react-bootstrap";
import { useNavigate ,useSearchParams} from "react-router-dom";
import { validateChangePasswordFields, changePassword} from "../../data/api";
import { BasicResponseType, LoginType } from "../../interfaces/user";
import useAlert from "../../hooks/alert/useAlert";


export const ChangePasswordForm = () => {
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [credentials, setCredentials] = useState({
    password: "",
    repeat_password : "",
    showPassword: false,
    showRepeatPassword : false
  });
  
  const defaultPasswordMessage = "Escriba una contraseña válida"
  const [passwordMessage, setPasswordMessage] = useState(defaultPasswordMessage);
  const [repeatPasswordMessage, setRepeatPasswordMessage] = useState(defaultPasswordMessage);
  const [showAlert, setShowAlert] = useState(false);
  const [typeAlert,setTypeAlert] = useState('success');
  const [messageAlert, setMessageAlert] = useState("");
  
  const { setTypeGlobalAlert, setMessageGlobalAlert, setShowGlobalAlert } = useAlert();
  const [searchParams, setSearchParams] = useSearchParams();

  const [finishedChange , setFinishedChange] = useState(false)
  const skipInputRef : React.RefObject<HTMLButtonElement> = createRef();
  const skipInputRefRepeat : React.RefObject<HTMLButtonElement> = createRef();
  useEffect(() => {
    if(skipInputRef.current) skipInputRef.current.tabIndex = -1;
    if(skipInputRefRepeat.current) skipInputRefRepeat.current.tabIndex = -1;
  }, []);

  const validateForm = () => {
    return credentials.password.length > 0 && credentials.repeat_password.length > 0;
  };

  const onKeyUp = (event: any) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };


  const handleSubmit = (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;
    form.repeat_password.setCustomValidity('')
    form.password.setCustomValidity('')
    setRepeatPasswordMessage(defaultPasswordMessage)
    setPasswordMessage(defaultPasswordMessage)
    setValidated(true);
    
    if(form.checkValidity() !== false){
      validateChangePasswordFields(credentials.password, credentials.repeat_password).then(
        (response: LoginType) => {
          
          if (response.status === "error") {
            if (response.field === 'password') {
              setPasswordMessage(response.message ?? "");
              form.password.setCustomValidity('invalid')
            }
            if (response.field === 'repeat_password') {
              setRepeatPasswordMessage(response.message ?? "");
              form.repeat_password.setCustomValidity('invalid')
            }

            return
  
          } else{

            
            const url_code = searchParams.get("code") || ''
            const user_id : number = parseInt(searchParams.get("id")||'0') 

            changePassword(credentials.password, credentials.repeat_password,url_code,user_id).then(
              async (response : BasicResponseType)=>{
              if(response.success){
                //Se logueó               
                showAlertMessage('success',response.message)
                setTimeout(() => {
                  setFinishedChange(true)
                }, 2000);
                
                
              }else if(response.message){
                showAlertMessage('danger',response.message)
              }else{
                showAlertMessage('danger', 'Ocurrió un error')
              }
              
            }).catch(err =>{
              console.log(err)
              showAlertMessage('danger', 'Ocurrió un error')
            })
          }
        }
      );
    }
    
  };

  const goLogin = ()=>{
    navigate('/iniciar-sesion')
  }
  const showAlertMessage = (type : string,message : string) => {
    setTypeAlert(type)
    setMessageAlert(message)
    setShowAlert(true)
    
  }

  return (
    <>
      
      {! finishedChange && 
      <div>
      <h2 className="fw-bold mb-3">Cambiar contraseña</h2>
      <Form noValidate validated={validated} onSubmit={handleSubmit} className="login-form w-100">
        
        
        <Form.Group
          className="w-100 mb-3 position-relative"
          controlId="password"
        >
          <Form.Label>Contraseña</Form.Label>
          <Form.Control
            value={credentials.password}
            onChange={(e) =>
              setCredentials({ ...credentials, password: e.target.value })
            }
            onKeyUp={onKeyUp}
            placeholder="********"
            className="password-input"
            type={credentials.showPassword ? "text" : "password"}
            required
            autoComplete="new-password"
            minLength={1}
          />
          <button
            type="button"
            ref={skipInputRef}
            className="login-icon-password float-end"
            onClick={(e) =>
              setCredentials({
                ...credentials,
                showPassword: !credentials.showPassword,
              })
            }
          >
            <i
              className={`"bi" ${credentials.showPassword ? "bi-eye-slash" : "bi-eye"
                }`}
            ></i>
          </button>
          <Form.Control.Feedback type="invalid">
            {passwordMessage}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group
          className="w-100 mb-3 position-relative"
          controlId="repeat_password"
        >
          <Form.Label>Repetir Contraseña</Form.Label>
          <Form.Control
            value={credentials.repeat_password}
            onChange={(e) =>
              setCredentials({ ...credentials, repeat_password: e.target.value })
            }
            onKeyUp={onKeyUp}
            placeholder="********"
            className="password-input"
            type={credentials.showRepeatPassword ? "text" : "password"}
            required
            minLength={1}
            autoComplete="new-password"
          />
          <button
            type="button"
            ref={skipInputRefRepeat}
            className="login-icon-password float-end"
            onClick={(e) =>
              setCredentials({
                ...credentials,
                showRepeatPassword: !credentials.showRepeatPassword,
              })
            }
          >
            <i
              className={`"bi" ${credentials.showRepeatPassword ? "bi-eye-slash" : "bi-eye"
                }`}
            ></i>
          </button>
          <Form.Control.Feedback type="invalid">
            {repeatPasswordMessage}
          </Form.Control.Feedback>
        </Form.Group>
        
        
        <Button
          className="mb-2 w-100 login-button"
          variant="primary"
          type="submit"
          disabled={!validateForm()}
        >
          Cambiar contraseña
        </Button>
        
        <Alert variant={typeAlert}  show={showAlert} onClose={() => setShowAlert(false)} dismissible>
        {messageAlert}
      </Alert>
      </Form>
      </div>
      }
      {
        finishedChange && 
        <div>
          <h2 className="fw-bold mb-3">Contraseña actualizada</h2>
          <p>Ahora puede iniciar sesión</p>
          <Button
          className="mb-2 w-100 login-button"
          variant="primary"
          type="button"
          onClick={goLogin}
        >
          Ir a iniciar sesión
        </Button>
        </div>
      }

    </>
  );
};
