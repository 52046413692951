import { ValidateDataRequest } from "../../interfaces/appraisal"
import { StepOne } from "./StepOne"
import { StepThree } from "./StepThree"
import { StepTwo } from "./StepTwo"

export const StepsContent = (
    { data , currentStep  , setData , setCurrentStep,setCompletedStep } : 
    { data ? : ValidateDataRequest , currentStep : number , setData : Function, setCurrentStep : Function , setCompletedStep : Function}) => {
    

    return (
        <>
            {
                currentStep == 1 && 
                <StepOne 
                    setData={setData} 
                    setCurrentStep={setCurrentStep}
                    data={data}
                     />
            }

            {
                currentStep == 2 && 
                <StepTwo 
                    setData={setData} 
                    setCurrentStep={setCurrentStep}
                    data={data}
                />
            }

            {
                currentStep == 3 && 
                <StepThree setCurrentStep={setCurrentStep} />
            }

            {
                currentStep == 4 && 
                <div>
                    CUARTO PASO
                </div>
            }


        </>
    )

}