
import { IconArrowRight } from "../../components/CustomIcons/IconArrowRight"
import "./appraisal.scss"
import useConfig from '../../hooks/config/useConfig'
import Lottie from "lottie-react";
import lottieUserDoc from "../../lotties/document-user-orange.json";
import lottieApproved from "../../lotties/approved-checked-orange.json";
import lottieMoneyBag from "../../lotties/money-bag-orange.json";
import lottieMortgageLoan from "../../lotties/mortgage-loan-orange.json";
import { Link } from "react-router-dom";

export const AppraisalMain = () => {
    
    const {translation} = useConfig()

    return (
        <main className="appraisals main container p-3 p-sm-5 mh-100 text-center">
            
            <h2>SOLICITAR TASACIÓN</h2>

            <div className="content-empty pt-5 p-3 mt-5" >
                <div className="text-center top-benefit">
                    <h4 className="mb-3">¡Conoce el valor de tu propiedad en solo minutos! </h4>
                    <p>Ahora puedes obtener <strong>tasaciones en línea</strong> precisas y confiables en nuestro sitio.</p>
                    <p>Completa la <strong>información</strong> que te solicitaremos y recibiras un <strong>informe detallado</strong> al instante.</p>
                    <p className="mt-3">¡Comienza hoy mismo a aprovechar esta herramienta con un <strong>precio especial</strong> para Iris!</p>
                    {/* <p className="mb-3" dangerouslySetInnerHTML={{__html : translation.warranty_description} }></p> */}
                </div>

                <div className="d-flex align-items-center warranty-steps mt-5">
                    <div>
                        {/* <Lottie animationData={lottieMortgageLoan} style={{ height: 100 }}  /> */}
                        <p>1. Elige la empresa tasadora</p>

                    </div>
                    <div className="arrow-icon">
                        <IconArrowRight width={30} />
                    </div>
                    
                    <div>
                        {/* <Lottie id='user-doc' animationData={lottieUserDoc} style={{ height: 100 }} /> */}
                        
                        <p>2. Ingresa los datos solicitados</p>
                    </div>
                    <div className="arrow-icon">
                        <IconArrowRight width={30} />
                    </div>
                    <div >
                        {/* <Lottie animationData={lottieMoneyBag} style={{ height: 100 }}/> */}
                        <p className="position-relative">3. Realiza el pago y envíanos el comprobante</p>
                        
                    </div>
                    <div className="arrow-icon">
                        <IconArrowRight width={30} />
                    </div>

                    <div>
                        {/* <Lottie animationData={lottieApproved} style={{ height: 100 }}  /> */}
                        <p className="position-relative">4. Recibirás el informe en tu correo</p>                        
                        
                    </div>
                </div>

                <div className="text-center my-3 mt-5 btn-open">
                    <Link to={'/solicitar-tasacion'} >
                        <button className="btn btn-primary " >Solicitar</button>
                    </Link>
                </div>
            </div>

        </main>
    )
}