import React , { Suspense } from 'react';
import { Navigate, Outlet } from "react-router-dom";
import useUser from "../hooks/user/useUser";

import SuspenseContent from '../fragments/user/layout/SuspenseContent';

import LeftSidebar from '../fragments/user/layout/LeftSidebar';

import links from "../routes/user/routes"

const UserRoute = () => {
    const {userState,isLoggedIn} = useUser()
    const auth = userState && userState.id && isLoggedIn

    if(!auth){
        return <Navigate to='/iniciar-sesion'/>
    }

    return (
        <Suspense fallback={<SuspenseContent />}>
            <>
                    { /* Left drawer - containing page content and side bar (always open) */ }
                    <div className="tw-drawer tw-drawer-mobile" style={{height : 'auto'}}>
                        <input id="left-sidebar-drawer" type="checkbox" className="tw-drawer-toggle" />
                        
                        {/* Page Content */}
                        <div className="tw-drawer-content tw-flex tw-flex-col ">
                            <main className="tw-flex-1 tw-overflow-y-auto tw-pt-8 tw-px-4 md:tw-px-6 tw-pb-4 tw-bg-base-200"  >
                            <Outlet/>
                            </main>
                        </div>
                        
                        <LeftSidebar />
                    </div>

                </>
        </Suspense>
    )
}

export default UserRoute;