export interface GetImagenOptimizadaProps {
    src: string;
    width?: number;
    height?: number;
    resize?: string;
}

export const useImage = ({ src, width, height, resize }: GetImagenOptimizadaProps) => {
    let d, resizes = "";
    let res = String(src);
    res = res.replace("cdn1.", "cdn2.");

    if (width && height) {
        d = Math.round(width) + "x" + Math.round(height);
        resizes = resize ? resize : "outside";

        if (hasImgExtension(res)) {
            let segments = res.split("/");
            segments[segments.length - 1] = `th.${resizes}${d}.`.concat(
                segments[segments.length - 1]
            );
            res = segments.join("/");
        }
    }

    return res;
};

const hasImgExtension = (src: string): boolean => /\.(jpg|png|JPG|PNG|JPEG|jpeg)/g.test(src);