import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import userFilter from "../../../hooks/filter/useFilter";
import { useSearchParams } from "react-router-dom";
import { uniqueFromArray, excludeFromArrayNumber } from "../../../utils/Functions";
import { DeveloperType, ProjectTypeType } from "../../../interfaces/project";
import { getPropertyTypes } from "../../../data/api";
import "./FilterDeveloper.scss"

export const FilterDeveloper = ({developersList}:{developersList:DeveloperType[]}) => {
  const propertyTypes = getPropertyTypes();
  const { setFilter } = userFilter();
  
  const [searchParams] = useSearchParams();
  const [currentDeveloper, setCurrentDeveloper] = useState(
    [] as number[]
  );

  useEffect(() => {
    const parameter = searchParams.get("developers");
    setCurrentDeveloper(
      !!parameter
        ? developersList.filter((t) => parameter.split(",").includes(t.id.toString())).map(d => d.id)
        : ([] as number[])
    );
  }, [searchParams]);
  
  return (
    <Form className="filter-developer">
      {developersList?.map((developer) => (
        <div key={`filter-${developer.name}`} className="mb-1">
          <label className="d-inline-flex w-100">
            <Form.Check
              className="me-2"
              type="checkbox"
              value={developer.id}
              checked={currentDeveloper.includes(developer.id) ?? false}
              onChange={(e) => {
                const current = currentDeveloper ?? [];
                setFilter({
                  developers: e.target.checked
                    ? (uniqueFromArray([
                      ...current,
                      developer.id,
                    ]) as unknown as number[])
                    : (excludeFromArrayNumber(current, developer.id) as unknown as number[]),
                });
              }}
            />
            {developer.name}
          </label>
        </div>
      ))}
    </Form>
  );
};
