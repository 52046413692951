import { useEffect, useState } from "react";
import { Alert, Button, Form, Row, Col } from "react-bootstrap";
import { NotificationsType } from "../../../interfaces/notifications";
import { useFetch } from "../../../hooks/fetch/useFetch";
import { Link } from "react-router-dom";
import SpecialSelect from "../../../components/ui/SpecialSelect";
import { ProjectType } from "../../../interfaces/project";

export const NotificationForm = () => {

    const countries = [
        { value: 'UY', label: 'Uruguay' },
        { value: 'PY', label: 'Paraguay' },
        { value: 'CL', label: 'Chile' },
        { value: 'CO', label: 'Colombia' },
        { value: 'PA', label: 'Panamá' },
        // { value: 'BO', label: 'Bolivia' },
        // { value: 'AR', label: 'Argentina' }

    ]

    const defaultNotification = {
        title: '',
        message: '',
        link: '',
        image: '',
        country: '',

    }
    const [optionsProjects, setOptionsProjects] = useState<{ value: any, label: string }[]>([])
    const [projects, setProjects] = useState<ProjectType[]>([])
    const { getAllProperties, sendPushNotification } = useFetch()
    const [selectedProject, setSelectedProject] = useState<any>(null);
    const [selectedCountries, setSelectedCountries] = useState<any>([])
    const [loading, setLoading] = useState(false)
    const [loadingProjects, setLoadingProjects] = useState(false)

    const [validated, setValidated] = useState(false);
    const [isProject, setIsProject] = useState<any>(false)
    const [notification, setNotification] = useState<NotificationsType>(defaultNotification)
    const [showAlert, setShowAlert] = useState(false);
    const [typeAlert, setTypeAlert] = useState('success');
    const [messageAlert, setMessageAlert] = useState("");
    const [invalidProject, setInvalidProject] = useState(false);


    const defaultMessages = {
        title: 'El titulo es requerido',
        message: 'El mensaje es requerido',
        image: 'La imagen debe ser una URL válida',
        country: '',
        link: 'El link debe ser una URL válida',
        project: 'Elige un proyecto'
    }
    const [fieldsErrorMessages, setFieldsErrorMessages] = useState(defaultMessages)

    useEffect(() => {
        let allProjects: any[] = []
        setLoadingProjects(true)
        getAllProperties('sale').then((props) => {
            setLoadingProjects(false)
            if (props.success) {
                console.log("props ",props)
                allProjects = props.data.map(project => {
                    return {
                        value: project.id,
                        label: project.title
                    }
                })
                setProjects(props.data)
                setOptionsProjects(allProjects)
            } else {
                showAlertMessage('danger', 'Ocurrió un error al listar los proyectos')
            }

        })

    }, [])

    const changeIsProject = (is_project:any) => {
        setIsProject(is_project)
        if (is_project == 0) {
            setNotification({
                ...notification,
                image: '',
                title: '',
                link: ''
            })
        } else {
            setSelectedProject(null)
        }
    }

    const pickProject = (project: { value: string, label: string }) => {
        
        setSelectedProject(project)
        setInvalidProject(false)
        if (projects ) {
            let fullProject = projects.find(p => p.id.toString() == project.value)
            if(fullProject){
                setNotification({
                    ...notification,
                    image: fullProject.images[0] ? fullProject.images[0] : undefined,
                    title: fullProject.title,
                    link: 'https://iris.infocasas.com.uy/proyecto/' + fullProject.identifier
                })
            }
            
        }

    }

    const changeCountries = (countries: any) => {
        setSelectedCountries(countries)
        if (countries && countries.length > 0) {
            let countriesString = countries.map((c: any) => c.value).join(',')
            setNotification({
                ...notification,
                country: countriesString
            })
        } else {
            setNotification({
                ...notification,
                country: ''
            })
        }
    }

    const showAlertMessage = (type: string, message: string) => {
        setTypeAlert(type)
        setMessageAlert(message)
        setShowAlert(true)

    }

    const handleSubmit = (event: any) => {
        event.preventDefault()

        setLoading(true)
        setShowAlert(false)
        setMessageAlert('')
        setTypeAlert('')

        let form = event.currentTarget;
        resetForm(form)
        setValidated(true);
        let validForm = form.checkValidity()


        if (validForm) {
            let sendData = { ...notification }
            //Todos los países
            if(!sendData.country || sendData.country == ''){
                sendData.country = Object.values(countries).map(c=>c.value).join(',')
            }

            sendPushNotification(sendData).then(
                (response: any) => {
                    setLoading(false)
                    if (response.success) {
                        
                        showAlertMessage('success', response.message)
                        setNotification(defaultNotification)
                        
                        setValidated(false);
                        
                        setSelectedProject(null)
                        setSelectedCountries(null)
                        resetForm(form)
                        //form.reset()
                        setTimeout(() => {
                            setShowAlert(false)
                            setMessageAlert('')
                        }, 4000);

                    } else {
                        let msg = response.message?response.message:'Ocurrió un error'
                        showAlertMessage('danger', msg)
                    }
                }
            );
        } else {

            setLoading(false)
        }

        return
    }

    const resetForm = (form:any)=> {
        Object.keys(defaultMessages).forEach(key => {
            form[key]?.setCustomValidity('')
        })
        setFieldsErrorMessages(defaultMessages);
    }

    const customStyles = {
        control: (base: any, state: any) => ({
            ...base,
            // state.isFocused can display different borderColor if you need it
            borderColor: invalidProject ?
                'red' : '#ddd'
        })
    }

    return (
        <div className="notification-form">


            <Form noValidate validated={validated}
                onSubmit={handleSubmit} className="login-form w-100">

                <Row className="d-flex flex-row">

                    <Col lg='12' sm='12' className="columna header-form ">
                        <Link to="/admin/notifications" className="fs-2 go-back mt-3 ms-3 ms-md-4" title="Volver">
                            <i className="text-light bi bi-arrow-left" ></i>
                        </Link>
                        <h3>Nueva notificación</h3>
                    </Col>

                    <Col lg='12' sm='12' className="columna ">                        
                        <br />                        
                    </Col>
                    
                    <Col lg='12' sm='12' className="columna specific-project">
                        <div className="d-flex flex-row align-items-center w-50 mb-2" style={{minHeight :40}}>
                            <Form.Check 
                                type='checkbox'
                                id={`is_project`}
                                label={`Es proyecto específico`}
                                onChange={(e)=>{changeIsProject(e.target.checked)}}
                                checked={isProject == true}
                            />
                            {
                                isProject &&
                                <Form.Group style={{marginRight:'2rem',marginLeft:'10px',flex:1}} controlId="project">
                                    {/* <Form.Label>Proyecto</Form.Label> */}
                                    {/* <Select
                                        placeholder='Elige un proyecto'
                                        value={selectedProject}
                                        onChange={pickProject}
                                        options={optionsProjects}
                                        styles={customStyles}
                                        isLoading={loadingProjects}
                                        noOptionsMessage={() => 'No se encontraron proyectos'}
                                        loadingMessage={()=>'Cargando...'}
                                        
                                    /> */}
                                    <SpecialSelect 
                                        options={optionsProjects} 
                                        onChange={pickProject} 
                                        selected={selectedProject}
                                        loading={loadingProjects}
                                        loadingMessage="Cargando..."
                                        placeholder="Elige un proyecto..."
                                        noOptionsMessage={'No se encontraron proyectos'}
                                        // setInvalid={invalidProject}
                                        
                                    />

                                    {
                                        invalidProject &&
                                        <div className="invalid-feedback d-block">{fieldsErrorMessages.project}</div>

                                    }

                                </Form.Group>
                            }
                        </div>
                        
                    </Col>

                    <Col lg='6' sm='12' className="columna col-left">
                      

                        <Form.Group className="w-100 mb-3" controlId="title">
                            <Form.Label>Título *</Form.Label>
                            <Form.Control
                                type="text"
                                className="text-input "
                                value={notification.title}
                                onChange={(e) => setNotification({ ...notification, title: e.target.value })}
                                autoFocus
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                {fieldsErrorMessages.title}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="w-100 mb-3" controlId="message">
                            <Form.Label>Mensaje *</Form.Label>
                            <Form.Control
                                as="textarea"
                                className="text-input "
                                value={notification.message}
                                onChange={(e) => setNotification({ ...notification, message: e.target.value })}
                                rows={3}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                {fieldsErrorMessages.message}
                            </Form.Control.Feedback>
                        </Form.Group>

                    </Col>

                    <Col lg='6' sm='12' className="columna">


                        <Form.Group className="w-100 mb-3" controlId="link">
                            <Form.Label>Link</Form.Label>
                            <Form.Control
                                type="url"
                                className="text-input "
                                value={notification.link}
                                onChange={(e) => setNotification({ ...notification, link: e.target.value })}

                            />
                            <Form.Control.Feedback type="invalid">
                                {fieldsErrorMessages.link}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="w-100 mb-3" controlId="image">
                            <Form.Label>Imagen</Form.Label>
                            <Form.Control
                                type="url"
                                className="text-input "
                                value={notification.image}
                                onChange={(e) => setNotification({ ...notification, image: e.target.value })}

                            />
                            <Form.Control.Feedback type="invalid">
                                {fieldsErrorMessages.image}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="w-100 mb-3" controlId="country">
                            <Form.Label>Países</Form.Label>
                            {/* <Select
                                placeholder='Elige...'
                                value={selectedCountries}
                                onChange={changeCountries}
                                options={countries}
                                isMulti={true}
                            /> */}
                            <SpecialSelect 
                                placeholder='Elige...'
                                options={countries} 
                                onChange={changeCountries} 
                                selected={selectedCountries}
                                isMulti={true}
                            />
                        </Form.Group>

                    </Col>

                    <Col lg='12' sm='12' className="columna text-center">
                        <div style={{ minHeight: 50 }}>
                            <Alert variant={typeAlert} show={showAlert} onClose={() => setShowAlert(false)} dismissible>
                                {messageAlert}
                            </Alert>
                        </div>
                        <div className="d-flex justify-content-center w-100">
                            {/* <Link to={'/admin/notifications'}>
                                <Button
                                    className="mb-2 px-5 create-button mx-1"
                                    variant="outline-secondary"
                                    type="button">
                                    VOLVER
                                </Button>
                            </Link> */}
                            <Button
                                className="mb-2 px-5 create-button mx-1 "
                                variant="primary"
                                type="submit"
                                disabled={loading == true}>
                                ENVIAR
                            </Button>
                        </div>
                        
                    </Col>


                </Row>

            </Form>
        </div>

    )
}
