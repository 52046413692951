import { Col, Container, Row } from "react-bootstrap";
import { Sidebar } from "./../../components/Sidebar/Sidebar";
import { FilterCountry } from "../../components/Filters/FilterCountry/FilterCountry";
import { FilterEstate } from "../../components/Filters/FilterEstate/FilterEstate";
import { TogglePropertyList } from "../../components/TogglePropertyList/TogglePropertyList";
import { Sorter } from "../../components/Sorter/Sorter";
import useBreakpoint from "use-breakpoint";
import { BREAKPOINTS } from "../../utils/Constants";
import { FilterSearch } from "../../components/Filters/FilterSearch/FilterSearch";
import { IconFilter } from "../../components/CustomIcons/IconFilter";
import { useDevelopers } from "../../hooks/project/useDevelopers";
import { useProjects } from "../../hooks/projects/useProjects";

export const ListHeader = ({ total, open, setOpen, listType, setListType, operationType, setOperationType }: { total: number; open: boolean; setOpen: Function; listType: "grid" | "list", setListType: Function, operationType: "sale" | "rent", setOperationType: Function }) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");
  const { projectStatusList , projectTypesList} = useProjects()

  const { developers } = useDevelopers()


  return (
    <>
      <Sidebar open={open} setOpen={setOpen} operationType={operationType} 
      projectStatusList={projectStatusList} projectTypesList={projectTypesList}
      developersList={developers} />
      <div className="filter-header w-100 bg-white border-bottom py-2">
        <Container className={"h-100 " + (breakpoint === "xs" ? "px-4" : "")}>
          <Row className="h-100 d-flex align-items-center gx-3 position-relative">
            <Col
              xs={{ order: 3, span: "auto" }}
              lg={{ order: 0 }}
              className="flex-grow-1 flex-lg-grow-0 d-flex justify-content-end"
            >
              <div
                onClick={() => setOpen(!open)}
                role="button"
                className="d-flex align-items-center"
              >
                <IconFilter width={20} height={20} className="text-primary" />

                <span
                  className="text-button mx-2 d-none d-lg-inline"
                  children="Filtrar"
                />
              </div>
            </Col>
            <Col xs={{ order: 4, span: "auto" }} lg={{ order: 0 }}>
              <Sorter />
            </Col>

            <Col
              xs={{ span: 12, order: 5 }}
              sm={{ span: 12, order: 5 }}
              md={{ span: 12, order: 5 }}
              lg={{ span: "auto", order: 0 }}
              xl={{ span: "auto", order: 0 }}
              xxl={{ span: "auto", order: 0 }}
              className="flex-lg-grow-1 d-flex justify-content-end"
            >
              <FilterSearch source="header" />
            </Col>

            <Col
              xs={{ order: 1, span: "auto" }}
              lg={{ order: 0 }}
            >
              <FilterCountry />
            </Col>
            <Col
              xs={{ order: 2, span: "auto" }}
              lg={{ order: 0 }}
              className="flex-shrink-1"
            >
              <FilterEstate />
            </Col>
            {(breakpoint !== "xs" && breakpoint !== "sm" && breakpoint !== "md" && breakpoint !== "lg") && (
              <>
                {/* <Col xs={{ order: 4, span: "auto" }}>
                  <TogglePropertyOperation setOperationType={setOperationType} operationType={operationType} isInHeader />
                </Col> */}
                <Col xs={{ order: 4, span: "auto" }}>
                  <TogglePropertyList setListType={setListType} listType={listType} isInHeader />
                </Col>
              </>
            )}

            {/* <Col
              xs={{ order: 5, span: "auto" }}
              lg={{ order: 0 }}
              className="flex-shrink-1"
            >
              <FilterNeighbourhood />
            </Col> */}
          </Row>
        </Container>
      </div>
    </>
  );
};
