import { useState, useEffect } from "react";
import userFilter from "../../../hooks/filter/useFilter";
import "./FilterSearch.scss";
import { useSearchParams } from "react-router-dom";

export const FilterSearch = ({
  source
}: {
  source: "filters" | "header"
}) => {
  const { setFilter, filterState } = userFilter();
  const [searchParams] = useSearchParams();
  const [inputValue, setInputValue] = useState("");
  const [prevInputValue , setPrevInputValue] = useState("")

  useEffect(() => {
    const search: null | string = searchParams.get("search");
    if (search) {
      setInputValue(search)
    }
  }, [])

  const delayValue = 250
  let timer: any = 0;

  const searchNameInput = (ev: any) => {

    clearTimeout(timer)
    timer = setTimeout(() => {
      
      if(filterState.search != ev.target.value){
        //Hacemos algo
        if (ev.target.value.length > 1) {
          setFilter({ search: ev.target.value });
        } else if (ev.target.value.length == 0) {
          setFilter({ search: '' });
        }
      
      }
      

    }, delayValue);
  }

  const keyDownHandle = (ev: any) => {
    clearTimeout(timer)
  }


  return (
    <div className={`input-group ${source === "header" ? "search-header" : "search-filters"}`}>
      {/* <input type="text" className="form-control bg-sm-light" placeholder="Buscar por nombre de proyecto" aria-label="Username" aria-describedby="basic-addon1" onChange={handleChange} value={inputValue} /> */}
      <input type="text" className="form-control bg-sm-light" placeholder="Buscar por nombre de proyecto" aria-label="Username" aria-describedby="basic-addon1" onKeyUp={searchNameInput} onChange={(ev) =>{
        setPrevInputValue(inputValue)
        setInputValue(ev.target.value)
      }}
        onKeyDown={keyDownHandle} value={inputValue} />

      <i className="bi bi-search input-group-text bg-sm-light"></i>
    </div>
  );
};
