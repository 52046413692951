import { useEffect, useState } from "react";
import { UnitType } from "../../interfaces/project";
import { IconBed } from "../CustomIcons/IconBed";
import useCountry from "../../hooks/config/useConfig";

export const PropertyBedroom = ({
  units = [],
  short = false,
}: {
  units?: UnitType[];
  short?: boolean;
}) => {
  const { country, translation } = useCountry();
  const [text,setText] = useState('')

  // aux
  const getBedroomText = (count: number, acortar: boolean) => {
    return count > 0
      ? count > 1
        ? `${count} ${acortar ? translation.short_bedroom : translation.bedrooms}`
        : `1 ${acortar ? translation.short_bedroom : translation.single_bedroom }`
      : `${acortar && translation.abbrStudio}`;
  };


  useEffect(()=>{
    if(country && translation.studio){
      
      units = units.filter(u=>u.tipology != 'Local Comercial')
      units.sort((a, b) => a.bedrooms - b.bedrooms);
      
      let res = "";
      if (units.length > 0) {
        if (units[0].bedrooms === units[units.length - 1].bedrooms) {
          res += getBedroomText(units[0].bedrooms, short);
        } else {
          res += units[0].bedrooms > 0 ? `${units[0].bedrooms}` : translation.abbrStudio;
        }
        if (units[0].bedrooms < units[units.length - 1].bedrooms) {
          res += " a ";
          res += getBedroomText(units[units.length - 1].bedrooms, short);
        }
      }
      setText(res)
    }
  },[units, short,country,translation])

  if (!text || text.length === 0) return null;

  return (
    <div className="property-bedroom d-flex align-items-center flex-lg-row">
      <IconBed width={15} height={13} />
      <span className="mx-2 me-lg-0">{text}</span>
    </div>
  );
};
