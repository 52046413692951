import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { PropertyType } from "../../interfaces/property";
import { PropertyForm } from "../PropertyForm/PropertyForm";
import useCountry from "../../hooks/config/useConfig"
import { ProjectType } from "../../interfaces/project";

export const PropertyFormButton = ({
  type = "SCHEDULE",
  property,
}: {
  property: ProjectType;
  type? :"OFFER" | "SCHEDULE" | "CLIENT";
}) => {
  const [modal, setModal] = useState(false);
  const {countryFeatures} = useCountry()
  const titles = {
    "OFFER" : (countryFeatures.type_offer=='offer'? "Envía una oferta":"Reservar"),
    "SCHEDULE" : "Agendar Visita",
    "CLIENT" :"Registrar Cliente"
  }
  return (
    <>
      <Button
        variant={type == "OFFER" ? "primary" : "outline-primary"}
        className="property-button-offer w-100"
        onClick={() => setModal(true)}
      >
        {titles[type]}
      </Button>
      {modal && (
        <Modal
          show
          onHide={() => setModal(false)}
          centered
          backdropClassName="property-form-modal"
          fullscreen={"sm-down"}
          className="property-form-modal-content"
        >
          <Modal.Header closeButton className="border-bottom-0"></Modal.Header>
          <Modal.Body className="pt-md-0">
            <PropertyForm
              property={property}
              initialForm={type}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
