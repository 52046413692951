import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { OperationType, PropertyType } from "../../interfaces/property";
import { ScheduleVisit } from "./ScheduleVisit";
import { SendOffer } from "./SendOffer";
import { SendReservation } from "./SendReservation";
import useCountry from "../../hooks/config/useConfig"
import { ProjectType } from "../../interfaces/project";
import { ClientRegister } from "./ClientRegister";


export const PropertyForm = ({
  property,
  initialForm = "CLIENT",
  operation 
}: {
  property: ProjectType;
  initialForm?: "OFFER" | "SCHEDULE" | "CLIENT";
  operation? : OperationType
}) => {
  
  const { countryFeatures } = useCountry()
  const [selectedTab , setSelectedTab ] = useState(initialForm)
  
  const enableClientRegister = countryFeatures.use_some_client_register && 
  ( (countryFeatures.use_client_register_outside && !property?.countryFeatures?.use_client_register_inside ) || property?.countryFeatures?.use_client_register_outside )

  return (
    <div className="property-form">
      <Row className="d-flex align-items-center justify-content gy-0 gx-2 mb-2">
        {
          enableClientRegister && 
          <Col>
            <Button
              variant="light"
              size="sm"
              onClick={() => setSelectedTab("CLIENT")}
              className={`form-toggle-button w-100 py-2 rounded-2 border-0 fw-500 ${selectedTab == "CLIENT" ? "bg-sm-light active" : "bg-white text-black-50"
                }`}
            >
              Registrar Cliente
            </Button>
          </Col>
        }
       
        <Col>
          <Button
            variant="light"
            size="sm"
            onClick={() =>  setSelectedTab("SCHEDULE")}
            className={`form-toggle-button w-100 py-2 rounded-2 border-0 fw-500 ${selectedTab == "SCHEDULE" ? "bg-sm-light active" : "bg-white text-black-50"
              }`}
          >
            Agendar visita
          </Button>
        </Col>
        <Col>
          <Button
            variant={"light"}
            size="sm"
            onClick={() =>  setSelectedTab("OFFER")}
            className={`form-toggle-button w-100 py-2 rounded-2 border-0 fw-500 ${selectedTab == "OFFER" ? "bg-sm-light active" : "bg-white text-black-50"
              }`}
          >
            {countryFeatures.type_offer=='offer' && 'Enviar Oferta'}
            {countryFeatures.type_offer == 'reservation' && 'Reservar'}
          </Button>
        </Col>
      </Row>
      {selectedTab == "OFFER" && countryFeatures.type_offer=='offer' ? (
        <SendOffer property={property} operation={operation} />
      ): 
      selectedTab == "OFFER" && countryFeatures.type_offer=='reservation' ? (
        <SendReservation property={property} operation={operation} />
      ) 
       : (
        selectedTab == "SCHEDULE" ?
        <ScheduleVisit property={property} /> :
        <ClientRegister property={property}/> 
      )}
    </div>
  );
};
