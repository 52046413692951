import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import userFilter from "../../../hooks/filter/useFilter";
import { useSearchParams } from "react-router-dom";
import { uniqueFromArray, excludeFromArray } from "../../../utils/Functions";

export const FilterBathroom = () => {
  const { setFilter } = userFilter();
  const [searchParams] = useSearchParams();
  const [currentBathrooms, setCurrentBathrooms] = useState([] as string[]);

  useEffect(() => {
    const parameter = searchParams.get("bathrooms");
    setCurrentBathrooms(!!parameter ? parameter.split(",") : ([] as string[]));
  }, [searchParams]);

  return (
    <div className="mb-1 d-flex justify-content-start flex-wrap numbers-container">
      {["1", "2", "3", "4"].map((number) => (
        <Button
          key={`filter-${number}`}
          variant="outline-ligth"
          className={`text-dark bg-white btn-number d-flex align-items-center justify-content-center ${currentBathrooms.includes(number) ? "active" : ""
            }`}
          onClick={(e) => {
            const current = currentBathrooms ?? ([] as string[]);
            setFilter({
              bathrooms: !(e.target as Element).classList.contains("active")
                ? (uniqueFromArray([...current, number]) as unknown as string[])
                : (excludeFromArray(current, number) as unknown as string[]),
            });
          }}
        >
          {number === "4" ? `+${number}` : number}
        </Button>
      ))}
    </div>
  );
};
