
import { NavLink, Routes, Link, useLocation } from 'react-router-dom'
import SidebarSubmenu from './SidebarSubmenu';
import "./LeftSidebar.scss"
import { IconBoxArrowUpRight } from '../../../components/CustomIcons/IconBoxArrowUpRight';

import { IconUser } from '../../../components/CustomIcons/IconUser'
import { IconCoin } from '../../../components/CustomIcons/IconCoin'
import { IconHeart } from '../../../components/CustomIcons/IconHeart'
import { IconClockHistory } from '../../../components/CustomIcons/IconClockHistory'
import { IconBuilding } from '../../../components/CustomIcons/IconBuilding'

import useConfig from "../../../hooks/config/useConfig"
import { IconBuildingLock } from '../../../components/CustomIcons/IconBuildingLock';
import { Badge } from 'react-bootstrap';
import UserLevel from '../levels/UserLevel';



const LeftSidebar = () => {
    const location = useLocation();

    const {countryFeatures} = useConfig()

    const sidebarLinks = [
        {
            path: '/usuario/perfil',
            icon: <IconUser width={24} />,
            name: 'Perfil',
            open_external : false,
            visible : true
        },
        // {
        //     path: '/usuario/ventas',
        //     icon: <IconCoin width={24} />,
        //     name: 'Ventas',
        //     open_external : false,
        //     visible : true
    
        // },
        {
            path: '/usuario/ventas',
            icon: <IconCoin width={24} />,
            name: 'Ventas',
            open_external : false,
            visible : true,
            soon : false
    
        },
        {
            path: '/usuario/favoritos',
            icon: <IconHeart width={24} />,
            name: 'Favoritos',
            open_external : false,
            visible : true
        },
        {
            path: '/usuario/actividad',
            icon: <IconClockHistory width={24} />,
            name: 'Actividad',
            open_external : false,
            visible : true
        },
        {
            path: '/garantias-alquiler',
            icon: <IconBuildingLock width={24} />,
            name: 'Garantías de Alquiler',
            open_external : true,
            visible : !!countryFeatures.rentWarrantyPanel
        },
    
    ]

    return (
        <div className="tw-drawer-side d-block profile-sidebar">
            <span className="tw-drawer-overlay"></span>
            <ul className="tw-menu  tw-pt-2 tw-w-64 bg-base-100 text-base-content">


                {
                    sidebarLinks.filter(l=>l.visible).map((route: any, k: number) => {
                        return (
                            <li className="" key={k}>
                                {
                                    route.submenu ?
                                        <SidebarSubmenu {...route} /> :
                                        (<NavLink
                                            end
                                            to={route.path}
                                            target={route.open_external ? '_blank' : '_self'}
                                            className={({ isActive }) => `${isActive && !route.soon ? 'tw-font-semibold  tw-bg-base-200 ' : 'tw-font-normal'} ${route.soon ? ' focus:tw-bg-transparent hover:tw-bg-transparent': ''} justify-content-between`} >
                                            <span>
                                                {route.icon} {route.name}
                                            </span> 
                                            
                                            {
                                                route.soon && <Badge bg='primary' >¡Próximamente!</Badge>
                                            }
                                            {
                                                route.open_external && <IconBoxArrowUpRight width="14" />
                                            }
                                            {
                                                location.pathname === route.path ? (<span className="tw-absolute tw-inset-y-0 tw-left-0 tw-w-1 tw-rounded-tr-md tw-rounded-br-md tw-bg-primary "
                                                    aria-hidden="true"></span>) : null
                                            }
                                        </NavLink>)
                                }

                            </li>
                        )
                    })


                }

            </ul>
            
            <div className='d-none d-lg-block'>
            <div className='space-level'></div>
            <UserLevel />
            </div>
           
        </div>
    )
}

export default LeftSidebar