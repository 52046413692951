import { useEffect, useState } from "react";
import { Dropdown, Offcanvas } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import useBreakpoint from "use-breakpoint";
import { BREAKPOINTS } from "../../../utils/Constants";
import "./FilterEstate.scss";
import { FilterEstateDropdown } from "./FilterEstateDropdown";
import useFilter from "../../../hooks/filter/useFilter"
import useConfig from "../../../hooks/config/useConfig"
import { Country, Department } from "../../../interfaces/locations";


export const FilterEstate = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");
  const [searchParams] = useSearchParams();
  const [currentEstate, setCurrentEstate] = useState<Department[]>([]);
  const [estates, setEstates] = useState<Department[]>([]);
  const [open, setOpen] = useState(false);
  const {filterState} = useFilter()
  const {allCountries} = useConfig()

  useEffect(() => {

    const country = searchParams.get("country");
    let selectedCountries : Country[] = allCountries
    let filteredEstates : Department[] = []
    let allEstates : Department[] = []

    if(allCountries.length > 0){
      
      allCountries.map(c=> {
        allEstates = allEstates.concat(c.departments || [])
      })
      
      if (!!country) {
        selectedCountries = allCountries.filter((t) => {
          return country.split(",").includes(t?.id.toString() || "");
        })
      } 
      
      selectedCountries.map(c=> {
        filteredEstates = filteredEstates.concat(c.departments || [])
      })
      
      setEstates(filteredEstates)
  
      const parameter = searchParams.get("location");
      if (!!parameter) {
        const newEstates = allEstates.filter((t) =>
          parameter.split(",").includes(t.id.toString())
        );
        setCurrentEstate(newEstates);
      } else setCurrentEstate([]);

    }
    
  }, [searchParams,allCountries]);

  const title =
    currentEstate.length > 0
      ? currentEstate.length > 1
        ? `${currentEstate.length} ubicaciones`
        : currentEstate[0].name
      : "Ubicación";

  if(!filterState.country || filterState.country.length == 0){
    return <></>
  }

  if (["xs", "sm", "md"].includes(breakpoint)) {
    return (
      <>
        <div
          role={"button"}
          onClick={() => setOpen(true)}
          className="d-flex align-items-center"
        >
          <span className="text-button me-2 text-primary">Ubicación</span>
          <i className="bi bi-chevron-down text-light-secondary ms-2 d-flex align-items-center"></i>
        </div>
        <Offcanvas show={open} onHide={() => setOpen(false)}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>{title}</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="pt-0">
            {
              estates.map((estate) => (
                <FilterEstateDropdown key={estate.id} estate={estate} currentEstate={currentEstate} />
              ))
            }
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }

  return (
    <Dropdown autoClose="outside" align={"end"}>
      <Dropdown.Toggle
        variant="info"
        className="bg-white filter-estate py-0 px-2 border-0 float-end d-flex align-items-center"
      >
        <span className="text-button text-primary">{title}</span>
        <i className="bi bi-chevron-down text-light-secondary ms-2 d-flex align-items-center"></i>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="px-1 py-0 border shadow"
        style={{ maxHeight: 270, overflow: "auto" }}
      >
        {
          estates.map((estate) => (
            <FilterEstateDropdown key={estate.id} estate={estate} currentEstate={currentEstate} />
          ))
        }
      </Dropdown.Menu>
    </Dropdown>
  );
};
