import { Col, Row } from "react-bootstrap"
import { CourseCard } from "../../components/Academy/CouseCard"
import { useAcademy } from "../../hooks/academy/useAcademy"
import useConfig from "../../hooks/config/useConfig"
import { Link } from "react-router-dom"
import "./Courses.scss"
import { CustomImage } from "../../components/CustomImage/CustomImage"

export const CoursesList = () => {

    const { courses } = useAcademy()
    const {country} = useConfig()


    return (
        <>
        {
            country == 'CL' && 
            <Row className="pt-3">
                <Col className="d-flex justify-content-end"> 
                    <img 
                    width={180}
                    src="https://cdn1.infocasas.com.uy/web/661868409b153_logo-b-4.png" 
                    />
                </Col>
            </Row>
        }
        
        <Row className="courses-list gx-2 gy-3 mb-4 mt-1 mt-lg-0">
            {courses.map(course =>
                <Col
                    className="px-2"
                    xl={"3"}
                    lg={"4"}
                    md={"6"}
                    sm="12"
                    xs="12"
                    key={"list_" + course.identifier}
                >
                    <Link
                      to={`/cursos/${course.identifier}`}
                      className={`text-decoration-none position-relative d-block `}
                    >
                        <CourseCard course={course} />
                    </Link>
                    
                </Col>)}
        </Row>
        </>
        

    )
}