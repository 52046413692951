import { useEffect, useState } from "react";
import { Button, Col, Container, ProgressBar, Row, Alert, Table, Modal, Form, Dropdown, DropdownButton } from "react-bootstrap";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import loaderIris from "../../../lotties/orange-loader.json";
import GrayLoader from "../../../lotties/gray-loader.json";
import { UserSale, UserSaleFull } from "../../../interfaces/user";
import { formatDateToDMY, formatMoney, getDateYMD } from "../../../utils/Functions";
import "./ListSales.scss"
import { IconDownload } from "../../../components/CustomIcons/IconDownload";
import { IconCloudUpload } from "../../../components/CustomIcons/IconCloudUpload";
import { IconEye } from "../../../components/CustomIcons/IconEye";
import moment from "moment";
import { IconBoxArrowUpRight } from "../../../components/CustomIcons/IconBoxArrowUpRight";
import { useUserFetch } from "../../../hooks/fetch/useUserFetch"
import { useAdminFetch } from "../../../hooks/fetch/useAdminFetch"
import { UpdateBillFileBody, UpdateSaleBody } from "../../../interfaces/userActivity";
import useAlert from "../../../hooks/alert/useAlert";
import Pagination from "../../../components/ui/pagination/Pagination";
import { IconTrashFill } from "../../../components/CustomIcons/IconTrashFill";
import { IconTrash } from "../../../components/CustomIcons/IconTrash";
import { IconPencil } from "../../../components/CustomIcons/IconPencil";
import { IconFileEamarkExcelFill } from "../../../components/CustomIcons/IconFileEamarkExcelFill";
import { AlertVariants } from "../../../context/Alert/AlertContext";

export const ListSales = () => {

    const MAX_PAGES = 4
    const LIMIT = 20
    const LIMIT_EXCEL = 1000
    const [sales, setSales] = useState<UserSaleFull[]>([])
    const [page, setPage] = useState(1)
    const [searchName, setSearchName] = useState('')
    const [statusFilter, setStatusFilter] = useState('')
    const [loading, setLoading] = useState(false)
    const { getAdminSales } = useAdminFetch()
    const [modal, setModal] = useState(false)
    const [modalDelete, setModalDelete] = useState(false)
    const [selectedSale, setSelectedSale] = useState<UserSale>()
    const [saleToDelete, setSaleToDelete] = useState<UserSale>()
    const [voucherFile, setVoucherFile] = useState()
    const [loadingFile, setLoadingFile] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [totalPages, setTotalPages] = useState(0)
    const { updateBillFile } = useUserFetch()
    const { updateSale, deleteSaleById,exportSales } = useAdminFetch()
    const { setPositionAlert, setTypeGlobalAlert, setShowGlobalAlert, setMessageGlobalAlert } = useAlert()
    const statusOptions = ['Registrada','Facturada','Cobrada']

    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.tz.guess() //Adivina el timezone del usuario

    useEffect(() => {
        setLoading(true)
        loadSales(page, LIMIT, '', '')
    }, [])

    const loadSales = (loadPage: number, loadLimit: number,  filterStatus?: string ,searchTerm?: string) => {
        
        setPage(loadPage)
        if(searchTerm) setSearchName(searchTerm)
        if(filterStatus) setStatusFilter(filterStatus)
        
        getAdminSales(loadPage, loadLimit, filterStatus , searchTerm ).then(data => {
            setLoading(false)
            if (data.success) {
                setSales(data.data)
                let total_pages = Math.ceil(data.total / loadLimit)
                setTotalPages(total_pages)
            }


        }).catch(err => {
            console.log(err)
        })
    }

    const openBillModal = (sale: UserSale) => {

        setModal(true)
        setSelectedSale(sale)
    }

    const selectVoucherFile = (ev: any) => {
        if (ev.target?.files && ev.target?.files[0]) {
            setVoucherFile(ev.target.files[0])
        } else {
            setVoucherFile(undefined)
        }

    }

    const sendBillFile = (e: any) => {
        e.preventDefault()
        if (selectedSale && voucherFile) {
            let body: UpdateSaleBody = {
                sale_id: selectedSale?.id,
                voucher_f: voucherFile

            }
            setLoadingFile(true)
            updateSale(body).then(result => {
                setLoadingFile(false)
                if (result.success) {
                    setModal(false)
                    showAlertMessage('success', 'Comprobante actualizado')

                    loadSales(page, LIMIT)
                } else {
                    showAlertMessage('danger', result.message ? result.message : 'Ocurrió un error')
                }
                // setModal(false)

            }).catch(err => {
                setLoadingFile(false)
                console.error(err)
                showAlertMessage('danger', 'Ocurrió un error')
                // setModal(false)
            })
        }

    }

    const handlePagination = (pageNav: number) => {
        if (pageNav != page) {
            setPage(pageNav)
            loadSales(pageNav, LIMIT)
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }

    }

    const openDeleteModal = (sale: any) => {
        setSaleToDelete(sale)
        setModalDelete(true)
    }

    const deleteSale = () => {
        setLoadingDelete(true)
        if (saleToDelete) {

            deleteSaleById(saleToDelete.id.toString()).then(res => {
                setLoadingDelete(false)
                setModalDelete(false)
                if (res.success) {
                    loadSales(1, LIMIT)
                    if(res.message){
                        showAlertMessage('success', res.message)
                    }else showAlertMessage('success',  'Venta eliminada')
                } else {
                    if(res.message){
                        showAlertMessage('danger', res.message)
                    }else showAlertMessage('danger', 'Ocurrió un error')
                    
                }

            }).catch(err => {
                setLoadingDelete(false)
                setModalDelete(false)
                showAlertMessage('danger', 'Ocurrió un error')
                console.error(err)
            })
        }

    }

 
    const showAlertMessage = (type: AlertVariants, message: string) => {
        setPositionAlert('bottom')
        setTypeGlobalAlert(type)
        setMessageGlobalAlert(message)
        setShowGlobalAlert(true)
        setTimeout(() => {
            setShowGlobalAlert(false)
            setTypeGlobalAlert('success')
            setMessageGlobalAlert('')
            setPositionAlert('top')
        }, 3000);

    }

    const delayValue = 250
    let timer: any = 0;
    const searchNameInput = (ev: any) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            //Hacemos algo
            if (ev.target.value.length > 1) {
                loadSales(1,LIMIT,statusFilter,searchName)
            } else if (ev.target.value.length == 0) {
                loadSales(1,LIMIT,statusFilter,'')
            }

        }, delayValue);
    }

    const keyDownHandle = (ev: any) => {
        clearTimeout(timer)
    }

    const handleChangeStatus = (status : string) => {
        setStatusFilter(status)
        loadSales(1,LIMIT,status,searchName)
    }

    const exportExcel = () => {
        exportSales(1, LIMIT_EXCEL, statusFilter , searchName).then(res => {
            
            if(res && res.success){
                const content = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

                const encodedUri = window.URL.createObjectURL(content);
                const link = document.createElement("a");
                let date = getDateYMD()
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", `LISTADO-VENTAS-IRIS-${date}.xlsx`);
                link.click();

            }else{
                showAlertMessage('danger', 'Ocurrió un error')
            }
            
        }).catch(err => console.error)
    }

    return (
        <div className="p-5 admin-sales-list">
            <div className="mb-4 py-2 px-4 d-flex justify-content-between flex-wrap position-relative">
                <Link to="/admin" className="fs-2 go-back mt-3 ms-3 ms-md-4" title="Volver">
                    <i className="text-light bi bi-arrow-left" ></i>
                </Link>
                <h3>VENTAS</h3>
                <Link to={'create'}><button className="btn btn-primary">CREAR NUEVA</button></Link>

            </div>
            <div className="d-flex mb-4 sales-filter align-items-center flex-wrap justify-content-between">
                <div className="d-flex  align-items-center flex-wrap ">
                    <Form.Group className="sales-filter-search me-2 mb-2">
                        <Form.Control placeholder="Busca inmobiliaria..." value={searchName} onKeyUp={searchNameInput} onChange={(ev) => setSearchName(ev.target.value)}
                            onKeyDown={keyDownHandle} />
                    </Form.Group>
                    <div className="d-flex align-items-center justify-content-start sales-filter-status">
                        <span className="mx-4 me-2 p-0">Filtrar Estado:</span>
                        <DropdownButton id="dropdown-basic-button" variant="outline-primary" title={!statusFilter || statusFilter == ''? 'Todas ': `${statusFilter}`}>
                            <Dropdown.Item onClick={() => handleChangeStatus('')}>Todas</Dropdown.Item>
                                {statusOptions.map((so,i) => <Dropdown.Item key={i} onClick={() => handleChangeStatus(so)}>{so}</Dropdown.Item> )}
                        </DropdownButton>
                    </div>
                </div>
                
                <div className="export-excel">
                    <Button variant="outline-primary" 

                     onClick={exportExcel}>Exportar <IconFileEamarkExcelFill width='20' /> </Button>
                </div>
            </div>
            <div>
                {
                    sales?.length > 0 &&
                    <Table bordered hover responsive>
                        <thead>
                            <tr>
                                <th>Inmobiliaria</th>
                                <th>Proyecto</th>
                                {/* <th>Unidad</th>
                                
                                <th>Garage</th> */}
                                <th>Total USD</th>
                                <th>Comisión</th>
                                {/* <th>Clicks Web</th> */}

                                <th>Estado</th>
                                <th>Factura</th>
                                <th>Comprobante de Pago</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                sales.map(sale => {

                                    //Convertir a fecha de zona de usuario y formatear
                                    const inmoName = sale.user.real_estate ? sale.user.name + ' - ' + sale.user.real_estate : sale.user.name

                                    return (
                                        <tr key={sale.id}>
                                            <td>{inmoName}</td>
                                            <td>
                                                <p><strong>{sale.project_name}</strong> </p>
                                                <p>Unidad {sale.unit_name ? sale.unit_name : '-'}</p>
                                                <p>{sale.garage_name ? 'Garage ' + sale.garage_name : '-'}</p>
                                            </td>
                                            {/* <td className="text-center">
                                                <p>Unidad {sale.unit.number}</p>
                                                <p><strong>{sale.unit_price_currency}  {formatMoney(sale.unit_price)}</strong> </p>
                                            </td>
                                            
                                            <td>
                                                <p>{sale.garage.name}</p>
                                                <p><strong>{sale.garage_price_currency}  {formatMoney(sale.garage_price)}</strong> </p>
                                            </td> */}
                                            <td>USD {formatMoney(sale.total_usd)}</td>
                                            <td>
                                                <p>{sale.commission_percentage}%</p>
                                                <p><strong>{sale.commission_currency} {formatMoney(sale.commission)}</strong></p>
                                            </td>
                                            {/* <td>{sale.web_views}</td> */}
                                            <td className="sale-info">{sale.status}</td>
                                            <td>
                                                {
                                                    sale.bill_file &&
                                                    <>
                                                        <a href={sale.bill_file} target="_blank">
                                                            <button className="btn btn-outline-primary border-0">
                                                                {/* Ver <IconBoxArrowUpRight width="14" /> */}
                                                                <IconEye width="20" />
                                                            </button>
                                                        </a>

                                                    </>

                                                }
                                            </td>
                                            <td>
                                                <div>
                                                    {
                                                        sale.voucher_file &&
                                                        <a href={sale.voucher_file} target="_blank">
                                                            <button className="btn btn-outline-primary border-0">
                                                                {/* Ver <IconBoxArrowUpRight width="14" /> */}
                                                                <IconEye width="20" />
                                                            </button>
                                                        </a>
                                                    }
                                                    <button className="btn btn-outline-primary border-0" onClick={() => openBillModal(sale)}><IconCloudUpload width="20" /> </button>
                                                </div>

                                            </td>
                                            <td>
                                                {/* <Link to={`detail/${sale.id}`}><button className="btn btn-outline-primary">Detalle </button> </Link>  */}
                                                <Link to={`edit/${sale.id}`}><button className="btn btn-outline-primary border-0"><IconPencil width={20} /> </button> </Link>
                                                <button className="btn btn-outline-primary border-0" onClick={() => openDeleteModal(sale)}><IconTrashFill width={20} /> </button>
                                            </td>

                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </Table>
                }
                {
                    (!sales || loading) &&
                    <div style={{ height: 400 }} className="w-100 d-flex align-items-center justify-content-center"><Lottie animationData={loaderIris} style={{ height: 100 }} loop /></div>
                }
                {sales?.length > 0 && totalPages && <Pagination currentPage={page} handlePagination={handlePagination} totalPages={totalPages} maxPages={MAX_PAGES} />}

            </div>
            {
                !loading && (!sales || sales.length == 0) &&
                <div className="text-center">
                    <hr />
                    <p className="mt-4">No hay ninguna venta registrada.</p>
                </div>

            }


            <Modal
                show={modal}
                onHide={() => setModal(false)}
                centered
                contentClassName="bg-md-light border-0 "
                size="lg"
            >

                <Modal.Header closeButton className="border-bottom-0 pb-0 mb-4">
                    <Modal.Title
                        className="d-flex flex-column w-100 text-center"
                        style={{ marginRight: -24 }}
                    >
                        <span className="text-primary">Proyecto: {selectedSale?.project?.title} - Unidad {selectedSale?.unit?.number}</span>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-1 px-3 pb-3 px-lg-4 pb-lg-4 mt-4 ">
                    {selectedSale &&
                        <div >
                            <div >

                                <Form onSubmit={sendBillFile} >
                                    <div className="mb-3">
                                        <Form.Group controlId="formFile" >
                                            <Form.Label className=" text-left"><strong>{selectedSale.bill_file ? 'Actualizar comprobante:' : 'Cargar comprobante: '}</strong> </Form.Label>
                                            <Form.Control type="file" className=" tw-w-full shadow-none " accept=".jpg,.jpeg,.png,.pdf" required
                                                onChange={selectVoucherFile}
                                            />
                                        </Form.Group>
                                        <div className="text-center mt-4">
                                            <button type="submit" className="btn btn-primary btn-upload-bill-file position-relative mt-4 " disabled={loadingFile}>
                                                CARGAR
                                                {loadingFile && <Lottie className="position-absolute loader-btn top-1" animationData={GrayLoader} style={{ height: 30, right: 5, top: 2 }} loop />}
                                            </button>
                                        </div>

                                    </div>
                                </Form>
                            </div>
                        </div>
                    }
                </Modal.Body>

            </Modal>

            <Modal
                show={modalDelete}
                onHide={() => setModalDelete(false)}
                centered
                contentClassName="bg-md-light border-0 "

            >

                <Modal.Header closeButton className="border-bottom-0 pb-0 mb-4">
                    <Modal.Title
                        className="d-flex flex-column w-100 text-center"
                        style={{ marginRight: -24 }}
                    >
                        <span className="text-primary">¿Está seguro de eliminar esta venta?</span>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-1 px-3 pb-3 px-lg-4 pb-lg-4 mt-4 ">
                    {saleToDelete &&
                        <div >
                            <div >
                                <div className="mb-3 text-center" >
                                    <p > Proyecto : {saleToDelete?.project?.title} - Unidad {saleToDelete?.unit?.number}</p>
                                    <div className="text-center mt-4">
                                        <button type="submit" className="btn btn-primary btn-upload-bill-file position-relative mt-4 px-4 "
                                            disabled={loadingDelete}
                                            onClick={deleteSale}>
                                            ELIMINAR
                                            {loadingDelete && <Lottie className="position-absolute loader-btn top-1" animationData={GrayLoader} style={{ height: 28, right: 0, top: 4 }} loop />}
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    }
                </Modal.Body>

            </Modal>
        </div>
    )
}