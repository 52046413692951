import { useEffect, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { VirtualSignPlan } from "../../../interfaces/services"
import { Link } from "react-router-dom"
import useConfig from '../../../hooks/config/useConfig'
import { formatMoney } from "../../../utils/Functions"

export const ModalVirtualSign = ({
    showModal,
    plan,
    setShow
}: {
    showModal: boolean,
    plan?: VirtualSignPlan,
    setShow: Function
}) => {

    const { countryFeatures } = useConfig()

    if (plan) {
        
        return <>
            <Modal
                show={showModal}
                onHide={() => setShow(false)}
                centered
                contentClassName="bg-xs-light border-0 coordinator-modal"
            >
                <Modal.Header closeButton className="border-bottom-0 pb-0">
                    <Modal.Title
                        className="d-flex flex-column w-100 text-center mt-2"
                        style={{ marginRight: -24 }}
                    >
                        <span className="text-primary">Obtener Plan: <strong>{plan?.name}</strong> </span>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-3 px-3 pb-3 px-lg-4 pb-lg-4 ">

                    <div className="d-flex justify-content-center">

                        <div className="d-flex align-items-end mt-4 mb-4 fw-bold position-relative">
                            {!!plan?.price && <span className="virtual-sign__card__old-price fw-normal text-decoration-line-through position-absolute">{plan.currency}{formatMoney(plan.price)} </span>}
                            <span>Valor</span>
                            <div className="d-flex flex-column align-items-center m-1">

                                <span className="fs-2 virtual-sign__card__price ">{plan.currency}{formatMoney(plan.irisPrice)} </span>
                            </div>
                            <span>+ IVA</span>
                        </div>
                    </div>
                   

                    <div className="mt-2">
                        <p >Sigue los siguientes pasos:</p>
                        <p>1. Realiza el pago:
                            <Link to={'https://www.webpay.cl/form-pay/35155'} target="_blank">
                            <Button className="ms-2" variant="primary">Paga Aquí</Button>
                            </Link>
                            </p>
                            
                        <p className="mt-1">2. Envíanos la captura: 
                            <Link to={countryFeatures.contact_wame || ''} target="_blank" >
                            <button className="btn btn-green border-color-green bg-white  ml-2 mx-2" >
                            Aquí <i className="bi bi-whatsapp"></i> 
                            </button>
                            </Link>
                            y te brindaremos el plan.
                        </p>

                    </div>
                </Modal.Body>
            </Modal>


        </>
    }else{
        return <></>
    }




}