export const IconTransport = ({ width = 20 }) => {
  return (
    <svg width={width} height={width} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.9375 8.9375H23.5625V12.1875H21.9375V8.9375Z" fill="currentColor" />
      <path d="M2.4375 8.9375H4.0625V12.1875H2.4375V8.9375Z" fill="currentColor" />
      <path d="M16.25 16.25H17.875V17.875H16.25V16.25Z" fill="currentColor" />
      <path d="M8.125 16.25H9.75V17.875H8.125V16.25Z" fill="currentColor" />
      <path d="M17.0625 3.25H8.9375C7.86045 3.25129 6.82789 3.67972 6.0663 4.4413C5.30472 5.20289 4.87629 6.23545 4.875 7.3125V18.6875C4.87543 19.1183 5.04677 19.5314 5.35143 19.8361C5.65608 20.1407 6.06916 20.3121 6.5 20.3125V22.75H8.125V20.3125H17.875V22.75H19.5V20.3125C19.9308 20.3119 20.3437 20.1404 20.6483 19.8358C20.9529 19.5312 21.1244 19.1183 21.125 18.6875V7.3125C21.1237 6.23545 20.6953 5.20289 19.9337 4.4413C19.1721 3.67972 18.1395 3.25129 17.0625 3.25ZM19.5 8.125V13H6.5V8.125H19.5ZM8.9375 4.875H17.0625C17.565 4.87649 18.0547 5.0335 18.4643 5.32446C18.874 5.61541 19.1836 6.02606 19.3505 6.5H6.6495C6.81643 6.02606 7.12599 5.61541 7.53566 5.32446C7.94533 5.0335 8.43502 4.87649 8.9375 4.875ZM6.5 18.6875V14.625H19.5008L19.5016 18.6875H6.5Z" fill="currentColor" />
    </svg>
  );
};
