
import { useEffect, useMemo, useState } from 'react'
import { PopupsInfo } from '../../data/preheaders-popup/popup'
import useConfig from '../../hooks/config/useConfig'
import { ImageModal } from './ImageModal'
import { RandomImagePopup } from './RandomImagePopup'
import { PromoModal } from './PromoModal'

export const PopupMain = () => {
    const { country } = useConfig()
    const [isDateValid, setIsDateValid] = useState(false);
    const popupsInfoCountry = PopupsInfo.find( p => p.country == country)
    const popups = popupsInfoCountry?.popups.filter( r => !!r.active) || []
    const eventDateString = "Dec 15, 2024 23:59:59";
    const isRandom = popups.length > 1 ? true : false
    const firstPopup = popups[0]

    useEffect(() => {
        const eventDate = new Date(eventDateString);
        const currentDate = new Date();
    
        setIsDateValid(currentDate <= eventDate);
    }, []);

    if(!popupsInfoCountry || popups.length <= 0) return null;

    return (
        <>
        {country === "UY" && isDateValid ?
            <PromoModal
                cookieName="iris-bf"
                mobileImg="https://cdn1.infocasas.com.uy/web/675883a0f1375_mobile.png"
                desktopImg="https://cdn1.infocasas.com.uy/web/675883a0c8bf6_desktop.png"
                link="https://www.infocasas.com.uy/iris/amp9"
                dateEvent={eventDateString}
            /> :
            <div className="container">
                {
                    isRandom ? 
                    <RandomImagePopup popups={popups} /> : 
                    <ImageModal 
                        cookieName={firstPopup.cookieName}  
                        desktopImg={firstPopup.desktopImg}
                        mobileImg={firstPopup.mobileImg}
                        link={firstPopup.link}
                        endDate={firstPopup.endDate}
                        name={firstPopup.name}

                    />
                }
            </div>
        }
        </>
    )
}