
import { useState , useRef, useEffect } from "react";
import { Dropdown, Form } from "react-bootstrap";

export interface OptionSelectType {
    label? : string, 
    value? : any,
    disabled? : boolean
}

const SpecialSelect = ({options , onChange , selected, loading, loadingMessage, 
    placeholder, isMulti , noOptionsMessage, setInvalid , setValid,autoFocus , className , disabled}:
    {options : OptionSelectType[],placeholder? :string , onChange : Function,
    selected : OptionSelectType | OptionSelectType[] | null, loading? : boolean , 
    loadingMessage? : string, isMulti? : boolean, noOptionsMessage? : string,
    setInvalid?: boolean, setValid? : boolean,autoFocus? : boolean, className? : string , disabled? : boolean}) =>{

    const [suggestion, setSuggestion] = useState(options)
    const [inputValue , setInputValue] = useState<any>('')
    const [multiSelections , setMultiSelections] = useState<OptionSelectType[]>([])
    const toggleRef = useRef(null);
    const inputRef = useRef(null);
    const menuRef = useRef(null);

    useEffect(()=>{
        if(options && options.length>0){
            setSuggestion(getFullOptions())
        }
        
    },[options])

    useEffect(()=>{

        if(selected ){
            if(isMulti && Array.isArray(selected) ){    

                setMultiSelections(selected as OptionSelectType[])
                setSuggestion(getFullOptions(selected))
            }else if(!isMulti){
                let singleSelected = selected as OptionSelectType
                
                if(singleSelected.label== undefined){
                    setInputValue('')
                }else{
                    setInputValue(singleSelected.label)
                }
                
                
            }
        }else{
            setInputValue('')
        }
        
    },[selected])

    const handleClick = (option:any) =>{

        if(!option.disabled){
            if(isMulti){
                let newSelections = [...multiSelections,option];
                setMultiSelections(newSelections)
                onChange(newSelections)
                setInputValue('')
                setSuggestion(getFullOptions(newSelections))
                
                
            }else{  
                setInputValue(option.label)
                onChange(option)
                setSuggestion(getFullOptions())
            }     
        }
          
        
    }

    const handleKeyDown=(e :any)=>{
        if(e.keyCode && e.keyCode === 32){
            e.preventDefault()
            setInputValue((prev : any)=> prev + ' ')
        }
    }

    
    const handleChange=(e :any)=>{ 
        
        let value = e.target.value?.toLowerCase();
        let fullOptions = getFullOptions()
        //Abrir menú de opciones al escribir si está cerrado
        if(menuRef.current){
            let element = menuRef.current as HTMLElement
            if(!element.classList.contains('show') && toggleRef.current){
                let element = toggleRef.current as HTMLElement
                element.click()
            }
        }

        let suggestion : any[]=[];
        if(value.length > 0){
            suggestion = fullOptions.filter(v => v.label?.toLowerCase().includes(value));
            setSuggestion(suggestion)
        }else{
            onChange({value : '' , label : ''})
            setSuggestion(fullOptions)
        }
        
        setInputValue(e.target.value)
        
    }

    const getFullOptions = (selectionsChosen = multiSelections) =>{
        let fullOptions = options
        if(isMulti){
            fullOptions = options.filter(o=> !selectionsChosen.map(s=> s.value).includes(o.value))   
        }
        return fullOptions
    }

    const handleToggle = (isOpen:any,e:any) =>{
        
        if(!isOpen){
            
            let target = e.originalEvent.target
            if(inputRef.current ) {
                //Se cierra al hacer click en el mismo select o desde un dropdown item
                let element = inputRef.current as HTMLElement          
                if(element.id == target.id  ){
                    // setInputValue('')
                }
            }   
            
        }else{
            // setInputValue('')
        }     
        setSuggestion(getFullOptions()) 
        
    }

    const removeSelection = (e: any,selection : OptionSelectType) =>{
        e.stopPropagation()
        let newSelections = multiSelections.filter(ms=> ms.value !== selection.value)
        setMultiSelections(newSelections)
        setSuggestion(getFullOptions(newSelections))   
        onChange(newSelections)
    }

    const removeAllSelection = (e : any) =>{
        e.stopPropagation()
        setMultiSelections([])
        onChange([])
        setSuggestion(options)  
    }

    const isSelected = (value : any) =>{
        
        let is_selected = false
        if(isMulti && Array.isArray(selected)){
            selected.map(sel=>{
                if (sel.value == value){
                    is_selected = true
                }
            })
        }else{
            let singleSelect = selected as OptionSelectType
            is_selected = singleSelect?.value == value
        }
        
        return is_selected
    }

    return(
        <div className={`special-select ${className}`}>
            
            <Dropdown onToggle={handleToggle} 
             >
                <Dropdown.Toggle
                ref={toggleRef}
                className={setInvalid?'invalid':''}
                >
                    {
                        isMulti && 
                        multiSelections.map(sel=> 
                        <div key={sel.value} className="multi-selection">
                            <span className="multi-option">{sel.label}</span>
                            <span className="multi-remove" onClick={(e)=>removeSelection(e,sel)}><i className="bi bi-x"></i></span>
                        </div> )
                    }
                    

                    <Form.Control 

                        type="text"
                        value={inputValue}
                        placeholder={ (isMulti && multiSelections.length == 0) || !isMulti ?  
                                (placeholder ? placeholder : 'Selecciona...') : ''}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        isInvalid={setInvalid}
                        isValid={setValid}
                        formNoValidate={true}
                        role="presentation"
                        ref={inputRef}
                        autoComplete="off" 
                        className="input-special-select"
                        autoFocus={autoFocus}
                        
                    />
                    
                    {isMulti && multiSelections.length>0 &&
                     <i className="bi bi-x-lg me-2 remove-all" onClick={removeAllSelection}></i> 
                     }
                    <i className="bi bi-chevron-down me-2"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu 
                ref={menuRef}
                >
                    { !loading && <ul>
                        {
                            suggestion.map(option=> <Dropdown.Item 
                                active={isSelected(option.value)}
                                key={option.value}
                                role="menu"
                                className={option.disabled ? 'text-light' : ''}
                                onClick={() => handleClick(option)}><li
                               
                            >{option.label}</li></Dropdown.Item>  )
                        }
                    </ul>}
                    {loading && 
                        <p className=" m-0 mx-2 text-center">{loadingMessage?loadingMessage:'Cargando...'}</p>
                    }

                    {
                        !loading && suggestion.length==0 &&
                        <p className=" m-0 mx-2 text-center">{noOptionsMessage?noOptionsMessage:'No hay opciones'}</p>
                    }
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default SpecialSelect;
