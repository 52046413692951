import { useState } from "react"
import { Alert, Col, Form, Row } from "react-bootstrap"
import { DataAYC, DataVTP, ValidateDataRequest } from "../../interfaces/appraisal"
import useUser from "../../hooks/user/useUser"
import SpecialSelect, { OptionSelectType } from "../ui/SpecialSelect"
import { communesAYC } from "../../data/appraisal/communesAYC"
import Lottie from "lottie-react";
import GrayLoader from "../../lotties/gray-loader.json";
import loaderIris from "../../lotties/orange-loader.json";


export const FormAYC = (
    { dataVTP, setValidForm, setData, errorMessage, setCurrentStep, loading }:
        { dataVTP?: any, setValidForm: Function, setData: Function, errorMessage: string, setCurrentStep: Function, loading: boolean }
) => {

    const { userState } = useUser()
    const [validated, setValidated] = useState(false);

    const defaultData: DataAYC = {
        op: "1",
        comuna_sii: "",
        manzana: "",
        predial: "",
        tipobien: ""
    }

    const initialData = dataVTP || defaultData


    const [formData, setFormData] = useState<DataAYC>(initialData)
    const [invalidCommune, setInvalidCommune] = useState(false)
    const [invalidType, setInvalidType] = useState(false)
    const [commune, setCommune] = useState({})
    const [type, setType] = useState({})

    const comunnesOptions: OptionSelectType[] = communesAYC
    .sort((a,b) => a.name > b.name ? 1 : -1)
    .map(c => ({ label: c.name, value: c.code }))

    const typesOptions: OptionSelectType[] = [
        { label: 'Casa', value: 1 },
        { label: 'Departamento', value: 2 },
        { label: 'Oficina', value: 3 },
    ]

    const setValue = (e: any) => {
        let newValue = e.target.value
        setFormData((prev) => ({ ...prev, [e.target.name]: newValue }))
    }

    const handleChangeCommune = (selected: any) => {
        setCommune(selected)
        setFormData(prev => ({ ...prev, comuna_sii: selected.value }))
    }

    const handleChangeType = (selected: any) => {
        setType(selected)
        setFormData(prev => ({ ...prev, tipobien: selected.value }))
    }

    const customValidation = () => {
        let isValid = true
        setInvalidType(false)
        setInvalidCommune(false)

        if (!type || !formData.tipobien) {
            setInvalidType(true)
            isValid = false
        }

        if (!commune || !formData.comuna_sii) {
            setInvalidCommune(true)
            isValid = false
        }

        return isValid
    }

    const handleSubmit = (event: any) => {

        event.preventDefault()

        window.scrollTo({ top: 0, behavior: 'smooth' });

        let form = event.currentTarget;
        setValidated(true)
        let validForm = form.checkValidity()

        const isValid = customValidation()
        if (!isValid) return

        //Llamar validación externa
        if (validForm) {

            setData((prev: ValidateDataRequest) => ({ ...prev, data: formData }))
            setValidForm(formData)

        }
    }

    const prev = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setCurrentStep((prev: number) => prev - 1)
    }


    return (
        <div className="p-3 text-start vtp-form">

            <Form onSubmit={handleSubmit} noValidate validated={validated}>
                <Row >
                    <Col sm="12" lg='6' className="m-auto">
                        <Row>
                            <Col sm="12" lg='6' >
                                <Form.Group controlId="type">
                                    <Form.Label className="fw-bold">Tipo</Form.Label>
                                    <SpecialSelect
                                        options={typesOptions}
                                        onChange={handleChangeType}
                                        selected={type}
                                        setInvalid={invalidType}
                                    />
                                    {
                                        invalidType &&
                                        <div className="invalid-feedback d-block">Campo requerido</div>
                                    }

                                </Form.Group>
                            </Col>

                            <Col sm="12" lg='6'>
                                <Form.Group controlId="comuna">
                                    <Form.Label className="fw-bold">Comuna SII</Form.Label>
                                    <SpecialSelect
                                        options={comunnesOptions}
                                        onChange={handleChangeCommune}
                                        selected={commune}
                                        setInvalid={invalidCommune}
                                    />
                                    {
                                        invalidCommune &&
                                        <div className="invalid-feedback d-block">Campo requerido</div>

                                    }

                                </Form.Group>
                            </Col>
                            <Col xs="6" lg='6'>
                                <Form.Group controlId="manzana">
                                    <Form.Label className="fw-bold">Rol</Form.Label>

                                    <Form.Control
                                        name="manzana"
                                        value={formData.manzana}
                                        onChange={setValue}
                                        required
                                        type="number"
                                    />
                                    <Form.Text className="text-muted">
                                        Manzana
                                    </Form.Text>
                                    <Form.Control.Feedback type="invalid">
                                        Campo requerido
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>


                            <Col xs="6" lg='6' className="position-relative" >
                                <div className="position-absolute" style={{ left: "-3px", top: "45%" }}>-</div>
                                <Form.Group controlId="predial">
                                    <Form.Label className="fw-none opacity-0 ">Rol</Form.Label>
                                    <Form.Control
                                        value={formData.predial}
                                        name="predial"
                                        onChange={setValue}
                                        required
                                        type="number"
                                    />
                                    <Form.Text className="text-muted">
                                        Predial
                                    </Form.Text>
                                    <Form.Control.Feedback type="invalid">
                                        Campo requerido
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                        </Row>
                        <Row>
                                {
                                    errorMessage && errorMessage != '' &&
                                    <Alert key="danger" variant="danger">
                                        {errorMessage}
                                    </Alert>
                                }

                                <Col sm="12" lg='12'>
                                    <div className="d-flex justify-content-center align-items-center mt-5">
                                        <button className="btn btn-outline-secondary me-2 px-5" onClick={prev} >ATRÁS</button>
                                        <button className="btn btn-primary position-relative px-5" type="submit" disabled={loading}>
                                            SIGUIENTE
                                            {loading && <Lottie className="position-absolute loader-btn top-1" animationData={GrayLoader} style={{ height: 28, right: 5, top: 4 }} loop />}
                                        </button>
                                    </div>

                                </Col>

                            </Row>

                    </Col>
                </Row>

            </Form>

        </div>
    )
}