import { useEffect, useState } from "react";
import { Placeholder, Row } from "react-bootstrap";
import { UnitType } from "../../../interfaces/project";
import { formatMoney } from "../../../utils/Functions";
import useCountry from "../../../hooks/config/useConfig";

interface unitData {
    bedrooms: number | undefined,
    text: string,
    price: number | undefined
}

export const PropertyTablePrice = ({ units, currencyPrice, isBlocked = false, propertyTypeId } : { units?: UnitType[], currencyPrice: string, isBlocked?: boolean, propertyTypeId: string }) => {
    const [ cheaperUnits, setCheaperUnits ] = useState([] as unitData[])
    const { translation } = useCountry();

    useEffect(() => {
        setCheaperUnits([]);

        const unique = (value: number, index: number, self: number[]) : boolean => {
            return self.indexOf(value) === index
        }
        const uniqueBedroom = (value: any, index: number, self: any[]) : boolean => {
            return self.indexOf(self.find(s=>s.bedrooms == value.bedrooms && s.tipology == value.tipology)) === index
        }

        const newArray = units ? [...units] : [];
        const bedrooms = newArray.map((unit) => {
            return {
                bedrooms : unit.bedrooms,
                tipology : unit.tipology
            }
        }).filter(uniqueBedroom)
        .sort((a,b) => a.bedrooms - b.bedrooms)
        
        bedrooms?.forEach((bedroom) => {
            const bedroomFiltered = newArray?.filter((unit) => unit.bedrooms === bedroom.bedrooms && unit.tipology === bedroom.tipology)
            const cheaperUnit = bedroomFiltered?.sort((a, b) => Number(a.price_by_usd) - Number(b.price_by_usd))[0]

            setCheaperUnits((cheaperUnits) => [...cheaperUnits, {
                bedrooms: cheaperUnit?.bedrooms,
                text: getBedroomText(bedroom.bedrooms , cheaperUnit),
                price: cheaperUnit?.price
            }])
        })
    }, [units])

    const getBedroomText = (count: number ,cheaperUnit : UnitType) => {
        return count > 0
          ? count > 1
            ? `${count} ${translation.short_bedroom}`
            : `1 ${translation.short_bedroom}`
          : cheaperUnit.tipology === 'Local Comercial'? "Local" : translation.abbrStudio;
    };
    
    return (
        <Row>
            {
                cheaperUnits.map((unit, index) => (
                    <div className="d-flex" key={`cheaper-unit-${index}`}>
                        {propertyTypeId !== "3" && unit.text && 
                            <div className="text-dorms">
                                {isBlocked ? (
                                    <Placeholder xs={10} className="text-light rounded mb-1" />
                                ) : (
                                    <span className={"price text-secondary " + (index === 0 ? "fw-bold" : "")}>
                                        {unit.text} {" "}
                                    </span>
                                )}
                            </div>
                        }
                        <div className="text-dorms">
                            {isBlocked ? (
                                <Placeholder xs={12} className="text-light rounded mb-1" />
                            ) : (
                                <span className={"price text-secondary " + (index === 0 ? "fw-bold" : "")}>
                                    {currencyPrice} {formatMoney(unit.price)}
                                </span>
                            )}
                        </div>
                    </div>
                ))
            }
        </Row>
    )
}