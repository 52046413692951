import { useEffect, useState } from "react"

import { useFetch } from '../../hooks/fetch/useFetch'
import { RentGuarantee, RentGuaranteeStatus } from "../../interfaces/rentGuarantees"
import useUser from "../../hooks/user/useUser";
import { WarrantyFilter } from "../../components/User/WarrantyFilter";
import { EmptyWarranties } from "../../components/User/EmptyWarranties";
import { Dropdown, Modal } from "react-bootstrap"
import { WarrantyModal } from "../../components/FeedBenefitsModal/WarrantyModal";
import { IconArrowUp } from "../../components/CustomIcons/IconArrowUp";
import { IconArrowDown } from "../../components/CustomIcons/IconArrowDown";
import { IconFilter } from "../../components/CustomIcons/IconFilter"
import { IconOrder } from "../../components/CustomIcons/IconOrder"
import Lottie from "lottie-react";
import loaderIris from "../../lotties/orange-loader.json";
import useConfig from "../../hooks/config/useConfig";

export const UserRentGuarantees = () => {

    const { getUserRentGuarantees, getGuaranteesStatus } = useFetch()
    const { userState } = useUser();
    const { translation } = useConfig()
    const [guarantees, setGuarantees] = useState<RentGuarantee[]>([])
    const [totals, setTotals] = useState<{ [key: string]: any }>({
        total: 0,
        total_successfull: 0,
        total_rejected_insurance: 0,
        total_pending_contracts: 0,
        total_any: 0,
        total_commisions: 0
    })

    const initialFilter = {
        activeType: '*',
        currentPage: 1,
        searchName: '',
        startDate: '',
        endDate: '',
        insurance_carrier: '',
        order: 'date_desc'
    }
    const [totalPages, setTotalPages] = useState<any[]>([]);
    const [showModal, setShowModal] = useState(false)
    const [showModalOrder, setShowModalOrder] = useState(false)
    const [showWarranty, setShowWarranty] = useState(false)
    const [statusList, setStatusList] = useState<RentGuaranteeStatus[]>([])
    const [currentOrder, setCurrentOrder] = useState(initialFilter.order)

    const [loading, setLoading] = useState(true)

    const [filter, setFilter] = useState(initialFilter)


    const limit = 10
    const MAX_PAGES = 6

    const totalCards = [
        {
            title: 'Total de <strong>' + translation.warranty + 's</strong>',
            mobile_title: '<strong>Total</stron>',
            number: totals.total,
            class: 'total-full',
            step: '*'
        },
        {
            title: translation.warranty + 's <strong>finalizadas</strong> con éxito',
            mobile_title: '<strong>Finalizadas</stron>',
            number: totals.total_successfull,
            img: window.location.origin + '/images/success-small.png',
            class: 'total-successfull',
            step: '7'
        },
        {
            title: translation.warranty + 's <strong>rechazadas</strong> por aseguradora',
            mobile_title: '<strong>Rechazadas</stron>',
            number: totals.total_rejected_insurance,
            img: window.location.origin + '/images/error-small.png',
            class: 'total-rejected',
            step: '3B'
        },
        {
            title: translation.warranty + 's <strong>pendientes</strong> de contrato',
            mobile_title: '<strong>Contrato pendiente</stron>',
            number: totals.total_pending_contracts,
            img: window.location.origin + '/images/pending-small.png',
            class: 'total-pending',
            step: '4'
        },
        {
            title: 'Total de <strong>comisiones generadas</strong> aprox.',
            mobile_title: '<strong>Comisiones generadas</stron>',
            number: '$' + totals.total_commisions,
            img: '',
            class: 'total-commission'
        }
    ]


    const insuranceLogos: { [key: string]: string } = {
        'PORTO SEGURO': window.location.origin + '/images/warranty/porto-logo.png',
        'MAPFRE': window.location.origin + '/images/warranty/mapfre-logo.png',
        'SURA': window.location.origin + '/images/warranty/sura-logo.png',
        'AFFI': 'https://cdn1.infocasas.com.uy/web/64f20f6806898_logo-affi.png',
        'UNIFIANZA': 'https://cdn1.infocasas.com.uy/web/64f20fcc37838_logo-unifianza.png',
        'AFIARRIENDOS': 'https://cdn1.infocasas.com.uy/web/64f20fd01ab18_logo-afiarriendos.png',
        'FIANZAS BOGOTA' :'https://cdn1.infocasas.com.uy/web/6509b24fe4101_fianzas.jpeg',
        'ALUCERTO' : 'https://cdn1.infocasas.com.uy/web/660c280a16a05_asset-7@2x-(1).png'
    }

    const orderItems = [
        { name: "Actualización más reciente", type: "date_desc" },
        { name: "Actualización más antigua", type: "date_asc" },
    ]

    const formatDate = (date: string | null) => {
        let totalDate = date?.split('T')
        if (totalDate) {
            let parts = totalDate[0]?.split('-');
            return parts && parts.length == 3 ? `${parts[0]}/${parts[1]}/${parts[2]}` : ''
        } else return ''

    }

    const handlePickType = (type: string) => {
        if (type != filter.activeType) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setFilter(prev => { return { ...prev, activeType: type, currentPage: 1 } })
        }
    }

    const handlePagination = (page: number) => {
        if (page != filter.currentPage) {
            setFilter(prev => { return { ...prev, currentPage: page } })
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }

    }

    const formatMoney = (numero: number) => {
        return numero.toLocaleString("es-cl");
    }

    const loadGuarantees = async () => {

        await getUserRentGuarantees(filter.currentPage, limit, filter.activeType, filter.searchName,
            filter.startDate, filter.endDate, filter.insurance_carrier, filter.order).then(response => {

                setLoading(false)

                const data = response.data
                if (response && response.success && data && data.guarantees) {

                    let guaranteesData = data.guarantees?.map(g => {
                        return {
                            ...g,
                            status_updated_at: formatDate(g.status_updated_at)
                        }
                    })
                    if (guaranteesData && data.total_listed) {
                        setGuarantees(guaranteesData)
                        let total_pages = Math.ceil(data.total_listed / limit)
                        let gen = generatePagination(total_pages, filter.currentPage, MAX_PAGES);
                        setTotalPages(gen)

                    }

                } else {
                    setGuarantees([])
                    let gen = generatePagination(0, 0, MAX_PAGES);
                    setTotalPages(gen)

                }
                setTotals({
                    total: data?.total || 0,
                    total_successfull: data?.total_successfull || 0,
                    total_pending_contracts: data?.total_pending_contracts || 0,
                    total_rejected_insurance: data?.total_rejected_insurance || 0,
                    total_any: data?.total_any || 0,
                    total_commisions: (data?.total_commisions ? formatMoney(data.total_commisions) : 0) || 0
                })
            }).catch(err => {
                setLoading(false)
            })
    }

    const generatePagination = (totalPages: any, currentPage: any, visiblePages: any) => {
        const ellipsis = '...';
        let pagination = [];

        if (totalPages <= visiblePages) {
            pagination = Array.from({ length: totalPages }, (_, index) => index + 1);
        } else {
            const halfVisible = Math.floor(visiblePages / 2);
            const firstVisiblePage = Math.max(currentPage - halfVisible, 1);
            const lastVisiblePage = Math.min(currentPage + halfVisible, totalPages);

            if (firstVisiblePage > 1) {
                pagination.push(1);
                if (firstVisiblePage > 2) {
                    pagination.push(ellipsis);
                }
            }

            for (let i = firstVisiblePage; i <= lastVisiblePage; i++) {
                pagination.push(i);
            }

            if (lastVisiblePage < totalPages) {
                if (lastVisiblePage < totalPages - 1) {
                    pagination.push(ellipsis);
                }
                pagination.push(totalPages);
            }
        }

        return pagination;
    }

    const changeOrder = (order: string) => {
        setFilter(prev => { return { ...prev, order: order } })
        setShowModalOrder(false)
    }


    useEffect(() => {
        loadGuarantees()
    }, [filter])

    useEffect(() => {
        getGuaranteesStatus().then(response => {
            if (response && response.success && response.data) {
                setStatusList(response.data)
            } else {
                setStatusList([])
            }
        })
    }, [])

    if (loading) {
        return <main className="user-guarantees main container p-3 p-sm-5">
            <div style={{ height: 400 }} className="w-100 d-flex align-items-center justify-content-center">
                <Lottie animationData={loaderIris} style={{ height: 100 }} loop /></div>
        </main>
    }

    if (!loading && totals.total_any == 0) {
        return <main className="user-guarantees main container p-3 p-sm-5">
            <EmptyWarranties />
        </main>
    }

    return (
        <main className="user-guarantees main container p-3 p-sm-5">

            <div className="nav-types">
                <div className="d-none d-md-flex mb-4 flex-wrap justify-content-between">
                    {
                        <>

                            {totalCards.map((card, index) =>
                                <div className={`total-guarantees border border-1 rounded p-1 p-sm-2 flex-1 
                        text-white d-flex flex-column justify-content-between ${card.class ? card.class : ''} ${filter.activeType == card.step ? 'active' : ''} `
                                }
                                    key={index}
                                    onClick={() => card.step ? handlePickType(card.step) : null}
                                >
                                    <h5 className="mb-0 show-desktop text-capitalize" dangerouslySetInnerHTML={{ __html: card.title }}></h5>
                                    {/* <h5 className="mb-0 show-mobile" dangerouslySetInnerHTML={{__html : card.mobile_title}}></h5> */}

                                    <div className="d-flex justify-content-between align-items-end">
                                        {card.img && <img src={card.img} height={100} width={100} alt="Success" />}
                                        {!card.img && <div></div>}
                                        <p className="number-guarantees fw-bold"> {card.number}</p>
                                    </div>
                                    <h5 className="mb-0 show-mobile" dangerouslySetInnerHTML={{ __html: card.mobile_title }}></h5>

                                </div>
                            )
                            }
                        </>

                    }

                </div>
                <div className=" btn-filter mt-2 d-flex w-100 justify-content-between">
                    <div className="d-flex w-50 justify-content-start me-1">
                        <div className=" me-1">
                            <button className="btn color-primary  bg-white w-100 text-primary" onClick={() => setShowModal(true)}><IconFilter width={23} /> </button>
                        </div>
                        <div className="me-1">
                            <button className="btn color-primary bg-white w-100 text-primary" onClick={() => setShowModalOrder(true)}><IconOrder width={25} /></button>
                        </div>
                    </div>
                    <div className="w-50 ">
                        <button className="btn btn-primary w-100" onClick={() => setShowWarranty(true)}>Derivar {translation.warranty}</button>
                    </div>

                </div>
            </div>
            {
                totals.total_any > 0 && !loading &&
                <>
                    <div className="d-none d-sm-block">
                        <WarrantyFilter filter={filter} setFilter={setFilter} waitApply={false}
                            closeModal={(() => setShowModal(false))}
                            showModalWarranty={() => setShowWarranty(true)} insuranceCarriers={Object.keys(insuranceLogos)}
                            statusList={statusList} setShowModalOrder={setShowModalOrder} />
                    </div>
                    <ul className="table-guarantees p-0">
                        <li className="bg-dark text-white">
                            <div><h6>Nombre del cliente</h6> </div>
                            <div><h6>Aseguradora</h6> </div>
                            <div><h6>Tipo</h6> </div>
                            <div className="d-flex align-items-center"  >
                                <h6>Fecha actualización</h6>
                                {filter.order == 'date_desc' &&
                                    <span onClick={() => changeOrder('date_asc')}>
                                        <IconArrowDown width={23} /></span>}
                                {filter.order == 'date_asc' && <span onClick={() => changeOrder('date_desc')}>
                                    <IconArrowUp width={23} /></span>}
                            </div>
                            <div><h6>Estado</h6></div>
                            <div><h6>Mensaje</h6></div>
                            <div><h6>Ver Póliza</h6> </div>
                        </li>
                        {
                            guarantees && guarantees.length > 0 &&
                            guarantees.map(guarantee =>
                                <li className={`status-type-${guarantee.status.step}`} key={guarantee.id}>
                                    <div>{guarantee.client_name}</div>
                                    {
                                        guarantee.insurance_carrier && insuranceLogos[guarantee.insurance_carrier] &&
                                        <div className="d-flex d-sm-block justify-content-between align-items-center ">
                                            <img src={insuranceLogos[guarantee.insurance_carrier]} alt="" width={100} />
                                            <span className="d-block d-sm-none status-guarantee"> {guarantee.status.name}</span>
                                        </div>
                                    }
                                    {
                                        (!guarantee.insurance_carrier || !insuranceLogos[guarantee.insurance_carrier]) &&
                                        <div></div>
                                    }
                                    <div>{guarantee.type}</div>
                                    <div>{guarantee.status_updated_at}</div>
                                    <div className="d-none d-sm-flex"><div>{guarantee.status.name}</div></div>
                                    <div>{guarantee.status.message}</div>
                                    <div >{guarantee.policy_pdf && <a className="d-flex align-items-center w-100 justify-content-center" href={guarantee.policy_pdf} target="_blank">
                                        <span className="d-inline-block d-sm-none me-2">Ver póliza</span>
                                        <i className="bi bi-eye fs-5" title="Ver Póliza" ></i>
                                    </a>}
                                    </div>
                                </li>

                            )
                        }

                    </ul>
                </>
            }


            {
                (!guarantees || guarantees.length == 0) && totals.total_any > 0 &&
                <div className="gx-6 info-no-warranties ">
                    <p className="text-center msg">Ninguna garantía coincide con el filtro</p>
                </div>

            }

            {
                totalPages.length > 0 && !loading &&
                <div className="guarantees-pagination">
                    <ul className="d-flex justify-content-center align-items-center px-0">
                        <li className={`m-2 list-unstyled rounded p-2 pagination-move ${filter.currentPage <= 1 ? 'disabled' : ''}`} title="Anterior"
                            onClick={() => handlePagination(filter.currentPage - 1)}
                        >
                            <i className="bi bi-chevron-left"></i> </li>


                        {totalPages && totalPages.map((i, index) => {
                            if (i != '...') return <li className={`m-2 list-unstyled rounded p-2 px-3 ${filter.currentPage == i ? 'active' : ''}`}
                                onClick={() => handlePagination(i)} key={index}>{i}</li>
                            else return i
                        }
                        )
                        }

                        <li className={`m-2 list-unstyled rounded p-2 pagination-move ${filter.currentPage >= totalPages.length ? 'disabled' : ''}`}
                            title="Siguiente"
                            onClick={() => handlePagination(filter.currentPage + 1)}>
                            <i className="bi bi-chevron-right"></i> </li>
                    </ul>
                </div>
            }


            <Modal
                show={showModal}
                className={` w-100 border-0 modal-warranty`}
                backdropClassName="property-modal-backdrop"
                centered
                onHide={() => setShowModal(false)}

            >
                <Modal.Body>
                    <WarrantyFilter filter={filter} setFilter={setFilter} waitApply={true}
                        closeModal={() => setShowModal(false)}
                        showModalWarranty={() => setShowModal(true)}
                        insuranceCarriers={Object.keys(insuranceLogos)}
                        statusList={statusList}
                        setShowModalOrder={setShowModalOrder} />
                </Modal.Body>
            </Modal>

            <Modal
                show={showModalOrder}
                className={` w-100 border-0 modal-warranty`}
                backdropClassName="property-modal-backdrop"
                centered
                onHide={() => setShowModalOrder(false)}

            >
                <Modal.Body>
                    <h3 style={{ textAlign: "center" }}>Ordenar</h3>
                    {
                        orderItems.map((o, i) => {
                            return <Dropdown.Item
                                onClick={() => changeOrder(o.type)}
                                key={`estate-${i}`}
                                className={`d-flex align-items-center px-2 py-2 rounded-1 ${filter.order == o.type ? "bg-sm-light" : ""
                                    }`}
                            >
                                <div
                                    className="rounded-circle border-light border d-flex align-items-center justify-content-center me-2"
                                    style={{ width: 17, height: 17 }}
                                >
                                    {filter.order == o.type && (
                                        <div
                                            className="rounded-circle bg-primary d-flex align-items-center justify-content-center"
                                            style={{ width: 10, height: 10 }}
                                        ></div>
                                    )}
                                </div>
                                {o.name}
                            </Dropdown.Item>
                        }

                        )
                    }

                </Modal.Body>
            </Modal>

            <WarrantyModal showWarranty={showWarranty} setShowWarranty={setShowWarranty} />


        </main>
    )
}