import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import useConfig from "../../hooks/config/useConfig"
import { Countdown } from "./Countdown";
import { useCookies } from "../../hooks/cookies/useCookies"
import { PopupData } from "../../interfaces/ui";
import { getDateYMD } from "../../utils/Functions";
import { useGoogleAnalytics } from '../../hooks/googleAnalytics/useGoogleAnalytics';


const getWindowSize = () => {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

export const ImageModal = ({ cookieName, link, desktopImg, mobileImg, clickModal , endDate , name }:
    PopupData) => {

    const [modal, setShowModal] = useState(false);
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const { country } = useConfig()
    const { getCookie, setCookie } = useCookies()
    const GA = useGoogleAnalytics()

    const now = new Date();
    const nowDate = new Date(getDateYMD())
    const time = now.getTime();
    const expireTime = time + 86400 * 1000; //24h
    now.setTime(expireTime);

    const end_date = endDate ? new Date(endDate) : null
    const validDateToShow = !end_date || end_date > nowDate ? true : false

    const expires = '=ok;expires=' + now.toUTCString() + ';path=/;SameSite=none;Secure';

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        if (!getCookie(cookieName) && validDateToShow) {
            // setCookie(cookieName, expires)
            setShowModal(true);
            
        } else {
            setShowModal(false)
        };

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };


    }, []);

    const closeModal = () => {
        setShowModal(false)
        setCookie(cookieName, expires)
    }

    const nameEvent = name.toLowerCase().split(' ').join('_')

    const clickImage = () => {
        GA.Event({category : 'Click Popup', action : `click_popup`, label : name })
        if (link && link != '') { window.open(link) }
        else if (clickModal != null) { clickModal() }
    }

    return (
        <Modal
            show={modal}
            size={windowSize.innerWidth > 991 ? "lg" : "sm"}
            onHide={closeModal}
            centered
            contentClassName="border-0 modal-container"
        >
            <Modal.Body className="popup-container" >
                <img src={
                    windowSize.innerWidth > 768 ?
                        desktopImg
                        : mobileImg
                } className="banner-promo" alt="preheader-img"
                    style={{ cursor: (link && link != '') ? 'pointer' : 'auto' }}
                    onClick={clickImage} />
            </Modal.Body>
        </Modal>
    )
}