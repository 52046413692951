export const IconParkCustom = ({ width = 20 }) => {
  return (
    <svg fill="#000000" height={20} width={width} version="1.1" id="Layer_1" 
	 viewBox="0 0 512 512">
<g>
	<g>
		<path d="M495.304,478.609h-26.45l-49.826-50.087l49.826-50.087h26.45c9.22,0,16.696-7.475,16.696-16.696
			s-7.475-16.696-16.696-16.696c-8.669,0-191.4,0-200.348,0c-9.22,0-16.696,7.475-16.696,16.696s7.475,16.696,16.696,16.696h27.146
			l49.826,50.087l-49.826,50.087h-88.363v-145.94c5.505,0.815,11.081,1.244,16.696,1.244c64.442,0,116.87-52.428,116.87-116.87
			c0-45.193-26.416-86.026-66.889-105.201C297.775,49.723,246.411,0,183.652,0C121.05,0,69.791,49.476,66.915,111.378
			C26.812,130.341,0,171.898,0,217.043c0,64.442,52.428,116.87,116.87,116.87c5.614,0,11.191-0.431,16.696-1.244v145.94H16.696
			C7.475,478.609,0,486.084,0,495.304S7.475,512,16.696,512c12.215,0,473.334,0,478.609,0c9.22,0,16.696-7.475,16.696-16.696
			S504.525,478.609,495.304,478.609z M116.87,300.522c-46.03,0-83.478-37.448-83.478-83.478c0-46.197,38.626-83.478,83.478-83.478
			c11.276,0,22.207,2.204,32.489,6.554c8.494,3.593,18.288-0.382,21.88-8.874c3.592-8.492-0.382-18.289-8.874-21.88
			c-14.419-6.098-29.726-9.19-45.495-9.19c-5.117,0-10.184,0.332-15.19,0.979c7.376-38.538,41.316-67.762,81.972-67.762
			c45.999,0,83.428,37.402,83.478,83.388c-0.091,1.113-0.419,2.516-0.569,5.042c-0.446,7.457,4.117,14.305,11.173,16.764
			c33.602,11.707,56.179,43.237,56.179,78.458c0,46.03-37.448,83.478-83.478,83.478c-20.321,0-40.07-7.709-55.608-21.707
			c-6.351-5.721-15.998-5.722-22.349,0C156.939,292.813,137.19,300.522,116.87,300.522z M200.348,478.609h-33.391V322.365
			c5.788-2.797,11.378-6.065,16.696-9.816c5.318,3.751,10.908,7.019,16.696,9.816V478.609z M369.202,378.435h52.552l-26.276,26.414
			L369.202,378.435z M369.202,478.609l26.276-26.414l26.277,26.414H369.202z"/>
	</g>
</g>
</svg>

  );
};

