import { useEffect, useState } from "react";
import { Promo } from "../../interfaces/promo";
import { PromoContext } from "./PromoContext";
import { usePromoFetch } from "../../hooks/fetch/usePromoFetch";
import useConfig from "../../hooks/config/useConfig";


interface props {
  children: JSX.Element | JSX.Element[];
}


export const PromoProvider = ({ children }: props) => {

  const [countryPromo, setCountryPromo] = useState<Promo>()
  const { getCountryPromo } = usePromoFetch()
  const { config } = useConfig()

  useEffect(() => {
    if (config && config.country) {
      getCountryPromo(config.country.id).then(response => {
        if (response.success) {
          setCountryPromo(response.data)
        } else {
          setCountryPromo(undefined)
        }
      })
    }

  }, [config])

  return (
    <PromoContext.Provider
      value={{
        countryPromo: countryPromo
      }}
    >

      {children}
    </PromoContext.Provider>
  )
};
