import { GetNotarialsRequest, GetNotarialsResponse } from "../../../../../interfaces/fetch/admin"
import { useFetchAdapter } from "../../../useFetchAdapter"

export const useNotarialFetch = () => {

    const { postRequest } = useFetchAdapter()

    const getNotarials = async ( data : GetNotarialsRequest) => {
        
        let result : GetNotarialsResponse = {
            success : false,
            data : [],
            message : "",
            total : 0,
            total_filter : 0
        }

        const urlPath = `/api/admin/services/notarial/get-notarials` 

        result = await postRequest(urlPath , data)

        return result
    }

    const exportNotarials = async ( data : GetNotarialsRequest ) => {

        const urlPath = `/api/admin/services/notarial/export-excel` 
        let result = { 
            success : false,
            data : ''
        }
        
        await postRequest(urlPath, data, true ).then( res => {
            result.success = true
            result.data = res
        })

        return result

    }

    return {
        getNotarials,
        exportNotarials
    }
}