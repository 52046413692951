import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Navigate, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { Tabs } from "../../components/Tabs/Tabs";
import { FinancialSection } from "../../fragments/detail/FinancialSection";
import useUser from "../../hooks/user/useUser";
import useBreakpoint from "use-breakpoint";
import { BREAKPOINTS } from "../../utils/Constants";
import "../../fragments/academy/Courses.scss";
import Lottie from "lottie-react";
import loaderIris from "../../lotties/loader-iris.json";
import { useGoogleAnalytics } from "../../hooks/googleAnalytics/useGoogleAnalytics";
import { CourseTopSection } from "../../fragments/academy/CourseTopSection";
import { CourseDetailHeader } from "../../fragments/academy/CourseDetailHeader";
import { CourseResumeSection } from "../../fragments/academy/CourseResumeSection";
import { useAcademyFetch } from "../../hooks/fetch/useAcademyFetch";
import { Course } from "../../interfaces/academy";
import { CourseFinancialSection } from "../../fragments/academy/CourseFinancialSection";

export const CourseDetail = () => {
    
  const { isLoggedIn, userState } = useUser();
  const { id = "" } = useParams();
  const { pathname, search } = useLocation();
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "xs");
  const GA = useGoogleAnalytics(true);


  const {getCourseByIdentifierEndpoint} = useAcademyFetch()

  const [ course , setCourse] = useState<Course>()
  const [ courseLoading , setCourseLoading] = useState(true)

  const [activeTab, setActiveTab] = useState<
    "RESUME" | "UNITS" | "LOCATION" | "FINANCIAL"
  >("RESUME");


  useEffect(() => {
    
    getCourseByIdentifierEndpoint(parseInt(id)).then(async (result) => {

        if(result.success && result.data){
            setCourse(result.data)
            setCourseLoading(false)
          
          }else{
            setCourseLoading(false)
        }

    })

    GA.Event({ category: "User open course", action: "user_open_course", label: `${course?.name}`, value: Number(userState.id) });

    
  }, []);

  if (!isLoggedIn)
    return <Navigate to="/iniciar-sesion" state={{ from: pathname, search: search }} />;
  if ((course == null && courseLoading)) { //loading to share
    return <div className="position-fixed">
      <div className="lottie-container vw-100 vh-100 d-flex align-items-center justify-content-center 
      position-fixed w-100 bg-white" >
        <Lottie animationData={loaderIris} style={{ height: 100 }} loop />
      </div>
    </div>
  } else if (course == null && !courseLoading) {
    return <Navigate to="/404" state={{ from: pathname }} />;
  }

  const hasPayment = course?.link_type == "PAGO" ? true : false

  const tabs = [
    { title: "Resumen", value: "RESUME", show : true },
    { title: "Financiación", value: "FINANCIAL", show : hasPayment},
  ];

  const tabsToShow = tabs.filter(t => !!t.show)

  const onTabChange = (x: any) => {
    window.open(pathname + (search || '') + "#" + x, "_self")

    setActiveTab(x)
  }

  return (
    <main className="detail course-detail">
      {course !== null && course !== undefined && !courseLoading && (
        <>
          <CourseDetailHeader course={course}  />

          <div className="bg-xs-light detail-container">
            <Container className="position-relative content">
              <Row className="d-flex align-items-start">
                <Col lg="12" xl="12" className={"py-3 " + (breakpoint === "xs" ? "px-4" : "")}>
                  <div className="detail-content">
                    <Row className="gx-0 gy-4">
                      <Col lg="12">
                        <CourseTopSection course={course} setActiveTab={setActiveTab} />
                      </Col>
                      <Col lg="12" className="border-bottom">
                        <Tabs
                          options={tabsToShow}
                          value={activeTab}
                          onChange={onTabChange}
                        />
                        <div id="RESUME"><CourseResumeSection course={course} /></div>
                        { hasPayment && <div id="FINANCIAL"><CourseFinancialSection course={course} /></div>}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </>

      )}
    </main>
  );
};

