import { Col, Row } from "react-bootstrap";
import { OperationType, PropertyType } from "../../interfaces/property";
import { Units } from "./Units";
import { Garages } from "./Garages";
import { Floors } from "./Floors";
import { Warehouses } from "./Warehouses";
import { ProjectType } from "../../interfaces/project";

export const UnitsSection = ({ property, operation }: { property: ProjectType, operation?: OperationType }) => {
  const unitsRent = property?.units?.filter((unit) => unit.operation_type === "Alquiler");
  const unitsSale = property?.units?.filter((unit) => unit.operation_type === "Venta");
  const activeGarages = property?.garages?.filter((garage) => garage.active);
  const activeWarehouses = property?.warehouses?.filter((warehouse) => warehouse.active);

  return (
    <Row className="pt-4 gy-4">
      {
        property.units && property.units.length > 0 &&
        <Col xs={12} className="pt-2">
          {!!unitsRent?.length && (!operation || operation === 'Alquiler') && <Units units={unitsRent} property={property} operationType="Alquiler" />}
          {(!operation || operation === 'Venta') && <Units units={unitsSale} property={property} operationType="Venta" />}
        </Col>
      }
      

      {(!operation || operation === 'Venta')  && property.garages?.length != null && property.garages?.length > 0 && (
        <>
          <Col xs={12}>{<hr className="mb-1 text-light-secondary" />}</Col>
          <Col xs={12}>
            <Garages garages={activeGarages} property={property} />
          </Col>
        </>
      )}

      {(!operation || operation === 'Venta') && property.warehouses?.length != null && property.warehouses?.length > 0 && (
        <>
          <Col xs={12}>{<hr className="mb-1 text-light-secondary" />}</Col>
          <Col xs={12}>
            <Warehouses warehouses={activeWarehouses} property={property} />
          </Col>
        </>
      )}

      {property.floors_list?.length != null && property.floors_list?.length > 0 && (
        <>
          <Col xs={12}>{<hr className="mb-1 text-light-secondary" />}</Col>
          <Col xs={12}>
            <h3 className="fs-4 fw-normal mb-3">Pisos o plantas</h3>
            <Floors floors={property.floors_list} property={property} />
          </Col>
        </>
      )}
      <Col xs={12}>{<hr className="mb-0 text-light-secondary" />}</Col>
    </Row>
  );
};
