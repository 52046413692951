
import { ListSales } from '../../../fragments/admin/sales/ListSales'

const AdminSales = () =>{
    return (
        <main>
            <ListSales/>
        </main>
    )
}
export default AdminSales