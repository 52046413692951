import { useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { PostType } from "../../../interfaces/post";
import { cleanHtmlFromString } from "../../../utils/Functions";
import dayjs from "dayjs";
import { google, outlook, yahoo } from "calendar-link";
import { useGoogleAnalytics } from "../../../hooks/googleAnalytics/useGoogleAnalytics";
import useUser from "../../../hooks/user/useUser";

export const FeedPostDescription = ({
  post,
  collapse = true,
}: {
  post: PostType;
  collapse?: boolean;
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [showTooltip, setshowTooltip] = useState(false);
  const {Event} = useGoogleAnalytics()
  const {userState} = useUser()

  const formatCalendarDate = (date: string) => {
    
    try {
      const finalDate = dayjs(date, "YYYY-MM-DD hh:mm:ss");
      if (finalDate.isValid()) { 
        return finalDate.format("MM/DD/YYYY hh:mm:ss") 
       }
      return date
    } catch (error) {
      console.log(error)
      return date
    }
    
  }


  const addEvent = (type: string) => {
    
    const event: any = {
      title: post.details?.title,
      description: post.details?.description,
      // @ts-ignore
      start: formatCalendarDate(post.details?.date),
      duration: [3, 'hours'],
      location: post.details?.country?.name
    };

    let url;

    if (type == "google") url = google(event)
    else if (type == "outlook") url = outlook(event)
    else url = yahoo(event)

    Event({ category: "User add event to calendar", action: "user_add_calendar", label: `Evento : ${post.details?.title}`, value: Number(userState.id) });

    window.open(url, "_blank");
  }

  const popover = (
    <Popover id="popover-calendar" className="shadow-sm border-0">
      <Popover.Header as="h3" className="bg-white border-0 pb-0 pe-1 d-flex justify-content-end">
        <button onClick={() => setshowTooltip(false)} className="border-0 bg-transparent">
          <i className="bi bi-x-lg text-light-secondary"></i>
        </button>
      </Popover.Header>
      <Popover.Body className="pt-0 pb-2">
        <button className="bg-white border-0 d-block text-primary" onClick={() => addEvent("google")}>Google Calendar</button>
        <button className="bg-white border-0 d-block text-primary" onClick={() => addEvent("yahoo")}>Yahoo Calendar</button>
        <button className="bg-white border-0 d-block text-primary" onClick={() => addEvent("outlook")}>Outlook Calendar</button>
      </Popover.Body>
    </Popover>
  );

  return (
    <div className={`post-description ${collapse ? "" : "expand"}`}>
      <div className="mt-3">
        <p
          className="lh-sm fw-light"
          dangerouslySetInnerHTML={{
            __html: cleanHtmlFromString(post.details?.description, true),
          }}
        ></p>
        {collapse && (
          <button onClick={
            post.details ?
              post.type === "Proyecto"
                ? () => navigate(`/proyecto/${post.details?.identifier}`, { state: { from: pathname } })
                : () => navigate(`/feed/${post.identifier}`, { state: { from: pathname } })
              : () => navigate(`/feed`)
          } className="text-primary fs-sm fw-500 text-nowrap text-decoration-none border-0 bg-transparent">Leer más</button>
        )}
      </div>

      {
        post.type === "Evento" &&
        <OverlayTrigger placement="top" show={showTooltip} overlay={popover}>
          <button onClick={() => setshowTooltip(!showTooltip)} className="text-primary mt-3 fw-bold border-0 bg-white"><i className="bi bi-calendar-week me-2"></i><span>Añadir al calendario</span></button>
        </OverlayTrigger>
      }
    </div >
  );
};
