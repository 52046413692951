import { Col, Row } from "react-bootstrap";
import { FloorType } from "../../interfaces/project";
import { CustomImage } from "../../components/CustomImage/CustomImage";

export const FloorModalContent = ({ floor: f }: { floor: FloorType }) => {
  return (
    <div className="floor-modal-content bg-white w-100 h-100 rounded-3 text-secondary px-5 py-4">
      <Row className="d-flex flex-lg-nowrap h-100">
        <Col
          xl="3"
          lg={{ order: 1, span: 5 }}
          xs={{ order: 2, span: 12 }}
          className="flex-shrink-1 mt-lg-3 mt-xl-5 pb-3 pb-lg-0"
        >
          <span className="fw-500 text-center text-lg-start d-block">
            Unidades de vivienda
          </span>
          <h2 className="mt-0 fw-bold text-center text-lg-start">{f?.name}</h2>
          <p>{f?.description}</p>
        </Col>
        <Col
          xl="9"
          lg={{ order: 2, span: 7 }}
          xs={{ order: 1, span: 12 }}
          className="image flex-grow-1 h-100 py-4 py-lg-0"
        >
          <CustomImage
            src={f?.plan}
            alt="plano"
            classes="w-100 h-100"
            styles={{ objectFit: "contain" }}
            width={400}
            height={400}
          />
        </Col>
      </Row>
    </div>
  );
};
