import { useImage } from "../../hooks/image/useImage";

export interface GetImagenOptimizadaProps {
    src: string;
    width?: number;
    height?: number;
    resize?: string;
    alt?: string;
    classes?: string;
    styles?: object
}

export const CustomImage = ({ src, width, height, resize, alt, classes, styles }: GetImagenOptimizadaProps) => {
    const newSrc = useImage({ src, width, height, resize })
    
    return (
        <img src={newSrc} alt={alt} className={classes} style={styles ?? {}} />
    )
};