import { useEffect, useReducer, useState } from "react";
import PropertiesContext from "./PropertiesContext";
import { PropertiesReducer } from "./PropertiesReducer";

interface props {
  children: JSX.Element | JSX.Element[];
}

export const INITIAL_STATE = [];
export const PROPERTIES_DATA = "properties_data";

export const PropertiesProvider = ({ children }: props) => {
  
  const getIntialState = () => {
    const propertiesData = window.localStorage.getItem(PROPERTIES_DATA);
    if (propertiesData) {
       const properties = JSON.parse(propertiesData);
      if (properties) return properties;
    }
    return INITIAL_STATE;
  };

  const [propertiesState, dispatch] = useReducer(PropertiesReducer, getIntialState());
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const setProperties = (properties: any) => {
    dispatch({ type: "setProperties", payload: properties });
    setIsLoading(false);
  };

  return (
    <PropertiesContext.Provider
      value={{
        propertiesState,
        setProperties,
        isLoading
      }}
    >
      {children}
    </PropertiesContext.Provider>
  );
};
