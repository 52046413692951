export const IconQuiet = ({ width = 20 }) => {
  return (
    <svg width={width} height={width} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.5 17.1667C13.7344 17.1667 17.1667 13.7344 17.1667 9.5C17.1667 5.2656 13.7344 1.83333 9.5 1.83333C5.2656 1.83333 1.83333 5.2656 1.83333 9.5C1.83333 13.7344 5.2656 17.1667 9.5 17.1667ZM9.5 18.125C14.2634 18.125 18.125 14.2634 18.125 9.5C18.125 4.7366 14.2634 0.875 9.5 0.875C4.7366 0.875 0.875 4.7366 0.875 9.5C0.875 14.2634 4.7366 18.125 9.5 18.125Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M5.99947 11.4167C6.74458 13.2529 8.11739 14.2917 9.49979 14.2917C10.8827 14.2917 12.255 13.2529 13.0001 11.4167H5.99947ZM9.49979 15.25C7.56491 15.25 5.88783 13.7895 5.06366 11.6563C5.0556 11.6357 5.04761 11.6151 5.0397 11.5945C4.826 11.0238 5.28312 10.4584 5.8931 10.4584H13.107C13.7169 10.4584 14.1745 11.0238 13.9599 11.5945C13.9521 11.6151 13.9443 11.6357 13.9364 11.6563C13.1117 13.7895 11.4347 15.25 9.49979 15.25Z" fill="currentColor" />
      <path d="M8.20638 8.7535C8.26867 8.58196 8.24999 8.37256 8.19872 8.18186C8.137 7.96223 8.03814 7.75479 7.90642 7.56852C7.62803 7.17129 7.16659 6.80665 6.5432 6.77981C5.89776 6.75298 5.41572 7.16267 5.11863 7.59727C4.97595 7.80646 4.86381 8.03493 4.78561 8.27577C4.71709 8.48948 4.68068 8.70798 4.70463 8.88288C4.71014 8.92275 4.7256 8.9606 4.7496 8.99292C4.7736 9.02524 4.80535 9.051 4.84193 9.06781C4.87851 9.08462 4.91873 9.09194 4.95888 9.08909C4.99904 9.08625 5.03783 9.07334 5.07168 9.05154C5.26334 8.92888 5.46411 8.76069 5.65003 8.60544C5.73436 8.53452 5.8163 8.46648 5.89201 8.40658C6.1613 8.19383 6.37117 8.07261 6.54463 8.06398C6.70995 8.05583 6.90065 8.15023 7.1412 8.3304C7.22745 8.39461 7.31082 8.46265 7.39707 8.53356L7.49051 8.60975C7.60743 8.70415 7.73201 8.80094 7.85755 8.87665C7.88783 8.89491 7.92178 8.90624 7.95696 8.90983C7.99214 8.91342 8.02768 8.90917 8.06103 8.8974C8.09438 8.88563 8.1247 8.86662 8.14984 8.84174C8.17497 8.81686 8.19428 8.78673 8.20638 8.7535Z" fill="currentColor" />
      <path d="M10.752 8.7535C10.6897 8.58196 10.7089 8.37256 10.7602 8.18186C10.8138 7.98252 10.913 7.76833 11.0524 7.56852C11.3304 7.17129 11.7923 6.80665 12.4157 6.77981C13.0606 6.75298 13.5432 7.16267 13.8402 7.59727C13.9907 7.81769 14.1028 8.05631 14.1733 8.27577C14.2413 8.48948 14.2777 8.70798 14.2538 8.88288C14.2482 8.92268 14.2327 8.96045 14.2088 8.99271C14.1848 9.02496 14.1531 9.05068 14.1166 9.06748C14.0801 9.08427 14.04 9.09162 13.9999 9.08883C13.9598 9.08605 13.921 9.07323 13.8872 9.05154C13.6955 8.92888 13.4943 8.76069 13.3088 8.60544C13.224 8.53452 13.1426 8.46648 13.0669 8.40658C12.7971 8.19383 12.5877 8.07261 12.4138 8.06398C12.2484 8.05583 12.0577 8.15023 11.8177 8.3304C11.7314 8.39461 11.6481 8.46265 11.5618 8.53356L11.4679 8.60975C11.351 8.70415 11.2269 8.80094 11.1013 8.87665C11.071 8.895 11.037 8.9064 11.0018 8.91004C10.9666 8.91368 10.931 8.90945 10.8975 8.89767C10.8641 8.8859 10.8338 8.86686 10.8086 8.84193C10.7834 8.817 10.7641 8.7868 10.752 8.7535Z" fill="currentColor" />
    </svg>
  );
};

