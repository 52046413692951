import { useFetch } from "./useFetch"



export const useFetchAdapter = () => {

    const { authFetch } = useFetch()

    const postRequest = async ( urlPath : string , body : object , expectArrayBuffer : boolean = false) : Promise<any> => {

        let result = {
            message : ''
        }

        urlPath = `${process.env.REACT_APP_AUTH_API_URL}${urlPath}`

        await authFetch(urlPath,
            {
                cache: 'no-cache',
                method : 'POST',
                body : JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache'
                },
            },
            expectArrayBuffer
        )
        .then((response) => {
                if (response.error) {
                    if (response.message && response.message !== '') {
                        result.message = response.message
                    }
                } else result = response

        }).catch( err => {
            console.log(err)
        })

        return result 
    }

    return  {
        postRequest
    }
}