import { useState } from "react"
import { ProjectType, UnitType } from "../../../interfaces/project"
import { formatMoneyDecimals, formatDateToDMY } from "../../../utils/Functions"

const today = formatDateToDMY(new Date().toISOString(),'/')
export const Payment = ({ project, pie, pie_amount, financing_percentage, financing_amount, currency ,exchange_rate,
    total
}:
    { project: ProjectType,
        pie: number, 
        pie_amount: number, 
        financing_percentage : number, 
        financing_amount : number , 
        currency : string,
        exchange_rate? : number,
        total : number
    }) => {

        
    
    return (
        <>
            
            <p className="fw-bold">III. FORMAS DE PAGO</p>
            <div className="px-4 mt-4">
                    <table style={{ width: '100%' }}>
                        <thead>
                            <tr style={{ borderBottom : '1px solid black' }}>
                                <th className="pb-3"></th>
                                <th className="pb-3 text-center" >PORCENTAJES</th>
                                <th className="pb-3 text-center">VALOR EN {currency}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td >Pie</td>
                                <td style={{ textAlign: 'right' }}>{formatMoneyDecimals(pie)} %</td>
                                <td style={{ textAlign: 'right' }}>{formatMoneyDecimals(pie_amount)}</td>
                            </tr>
                            <tr>
                                <td className="pb-2">Monto de financiación con banco</td>
                                <td className="pb-2" style={{ textAlign: 'right' }}>{formatMoneyDecimals(financing_percentage)} %</td>
                                <td className="pb-2" style={{ textAlign: 'right' }}>{formatMoneyDecimals(financing_amount)}</td>
                            </tr>
                            <tr style={{ borderTop: '1px solid black'}}>
                                <td>TOTAL</td>
                                <td style={{ textAlign: 'right' }}>{formatMoneyDecimals(100)} %</td>
                                <td style={{ textAlign: 'right' }}>{formatMoneyDecimals(total)}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="d-flex w-100" style={{marginTop : 30}}>
                        <p style={{fontWeight : 'bold'}}>Bono Pie:</p> <p>{project.pie_bonus ? 'Sí' : 'No'}</p>
                        </div>
                    {/* {
                        !!project.pie_bonus && 
                        <>
                         <p>Condiciones de Bono Pie : </p>
                            {
                                project.pie_bonus_conditions && 
                                <p>{project.pie_bonus_conditions}</p>
                            }
                        </>
                    } */}
                   
                    {
                        !!exchange_rate && 
                        <p className="mt-4">* Los montos en UF corresponden al valor de la UF de $ {formatMoneyDecimals(exchange_rate)} al {today}, por lo cual solo se citan de referencia </p>
                    }

                    <div className="border rounded-2 px-2 pb-3 pt-0" style={{marginTop : 40}}>
                        <p style={{fontStyle : 'italic' , textAlign : 'center'}}>La presente cotización no es considerado como una reserva, por lo que no asegura la disponibilidad de las unidades cotizadas al momento de proceder a la compra.</p>
                    </div>
            </div>

        </>

    )
}