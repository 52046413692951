import { Col, Row } from "react-bootstrap";
import { IconGarage } from "../CustomIcons/IconGarage";
import { IconCheckCircle } from "../CustomIcons/IconCheckCircle";
import { useState } from "react";
import './PropertyAmenities.scss'

interface amenitiesType {
  title: string,
  icon: JSX.Element,
  show: boolean
}

export const PropertyAmenities = ({ amenities, extra_amenities = [], isInPdf = false }: { amenities: amenitiesType[], extra_amenities?: string[], isInPdf?: boolean }) => {

  const extraAmenities = extra_amenities ? extra_amenities.map(am => ({ title: am, icon: <IconCheckCircle width='20' />, show: true })) : []

  const totalAmenities = amenities.concat(extraAmenities)
  const [expand, setExpand] = useState(false)

  return (

    <Row className={`amenities gx-2 gy-2 ${expand ? 'expand' : 'not-expand'} ${isInPdf ? 'inpdf' : 'notpdf'}`}>
      {totalAmenities
        .filter((a) => a.show)
        .map((a, index) => (
          <Col xs={"auto"} key={"amenitie_" + index}>
            <div
              className={"px-3 py-1 fw-500 fs-sm d-flex align-items-center " + (!isInPdf ? "rounded-pill border" : "")}
              style={{ height: 35 }}
            >
              {a.icon}
              <span className="ms-2">{a.title}</span>
            </div>
          </Col>
        ))}
      {
        totalAmenities.length > 20 && !isInPdf &&
        <span
          role={"button"}
          onClick={() => setExpand(!expand)}
          className="more bg-xs-light fw-light d-flex align-items-baseline justify-content-between span-expand"
        >
          {!expand && "... "}
          <span className="text-primary fs-sm fw-500">
            {expand ? "Ver menos" : "Ver todos"}
          </span>
        </span>
      }
    </Row>


  );
};
