
import { Filter } from '../../interfaces/filter'

type FilterAction =
  | { type: 'setFilter'; payload: Filter }
  | { type: 'removeFilter' }

export const FilterReducer = (state: Filter, action: FilterAction) => {
  switch (action.type) {
    case 'setFilter':
      return { ...state, ...action.payload }

    //Limpiar filtros excepto país que se elige en dropdown aparte
    case 'removeFilter':
      let filterClear: Filter = {country : state.country}
      return filterClear

    default:
      return state
  }
}
