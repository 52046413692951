import { ReactNode, useMemo, useState } from "react";
import { Button, Carousel, Col, Container, Row } from "react-bootstrap";
import { IconWheelChair } from "../../components/CustomIcons/IconWheelChair";
import { GalleryArrow } from "../../components/GalleryArrow/GalleryArrow";
import { PropertyModal } from "../../components/PropertyModal/PropertyModal";
import { UnitsTable } from "../../components/UnitsTable/UnitsTable";
import { WarehouseType, ProjectType } from "../../interfaces/project";
import { formatMoney } from "../../utils/Functions";
import { GarageModalContent } from "./GarageModalContent";
import useCountry from "../../hooks/config/useConfig";
import { WarehouseModalContent } from "./WarehouseModalContent";

export const Warehouses = ({
  warehouses = [],
  property
}: {
  warehouses?: WarehouseType[];
  property: ProjectType;
}) => {
  const [garageSorter, setGarageSorter] = useState("title");
  const [warehouseExpand, setWarehouseExpand] = useState<number | null>(null);
  const { translation } = useCountry();

  const columns: { text: ReactNode; name: string; visible: boolean }[] = [
    { text: translation.warehouse, name: "title", visible: true },
    { text: "Precio", name: "price", visible: true },
    { text: "Ubicación", name: "location", visible: true },
    { text: "", name: "action", visible: true },
  ];

  const data: any[] = useMemo(() => {
    const res: any[] = [];
    warehouses
      .sort((a, b) => {
        if (garageSorter === "price") return parseFloat(a.price) - parseFloat(b.price);
        else if (garageSorter === "location") {
          if (a.location < b.location) return -1;
          if (a.location > b.location) return 1;
          return 0;
        } else if (garageSorter === "dimension")
          return parseFloat(a.width) * parseFloat(a.depth) - parseFloat(b.width) *  parseFloat(b.depth);
        else if (garageSorter === "height") return parseFloat(a.height) - parseFloat(b.height);
        else {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        }
      })
      .forEach((w) => {
        res.push({
          id: w.id,
          title: (
            <div className="d-inline-flex flex-column">
              <span
                className="fs-xxs fw-bold d-lg-none mb-1 mb-lg-0"
                children={translation.warehouse}
              />
              {w.name}
            </div>
          ),
          price: (
            <div className="d-inline-flex flex-column">
              <span
                className="fs-xxs fw-bold d-lg-none mb-1 mb-lg-0"
                children={"Precio"}
              />
              <span className="fw-bold text-secondary">
                {w.currency} {formatMoney(w.price)}
              </span>
            </div>
          ),
          location: (
            <div className="d-inline-flex flex-column">
              <span
                className="fs-xxs fw-bold d-lg-none mb-1 mb-lg-0"
                children={"Ubicación"}
              />
              {w.location}
            </div>
          ),
          action: (
            <i className="bi bi-chevron-right text-primary fs-5"></i>
          ),
        });
      });
    return res;
  }, [warehouses, garageSorter]);

  const handleWarehouseModal = (id: any) => {
    setWarehouseExpand(warehouses.findIndex((warehouse) => warehouse.id === id));
  };

  return (
    <>
      <Row className="gy-3 align-items-end">
        <Col lg={12} xs={"auto"} className="flex-grow-1">
          <h3 className="fs-4 fw-normal mb-0 mt-0 d-flex flex-column flex-lg-row align-items-lg-end align-items-start">
            {translation.warehouses}
            <span
              className="note ms-lg-2 mt-2 mt-lg-0 fs-xxs fw-500 bg-secondary text-white px-1 rounded-1 position-relative text-uppercase"
              style={{ bottom: 3, padding: "2px 0px" }}
            >
              {`${property.warehouses?.filter((g) => g.active)?.length
                } Disponible`}
            </span>
          </h3>
        </Col>
        <Col xs="12">
          <UnitsTable
            data={data}
            columns={columns}
            onSort={(s) => setGarageSorter(s)}
            className="warehouses"
            handleModal={handleWarehouseModal}
          />
        </Col>
      </Row>
      <PropertyModal
        show={warehouseExpand !== null}
        onClose={() => setWarehouseExpand(null)}
        property={property}
        className="property-modal-warehouses"
      >
        <Container className="h-100">
          {warehouseExpand !== null && (
            <Carousel
              className="carousel-100 h-100"
              activeIndex={warehouseExpand}
              onSelect={setWarehouseExpand}
              interval={null}
              indicators={false}
              wrap={false}
              controls={warehouses.length > 1}
              nextIcon={
                <GalleryArrow
                  direction="right"
                  customClass="border shadow property-gallery-arrow"
                />
              }
              prevIcon={
                <GalleryArrow
                  direction="left"
                  customClass="border shadow property-gallery-arrow"
                />
              }
            >
              {warehouses.map((warehouse, index) => (
                <Carousel.Item key={"floor-modal-item_" + index}>
                  <WarehouseModalContent warehouse={warehouse} />
                </Carousel.Item>
              ))}
            </Carousel>
          )}
        </Container>
      </PropertyModal>
    </>
  );
};
