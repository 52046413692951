import { Badge, Card, OverlayTrigger, ProgressBar, Image } from "react-bootstrap"

import useUser from '../../../hooks/user/useUser'
import { AUTH_VALUES } from "../../../utils/Constants"
import { useUserFetch } from "../../../hooks/fetch/useUserFetch"
import { useEffect, useState } from "react"
import { useLevel } from "../../../hooks/level/useLevel"
import "./UserLevel.scss"
import { IconArrowDown } from "../../../components/CustomIcons/IconArrowDown"

const UserLevel = () => {

    const { userState } = useUser()
    const { level, nextSteps, totalPercentage, hiddenMore, setHiddenMore } = useLevel()

    const checkMobile = () => {
        return window.innerWidth < 600
    }
    const [isMobile, setIsMobile] = useState(checkMobile())
    const newSteps = nextSteps?.filter(s => s.new)

    useEffect(() => {
        function handleWindowResize() {
            setIsMobile(checkMobile())
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };

    }, []);

    return <>
        <Card className="gy-1 border-0 p-3 mb-3 mt-3 user-level">
            <div className="d-flex align-items-center">
                <Image width={60} height={60} src={level?.icon || 'https://cdn1.infocasas.com.uy/web/62bdd922446a2_user-no-image.png'} roundedCircle fluid />
                <div className="ms-2 text-break">
                    <h6 className="m-0 text-secondary fw-bold fs-6">{userState?.name}</h6>
                    {/* <span className="fs-xs text-light-secondary d-block me-2 lh-sm">{userState?.email}</span> */}
                    <Badge bg="primary" className="fs-xxs lh-1">NIVEL {level?.name}</Badge>
                </div>
            </div>
            {/* <div className="border-top mt-2 mb-1 pb-1"></div> */}
            <div className="mt-2">

                {level && parseInt(level?.name) < (AUTH_VALUES.ADMIN_LEVEL - 1)
                    ? <span className="d-block text-secondary fs-sm">
                        <span className="me-1">Tu próximo nivel:</span>
                        <span className="text-primary me-1">Nivel {parseInt(level?.name) + 1}</span>
                    </span>
                    : ''
                }
                <span className="percentage d-block text-end text-secondary fw-bold fs-sm">{totalPercentage}%</span>
                <div className="container-progress-bar position-relative">
                    <div className="progress-dot bg-primary rounded-circle position-absolute shadow" style={{ marginLeft: `${totalPercentage - 3}%` }}></div>
                    <ProgressBar now={totalPercentage} variant="primary" key={1} />
                </div>
                {
                    newSteps && newSteps?.length > 0 &&
                    <>
                        <div className={`mt-2 next-steps pb-4 ${hiddenMore && !isMobile ? 'hidden-half' : ''}`} >
                            <p className="header-subtitle text-light-secondary" >Cumple los siguientes objetivos:</p>
                            {
                                newSteps?.map((s, i) => (
                                    <div className="mt-2">
                                        <label htmlFor="">{s.short_label}</label>

                                        <div className="container-progress-bar position-relative">
                                            {/* <div className="progress bg-primary rounded-circle position-absolute shadow" style={{ marginLeft: `${s.percentage - 3}%` }}></div> */}
                                            <ProgressBar striped now={s.percentage} variant="primary" key={1} />
                                        </div>
                                        <span className="float-end">{s.user_interaction} / {s.next}</span>
                                    </div>
                                ))
                            }



                        </div>

                        {
                            hiddenMore && !isMobile && <button onClick={() => setHiddenMore(false)} className="btn btn-white btn-more bg-white position-absolute">
                                Ver más <IconArrowDown width={14} />
                            </button>
                        }

                    </>
                }
                {
                    (!newSteps || newSteps.length == 0) && level && parseInt(level?.name) == AUTH_VALUES.TOP_GAMIFICATION_LEVEL &&
                    <p >¡Has alcanzado el máximo nivel!</p>
                }


            </div>
        </Card>




    </>
}

export default UserLevel