import { interfaceData, interfacePosts, interfaceUsers , interfaceObjectData, listProjects, projectByIdentifier, interfacePostDetail, generalDataResult, GetUsersResponse, GetCurrenciesResponse} from "../../interfaces/apiTypes";
import {StatusGuaranteesResponseType, User, UserGuaranteesResponseType, UserInfoResponseType } from "../../interfaces/user";
import useUser from "../user/useUser";
import { LS_ACCESS_TOKEN, LS_REFRESH_TOKEN } from '../../context/User/UserProvider'
import useAlert from "../../hooks/alert/useAlert";
import { useNavigate } from "react-router-dom";
import { PostType } from "../../interfaces/post";
import { RankingProjectsType } from "../../interfaces/ranking";
import rankingProjectsData from '../../data/rankingProperties.json'
import { CountryCodesType } from "../../interfaces/config";
import useConfig from '../../hooks/config/useConfig'
import { ProjectType } from "../../interfaces/project";
import useFilter from "../../hooks/filter/useFilter"
import { Filter } from "../../interfaces/filter";
import { AlertVariants } from "../../context/Alert/AlertContext";

let isRefreshing = false;
let lastRefreshTime = new Date().getTime()
let failedQueue :any = [];

export const useFetch = () => {
    const { setTypeGlobalAlert, setShowGlobalAlert, setMessageGlobalAlert } = useAlert()
    const { logoutUser, userState, accessToken, refreshToken, saveAccessToken, saveRefreshToken} = useUser()
    const navigate = useNavigate()
    const {config , allCountries} = useConfig()
    const {filterState} = useFilter()
    const processQueue = (error : any, token = null) => {
        failedQueue.forEach((prom : any) => {
            if (error) {
                prom?.reject(error);
            } else {
                prom?.resolve(token);
            }
        });

        failedQueue = [];
    };

    const authFetch = async (url: string, options: RequestInit, isArrayBuffer? : boolean): Promise<UserInfoResponseType | interfaceData | interfacePosts
        | interfaceUsers | any> => {

        let access_token = window?.localStorage.getItem(LS_ACCESS_TOKEN) || ''
        // if (!access_token || access_token === '') {
        //     requireLogin()
        //     return {error :true};
        // }

        let response = await makeCall(url, options, access_token,false,isArrayBuffer)
        if (response && response.error) {
            response.message = ''

            if (response.error === 'invalid_token' && response.error_description.includes('expired')) {

                //Refresh token
                if (isRefreshing) {
                    return addToQueue(url,options)
                }

                isRefreshing = true;

                return new Promise(function(resolve, reject) {
                    requestRefreshToken()
                    .then((data) => {
                        /* axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.fooToken;
                        originalRequest.headers['Authorization'] = 'Bearer ' + data.fooToken; */
                        if(data.error && data.error === 'no_token'){
                            requireLogin()
                            resolve(false)
                        }else if(data.access_token){                            
                            processQueue(null, data.access_token);
                            resolve(makeCall(url,options,data.access_token,true));
                        }else if (data && data.error && ( data.error_description?.includes('refresh token has expired') ||
                        data.error_description?.includes('refresh token is invalid'))) {
                            //El refreshToken ya venció o no es válido
                            requireLogin()
                            resolve(false)
                        }
                    })
                    .catch(err => {
                        processQueue(err, null);

                        reject(err);
                    })
                    .then(() => {
                        isRefreshing = false;
                    });
                        
                });
                    
            } else if (response.error === 'invalid_token') {
                
                let originalHeaders  = options.headers as {'Authorization' : string};
                let originalToken  = originalHeaders['Authorization'];
                let currentAccessToken = localStorage.getItem('accessToken') || ''
                let now = new Date().getTime();
                let diff = now - lastRefreshTime;
                //Está refrescando  
                if(isRefreshing){
                    //pendingRequests.push({url,options})
                    return addToQueue(url,options)
                }else if(originalToken !== currentAccessToken && diff < 300000){
                    //es un request que se lanzó antes de que se refresque y retornó después , se valida que sea RECIENTE con máximo 5 min tardío  (diferencia desde que se llamó a refrescar ) para que no se deje pasar un request con token invalido y antiguo que quizá sea erroneo
                    let _response = await makeCall(url, options, currentAccessToken,true)
                    return _response;
                }else{
                    requireLogin()
                }                
            } else if (response.error === 'no_token') {
                requireLogin()
            } else {
                if(response.error == 'aborted_response'){
                    response.message = null
                }else{
                    response.message = response.error_description ? response.error_description : 'Ocurrió un error'
                }
                
            }

        }
        return response;

    }

    const addToQueue = (url : string,options : any) =>{
        return new Promise(function(resolve, reject) {
            failedQueue.push({ resolve, reject });
        })
            .then(async token=> {
                if(token && typeof token==='string'){
                    return await makeCall(url,options,token,true);
                }else{
                    return Promise.reject(false);    
                }
                
            })
            .catch(err => {
                return Promise.reject(err);
            });
    }


    const makeCall = async (url: string, options: RequestInit, access_token: string, isRetry = false , isArrayBuffer = false) => {
        if (options.headers) {
            options.headers = {
                ...options.headers,
                'Authorization': access_token != '' ? 'Bearer ' + access_token : ''
            }
        } else {
            options.headers = {
                'Authorization':  access_token != '' ? 'Bearer ' + access_token : ''
            }
        }

        let response = await fetch(
            url,
            options
        ).then(data => {
            if(data){
                if(isArrayBuffer && data.status == 200){
                    return data.arrayBuffer()
                }else{
                    return data.json()
                }
            }else{
                return false
            }
            
        }).catch(err => {
            return {
                error : err.toString()
            }
        })

        

        if(isRetry && response && response.error && response.error === 'invalid_token'){
            requireLogin()
            return {error : true};
        }else{
            if(!response) return {error : 'no_response'}
            if(response.error && (response.error.includes('AbortError') || response.error.includes('NetworkError'))){
                response.error = 'aborted_response'
            }
            return response
        }
        
    }

    const requestRefreshToken = async () => {
        let result = {
            error: 'no_token',
            access_token: ''
        }

        let refresh_token = window?.localStorage.getItem(LS_REFRESH_TOKEN)

        if (refresh_token && refresh_token !== '') {

            const CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID || ''

            const response = await fetch(
                process.env.REACT_APP_AUTH_API_URL + '/api/auth/refresh-token',
                {
                    method: "POST",
                    body: JSON.stringify({
                        'grant_type': 'refresh_token',
                        'refresh_token': refresh_token,
                        'client_id': CLIENT_ID
                    }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            ).then(data => data.json())

            if (response.access_token) {
                saveAccessToken(response.access_token)
                if (response.refresh_token) {
                    saveRefreshToken(response.refresh_token)
                }
            }
            return response;
        } else {
            return result;
        }
    }



    const getListNotifications = async ({ userId, level }: any) => {
        let result: any = {
            success: false,
            data: [],
            message: '',
            goLogin: false
        }

        try {
            let domain =window?.location.hostname // 'https://iris.infocasas.com.uy'// window?.location.hostname 
            let params: RequestInit = {
                method: "POST",
                body : JSON.stringify({
                    user_id : userId,
                    domain : domain
                    //country : country                    
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache'
                },
            }
    
            const response = await authFetch(
                process.env.REACT_APP_AUTH_API_URL + '/api/notifications/list-user-notifications',
                params
            )
            

            if (response.error) {
                if (response.message && response.message !== '') {
                    result.message = response.message
                }
            } else {
                let setTypeNotifications = response.data.map((noti:any)=>{
                    return {
                        ...noti,
                        type : 'manual'
                    }
                })
                result.data = setTypeNotifications
                result.success = response.success
                result.message = response.message
            }
            return result;

        } catch (error) {
            console.log(error)
            return result;
        }
    }
  

    const markViewedNotifications = async (
        userId: string,
        notificationId: string,
        clicked : boolean
    ) => {

        let result: any = {
            success: false,
            data: '',
            message: '',
            goLogin: false
        }

        try {

            let params: RequestInit = {
                method: "POST",
                body : JSON.stringify({
                    user_id : userId,
                    notification_ids : notificationId,
                    clicked : clicked               
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache'
                },
            }
    
            const response = await authFetch(
                process.env.REACT_APP_AUTH_API_URL + '/api/notifications/set-view-notification',
                params
            )
            

            if (response.error) {
                if (response.message && response.message !== '') {
                    result.message = response.message
                }
            } else {
                result.data = response.data
                result.success = response.success
                result.message = response.message
            }
            return result;


        } catch (error) {

            console.log(error)
            return result;
        }
    }


    const trackEventUserOld = async (
        eventName: string,
        userid: string,
        usercrm?: boolean
    ) => {
        if(!usercrm) usercrm = false
        const ENDPOINT = `${process.env.REACT_APP_URL_ENDPOINT}/apps/iris/trackEvents.php`
        const sendData = { eventName, userid, usercrm }

        const res = await authFetch(ENDPOINT, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(sendData),
        }).catch(err => {
            console.error(err)
        })

        return res
    }

    const trackEventUser = async (
        action: string,
        user_id: string,
        category ? : string,
        value?: string,
        label?: string,
    ) => {
        
        const ENDPOINT = `${process.env.REACT_APP_AUTH_API_URL}/api/user/track-event`
        const sendData = {  action, user_id,category, value , label}

        const res = await authFetch(ENDPOINT, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(sendData),
        }).catch(err => {
            console.error(err)
        })

        return res
    }

    const getUserDetail = async (): Promise<UserInfoResponseType> => {
        let params: RequestInit = {
            method: "POST"
        }

        const response = await authFetch(
            process.env.REACT_APP_AUTH_API_URL + '/api/user/get-user-info',
            params
        )
        return response;
    }

    const getUserBasicInfo = async (user_id:string): Promise<any> => {
        let params: RequestInit = {
            method: "POST",
            body : JSON.stringify({user_id:user_id , country_web : config.country.code}),
            headers: {
                'Content-Type': 'application/json'
            },
        }

        const response = await fetch(
            process.env.REACT_APP_AUTH_API_URL + '/api/user/get-user-basic',
            params
        ).then(data=>data.json())
        return response;
    }

    const getUserRentGuarantees= async (page? : number, limit? : number, step? : string,
         search_name? : string, start_date? : string,
        end_date? : string, insurance_carrier? : string, order? : string): Promise<UserGuaranteesResponseType> => {
        let params: RequestInit = {
            method: "POST",
            body : JSON.stringify({page,limit,step,search_name,start_date,end_date,insurance_carrier,order}),
            headers: {
                'Content-Type': 'application/json'
            },
        }

        const response = await authFetch(
            process.env.REACT_APP_AUTH_API_URL + '/api/user/list-rent-guarantees',
            params
        )
        return response;
    }

    const getGuaranteesStatus = async (): Promise<StatusGuaranteesResponseType> => {
        let params: RequestInit = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            },
        }

        const response = await authFetch(
            process.env.REACT_APP_AUTH_API_URL + '/api/rent-guarantees/guarantees-status-types',
            params
        ).catch(err=> console.log(err))
        return response;
    }

    const getListPropertySimilars = async (identifier: string, countryId : string): Promise<ProjectType[]> => {

        const similarIds : any = {
            '1' : ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
            '2' : ['114', '115', '116', '117', '118', '119', '120', '122', '123'],
            '10' : ['247', '248', '249', '250', '251', '252', '253', '254', '269'],
        }

        if(similarIds[countryId] && similarIds[countryId].length>0){

            const properties = await getListProjects({},9,1,similarIds[countryId]).then((prop) => {
                if (prop.success) {
                    return prop.data.filter((property) => property.identifier !== identifier).slice(0, 8)
                } else {
                    return []
                }
            }
            )
            return properties
        }else{
            return []
        }
        
    }

    const getListHighlights = async (country_id : number): Promise<listProjects> => {

        let filter = {
            order : ['relevance'],
            country : [country_id]
        }

        const properties = await getListProjects(filter,8,1,[],'sale').then((prop) => {
                return prop
            }
            )
            return properties
        
    }

    const listRankingProjects = async (
        country?: CountryCodesType,
        number?: number,

    ): Promise<RankingProjectsType[]> => {
        let response: RankingProjectsType[] = rankingProjectsData.ranking
            .filter((r) => r.country === country)
            .slice(0, number ?? 3)
        //@ts-ignore
        const ids: string[] = response.map((r) => r.projectID)
        if(ids.length>0){
            
            const propertiesData = await getListProjects({},9,1,ids)

            if (propertiesData.success) {
                
                response.forEach((ranking) => {
                    if (typeof ranking.projectID !== 'undefined') {
                        //@ts-ignore
                        ranking['project'] = getRankingProject(ranking.projectID, propertiesData.data)
                    }
                })
                
                return response
            } else {
                return []
            }
        }else{
            return []
        }
        
    }
    const getRankingProject = (
        id: string,
        properties: ProjectType[],
    ): ProjectType | null => {
        let res = properties.find((p) => {
            return p.identifier == id
        })
        return res ?? null
    }

    const getAdminNotifications = async ( page : number,limit : number ) => {

        let result: any = {
            success: false,
            data: [],
            message: '',
            goLogin: false
        }

        try {
            let params: RequestInit = {
                method: "POST",
                body : JSON.stringify({
                    page ,
                    limit                    
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache'
                },
            }
    
            const response = await authFetch(
                process.env.REACT_APP_AUTH_API_URL + '/api/notifications/list-push-notifications',
                params
            )
            

            if (response.error) {
                if (response.message && response.message !== '') {
                    result.message = response.message
                }
            } else {
                result.data = response.data
                result.success = response.success
                result.message = response.message
            }
            return result;

        } catch (error) {
            console.log(error)
            return result;
        }


    }

    const sendPushNotification = async ( {title,message,link,image,country} : {title : string,message : string, link? : string, image? : string, country?: string }) => {

        let result: any = {
            success: false,
            data: [],
            message: '',
            goLogin: false
        }


        try {
            let params: RequestInit = {
                method: "POST",
                body : JSON.stringify({
                    title,
                    message,
                    country ,
                    image,
                    link ,
                    is_test : window?.location.hostname.includes('localhost') || 
                    window?.location.hostname.includes('127.0.0.1') ,
                    show_in_panel : 1                  
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache'
                },
            }
    
            const response = await authFetch(
                process.env.REACT_APP_AUTH_API_URL + '/api/notifications/send-push-notification',
                params
            )
            

            if (response.error) {
                if (response.message && response.message !== '') {
                    result.message = response.message
                }
            } else {
                result.data = response.data
                result.success = response.success
                result.message = response.message
            }
            return result;

        } catch (error) {
            console.log(error)
            return result;
        }


    }

    const logout = async (user: User, skipRequestLogout = false): Promise<boolean> => {
  
        if(!skipRequestLogout){
            await authFetch(
                process.env.REACT_APP_AUTH_API_URL+'/api/auth/logout',    
                {
                    method: "POST"
                }
            )
              
        }
        
        logoutUser(user)
        navigate('/iniciar-sesion')
        return true
        
    }

    //Nuevo backend:

    const getListProjects: (filterData : Filter, limit : number , page : number, identifiers? : string[] ,operation?: 'sale' | 'rent') => Promise<listProjects> =
    async (filterData, limit,page , identifiers, operation?: 'sale' | 'rent' ) => {

        let result: listProjects = {
            success: false,
            data: [],
            message: '',
            goLogin: false,
            total_listed : 0,
            total : 0
        }

        let operationParsed
        if (operation) operationParsed = operation === 'sale' ? 'Venta' : 'Alquiler'

        const url = `${process.env.REACT_APP_AUTH_API_URL}/api/projects/get-projects`

        let order = filterData.order ? filterData.order : ['promos','popularity']
        
        let min_date = filterData.hand_over_date && filterData.hand_over_date[0] ? filterData.hand_over_date[0] : undefined
        let max_date = filterData.hand_over_date && filterData.hand_over_date[1] ? filterData.hand_over_date[1] : undefined
        
        filterData = {
            country : filterData.country,
            department_id : filterData.location,
            zone_id : filterData.neighbourhood,
            commune_id : filterData.commune,
            project_type : filterData.propertyType,
            min_price : filterData.minPrice ? filterData.minPrice : undefined,
            max_price : filterData.maxPrice ? filterData.maxPrice : undefined,
            currency_minmax : filterData.currency_minmax,
            project_status : filterData.state,
            rooms : filterData.rooms,
            bathrooms : filterData.bathrooms,
            amenities : filterData.amenities,
            search : filterData.search,
            financing : filterData.financing,
            min_handover_date : min_date,
            max_handover_date : max_date,
            subsidy : filterData.subsidy,
            social_housing : filterData.social_housing,
            pie_bonus : filterData.pie_bonus ? true : undefined,
            units_rented : filterData.units_rented,
            operation_type : operationParsed,
            identifiers :  identifiers,
            level : userState?.level,
            service_room : filterData.service_room ? true : undefined,
            developers : filterData.developers,
            is_promo : filterData.is_promo == true ? true : undefined,
            
        }
        
        try {
            let body = {
                limit : limit,
                page : page,
                filter : filterData,
                order : order
            }
            await authFetch(url,
                {
                    cache: 'no-cache',
                    method : 'POST',
                    body : JSON.stringify(body),
                    headers: {
                        'Content-Type': 'application/json',
                        'Cache-Control': 'no-cache'
                    },
                }
            )
                .then((properties) => {

                    if (properties.error) {
                        if (properties.message && properties.message !== '') {
                            result.message = properties.message
                        }
                    } else if(properties.success && properties.data){
                        
                        result = properties
                    }

                })

            return result
        } catch (error) {
            console.log(error)
            return result
        }
    }

    const getProjectByIdentifier = async (identifier: string, operationType? : string) => {
        let result: projectByIdentifier = {
            success: false,
            data: undefined,
            message: '',
            goLogin: false
        }

        const url = `${process.env.REACT_APP_AUTH_API_URL}/api/projects/get-project`

        const body = {
            identifier : identifier,
            operation_type : operationType
        }
        
        try {
            await authFetch(
                url,
                {
                    cache: 'no-cache',
                    method : 'POST',
                    body : JSON.stringify(body),
                    headers: {
                        'Content-Type': 'application/json',
                        'Cache-Control': 'no-cache'
                    },
                },
            )
                .then((property) => {
                    
                    if(!property){
                        return result
                    }
                    if (property.error) {
                        if (property.message && property.message !== '') {
                            result.message = property.message
                        }
                    } else {
                        result = property
                    }

                })
        } catch (error) {
            console.log(error)
            result.message = 'Ocurrió un error'
        }

        return result
    }

    const getPublicProjectByIdentifier = async (identifier: string, hash_id : string , operationType? : string) => {
        let result: projectByIdentifier = {
            success: false,
            data: undefined,
            message: '',
            goLogin: false
        }

        const url = `${process.env.REACT_APP_AUTH_API_URL}/api/projects/get-project-public`

        const body = {
            identifier : identifier,
            operation_type : operationType,
            hash_id : hash_id
        }
        
        try {
            await authFetch(
                url,
                {
                    cache: 'no-cache',
                    method : 'POST',
                    body : JSON.stringify(body),
                    headers: {
                        'Content-Type': 'application/json',
                        'Cache-Control': 'no-cache'
                    },
                },
            )
                .then((property) => {
                    
                    if(!property){
                        return result
                    }
                    if (property.error) {
                        if (property.message && property.message !== '') {
                            result.message = property.message
                        }
                    } else {
                        result = property
                    }

                })
        } catch (error) {
            console.log(error)
            result.message = 'Ocurrió un error'
        }

        return result
    }
    
    const getListPosts = async (): Promise<interfacePosts> => {
        let result: interfacePosts = {
            success: false,
            data: [],
            message: '',
            goLogin: false
        }

        try {

            const url = `${process.env.REACT_APP_AUTH_API_URL}/api/posts/get-posts`
            let body = {
                country_id : config.country.id
            }
            const response = await authFetch(url,
                {
                    cache: 'no-cache',
                    method : 'POST',
                    body : JSON.stringify(body),
                    headers: {
                        'Content-Type': 'application/json',
                        'Cache-Control': 'no-cache'
                    },
                },
            )

            if (response.error) {

                if (response.message && response.message !== '') {
                    result.message = response.message
                }
                return result

            } else {
                let list: PostType[] = response.data;

                list = list
                    .filter((post) => post.details !== undefined)
                    .sort(
                        //@ts-ignore
                        (a, b) => new Date(b.details.publish_date)?.getTime() - new Date(a.details.publish_date)?.getTime(),
                    )


                    result = response
                    
                return result
            }

        } catch (error) {
            console.log(error)
            return result
        }
    }
    
    const getPostDetail = async (
        id: string,
    ): Promise<interfacePostDetail> => {


        const url = `${process.env.REACT_APP_AUTH_API_URL}/api/posts/get-post`
            let body = {
                identifier : id
            }
            const response = await authFetch(url,
                {
                    cache: 'no-cache',
                    method : 'POST',
                    body : JSON.stringify(body),
                    headers: {
                        'Content-Type': 'application/json',
                        'Cache-Control': 'no-cache'
                    },
                },
            ).catch(err => {
                console.log(err)
                return false
            })
            
            
            return response


    }

    const getAllProperties: (operation?: 'sale' | 'rent') => Promise<interfaceObjectData> =
        async (operation?: 'sale' | 'rent') => {

            let result: interfaceObjectData = {
                success: false,
                data: [],
                message: '',
                goLogin: false
            }

            let operationParsed
            if (operation) operationParsed = operation === 'sale' ? 'Venta' : 'Alquiler'
            

            const url = `${process.env.REACT_APP_AUTH_API_URL}/api/projects/get-projects-basic`
        

            try {

                const res = await authFetch(url,
                    {
                        cache: 'no-cache',
                        method : 'POST',
                        body : JSON.stringify({}),
                        headers: {
                            'Content-Type': 'application/json',
                            'Cache-Control': 'no-cache'
                        },
                    }
                )
                    .then((properties) => {

                        if (properties.error) {
                            if (properties.message && properties.message !== '') {
                                result.message = properties.message
                            }
                        } else {                           
                            result = properties
                        }

                    })

                return result
            } catch (error) {
                console.log(error)
                return result
            }

    }

    const getCurrencies: () => Promise<GetCurrenciesResponse> =
        async () => {

            let result: GetCurrenciesResponse = {
                success: false,
                data: [],
                message: ''
            }


            const url = `${process.env.REACT_APP_AUTH_API_URL}/api/auxiliary/get-currencies`
        

            try {

                const res = await authFetch(url,
                    {
                        cache: 'no-cache',
                        headers: {
                            'Content-Type': 'application/json',
                            'Cache-Control': 'no-cache'
                        },
                    }
                )
                    .then((properties) => {

                        if (properties.error) {
                            if (properties.message && properties.message !== '') {
                                result.message = properties.message
                            }
                        } else {                           
                            result = properties
                        }

                    })

                return result
            } catch (error) {
                console.log(error)
                return result
            }

    }
    

    const showGlobalAlert = (type: AlertVariants, message: string) => {
        setTypeGlobalAlert(type)
        setMessageGlobalAlert(message)
        setShowGlobalAlert(true)
        setTimeout(() => {
            setShowGlobalAlert(false)
            setTypeGlobalAlert('success')
            setMessageGlobalAlert('')
        }, 3000);

    }

    const requireLogin = () => {
        showGlobalAlert('danger', 'Necesita iniciar sesión')
        setTimeout(() => {
            logout(userState, true)

            navigate('/iniciar-sesion')
        }, 3000);
    }

    return {
        authFetch,
        getListNotifications,
        getListPosts,
        getPostDetail,
        markViewedNotifications,
        trackEventUser,
        getUserDetail,
        getListPropertySimilars,
        listRankingProjects,
        getAdminNotifications,
        getAllProperties,
        sendPushNotification,
        getUserBasicInfo,
        getUserRentGuarantees,
        logout,
        getGuaranteesStatus ,
        getListProjects,
        getListHighlights,
        getProjectByIdentifier,
        getPublicProjectByIdentifier,
        getCurrencies,
        
    }
}