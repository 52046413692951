import { countriesFeatures, filtersByCountry } from '../data/countriesFeatures';
import { CountryFeatures, FiltersByCountry } from '../interfaces/config';

export const formatMoney = (value: any) => {
  if(value != null) return value.toLocaleString("es-uy")
  else return ''
};

export const formatMoneyDecimals = (value: any) => {
  if(value) return value.toLocaleString("es-uy",{minimumFractionDigits: 2,maximumFractionDigits: 2})
  else return ''
};

export const uniqueFromArray = (arr: any[] = []) => {
  return arr?.filter((v, i) => arr.indexOf(v) === i);
};

export const excludeFromArray = (arr: any[] = [], exclude: string) => {
  return arr?.filter((v, i) => v !== exclude);
};

export const excludeFromArrayNumber = (arr: any[] = [], exclude: number) => {
  return arr?.filter((v, i) => v !== exclude);
};

export function hasSomeParentTheClass(
  element: any,
  classname: string
): boolean {
  if (
    typeof element.className !== "undefined" &&
    element.className.length > 0 &&
    element.className.split(" ").indexOf(classname) >= 0
  )
    return true;
  return (
    element.parentNode && hasSomeParentTheClass(element.parentNode, classname)
  );
}

export const cleanHtmlFromString = (
  html: string,
  keepLineBreaks: boolean = false
) => {
  if (html) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    
    if (keepLineBreaks) {
      html = html.replace(/<br>/g, "$br$");
      html = html.replaceAll('\n','$br$').replaceAll('\n\n','$br$$br$').replaceAll('\r\n\r\n','$br$$br$').replaceAll('\n\r\n','$br$$br$')
    }
    html = html.replace(/<\/?[^>]+(>|$)/g, "");
    html = html.replace(/\$br\$/g, " <br/>");
    html = html
      .replace(/&gt;/g, ">")
      .replace(/&lt;/g, "<")
      .replace(/&nbsp;/g, "\u00A0");
    //Add link tag
    html = html.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
  }
  
  return html;
};

export const cleanJumpsHtmlFromString = (html: string) => {
  if (html) {
    html = html.replace(/<p><br[/]?><[/]?p>/g, "");
    html = html.replace(/[/]?h[1-6]>/g, "p>");
  }

  return html;
};

export const validateYoutubeVideo = (url : string) => {
  const youtubeRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  const youtubeShortsRegex = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;

  if (youtubeRegex.test(url) || youtubeShortsRegex.test(url)) {
    return true
  } else {
    return false
  }
}


export const validateDriveFile = (url : string) => {
  var driveLinkRegex =/^https?:\/\/drive\.google\.com\/(?:file\/d\/|open\?id=)([\w-]+)(?:\/view)?(.)*$/;
  return driveLinkRegex.test(url);
}

export const getEmbedLinkFromGoogleDriveLink = (link : string) =>  {
  const linkParts = link.match(/\/(?:file\/d\/|open\?id=)([\w-]+)(?:\/view)?(?:\/open)?/)
  // Extract file ID from the link
  var fileId =  linkParts ? linkParts[1] : null;
  if(fileId){
    return `https://drive.google.com/file/d/${fileId}/preview`;
  }else return null
  
}

export const getDownloadLinkFromGoogleDriveLink = (link : string) =>  {
  const linkParts = link.match(/\/(?:file\/d\/|open\?id=)([\w-]+)(?:\/view)?(?:\/open)?/)
  // Extract file ID from the link
  var fileId =  linkParts ? linkParts[1] : null;
  if(fileId){
    return `https://drive.usercontent.google.com/download?id=${fileId}&confirm=xxx`;
  }else return null
  
}


export const getCountryFeatures = (countryCode : string )=> {
  let countriesConfigs = countriesFeatures as {[key : string] : CountryFeatures}
  let country = countriesConfigs[countryCode] ? countriesConfigs[countryCode]: null
  return country
}

export const getFiltersByCountry = (countryCode : string )=> {
  let filtersByCountryData = filtersByCountry as {[key : string] : FiltersByCountry}
  let filters = filtersByCountryData[countryCode] ? filtersByCountryData[countryCode]: null

  return filters
}

export const isCountryAllowed = (countryCode : string , field : string)=> {
  let countriesConfigs = countriesFeatures as {[key : string] : CountryFeatures}
  let country = countriesConfigs[countryCode] ? countriesConfigs[countryCode]: null
  if(country && Object.keys(country).includes(field)){
    let countryObj = country as any
    return countryObj[field]
  }else return false
}

export const formatDateToDMY = (date : string, newDelimiter : string) => {

  let parts = date.split('T')
  let date_date = parts[0]
  let parts_date = date_date.split('-')
  if(parts_date.length > 2){
    return `${parts_date[2]}${newDelimiter}${parts_date[1]}${newDelimiter}${parts_date[0]}` 
  }else return false
  
}

export const getDateYMD = (date = new Date(), newDelimiter : string = '-' ) => {
  const year = date.toLocaleString('default', {year: 'numeric'});
  const month = date.toLocaleString('default', {
    month: '2-digit',
  });
  const day = date.toLocaleString('default', {day: '2-digit'});

  return [year, month, day].join(newDelimiter);
}

export const isValidHexaCode = (str : string) => {
  // Regex to check valid
  // hexadecimalColor_code  
  let regex = new RegExp(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/);

  // if str 
  // is empty return false
  if (str == null) {
      return false;
  }

  // Return true if the str
  // matched the ReGex
  if (regex.test(str) == true) {
      return true
  }
  else {
      return false;
  }
}

export const  getBase64ImageFromUrl = async (imageUrl: string) => {
  var res = await fetch(imageUrl);
  var blob = await res.blob();

  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.addEventListener("load", function () {
      resolve(reader.result);
    }, false);

    reader.onerror = () => {
      return reject("error");
    };
    reader.readAsDataURL(blob);
  })
}

export const compareUrlsExceptParam = (url1 : string, url2 : string, paramToExclude : string) =>  {
  // Extract query parameters from both URLs
  const params1 : any= getQueryParams(url1);
  const params2 : any = getQueryParams(url2);
  // Remove the specified parameter from both sets of parameters
  delete params1[paramToExclude];
  delete params2[paramToExclude];

  // Compare the remaining parameters
  const keys1 = Object.keys(params1);
  const keys2 = Object.keys(params2);

  // Check if both URLs have the same remaining parameters with different values
  return keys1.length === keys2.length && keys1.every(key => params1[key] === params2[key]);
}

// Helper function to extract query parameters from a URL
const getQueryParams = (url : string) => {
  const searchParams: any = new URLSearchParams(new URL(url).search);
  const params : any = {};
  for (const [key, value] of searchParams ) {
    params[key] = value;
  }
  return params;
}
