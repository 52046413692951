import { useEffect, useState } from "react"
import { RentGuarantee, RentGuaranteeStatus } from "../../interfaces/rentGuarantees";
import { Dropdown } from "react-bootstrap";
import { IconOrder } from "../CustomIcons/IconOrder";
import useConfig from "../../hooks/config/useConfig"

export const WarrantyFilter = ({ filter, setFilter, waitApply, closeModal,showModalWarranty , insuranceCarriers,
    statusList,setShowModalOrder}:
    {
        filter: any, setFilter: Function, waitApply : boolean,closeModal:Function, showModalWarranty : Function,
        insuranceCarriers : string[], statusList : RentGuaranteeStatus[], setShowModalOrder: Function
    }) => {

    const [searchName, setSearchName] = useState(filter.searchName);
    const [startDate, setStartDate] = useState(filter.startDate);
    const [endDate, setEndDate] = useState(filter.endDate);
    const [insuranceCarrier, setInsuranceCarrier] = useState(filter.insurance_carrier);
    const [status ,setStatus] = useState(filter.activeType)
    const {translation} = useConfig()

    const delayValue = 250
    let timer: any = 0;
    const searchNameInput = (ev: any) => {
        if(waitApply) return

        clearTimeout(timer)
        timer = setTimeout(() => {
            //Hacemos algo
            if (ev.target.value.length > 1) {
                setFilter((prev: any) => { return { ...prev, currentPage: 1, searchName: ev.target.value } })
            } else if (ev.target.value.length == 0) {
                setFilter((prev: any) => { return { ...prev, currentPage: 1, searchName: '' } })
            }

        }, delayValue);
    }

    const keyDownHandle = (ev: any) => {
        clearTimeout(timer)
    }

    const clearSearchName = () => {
        setSearchName('')

        if(waitApply) return
        setFilter((prev: any) => { return { ...prev, searchName: '' ,currentPage:1} })
    }

    const handleChangeStartDate = (ev:any) =>{
        setStartDate(ev.target.value)
        if(waitApply) return
        setFilter((prev: any) => { return { ...prev, startDate: ev.target.value, currentPage : 1 } })
    }
    const handleChangeEndDate = (ev:any) =>{
        setEndDate(ev.target.value)
        if(waitApply) return
        setFilter((prev: any) => { return { ...prev, endDate: ev.target.value,currentPage : 1 } })
    }
    const handleClearStartDate = () =>{
        setStartDate('')
        if(waitApply) return
        setFilter((prev: any) => { return { ...prev, startDate: '' ,currentPage: 1} })
    }
    const handleClearEndDate = () =>{
        setEndDate('')
        if(waitApply) return
        setFilter((prev: any) => { return { ...prev, endDate: '' ,currentPage:1} })
    }

    const handleChangeInsuranceCarrier = (ev:any) =>{
        setInsuranceCarrier(ev.target.value)
        if(waitApply) return
        setFilter((prev: any) => { return { ...prev, insurance_carrier: ev.target.value ,currentPage:1} })
    }

    const handleChangeStatus = (ev:any) =>{
        setStatus(ev.target.value)
        if(waitApply) return
        setFilter((prev: any) => { return { ...prev,activeType : ev.target.value ,currentPage:1} })
    }

    const applyFilter = () =>{
        
        setFilter((prev:any) => { return {
            ...prev, 
            startDate : startDate, 
            endDate : endDate , 
            searchName : searchName,
            insurance_carrier :insuranceCarrier,
            activeType : status,
            currentPage : 1
        }})
        closeModal()
    }

    useEffect(()=>{
        setStatus(filter.activeType)
    },[filter])

    return (
        <div className="d-flex justify-content-between align-items-center  mb-4 flex-wrap flex-column">
            <div className="d-sm-none d-block w-100">
                <h3 className="text-center w-100 mb-4">Filtrar</h3>
            </div>
            <div className="btn-new-warranty d-none d-sm-flex mb-3 w-100 justify-content-between justify-content-lg-end">
            <button className="btn color-primary bg-white text-primary d-none d-sm-block d-lg-none" onClick={() => setShowModalOrder(true)}>
                <IconOrder width={28} /></button>
                <button className="btn btn-primary " onClick={()=> showModalWarranty()}>Derivar {translation.warranty}</button>
            </div>
            <div className="d-flex justify-content-between w-100 mb-2 align-items-center flex-wrap 
            top-search ">
                <div className="d-flex position-relative search-name me-0" >
                    <i className="bi bi-search position-absolute"></i>
                    <input type="text" placeholder="Buscar cliente..." className="me-0 me-sm-2 form-control"
                        value={searchName} onKeyUp={searchNameInput} onChange={(ev) => setSearchName(ev.target.value)}
                        onKeyDown={keyDownHandle} />
                    {searchName && searchName != '' && <i className="bi bi-x  position-absolute" onClick={clearSearchName}></i>}
                </div>
                <div className="me-0 me-sm-2 select-container">
                    <select name="select-insurance" id="selectInsurance" className="form-control"
                     onChange={handleChangeInsuranceCarrier} value={insuranceCarrier} >
                        <option value="" className="">Elige aseguradora</option>
                        {
                            insuranceCarriers.map((ic,index)=> 
                                <option value={ic} key={index} >{ic}</option>
                            )
                        }
                    </select>
                </div>

                <div className="me-0 me-sm-2 select-container">
                    <select name="select-insurance" id="selectInsurance" className="form-control" 
                    onChange={handleChangeStatus} value={status}>
                        <option value="*" className="">Elige estado</option>
                        {
                            statusList.map((status,index)=> 
                                <option value={status.step} key={index}>{status.name}</option>
                            )
                        }
                    </select>
                </div>

                <div className="d-flex align-items-center date-filter flex-wrap">
                    <div className="position-relative">
                        <input type="date" className="form-control me-2"
                            onChange={handleChangeStartDate} value={startDate} />
                        {startDate && startDate != '' && <i className="bi bi-x position-absolute"
                            onClick={handleClearStartDate}></i>}
                    </div>
                    <span className="mx-2">al</span>
                    <div className="position-relative">
                        <input type="date" className="form-control me-2"
                            onChange={handleChangeEndDate} value={endDate} />
                        {endDate && endDate != '' && <i className="bi bi-x position-absolute"
                            onClick={handleClearEndDate}></i>}
                    </div>

                </div>
                {
                    waitApply && <div>
                        <button className="btn btn-primary w-100 mt-4" onClick={applyFilter}>Aplicar Filtros</button>
                    </div>
                }
            </div>
            
        </div>
    )
}