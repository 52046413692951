
import { Col, Modal, Row } from "react-bootstrap"
import { VirtualSignPlans } from "../../../data/services/virtual-sign/plans"
import { VirtualSignPlan } from "../../../interfaces/services"
import { VirtualSignPlanCard } from "./VirtualSignPlanCard"
import { useState } from "react"
import { ModalVirtualSign } from "./ModalVirtualSign"

export const PlanList = () => {

    const plans : VirtualSignPlan[] = VirtualSignPlans
    const [ showModal , setShowModal ] = useState(false)
    const [ selectedPlan , setSelectedPlan ] = useState<VirtualSignPlan>()

    const openModal = (plan : VirtualSignPlan) => {
        setSelectedPlan(plan)
        setShowModal(true)
    }

    return <>
    <Row>
        {
            plans.map((plan, index) => <Col md={4} className="p-4">
                <VirtualSignPlanCard plan={plan} handleGetPlan={openModal} key={index} />
            </Col>)
        }
    </Row>

    <ModalVirtualSign  
        setShow={setShowModal}
        showModal={showModal} 
        plan={selectedPlan}
    />
        
    </>
}