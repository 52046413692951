

import UserProfileForm from "../../fragments/user/profile/UserProfileForm"
import TitleCardTS from "../../daisyUI/components/Cards/TitleCardTs"
import { useGoogleAnalytics } from "../../hooks/googleAnalytics/useGoogleAnalytics";
import useUser from "../../hooks/user/useUser";
import { useEffect, useState } from "react";
import { Badge, Tab, Tabs } from "react-bootstrap";
import UserAccountForm from "../../fragments/user/profile/UserAccountForm";
import UserLevel from "../../fragments/user/levels/UserLevel";

function UserProfile(){

    const GA = useGoogleAnalytics(true);
    const {userState} = useUser()
    useEffect(()=> {
        GA.Event({ category: "User open profile", action: "user_open_profile", label: `${userState.name}`, value: Number(userState.id) });
    },[])

    const checkMobile = () => {
        return  window.innerWidth < 600
    }
    const [ isMobile, setIsMobile] = useState(checkMobile())

    useEffect(() => {
        function handleWindowResize() {
            setIsMobile(checkMobile())
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };

    }, []);

    const title = () => {
        return (
            <div>
                <span>Mi Perfil</span>
                {isMobile && <Badge bg="primary" className="fs-xxs lh-1">NIVEL {userState?.level}</Badge>}
            </div>
        )
    }
    return (
        <>
            <TitleCardTS title={'Mi Perfil'} topMargin="tw-mt-2 user-profile">
                
                
                {isMobile && <div className="position-absolute user-level-title">

                    NIVEL {userState?.level}
                </div> }

                <Tabs
                defaultActiveKey={'profile'}
                id="uncontrolled-tab-example"
            
                >
                
                <Tab eventKey="profile" title="Información">
                    <UserProfileForm />
                </Tab>
                <Tab eventKey="account" title="Cuenta" >
                    <UserAccountForm />
                </Tab>

                {
                   isMobile && 
                    <Tab eventKey="level" title="Nivel">
                        <UserLevel />
                    </Tab>
                }
                
                
                
            </Tabs>
            </TitleCardTS>
        </>
    )
}


export default UserProfile