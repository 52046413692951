import { useEffect } from "react";
import useUser from "../hooks/user/useUser";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../hooks/fetch/useFetch";


const Logout = () => {
  const { userState } = useUser();
  const { logout} = useFetch()
  const navigate = useNavigate();
  
  useEffect(() => {
    logout(userState)
    navigate(`/`)
  }, [])

  return <div>Cerrando sesión</div>
};

export default Logout;
