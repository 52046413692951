export const IconSecurity = ({ width = 18 }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.0625 3.78638C5.0625 2.91525 9 2.25 9 2.25C9 2.25 12.9375 2.91525 12.9375 3.78638C12.9375 4.94775 12.2213 5.4375 12.2213 5.4375H5.77875C5.77875 5.4375 5.0625 4.94775 5.0625 3.78638ZM9.5625 3.9375C9.5625 4.08668 9.50324 4.22976 9.39775 4.33525C9.29226 4.44074 9.14918 4.5 9 4.5C8.85082 4.5 8.70774 4.44074 8.60225 4.33525C8.49676 4.22976 8.4375 4.08668 8.4375 3.9375C8.4375 3.78832 8.49676 3.64524 8.60225 3.53975C8.70774 3.43426 8.85082 3.375 9 3.375C9.14918 3.375 9.29226 3.43426 9.39775 3.53975C9.50324 3.64524 9.5625 3.78832 9.5625 3.9375Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9303 6.855C12.4516 6.44813 12.3706 6 12.3706 6H5.6296C5.6296 6 5.54823 6.44813 6.06985 6.855C5.97383 7.29311 5.97726 7.74717 6.07989 8.18378C6.18252 8.62038 6.38174 9.02842 6.66289 9.37787C6.94404 9.72731 7.29996 10.0093 7.70447 10.203C8.10898 10.3967 8.55178 10.4973 9.00029 10.4973C9.44879 10.4973 9.89159 10.3967 10.2961 10.203C10.7006 10.0093 11.0565 9.72731 11.3377 9.37787C11.6188 9.02842 11.8181 8.62038 11.9207 8.18378C12.0233 7.74717 12.0267 7.29311 11.9307 6.855H11.9303ZM9.0001 7.5C10.0205 7.5 10.7337 7.38225 11.2321 7.21275C11.2727 7.52975 11.2452 7.85175 11.1516 8.15731C11.058 8.46288 10.9004 8.745 10.6892 8.98488C10.4781 9.22477 10.2182 9.41692 9.927 9.54855C9.63578 9.68018 9.31987 9.74826 9.00029 9.74826C8.68071 9.74826 8.36479 9.68018 8.07358 9.54855C7.78236 9.41692 7.52253 9.22477 7.31137 8.98488C7.10022 8.745 6.94259 8.46288 6.84898 8.15731C6.75537 7.85175 6.72792 7.52975 6.76848 7.21275C7.26648 7.38225 7.97973 7.5 9.0001 7.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5625 11.25C9.66196 11.25 9.75734 11.2895 9.82767 11.3598C9.89799 11.4302 9.9375 11.5255 9.9375 11.625V12.1432C9.93746 12.2129 9.91804 12.2811 9.88142 12.3403C9.84479 12.3995 9.79241 12.4474 9.73013 12.4785L9.5625 12.5625L9.77887 13.6444L11.3689 11.2594C11.4776 11.2594 11.5789 11.2601 11.6704 11.2609C13.6973 11.7232 15.75 12.666 15.75 14.0842V15.75H2.25V14.0842C2.25 12.666 4.39237 11.7124 6.41925 11.25C6.41213 11.2515 6.48825 11.2526 6.62738 11.2538L8.23388 13.6631L8.4375 12.5625L8.26987 12.4785C8.20759 12.4474 8.15521 12.3995 8.11858 12.3403C8.08196 12.2811 8.06254 12.2129 8.0625 12.1432V11.625C8.0625 11.5255 8.10201 11.4302 8.17233 11.3598C8.24266 11.2895 8.33804 11.25 8.4375 11.25H9.5625ZM13.125 13.2499C13.125 13.2499 12.6251 13.0001 12.375 12.75C12.1249 13.0001 11.625 13.2499 11.625 13.2499C11.625 13.2499 11.8868 14.25 12.375 14.25C12.8632 14.25 13.125 13.2499 13.125 13.2499Z"
        fill="currentColor"
      />
    </svg>
  );
};
