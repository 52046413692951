import { MouseEventHandler, useRef, useState } from "react";
import { VirtualSignPlan } from "../../../interfaces/services"
import { IconCheckCircle } from "../../CustomIcons/IconCheckCircle"
import './VirtualSignPlanCard.scss'
import { Form } from "react-bootstrap";
import { formatMoney } from "../../../utils/Functions";

export const VirtualSignPlanCard = (
    { plan, handleGetPlan }:
        { plan: VirtualSignPlan; handleGetPlan: Function }
) => {

    const [price, setPrice] = useState(plan.irisPrice)
    const [qtyOperations, setQtyOperations] = useState(plan.qtyOperations)
    const [validated, setValidated] = useState(false);
    const refForm = useRef(null)

    const handleClickPlan = (ev: any) => {
        ev.preventDefault()
        const form = ev.currentTarget

        setValidated(true);
        if (form?.qty) form?.qty.setCustomValidity('')

        if (plan.custom && !!qtyOperations && qtyOperations < 11) {

            if (form && form?.qty) {
                form?.qty.setCustomValidity(false)
            }
        } else {
            const planToOpen: VirtualSignPlan = { ...plan, irisPrice: price }
            handleGetPlan(planToOpen)
        }

    }

    const handleChangeQtyOperations = (ev: any) => {

        const qty = ev.target.value
        const calcPrice = qty * 8940
        setQtyOperations(qty)
        setPrice(calcPrice)
    }

    return <>
        <div className={`virtual-sign__card border-primary border-2 rounded-3 shadow ${plan.highlight ? ' highlight ' : ''}`}>
            
            <div className="top-card p-3">
                <h2 className="fs-4">{plan.name}</h2>
            </div>
        
            
            <div className="p-3 pt-0">
                { !plan.highlight && <hr />}
                <div className="d-flex align-items-end mt-4 mb-4 fw-bold position-relative">
                    {!!plan.price && <span className="virtual-sign__card__old-price fw-normal text-decoration-line-through position-absolute">{plan.currency}{formatMoney(plan.price)} </span>}
                    <span>Valor</span>
                    <div className="d-flex flex-column align-items-center m-1">

                        <span className="fs-2 virtual-sign__card__price ">{plan.currency}{formatMoney(price)} </span>
                    </div>
                    <span>+ IVA</span>
                </div>
                <Form noValidate validated={validated} className="login-form w-100" ref={refForm} onSubmit={handleClickPlan}>
                    <div className="text-center my-2">
                        <button
                            type="submit"
                            className="btn btn-primary"
                        >
                            Obtener paquete
                        </button>
                    </div>

                    <div>
                        <p>Incluye:</p>
                        <ul>
                            {
                                plan.custom && <li className="d-flex justify-content-start align-items-center">
                                    <span className="text-primary"><IconCheckCircle width={15} /></span>

                                    <Form.Group className="w-100 mb-3 custom-plan" controlId="qty">

                                        <Form.Control
                                            className="d-inline"
                                            type="number"
                                            value={qtyOperations}
                                            onChange={handleChangeQtyOperations}
                                        ></Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Min 11 operaciones
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <span> operaciones</span>
                                </li>
                            }
                            {
                                plan.features.map(feature => <li ><span className="text-primary"><IconCheckCircle width={15} /></span> {feature}</li>)
                            }
                        </ul>
                    </div>

                </Form>
            </div>



        </div>
    </>
}