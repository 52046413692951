import { RankingProjectsType, RankingUserType } from "../../interfaces/ranking";
import { IconBuilding } from "../CustomIcons/IconBuilding";
import useCountry from "../../hooks/config/useConfig";

export const FeedRankingButtons = ({
    setShowRankingUsers,
    setShowRankingProjects,
    rankingProjectsData,
    rankingUsersData,
    isLoadingProjects
}: {
    setShowRankingUsers: (x: boolean) => void,
    setShowRankingProjects: (x: boolean) => void,
    rankingProjectsData: RankingProjectsType[],
    rankingUsersData :  RankingUserType[],
    isLoadingProjects : boolean
}) => {
    const handleShowUsers = () => setShowRankingUsers(true);
    const handleShowProjects = () => setShowRankingProjects(true);
    const {country} = useCountry()

    return (
        <div className="d-flex justify-content-between mb-3">
            {
                rankingUsersData.length>0 && 
                <button onClick={() => handleShowUsers()} className="w-50 border-0 bg-white py-3 px-3 me-1 rounded shadow-sm">
                    <span className="text-green me-3">
                        <i className="bi bi-trophy-fill text-green"></i>
                    </span>
                    <span className="fw-500 text-secondary">Top Ranking</span>
                </button>
            }
            {
                (rankingProjectsData.length>0 || isLoadingProjects) && 
                 <button onClick={() => handleShowProjects()} className="w-50 border-0 bg-white py-3 px-3 ms-1 rounded shadow-sm">
                    <span className="text-green me-3">
                        <IconBuilding width={20} />
                    </span>
                    <span className="fw-500 text-secondary">Lo más vendido</span>
                </button>
            }
            
           
        </div>
    )
}