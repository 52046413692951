import { ReactNode } from "react";
import { Modal } from "react-bootstrap"

export const FeedRankingModal = ({
    show,
    onClose,
    children
  }: {
    show: boolean;
    onClose: () => void;
    children: ReactNode
  }) => {
    return (
        <Modal
            show={show}
            onHide={onClose}
            className={`property-modal w-100 border-0`}
            backdropClassName="property-modal-backdrop"
            centered
        >
            <Modal.Body>{children}</Modal.Body>
        </Modal>
    )
}